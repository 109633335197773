import React, { FC } from 'react'
import styles from '../Tables.module.scss'
import { SpriteIcon } from '../../UI/SpriteIcon/SpriteIcon'
import Input, { InputIconAligns } from '../../UI/FormComponents/Input'
import Switch from '../../UI/Switch/Switch'
import InputWithText from '../../UI/FormComponents/InputWithText'
import { CashappAccount } from '../../../types/PaymentAccounts/CashappAccount/CashappAccount'
import { CustomPaymentAccountsFields } from '../../../types/PaymentAccountsFields/CustomPaymentAccountsFields'
import CashAppAccountStatusBadge from '../../UI/StatusBadges/CashAppAccountStatusBadge'
import { PaymentAccountStatus } from '../../../types/CashAppAccountStatus/PaymentAccountStatus'
import { CustomGatewayAccount } from '../../../types/PaymentAccounts/CustomGatewayAccount/CustomGatewayAccount'
import SetupField from '../../UI/SetupField/SetupField'
import { SetupParameter } from '../../../types/Plugin/ExplorerPlugin'

interface PaymentAccountsTableProps {
	onEditClick: (id: number) => void
	onDeleteClick: (id: number) => void
	paymentsAccounts: CustomGatewayAccount[]
	paymentAccountsFields: CustomPaymentAccountsFields
	setPaymentAccountsFields: (value: CustomPaymentAccountsFields) => void
	mainFieldSetupParameter?: SetupParameter
	isSettingsPaymentsModifyAvailable: boolean
}

interface PaymentAccountsTableBodyItemProps {
	onEditClick: (id: number) => void
	onDeleteClick: (id: number) => void
	paymentAccount: CustomGatewayAccount
	paymentAccountsFields: CustomPaymentAccountsFields
	setPaymentAccountsFields: (value: CustomPaymentAccountsFields) => void
	mainFieldSetupParameter?: SetupParameter
	isSettingsPaymentsModifyAvailable: boolean
}

const PaymentAccountsTableBodyItem: FC<PaymentAccountsTableBodyItemProps> = ({
	paymentAccount,
	setPaymentAccountsFields,
	paymentAccountsFields,
	onEditClick,
	mainFieldSetupParameter,
	onDeleteClick,
	isSettingsPaymentsModifyAvailable,
}) => {
	const paymentAccountFields = paymentAccountsFields && paymentAccountsFields[paymentAccount.id]
	const sumOfPriorities =
		paymentAccountsFields &&
		Object.keys(paymentAccountsFields)
			.map(key => paymentAccountsFields[Number(key)].priority)
			.reduce((a, b) => a + b, 0)

	return (
		<div className={styles.trWrapper}>
			<tr>
				<td>
					{mainFieldSetupParameter && (
						<SetupField
							withoutTitle={true}
							setupParameter={mainFieldSetupParameter}
							setValue={value =>
								setPaymentAccountsFields({
									...paymentAccountsFields,
									[paymentAccountFields.id]: {
										...paymentAccountFields,
										mainField: {
											name: mainFieldSetupParameter?.variableName,
											value: value,
										},
									},
								})
							}
							value={paymentAccountFields?.mainField.value}
						/>
					)}
				</td>
				<td>
					<InputWithText
						value={paymentAccountFields?.priority || 0}
						text={`PERCENT ${
							paymentAccountFields?.priority &&
							sumOfPriorities &&
							((paymentAccountFields?.priority / sumOfPriorities) * 100).toFixed()
						}%`}
						setValue={value =>
							setPaymentAccountsFields({
								...paymentAccountsFields,
								[paymentAccount.id]: {
									...paymentAccountFields,
									priority: +value,
								},
							})
						}
						icon={{
							id: 'pen',
							width: 13,
							height: 13,
							align: InputIconAligns.RIGHT,
						}}
					/>
				</td>
				<td>
					<CashAppAccountStatusBadge cashAppAccountStatus={paymentAccount.status} />
				</td>
				{/*<td>*/}
				{/*	<Switch*/}
				{/*		checked={cashappAccountFields?.isEnabled}*/}
				{/*		setChecked={checked =>*/}
				{/*			setCashappAccountsFields({*/}
				{/*				...cashappAccountsFields,*/}
				{/*				[cashappAccount.id]: {*/}
				{/*					...cashappAccountFields,*/}
				{/*					isEnabled: checked,*/}
				{/*				},*/}
				{/*			})*/}
				{/*		}*/}
				{/*	/>*/}
				{/*</td>*/}
				<td className={styles.medium_small}>
					{isSettingsPaymentsModifyAvailable && (
						<div className={styles.buttons}>
							<SpriteIcon
								onClick={() => onEditClick(paymentAccount.id)}
								iconId={'pen'}
								width={15}
								height={15}
							/>

							<SpriteIcon
								onClick={() => onDeleteClick(paymentAccount.id)}
								iconId={'trashBin'}
								width={14}
								height={15}
							/>
						</div>
					)}
				</td>
			</tr>
		</div>
	)
}

const PaymentAccountsTable: FC<PaymentAccountsTableProps> = ({
	onEditClick,
	paymentsAccounts,
	setPaymentAccountsFields,
	paymentAccountsFields,
	mainFieldSetupParameter,
	onDeleteClick,
	isSettingsPaymentsModifyAvailable,
}) => {
	return (
		<table className={`${styles.table} ${styles.fullWidth}`}>
			<thead className={styles.thead}>
				<tr>
					<th>{mainFieldSetupParameter?.variableName}</th>
					<th>PRIORITY</th>
					<th>STATUS</th>
					<th className={styles.medium_small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{paymentsAccounts.map(account => (
					<PaymentAccountsTableBodyItem
						onDeleteClick={onDeleteClick}
						mainFieldSetupParameter={mainFieldSetupParameter}
						onEditClick={onEditClick}
						paymentAccount={account}
						paymentAccountsFields={paymentAccountsFields}
						setPaymentAccountsFields={setPaymentAccountsFields}
						isSettingsPaymentsModifyAvailable={isSettingsPaymentsModifyAvailable}
					/>
				))}
			</tbody>
		</table>
	)
}

export default PaymentAccountsTable
