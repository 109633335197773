import React, { FC, useRef, useState } from 'react'
import styles from './Tables.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { NavLink, useNavigate } from 'react-router-dom'
import StatusBadge from '../UI/StatusBadge/StatusBadge'
import PaymentTypeBadge from '../PaymentTypeBadge/PaymentTypeBadge'
import RatingBadge from '../UI/RatingBadge/RatingBadge'
import { Review } from '../../types/Review/Review'
import { format } from 'date-fns'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import { APIKey } from '../../types/APIKey/APIKey'
import { useCopy } from '../../hooks/useCopy'
import { IpDetailForCustomers } from '../../types/IpDetailForCustomers/IpDetailForCustomers'

interface IpDetailsTableProps {
	ipDetails: IpDetailForCustomers[]
}

interface IpDetailsTableBodyItemProps {
	ipDetail: IpDetailForCustomers
}

const IpAddressesTableBodyItem: FC<IpDetailsTableBodyItemProps> = ({ ipDetail }) => {
	return (
		<div className={styles.trWrapper}>
			<tr>
				<td className={styles.large}>{ipDetail.ip}</td>
				<td>
					<>
						<p>{format(new Date(ipDetail.seenAt), 'MMM dd, yyyy')}</p>
					</>
				</td>
			</tr>
		</div>
	)
}

const IpDetailsTable: FC<IpDetailsTableProps> = ({ ipDetails }) => {
	return (
		<table className={`${styles.table} ${styles.fullWidth}`}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.large}>IP ADDRESS</th>
					<th>DATE</th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{ipDetails.map(ipDetail => (
					<IpAddressesTableBodyItem ipDetail={ipDetail} key={ipDetail.ip} />
				))}
			</tbody>
		</table>
	)
}

export default IpDetailsTable
