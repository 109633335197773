import { privateAPI } from './API'

export const DiscordUserService = {
	connect: async (code: string, redirectUrl: string) => {
		return await privateAPI.post(`/v1/dash/user/discord/connect`, {
			code,
			redirectUrl,
		})
	},
	getGuilds: async () => {
		return await privateAPI.get(`/v1/dash/user/discord/guilds`)
	},
	getGuild: async (guildId: string, shopId: string) => {
		return await privateAPI.get(`/v1/dash/user/discord/guilds/${guildId}`, {
			params: {
				shopId,
			},
		})
	},
}
