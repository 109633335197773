import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import styles from './WebhooksPage.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import SearchInput, { SearchInputStyles } from '../../components/UI/SearchInput/SearchInput'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import Pagination from '../../components/UI/Pagination/Pagination'
import WebhookEndpointsTable from '../../components/Tables/WebhookEndpointsTable'
import Modal from '../../components/Modals/Modal'
import { closeModal } from '../../helpers/closeModal'
import CreateWebhookEndpointModal from '../../components/Modals/CreateWebhookEndpointModal/CreateWebhookEndpointModal'
import { useAPI } from '../../hooks/useAPI'
import { useAppContext } from '../../hooks/useAppContext'
import { DeveloperSettingsService } from '../../API/DeveloperSettingsService'
import { Webhook } from '../../types/Webhook/Webhook'
import Spinner from '../../components/UI/Spinner/Spinner'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import PageTableHeader from '../../components/PageTableHeader/PageTableHeader'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import noWebhookEndpoints from '../../assets/images/noWebhookEndpoints.png'
import { useDebounce } from '../../hooks/useDebounce'
import { usePageTitle } from '../../hooks/usePageTitle'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

const WebhooksPage = () => {
	const [searchValue, setSearchValue] = useState('')
	const [totalItems, setTotalItems] = useState(0)
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [isCreateModalActive, setIsCreateModalActive] = useState(false)
	const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
	const [isDeleteModalActive, setIsDeleteModalActive] = useState(false)
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

	const [workableWebhooks, setWorkableWebhooks] = useState<Webhook[]>([])
	const [deletingWebhookId, setDeletingWebhookId] = useState<number | null>(null)
	const { shop } = useAppContext()
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isDevelopersViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.DEVELOPERS_VIEW) || isAdmin
	const isDevelopersModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.DEVELOPERS_MODIFY) || isAdmin
	const isDevelopersDeleteAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.DEVELOPERS_DELETE) || isAdmin

	usePageTitle(`Webhooks`)

	const [isGetWebhooksLoading, webhooksData, getWebhooks] = useAPI<{
		webhookSecret: string
		webhooks: Webhook[]
	}>(() => DeveloperSettingsService.getAllWebhooks(shop?.id || ''), false, undefined, {
		onSuccess: response => {
			setTotalItems(response.data.data.webhooks.length)
		},
	})

	const isFirstLoading = useFirstLoading(isGetWebhooksLoading)

	const [isDeleteWebhookLoading, , deleteWebhook] = useAPI(
		() => DeveloperSettingsService.deleteWebhook(shop?.id || '', deletingWebhookId),
		false,
		'Webhook successfully deleted',
		{
			onSuccess: () => {
				getWebhooks()
				closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)
			},
		}
	)

	const closeCreateModal = () => {
		closeModal(setIsCreateModalActive, setIsCreateModalVisible)
	}

	const handleWebhooks = (searchValue?: string) => {
		setWorkableWebhooks(
			webhooksData?.webhooks
				?.filter(webhook => webhook.url.toLowerCase().includes(searchValue?.toLowerCase() || ''))
				.filter((webhook, index) => {
					return index < pageSize * (currentPage + 1) && index >= pageSize * currentPage
				}) || []
		)
	}

	useDebounce(searchValue, handleWebhooks, 400)

	const deleteWebhookHandler = (id: number) => {
		setDeletingWebhookId(id)
		setIsDeleteModalActive(true)
	}

	useEffect(() => {
		if (shop && isDevelopersViewAvailable) {
			getWebhooks()
		}
	}, [shop])

	useEffect(() => {
		if (webhooksData) {
			handleWebhooks()
		}
	}, [webhooksData, currentPage, pageSize])

	if (isFirstLoading) {
		return <PageLoading />
	}

	if (!isDevelopersViewAvailable)
		return (
			<NoDataComponent
				imageSrc={noWebhookEndpoints}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)

	return (
		<PageContainer>
			{isCreateModalActive && (
				<Modal
					closeModal={closeCreateModal}
					visible={isCreateModalVisible}
					setVisible={setIsCreateModalVisible}
				>
					<CreateWebhookEndpointModal
						simulate={false}
						onClose={closeCreateModal}
						getWebhooks={getWebhooks}
					/>
				</Modal>
			)}
			{isDeleteModalActive && (
				<Modal
					closeModal={() => closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)}
					visible={isDeleteModalVisible}
					setVisible={setIsDeleteModalVisible}
				>
					<ConfirmModal
						title={'Delete Webhook'}
						onConfirm={deleteWebhook}
						description={`You are about to delete webhook. Are you sure you want to delete this webhook? This cannot be undone.`}
						confirmButtonText={'Delete'}
						onClose={closeCreateModal}
						isButtonLoading={isDeleteWebhookLoading}
						buttonIcon={{
							id: 'trashBin',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
					/>
				</Modal>
			)}
			<PageWrapper>
				{!webhooksData?.webhooks?.length && !searchValue ? (
					<NoDataComponent
						imageSrc={noWebhookEndpoints}
						title={'No Endpoints'}
						description={
							'You don’t have any webhook endpoints. To create an endpoint, tap on the button below.'
						}
						button={
							isDevelopersModifyAvailable
								? {
										onClick: () => setIsCreateModalActive(true),
										text: 'New Endpoint',
										icon: {
											id: 'plus',
											width: 13,
											height: 13,
											align: ButtonsIconAlign.LEFT,
										},
								  }
								: null
						}
					/>
				) : (
					<div className={styles.webhooksPage}>
						<PageHeader title={'Webhook Endpoints'}>
							<div className={styles.headerEnd}>
								<SearchInput
									style={SearchInputStyles.secondary}
									value={searchValue}
									setValue={setSearchValue}
									placeholder={'Search for a webhook by URL or event'}
								/>
								{isDevelopersModifyAvailable && (
									<Button
										style={ButtonStyles.SECONDARY}
										icon={{
											id: 'plus',
											height: 13,
											width: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										onClick={() => setIsCreateModalActive(true)}
									>
										New Endpoint
									</Button>
								)}
							</div>
						</PageHeader>

						<PageTableHeader
							listItemNamePlural={'endpoints'}
							pageSize={pageSize}
							setPageSize={setPageSize}
							totalItems={totalItems}
							setCurrentPage={setCurrentPage}
						/>

						{isGetWebhooksLoading ? (
							<Spinner />
						) : (
							webhooksData?.webhooks && (
								<div className={styles.tableWrapper}>
									<WebhookEndpointsTable
										webhooks={workableWebhooks}
										onDelete={deleteWebhookHandler}
										isDevelopersDeleteAvailable={isDevelopersDeleteAvailable}
									/>
								</div>
							)
						)}

						<div className={styles.paginationWrapper}>
							<Pagination
								withoutPageSizeSelector={true}
								totalItems={totalItems}
								pageSize={pageSize}
								setPageSize={setPageSize}
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
							/>
						</div>
					</div>
				)}
			</PageWrapper>
		</PageContainer>
	)
}

export default WebhooksPage
