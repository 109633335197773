import React, { FC } from 'react'
import styles from '../DiscordBotBuilderPage.module.scss'
import { SpriteIcon } from '../../../components/UI/SpriteIcon/SpriteIcon'
import { BotConnectingSteps } from '../DiscordBotBuilderPage'
import { DiscordClusterType } from '../../../types/DiscordClusterType/DiscordClusterType'

interface ConnectingBotStepsProps {
	currentStep: BotConnectingSteps
	setCurrentStep: (value: BotConnectingSteps) => void
	discordClusterType: DiscordClusterType | null
}

const ConnectingBotSteps: FC<ConnectingBotStepsProps> = ({
	currentStep,
	setCurrentStep,
	discordClusterType,
}) => {
	const steps = [
		{
			title: 'Connect Discord',
			isActive: currentStep === BotConnectingSteps.CONNECT_DISCORD,
			isDone: currentStep > BotConnectingSteps.CONNECT_DISCORD,
		},
		{
			title: 'Select Option',
			isActive: currentStep === BotConnectingSteps.SELECT_OPTION,
			isDone: currentStep > BotConnectingSteps.SELECT_OPTION,
		},

		{
			title: 'Customize Bot',
			isActive: currentStep === BotConnectingSteps.CUSTOMIZE_BOT,
			isDone: currentStep > BotConnectingSteps.CUSTOMIZE_BOT,
		},
	]

	if (discordClusterType === DiscordClusterType.Standard) {
		steps.splice(2, 0, {
			title: 'Connect Bot',
			isActive: currentStep === BotConnectingSteps.PRIMARY_BOT,
			isDone: currentStep > BotConnectingSteps.PRIMARY_BOT,
		})
	}

	if (discordClusterType === DiscordClusterType.Secured) {
		steps.splice(
			2,
			0,
			{
				title: 'Primary Bot',
				isActive: currentStep === BotConnectingSteps.PRIMARY_BOT,
				isDone: currentStep > BotConnectingSteps.PRIMARY_BOT,
			},
			{
				title: 'Secondary Bot',
				isActive: currentStep === BotConnectingSteps.SECONDARY_BOT,
				isDone: currentStep > BotConnectingSteps.SECONDARY_BOT,
			}
		)
	}

	return (
		<div className={styles.connectingBotSteps}>
			{steps.map((step, index) => (
				<>
					<div className={styles.connectingBotStep} key={step.title}>
						<div
							className={`${styles.connectingBotStepStatus} ${step.isActive && styles.active} ${
								step.isDone && styles.done
							}`}
						>
							<p>{index + 1}</p>
							<SpriteIcon iconId={'checked'} width={13} height={13} />
						</div>
						<h2 className={styles.connectingBotPathName}>{step.title}</h2>
					</div>
					{steps.length !== index + 1 && (
						<SpriteIcon
							className={styles.arrowNext}
							iconId={'arrowHead'}
							width={14}
							height={14}
							key={step.title + 'icon'}
						/>
					)}
				</>
			))}
		</div>
	)
}

export default ConnectingBotSteps
