import React, { useState } from 'react'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import generalStyles from '../Settings.module.scss'
import styles from './SettingsBillingPage.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../components/UI/Button/Button'
import SubscriptionCard from '../../../components/SubscriptionCard/SubscriptionCard'
import { Subscriptions } from '../../../types/Subscriptions/Subscriptions'
import BillingInvoicesTable from '../../../components/Tables/BillingInvoicesTable'
import Modal from '../../../components/Modals/Modal'
import { closeModal } from '../../../helpers/closeModal'
import SubscribeModal from '../../../components/Modals/SubscribeModal/SubscribeModal'
import Tabs from '../../../components/UI/Tabs/Tabs'
import { useAppContext } from '../../../hooks/useAppContext'
import SettingsBillingPageMigration from './SettingsBillingPageMigration/SettingsBillingPageMigration';

enum SubscriptionTabs {
	monthly,
	yearly,
}

const SettingsBillingPage = () => {
	const [currentTab, setCurrentTab] = useState<SubscriptionTabs>(SubscriptionTabs.monthly)
	const [totalInvoices, setTotalInvoices] = useState(20)
	const [invoicesCurrentPage, setInvoicesCurrentPage] = useState(1)
	const [invoicesPageSize, setInvoicesPageSize] = useState(10)
	const [isSubscribeModalActive, setIsSubscribeModalActive] = useState(false)
	const [isSubscribeModalVisible, setIsSubscribeModalVisible] = useState(false)
	const [currentModalSubscription, setCurrentModalSubscription] = useState<Subscriptions>(
		Subscriptions.CORE_PLUS
	)
	const { user } = useAppContext()

	const onUpgradeSubscriptionClickHandler = (subscription: Subscriptions) => {
		setIsSubscribeModalActive(true)
		setCurrentModalSubscription(subscription)
	}

	return (
		<PageWrapper isNotRequired>
			{isSubscribeModalActive && (
				<Modal
					closeModal={() => closeModal(setIsSubscribeModalActive, setIsSubscribeModalVisible)}
					visible={isSubscribeModalVisible}
					setVisible={setIsSubscribeModalVisible}
				>
					<SubscribeModal
						subscription={currentModalSubscription}
						isYearly={currentTab === SubscriptionTabs.yearly}
					/>
				</Modal>
			)}
			<div className={generalStyles.settingsPageInnerBody}>
				<header className={styles.subscriptionsHeader}>
					<Tabs
						style={'alternate'}
						tabs={[
							{
								label: 'Monthly',
								value: SubscriptionTabs.monthly,
							},
							{
								label: 'Yearly (Save 20%)',
								value: SubscriptionTabs.yearly,
							},
						]}
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
					/>
				</header>

				<div className={styles.subscriptionPlans}>
					<SubscriptionCard
						subscription={Subscriptions.CORE_PLUS}
						isActive={user?.subscription.id === Subscriptions.CORE_PLUS}
						yearly={currentTab === SubscriptionTabs.yearly}
						onUpgradeClick={() => onUpgradeSubscriptionClickHandler(Subscriptions.CORE_PLUS)}
					/>
					<SubscriptionCard
						subscription={Subscriptions.BUSINESS}
						isActive={user?.subscription.id === Subscriptions.BUSINESS}
						yearly={currentTab === SubscriptionTabs.yearly}
						onUpgradeClick={() => onUpgradeSubscriptionClickHandler(Subscriptions.BUSINESS)}
					/>
					<SubscriptionCard
						subscription={Subscriptions.SCALE}
						isActive={user?.subscription.id === Subscriptions.SCALE}
						yearly={currentTab === SubscriptionTabs.yearly}
						onUpgradeClick={() => onUpgradeSubscriptionClickHandler(Subscriptions.SCALE)}
					/>
				</div>

				<div className={generalStyles.mtMediumLarge}>
					<Button
						style={ButtonStyles.SECONDARY}
						width={'100%'}
						icon={{
							id: 'blankWindow',
							width: 12,
							height: 12,
							align: ButtonsIconAlign.RIGHT,
						}}
						onClick={() => window.open('https://billgang.com/pricing', 'blank', 'noopener')}
					>
						Compare Features
					</Button>
				</div>

				{!user?.isSubscriptionMigrated &&
					(
						<div className={styles.subscriptionMigration}>
							<SettingsBillingPageMigration/>
						</div>
					)
				}

				{/*<div className={`${generalStyles.settingSection}`}>*/}
				{/*	<header className={generalStyles.settingSectionHeader}>*/}
				{/*		<p className={generalStyles.settingSectionTitle}>REVENUE LIMIT</p>*/}
				{/*		<p className={generalStyles.settingSectionDescription}>*/}
				{/*			Your existing plan (Core) has a cumulative revenue limit of $5,000 per annum. This*/}
				{/*			revenue limit is enforced on a month-by-month basis, resulting in an acceptable*/}
				{/*			overage-free charge of $415.00 per month. Once you surpass that monthly threshold, you*/}
				{/*			will be charged an overage fee of 5.00%.*/}
				{/*		</p>*/}
				{/*	</header>*/}
				{/*	<div className={styles.revenueLimit}>*/}
				{/*		<p className={styles.progressText}>*/}
				{/*			$207.24 <span>of $5,000</span>*/}
				{/*		</p>*/}
				{/*		<div className={styles.progressLine}>*/}
				{/*			<span />*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*	<footer className={generalStyles.settingSectionFooter}>*/}
				{/*		<p className={generalStyles.orangeText}>*/}
				{/*			<a href='#'>Upgrade Plan</a>*/}
				{/*		</p>*/}
				{/*		<p className={generalStyles.helperText}>$415.00 monthly limit</p>*/}
				{/*	</footer>*/}
				{/*</div>*/}

				{/*<div className={`${generalStyles.settingSection}`}>*/}
				{/*	<header className={generalStyles.settingSectionHeader}>*/}
				{/*		<p className={generalStyles.settingSectionTitle}>ACCOUNT BALANCE</p>*/}
				{/*		<p className={generalStyles.settingSectionDescription}>*/}
				{/*			Top up your account balance to cover monthly subscriptions, revenue overage fees,*/}
				{/*			individual app costs, and API overage fees to prevent account restrictions.*/}
				{/*		</p>*/}
				{/*	</header>*/}
				{/*	<div className={generalStyles.formWrapper}>*/}
				{/*		<Input value={'$0.00'} setValue={() => {}} />*/}
				{/*	</div>*/}
				{/*	<footer className={generalStyles.settingSectionFooter}>*/}
				{/*		<Button style={ButtonStyles.DARK}>Top Up Balance</Button>*/}
				{/*	</footer>*/}
				{/*</div>*/}

				{/*<div className={`${generalStyles.settingSection} ${generalStyles.table}`}>*/}
				{/*	<header className={generalStyles.settingSectionHeader}>*/}
				{/*		<p className={generalStyles.settingSectionTitle}>Billing History</p>*/}
				{/*	</header>*/}
				{/*	<div className={generalStyles.formWrapper}>*/}
				{/*		<BillingInvoicesTable />*/}
				{/*	</div>*/}
				{/*</div>*/}
			</div>
		</PageWrapper>
	)
}

export default SettingsBillingPage
