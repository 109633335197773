import { privateAPI } from './API'

export const AffiliatesService = {
	getAll: async (
		shopId: string,
		searchString?: string,
		PageNumber?: number,
		PageSize?: number,
		orderBy?: string
	) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/affiliates`, {
			params: {
				searchString,
				PageNumber,
				PageSize,
				orderBy,
			},
		})
	},
}
