import { type FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SettingsBillingPageMigration.module.scss';
import MigratingStepper from './components/MigratingStepper'
import MigrateApiKeyStep from './components/MigrateApiKeyStep'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../../components/UI/Button/Button'
import { FormErrors } from '../../../../types/FormErrors/FormErrors';
import StatusAlertBadge from '../../../../components/UI/StatusAlertBadge/StatusAlertBadge';
import { StatusAlert } from '../../../../types/StatusAlert/StatusAlert';
import loadingWTFImage from '../../../../assets/images/loading-wtf.gif'
import { useAPI } from '../../../../hooks/useAPI';
import { MigrationService } from '../../../../API/MigrationService';

export enum MigratingStep {
    API_KEY,
    VERIFY_MIGRATION,
}

const SettingsBillingPageMigration: FC = () => {
    // hooks
    const navigate = useNavigate();

    // states
    const [currentStep, setCurrentStep] = useState<MigratingStep>(MigratingStep.API_KEY)
    const [apiKey, setApiKey] = useState('')
    const [errors, setErrors] = useState<FormErrors>({})
    const [wasMigrated, setWasMigrated] = useState<boolean>(false);
    const [hasError, setHasError] = useState(false);

    // Handle migration
    const [isCreateMigrationLoading, , createMigration] = useAPI(
        () =>
            MigrationService.createSubscriptionMigration({
                apiKey,
            }),
        false,
        'Migrate request successfully sent',
        {
            onSuccess: () => {
                setWasMigrated(true);
            },
            onError: () => {
                setHasError(true);
            },
        }
    )

    const onContinueClickHandler = () => {
        if (currentStep < MigratingStep.VERIFY_MIGRATION) {
            setCurrentStep(currentStep => currentStep + 1)
        }

        if (currentStep === MigratingStep.API_KEY) {
            createMigration()
        }
    }

    return (
        <div className={`${styles.migrateSection}`}>
                    <p className={styles.defaultTitle}>Migrate subscription from Sellpass</p>
                    <div className={`${styles.migrateSectionBody} ${styles.mtMediumLarge}`}>
                        <MigratingStepper currentStep={currentStep} setCurrentStep={setCurrentStep}/>

                        {currentStep === MigratingStep.API_KEY && (
                            <MigrateApiKeyStep
                                apiKey={apiKey}
                                setApiKey={setApiKey}
                                errors={errors}
                                setErrors={setErrors}
                            />
                        )}

                        {currentStep === MigratingStep.VERIFY_MIGRATION && !wasMigrated && !hasError &&
                            (
                                <div className={styles.mtMediumLarge}>
                                    <StatusAlertBadge
                                        title={'Pending Migration'}
                                        description={
                                            <div>
                                                <p>
                                                    Your migration is currently in progress. Our system is currently
                                                    fetching data from Sellpass and storing them on BIllgang.
                                                </p>

                                                <div className={`${styles.loadingImageWrapper} ${styles.mtMedium}`}>
                                                    <img src={loadingWTFImage} alt='loading'/>
                                                </div>
                                            </div>
                                        }
                                        statusAlertType={StatusAlert.WARNING}
                                    />
                                </div>
                            )}

                        {currentStep === MigratingStep.VERIFY_MIGRATION && hasError && (
                            <div className={styles.mtMediumLarge}>
                                <StatusAlertBadge
                                    title={'Subscription Migration Failed'}
                                    description={
                                        <p>
                                            Uh oh. While we were transferring your subscription from Sellpass to Billgang, our
                                            system was met with an unknown error. Don’t worry - our engineers have
                                            been notified.
                                            <br/>
                                            <br/>
                                            Please contact us through our support channels and provide us with your
                                            storefront link, email address, and with a brief summary of your business.
                                        </p>
                                    }
                                    button={{
                                        icon: {
                                            id: 'blankWindow',
                                            width: 13,
                                            height: 13,
                                            align: ButtonsIconAlign.LEFT,
                                        },
                                        text: 'Contact engineering support',
                                        onClick: () =>
                                            window.open('https://discord.gg/5MSmTbbvxJ', 'blank', 'noopener'),
                                    }}
                                    statusAlertType={StatusAlert.DANGER}
                                />
                            </div>
                        )}

                        {currentStep === MigratingStep.VERIFY_MIGRATION && wasMigrated && (
                            <div className={styles.mtMediumLarge}>
                                <StatusAlertBadge
                                    title={'Successfully Migrated subscription'}
                                    description={
                                        <p>
                                            Welcome to Billgang. If you’re already here, you’re more than likely aware
                                            but here goes nothing - Billgang is the most powerful (and easiest)
                                            platform to sell digital goods online.
                                            <br/>
                                            <br/>
                                            Billgang powers builders, visionaries, the leaders of tomorrow, and people
                                            just like you.
                                        </p>
                                    }
                                    button={{
                                        icon: {
                                            id: 'blankWindow',
                                            width: 13,
                                            height: 13,
                                            align: ButtonsIconAlign.LEFT,
                                        },
                                        text: 'Visit business dashboard',
                                        onClick: () => navigate('/'),
                                    }}
                                    statusAlertType={StatusAlert.SUCCESS}
                                />
                            </div>
                        )}

                        <div className={styles.buttons}>
                            {currentStep === MigratingStep.API_KEY && (
                                <Button
                                    style={ButtonStyles.BORDERED}
                                    icon={{
                                        id: 'arrowRight',
                                        width: 13,
                                        height: 12,
                                        align: ButtonsIconAlign.RIGHT,
                                    }}
                                    width={'100%'}
                                    disableShadow={true}
                                    onClick={onContinueClickHandler}
                                    isLoading={isCreateMigrationLoading}
                                >
                                    Start Migration
                                </Button>
                            )}
                        </div>
                    </div>
        </div>
    )
}

export default SettingsBillingPageMigration;