import { type FC } from 'react';
import styles from './SubscriptionPlanCard.module.scss';
import UpgradePlanButton from './UpgradePlanButton';
import ChangePlanButton from './ChangePlanButton';

interface SubscriptionPlanCardProps {
    currentPlan: string;
    expireDate: string | undefined;
    onPlanChangeClick: () => void;
}

const SubscriptionPlanCard: FC<SubscriptionPlanCardProps> = ({ currentPlan, expireDate, onPlanChangeClick }) => {
    // Transform expire date
    let formattedDate;
    if (expireDate) {
        const dateObj = new Date(expireDate);
        formattedDate = dateObj.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    }

    // Check if subscription has expired
    const hasExpired = new Date(expireDate ?? '').getTime() < new Date().getTime();

    return (
        <section className={styles.container}>
            <div className={styles.planInfo}>
                <h2 className={styles.planName}>{currentPlan}</h2>
                <p className={styles.planDescription} style={{
                    color: (currentPlan !== 'Free' && hasExpired) ? '#C12A2A' : '',
                }}>
                    {currentPlan === 'Free'
                        ? 'The 10% off for upgrade'
                        : `${hasExpired ? 'Expired at' : 'Expires at'} ${formattedDate ?? ''}`
                    }
                </p>
            </div>
            {currentPlan === 'Free'
                ? <UpgradePlanButton onChangePlan={onPlanChangeClick} />
                : <ChangePlanButton onChangePlan={onPlanChangeClick} />
            }
        </section>
    );
};

export default SubscriptionPlanCard;