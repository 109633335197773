import React, { FC } from 'react'
import styles from './WebhookEventBadge.module.scss'
import { NotificationEvent } from '../../../types/NotificationEvent/NotificationEvent'

interface WebhookEventBadgeProps {
	event: NotificationEvent
}

const WebhookEventBadge: FC<WebhookEventBadgeProps> = ({ event }) => {
	return <div className={styles.webhookEventBadge}>{event.toLowerCase().replaceAll('_', ':')}</div>
}

export default WebhookEventBadge
