export const debounce = (func: Function, timeout: number) => {
	let timer: NodeJS.Timeout

	return (...args: any[]) => {
		clearTimeout(timer)
		timer = setTimeout(() => {
			func.apply(this, args)
		}, timeout)
	}
}
