import React, { useEffect, useState } from 'react'
import styles from './WebhookPayloadPage.module.scss'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageHeader from '../../components/PageHeader/PageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import Button, { ButtonsIconAlign } from '../../components/UI/Button/Button'
import { SpriteIcon } from '../../components/UI/SpriteIcon/SpriteIcon'
import { JsonViewer } from '@textea/json-viewer'
import { useAPI } from '../../hooks/useAPI'
import { WebhooksService } from '../../API/WebhooksService'
import { useAppContext } from '../../hooks/useAppContext'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import { format } from 'date-fns'
import { WebhookPayload } from '../../types/WebhookPayload/WebhookPayload'
import WebhookMessageEventStatusBadge from '../../components/UI/StatusBadges/WebhookMessageEventStatusBadge'
import CustomSelect, { SelectStyles } from '../../components/UI/CustomSelect/CustomSelect'
import StatusCodeBadge from '../../components/UI/StatusBadges/StatusCodeBadge'
import { WebhookPayloadAttempt } from '../../types/WebhookPayloadAttempt/WebhookPayloadAttempt'
import JSONWrapper from '../../components/UI/JSONWrapper/JSONWrapper'
import Modal from '../../components/Modals/Modal'
import WebhookRequestModal from '../../components/Modals/WebhookRequestModal/WebhookRequestModal'
import { closeModal } from '../../helpers/closeModal'
import { usePageTitle } from '../../hooks/usePageTitle'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

const WebhookPayloadPage = () => {
	const navigate = useNavigate()
	const params = useParams()
	const externalMessageId = params.externalMessageId
	const externalEndpointId = params.externalEndpointId
	const { shop } = useAppContext()
	const [activeAttempt, setActiveAttempt] = useState<WebhookPayloadAttempt | null>(null)
	const [isWebhookRequestModalActive, setIsWebhookModalActive] = useState(false)
	const [isWebhookRequestModalVisible, setIsWebhookModalVisible] = useState(false)

	usePageTitle(`Webhook Payload`)

	const [isGetWebhookPayloadLoading, webhookPayload, getWebhookPayload] = useAPI<WebhookPayload>(
		() => WebhooksService.getOneLog(shop?.id || '', externalEndpointId, externalMessageId),
		false
	)

	useEffect(() => {
		if (shop) {
			getWebhookPayload()
		}
	}, [shop])

	if (isGetWebhookPayloadLoading) return <PageLoading />

	return (
		<PageContainer>
			{isWebhookRequestModalActive && (
				<Modal
					closeModal={() => closeModal(setIsWebhookModalActive, setIsWebhookModalVisible)}
					visible={isWebhookRequestModalVisible}
					setVisible={setIsWebhookModalVisible}
				>
					<WebhookRequestModal
						json={JSON.parse(webhookPayload?.payloadJson || '{}')}
						onClose={() => closeModal(setIsWebhookModalActive, setIsWebhookModalVisible)}
					/>
				</Modal>
			)}
			<PageWrapper>
				<div className={styles.webhookPayloadPage}>
					<PageHeader
						title={'Webhook Payload'}
						isRow={true}
						backButton={{
							isActive: true,
							onClick: () => navigate('/developers/webhook-logs'),
						}}
					>
						{/*<Button*/}
						{/*	icon={{*/}
						{/*		id: 'refresh',*/}
						{/*		height: 14,*/}
						{/*		width: 14,*/}
						{/*		align: ButtonsIconAlign.LEFT,*/}
						{/*	}}*/}
						{/*>*/}
						{/*	Send Again*/}
						{/*</Button>*/}
					</PageHeader>

					<div className={styles.body}>
						<div>
							<div className={styles.section}>
								<header>
									<div className={styles.urlAndStatus}>
										{webhookPayload?.url}
										{webhookPayload && (
											<WebhookMessageEventStatusBadge
												webhookMessageEventStatus={webhookPayload?.status}
											/>
										)}
									</div>
								</header>

								<div className={styles.titleValueWrapper}>
									<h2>URL</h2>
									<p>{webhookPayload?.url}</p>
								</div>
								<div className={styles.titleValueWrapper}>
									<h2>Event</h2>
									<p>{webhookPayload?.event.toLowerCase().replaceAll('_', ':')}</p>
								</div>
								<div className={styles.titleValueWrapper}>
									<h2>Status</h2>
									<p>{webhookPayload?.status}</p>
								</div>
								{/*<div className={styles.titleValueWrapper}>*/}
								{/*	<h2>Retries</h2>*/}
								{/*	<p>1/5</p>*/}
								{/*</div>*/}
								<div className={styles.titleValueWrapper}>
									<h2>Sent At</h2>
									{webhookPayload?.createdAt && (
										<p>
											{format(new Date(webhookPayload?.createdAt), 'MMM dd, yyyy')} at{' '}
											{format(new Date(webhookPayload?.createdAt), 'hh:mm aa z')}
										</p>
									)}
								</div>
								<div className={styles.titleValueWrapper}>
									<h2>Request</h2>
									<a onClick={() => setIsWebhookModalActive(true)}>View Request</a>
								</div>
							</div>
						</div>

						<div className={styles.attemptsWrapper}>
							<div className={styles.attemptsList}>
								<header className={styles.attemptsWrapperHeader}>
									<p>{webhookPayload?.attempts.length} attempts</p>
									{/*	<div className={styles.attemptsFilter}>*/}
									{/*	<CustomSelect*/}
									{/*		style={SelectStyles.transparent}*/}
									{/*		value={''}*/}
									{/*		setValue={() => {}}*/}
									{/*		options={[{ label: 'Status: All', value: 'Status: All' }]}*/}
									{/*	/>*/}
									{/*</div>*/}
								</header>

								<div className={styles.attempts}>
									{webhookPayload?.attempts.map(attempt => (
										<div className={styles.attemptWrapper}>
											<div
												className={`${styles.attempt} ${
													activeAttempt?.id === attempt.id && styles.active
												}`}
												onClick={() => setActiveAttempt(attempt)}
											>
												<StatusCodeBadge statusCode={attempt.responseStatusCode} />
												<span className={styles.attemptEvent}>
													{webhookPayload?.event.toLowerCase().replaceAll('_', ':')}
												</span>
												<time>
													{format(new Date(attempt.createdAt), "MMM dd, yyyy 'at' hh:mm aa z")}
												</time>
											</div>
										</div>
									))}
								</div>
							</div>

							<div className={styles.attemptResponse}>
								<header className={styles.attemptsWrapperHeader}>
									<p>Response</p>
									<SpriteIcon iconId={'copy'} width={14} height={14} />
								</header>

								<JSONWrapper json={JSON.parse('{}')} />
							</div>
						</div>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default WebhookPayloadPage
