import { useEffect, useState } from 'react'

export const useFirstLoading = (isLoading: boolean) => {
	const [isFirstLoading, setIsFirstLoading] = useState(false)
	const [isMount, setIsMount] = useState(false)

	useEffect(() => {
		if (isLoading && !isMount) {
			setIsFirstLoading(true)
			setIsMount(true)
		}
		if (!isLoading && isFirstLoading && isMount) {
			setIsFirstLoading(false)
		}
	}, [isLoading])

	return isFirstLoading
}
