import React, { FC } from 'react'
import generalStyles from '../../CreatePostPage.module.scss'
import ImageInput from '../../../../components/UI/ImageInput/ImageInput'
import HelpLink from '../../../../components/UI/HelpLink/HelpLink'
import Radio from '../../../../components/UI/Radio/Radio'
import { ListingVisibility } from '../../../../types/ListingVisibility/ListingVisibility'
import ImagePicker from '../../../../components/ImagePicker/ImagePicker'
import RequiredBadge from '../../../../components/UI/RequiredBadge/RequiredBadge'
import RadioWrapper from '../../../../components/UI/RadioWrapper/RadioWrapper'
import { Image } from '../../../../types/Image/Image'

interface PostEditProps {
	image: Image | null
	setImage: (image: Image | null) => void
	visibility: ListingVisibility
	setVisibility: (value: ListingVisibility) => void
}

const PostEdits: FC<PostEditProps> = ({ image, setImage, visibility, setVisibility }) => {
	return (
		<div className={generalStyles.ProductGroupEdits}>
			<div className={generalStyles.fieldWrapper}>
				<p className={generalStyles.fieldTitle}>Post Image</p>
				<ImagePicker setImages={images => setImage(images[0])} images={[image]} />
			</div>
			<div className={generalStyles.horLine} />
			<div className={generalStyles.fieldWrapper}>
				{/*<p className={generalStyles.fieldTitle}>VISIBILITY</p>*/}
				{/*<p className={generalStyles.fieldDescription}>*/}
				{/*	Limit the product visibility on your storefront.*/}
				{/*</p>*/}
				<div className={generalStyles.radios}>
					<RadioWrapper
						id={ListingVisibility.PUBLIC}
						value={ListingVisibility.PUBLIC}
						setValue={value => setVisibility(value as ListingVisibility)}
						checked={visibility === ListingVisibility.PUBLIC}
						description={'Available to any customer.'}
						title={'Visible (public)'}
					/>
					<RadioWrapper
						id={ListingVisibility.HIDDEN}
						value={ListingVisibility.HIDDEN}
						setValue={value => setVisibility(value as ListingVisibility)}
						checked={visibility === ListingVisibility.HIDDEN}
						title={'HIDDEN (LINK)'}
						description={'Viewed with a direct post link.'}
					/>
					<RadioWrapper
						id={ListingVisibility.PRIVATE}
						value={ListingVisibility.PRIVATE}
						setValue={value => setVisibility(value as ListingVisibility)}
						checked={visibility === ListingVisibility.PRIVATE}
						title={'HIDDEN (PRIVATE)'}
						description={'Post cannot be viewed.'}
					/>
				</div>
			</div>
		</div>
	)
}

export default PostEdits
