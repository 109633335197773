import React, { FC } from 'react'
import styles from './IconField.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

interface IconFieldProps {
	value: string
	icon: {
		id: string
		width: number
		height: number
	}
}

const IconField: FC<IconFieldProps> = ({ value, icon }) => {
	return (
		<div className={styles.iconField}>
			<SpriteIcon iconId={icon.id} width={icon.width} height={icon.height} />
			{value}
		</div>
	)
}

export default IconField
