import React, { FC, useEffect, useState } from 'react'
import modalStyles from '../Modals.module.scss'
import { getRewardRuleTypeOption } from '../../../helpers/getRewardRuleTypeOption'
import { BalanceRewardType } from '../../../types/BalanceRewardType/BalanceRewardType'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import CashbackRuleForm from './CashbackRuleForm'
import TopUpRuleForm from './TopUpRuleForm'
import { useAPI } from '../../../hooks/useAPI'
import { PaymentsSettingsService } from '../../../API/PaymentsSettingsService'
import { useAppContext } from '../../../hooks/useAppContext'
import { GetBalanceRewards } from '../../../types/GetBalanceRewards/GetBalanceRewards'

interface BalanceRewardModalProps extends BaseModalProps {
	balanceRewardType: BalanceRewardType
	balanceRewards?: GetBalanceRewards
	getRewards: () => void
}

const BalanceRewardModal: FC<BalanceRewardModalProps> = ({
	balanceRewardType,
	balanceRewards,
	onClose,
	getRewards,
}) => {
	const ruleOption = getRewardRuleTypeOption(balanceRewardType)
	const [cashbackPercentage, setCashbackPercentage] = useState(0)
	const [bonusPercentage, setBonusPercentage] = useState(0)
	const [minimumTopUp, setMinimumTopUp] = useState(0)
	const { shop } = useAppContext()

	const [isUpdateBalanceRewardsLoading, , updateBalanceRewards] = useAPI(
		() =>
			PaymentsSettingsService.updateBalanceRewards(shop?.id || '', {
				topUpBonusEnabled:
					balanceRewardType === BalanceRewardType.topUpBonus
						? true
						: !!balanceRewards?.topUpBonusEnabled,
				minimumTopUpForBonus: +minimumTopUp,
				cashbackPercent: +cashbackPercentage,
				cashbackEnabled:
					balanceRewardType === BalanceRewardType.cashback
						? true
						: !!balanceRewards?.cashbackEnabled,
				bonusPercent: +bonusPercentage,
			}),
		false,
		'Balance reward successfully saved',
		{
			onSuccess: () => {
				getRewards()
				onClose()
			},
		}
	)

	const onSaveHandler = () => {
		updateBalanceRewards()
	}

	useEffect(() => {
		if (balanceRewards) {
			setCashbackPercentage(balanceRewards?.cashbackPercent || 0)
			setBonusPercentage(balanceRewards.bonusPercent || 0)
			setMinimumTopUp(balanceRewards.minimumTopUpForBonus || 0)
		}
	}, [])

	return (
		<div className={modalStyles.modalInner}>
			<header className={modalStyles.header}>
				<h1>{ruleOption.title}</h1>
			</header>

			<div className={modalStyles.body}>
				<p className={modalStyles.description}>{ruleOption.description}</p>
				{balanceRewardType === BalanceRewardType.cashback && (
					<CashbackRuleForm percentage={cashbackPercentage} setPercentage={setCashbackPercentage} />
				)}
				{balanceRewardType === BalanceRewardType.topUpBonus && (
					<TopUpRuleForm
						bonusPercentage={bonusPercentage}
						setBonusPercentage={setBonusPercentage}
						minimumTopUp={minimumTopUp}
						setMinimumTopUp={setMinimumTopUp}
					/>
				)}
			</div>

			<footer className={modalStyles.footer}>
				<div className={modalStyles.buttons}>
					<Button style={ButtonStyles.BORDERED} onClick={onClose} disableShadow={true}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'download',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						width={'100%'}
						disableShadow={true}
						onClick={onSaveHandler}
						isLoading={isUpdateBalanceRewardsLoading}
					>
						Save Rule
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default BalanceRewardModal
