import React, { FC } from 'react'
import styles from './RewardRuleModal.module.scss'
import modalStyles from '../Modals.module.scss'
import Input from '../../UI/FormComponents/Input'
import RewardTypeForm from './RewardTypeForm'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'
import RequiredBadge from '../../UI/RequiredBadge/RequiredBadge'

interface SpendRuleFormProps {
	forEach: number
	setForEach: (value: number) => void
	errors: FormErrors
	setErrors: (value: FormErrors) => void
}

const SpendRuleForm: FC<SpendRuleFormProps> = ({ forEach, setForEach, setErrors, errors }) => {
	return (
		<div className={modalStyles.mtMediumLarge}>
			<div className={modalStyles.fieldWrapper}>
				<h2>
					Spend Limit <RequiredBadge />
				</h2>
				<Input
					value={forEach}
					setValue={setForEach}
					placeholder={'For each $ spent (e.g: 100)'}
					type={'number'}
					errorMessage={errors['forEach']}
					onBlur={() =>
						setErrors({
							...errors,
							forEach: fieldValidators.blankValidator('Spend limit', forEach),
						})
					}
				/>
				<p className={modalStyles.fieldDescription}>
					How much should customers spend before unlocking this reward?
				</p>
			</div>
		</div>
	)
}

export default SpendRuleForm
