import React, { FC } from 'react'
import styles from './ProductSEO.module.scss'
import generalStyles from '../../CreateProductPage.module.scss'
import Input from '../../../../components/UI/FormComponents/Input'
import Textarea from '../../../../components/UI/FormComponents/Textarea'

import UrlInput from '../../../../components/UI/FormComponents/UrlInput/UrlInput'
import ImagePicker from '../../../../components/ImagePicker/ImagePicker'
import { FormErrors } from '../../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../../helpers/fieldValidators'
import { useAppContext } from '../../../../hooks/useAppContext'
import { Image } from '../../../../types/Image/Image'
import RequiredBadge from '../../../../components/UI/RequiredBadge/RequiredBadge'
import OptionalBadge from '../../../../components/UI/OptionalBadge/OptionalBadge'

interface ProductSEOProps {
	slugUrl: string
	setSlugUrl: (value: string) => void
	metaTitle: string
	setMetaTitle: (value: string) => void
	metaDescription: string
	setMetaDescription: (value: string) => void
	setMetaImage: (value: Image | null) => void
	metaImage: Image | null
	errors: FormErrors
	setErrors: (value: FormErrors) => void
}

const ProductSEO: FC<ProductSEOProps> = ({
	slugUrl,
	setSlugUrl,
	setMetaDescription,
	setMetaTitle,
	metaDescription,
	metaTitle,
	metaImage,
	setMetaImage,
	errors,
	setErrors,
}) => {
	const { shop, shopDomain } = useAppContext()

	return (
		<div className={styles.productGeneral}>
			<div className={generalStyles.fieldWrapper}>
				<p className={generalStyles.fieldTitle}>
					Slug URL <RequiredBadge />
				</p>

				<UrlInput
					URL={`${shopDomain}/products/`}
					value={slugUrl}
					setValue={setSlugUrl}
					errorMessage={errors['uniquePath']}
					onBlur={() =>
						setErrors({
							...errors,
							uniquePath: fieldValidators.blankValidator('Product URL', slugUrl),
						})
					}
				/>
				<p className={generalStyles.fieldDescription}>
					This is the link your customers will access your product from.
				</p>
			</div>
			<div className={generalStyles.horLine} />
			<div className={generalStyles.fieldWrapper}>
				<p className={generalStyles.fieldTitle}>
					Page Title <RequiredBadge />
				</p>
				<Input
					id={'productMetaTitle'}
					value={metaTitle}
					setValue={setMetaTitle}
					maxLength={60}
					placeholder={'Page Title'}
				/>
			</div>
			<div className={generalStyles.horLine} />
			<div className={generalStyles.doubleFields}>
				<div className={generalStyles.fieldWrapper}>
					<p className={generalStyles.fieldTitle}>Meta Description</p>
					<Textarea
						id={'productMetaDescription'}
						value={metaDescription}
						setValue={setMetaDescription}
						height={164}
						resizable={false}
						maxLength={320}
						placeholder={
							'We are currently updating our website. You may experience periodic downtimes, so remember to join our server.'
						}
					/>
				</div>
				<div className={generalStyles.fieldWrapper} id={'productMetaImage'}>
					<p className={generalStyles.fieldTitle}>Meta Preview Image</p>
					<ImagePicker setImages={images => setMetaImage(images[0])} images={[metaImage]} />
				</div>
			</div>
		</div>
	)
}

export default ProductSEO
