import React, { FC } from 'react'
import styles from './FormComponents.module.scss'

interface StockTextareaProps {
	value: string
	setValue: (value: string) => void
	stockInDatabase: number
	stockInEditor: number
	isEditing?: boolean
}

const StockTextarea: FC<StockTextareaProps> = ({
	setValue,
	value,
	stockInDatabase,
	stockInEditor,
	isEditing,
}) => {
	return (
		<div className={styles.stockTextareaWrapper}>
			<textarea
				className={styles.textarea}
				value={value}
				onChange={event => setValue(event.target.value)}
				placeholder={'billgang_8bbd7c7c-b7d5-4366-b653-b8c1d9a10a1,\n' + 'billgang_c65c0c54-f72...'}
			/>
			<div className={styles.stockInformation}>
				<p>{isEditing ? 'Editing' : 'Adding'} stock</p>
				<p>Stock in database: {stockInDatabase}</p>
				<p>Stock in editor: {stockInEditor}</p>
			</div>
		</div>
	)
}

export default StockTextarea
