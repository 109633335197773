import React, { FC } from 'react'
import styles from './RewardRuleModal.module.scss'
import modalStyles from '../Modals.module.scss'
import Input from '../../UI/FormComponents/Input'
import RewardTypeForm from './RewardTypeForm'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'
import RequiredBadge from '../../UI/RequiredBadge/RequiredBadge'

interface ReferRuleFormProps {
	minCompletedOrders: number
	setMinCompletedOrders: (value: number) => void
	minReferrals: number
	setMinReferrals: (value: number) => void
	errors: FormErrors
	setErrors: (value: FormErrors) => void
}

const ReferRuleForm: FC<ReferRuleFormProps> = ({
	minReferrals,
	setMinReferrals,
	setMinCompletedOrders,
	minCompletedOrders,
	setErrors,
	errors,
}) => {
	return (
		<div className={modalStyles.mtMediumLarge}>
			<div className={modalStyles.fieldWrapper}>
				<h2>
					Minimum Referrals <RequiredBadge />
				</h2>
				<Input
					value={minReferrals}
					setValue={setMinReferrals}
					placeholder={'0'}
					type={'number'}
					errorMessage={errors['minReferrals']}
					onBlur={() =>
						setErrors({
							...errors,
							minReferrals: fieldValidators.blankValidator('Minimum referrals', minReferrals),
						})
					}
				/>
				<p className={modalStyles.fieldDescription}>
					How much should your users refer to your store to unlock this reward?
				</p>
			</div>
			<div className={modalStyles.fieldWrapper}>
				<h2>Minimum Order</h2>
				<Input
					value={minCompletedOrders}
					setValue={setMinCompletedOrders}
					placeholder={'0'}
					type={'number'}
				/>
				<p className={modalStyles.fieldDescription}>
					How much orders should the new customer complete to activate the reward?
				</p>
			</div>
		</div>
	)
}

export default ReferRuleForm
