import React, { FC, useEffect, useState } from 'react'
import styles from './RewardRuleModal.module.scss'
import modalStyles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { getRewardRuleTypeOption } from '../../../helpers/getRewardRuleTypeOption'
import ReviewRuleForm from './ReviewRuleForm'
import { RewardRuleType } from '../../../types/RewardRuleType/RewardRuleType'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import ReferRuleForm from './ReferRuleForm'
import OrdersRuleForm from './OrdersRuleForm'
import SpendRuleForm from './SpendRuleForm'
import RewardTypeForm from './RewardTypeForm'
import { useAPI } from '../../../hooks/useAPI'
import { RewardsService } from '../../../API/RewardsService'
import { useAppContext } from '../../../hooks/useAppContext'
import { RewardType } from '../../../types/RewardType/RewardType'
import { ProductWithVariantForProductRewardType } from '../../../types/ProductWithVariantForProductRewardType/ProductWithVariantForProductRewardType'
import uniqid from 'uniqid'
import { GetReward } from '../../../types/GetReward/GetReward'
import { CreateRewardRule } from '../../../types/CreateRewardRule/CreateRewardRule'
import { RewardRule } from '../../../types/RewardRule/RewardRule'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'
import FormErrorsDisplayBadge from '../../UI/FormErrorsDisplayBadge/FormErrorsDisplayBadge'

interface RewardRuleModalProps extends BaseModalProps {
	ruleType: RewardRuleType
	getRewards: () => void
	rewards: RewardRule[] | undefined
	isEditing: boolean
}

const RewardRuleModal: FC<RewardRuleModalProps> = ({
	ruleType,
	onClose,
	getRewards,
	rewards,
	isEditing,
}) => {
	const ruleOption = getRewardRuleTypeOption(ruleType)
	const [balanceReward, setBalanceReward] = useState({
		amount: '0.00',
		currency: 'USD',
	})
	const [minReviews, setMinReviews] = useState(0)
	const [minStars, setMinStars] = useState(4)
	const [forEach, setForEach] = useState(0)
	const [ordersCount, setOrdersCount] = useState(0)
	const [minReferrals, setMinReferrals] = useState(0)
	const [minCompletedOrders, setMinCompletedOrders] = useState(0)
	const [currentRewardType, setCurrentRewardType] = useState<RewardType>(RewardType.BALANCE)
	const [editingReward, setEditingReward] = useState<RewardRule>()
	const [productsWithVariants, setProductsWithVariants] = useState<
		ProductWithVariantForProductRewardType[]
	>([
		{
			productId: 0,
			id: uniqid(),
			quantity: 0,
			variantId: 0,
		},
	])
	const [errors, setErrors] = useState<FormErrors>({
		minReviews: '',
		forEach: '',
		ordersCount: '',
		minReferrals: '',
		minCompletedOrders: '',
	})
	const { shop } = useAppContext()
	const [showErrorsDisplay, setShowErrorsDisplay] = useState(false)
	const errorMessages = Object.keys(errors)
		.map(key => errors[key])
		.filter(error => error)

	const [isCreateRewardLoading, , createReward] = useAPI(
		(data: CreateRewardRule) => RewardsService.create(shop?.id || '', data),
		false,
		'Reward rule successfully created',
		{
			onSuccess: () => {
				onClose()
				getRewards()
			},
		}
	)

	const [isUpdateRewardLoading, , updateReward] = useAPI(
		(data: CreateRewardRule) =>
			RewardsService.update(shop?.id || '', editingReward?.id, {
				...data,
				id: editingReward?.id,
			}),
		false,
		'Reward rule successfully updated',
		{
			onSuccess: () => {
				onClose()
				getRewards()
			},
		}
	)

	const createUpdateValidator = () => {
		const minReviewsValidator =
			ruleType === RewardRuleType.LEAVE_REVIEW
				? fieldValidators.blankValidator('Minimum reviews', minReviews)
				: ''
		const minOrdersValidator =
			ruleType === RewardRuleType.ORDER_COUNT
				? fieldValidators.blankValidator('Order limit', ordersCount)
				: ''
		const minReferralsValidator =
			ruleType === RewardRuleType.REFER_FRIEND
				? fieldValidators.blankValidator('Minimum referrals', minReferrals)
				: ''
		const forEachValidator =
			ruleType === RewardRuleType.SPEND_AMOUNT
				? fieldValidators.blankValidator('Spend limit', forEach)
				: ''

		setErrors({
			minReviews: minReviewsValidator,
			ordersCount: minOrdersValidator,
			minReferrals: minReferralsValidator,
			forEach: forEachValidator,
		})

		return !(minReviewsValidator || minReferralsValidator || minOrdersValidator || forEachValidator)
	}

	const onSaveHandler = () => {
		if (createUpdateValidator()) {
			const data: CreateRewardRule = {
				rewardOptions: {
					balance: currentRewardType === RewardType.BALANCE ? balanceReward : null,
					productsWithVariants:
						currentRewardType === RewardType.PRODUCT
							? productsWithVariants.map(product => ({
									id: product.productId,
									variantId: product.variantId,
									quantity: product.quantity,
							  }))
							: null,
				},
				rewardType: currentRewardType,
				ruleOptions: {
					minReviews: ruleType === RewardRuleType.LEAVE_REVIEW ? +minReviews : null,
					minStars: ruleType === RewardRuleType.LEAVE_REVIEW ? +minStars : null,
					forEach: ruleType === RewardRuleType.SPEND_AMOUNT ? +forEach : null,
					minReferrals: ruleType === RewardRuleType.REFER_FRIEND ? +minReferrals : null,
					ordersCount: ruleType === RewardRuleType.ORDER_COUNT ? +ordersCount : null,
					minCompletedOrders: ruleType === RewardRuleType.REFER_FRIEND ? +minCompletedOrders : null,
				},
				ruleType: ruleType,
				isEnabled: true,
			}

			if (isEditing) {
				updateReward(data)
			} else {
				createReward(data)
			}
		} else {
			setShowErrorsDisplay(true)
		}
	}

	useEffect(() => {
		if (isEditing) {
			const currentReward = rewards?.find(reward => reward.ruleType === ruleType)
			setEditingReward(currentReward)

			setForEach(currentReward?.ruleOptions.forEach || 0)
			setOrdersCount(currentReward?.ruleOptions.ordersCount || 0)
			setMinReferrals(currentReward?.ruleOptions.minReferrals || 0)
			setMinReviews(currentReward?.ruleOptions.minReviews || 0)
			setMinStars(currentReward?.ruleOptions.minStars || 0)
			setMinCompletedOrders(currentReward?.ruleOptions.minCompletedOrders || 0)
			setCurrentRewardType(currentReward?.rewardType || RewardType.BALANCE)
			setBalanceReward(
				currentReward?.rewardOptions.balance || {
					amount: '0.00',
					currency: 'USD',
				}
			)
			setProductsWithVariants(
				currentReward?.rewardOptions?.productsWithVariants?.map(product => ({
					id: uniqid(),
					productId: product.id,
					quantity: product.quantity,
					variantId: product.variantId,
				})) || [{ quantity: 0, variantId: 0, id: uniqid(), productId: 0 }]
			)
		}
	}, [])

	return (
		<div className={modalStyles.modalInner}>
			{/*{showErrorsDisplay && !!errorMessages.length && (*/}
			{/*	<FormErrorsDisplayBadge*/}
			{/*		title={`Reward rule Save Error`}*/}
			{/*		errors={errorMessages}*/}
			{/*		isModal={true}*/}
			{/*	/>*/}
			{/*)}*/}

			<header className={modalStyles.header}>
				<h1>{ruleOption.title}</h1>
			</header>

			<div className={modalStyles.body}>
				<p className={styles.description}>{ruleOption.description}</p>
				{ruleType === RewardRuleType.LEAVE_REVIEW && (
					<ReviewRuleForm
						minReviews={minReviews}
						minStars={minStars}
						setMinReviews={setMinReviews}
						setMinStars={setMinStars}
						setErrors={setErrors}
						errors={errors}
					/>
				)}
				{ruleType === RewardRuleType.REFER_FRIEND && (
					<ReferRuleForm
						minCompletedOrders={minCompletedOrders}
						minReferrals={minReferrals}
						setMinCompletedOrders={setMinCompletedOrders}
						setMinReferrals={setMinReferrals}
						errors={errors}
						setErrors={setErrors}
					/>
				)}
				{ruleType === RewardRuleType.ORDER_COUNT && (
					<OrdersRuleForm
						ordersCount={ordersCount}
						setOrdersCount={setOrdersCount}
						setErrors={setErrors}
						errors={errors}
					/>
				)}
				{ruleType === RewardRuleType.SPEND_AMOUNT && (
					<SpendRuleForm
						setForEach={setForEach}
						forEach={forEach}
						errors={errors}
						setErrors={setErrors}
					/>
				)}

				<RewardTypeForm
					balanceReward={balanceReward}
					setBalanceReward={setBalanceReward}
					currentReward={currentRewardType}
					setCurrentReward={setCurrentRewardType}
					productsWithVariants={productsWithVariants}
					setProductsWithVariants={setProductsWithVariants}
					shopId={shop?.id || ''}
				/>
			</div>

			<footer className={modalStyles.footer}>
				<div className={modalStyles.buttons}>
					<Button style={ButtonStyles.BORDERED} onClick={onClose} disableShadow={true}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'download',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						width={'100%'}
						disableShadow={true}
						onClick={onSaveHandler}
						isLoading={isCreateRewardLoading || isUpdateRewardLoading}
					>
						Save Rule
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default RewardRuleModal
