import { MutableRefObject, useEffect } from 'react'

export const useOutsideClick = (
	ref: MutableRefObject<any>,
	onClick: () => void,
	unbindEvent?: boolean
) => {
	useEffect(() => {
		function handleClickOutside(event: any) {
			if (ref.current && !ref.current.contains(event.target)) {
				onClick()
			}
		}

		document.addEventListener('mousedown', handleClickOutside)

		if (unbindEvent) {
			document.removeEventListener('mousedown', handleClickOutside)
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [ref, unbindEvent])
}
