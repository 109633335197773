import React, { FC, useState } from 'react'
import styles from './ProductVariantsDelivery.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../../components/UI/Button/Button'
import { VariantsDelivery } from '../../../../types/CreateVariants/VariantsDelivery'
import DeliveryProductStock from '../../../../components/DeliveryProductStock/DeliveryProductStock'
import Modal from '../../../../components/Modals/Modal'
import { closeModal } from '../../../../helpers/closeModal'
import ConfirmModal from '../../../../components/Modals/ConfirmModal/ConfirmModal'

interface ProductDeliveryProductProps {
	onAddClickHandler: () => void
	variantsDelivery: VariantsDelivery
	setVariantsDelivery: (value: VariantsDelivery) => void
	currentVariantIndex: string
	setEditingDeliveryConfigurationId: (value: string) => void
}

const ProductDeliveryProduct: FC<ProductDeliveryProductProps> = ({
	onAddClickHandler,
	setVariantsDelivery,
	variantsDelivery,
	currentVariantIndex,
	setEditingDeliveryConfigurationId,
}) => {
	const [isDeleteModalActive, setIsDeleteModalActive] = useState(false)
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
	const [deletingProductDeliveryId, setDeletingProductDeliveryId] = useState<string | null>(null)

	const closeDeleteModal = () => {
		closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)
		setDeletingProductDeliveryId(null)
	}

	const deleteProductDelivery = () => {
		if (deletingProductDeliveryId) {
			const newDeliveryConfiguration = variantsDelivery[currentVariantIndex].deliveryConfiguration
			delete newDeliveryConfiguration[deletingProductDeliveryId]
			setVariantsDelivery({
				...variantsDelivery,
				[currentVariantIndex]: {
					...variantsDelivery[currentVariantIndex],
					deliveryConfiguration: newDeliveryConfiguration,
				},
			})

			closeDeleteModal()
		}
	}

	return (
		<>
			{isDeleteModalActive && (
				<Modal
					closeModal={closeDeleteModal}
					visible={isDeleteModalVisible}
					setVisible={setIsDeleteModalVisible}
				>
					<ConfirmModal
						onConfirm={deleteProductDelivery}
						onClose={closeDeleteModal}
						description={
							'You are about to delete delivery product. Are you sure you want to delete this delivery product? This cannot be undone.'
						}
						title={'Delete Delivery Product'}
						confirmButtonText={'Delete'}
						buttonIcon={{
							id: 'trashBin',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
					/>
				</Modal>
			)}

			<div className={styles.productDeliveryProduct}>
				{Object.keys(variantsDelivery[currentVariantIndex].deliveryConfiguration).length > 0 && (
					<div className={styles.productDeliveryProductTable}>
						{Object.keys(variantsDelivery[currentVariantIndex].deliveryConfiguration).map(
							deliveryConfigurationKey => (
								<DeliveryProductStock
									key={deliveryConfigurationKey}
									customName={
										variantsDelivery[currentVariantIndex].deliveryConfiguration[
											deliveryConfigurationKey as any
										]?.customName
									}
									deliveryConfigurationType={
										variantsDelivery[currentVariantIndex].deliveryConfiguration[
											deliveryConfigurationKey as any
										].type
									}
									stock={
										variantsDelivery[currentVariantIndex].deliveryConfiguration[
											deliveryConfigurationKey as any
										].stock
									}
									onDeleteClick={() => {
										setDeletingProductDeliveryId(deliveryConfigurationKey)
										setIsDeleteModalActive(true)
									}}
									onEditClick={() => {
										setEditingDeliveryConfigurationId(deliveryConfigurationKey)
										onAddClickHandler()
									}}
								/>
							)
						)}
					</div>
				)}

				<div className={` ${styles.mtMediumLarge}`}>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'plus',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						width={'100%'}
						onClick={onAddClickHandler}
					>
						Add Deliverables
					</Button>
				</div>
			</div>
		</>
	)
}

export default ProductDeliveryProduct
