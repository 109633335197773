import React, { FC, useEffect, useState } from 'react'
import styles from '../Modals.module.scss'
import Switch from '../../UI/Switch/Switch'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { useAppContext } from '../../../hooks/useAppContext'
import { useAPI } from '../../../hooks/useAPI'
import { PaymentsSettingsService } from '../../../API/PaymentsSettingsService'
import PaymentType from '../../PaymentType/PaymentType'
import CustomSelect, { SelectStyles } from '../../UI/CustomSelect/CustomSelect'
import { BalanceSettings } from '../../../types/BalanceSettings/BalanceSettings'
import Checkbox from '../../UI/Checkbox/Checkbox'

const currencies = [
	'USD',
	'EUR',
	'JPY',
	'GBP',
	'AUD',
	'CAD',
	'CHF',
	'CNY',
	'SEK',
	'NZD',
	'PLN',
	'ILS',
	'HKD',
	'ISK',
	'PHP',
	'DKK',
	'HUF',
	'CZK',
	'RON',
	'IDR',
	'INR',
	'BRL',
	'RUB',
	'HRK',
	'THB',
	'MYR',
	'BGN',
	'NOK',
	'ZAR',
	'MXN',
	'SGD',
	'KRW',
]

interface ConfigureBalanceModalProps extends BaseModalProps {
	getPayments: () => void
	balanceSettings?: BalanceSettings
}

const ConfigureBalanceModal: FC<ConfigureBalanceModalProps> = ({
	onClose,
	getPayments,
	balanceSettings,
}) => {
	const [isEnableTopUp, setIsEnableTopUp] = useState(false)
	const [gateways, setGateways] = useState<string[]>([])
	const [defaultCurrency, setDefaultCurrency] = useState('USD')
	const [isEnabled, setIsEnabled] = useState(false)

	const { shop, availablePayments } = useAppContext()

	const [isUpdateBalanceLoading, , updateBalance] = useAPI(
		() =>
			PaymentsSettingsService.updateBalance(shop?.id || '', {
				gateways: isEnableTopUp ? gateways : null,
				defaultCurrency: defaultCurrency,
				isEnabled: isEnabled,
			}),
		false,
		'Successfully saved',
		{
			onSuccess: () => {
				onClose()
				getPayments()
			},
		}
	)

	useEffect(() => {
		if (balanceSettings) {
			setIsEnableTopUp(balanceSettings.gateways.length > 0)
			setGateways(balanceSettings.gateways)
			setDefaultCurrency(balanceSettings.defaultCurrency)
		}
	}, [])

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Configure Balance</h1>
			</header>

			<div className={styles.body}>
				<div className={styles.fieldWrapper}>
					<div className={`${styles.switchWrapper} ${styles.mt0}`}>
						<Switch checked={isEnableTopUp} setChecked={setIsEnableTopUp} />
						<p>Enable customer balance top-ups</p>
					</div>
				</div>
				{isEnableTopUp && (
					<div className={styles.fieldWrapper}>
						<div className={styles.twoColumns}>
							{availablePayments?.map(gateway => (
								<PaymentType
									checked={gateways.includes(gateway.name)}
									setChecked={checked => {
										if (checked) {
											setGateways([...gateways, gateway.name])
										} else {
											setGateways(gateways.filter(gatewayItem => gatewayItem !== gateway.name))
										}
									}}
									gateway={gateway}
								/>
							))}
						</div>
					</div>
				)}

				<div className={styles.fieldWrapper}>
					<h2>DEFAULT CURRENCY</h2>
					<CustomSelect
						style={SelectStyles.secondary}
						value={defaultCurrency}
						setValue={setDefaultCurrency}
						options={currencies.map(currency => ({ value: currency, label: currency }))}
					/>
				</div>

				<div className={styles.fieldWrapper}>
					<div className={styles.switchWrapper}>
						<Checkbox id={'isEnabled'} checked={isEnabled} setChecked={setIsEnabled} />
						<p>Enable for all products</p>
					</div>
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'download',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						isLoading={isUpdateBalanceLoading}
						onClick={updateBalance}
					>
						Save Settings
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default ConfigureBalanceModal
