import React, { FC } from 'react'
import styles from '../Modals.module.scss'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'

interface ConfirmModalProps extends BaseModalProps {
	title: string
	description: string
	onConfirm: () => void
	confirmButtonText: string
	isButtonLoading?: boolean
	buttonIcon?: {
		id: string
		width: number
		height: number
		align: ButtonsIconAlign
	}
}

const ConfirmModal: FC<ConfirmModalProps> = ({
	title,
	description,
	onClose,
	onConfirm,
	confirmButtonText,
	isButtonLoading,
	buttonIcon,
}) => {
	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>{title}</h1>
			</header>

			<div className={styles.body}>
				<p className={styles.description}>{description}</p>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={buttonIcon}
						disableShadow={true}
						onClick={onConfirm}
						isLoading={isButtonLoading}
					>
						{confirmButtonText || 'Confirm'}
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default ConfirmModal
