import React, { createContext, FC, ReactNode, useEffect, useState } from 'react'
import { AppContextType } from '../types/Context/AppContextType'
import { ActionMessage } from '../types/ActionMessage/ActionMessage'
import Spinner from '../components/UI/Spinner/Spinner'
import { Shop } from '../types/Shop/Shop'
import { User } from '../types/User/User'
import { AvailablePayment } from '../types/AvailablePayment/AvailablePayment'
import { Subscriptions } from '../types/Subscriptions/Subscriptions'

export const AppContext = createContext<AppContextType | null>(null)

const AppContextProvider: FC<{
	children: ReactNode
}> = ({ children }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [isSidebarActive, setIsSidebarActive] = useState(false)
	const [redirectAfterAuthPath, setRedirectAfterAuthPath] = useState<string | null>(null)
	const [actionMessage, setActionMessage] = useState<ActionMessage>({
		message: 'Unhandled error',
		title: 'Error',
		status: false,
		isActive: false,
	})
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const [shop, setShop] = useState<Shop | null>(null)
	const [user, setUser] = useState<User | null>(null)
	const [availablePayments, setAvailablePayments] = useState<AvailablePayment[] | null>(null)
	const [shopDomain, setShopDomain] = useState('')
	const [isSubscriptionModalActive, setIsSubscriptionModalActive] = useState(false)
	const [requiredSubscription, setRequiredSubscription] = useState<Subscriptions>(
		Subscriptions.CORE_PLUS
	)
	const [theme, setTheme] = useState('default')
	const [builderLink, setBuilderLink] = useState('')

	useEffect(() => {
		if (shop) {
			setShopDomain(`https://${shop.domain.replace('sellpass.io', 'billgang.store')}`)
			setBuilderLink(
				`https://builder.billgang.com/auth/${shop?.id}/${localStorage.getItem('accessToken')}`
			)
		}
	}, [shop])

	if (isLoading) return <Spinner />

	return (
		<AppContext.Provider
			value={{
				setActionMessage,
				actionMessage,
				isSidebarActive,
				setIsSidebarActive,
				windowWidth,
				shop,
				setShop,
				setUser,
				user,
				availablePayments,
				setAvailablePayments,
				redirectAfterAuthPath,
				setRedirectAfterAuthPath,
				shopDomain,
				setIsSubscriptionModalActive,
				isSubscriptionModalActive,
				requiredSubscription,
				setRequiredSubscription,
				builderLink,
				theme,
				setTheme,
			}}
		>
			{children}
		</AppContext.Provider>
	)
}

export default AppContextProvider
