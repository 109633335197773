import React, { FC } from 'react'
import styles from '../../OrderDetailedPage.module.scss'
import PartOrdersTable from '../../../../components/Tables/PartOrdersTable'
import { PartOrder } from '../../../../types/PartOrder/PartOrder'

interface OrdersProductDeliveredProps {
	partOrders: PartOrder[]
	onViewClick: (id: number) => void
	onReplacementClick: (id: number, quantity: number) => void
	isOrdersModifyAvailable: boolean
}

const OrdersProductDelivered: FC<OrdersProductDeliveredProps> = ({
	partOrders,
	onViewClick,
	onReplacementClick,
	isOrdersModifyAvailable,
}) => {
	return (
		<div className={styles.section}>
			<header className={styles.header}>
				<h1>Product Delivered</h1>
			</header>

			<PartOrdersTable
				partOrders={partOrders}
				onViewClick={onViewClick}
				onReplacementClick={onReplacementClick}
				isOrdersModifyAvailable={isOrdersModifyAvailable}
			/>
		</div>
	)
}

export default OrdersProductDelivered
