import React, { FC, useRef, useState } from 'react'
import styles from './Dropdown.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import { useOutsideClick } from '../../../hooks/useOutsideClick'

interface DropdownProps {
	options: {
		icon: {
			id: string
			width: number
			height: number
		}
		text: string
		onClick?: () => void
	}[]
	title: string
	style?: 'header' | 'headerNotActive'
}

const Dropdown: FC<DropdownProps> = ({ options, title, style }) => {
	const [isActive, setIsActive] = useState(false)
	const dropdownRef = useRef<HTMLDivElement>(null)
	useOutsideClick(dropdownRef, () => setIsActive(false), !isActive)

	return (
		<div
			className={`${styles.dropdown} ${isActive && styles.active} ${style && styles[style]}`}
			ref={dropdownRef}
		>
			<div className={styles.face} onClick={() => setIsActive(!isActive)}>
				{title} <SpriteIcon iconId={'arrowDown'} width={10} height={4} />
			</div>

			<div className={`${styles.dropdownMenu}`}>
				{options.map(option => (
					<div className={styles.dropdownItemWrapper}>
						<div className={styles.dropdownItem} onClick={option.onClick}>
							{option.text}
							<div className={styles.icon}>
								<SpriteIcon
									iconId={option.icon.id}
									width={option.icon.width}
									height={option.icon.height}
								/>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default Dropdown
