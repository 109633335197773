import React, { FC } from 'react'
import modalStyles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import JSONWrapper from '../../UI/JSONWrapper/JSONWrapper'

interface WebhookRequestModalProps extends BaseModalProps {
	json: JSON
}

const WebhookRequestModal: FC<WebhookRequestModalProps> = ({ json, onClose }) => {
	return (
		<div className={modalStyles.modalInner}>
			<header className={modalStyles.header}>
				<h1>Webhook Request</h1>
			</header>
			<div className={modalStyles.body}>
				<JSONWrapper json={json} />
			</div>
			<footer className={modalStyles.footer}>
				<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
					Cancel
				</Button>
			</footer>
		</div>
	)
}

export default WebhookRequestModal
