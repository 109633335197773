import React, { FC, useRef, useState } from 'react'
import styles from './Tables.module.scss'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import { format } from 'date-fns'
import { CustomPlugin } from '../../types/Plugin/CustomPlugin'
import PluginApprovalStatusBadge from '../UI/StatusBadges/PluginApprovalStatusBadge'
import { useNavigate } from 'react-router-dom'
import { PluginApprovalStatus } from '../../types/PluginApprovalStatus/PluginApprovalStatus'
import ReplyAnswerBadge from '../UI/ReplyAnswerBadge/ReplyAnswerBadge'
import { PluginVersion } from '../../types/Plugin/PluginVersion'

interface PluginVersionsTableProps {
	versions: PluginVersion[]
	onDeleteClick: (id: number) => void
	onViewManifestClick: (id: number) => void
	onActivateClick: (id: number) => void
}

interface PluginVersionsTableBodyItemProps {
	version: PluginVersion
	onDeleteClick: (id: number) => void
	onViewManifestClick: (id: number) => void
	onActivateClick: (id: number) => void
}

const PluginVersionsTableBodyItem: FC<PluginVersionsTableBodyItemProps> = ({
	onDeleteClick,
	version,
	onViewManifestClick,
	onActivateClick,
}) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)
	const tableBodyRef = useRef<HTMLDivElement>(null)

	const actionMenuButtons = [
		{
			onClick: () => onDeleteClick(version.id),
			icon: {
				id: 'trashBin',
				width: 13,
				height: 13,
			},
			title: 'Delete Version',
		},
		{
			onClick: () => onViewManifestClick(version.id),
			icon: {
				id: 'code',
				width: 13,
				height: 13,
			},
			title: 'View Manifest',
		},
		{
			onClick: () => onActivateClick(version.id),
			icon: {
				id: 'checked',
				width: 13,
				height: 13,
			},
			title: 'Set As Current Version',
		},
	]

	return (
		<div
			className={`${styles.trWrapper} ${isActionMenuActive && styles.actionMenuActive}`}
			style={{
				paddingBottom: isActionMenuActive ? +actionMenuButtons.length * 50 : 5,
			}}
			ref={tableBodyRef}
		>
			<tr>
				<td>
					<PluginApprovalStatusBadge status={version.approvalStatus} />
				</td>
				<td>
					{version.approvalNote ? (
						<ReplyAnswerBadge message={version.approvalNote} author={'— Billgang Team'} />
					) : (
						'—'
					)}
				</td>
				<td>
					{version.approvedAt ? (
						<>
							<p>{format(new Date(version.approvedAt), 'MMM dd, yyyy')}</p>
							<span>{format(new Date(version.approvedAt), 'hh:mm aa z')}</span>
						</>
					) : (
						version.approvalStatus.replaceAll('_', ' ')
					)}
				</td>
				<td>
					<p>{format(new Date(version.uploadedAt), 'MMM dd, yyyy')}</p>
					<span>{format(new Date(version.uploadedAt), 'hh:mm aa z')}</span>
				</td>
				<td className={styles.small}>
					<ActionMenu
						actionButtons={actionMenuButtons}
						isActive={isActionMenuActive}
						setIsActive={setIsActionMenuActive}
					/>
				</td>
			</tr>
		</div>
	)
}

const PluginVersionsTable: FC<PluginVersionsTableProps> = ({
	versions,
	onDeleteClick,
	onViewManifestClick,
	onActivateClick,
}) => {
	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th>status</th>
					<th>Note</th>
					<th>APPROVED DATE</th>
					<th>upload DATE</th>
					<th className={styles.small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{versions.map(category => (
					<PluginVersionsTableBodyItem
						version={category}
						onDeleteClick={onDeleteClick}
						onViewManifestClick={onViewManifestClick}
						onActivateClick={onActivateClick}
					/>
				))}
			</tbody>
		</table>
	)
}

export default PluginVersionsTable
