export const APIDocumentation: {
	[url: string]: {
		[method: string]: string
	}
} = {
	'/v1/dash/shops/{shopId}/affiliates': {
		GET: 'Retrieve affiliates information ',
	},
	'/v1/dash/shops/{shopId}/analytics/sales-charts': {
		GET: 'Retrieve sales charts analytics ',
	},
	'/v1/dash/shops/{shopId}/analytics/recent-actions': {
		GET: 'Retrieve recent actions analytics ',
	},
	'/v1/dash/shops/{shopId}/analytics/reviews-tickets-charts': {
		GET: 'Retrieve reviews and tickets charts analytics ',
	},
	'/v1/dash/shops/{shopId}/analytics/top-products-charts': {
		GET: 'Retrieve top products charts analytics ',
	},
	'/v1/dash/shops/{shopId}/analytics/visits': {
		GET: 'Retrieve visits analytics ',
	},
	'/v1/dash/shops/{shopId}/analytics/conversion-rates': {
		GET: 'Retrieve conversion rates analytics ',
	},
	'/v1/dash/shops/{shopId}/announcements': {
		GET: 'Retrieve announcements ',
		POST: 'Create a new announcement ',
	},
	'/v1/dash/shops/{shopId}/announcements/{id}': {
		GET: 'Retrieve a specific announcement ',
	},
	'/v1/dash/shops/{shopId}/announcements/{announcementId}': {
		PUT: 'Update a specific announcement ',
		DELETE: 'Delete a specific announcement ',
	},
	'/v1/dash/shops/{shopId}/audit-logs': {
		GET: 'Retrieve audit logs ',
	},
	'/v1/dash/shops/{shopId}/audit-logs/{auditLogId}': {
		GET: 'Retrieve a specific audit log',
	},
	'/v1/dash/auth/register/basic': {
		POST: 'Register a new user with basic authentication',
	},
	'/v1/dash/auth/login/basic': {
		POST: 'Log in with basic authentication',
	},
	'/v1/dash/auth/reset/basic': {
		POST: 'Request password reset with basic authentication',
	},
	'/v1/dash/auth/reset/basic/complete': {
		POST: 'Complete password reset process with basic authentication',
	},
	'/v1/dash/auth/login/google': {
		POST: 'Log in with Google authentication',
	},
	'/v1/dash/auth/login/discord': {
		POST: 'Log in with Discord authentication',
	},
	'/v1/dash/shops/{shopId}/products/bulk-update/custom-fields': {
		POST: 'Bulk update custom fields for products ',
	},
	'/v1/dash/shops/{shopId}/products/bulk-update/gateways': {
		POST: 'Bulk update gateways for products ',
	},
	'/v1/dash/shops/{shopId}/products/bulk-update/customer-note': {
		POST: 'Bulk update customer notes for products ',
	},
	'/v1/dash/shops/{shopId}/products/bulk-update/visibility': {
		POST: 'Bulk update visibility for products ',
	},
	'/v1/dash/shops/{shopId}/products/bulk-update/discord-integration': {
		POST: 'Bulk update Discord integration for products ',
	},
	'/v1/dash/shops/{shopId}/products/bulk-update/delete': {
		POST: 'Bulk delete products',
	},
	'/v1/dash/shops/{shopId}/categories': {
		GET: 'Retrieve categories ',
		POST: 'Create a new category ',
	},
	'/v1/dash/shops/{shopId}/categories/{id}': {
		GET: 'Retrieve a specific category ',
		PUT: 'Update a specific category ',
		DELETE: 'Delete a specific category ',
	},
	'/v1/dash/shops/{shopId}/charges': {
		GET: 'Retrieve charges ',
	},
	'/v1/dash/shops/{shopId}/charges/{id}': {
		GET: 'Retrieve a specific charge ',
	},
	'/v1/dash/shops/{shopId}/charges/{id}/mark-as-paid': {
		POST: 'Mark a charge as paid',
	},
	'/v1/dash/shops/{shopId}/coupons': {
		GET: 'Retrieve coupons ',
		POST: 'Create a new coupon ',
	},
	'/v1/dash/shops/{shopId}/coupons/{id}': {
		GET: 'Retrieve a specific coupon ',
		PUT: 'Update a specific coupon ',
		DELETE: 'Delete a specific coupon ',
	},
	'/v1/dash/shops/{shopId}/customers/stats': {
		GET: 'Retrieve customer statistics ',
	},
	'/v1/dash/shops/{shopId}/customers': {
		GET: 'Retrieve customers ',
	},
	'/v1/dash/shops/{shopId}/customers/{id}': {
		GET: 'Retrieve a specific customer ',
		PUT: 'Update a specific customer ',
	},
	'/v1/dash/shops/{shopId}/customers/{id}/note': {
		PUT: 'Update note for a specific customer',
	},
	'/v1/dash/shops/{shopId}/customers/{id}/balance/add': {
		POST: 'Add balance to a specific customer',
	},
	'/v1/dash/gateway-plugins/explorer': {
		GET: 'Explore gateway plugins',
	},
	'/v1/dash/gateway-plugins/me': {
		POST: 'Create a new gateway plugin for the user',
		GET: "Retrieve user's gateway plugins",
	},
	'/v1/dash/gateway-plugins/me/{pluginId}': {
		PUT: 'Update a specific gateway plugin for the user',
		DELETE: 'Delete a specific gateway plugin for the user',
		GET: 'Retrieve details of a specific gateway plugin for the user',
	},
	'/v1/dash/gateway-plugins/me/{pluginId}/versions/{pluginVersionId}/approve': {
		POST: 'Approve a specific version of a gateway plugin',
	},
	'/v1/dash/gateway-plugins/me/{pluginId}/versions': {
		GET: 'Retrieve versions of a gateway plugin',
	},
	'/v1/dash/gateway-plugins/me/{pluginId}/versions/{pluginVersionId}': {
		DELETE: 'Delete a specific version of a gateway plugin',
	},
	'/v1/dash/gateway-plugins/me/{pluginId}/versions/{pluginVersionId}/activate': {
		POST: 'Activate a specific version of a gateway plugin',
	},
	'/v1/dash/gateway-plugins/me/{pluginId}/versions/{pluginVersionId}/manifest': {
		GET: 'Retrieve manifest for a specific version of a gateway plugin',
	},
	'/v1/dash/shops/{shopId}/settings/developers/webhooks': {
		GET: 'Retrieve webhooks ',
		POST: 'Create a new webhook ',
	},
	'/v1/dash/shops/{shopId}/settings/developers/webhooks/reset-secret': {
		POST: 'Reset secret for webhooks ',
	},
	'/v1/dash/shops/{shopId}/settings/developers/webhooks/{webhookId}': {
		DELETE: 'Delete a specific webhook ',
	},
	'/v1/dash/shops/{shopId}/discord-clusters': {
		GET: 'Retrieve Discord clusters ',
	},
	'/v1/dash/shops/{shopId}/discord-clusters/{id}': {
		GET: 'Retrieve a specific Discord cluster ',
		POST: 'Mark a specific Discord cluster as paid',
	},
	'/v1/dash/user/discord/guilds': {
		GET: 'Retrieve Discord guilds for the user',
	},
	'/v1/dash/user/discord/guilds/{guildId}': {
		GET: 'Retrieve a specific Discord guild for the user',
	},
	'/v1/dash/shops/{shopId}/custom-domains': {
		GET: 'Retrieve custom domains ',
		POST: 'Create a new custom domain ',
	},
	'/v1/dash/shops/{shopId}/custom-domains/shortened': {
		POST: 'Enable/Disable shortened domain ',
	},
	'/v1/dash/shops/{shopId}/custom-domains/{domainId}': {
		DELETE: 'Delete a specific custom domain ',
	},
	'/v1/dash/shops/{shopId}/custom-domains/{domainId}/recheck': {
		POST: 'Recheck a specific custom domain ',
	},
	'/v1/dash/shops/{shopId}/faqs': {
		GET: 'Retrieve FAQs ',
		POST: 'Create a new FAQ ',
	},
	'/v1/dash/shops/{shopId}/faqs/{id}': {
		GET: 'Retrieve a specific FAQ ',
		PUT: 'Update a specific FAQ ',
		DELETE: 'Delete a specific FAQ ',
	},
	'/v1/dash/shops/{shopId}/fraud/rules': {
		GET: 'Retrieve fraud rules ',
		POST: 'Create a new fraud rule ',
		DELETE: 'Delete a specific fraud rule ',
	},
	'/v1/dash/shops/{shopId}/images': {
		GET: 'Retrieve images ',
		POST: 'Upload a new image ',
	},
	'/v1/dash/shops/{shopId}/images/{imageId}': {
		DELETE: 'Delete a specific image ',
	},
	'/v1/dash/shops/{shopId}/settings/integrations': {
		GET: 'Retrieve integration settings ',
	},
	'/v1/dash/shops/{shopId}/settings/integrations/trustpilot': {
		PUT: 'Update Trustpilot integration settings ',
	},
	'/v1/dash/shops/{shopId}/listings': {
		GET: 'Retrieve listings ',
	},
	'/v1/dash/shops/{shopId}/managers': {
		GET: 'Retrieve managers ',
	},
	'/v1/dash/shops/{shopId}/managers/{userId}': {
		PUT: 'Update manager permissions ',
		DELETE: 'Remove manager from a shop',
	},
	'/v1/dash/shops/{shopId}/managers/invites': {
		GET: 'Retrieve manager invites ',
		POST: 'Invite a manager to a shop',
	},
	'/v1/dash/shops/{shopId}/managers/invites/{inviteId}': {
		DELETE: 'Delete a manager invite ',
	},
	'/v1/dash/shops/{shopId}/settings/managers/info': {
		GET: 'Retrieve information about managers settings ',
	},
	'/v1/dash/shops/{shopId}/settings/managers/settings': {
		PUT: 'Update managers settings ',
	},
	'/v1/migrate/{apiKey}': {
		GET: 'Retrieve migrate data using provided API key',
		POST: 'Migrate data using provided API key',
	},
	'/v1/dash/shops/{shopId}/settings/notifications': {
		GET: 'Retrieve notification settings ',
		PUT: 'Update notification settings ',
	},
	'/v1/dash/shops/{shopId}/settings/notifications/discord-webhook': {
		PUT: 'Update Discord webhook for notification settings',
	},
	'/v1/dash/shops/{shopId}/orders': {
		GET: 'Retrieve orders ',
	},
	'/v1/dash/shops/{shopId}/orders/{id}': {
		GET: 'Retrieve a specific order ',
	},
	'/v1/dash/shops/{shopId}/orders/{id}/resend': {
		POST: 'Resend a specific order ',
	},
	'/v1/dash/shops/{shopId}/orders/{id}/refund': {
		POST: 'Refund a specific order ',
	},
	'/v1/dash/shops/{shopId}/orders/{orderId}/webhook-logs': {
		GET: 'Retrieve webhook logs for a specific order ',
	},
	'/v1/dash/shops/{shopId}/settings/payments/available-payments': {
		GET: 'Retrieve available payment methods ',
	},
	'/v1/dash/shops/{shopId}/settings/payments': {
		GET: 'Retrieve payment settings ',
	},
	'/v1/dash/shops/{shopId}/settings/payments/{gatewayName}': {
		PUT: 'Update payment settings for a specific gateway ',
	},
	'/v1/dash/shops/{shopId}/settings/payments/balance': {
		PUT: 'Update balance payment settings ',
	},
	'/v1/dash/shops/{shopId}/settings/payments/balance/rewards': {
		PUT: 'Update rewards settings for balance payments ',
		GET: 'Retrieve rewards settings for balance payments ',
	},
	'/v1/dash/shops/{shopId}/products': {
		GET: 'Retrieve products ',
		POST: 'Create a new product ',
	},
	'/v1/dash/shops/{shopId}/products/{productId}': {
		PUT: 'Update a specific product ',
		GET: 'Retrieve a specific product ',
		DELETE: 'Delete a specific product ',
	},
	'/v1/dash/shops/{shopId}/reviews/stats': {
		GET: 'Retrieve review statistics ',
	},
	'/v1/dash/shops/{shopId}/reviews': {
		GET: 'Retrieve reviews ',
	},
	'/v1/dash/shops/{shopId}/reviews/{id}': {
		GET: 'Retrieve a specific review ',
	},
	'/v1/dash/shops/{shopId}/reviews/{id}/answer': {
		POST: 'Answer a specific review ',
	},
	'/v1/dash/shops/{shopId}/reviews/{id}/appeal': {
		POST: 'Appeal a specific review ',
	},
	'/v1/dash/shops/{shopId}/rewards': {
		GET: 'Retrieve rewards ',
		POST: 'Create a new reward ',
	},
	'/v1/dash/shops/{shopId}/rewards/{rewardRuleId}': {
		PUT: 'Update a specific reward ',
	},
	'/v1/dash/shops/{shopId}/rewards/referral-system': {
		PUT: 'Update referral system settings for rewards ',
	},
	'/v1/dash/shops/{shopId}/settings/company': {
		GET: 'Retrieve company settings ',
	},
	'/v1/dash/shops/{shopId}/settings/subdomain': {
		PUT: 'Update subdomain settings ',
	},
	'/v1/dash/shops/{shopId}/settings/name': {
		PUT: 'Update shop name ',
	},
	'/v1/dash/shops/{shopId}/settings/company-info': {
		PUT: 'Update company information settings ',
	},
	'/v1/dash/shops/{shopId}/settings/website-settings': {
		PUT: 'Update website settings ',
	},
	'/v1/dash/shops/{shopId}/settings/timezone-and-currency': {
		PUT: 'Update timezone and currency settings ',
	},
	'/v1/dash/shops/{shopId}/settings/automated-reviews': {
		PUT: 'Update automated reviews settings ',
	},
	'/v1/dash/shops/{shopId}/settings/post-purchase-request': {
		PUT: 'Update post-purchase request settings ',
	},
	'/v1/dash/shops/{shopId}/settings/customer-accounts': {
		PUT: 'Update customer accounts settings ',
	},
	'/v1/dash/shops/{shopId}/settings/legal/terms-of-service': {
		PUT: 'Update terms of service ',
	},
	'/v1/dash/shops/{shopId}/settings/legal/refund-policy': {
		PUT: 'Update refund policy ',
	},
	'/v1/dash/shops/{shopId}/settings/legal/privacy-policy': {
		PUT: 'Update privacy policy ',
	},
	'/v1/dash/shops/{shopId}/settings/legal': {
		GET: 'Retrieve legal settings ',
	},
	'/v1/dash/shops/{shopId}/settings/logo': {
		POST: 'Upload a logo ',
	},
	'/v1/dash/shops': {
		POST: 'Create a new shop',
	},
	'/v1/dash/shops/{shopId}/warehouses': {
		GET: 'Retrieve warehouses ',
		POST: 'Create a new warehouse ',
	},
	'/v1/dash/shops/{shopId}/warehouses/{warehouseId}': {
		GET: 'Retrieve a specific warehouse ',
		PUT: 'Update a specific warehouse ',
		DELETE: 'Delete a specific warehouse ',
	},
	'/v1/dash/shops/{shopId}/warehouses/{warehouseId}/stock': {
		GET: 'Retrieve stock for a specific warehouse ',
	},
	'/v1/dash/shops/{shopId}/warehouses/{warehouseId}/stock/raw': {
		GET: 'Retrieve raw stock data for a specific warehouse ',
	},
	'/v1/dash/shops/{shopId}/warehouses/{warehouseId}/add-stock': {
		POST: 'Add stock to a specific warehouse ',
	},
	'/v1/dash/shops/{shopId}/warehouses/{warehouseId}/edit-stock': {
		POST: 'Edit stock in a specific warehouse ',
	},
	'/v1/dash/shops/{shopId}/warehouses/{warehouseId}/delete-stock': {
		POST: 'Delete stock from a specific warehouse ',
	},
	'/v1/dash/stripe/exchange-code': {
		POST: 'Exchange code with Stripe',
	},
	'/v1/dash/subscription/purchase': {
		POST: 'Purchase a subscription',
	},
	'/v1/dash/shops/{shopId}/tickets': {
		GET: 'Retrieve tickets ',
	},
	'/v1/dash/shops/{shopId}/tickets/{id}': {
		GET: 'Retrieve a specific ticket ',
	},
	'/v1/dash/shops/{shopId}/tickets/{ticketId}/answer': {
		POST: 'Answer a specific ticket ',
	},
	'/v1/dash/shops/{shopId}/tickets/{ticketId}/close': {
		POST: 'Close a specific ticket ',
	},
	'/v1/dash/user': {
		GET: 'Retrieve user information',
	},
	'/v1/dash/user/logout': {
		POST: 'Logout user',
	},
	'/v1/dash/user/complete-email-verification': {
		POST: 'Complete email verification',
	},
	'/v1/dash/user/resend-email-verification': {
		POST: 'Resend email verification',
	},
	'/v1/dash/user/invites/accept': {
		POST: 'Accept user invite',
	},
	'/v1/dash/user/security': {
		GET: 'Retrieve user security settings',
	},
	'/v1/dash/user/security/discord/connect': {
		POST: 'Connect Discord for user security',
	},
	'/v1/dash/user/security/google/connect': {
		POST: 'Connect Google for user security',
	},
	'/v1/dash/user/security/tfa/providers/google/challenge/start': {
		POST: 'Start Google two-factor authentication challenge',
	},
	'/v1/dash/user/security/tfa/providers/google/challenge/complete': {
		POST: 'Complete Google two-factor authentication challenge',
	},
	'/v1/dash/user/security/tfa/providers/google/disable': {
		POST: 'Disable Google two-factor authentication',
	},
	'/v1/dash/user/security/email/change': {
		POST: 'Change user email',
	},
	'/v1/dash/user/security/basic/change': {
		POST: 'Change basic user security settings',
	},
	'/v1/dash/user/security/sessions': {
		GET: 'Retrieve user security sessions',
	},
	'/v1/dash/user/security/sessions/{sessionId}/invalidate': {
		POST: 'Invalidate specific user security session',
	},
	'/v1/dash/user/security/sessions/invalidate': {
		POST: 'Invalidate all user security sessions',
	},
	'/v1/dash/user/settings': {
		GET: 'Retrieve user settings',
	},
	'/v1/dash/user/settings/logout': {
		POST: 'Logout from user settings',
	},
	'/v1/dash/user/settings/developer/api-keys': {
		GET: 'Retrieve developer API keys',
		POST: 'Create developer API key',
	},
	'/v1/dash/user/settings/developer/api-keys/{apiKeyId}': {
		DELETE: 'Delete specific developer API key',
	},
	'/v1/dash/shops/{shopId}/external/webhooks/{externalMessageId}/{externalEndpointId}': {
		GET: 'Retrieve external webhooks ',
	},
	'/v1/dash/shops/{shopId}/webhooks/logs': {
		GET: 'Retrieve webhook logs ',
	},
}

const asome = {
	'/v1/dash/shops/{shopId}/orders/{id}/resend': {
		POST: 'Resend a specific order ',
	},
	'/v1/dash/shops/{shopId}/orders/{id}/refund': {
		POST: 'Refund a specific order ',
	},
	'/v1/dash/shops/orders/{orderId}/webhook-logs': {
		GET: 'Retrieve webhook logs for a specific order ',
	},
	'/v1/dash/shops/{shopId}/images/{imageId}': {
		DELETE: 'Delete a specific image ',
	},
	'/v1/dash/shops/{shopId}/settings/integrations': {
		GET: 'Retrieve integration settings ',
	},
	'/v1/dash/shops/{shopId}/settings/integrations/trustpilot': {
		PUT: 'Update Trustpilot integration settings ',
	},
	'/v1/dash/shops/{shopId}/listings': {
		GET: 'Retrieve listings ',
	},
	'/v1/dash/shops/{shopId}/managers': {
		GET: 'Retrieve managers ',
	},
	'/v1/dash/auth/login/basic': {
		POST: 'Log in with basic authentication',
	},
	'/v1/dash/shops/{shopId}/faqs/{id}': {
		GET: 'Retrieve a specific FAQ ',
		PUT: 'Update a specific FAQ ',
		DELETE: 'Delete a specific FAQ ',
	},
	'/v1/dash/user/security/email/change': {
		POST: 'Change user email',
	},
	'/v1/dash/user/security/basic/change': {
		POST: 'Change basic user security settings',
	},
	'/v1/dash/user/security/sessions': {
		GET: 'Retrieve user security sessions',
	},
	'/v1/dash/user/security/sessions/{sessionId}/invalidate': {
		POST: 'Invalidate specific user security session',
	},
	//...
}
