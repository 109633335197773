import { privateAPI } from './API'
import { ProductCreate } from '../types/Product/ProductCreate'

export const ProductsService = {
	getProducts: async (
		shopId: string,
		searchString?: string,
		PageNumber?: number,
		PageSize?: number
	) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/products`, {
			params: {
				searchString,
				PageNumber,
				PageSize,
			},
		})
	},
	createProduct: async (shopId: string, data: ProductCreate) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/products`, data)
	},
	updateProduct: async (shopId: string, productId: number, data: ProductCreate) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/products/${productId}`, data)
	},
	getProduct: async (shopId: string, productId: number) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/products/${productId}`)
	},
	deleteProduct: async (shopId: string, productId: number) => {
		return await privateAPI.delete(`/v1/dash/shops/${shopId}/products/${productId}`)
	},
}
