import React, { useEffect } from 'react'
import styles from './Settings.module.scss'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageHeader from '../../components/PageHeader/PageHeader'
import { NavLink, Route, Routes, useLocation } from 'react-router-dom'
import SettingsGeneralPage from './SettingsGeneralPage/SettingsGeneralPage'
import SettingsTeamPage from './SettingsTeamPage/SettingsTeamPage'
import SettingsPaymentsPage from './SettingsPaymentsPage/SettingsPaymentsPage'
import SettingsDesignPage from './SettingsDesignPage/SettingsDesignPage'
import SettingsNotificationsPage from './SettingsNotificationsPage/SettingsNotificationsPage'
import SettingsMigratePage, { MigratingStep } from './SettingsMigratePage/SettingsMigratePage'
import SettingsDomainsPage from './SettingsDomainsPage/SettingsDomainsPage'
import SettingsSecurityPage from './SettingsSecurityPage/SettingsSecurityPage'
import SettingsDevicesPage from './SettingsDevicesPage/SettingsDevicesPage'
import SettingsBillingPage from './SettingsBillingPage/SettingsBillingPage'
import DiscordConnectPage from './Connects/DiscordConnectPage'
import StripeConnectPage from './Connects/StripeConnectPage'
import GoogleConnectPage from './Connects/GoogleConnectPage'
import { SpriteIcon } from '../../components/UI/SpriteIcon/SpriteIcon'
import SettingsIntegrationsPage from './SettingsIntegrationsPage/SettingsIntegrationsPage'
import SettingsAccountPage from './SettingsAccountPage/SettingsAccountPage'
import SettingsLegalPage from './SettingsLegalPage/SettingsLegalPage'
import SettingsAuditLogPage from './SettingsAuditLogPage/SettingsAuditLogPage'
import { usePageTitle } from '../../hooks/usePageTitle'
import { useAPI } from '../../hooks/useAPI'
import { GetMigration } from '../../types/GetMigration/GetMigration'
import { MigrationService } from '../../API/MigrationService'
import { useAppContext } from '../../hooks/useAppContext'

const SettingsPage = () => {
	const location = useLocation()
	const { shop } = useAppContext()
	usePageTitle(`Settings`)

	const [, migrationData, getMigrationData] = useAPI<GetMigration>(
		() => MigrationService.getMigration(shop?.id || ''),
		false,
		undefined
	)

	const navLinks = [
		{
			title: 'Company',
			path: '/settings/general',
		},
		{
			title: 'Team',
			path: '/settings/team',
		},
		{
			title: 'Payments',
			path: '/settings/payments',
		},
		{
			title: 'Integrations',
			path: '/settings/integrations',
		},
		{
			title: 'Account',
			path: '/settings/account',
		},
		{
			title: 'Legal',
			path: '/settings/legal',
		},
		{
			title: 'Notifications',
			path: '/settings/notifications',
		},
		{
			title: 'Audit Log',
			path: '/settings/audit-log',
		},
		{
			title: 'Domains',
			path: '/settings/domains',
		},
		// {
		// 	title: 'Security',
		// 	path: '/settings/security',
		// },
		// {
		// 	title: 'Devices',
		// 	path: '/settings/devices',
		// },
		{
			title: 'Billing',
			path: '/settings/billing',
		},
	]

	if (!migrationData?.isMigrated) {
		navLinks.splice(8, 0, {
			title: 'Migrate',
			path: '/settings/migrate',
		})
	}

	useEffect(() => {
		if (shop) {
			getMigrationData()
		}
	}, [shop])

	return (
		<PageContainer>
			<PageWrapper isNotRequired>
				<div className={styles.settingsPage}>
					<PageHeader title={'Settings'} />

					<div className={styles.settingsPageInner}>
						<div className={styles.navbar}>
							{navLinks.map(navLink => (
								<NavLink
									to={navLink.path}
									className={`${styles.navItem} ${
										navLink.path === location.pathname && styles.active
									} `}
									key={navLink.path}
								>
									{navLink.title}
									<SpriteIcon
										className={styles.arrowRight}
										iconId={'arrowRight'}
										width={12}
										height={10}
									/>
								</NavLink>
							))}
						</div>

						<Routes>
							<Route path={'general'} element={<SettingsGeneralPage />} />
							<Route path={'team'} element={<SettingsTeamPage />} />
							<Route path={'payments'} element={<SettingsPaymentsPage />} />
							<Route path={'integrations'} element={<SettingsIntegrationsPage />} />
							<Route path={'account'} element={<SettingsAccountPage />} />
							<Route path={'legal'} element={<SettingsLegalPage />} />
							<Route path={'design'} element={<SettingsDesignPage />} />
							<Route path={'notifications'} element={<SettingsNotificationsPage />} />
							<Route path={'migrate'} element={<SettingsMigratePage />} />
							<Route path={'domains'} element={<SettingsDomainsPage />} />
							<Route path={'security'} element={<SettingsSecurityPage />} />
							<Route path={'devices'} element={<SettingsDevicesPage />} />
							<Route path={'billing'} element={<SettingsBillingPage />} />
							<Route path={'audit-log'} element={<SettingsAuditLogPage />} />
							<Route path={'connects/discord'} element={<DiscordConnectPage />} />
							<Route path={'connects/google'} element={<GoogleConnectPage />} />
							<Route path={'connects/stripe'} element={<StripeConnectPage />} />
						</Routes>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default SettingsPage
