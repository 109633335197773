import React, { FC } from 'react'
import styles from './DeliveryProductType.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { DeliveryProductType } from '../../types/DeliveryProductType/DeliveryProductType'
import OrangeBadge from '../UI/OrangeBadge/OrangeBadge'
import { DeliveryConfigurationType } from '../../types/DeliveryConfigurationType/DeliveryConfigurationType'
import { productDeliverablesOptions } from '../../helpers/productDeliverablesOptions'

interface DeliveryProductTypeComponentProps {
	deliveryConfigurationType: DeliveryConfigurationType
	onClick: () => void
	isActive: boolean
}

const DeliveryProductTypeComponent: FC<DeliveryProductTypeComponentProps> = ({
	deliveryConfigurationType,
	isActive,
	onClick,
}) => {
	return (
		<div className={`${styles.deliveryProductType} ${isActive && styles.active}`} onClick={onClick}>
			<div className={styles.image}>
				<SpriteIcon
					iconId={productDeliverablesOptions[deliveryConfigurationType].icon.id}
					width={productDeliverablesOptions[deliveryConfigurationType].icon.width}
					height={productDeliverablesOptions[deliveryConfigurationType].icon.height}
				/>
			</div>
			<div>
				<h2>
					{productDeliverablesOptions[deliveryConfigurationType].title}{' '}
					{deliveryConfigurationType === DeliveryConfigurationType.TELEGRAM && (
						<SpriteIcon iconId={'comingSoon'} width={70} height={20} />
					)}
				</h2>
				<p>{productDeliverablesOptions[deliveryConfigurationType].description}</p>
			</div>
		</div>
	)
}

export default DeliveryProductTypeComponent
