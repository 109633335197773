import React, { FC, useEffect, useState } from 'react'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import styles from './ConnectDiscord.module.scss'

interface ConnectDiscordProps {
	style?: 'secondary'
	isConnected?: boolean
}

const ConnectDiscord: FC<ConnectDiscordProps> = ({ style, isConnected }) => {
	const [newWindow, setNewWindow] = useState<Window | null>(null)

	useEffect(() => {
		if (newWindow) {
			const Interval = setInterval(() => {
				if (newWindow.closed) {
					clearInterval(Interval)
				}
			}, 500)
		}
	}, [newWindow])

	return (
		<div className={`${styles.connectDiscord} ${style && styles[style]}`}>
			<Button
				width={'100%'}
				style={ButtonStyles.BORDERED}
				disableShadow={true}
				icon={{
					id: 'discordFlex',
					height: 11,
					width: 15,
					align: ButtonsIconAlign.LEFT,
					className: styles.discord,
				}}
				onClick={() => {
					setNewWindow(
						window.open(
							`https://discord.com/api/oauth2/authorize?client_id=1032724229172510740&redirect_uri=${
								window.location.hostname === 'localhost'
									? 'http%3A%2F%2Flocalhost%3A3000'
									: 'https%3A%2F%2Fdash.billgang.com'
							}%2Fsettings%2Fconnects%2Fdiscord&response_type=code&scope=identify%20guilds%20email%20guilds.join`,
							'_blank',
							'width=480, height=720'
						)
					)
				}}
			>
				{isConnected ? 'Reconnect Discord' : 'Connect Discord'}
			</Button>
		</div>
	)
}

export default ConnectDiscord
