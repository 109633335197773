import React, { useEffect, useState } from 'react'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import styles from '../Settings.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../components/UI/Button/Button'
import { useAPI } from '../../../hooks/useAPI'
import { UserSecurityService } from '../../../API/UserSecurityService'
import { SecuritySettings } from '../../../types/SecuritySettings/SecuritySettings'
import PageLoading from '../../../components/UI/PageLoading/PageLoading'
import Input from '../../../components/UI/FormComponents/Input'
import RadioWrapper from '../../../components/UI/RadioWrapper/RadioWrapper'
import { TrustpilotRequestType } from '../../../types/TrustpilotRequestType/TrustpilotRequestType'
import { IntegrationsSettingsService } from '../../../API/IntegrationsSettingsService'
import { useAppContext } from '../../../hooks/useAppContext'
import { GetIntegrations } from '../../../types/GetIntegrations/GetIntegrations'
import { ShopForUserPermissions } from '../../../types/ShopForUserPermissions/ShopForUserPermissions'
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent'
import pageNotFoundImage from '../../../assets/images/404.png'

const SettingsIntegrationsPage = () => {
	const [newWindow, setNewWindow] = useState<Window | null>(null)
	const [trustpilotEmail, setTrustpilotEmail] = useState('')
	const [trustpilotType, setTrustpilotType] = useState<TrustpilotRequestType>(
		TrustpilotRequestType.AFTER_ORDER
	)
	const { shop } = useAppContext()
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isSettingsGeneralViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.SETTINGS_GENERAL_VIEW) || isAdmin
	const isSettingsGeneralModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.SETTINGS_GENERAL_MODIFY) || isAdmin

	const [isGetSecuritySettingsLoading, securitySettings, getSecuritySettings] =
		useAPI<SecuritySettings>(() => UserSecurityService.getSecurity(), false)

	const [isGetIntegrationsLoading, integrations, getIntegrations] = useAPI<GetIntegrations>(
		() => IntegrationsSettingsService.getIntegrations(shop?.id || ''),
		false,
		undefined,
		{
			onSuccess: response => {
				if (response.data.data.trustpilot) {
					setTrustpilotType(response.data.data.trustpilot.type)
					setTrustpilotEmail(response.data.data.trustpilot.bccEmail)
				}
			},
		}
	)

	const [isUpdateTrustpilotLoading, , updateTrustpilot] = useAPI(
		() =>
			IntegrationsSettingsService.updateTrustpilot(shop?.id || '', {
				type: trustpilotType,
				bccEmail: trustpilotEmail,
			}),
		false,
		'Trustpilot settings successfully saved',
		{
			onSuccess: () => {
				getIntegrations()
			},
		}
	)

	useEffect(() => {
		if (newWindow) {
			const Interval = setInterval(() => {
				if (newWindow.closed) {
					clearInterval(Interval)
				}
			}, 500)
		}
	}, [newWindow])

	useEffect(() => {
		if (shop && isSettingsGeneralViewAvailable) {
			getSecuritySettings()
			getIntegrations()
		}
	}, [shop])

	if (isGetSecuritySettingsLoading || isGetIntegrationsLoading) return <PageLoading />

	if (!isSettingsGeneralViewAvailable)
		return (
			<NoDataComponent
				isSmallHeight={true}
				imageSrc={pageNotFoundImage}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)

	return (
		<PageWrapper>
			<div className={styles.settingsPageInnerBody}>
				<div className={styles.settingSection}>
					<header className={styles.settingSectionHeader}>
						<h1 className={styles.settingSectionTitle}>Discord Integration</h1>
					</header>
					<div className={styles.settingSectionBody}>
						<div className={styles.fieldWrapper}>
							<p className={styles.fieldWrapperTitle}>
								Automatically invite members to your Discord servers.
							</p>
							{isSettingsGeneralModifyAvailable && (
								<div className={styles.formWrapper}>
									<Button
										style={ButtonStyles.BORDERED}
										width={'100%'}
										disableShadow={true}
										icon={{
											id: 'discord',
											width: 16,
											height: 12,
											align: ButtonsIconAlign.LEFT,
										}}
										onClick={() =>
											setNewWindow(
												window.open(
													`https://discord.com/api/oauth2/authorize?client_id=1032724229172510740&redirect_uri=${
														window.location.hostname === 'localhost'
															? 'http%3A%2F%2Flocalhost%3A3000'
															: 'https%3A%2F%2Fdash.billgang.com'
													}%2Fsettings%2Fconnects%2Fdiscord&response_type=code&scope=identify%20guilds%20email%20guilds.join`,
													'_blank',
													'width=480, height=720'
												)
											)
										}
									>
										{securitySettings?.authDetails.discordOauth
											? 'Reconnect Discord'
											: 'Connect Discord'}
									</Button>

									{securitySettings?.authDetails.discordOauth?.username && (
										<p className={styles.fieldWrapperDescription}>
											Connected Account: {securitySettings?.authDetails.discordOauth?.username}
										</p>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
				{/*<div className={styles.settingSection}>*/}
				{/*	<header className={styles.settingSectionHeader}>*/}
				{/*		<h1 className={styles.settingSectionTitle}>*/}
				{/*			Telegram Integration <SpriteIcon iconId={'comingSoon'} width={70} height={20} />*/}
				{/*		</h1>*/}
				{/*	</header>*/}
				{/*	<div className={styles.settingSectionBody}>*/}
				{/*		<div className={styles.fieldWrapper}>*/}
				{/*			<p className={styles.fieldWrapperTitle}>*/}
				{/*				Grant members access to your Telegram group.*/}
				{/*			</p>*/}
				{/*			<div className={styles.formWrapper}>*/}
				{/*				<Button*/}
				{/*					style={ButtonStyles.BORDERED}*/}
				{/*					width={'100%'}*/}
				{/*					icon={{*/}
				{/*						id: 'blueTelegram',*/}
				{/*						width: 16,*/}
				{/*						height: 14,*/}
				{/*						align: ButtonsIconAlign.LEFT,*/}
				{/*					}}*/}
				{/*					disableShadow={true}*/}
				{/*				>*/}
				{/*					Connect Telegram*/}
				{/*				</Button>*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*</div>*/}
				{/*<div className={styles.settingSection}>*/}
				{/*	<header className={styles.settingSectionHeader}>*/}
				{/*		<h1 className={styles.settingSectionTitle}>Google Authentication</h1>*/}
				{/*	</header>*/}
				{/*	<div className={styles.settingSectionBody}>*/}
				{/*		<div className={styles.fieldWrapper}>*/}
				{/*			<p className={styles.fieldWrapperTitle}>*/}
				{/*				Use your Google account to sign in to your Billgang account.*/}
				{/*			</p>*/}
				{/*			<div className={styles.formWrapper}>*/}
				{/*				<Button*/}
				{/*					style={ButtonStyles.BORDERED}*/}
				{/*					width={'100%'}*/}
				{/*					icon={{*/}
				{/*						id: 'google',*/}
				{/*						width: 15,*/}
				{/*						height: 15,*/}
				{/*						align: ButtonsIconAlign.LEFT,*/}
				{/*					}}*/}
				{/*					disableShadow={true}*/}
				{/*					onClick={() =>*/}
				{/*						setNewWindow(*/}
				{/*							window.open(*/}
				{/*								`https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=${*/}
				{/*									window.location.hostname === 'localhost'*/}
				{/*										? 'http%3A%2F%2Flocalhost%3A3000'*/}
				{/*										: 'https%3A%2F%2Fdash.billgang.com'*/}
				{/*								}%2Fsettings%2Fconnects%2Fgoogle&prompt=consent&response_type=code&client_id=165295676705-d81fbtjp5tn4uni6mljmssjp96ll01tq.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline&flowName=GeneralOAuthFlow`,*/}
				{/*								'_blank',*/}
				{/*								'width=480, height=720'*/}
				{/*							)*/}
				{/*						)*/}
				{/*					}*/}
				{/*				>*/}
				{/*					Connect Google*/}
				{/*				</Button>*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*</div>*/}

				<div className={styles.settingSection}>
					<header className={styles.settingSectionHeader}>
						<h1 className={styles.settingSectionTitle}>Trustpilot Integration</h1>
					</header>
					<div className={styles.settingSectionBody}>
						<p>
							Automatically send customer emails for requests to leave a review or testimonial on
							your Trustpilot page once an order has successfully been completed.
						</p>
						<div className={styles.mtMediumLarge}>
							<div className={styles.fieldWrapper}>
								<p className={styles.fieldWrapperTitle}>Invitation Email</p>
								<div className={styles.formWrapper}>
									<Input
										icon={{
											id: 'trustpilot',
											width: 16,
											height: 15,
										}}
										value={trustpilotEmail}
										setValue={setTrustpilotEmail}
										placeholder={'yourwebsite.com+code123@invite.trustpilot.com'}
									/>
								</div>
							</div>
						</div>

						<p className={styles.mtMediumLarge}>
							To find your Trustpilot invitation email,{' '}
							<a
								className={styles.anchorLink}
								href='https://support.trustpilot.com/hc/en-us/articles/213703667-How-to-use-Automatic-Feedback-Service'
								target={'_blank'}
							>
								visit this page.
							</a>
						</p>

						<div className={styles.mtMediumLarge}>
							<div className={styles.twoColumns}>
								<RadioWrapper
									id={'completedOrder'}
									value={TrustpilotRequestType.AFTER_ORDER}
									setValue={value => setTrustpilotType(value as TrustpilotRequestType)}
									checked={trustpilotType === TrustpilotRequestType.AFTER_ORDER}
									title={'COMPLETED ORDER'}
									description={'Only send the request after the order is completed.'}
								/>
								<RadioWrapper
									id={'closetTicket'}
									value={TrustpilotRequestType.AFTER_TICKET}
									setValue={value => setTrustpilotType(value as TrustpilotRequestType)}
									checked={trustpilotType === TrustpilotRequestType.AFTER_TICKET}
									title={'CLOSED TICKET'}
									description={'Only send the request after the customer support ticket is closed.'}
								/>
							</div>
						</div>
						{isSettingsGeneralModifyAvailable &&
							(trustpilotType !==
								(integrations?.trustpilot?.type || TrustpilotRequestType.AFTER_ORDER) ||
								trustpilotEmail !== (integrations?.trustpilot?.bccEmail || '')) && (
								<div className={styles.mtMediumLarge}>
									<Button
										style={ButtonStyles.BORDERED}
										icon={{
											id: 'download',
											width: 13,
											height: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										disableShadow={true}
										onClick={updateTrustpilot}
										isLoading={isUpdateTrustpilotLoading}
									>
										Save Settings
									</Button>
								</div>
							)}
					</div>
				</div>

				{/*<div className={styles.settingSection}>*/}
				{/*	<header className={styles.settingSectionHeader}>*/}
				{/*		<h1 className={styles.settingSectionTitle}>Checkout Tracking</h1>*/}
				{/*	</header>*/}
				{/*	<div className={styles.settingSectionBody}>*/}
				{/*		<div className={styles.fieldWrapper}>*/}
				{/*			<p className={styles.fieldWrapperTitle}>Google Analytics ID</p>*/}
				{/*			<div className={styles.formWrapper}>*/}
				{/*				<Input value={''} setValue={() => {}} placeholder={'GA-00000000'} />*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*		<div className={styles.mtMediumLarge}>*/}
				{/*			<div className={styles.fieldWrapper}>*/}
				{/*				<p className={styles.fieldWrapperTitle}>TikTok Pixel ID</p>*/}
				{/*				<div className={styles.formWrapper}>*/}
				{/*					<Input value={''} setValue={() => {}} placeholder={'TK-00000000'} />*/}
				{/*				</div>*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*		<div className={styles.mtMediumLarge}>*/}
				{/*			<div className={styles.fieldWrapper}>*/}
				{/*				<p className={styles.fieldWrapperTitle}>Meta Pixel ID</p>*/}
				{/*				<div className={styles.formWrapper}>*/}
				{/*					<Input value={''} setValue={() => {}} placeholder={'FB-00000000'} />*/}
				{/*				</div>*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*		<div className={styles.mtMediumLarge}>*/}
				{/*			<div className={styles.fieldWrapper}>*/}
				{/*				<p className={styles.fieldWrapperTitle}>Reddit Pixel ID</p>*/}
				{/*				<div className={styles.formWrapper}>*/}
				{/*					<Input value={''} setValue={() => {}} placeholder={'RD-00000000'} />*/}
				{/*				</div>*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*		<div className={styles.mtMediumLarge}>*/}
				{/*			<Button*/}
				{/*				style={ButtonStyles.BORDERED}*/}
				{/*				icon={{*/}
				{/*					id: 'download',*/}
				{/*					width: 13,*/}
				{/*					height: 13,*/}
				{/*					align: ButtonsIconAlign.LEFT,*/}
				{/*				}}*/}
				{/*				disableShadow={true}*/}
				{/*			>*/}
				{/*				Save Settings*/}
				{/*			</Button>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*</div>*/}
			</div>
		</PageWrapper>
	)
}

export default SettingsIntegrationsPage
