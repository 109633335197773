import React from 'react'
import styles from './Tables.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import OrangeBadge from '../UI/OrangeBadge/OrangeBadge'

const DevicesTable = () => {
	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.large}>DEVICE</th>
					<th>LOGIN METHOD</th>
					<th>LOCATION</th>
					<th>LAST LOGIN</th>
					<th className={styles.small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				<div className={styles.trWrapper}>
					<tr>
						<td className={styles.large}>
							<div className={styles.itemInfo}>
								<div className={styles.itemImage}>
									<SpriteIcon iconId={'monitor'} width={15} height={15} />
								</div>
								<div>
									<p>Chrome</p>
									<span>MacOS</span>
								</div>
							</div>
						</td>
						<td>Password</td>
						<td>Los Angeles, California</td>
						<td>2 days ago</td>
						<td className={styles.small}>
							<SpriteIcon iconId={'ellipsis'} width={10} height={4} />
						</td>
					</tr>
				</div>
				<div className={styles.trWrapper}>
					<tr>
						<td className={styles.large}>
							<div className={styles.itemInfo}>
								<div className={styles.itemImage}>
									<SpriteIcon iconId={'smartphone'} width={15} height={15} />
								</div>
								<div>
									<p>Chrome</p>
									<span>MacOS</span>
								</div>
							</div>
						</td>
						<td>Password</td>
						<td>Los Angeles, California</td>
						<td>2 days ago</td>
						<td className={styles.small}>
							<SpriteIcon iconId={'ellipsis'} width={10} height={4} />
						</td>
					</tr>
				</div>
			</tbody>
		</table>
	)
}

export default DevicesTable
