import React, { useEffect, useState } from 'react'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import styles from './PluginDetailPage.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import { useAPI } from '../../hooks/useAPI'
import { CustomGatewaysService } from '../../API/CustomGatewaysService'
import PageTableHeader from '../../components/PageTableHeader/PageTableHeader'
import Modal from '../../components/Modals/Modal'
import { closeModal } from '../../helpers/closeModal'
import Spinner from '../../components/UI/Spinner/Spinner'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import { useDebounce } from '../../hooks/useDebounce'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import { useParams } from 'react-router-dom'
import PluginVersionsTable from '../../components/Tables/PluginVersionsTable'
import { PluginVersion } from '../../types/Plugin/PluginVersion'
import ViewPluginVersionManifestModal from '../../components/Modals/ViewPluginManifestModal/ViewPluginVersionManifestModal'
import CreateCustomGatewayModal from '../../components/Modals/CreateCustomGatewayModal/CreateCustomGatewayModal'
import { CustomPlugin } from '../../types/Plugin/CustomPlugin'
import { PluginApprovalStatus } from '../../types/PluginApprovalStatus/PluginApprovalStatus'
import { format } from 'date-fns'
import Pagination from '../../components/UI/Pagination/Pagination'
import { usePageTitle } from '../../hooks/usePageTitle'

const PluginDetailPage = () => {
	const [search, setSearch] = useState('')
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [totalItems, setTotalItems] = useState(0)
	const [isCreateModalActive, setIsCreateModalActive] = useState(false)
	const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
	const [isDeleteModalActive, setIsDeleteModalActive] = useState(false)
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
	const [isViewManifestModalActive, setIsViewManifestModalActive] = useState(false)
	const [isViewManifestModalVisible, setIsViewManifestModalVisible] = useState(false)
	const [isActivateVersionModalActive, setIsActivateVersionModalActive] = useState(false)
	const [isActivateVersionModalVisible, setIsActivateVersionModalVisible] = useState(false)
	const [statusFilter, setStatusFilter] = useState<PluginApprovalStatus | null>()
	const params = useParams()
	const pluginId = params.id
	usePageTitle(`Plugin Detailed`)

	const [usingVersionId, setUsingVersionId] = useState<number | null>(null)

	const [isGetPluginVersionsLoading, versions, getPluginVersions] = useAPI<PluginVersion[]>(
		() =>
			CustomGatewaysService.getVersions(Number(pluginId), currentPage + 1, pageSize, statusFilter),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	const [isGetEditingPluginLoading, editingPlugin, getEditingPlugin] = useAPI<CustomPlugin>(
		pluginId => CustomGatewaysService.getOne(pluginId),
		false
	)

	const [isDeleteVersionLoading, , deleteVersion] = useAPI(
		() => CustomGatewaysService.deleteVersion(Number(pluginId), Number(usingVersionId)),
		false,
		'Plugin version successfully deleted',
		{
			onSuccess: () => {
				closeDeleteModal()
				getPluginVersions()
			},
		}
	)

	const [isActivateVersionLoading, , activateVersion] = useAPI(
		() => CustomGatewaysService.activateVersion(Number(pluginId), Number(usingVersionId)),
		false,
		'Plugin version successfully set as current',
		{
			onSuccess: () => {
				closeActivateModal()
				getPluginVersions()
			},
		}
	)

	useDebounce(search, getPluginVersions, 400)
	const isFirstLoading = useFirstLoading(isGetEditingPluginLoading)

	const onDeleteVersionClick = (id: number) => {
		setUsingVersionId(id)
		setIsDeleteModalActive(true)
	}

	const onViewPluginManifestClick = (id: number) => {
		setUsingVersionId(id)
		setIsViewManifestModalActive(true)
	}

	const onActivateVersionClick = (id: number) => {
		setUsingVersionId(id)
		setIsActivateVersionModalActive(true)
	}
	const closeDeleteModal = () => {
		closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)
		setUsingVersionId(null)
	}

	const closeCreateModal = () => {
		closeModal(setIsCreateModalActive, setIsCreateModalVisible)
		setUsingVersionId(null)
	}

	const closeActivateModal = () => {
		closeModal(setIsActivateVersionModalActive, setIsActivateVersionModalVisible)
		setUsingVersionId(null)
	}

	useEffect(() => {
		getPluginVersions()
	}, [currentPage, pageSize, statusFilter])

	useEffect(() => {
		if (pluginId) {
			getEditingPlugin(pluginId)
		}
	}, [])

	if (isFirstLoading) return <PageLoading />

	return (
		<PageWrapper>
			{isCreateModalActive && (
				<Modal
					closeModal={closeCreateModal}
					visible={isCreateModalVisible}
					setVisible={setIsCreateModalVisible}
				>
					<CreateCustomGatewayModal
						onClose={closeCreateModal}
						getPlugins={getPluginVersions}
						editingPluginId={pluginId ? +pluginId : null}
					/>
				</Modal>
			)}
			{isViewManifestModalActive && (
				<Modal
					closeModal={() => closeModal(setIsViewManifestModalActive, setIsViewManifestModalVisible)}
					visible={isViewManifestModalVisible}
					setVisible={setIsViewManifestModalVisible}
				>
					<ViewPluginVersionManifestModal
						onClose={() => closeModal(setIsViewManifestModalActive, setIsViewManifestModalVisible)}
						pluginId={pluginId ? +pluginId : null}
						pluginVersionId={usingVersionId}
					/>
				</Modal>
			)}
			{isDeleteModalActive && (
				<Modal
					closeModal={closeDeleteModal}
					visible={isDeleteModalVisible}
					setVisible={setIsDeleteModalVisible}
				>
					<ConfirmModal
						onClose={closeDeleteModal}
						description={
							'You are about to delete plugin version. Are you sure you want to delete this plugin version? This cannot be undone.'
						}
						title={'Delete Plugin'}
						onConfirm={deleteVersion}
						confirmButtonText={'Delete Version'}
						buttonIcon={{
							id: 'trashBin',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						isButtonLoading={isDeleteVersionLoading}
					/>
				</Modal>
			)}
			{isActivateVersionModalActive && (
				<Modal
					closeModal={closeActivateModal}
					visible={isActivateVersionModalVisible}
					setVisible={setIsActivateVersionModalVisible}
				>
					<ConfirmModal
						onClose={closeActivateModal}
						description={`You're about to activate the plugin version uploaded on ${
							usingVersionId &&
							format(
								new Date(
									versions?.find(version => version.id === usingVersionId)?.uploadedAt || ''
								),
								`MMMM dd, yyyy 'at' hh:mm aa z`
							)
						}. Are you sure you want to activate this version?`}
						title={'Activate Plugin'}
						onConfirm={activateVersion}
						confirmButtonText={'Activate Plugin'}
						buttonIcon={{
							id: 'checkedCircle',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						isButtonLoading={isActivateVersionLoading}
					/>
				</Modal>
			)}
			<PageContainer>
				<div className={styles.pluginsPage}>
					<PageHeader title={`${editingPlugin?.displayName}: Plugin Versions`}>
						<div className={styles.headerEnd}>
							<div className={`${styles.filterButton} ${statusFilter === null && styles.active}`}>
								<Button style={ButtonStyles.SECONDARY} onClick={() => setStatusFilter(null)}>
									All
								</Button>
							</div>
							<div
								className={`${styles.filterButton} ${
									statusFilter === PluginApprovalStatus.ACCEPTED && styles.active
								}`}
							>
								<Button
									style={ButtonStyles.SECONDARY}
									onClick={() => setStatusFilter(PluginApprovalStatus.ACCEPTED)}
								>
									Approved
								</Button>
							</div>
							<div
								className={`${styles.filterButton} ${
									statusFilter === PluginApprovalStatus.AWAITING_REVIEW && styles.active
								}`}
							>
								<Button
									style={ButtonStyles.SECONDARY}
									onClick={() => setStatusFilter(PluginApprovalStatus.AWAITING_REVIEW)}
								>
									Pending
								</Button>
							</div>
						</div>
					</PageHeader>

					<PageTableHeader
						listItemNamePlural={'plugins'}
						totalItems={totalItems}
						pageSize={pageSize}
						setPageSize={setPageSize}
						button={{
							icon: {
								id: 'plus',
								height: 13,
								width: 13,
								align: ButtonsIconAlign.LEFT,
							},
							onClick: () => setIsCreateModalActive(true),
							style: ButtonStyles.LIGHT,
							text: 'Upload new version',
						}}
						setCurrentPage={setCurrentPage}
					/>
					{isGetPluginVersionsLoading ? (
						<Spinner />
					) : (
						versions && (
							<div className={styles.tableWrapper}>
								<PluginVersionsTable
									versions={versions}
									onDeleteClick={onDeleteVersionClick}
									onViewManifestClick={onViewPluginManifestClick}
									onActivateClick={onActivateVersionClick}
								/>
							</div>
						)
					)}

					<div className={styles.paginationWrapper}>
						<Pagination
							withoutPageSizeSelector={true}
							totalItems={totalItems}
							pageSize={pageSize}
							setPageSize={setPageSize}
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
						/>
					</div>
				</div>
			</PageContainer>
		</PageWrapper>
	)
}

export default PluginDetailPage
