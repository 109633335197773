import { GetRecentActions } from '../types/GetRecentActions/GetRecentActions'
import { NotificationEvent } from '../types/NotificationEvent/NotificationEvent'
import getSymbolFromCurrency from 'currency-symbol-map'
import { formatNumberWithDecimalsAndCommas } from './formatNumberWithDecimalsAndCommas'

export const getRecentActionOption = (recentAction: GetRecentActions) => {
	const price = `${getSymbolFromCurrency(
		recentAction.arguments.price?.currency || 'USD'
	)}${formatNumberWithDecimalsAndCommas(Number(recentAction.arguments.price?.amount), 2)}`

	switch (recentAction.event) {
		case NotificationEvent.CHARGE_CREATED:
			return {
				text: `A new charge of ${price} has been created by ${recentAction.arguments.customerEmail}.`,
				link: '/finances/orders',
				emoji: '🛒',
			}
		case NotificationEvent.ORDER_CREATED:
			return {
				text: `An order of ${price} has been created for ${recentAction.arguments?.productName}.`,
				link: `/finances/orders/${recentAction.arguments.id}`,
				emoji: '🛒',
			}
		case NotificationEvent.CHARGE_PAID:
			return {
				text: `A charge of ${price} by ${recentAction.arguments.customerEmail} has been processed with ${recentAction.arguments.gateway}.`,
				link: '/finances/orders',
				emoji: '🤑',
			}
		case NotificationEvent.ORDER_COMPLETED:
			return {
				text: `An order of ${price} for ${recentAction.arguments.productName} has been processed with ${recentAction.arguments.gateway}.`,
				link: '/finances/orders',
				emoji: '🤑',
			}
		case NotificationEvent.ORDER_FULL_DELIVERY_FAILURE:
			return {
				text: `${recentAction.arguments.customerEmail}’s order was not successful. You must manually deliver the remainder of this product to your customer.`,
				link: '/finances/orders',
				emoji: '🚫',
			}
		case NotificationEvent.ORDER_PARTIALLY_DELIVERED:
			return {
				text: `${recentAction.arguments.customerEmail}’s order is not complete. You must manually deliver the remainder of this product to your customer.`,
				link: '/finances/orders',
				emoji: '😢',
			}
		case NotificationEvent.WAREHOUSE_OUT_OF_STOCK:
			return {
				text: `${recentAction.arguments.name} is currently out of stock.`,
				link: `/products/warehouse/stock-groups/${recentAction.arguments.id}`,
				emoji: '🚫',
			}
		case NotificationEvent.WAREHOUSE_LOW_STOCK:
			return {
				text: `${recentAction.arguments.name} currently has ${recentAction.arguments.stock} stock, we recommend adding more stock.`,
				link: `/products/warehouse/stock-groups/${recentAction.arguments.id}`,
				emoji: '😢',
			}
		case NotificationEvent.REVIEW_CREATED:
			return {
				text: `You’ve received a new review of ${recentAction.arguments.stars}/5 from ${recentAction.arguments.customerEmail}.`,
				link: `/operations/reviews/${recentAction.arguments.id}`,
				emoji: '⭐',
			}
		case NotificationEvent.REVIEW_EDITED:
			return {
				text: `${recentAction.arguments.customerEmail} edited their review to ${recentAction.arguments.stars}/5 stars.`,
				link: `/operations/reviews/${recentAction.arguments.id}`,
				emoji: '⭐',
			}
		case NotificationEvent.REVIEW_APPEALED:
			return {
				text: `A negative review from ${recentAction.arguments.customerEmail} has been removed.`,
				link: `/operations/reviews`,
				emoji: '⭐',
			}
		case NotificationEvent.TICKET_CREATED:
			return {
				text: `${recentAction.arguments.customerEmail} has opened a new ticket for ${recentAction.arguments.title}.`,
				link: `/operations/tickets/inbox`,
				emoji: '💬',
			}
		case NotificationEvent.TICKET_MESSAGE_RECEIVED:
			return {
				text: `You’ve received a new response from ${recentAction.arguments.customerEmail} for ${recentAction.arguments.title}.`,
				link: `/operations/tickets/inbox`,
				emoji: '💬',
			}
	}
}
