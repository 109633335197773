import React, { FC } from 'react'
import styles from '../../OrderDetailedPage.module.scss'
import StatusBadge from '../../../../components/UI/StatusBadge/StatusBadge'
import { SpriteIcon } from '../../../../components/UI/SpriteIcon/SpriteIcon'
import { CashAppOrderCharge } from '../../../../types/CashAppOrderCharge/CashAppOrderCharge'
import { OrderCharge } from '../../../../types/OrderCharge/OrderCharge'

interface OrdersCashAppDetailsProps {
	orderCharge: OrderCharge
}

const OrdersChargeDetails: FC<OrdersCashAppDetailsProps> = ({ orderCharge }) => {
	return (
		<div className={styles.section}>
			<header className={styles.header}>
				<h1>{orderCharge.gateway} Payment Details</h1>
			</header>

			<div className={styles.titleValueGrid}>
				{orderCharge.customCharge &&
					Object.keys(orderCharge.customCharge.lifetimeStorage).map(key => (
						<div className={styles.titleValueWrapper}>
							<h2>{key}</h2>
							<p>{orderCharge.customCharge?.lifetimeStorage[key]}</p>
						</div>
					))}
			</div>
		</div>
	)
}

export default OrdersChargeDetails
