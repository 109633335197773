import React, { FC, useRef, useState } from 'react'
import styles from './Tables.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { NavLink, useNavigate } from 'react-router-dom'
import Checkbox from '../UI/Checkbox/Checkbox'
import ProductTypeBadge from '../UI/ProductTypeBadge/ProductTypeBadge'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import { Category } from '../../types/Category/Category'
import { format } from 'date-fns'
import { ActionMenuOption } from '../../types/ActionMenuOption/ActionMenuOption'

interface CategoriesTableProps {
	categories: Category[]
	onDeleteClick: (id: number) => void
	isCategoriesDeleteAvailable: boolean
	isCategoriesModifyAvailable: boolean
}

interface CategoriesTableBodyProps {
	category: Category
	onDeleteClick: (id: number) => void
	isCategoriesDeleteAvailable: boolean
	isCategoriesModifyAvailable: boolean
}

const CategoriesTableBody: FC<CategoriesTableBodyProps> = ({
	onDeleteClick,
	category,
	isCategoriesModifyAvailable,
	isCategoriesDeleteAvailable,
}) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)
	const tableBodyRef = useRef<HTMLAnchorElement>(null)
	const navigate = useNavigate()

	const actionMenuButtons: ActionMenuOption[] = []

	isCategoriesModifyAvailable &&
		actionMenuButtons.push({
			onClick: () => navigate(`/products/categories/edit/${category.id}`),
			icon: {
				id: 'pen',
				width: 12,
				height: 12,
			},
			title: 'Edit category',
		})

	isCategoriesDeleteAvailable &&
		actionMenuButtons.push({
			onClick: () => onDeleteClick(category.id),
			icon: {
				id: 'trashBin',
				width: 12,
				height: 12,
			},
			title: 'Delete category',
		})

	return (
		<NavLink
			className={`${styles.trWrapper} ${isActionMenuActive && styles.actionMenuActive}`}
			to={`/products/categories/edit/${category.id}`}
			style={{
				paddingBottom: isActionMenuActive ? +actionMenuButtons.length * 50 : 5,
			}}
			ref={tableBodyRef}
		>
			<tr>
				<td className={styles.large}>
					<div className={styles.itemInfo}>
						<div>
							<p>{category.name}</p>
							<span>ID: {category.id}</span>
						</div>
					</div>
				</td>
				<td>{category.listingsCount}</td>
				<td>
					<p>{format(new Date(category.updatedAt), 'MMM dd, yyyy')}</p>
					<span>{format(new Date(category.updatedAt), 'hh:mm aa z')}</span>
				</td>
				<td className={styles.small}>
					<ActionMenu
						actionButtons={actionMenuButtons}
						isActive={isActionMenuActive}
						setIsActive={setIsActionMenuActive}
					/>
				</td>
			</tr>
		</NavLink>
	)
}

const CategoriesTable: FC<CategoriesTableProps> = ({
	categories,
	onDeleteClick,
	isCategoriesModifyAvailable,
	isCategoriesDeleteAvailable,
}) => {
	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.large}>Category</th>
					<th>Products</th>
					<th>Last Updated</th>
					<th className={styles.small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{categories.map(category => (
					<CategoriesTableBody
						isCategoriesDeleteAvailable={isCategoriesDeleteAvailable}
						isCategoriesModifyAvailable={isCategoriesModifyAvailable}
						category={category}
						onDeleteClick={onDeleteClick}
						key={category.id}
					/>
				))}
			</tbody>
		</table>
	)
}

export default CategoriesTable
