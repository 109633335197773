import React, { FC } from 'react'
import styles from './ProductAdvisor.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { CreateProductSectionIds } from '../../types/CreateProductSectionIds/CreateProductSectionIds'
import { scrollIntoViewAndFocus } from '../../helpers/scrollIntoViewAndFocus'
import { Image } from '../../types/Image/Image'

interface ProductAdvisorSEOProps {
	percent: number
	setCurrenSectionId: (value: CreateProductSectionIds) => void
	metaTitle: string
	metaImage: Image | null
	metaDescription: string
}

const ProductAdvisorSEO: FC<ProductAdvisorSEOProps> = ({
	setCurrenSectionId,
	percent,
	metaDescription,
	metaTitle,
	metaImage,
}) => {
	const onBackClickHandler = () => {
		setCurrenSectionId(CreateProductSectionIds.VARIANTS)
		scrollIntoViewAndFocus(CreateProductSectionIds.VARIANTS)
	}

	return (
		<div className={styles.productAdvisor}>
			<header className={styles.header}>
				{/*<div className={styles.criticalAlert}>*/}
				{/*	<SpriteIcon iconId={'exclamationMarkTriangle'} width={16} height={16} />*/}
				{/*	<p>1 Critical Alert</p>*/}
				{/*</div>*/}
				<h2 className={styles.title}>Product Advisor</h2>
				<p className={styles.progressPercent}>{percent}%</p>

				<div className={styles.progressBar}>
					<div
						className={styles.progressBarActiveLine}
						style={{
							width: `${percent}%`,
						}}
					/>
					<div
						className={styles.progressBarWillActiveLine}
						style={{
							width: `${
								percent +
								(!metaDescription ? 10 : 0) +
								(!metaTitle ? 10 : 0) +
								(!metaImage ? 10 : 0)
							}%`,
						}}
					/>
				</div>

				<p className={styles.description}>
					Customize your page title and descriptions to fit your unique business identity.
				</p>
			</header>

			<div className={styles.body}>
				<h2 className={styles.title}>Advanced Optimization</h2>

				<div className={`${styles.advices} `}>
					<div
						className={`${styles.adviceItem} ${metaTitle && styles.done}`}
						onClick={() => scrollIntoViewAndFocus('productMetaTitle')}
					>
						<div>
							<h3 className={styles.adviceTitle}>Page Title</h3>
							<p className={styles.adviceDescription}>Customize your product page title.</p>
						</div>

						<div className={styles.advicePercent}>+10%</div>
						<SpriteIcon
							className={styles.checked}
							iconId={'checkedCircle'}
							width={15}
							height={15}
						/>
					</div>
					<div
						className={`${styles.adviceItem} ${metaDescription && styles.done}`}
						onClick={() => scrollIntoViewAndFocus('productMetaDescription')}
					>
						<div>
							<h3 className={styles.adviceTitle}>Page Description</h3>
							<p className={styles.adviceDescription}>Add a custom SEO optimized description.</p>
						</div>

						<div className={styles.advicePercent}>+10%</div>
						<SpriteIcon
							className={styles.checked}
							iconId={'checkedCircle'}
							width={15}
							height={15}
						/>
					</div>

					<div
						className={`${styles.adviceItem} ${metaImage && styles.done}`}
						onClick={() => scrollIntoViewAndFocus('productMetaImage')}
					>
						<div>
							<h3 className={styles.adviceTitle}>Preview Image</h3>
							<p className={styles.adviceDescription}>Select an image to appear in links.</p>
						</div>

						<div className={styles.advicePercent}>+10%</div>
						<SpriteIcon
							className={styles.checked}
							iconId={'checkedCircle'}
							width={15}
							height={15}
						/>
					</div>
				</div>

				<footer className={styles.footer}>
					<Button
						style={ButtonStyles.BORDERED}
						disableShadow={true}
						width={'100%'}
						icon={{
							id: 'arrowLeft',
							height: 12,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						onClick={onBackClickHandler}
					>
						Back
					</Button>
				</footer>
			</div>
		</div>
	)
}

export default ProductAdvisorSEO
