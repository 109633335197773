import { privateAPI } from './API'

export const UserSettingsDeveloperService = {
	getApiKeys: async () => {
		return await privateAPI.get('/v1/dash/user/settings/developer/api-keys')
	},
	createApiKey: async (data: { name: string; expiresInTicks: number | null }) => {
		return await privateAPI.post('/v1/dash/user/settings/developer/api-keys', data)
	},
	deleteApiKey: async (apiKeyId: number) => {
		return await privateAPI.delete(`/v1/dash/user/settings/developer/api-keys/${apiKeyId}`)
	},
}
