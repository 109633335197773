import { privateAPI } from './API'
import { AnnouncementCreate } from '../types/Announcement/AnnouncementCreate'

export const MigrationService = {
	getMigration: async (shopId: string) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/migration`)
	},
	createMigration: async (
		shopId: string,
		data: {
			fromShopDomain: string
			apiKey: string
			migrateProducts: boolean
			migrateAnnouncements: boolean
			migrateBlacklists: boolean
			migrateCategories: boolean
			migrateGroups: boolean
			migrateOrders: boolean
			migrateTickets: boolean
			migrateFaqs: boolean
		}
	) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/migration/migrate`, data)
	},
	createSubscriptionMigration: async (
		data: {
			apiKey: string
		}
	) => {
		return await privateAPI.post(`/v1/dash/shops/shop/migration/migrate-subscription`, data)
	}
}
