import { DomainStatus } from '../../../types/DomainStatus/DomainStatus'
import React, { FC } from 'react'
import { DiscordClusterBotStatus } from '../../../types/DiscordClusterBotStatus/DiscordClusterBotStatus'
import styles from './StatusBadges.module.scss'

interface DiscordBotStatusProps {
	status: DiscordClusterBotStatus
}

const DiscordBotStatus: FC<DiscordBotStatusProps> = ({status}) => {
	const discordBotStatusOptions = {
		[DiscordClusterBotStatus.ONLINE]: {
			name: 'Online',
			className: 'green',
		},
		[DiscordClusterBotStatus.OFFLINE]: {
			name: 'Offline',
			className: 'gray',
		},
		[DiscordClusterBotStatus.AWAY]: {
			name: 'Idle',
			className: 'brown',
		},
		[DiscordClusterBotStatus.DO_NOT_DISTURB]: {
			name: 'Do Not Disturb',
			className: 'red',
		},
	}

	return (
		<div className={`${styles.statusBadge} ${styles[discordBotStatusOptions[status].className]}`}>
			{discordBotStatusOptions[status].name}
		</div>
	)
}

export default DiscordBotStatus