import React, { useState } from 'react'
import styles from './ShopProfile.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import BackgroundOverlay from '../UI/BackgroundOverlay/BackgroundOverlay'
import { useAppContext } from '../../hooks/useAppContext'
import { useAPI } from '../../hooks/useAPI'
import { UserService } from '../../API/UserService'
import { NavLink } from 'react-router-dom'

const ShopProfile = () => {
	const [isActive, setIsActive] = useState(false)
	const { shop: currentShop, user, shopDomain } = useAppContext()

	const [, , logout] = useAPI(() => UserService.logout(), false, 'Successfully signed out', {
		onSuccess: () => {
			localStorage.removeItem('accessToken')
			localStorage.removeItem('shopId')
			window.location.pathname = '/auth/sign-in'
		},
	})

	const closeShopProfile = () => {
		setIsActive(false)
	}

	return (
		<>
			<BackgroundOverlay isActive={isActive} onClick={() => setIsActive(false)} />
			<div className={`${styles.shopProfile} ${isActive && styles.active}`}>
				<div className={`${styles.menu} ${isActive && styles.active}`}>
					<a
						href={shopDomain}
						target={'_blank'}
						className={styles.menuItem}
						onClick={closeShopProfile}
					>
						<SpriteIcon
							className={styles.selectIcon}
							iconId={'blankWindow'}
							width={13}
							height={13}
						/>
						<p>View your website</p>
					</a>
					<NavLink to={'/settings/general'} className={styles.menuItem} onClick={closeShopProfile}>
						<SpriteIcon className={styles.selectIcon} iconId={'gear'} width={13} height={13} />
						<p>Edit settings</p>
					</NavLink>
					<NavLink to={'/settings/billing'} className={styles.menuItem} onClick={closeShopProfile}>
						<SpriteIcon className={styles.selectIcon} iconId={'person'} width={13} height={13} />
						<p>Manage subscription</p>
					</NavLink>
					<div className={styles.menuItem} onClick={logout}>
						<SpriteIcon className={styles.selectIcon} iconId={'exit'} width={13} height={13} />
						<p>Sign out</p>
					</div>
				</div>

				<div className={styles.front} onClick={() => setIsActive(!isActive)}>
					<div className={styles.iconWrapper}>
						<SpriteIcon iconId={'person2'} width={13} height={13} />
					</div>
					<p>{user?.email}</p>
				</div>
			</div>
		</>
	)
}

export default ShopProfile
