import { privateAPI } from './API'
import { BulkUpdatesProductsCustomFields } from '../types/BulkUpdateProducts/BulkUpdatesProductsCustomFields'
import { BulkUpdatesProductsGateways } from '../types/BulkUpdateProducts/BulkUpdatesProductsGateways'
import { BulkUpdatesProductsVisibility } from '../types/BulkUpdateProducts/BulkUpdatesProductsVisibility'
import { BulkUpdatesProductsCustomerNote } from '../types/BulkUpdateProducts/BulkUpdatesProductsCustomerNote'
import { BulkUpdatesProductsDiscordIntegration } from '../types/BulkUpdateProducts/BulkUpdatesProductsDiscordIntegration'

export const BulkUpdateProductsService = {
	updateCustomFields: async (shopId: string, data: BulkUpdatesProductsCustomFields) => {
		return await privateAPI.post(
			`/v1/dash/shops/${shopId}/products/bulk-update/custom-fields`,
			data
		)
	},
	updateGateways: async (shopId: string, data: BulkUpdatesProductsGateways) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/products/bulk-update/gateways`, data)
	},
	updateVisibility: async (shopId: string, data: BulkUpdatesProductsVisibility) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/products/bulk-update/visibility`, data)
	},
	updateCustomerNote: async (shopId: string, data: BulkUpdatesProductsCustomerNote) => {
		return await privateAPI.post(
			`/v1/dash/shops/${shopId}/products/bulk-update/customer-note`,
			data
		)
	},
	updateDiscordIntegration: async (shopId: string, data: BulkUpdatesProductsDiscordIntegration) => {
		return await privateAPI.post(
			`/v1/dash/shops/${shopId}/products/bulk-update/discord-integration`,
			data
		)
	},
}
