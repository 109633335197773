import React, { FC } from 'react'
import styles from './RewardsCard.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { getRewardRuleTypeOption } from '../../helpers/getRewardRuleTypeOption'
import { RewardRuleType } from '../../types/RewardRuleType/RewardRuleType'
import { useAPI } from '../../hooks/useAPI'
import { RewardsService } from '../../API/RewardsService'
import { useAppContext } from '../../hooks/useAppContext'
import { RewardRule } from '../../types/RewardRule/RewardRule'
import { BalanceRewardType } from '../../types/BalanceRewardType/BalanceRewardType'
import { GetBalanceRewards } from '../../types/GetBalanceRewards/GetBalanceRewards'
import { PaymentsSettingsService } from '../../API/PaymentsSettingsService'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'

interface RewardsCardProps {
	ruleType: RewardRuleType | BalanceRewardType
	onEnableClick: () => void
	onEditClick: () => void
	reward?: RewardRule
	balanceRewards?: GetBalanceRewards
	getRewards: () => void
	isAffiliatesModifyAvailable: boolean
}

const RewardsCard: FC<RewardsCardProps> = ({
	ruleType,
	onEditClick,
	onEnableClick,
	reward,
	getRewards,
	balanceRewards,
	isAffiliatesModifyAvailable,
}) => {
	const ruleOption = getRewardRuleTypeOption(ruleType)
	const { shop } = useAppContext()

	const [isUpdateRewardLoading, , updateReward] = useAPI(
		reward => RewardsService.update(shop?.id || '', reward?.id, reward),
		false,
		'Reward rule successfully updated',
		{
			onSuccess: getRewards,
		}
	)

	const [isUpdateBalanceRewardsLoading, , updateBalanceRewards] = useAPI(
		(isEnabled: boolean) =>
			PaymentsSettingsService.updateBalanceRewards(shop?.id || '', {
				topUpBonusEnabled:
					ruleType === BalanceRewardType.topUpBonus
						? isEnabled
						: !!balanceRewards?.topUpBonusEnabled,
				minimumTopUpForBonus: balanceRewards?.minimumTopUpForBonus || 0,
				cashbackPercent: balanceRewards?.cashbackPercent || 0,
				cashbackEnabled:
					ruleType === BalanceRewardType.cashback ? isEnabled : !!balanceRewards?.cashbackEnabled,
				bonusPercent: balanceRewards?.bonusPercent || 0,
			}),
		false,
		'Balance reward successfully saved',
		{
			onSuccess: () => {
				getRewards()
			},
		}
	)

	const onDisableClickHandler = () => {
		if (reward) {
			updateReward({ ...reward, isEnabled: false })
		}
		if (balanceRewards) {
			updateBalanceRewards(false)
		}
	}

	const onEnableClickHandler = () => {
		if (reward && !reward.isEnabled) {
			updateReward({ ...reward, isEnabled: true })
		} else {
			onEnableClick()
		}
	}

	return (
		<div className={styles.rewardsCard}>
			<header>
				<div className={styles.ruleIcon}>
					<SpriteIcon
						iconId={ruleOption.icon.id}
						width={ruleOption.icon.width}
						height={ruleOption.icon.height}
					/>
				</div>
			</header>

			<div className={styles.body}>
				<h1 className={styles.title}>{ruleOption.title}</h1>
				<p className={styles.description}>{ruleOption.description}</p>
			</div>

			{isAffiliatesModifyAvailable && (
				<footer className={styles.footer}>
					{reward?.isEnabled ||
					(ruleType === BalanceRewardType.cashback ? balanceRewards?.cashbackEnabled : false) ||
					(ruleType === BalanceRewardType.topUpBonus
						? balanceRewards?.topUpBonusEnabled
						: false) ? (
						<div className={styles.buttons}>
							<Button
								style={ButtonStyles.BORDERED}
								disableShadow={true}
								icon={{
									id: 'pen',
									width: 13,
									height: 13,
									align: ButtonsIconAlign.LEFT,
									className: styles.dark,
								}}
								onClick={onEditClick}
							>
								Edit
							</Button>
							<Button
								style={ButtonStyles.BORDERED}
								disableShadow={true}
								icon={{
									id: 'trashBin',
									width: 12,
									height: 13,
									align: ButtonsIconAlign.LEFT,
									className: styles.dark,
								}}
								onClick={onDisableClickHandler}
								isLoading={isUpdateRewardLoading}
							>
								Disable
							</Button>
						</div>
					) : (
						<Button
							style={ButtonStyles.DARK}
							icon={{
								id: 'circlePlus',
								width: 14.5,
								height: 14.5,
								align: ButtonsIconAlign.LEFT,
							}}
							disableShadow={true}
							onClick={onEnableClickHandler}
							isLoading={isUpdateRewardLoading}
						>
							Enable Reward
						</Button>
					)}
				</footer>
			)}
		</div>
	)
}

export default RewardsCard
