import React, { ChangeEvent, DragEvent, FC, useRef, useState } from 'react'
import styles from './ImageInput.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage'

interface ImageInputProps {
	title: string
	subTitle: string
	file: File | null
	setFile: (file: File | null) => void
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
	errorMessage?: string
}

const ImageInput: FC<ImageInputProps> = ({
	title,
	subTitle,
	setFile,
	file,
	onBlur,
	errorMessage,
}) => {
	const [dragActive, setDragActive] = useState(false)
	const inputRef = useRef<HTMLInputElement>(null)
	const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setFile(event.target.files ? event.target.files[0] : null)
	}

	const handleDrag = (event: DragEvent) => {
		event.preventDefault()
		event.stopPropagation()

		if (event.type === 'dragenter' || event.type === 'dragover') {
			setDragActive(true)
		} else if (event.type === 'dragleave') {
			setDragActive(false)
		}
	}

	const handleDrop = (event: DragEvent) => {
		event.preventDefault()
		event.stopPropagation()
		setDragActive(false)
		if (event.dataTransfer.files && event.dataTransfer.files[0]) {
			setFile(event.dataTransfer.files[0])
		}
	}

	return (
		<div>
			<div
				className={`${styles.imageInput} ${dragActive && styles.dragActive}`}
				onDragEnter={handleDrag}
				onClick={() => inputRef.current?.click()}
			>
				<SpriteIcon iconId={'upload'} width={31} height={31} />
				<p>{file ? file.name : title}</p>
				<span>{subTitle}</span>
				<input
					onChange={onChangeHandler}
					type='file'
					accept={'image/jpeg, image/png, image/gif'}
					ref={inputRef}
					onBlur={onBlur}
				/>

				{dragActive && (
					<div
						className={styles.dragFileElement}
						onDragEnter={handleDrag}
						onDragLeave={handleDrag}
						onDragOver={handleDrag}
						onDrop={handleDrop}
					></div>
				)}
			</div>

			{errorMessage && <FieldErrorMessage errorMessage={errorMessage} />}
		</div>
	)
}

export default ImageInput
