import React, { FC, useState, useRef } from 'react'
import generalStyles from '../Modals.module.scss'
import styles from './SubscribeModal.module.scss'
import InputWithButton from '../../UI/FormComponents/InputWithButton/InputWithButton'
import Radio from '../../UI/Radio/Radio'
import debitCreditCardImg from '../../../assets/images/debitCreditCard.png'
import paypalImg from '../../../assets/images/paypal.png'
import cryptocurrencyImg from '../../../assets/images/cryptocurrency.png'
import Button, { ButtonStyles } from '../../UI/Button/Button'
import { SpriteIcon } from '../../UI/SpriteIcon/SpriteIcon'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { Subscriptions } from '../../../types/Subscriptions/Subscriptions'
import { subscriptionOptions } from '../../../helpers/subscriptionsOptions'
import { useAPI } from '../../../hooks/useAPI'
import { SubscriptionService } from '../../../API/SubscriptionService'
import { SubscriptionPlanId } from '../../../types/SubscriptionPlanId/SubscriptionPlanId'

interface SubscribeModalProps {
	subscription: Subscriptions
	isYearly: boolean
}

const SubscribeModal: FC<SubscribeModalProps> = ({ subscription, isYearly }) => {
	const [couponCode, setCouponCode] = useState('')
	const [selectPaymentMethod, setSelectPaymentMethod] = useState('Hoodpay')
	const subAddressRef = useRef<Window | null>(null)

	const [isLoading, , purchase] = useAPI(
		() =>
			SubscriptionService.purchase({
				coupon: couponCode,
				gateway: selectPaymentMethod,
				subscriptionId: subscription,
				subscriptionPlanId: getSubscriptionPlanId(),
			}),
		false,
		undefined,
		{
			onSuccess: response => {
				const url = response.data.data

				if (subAddressRef.current && typeof url === 'string') {
					subAddressRef.current.location.href = url
				}
			},
			onError: () => {
				if (subAddressRef.current) {
					subAddressRef.current.close()
				}
			},
		}
	)

	const purchasePlan = async () => {
		const newWindow = window.open('', '_blank')
		subAddressRef.current = newWindow
		await purchase()
	}
	const getSubscriptionPlanId = () => {
		switch (subscription) {
			case Subscriptions.BUSINESS:
				if (isYearly) return SubscriptionPlanId.BUSINESS_YEARLY
				return SubscriptionPlanId.BUSINESS_MONTHLY
			case Subscriptions.CORE:
				return 1
			case Subscriptions.CORE_PLUS:
				if (isYearly) return SubscriptionPlanId.CORE_PLUS_YEARLY
				return SubscriptionPlanId.CORE_PLUS_MONTHLY
			case Subscriptions.SCALE:
				if (isYearly) return SubscriptionPlanId.SCALE_YEARLY
				return SubscriptionPlanId.SCALE_MONTHLY
		}
	}

	return (
		<div className={generalStyles.modalInner}>
			<div className={styles.subscribeModal}>
				<header className={styles.header}>
					<h1 className={styles.subsName}>{subscriptionOptions[subscription].title}</h1>
					<p className={styles.subsPrice}>
						{isYearly
							? subscriptionOptions[subscription].yearlyPrice
							: subscriptionOptions[subscription].monthlyPrice}
					</p>
					<p className={styles.subsDescription}>{subscriptionOptions[subscription].description}</p>
				</header>

				<div className={styles.body}>
					<InputWithButton
						value={couponCode}
						setValue={setCouponCode}
						buttonText={'Apply'}
						onButtonClick={() => {}}
						placeholder={'Enter a coupon code'}
					/>

					<div className={styles.paymentMethods}>
						{/*<label*/}
						{/*	className={`${styles.paymentMethod} ${selectPaymentMethod === '0' && styles.active}`}*/}
						{/*>*/}
						{/*	<Radio*/}
						{/*		id={'0'}*/}
						{/*		value={'0'}*/}
						{/*		setValue={setSelectPaymentMethod}*/}
						{/*		checked={selectPaymentMethod === '0'}*/}
						{/*	/>*/}
						{/*	<img src={debitCreditCardImg} alt='debit credit card' />*/}
						{/*	<p>Debit/Credit Card</p>*/}
						{/*</label>*/}
						{/*<label*/}
						{/*	className={`${styles.paymentMethod} ${selectPaymentMethod === '1' && styles.active}`}*/}
						{/*>*/}
						{/*	<Radio*/}
						{/*		id={'1'}*/}
						{/*		value={'1'}*/}
						{/*		setValue={setSelectPaymentMethod}*/}
						{/*		checked={selectPaymentMethod === '1'}*/}
						{/*	/>*/}
						{/*	<img src={paypalImg} alt='paypal' />*/}
						{/*	<p>PayPal</p>*/}
						{/*</label>*/}
						<label
							className={`${styles.paymentMethod} ${selectPaymentMethod === '2' && styles.active}`}
						>
							<Radio
								id={'Hoodpay'}
								value={'Hoodpay'}
								setValue={setSelectPaymentMethod}
								checked={selectPaymentMethod === 'Hoodpay'}
							/>
							<img src={cryptocurrencyImg} alt='cryptocurrency' />
							<p>Cryptocurrency</p>
						</label>
					</div>

					<div className={styles.subscribeButton}>
						<Button
							style={ButtonStyles.PRIMARY}
							disableShadow={true}
							onClick={purchasePlan}
							isLoading={isLoading}
						>
							Purchase
						</Button>
					</div>
				</div>

				<footer className={styles.footer}>
					<div className={styles.credentials}>
						<p>Powered by</p>
						<div className={styles.logo}>
							<SpriteIcon iconId={'nameLogo'} width={84} height={19} />
						</div>
					</div>
					<div className={styles.links}>
						<a href='https://billgang.com/terms-of-service' target={'_blank'}>
							Terms
						</a>
						<a href='https://billgang.com/privacy-policy' target={'_blank'}>
							Privacy
						</a>
					</div>
				</footer>
			</div>
		</div>
	)
}

export default SubscribeModal
