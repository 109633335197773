import React, { FC } from 'react'
import styles from './SettingsDomainsPage.module.scss'
import generalStyles from '../Settings.module.scss'
import Input, { InputIconAligns } from '../../../components/UI/FormComponents/Input'
import { Domain } from '../../../types/Domain/Domain'
import { useCopy } from '../../../hooks/useCopy'

interface DomainCNAMERecordFormProps {
	domain: Domain
}

const DomainCNAMERecordForm: FC<DomainCNAMERecordFormProps> = ({ domain }) => {
	const copy = useCopy()

	return (
		<div className={styles.connectionDomainStepForm}>
			<h2 className={styles.connectionDomainStepFormTitle}>Add CNAME Record</h2>
			<p className={styles.connectionDomainStepFormDescription}>
				Add a CNAME record to your DNS settings in order to point your custom domain to Billgang,
				copy and paste the two values below.
			</p>

			<div className={generalStyles.mtMediumLarge}>
				<div className={generalStyles.fieldWrapper}>
					<p className={generalStyles.fieldWrapperTitle}>Type</p>
					<div className={generalStyles.formWrapper}>
						<Input
							value={'CNAME'}
							setValue={() => {}}
							placeholder={'CNAME'}
							icon={{
								id: 'copy',
								width: 14,
								height: 14,
								align: InputIconAligns.RIGHT,
							}}
						/>
					</div>
				</div>
			</div>
			<div className={generalStyles.mtMediumLarge}>
				<div className={generalStyles.fieldWrapper}>
					<p className={generalStyles.fieldWrapperTitle}>Alias (or hostname)</p>
					<div className={generalStyles.formWrapper}>
						<Input
							value={'@'}
							setValue={() => {}}
							placeholder={'@'}
							icon={{
								id: 'copy',
								width: 14,
								height: 14,
								align: InputIconAligns.RIGHT,
								onClick: () => copy('@'),
							}}
						/>
					</div>
				</div>
			</div>
		
			<div className={generalStyles.mtMediumLarge}>
				<div className={generalStyles.fieldWrapper}>
					<p className={generalStyles.fieldWrapperTitle}>Custom Hostname CNAME value</p>
					<div className={generalStyles.formWrapper}>
						<Input
							value={'domains.bgng.io'}
							setValue={() => {}}
							placeholder={'domains.bgng.io'}
							icon={{
								id: 'copy',
								width: 14,
								height: 14,
								align: InputIconAligns.RIGHT,
								onClick: () => copy('domains.bgng.io'),
							}}
						/>
					</div>
				</div>
			</div>
			<div className={generalStyles.mtMediumLarge}>
				<div className={generalStyles.fieldWrapper}>
					<p className={generalStyles.fieldWrapperTitle}>TTL</p>
					<div className={generalStyles.formWrapper}>
						<Input value={''} setValue={() => {}} placeholder={'Auto (default)'} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default DomainCNAMERecordForm
