import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Textarea from '../../UI/FormComponents/Textarea'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import DiscordSettings from '../../DiscordSettings/DiscordSettings'
import { BulkUpdateModalsProps } from '../../../types/BulkUpdateModalsProps/BulkUpdateModalsProps'
import { DiscordGuildWithRoles } from '../../../types/DiscordGuildWithRoles/DiscordGuildWithRoles'
import { useAPI } from '../../../hooks/useAPI'
import { BulkUpdateProductsService } from '../../../API/BulkUpdateProductsService'
import { useAppContext } from '../../../hooks/useAppContext'
import { is } from 'date-fns/locale'

const UpdateDiscordIntegrationModal: FC<BulkUpdateModalsProps> = ({
	onClose,
	getProducts,
	productIds,
}) => {
	const [isEnabled, setIsEnabled] = useState(false)
	const [isRequired, setIsRequired] = useState(false)
	const [isBeforePurchaseAddUserEnabled, setIsBeforePurchaseAddUserEnabled] = useState(false)
	const [isBeforePurchaseRequireRolesEnabled, setIsBeforePurchaseRequireRolesEnabled] =
		useState(false)
	const [beforePurchaseAddUser, setBeforePurchaseAddUser] = useState<DiscordGuildWithRoles | null>(
		null
	)
	const [beforePurchaseRequireRoles, setBeforePurchaseRequireRoles] =
		useState<DiscordGuildWithRoles | null>(null)

	const [isDiscordDeliveryEnabled, setIsDiscordDeliveryEnabled] = useState(false)
	const [discordDelivery, setDiscordDelivery] = useState<DiscordGuildWithRoles | null>(null)
	const { shop } = useAppContext()

	const [isUpdateDiscordIntegrationLoading, , updateDiscordIntegration] = useAPI(
		() =>
			BulkUpdateProductsService.updateDiscordIntegration(shop?.id || '', {
				discordDelivery: isDiscordDeliveryEnabled ? discordDelivery : null,
				discordSettings: {
					beforePurchaseRequireRoles: isBeforePurchaseRequireRolesEnabled
						? beforePurchaseRequireRoles
						: null,
					beforePurchaseAddUser: isBeforePurchaseAddUserEnabled ? beforePurchaseAddUser : null,
					isRequired: isRequired,
					isEnabled: isEnabled,
				},
				productIds,
			}),
		false,
		'Products successfully updated',
		{
			onSuccess: () => {
				onClose()
				getProducts()
			},
		}
	)

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Update Discord Integration</h1>
			</header>

			<div className={styles.body}>
				<DiscordSettings
					isVertical={true}
					isEnabled={isEnabled}
					setIsEnabled={setIsEnabled}
					isRequired={isRequired}
					setIsRequired={setIsRequired}
					isBeforePurchaseAddUserEnabled={isBeforePurchaseAddUserEnabled}
					setIsBeforePurchaseAddUserEnabled={setIsBeforePurchaseAddUserEnabled}
					isBeforePurchaseRequireRolesEnabled={isBeforePurchaseRequireRolesEnabled}
					setIsBeforePurchaseRequireRolesEnabled={setIsBeforePurchaseRequireRolesEnabled}
					beforePurchaseAddUser={beforePurchaseAddUser}
					setBeforePurchaseAddUser={setBeforePurchaseAddUser}
					beforePurchaseRequireRoles={beforePurchaseRequireRoles}
					setBeforePurchaseRequireRoles={setBeforePurchaseRequireRoles}
					showDiscordDelivery
					discordDelivery={discordDelivery}
					setDiscordDelivery={setDiscordDelivery}
					isDiscordDeliveryEnabled={isDiscordDeliveryEnabled}
					setIsDiscordDeliveryEnabled={setIsDiscordDeliveryEnabled}
				/>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						disableShadow={true}
						icon={{
							id: 'download',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						onClick={updateDiscordIntegration}
						isLoading={isUpdateDiscordIntegrationLoading}
					>
						Save Update
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default UpdateDiscordIntegrationModal
