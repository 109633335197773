import {type FC} from 'react';
import styles from './SubscriptionPlanCard.module.scss';
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon';

interface UpgradePlanButtonProps {
    onChangePlan: () => void;
}

const UpgradePlanButton: FC<UpgradePlanButtonProps> = ({ onChangePlan }) => {
    return (
        <button className={styles.upgradePlanButton} onClick={onChangePlan}>
            <SpriteIcon iconId={'rocket'} width={16} height={16} />
            <span>Upgrade plan</span>
        </button>
    );
};

export default UpgradePlanButton;