import React, { useEffect, useState } from 'react'
import styles from './CreateGroupPage.module.scss'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageHeader from '../../components/PageHeader/PageHeader'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import Input from '../../components/UI/FormComponents/Input'
import CustomMultiSelect from '../../components/UI/CustomMultiSelect/CustomMultiSelect'
import { ListingVisibility } from '../../types/ListingVisibility/ListingVisibility'
import { Image } from '../../types/Image/Image'
import ImagePicker from '../../components/ImagePicker/ImagePicker'
import Section from '../../components/UI/Section/Section'
import RadioWrapper from '../../components/UI/RadioWrapper/RadioWrapper'
import Textarea from '../../components/UI/FormComponents/Textarea'
import { fieldValidators } from '../../helpers/fieldValidators'
import { FormErrors } from '../../types/FormErrors/FormErrors'
import SEOSection from '../../components/SEOSection/SEOSection'
import { useAppContext } from '../../hooks/useAppContext'
import { useAPI } from '../../hooks/useAPI'
import { useNavigate, useParams } from 'react-router-dom'
import { GroupsService } from '../../API/GroupsService'
import { CreateGroup } from '../../types/CreateGroup/CreateGroup'
import { usePageTitle } from '../../hooks/usePageTitle'
import FormErrorsDisplayBadge from '../../components/UI/FormErrorsDisplayBadge/FormErrorsDisplayBadge'
import OptionalBadge from '../../components/UI/OptionalBadge/OptionalBadge'
import FieldErrorMessage from '../../components/UI/FieldErrorMessage/FieldErrorMessage'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import { DetailedGroup } from '../../types/Group/Group'
import { ListingsService } from '../../API/ListingsService'
import { ListingType } from '../../types/ListingType/ListingType'
import ChooseAndSortListings from '../../components/ChooseAndSortListings/ChooseAndSortListings'
import { Listing } from '../../types/Listing/Listing'
import Spinner from '../../components/UI/Spinner/Spinner'

const CreateGroupPage = () => {
	const [titleValue, setTitleValue] = useState('')
	const [shortDescription, setShortDescription] = useState('')
	const [visibility, setVisibility] = useState<ListingVisibility>(ListingVisibility.PUBLIC)
	const [image, setImage] = useState<Image | null>(null)
	const [metaImage, setMetaImage] = useState<Image | null>(null)
	const [errors, setErrors] = useState<FormErrors>({})
	const [metaTitle, setMetaTitle] = useState('')
	const [metaDescription, setMetaDescription] = useState('')
	const [slugUrl, setSlugUrl] = useState('')
	const { shopDomain, shop } = useAppContext()
	const navigate = useNavigate()
	const params = useParams()
	const groupId = params.id
	const [showErrorsDisplay, setShowErrorsDisplay] = useState(false)
	usePageTitle(`${groupId ? 'Edit' : 'Create'} Group`)
	const errorMessages = Object.keys(errors)
		.map(key => errors[key])
		.filter(error => error)
	const [handledListings, setHandledListings] = useState<Listing[]>([])
	const titleValidator = fieldValidators.blankValidator('Group title', titleValue)
	const slugURLValidator = fieldValidators.blankValidator('Slug URL', slugUrl)
	const shortDescriptionValidator = fieldValidators.blankValidator(
		'Short description',
		shortDescription
	)
	const productsValidator = handledListings.length ? '' : "Products can't be empty"

	const [isGetProductsLoading, products, getProducts] = useAPI<Listing[]>(
		() => ListingsService.getListings(shop?.id || '', ListingType.PRODUCT),
		false
	)

	const [isCreateGroupLoading, , createGroup] = useAPI(
		(data: CreateGroup) => GroupsService.create(shop?.id || '', data),
		false,
		'Group successfully created',
		{
			onSuccess: () => {
				navigate('/products/groups')
			},
		}
	)

	const [isGetDetailedGroupLoading, detailedGroup, getDetailedGroup] = useAPI<DetailedGroup>(
		() => GroupsService.getOne(shop?.id || '', Number(groupId)),
		false
	)

	const [isUpdateGroupLoading, , updateGroup] = useAPI(
		(data: CreateGroup) => GroupsService.update(shop?.id || '', Number(groupId), data),
		false,
		'Group successfully updated',
		{
			onSuccess: () => {
				navigate('/products/groups')
			},
		}
	)

	const submitValidator = () => {
		setErrors({
			title: titleValidator,
			slugUrl: slugURLValidator,
			shortDescription: shortDescriptionValidator,
			products: productsValidator,
		})

		return !(titleValidator || slugURLValidator || shortDescriptionValidator || productsValidator)
	}

	const onSubmitHandler = () => {
		if (submitValidator()) {
			const group: CreateGroup = {
				shortDescription,
				imageId: image?.id || null,
				uniquePath: slugUrl,
				name: titleValue,
				isPrivate: visibility !== ListingVisibility.PUBLIC,
				listings: handledListings.map(listing => ({
					position: listing.position,
					id: listing.id,
				})),
				seoDetails: {
					pageDescription: metaDescription,
					pageTitle: metaTitle,
					pageImageId: metaImage?.id || null,
				},
			}

			if (groupId) {
				updateGroup(group)
			} else {
				createGroup(group)
			}
		} else {
			setShowErrorsDisplay(true)
		}
	}

	useEffect(() => {
		if (shop) {
			getProducts()
			if (groupId) {
				getDetailedGroup()
			}
		}
	}, [shop])

	useEffect(() => {
		if (detailedGroup && products) {
			setTitleValue(detailedGroup.name)
			setShortDescription(detailedGroup.shortDescription)
			setMetaTitle(detailedGroup.seoDetails?.pageTitle || '')
			setMetaDescription(detailedGroup.seoDetails?.pageDescription || '')
			setVisibility(detailedGroup.isPrivate ? ListingVisibility.PRIVATE : ListingVisibility.PUBLIC)
			setSlugUrl(detailedGroup.uniquePath)
			setImage(detailedGroup.image || null)
			setMetaImage(detailedGroup.seoDetails?.pageImage || null)
			setHandledListings(
				detailedGroup.listings.map(listing => {
					const detailedListing = products?.find(item => item.id === listing.id)
					return {
						position: listing.position,
						id: listing.id,
						name: detailedListing?.name || '',
						imageCfId: detailedListing?.imageCfId,
					}
				})
			)
		}
	}, [detailedGroup, products])

	useEffect(() => {
		if (!detailedGroup?.uniquePath) {
			setSlugUrl(titleValue.toLowerCase().replace(/[^a-z1-9-]+/g, '-'))
		}

		if (!detailedGroup?.seoDetails?.pageTitle) {
			setMetaTitle(`${titleValue} by ${shop?.name}`)
		}
	}, [titleValue])

	if (isGetDetailedGroupLoading) return <PageLoading />

	return (
		<PageContainer>
			{showErrorsDisplay && !!errorMessages.length && (
				<FormErrorsDisplayBadge
					title={`Group ${groupId ? 'Edit' : 'Create'} Error`}
					errors={errorMessages}
				/>
			)}
			<PageWrapper>
				<div className={styles.createGroupPage}>
					<PageHeader title={`${groupId ? 'Edit' : 'Create'} Group`}></PageHeader>

					<div className={styles.body}>
						<div className={styles.column}>
							<Section title={'Group Details'}>
								<div className={styles.fieldWrapper}>
									<p className={styles.fieldTitle}>Group TITLE</p>
									<Input
										value={titleValue}
										setValue={setTitleValue}
										placeholder={'Demo Group'}
										onBlur={() =>
											setErrors({
												...errors,
												title: titleValidator,
											})
										}
										errorMessage={errors['title']}
									/>
								</div>
								<div className={styles.fieldWrapper}>
									<p className={styles.fieldTitle}>Group Short Description</p>
									<Textarea
										value={shortDescription}
										setValue={setShortDescription}
										placeholder={'Use this space to write a short description of your group.'}
										height={90}
										resizable={false}
										onBlur={() =>
											setErrors({
												...errors,
												shortDescription: shortDescriptionValidator,
											})
										}
										errorMessage={errors['shortDescription']}
									/>
								</div>
								<div className={styles.fieldWrapper}>
									<p className={styles.fieldTitle}>PRODUCTS</p>
									{isGetProductsLoading && <Spinner />}
									{
										<ChooseAndSortListings
											listings={products}
											handledListings={handledListings}
											setHandledListings={setHandledListings}
										/>
									}
									{errors['products'] && <FieldErrorMessage errorMessage={errors['products']} />}
								</div>
							</Section>
							<Section title={'Group SEO'}>
								<SEOSection
									slugUrl={slugUrl}
									setSlugUrl={setSlugUrl}
									metaTitle={metaTitle}
									setMetaTitle={setMetaTitle}
									metaDescription={metaDescription}
									setMetaDescription={setMetaDescription}
									metaImage={metaImage}
									setMetaImage={setMetaImage}
									errors={errors}
									setErrors={setErrors}
									url={`${shopDomain}/groups/`}
									isMostlyOptional={false}
								/>
							</Section>
						</div>
						<div className={styles.column}>
							<Button
								style={ButtonStyles.SECONDARY}
								icon={{
									id: 'plus',
									width: 13,
									height: 13,
									align: ButtonsIconAlign.LEFT,
								}}
								onClick={onSubmitHandler}
								isLoading={isUpdateGroupLoading || isCreateGroupLoading}
							>
								{groupId ? 'Edit' : 'Create'} Group
							</Button>
							<Section title={'Group Edit'}>
								<div className={styles.fieldWrapper}>
									<p className={styles.fieldTitle}>
										Group Image <OptionalBadge />
									</p>
									<ImagePicker setImages={images => setImage(images[0])} images={[image]} />
								</div>
								<div className={styles.horLine} />
								<div className={styles.fieldWrapper}>
									<div className={styles.radios}>
										<RadioWrapper
											id={'visible'}
											value={ListingVisibility.PUBLIC}
											setValue={value => setVisibility(value as ListingVisibility)}
											checked={visibility === ListingVisibility.PUBLIC}
											title={'Visible (public)'}
											description={'Available to any customer.'}
										/>

										<RadioWrapper
											id={'private'}
											value={ListingVisibility.PRIVATE}
											setValue={value => setVisibility(value as ListingVisibility)}
											checked={visibility === ListingVisibility.PRIVATE}
											title={'HIDDEN (PRIVATE)'}
											description={'Product cannot be viewed.'}
										/>
									</div>
								</div>
							</Section>
						</div>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default CreateGroupPage
