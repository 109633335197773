import React, { FC } from 'react'
import styles from './TeamMember.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { Manager } from '../../types/Manager/Manager'
import { format } from 'date-fns'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'
import { useAppContext } from '../../hooks/useAppContext'

interface TeamMemberProps {
	manager: Manager
	onEditClick: (manager: Manager) => void
	onDeleteClick: (manager: Manager) => void
}
const TeamMember: FC<TeamMemberProps> = ({ manager, onEditClick, onDeleteClick }) => {
	const { shop } = useAppContext()
	const isUserAdmin = shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isUserOwner = shop?.permissions.includes(ShopForUserPermissions.OWNER)
	const isManagerAdmin =
		manager.permissions.includes(ShopForUserPermissions.OWNER) ||
		manager.permissions.includes(ShopForUserPermissions.ADMIN)

	return (
		<div className={styles.teamMember}>
			<div className={styles.teamMemberHead}>
				<div className={styles.teamMemberIcon}>
					{manager.permissions.includes(ShopForUserPermissions.ADMIN) ? (
						<SpriteIcon iconId={'crown'} width={16} height={12} />
					) : (
						<SpriteIcon iconId={'person'} width={16} height={12} />
					)}
				</div>
				<div>
					<p className={styles.teamMemberEmail}>{manager.email}</p>
					{manager.lastLoginAt && (
						<time>
							Last login was {format(new Date(manager.lastLoginAt), "MMM dd, yyyy 'at' hh:mm aa z")}
						</time>
					)}
				</div>
			</div>
			<div className={styles.teamMemberEnd}>
				{manager.isMFAEnabled ? (
					<div
						data-tooltip-id='my-tooltip'
						data-tooltip-content='Two-factor authentication enabled'
					>
						<SpriteIcon className={styles.green} iconId={'checkedCircle'} width={16} height={16} />
					</div>
				) : (
					<div
						data-tooltip-id='my-tooltip'
						data-tooltip-content='Two-factor authentication is not enabled'
					>
						<SpriteIcon
							className={styles.brown}
							iconId={'exclamationMarkCircle'}
							width={16}
							height={16}
						/>
					</div>
				)}

				{(isUserOwner || (isUserAdmin && !isManagerAdmin)) && (
					<>
						<SpriteIcon
							iconId={'pen'}
							width={15}
							height={15}
							onClick={() => onEditClick(manager)}
						/>
						<SpriteIcon
							iconId={'trashBin'}
							width={15}
							height={15}
							onClick={() => onDeleteClick(manager)}
						/>
					</>
				)}
			</div>
		</div>
	)
}

export default TeamMember
