import React, { useEffect, MouseEvent, useRef, FC, useState } from 'react'
import styles from './Sidebar.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import UserSubPlan from '../UserSubPlan/UserSubPlan'
import ShopSelect from '../ShopSelect/ShopSelect'
import { useAppContext } from '../../hooks/useAppContext'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import BackgroundOverlay from '../UI/BackgroundOverlay/BackgroundOverlay'
import SubscriptionPlanCard from '../../components/SubscriptionPlanCard/SubscriptionPlanCard';
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

interface NavLinkType {
	title: string
	icon: {
		id: string
		height: number
		width: number
	}
	path: string
	exact?: boolean
	subLinks?: {
		title: string
		path: string
		externalLink?: boolean
		exact?: boolean
	}[]
	externalLink?: boolean
	divider?: boolean
}

interface NavLinkItemProps {
	link: NavLinkType
	setIsSidebarActive: (value: boolean) => void
}

interface SideBarProps {
	subscription: Record<string, any>;
}

const NavLinkItem: FC<NavLinkItemProps> = ({ link, setIsSidebarActive }) => {
	const location = useLocation()
	const isActive = link.exact
		? location.pathname === link.path
		: location.pathname.startsWith(link.path)

	return (
		<li key={link.path}>
			<div className={`${styles.linkWrapper} ${isActive && styles.active}`}>
				<NavLink to={link.path} target={link.externalLink ? '_blank' : undefined}>
					<SpriteIcon iconId={link.icon.id} width={link.icon.width} height={link.icon.height} />
					<p className={styles.linkTitle}>{link.title}</p>

					{link.subLinks && (
						<SpriteIcon className={styles.arrowDown} iconId={'arrowDown2'} width={14} height={7} />
					)}
					{link.externalLink && (
						<SpriteIcon
							className={styles.arrowDown}
							iconId={'ascendantArrow'}
							width={10}
							height={10}
						/>
					)}
				</NavLink>
			</div>

			{link.subLinks && (
				<ul className={`${styles.subLinks} ${isActive && styles.active}`}>
					{link.subLinks.map(subLink => (
						<li
							key={subLink.path}
							className={
								(
									subLink.exact
										? location.pathname === subLink.path
										: location.pathname.startsWith(subLink.path)
								)
									? styles.active
									: ''
							}
						>
							<NavLink
								to={subLink.path}
								target={subLink.externalLink ? '_blank' : '_self'}
								onClick={() => setIsSidebarActive(false)}
							>
								{/*<SpriteIcon*/}
								{/*	iconId={subLink.icon.id}*/}
								{/*	width={subLink.icon.width}*/}
								{/*	height={subLink.icon.height}*/}
								{/*/>*/}
								<p className={styles.linkTitle}>{subLink.title}</p>
								{subLink.externalLink && (
									<SpriteIcon
										className={styles.arrowDown}
										iconId={'ascendantArrow'}
										width={10}
										height={10}
									/>
								)}
							</NavLink>
						</li>
					))}
				</ul>
			)}
			{link.divider && <div className={styles.horLine} />}
		</li>
	)
}

const Sidebar: FC<SideBarProps> = ({ subscription }) => {
	const { setIsSidebarActive, isSidebarActive, builderLink, shop } = useAppContext()
	const sidebarRef = useRef(null)
	useOutsideClick(sidebarRef, () => setIsSidebarActive(false), !isSidebarActive)
	const navigate = useNavigate();

	const navLinks: NavLinkType[] = [
		{
			title: 'Dashboard',
			icon: {
				id: 'house',
				height: 18,
				width: 18,
			},
			path: '/',
			exact: true,
		},
		{
			title: 'Products',
			icon: {
				id: 'box',
				height: 18,
				width: 18,
			},
			path: '/products',
			subLinks: [
				{
					title: 'Products',
					path: '/products',
					exact: true,
				},
				{
					title: 'Warehouse',
					path: '/products/warehouse',
				},
				{
					title: 'Categories',
					path: '/products/categories',
				},
				{
					title: 'Groups',
					path: '/products/groups',
				},
				{
					title: 'Upsells',
					path: '/products/upsells',
				},
				{
					title: 'Add-ons',
					path: '/products/addons',
				},
			],
		},
		{
			title: 'Finances',
			icon: {
				id: 'dollarCircle',
				height: 18,
				width: 18,
			},
			path: '/finances',
			subLinks: [
				{
					title: 'Orders',
					path: '/finances/orders',
					exact: true,
				},
				{
					title: 'Charges',
					path: '/finances/charges',
					exact: true,
				},
				{
					title: 'Links',
					path: '/finances/links',
					exact: true,
				},
			],
		},
		{
			title: 'Customers',
			icon: {
				id: 'persons',
				height: 18,
				width: 18,
			},
			path: '/customers',
		},
		{
			title: 'Operations',
			path: '/operations',
			icon: {
				id: 'hierarchySquare',
				width: 18,
				height: 18,
			},
			subLinks: [
				{
					title: 'Tickets',
					path: '/operations/tickets/inbox',
					exact: true,
				},
				{
					title: 'Reviews',
					path: '/operations/reviews',
					exact: false,
				},
				{
					title: 'Blacklist',
					path: '/operations/blacklist',
					exact: true,
				},
				{
					title: 'Whitelist',
					path: '/operations/whitelist',
					exact: true,
				},
				{
					title: 'Images',
					path: '/operations/images',
					exact: true,
				},
			],
		},
		{
			title: 'Website',
			path: '/website',
			icon: {
				id: 'internet',
				width: 18,
				height: 18,
			},
			subLinks: [
				// {
				// 	title: 'Themes',
				// 	path: '/website/themes',
				// 	exact: true,
				// },
				{
					title: 'Builder',
					path: builderLink,
					externalLink: true,
				},
				{
					title: 'Posts',
					path: '/website/posts',
					exact: false,
				},
				{
					title: 'Questions',
					path: '/website/faq',
					exact: true,
				},
			],
		},
		{
			title: 'Marketing',
			icon: {
				id: 'pieChart',
				height: 18,
				width: 18,
			},
			path: '/marketing',
			subLinks: [
				{
					title: 'Coupons',
					path: '/marketing/coupons',
					exact: false,
				},
				{
					title: 'Affiliates',
					path: '/marketing/affiliates/rewards',
					exact: true,
				},
				{
					title: 'Members',
					path: '/marketing/affiliates/members',
					exact: true,
				},
			],
		},
		{
			title: 'Integrations',
			icon: {
				id: 'apps',
				height: 18,
				width: 18,
			},
			path: '/integrations',
			subLinks: [
				{
					title: 'Discord Bot',
					path: '/integrations/discord-bot',
					exact: false,
				},
				{
					title: 'App Store',
					path: '/integrations/apps',
					exact: true,
				},
				{
					title: 'Custom Plugins',
					path: '/integrations/custom-plugins',
					exact: false,
				},
			],
		},

		{
			title: 'Developers',
			path: '/developers',
			icon: {
				id: 'code',
				width: 18,
				height: 18,
			},
			subLinks: [
				{
					title: 'Keys',
					path: '/developers/api-keys',
					exact: false,
				},
				{
					title: 'Embeds',
					path: '/developers/embeds',
					exact: false,
				},
				{
					title: 'Webhooks',
					path: '/developers/webhooks',
					exact: false,
				},
				{
					title: 'Webhook Logs',
					path: '/developers/webhook-logs',
					exact: true,
				},
			],
		},
		{
			title: 'Settings',
			path: '/settings',
			icon: {
				id: 'gear',
				width: 18,
				height: 18,
			},
		},
	]

	return (
		<>
			<BackgroundOverlay isActive={isSidebarActive} />
			<aside className={`${styles.sidebar} ${isSidebarActive && styles.active}`} ref={sidebarRef}>
				<div className={styles.sidebarTop}>
					<ShopSelect />
				</div>
				<div className={styles.navigation}>
					<ul className={styles.navLinks}>
						{navLinks.map(link => (
							<NavLinkItem link={link} setIsSidebarActive={setIsSidebarActive} key={link.path} />
						))}
					</ul>
				</div>

				{shop?.permissions.includes(ShopForUserPermissions.OWNER) && (
					<div className={styles.sidebarBottom}>
						<SubscriptionPlanCard currentPlan={subscription.name} expireDate={subscription.expiresAt}
											  onPlanChangeClick={() => {
												  navigate('/settings/billing');
											  }}/>
					</div>
				)}
			</aside>
		</>
	)
}

export default Sidebar
