import React, { FC } from 'react'
import styles from './StatusBadges.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import { WarehouseStatus } from '../../../types/WarehouseStatus/WarehouseStatus'
import { OrderStatus } from '../../../types/OrderStatus/OrderStatus'
import { OrderChargeStatus } from '../../../types/OrderChargeStatus/OrderChargeStatus'
import { ListingVisibility } from '../../../types/ListingVisibility/ListingVisibility'

interface ListingVisibilityStatusBadgeProps {
	visibility: ListingVisibility
}

const ListingVisibilityStatusBadge: FC<ListingVisibilityStatusBadgeProps> = ({ visibility }) => {
	const listingVisibilityStatusData = {
		[ListingVisibility.PUBLIC]: {
			name: 'Live',
			className: 'green',
			iconId: 'checkedCircle',
		},
		[ListingVisibility.PRIVATE]: {
			name: 'Private',
			className: 'gray',
			iconId: 'hiddenEye',
		},
		[ListingVisibility.HIDDEN]: {
			name: 'Hidden',
			className: 'gray',
			iconId: 'hiddenEye',
		},
	}

	return (
		<div
			className={`${styles.statusBadge} ${styles.mediumFz}  ${
				styles[listingVisibilityStatusData[visibility].className]
			}`}
		>
			<SpriteIcon iconId={listingVisibilityStatusData[visibility].iconId} width={16} height={16} />
			{listingVisibilityStatusData[visibility].name}
		</div>
	)
}

export default ListingVisibilityStatusBadge
