import React from 'react'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import noUpsellsImage from '../../assets/images/noUpsells.png'
import { ButtonsIconAlign } from '../../components/UI/Button/Button'
import { usePageTitle } from '../../hooks/usePageTitle'

const UpsellsPage = () => {
	usePageTitle(`Upsells`)

	return (
		<NoDataComponent
			title={'Upsells'}
			description={
				'We’re currently building out our upsell feature and expect a launch in October 2024.'
			}
			imageSrc={noUpsellsImage}
			button={{
				icon: {
					id: 'blankWindow',
					height: 12,
					width: 12,
					align: ButtonsIconAlign.RIGHT,
				},
				onClick: () => window.open('https://x.com/billgangcom', 'blank', 'noopener'),
				text: 'Follow us on X.com',
			}}
		/>
	)
}

export default UpsellsPage
