import React, { FC, ReactNode } from 'react'
import styles from './StatusAlertBadge.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import { StatusAlert } from '../../../types/StatusAlert/StatusAlert'
import Button, { ButtonsIconAlign, ButtonStyles } from '../Button/Button'

interface StatusAlertBadgeProps {
	title: string
	description: string | ReactNode
	statusAlertType: StatusAlert
	button?: {
		icon?: {
			id: string
			width: number
			height: number
			align: ButtonsIconAlign
		}
		text: string
		onClick?: () => void
		isLoading?: boolean
	}
}

const StatusAlertBadge: FC<StatusAlertBadgeProps> = ({
	statusAlertType,
	title,
	description,
	button,
}) => {
	const alertOptions = {
		[StatusAlert.DANGER]: {
			iconId: 'exclamationMarkCircle',
			className: 'danger',
		},
		[StatusAlert.SUCCESS]: {
			iconId: 'checkedCircle',
			className: 'success',
		},
		[StatusAlert.INFORMATION]: {
			iconId: 'informationMark',
			className: 'info',
		},
		[StatusAlert.WARNING]: {
			iconId: 'exclamationMarkCircle',
			className: 'warning',
		},
	}

	return (
		<div
			className={`${styles.statusAlertBadge} ${styles[alertOptions[statusAlertType].className]}`}
		>
			<header className={styles.header}>
				<SpriteIcon iconId={alertOptions[statusAlertType].iconId} width={16} height={16} />
				<h1 className={styles.title}>{title}</h1>
			</header>
			<div className={styles.body}>
				<p>{description}</p>
				{button && (
					<div className={styles.button}>
						<Button
							style={ButtonStyles.BORDERED}
							disableShadow={true}
							icon={button.icon}
							onClick={button.onClick}
							isLoading={button.isLoading}
						>
							{button.text}
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}

export default StatusAlertBadge
