import React, { FC, MutableRefObject, useEffect, useState } from 'react'
import styles from '../AuthPages.module.scss'
import { SpriteIcon } from '../../../components/UI/SpriteIcon/SpriteIcon'
import Input from '../../../components/UI/FormComponents/Input'
import Button, { ButtonStyles } from '../../../components/UI/Button/Button'
import { useAPI } from '../../../hooks/useAPI'
import { AuthService } from '../../../API/AuthService'
import { usePageTitle } from '../../../hooks/usePageTitle'

interface ResetPasswordPageProps {
	recaptchaRef: MutableRefObject<any>
}

const ResetPasswordPage: FC<ResetPasswordPageProps> = ({ recaptchaRef }) => {
	const [email, setEmail] = useState('')
	const [errors, setErrors] = useState({
		email: '',
	})
	usePageTitle('Reset Password')

	const resetValidator = () => {
		const isEmailMatchesToRegexp = email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)

		setErrors({
			...errors,
			email: !email ? 'Required' : !isEmailMatchesToRegexp ? 'Invalid email' : '',
		})

		return !!email && !!isEmailMatchesToRegexp
	}

	const [isResetBasicLoading, resetBasicData, resetBasic] = useAPI(
		async () => {
			const recaptcha = await recaptchaRef.current.executeAsync()
			recaptchaRef.current.reset()

			return AuthService.resetBasic({
				email: email,
				recaptcha: recaptcha,
			})
		},
		false,
		'Reset link successfully send',
		{
			validator: resetValidator,
		}
	)

	return (
		<div className={styles.authInner}>
			<div className={styles.logo}>
				<SpriteIcon iconId={'nameLogo'} width={124} height={27} />
			</div>
			<h1 className={styles.title}>Reset your account password</h1>

			<div className={styles.fieldWrapper}>
				<h2 className={styles.fieldTitle}>Email address</h2>
				<Input
					value={email}
					setValue={setEmail}
					placeholder={'Enter your email address'}
					errorMessage={errors.email}
				/>
			</div>

			<div className={styles.button}>
				<Button style={ButtonStyles.PRIMARY} onClick={resetBasic} isLoading={isResetBasicLoading}>
					Send Password Reset
				</Button>
			</div>
		</div>
	)
}

export default ResetPasswordPage
