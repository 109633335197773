export enum SessionAuthType {
	BASIC = 'BASIC',
	GOOGLE_OAUTH = 'GOOGLE_OAUTH',
	DISCORD_OAUTH = 'DISCORD_OAUTH',
	OTP_EMAIL = 'OTP_EMAIL',
	TELEGRAM_BOT = 'TELEGRAM_BOT',
	ADMIN_GENERATOR = 'ADMIN_GENERATOR',
	API_KEY = 'API_KEY',
	CONNECT_TOKEN = 'CONNECT_TOKEN',
}
