import React, { useEffect, useState } from 'react'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import styles from '../Settings.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../components/UI/Button/Button'
import TeamMember from '../../../components/TeamMember/TeamMember'
import DeviceHistory from '../../../components/DeviceHistory/DeviceHistory'
import Modal from '../../../components/Modals/Modal'
import InviteTeamMemberModal from '../../../components/Modals/InviteTeamMemberModal/InviteTeamMemberModal'
import { closeModal } from '../../../helpers/closeModal'
import { useAPI } from '../../../hooks/useAPI'
import { ManagersService } from '../../../API/ManagersService'
import { useAppContext } from '../../../hooks/useAppContext'
import { Manager } from '../../../types/Manager/Manager'
import { Invite } from '../../../types/Invite/Invite'
import { ManagersInvitesService } from '../../../API/ManagersInvitesService'
import TeamMemberInvite from '../../../components/TeamMember/TeamMemberInvite'
import ConfirmModal from '../../../components/Modals/ConfirmModal/ConfirmModal'
import Spinner from '../../../components/UI/Spinner/Spinner'
import TeamMemberOwner from '../../../components/TeamMember/TeamMemberOwner'
import Switch from '../../../components/UI/Switch/Switch'
import { ManagersSettingsService } from '../../../API/ManagersSettingsService'
import { GetManagersSettings } from '../../../types/GetManagersSettings/GetManagersSettings'
import { Subscriptions } from '../../../types/Subscriptions/Subscriptions'
import { UserSecurityService } from '../../../API/UserSecurityService'
import { SessionAuthType } from '../../../types/SesssionAuthType/SessionAuthType'
import { GetAuthSession } from '../../../types/GetAuthSession/GetAuthSession'
import { ShopForUserPermissions } from '../../../types/ShopForUserPermissions/ShopForUserPermissions'
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent'
import pageNotFoundImage from '../../../assets/images/404.png'

const SettingsTeamPage = () => {
	const [isInviteModalActive, setIsInviteModalActive] = useState(false)
	const [isInviteModalVisible, setIsInviteModalVisible] = useState(false)
	const [isDeleteInviteModalActive, setIsDeleteInviteModalActive] = useState(false)
	const [isDeleteInviteModalVisible, setIsDeleteInviteModalVisible] = useState(false)
	const [isDeleteManagerModalActive, setIsDeleteManagerModalActive] = useState(false)
	const [isDeleteManagerModalVisible, setIsDeleteManagerModalVisible] = useState(false)
	const [isSignOutFromDeviceModalActive, setIsSignOutFromDeviceModalActive] = useState(false)
	const [isSignOutFromDeviceModalVisible, setIsSignOutFromDeviceModalVisible] = useState(false)
	const [isSignOutFromAllDevicesClicked, setIsSignOutFromAllDevicesClicked] = useState(false)
	const [is2FAForced, setIs2FAForced] = useState(false)

	const [editingManager, setEditingManager] = useState<Manager | null>(null)
	const [deletingInvite, setDeletingInvite] = useState<Invite | null>(null)
	const { shop, user, setIsSubscriptionModalActive, setRequiredSubscription } = useAppContext()
	const [sessionAuthTypeFilter, setSessionAuthTypeFilter] = useState<SessionAuthType | null>(null)
	const [currentSessionId, setCurrentSessionId] = useState<number | null>(null)
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isSettingsGeneralViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.SETTINGS_GENERAL_VIEW) || isAdmin
	const isSettingsGeneralModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.SETTINGS_GENERAL_MODIFY) || isAdmin

	const [isGetSettingsLoading, settings, getSettings] = useAPI<GetManagersSettings>(
		() => ManagersSettingsService.getSettings(shop?.id || ''),
		false,
		undefined,
		{
			onSuccess: response => {
				setIs2FAForced(response.data.data.is2FAForced)
			},
		}
	)

	const [isGetSessionsLoading, sessions, getSessions] = useAPI<GetAuthSession[]>(
		() => UserSecurityService.getSessions(sessionAuthTypeFilter, false),
		false
	)

	const [isInvalidateSessionLoading, , invalidateSession] = useAPI(
		sessionId => UserSecurityService.invalidateSession(sessionId),
		false,
		'Device successfully signed out',
		{
			onSuccess: () => {
				closeSignOutFromDeviceModal()
				getSessions()
				setCurrentSessionId(null)
			},
		}
	)

	const [isInvalidateAllSessionsLoading, , invalidateAllSessions] = useAPI(
		() => UserSecurityService.invalidateAllSessions(),
		false,
		'All Devices successfully signed out',
		{
			onSuccess: () => {
				closeSignOutFromDeviceModal()
				getSessions()
			},
		}
	)

	const [isUpdateSettingsLoading, , updateSettings] = useAPI(
		() =>
			ManagersSettingsService.updateSettings(shop?.id || '', {
				is2FAForced: is2FAForced,
			}),
		false,
		'Managers settings successfully updated',
		{
			onSuccess: () => {
				getSettings()
			},
		}
	)

	const [isGetManagersLoading, managers, getManagers] = useAPI<Manager[]>(
		() => ManagersService.getAll(shop?.id || ''),
		false
	)

	const [isDeleteManagerLoading, , deleteManager] = useAPI(
		(managerId: number) => ManagersService.delete(shop?.id || '', managerId),
		false,
		'Team member successfully deleted',
		{
			onSuccess: () => {
				closeManagerDeleteModal()
				getManagers()
			},
		}
	)

	const [isDeleteInviteLoading, , deleteInvite] = useAPI(
		(inviteId: string) => ManagersInvitesService.delete(shop?.id || '', inviteId),
		false,
		'Team invite successfully deleted',
		{
			onSuccess: () => {
				closeDeleteInviteModal()
				getInvites()
			},
		}
	)

	const [isGetInvitesLoading, invites, getInvites] = useAPI<Invite[]>(
		() => ManagersInvitesService.getAll(shop?.id || ''),
		false
	)

	const onEditTeamMemberClick = (manager: Manager) => {
		setEditingManager(manager)
		setIsInviteModalActive(true)
	}

	const onDeleteTeamMemberClick = (manager: Manager) => {
		setEditingManager(manager)
		setIsDeleteManagerModalActive(true)
	}

	const closeManagerDeleteModal = () => {
		closeModal(setIsDeleteManagerModalActive, setIsDeleteManagerModalVisible)
		setEditingManager(null)
	}

	const closeDeleteInviteModal = () => {
		closeModal(setIsDeleteInviteModalActive, setIsDeleteInviteModalVisible)
		setDeletingInvite(null)
	}

	const closeInviteModal = () => {
		closeModal(setIsInviteModalActive, setIsInviteModalVisible)
		setEditingManager(null)
	}

	const closeSignOutFromDeviceModal = () => {
		closeModal(setIsSignOutFromDeviceModalActive, setIsSignOutFromDeviceModalVisible)
	}

	const onSignOutDeviceClick = (sessionId: number) => {
		setIsSignOutFromDeviceModalActive(true)
		setCurrentSessionId(sessionId)
	}

	useEffect(() => {
		if (shop && isSettingsGeneralViewAvailable) {
			getManagers()
			getInvites()
			getSettings()
			getSessions()
		}
	}, [shop])

	if (!isSettingsGeneralViewAvailable)
		return (
			<NoDataComponent
				isSmallHeight={true}
				imageSrc={pageNotFoundImage}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)

	return (
		<PageWrapper>
			{isInviteModalActive && (
				<Modal
					closeModal={closeInviteModal}
					visible={isInviteModalVisible}
					setVisible={setIsInviteModalVisible}
				>
					<InviteTeamMemberModal
						editingManager={editingManager}
						getInvites={getInvites}
						getManagers={getManagers}
						onClose={closeInviteModal}
					/>
				</Modal>
			)}
			{isDeleteManagerModalActive && (
				<Modal
					closeModal={closeManagerDeleteModal}
					visible={isDeleteManagerModalVisible}
					setVisible={setIsDeleteManagerModalVisible}
				>
					<ConfirmModal
						title={'Delete Team Member'}
						description={`You are about to delete ${editingManager?.email}. Are you sure you want to delete this team member? This cannot be undone.`}
						onConfirm={() => deleteManager(editingManager?.id)}
						confirmButtonText={'Delete Team Member'}
						buttonIcon={{
							id: 'trashBin',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						isButtonLoading={isDeleteManagerLoading}
						onClose={closeManagerDeleteModal}
					/>
				</Modal>
			)}
			{isDeleteInviteModalActive && (
				<Modal
					closeModal={closeDeleteInviteModal}
					visible={isDeleteInviteModalVisible}
					setVisible={setIsDeleteInviteModalVisible}
				>
					<ConfirmModal
						title={'Delete Team Invite'}
						description={`You are about to delete invite to ${deletingInvite?.inviteToEmail}. Are you sure you want to delete this team invite? This cannot be undone.`}
						onConfirm={() => deleteInvite(deletingInvite?.id)}
						confirmButtonText={'Delete Team Invite'}
						buttonIcon={{
							id: 'trashBin',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						isButtonLoading={isDeleteInviteLoading}
						onClose={closeDeleteInviteModal}
					/>
				</Modal>
			)}
			{isSignOutFromDeviceModalActive && (
				<Modal
					closeModal={closeSignOutFromDeviceModal}
					visible={isSignOutFromDeviceModalVisible}
					setVisible={setIsSignOutFromDeviceModalVisible}
				>
					<ConfirmModal
						title={`Sign Out ${isSignOutFromAllDevicesClicked ? 'Devices' : 'Device'}`}
						description={`You’re about to sign out of ${
							isSignOutFromAllDevicesClicked
								? `${sessions?.length} device${(sessions?.length || 1) > 1 ? 's' : ''}`
								: 'this device'
						}. Are you sure you want to revoke temporary access? They’ll be able to sign back in with their password.`}
						onConfirm={() =>
							currentSessionId
								? invalidateSession(currentSessionId)
								: isSignOutFromAllDevicesClicked && invalidateAllSessions()
						}
						confirmButtonText={'Sign Out'}
						buttonIcon={{
							id: 'shield',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						onClose={closeSignOutFromDeviceModal}
						isButtonLoading={isInvalidateSessionLoading || isInvalidateAllSessionsLoading}
					/>
				</Modal>
			)}
			<div className={styles.settingsPageInnerBody}>
				<div className={styles.settingSection}>
					<header className={styles.settingSectionHeader}>
						<h1 className={styles.settingSectionTitle}>Team Add-Ons</h1>
					</header>
					<div className={styles.settingSectionBody}>
						<div className={styles.fieldWrapper}>
							<header className={styles.fieldWrapperHeader}>
								<div>
									<p className={styles.fieldWrapperTitle}>Require 2FA (recommended)</p>
									<p className={styles.fieldWrapperDescription}>
										Members on your team will be required to enable 2FA, this keeps their account
										secure.
									</p>
								</div>
								<Switch checked={is2FAForced} setChecked={setIs2FAForced} />
							</header>
						</div>

						{isSettingsGeneralModifyAvailable && is2FAForced !== settings?.is2FAForced && (
							<div className={styles.mtMediumLarge}>
								<Button
									style={ButtonStyles.BORDERED}
									icon={{
										id: 'download',
										width: 13,
										height: 13,
										align: ButtonsIconAlign.LEFT,
									}}
									disableShadow={true}
									onClick={updateSettings}
									isLoading={isUpdateSettingsLoading}
								>
									Save Settings
								</Button>
							</div>
						)}
					</div>
				</div>

				<div className={styles.settingSection}>
					<header className={styles.settingSectionHeader}>
						<h1 className={styles.settingSectionTitle}>Team Members</h1>
					</header>
					<div className={styles.settingSectionBody}>
						<div className={styles.column}>
							{isGetManagersLoading && <Spinner />}
							<>
								<TeamMemberOwner key={'owner'} />
								<div className={styles.horLine} key={'ownerHorLine'} />
							</>
							{managers?.map(manager => (
								<>
									<TeamMember
										manager={manager}
										onDeleteClick={onDeleteTeamMemberClick}
										onEditClick={onEditTeamMemberClick}
										key={manager.id}
									/>
									<div className={styles.horLine} key={manager.id + 'horLine'} />
								</>
							))}
						</div>

						{isSettingsGeneralModifyAvailable && (
							<div className={styles.mtMediumLarge}>
								<Button
									style={ButtonStyles.BORDERED}
									icon={{
										id: 'plus',
										width: 13,
										height: 13,
										align: ButtonsIconAlign.LEFT,
									}}
									disableShadow={true}
									onClick={() => {
										if (
											managers &&
											user &&
											managers?.length < user?.subscription.perks.managersLimit
										) {
											setIsInviteModalActive(true)
										} else {
											setIsSubscriptionModalActive(true)
											setRequiredSubscription(Subscriptions.CORE_PLUS)
										}
									}}
								>
									Invite Team Member
								</Button>
							</div>
						)}
					</div>
				</div>

				{!!invites?.length && (
					<div className={styles.settingSection}>
						<header className={styles.settingSectionHeader}>
							<h1 className={styles.settingSectionTitle}>Pending Team Invites</h1>
						</header>
						<div className={styles.settingSectionBody}>
							{isGetInvitesLoading && <Spinner />}
							<div className={styles.column}>
								{invites?.map(invite => (
									<>
										<TeamMemberInvite
											invite={invite}
											onDeleteClick={invite => {
												setDeletingInvite(invite)
												setIsDeleteInviteModalActive(true)
											}}
											key={invite.id}
										/>
										<div className={styles.horLine} key={invite.id + 'horLine'} />
									</>
								))}
							</div>

							{/*{isSettingsGeneralModifyAvailable && (*/}
							{/*	<div className={styles.mtMediumLarge}>*/}
							{/*		<Button*/}
							{/*			style={ButtonStyles.BORDERED}*/}
							{/*			icon={{*/}
							{/*				id: 'trashBin',*/}
							{/*				width: 13,*/}
							{/*				height: 13,*/}
							{/*				align: ButtonsIconAlign.LEFT,*/}
							{/*			}}*/}
							{/*			disableShadow={true}*/}
							{/*			onClick={() => setIsInviteModalActive(true)}*/}
							{/*		>*/}
							{/*			Cancel Team Invites*/}
							{/*		</Button>*/}
							{/*	</div>*/}
							{/*)}*/}
						</div>
					</div>
				)}

				<div className={styles.settingSection}>
					<header className={styles.settingSectionHeader}>
						<h1 className={styles.settingSectionTitle}>Access History</h1>
					</header>
					<div className={styles.settingSectionBody}>
						<div className={styles.column}>
							{isGetSessionsLoading && <Spinner />}

							{sessions?.map(session => (
								<>
									<DeviceHistory
										isSettingsGeneralModifyAvailable={isSettingsGeneralModifyAvailable}
										session={session}
										onExitClick={onSignOutDeviceClick}
										key={session.id}
									/>
									<div className={styles.horLine} key={session.id + 'horLine'} />
								</>
							))}
						</div>

						{isSettingsGeneralModifyAvailable && (
							<div className={styles.mtMediumLarge}>
								<Button
									style={ButtonStyles.BORDERED}
									icon={{
										id: 'shield',
										width: 13,
										height: 13,
										align: ButtonsIconAlign.LEFT,
									}}
									disableShadow={true}
									onClick={() => {
										setIsSignOutFromDeviceModalActive(true)
										setIsSignOutFromAllDevicesClicked(true)
									}}
								>
									Sign Out
								</Button>
							</div>
						)}
					</div>
				</div>
			</div>
		</PageWrapper>
	)
}

export default SettingsTeamPage
