import React, { FC, useEffect } from 'react'
import modalStyles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { RewardType } from '../../../types/RewardType/RewardType'
import PriceInput from '../../UI/PriceInput/PriceInput'
import RewardProductsTable from '../../RewardProductsTable/RewardProductsTable'
import { ProductWithVariantForProductRewardType } from '../../../types/ProductWithVariantForProductRewardType/ProductWithVariantForProductRewardType'
import { useAPI } from '../../../hooks/useAPI'
import { ProductsService } from '../../../API/ProductsService'
import { Product } from '../../../types/Product/Product'
import Spinner from '../../UI/Spinner/Spinner'
import uniqid from 'uniqid'
import Tabs from '../../UI/Tabs/Tabs'

interface RewardTypeFormProps {
	balanceReward: {
		currency: string
		amount: string
	}
	setBalanceReward: (value: { currency: string; amount: string }) => void
	currentReward: RewardType
	setCurrentReward: (value: RewardType) => void
	productsWithVariants: ProductWithVariantForProductRewardType[]
	setProductsWithVariants: (value: ProductWithVariantForProductRewardType[]) => void
	shopId: string
}

const RewardTypeForm: FC<RewardTypeFormProps> = ({
	balanceReward,
	setBalanceReward,
	setCurrentReward,
	currentReward,
	setProductsWithVariants,
	productsWithVariants,
	shopId,
}) => {
	const [isGetProductsLoading, products, getProducts] = useAPI<Product[]>(
		() => ProductsService.getProducts(shopId),
		false
	)

	useEffect(() => {
		if (currentReward === RewardType.PRODUCT && !products) {
			getProducts()
		}
	}, [currentReward])

	return (
		<div className={modalStyles.mtMediumLarge}>
			<Tabs
				tabs={[
					{
						value: RewardType.BALANCE,
						label: 'Balance',
					},
					{
						value: RewardType.PRODUCT,
						label: 'Product',
					},
				]}
				currentTab={currentReward}
				setCurrentTab={setCurrentReward}
			/>

			<div className={modalStyles.mtMediumLarge}>
				{isGetProductsLoading && <Spinner />}
				{currentReward === RewardType.BALANCE && (
					<PriceInput
						currency={balanceReward.currency}
						setCurrency={value =>
							setBalanceReward({
								...balanceReward,
								currency: value,
							})
						}
						price={balanceReward.amount}
						setPrice={value =>
							setBalanceReward({
								...balanceReward,
								amount: value,
							})
						}
					/>
				)}

				{currentReward === RewardType.PRODUCT && products && (
					<RewardProductsTable
						products={products}
						setProductsWithVariants={setProductsWithVariants}
						productsWithVariants={productsWithVariants}
						shopId={shopId}
					/>
				)}
			</div>

			{currentReward === RewardType.PRODUCT && (
				<div className={modalStyles.mtMediumLarge}>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'plus',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={() =>
							setProductsWithVariants([
								...productsWithVariants,
								{
									productId: 0,
									variantId: 0,
									id: uniqid(),
									quantity: 0,
								},
							])
						}
					>
						Add Deliverable
					</Button>
				</div>
			)}

			<div className={modalStyles.mtMediumLarge}>
				<p className={modalStyles.secondaryText}>
					Customers will receive this delivery once they complete this reward.
				</p>
			</div>
		</div>
	)
}

export default RewardTypeForm
