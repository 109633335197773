import { privateAPI } from './API'

export const ManagersSettingsService = {
	getSettings: async (shopId: string) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/settings/managers/info`)
	},
	updateSettings: async (
		shopId: string,
		data: {
			is2FAForced: boolean
		}
	) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/managers/settings`, data)
	},
}
