import React, { FC } from 'react'
import styles from './StatusBadges.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

interface StatusCodeBadgeProps {
	statusCode: number
}

const StatusCodeBadge: FC<StatusCodeBadgeProps> = ({ statusCode }) => {
	const getStatusOptions = () => {
		if (statusCode > 200 && statusCode < 300) {
			return {
				className: 'green',
				text: `${statusCode} OK`,
				iconId: 'checkedCircle',
			}
		} else if (statusCode > 300 && statusCode < 400) {
			return {
				className: 'brown',
				text: `${statusCode} RDR`,
				iconId: 'exclamationMarkCircle',
			}
		}

		return {
			className: 'red',
			text: `${statusCode} ERR`,
			iconId: 'xMarkCircle',
		}
	}

	const statusOptions = getStatusOptions()

	return (
		<div className={`${styles.statusBadge} ${styles[statusOptions.className]}`}>
			{statusOptions.text}
			<SpriteIcon iconId={statusOptions.iconId} width={16} height={16} />
		</div>
	)
}

export default StatusCodeBadge
