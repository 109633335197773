export const warrantySelectOptions = [
	{ value: '0', label: 'No Warranty' },
	{ value: '-1', label: 'Life Time' },
	{ value: '900', label: '15 minutes' },
	{ value: '3600', label: '1 hour' },
	{ value: '7200', label: '2 hours' },
	{ value: '43200', label: '12 hours' },
	{ value: '86400', label: '24 hours' },
	{ value: '604800', label: '7 days' },
	{ value: '1209600', label: '14 days' },
	{ value: '2592000', label: '30 days' },
	{ value: '31536000', label: '365 days' },
	{ value: 'manual', label: 'Input Manually' },
]
