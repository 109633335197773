import React, { FC } from 'react'
import styles from './ProductAdvisor.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { CreateProductSectionIds } from '../../types/CreateProductSectionIds/CreateProductSectionIds'
import { ListingVisibility } from '../../types/ListingVisibility/ListingVisibility'
import { scrollIntoViewAndFocus } from '../../helpers/scrollIntoViewAndFocus'
import { VariantsDelivery } from '../../types/CreateVariants/VariantsDelivery'
import { ProductVariantsTab } from '../../types/ProductVariantsTab/ProductVariantsTab'

interface ProductAdvisorCriticalAlertsProps {
	titleValue: string
	visibility: ListingVisibility
	currentVariantsTab: { [variantIndex: string]: ProductVariantsTab }
	setCurrentVariantsTab: (value: { [variantIndex: string]: ProductVariantsTab }) => void
	deliveryConfigurationKeys: string[]
	isZeroStockDone: boolean | number
	criticalAlerts: boolean[]
	currentVariantIndex: string
}

const ProductAdvisorCriticalAlerts: FC<ProductAdvisorCriticalAlertsProps> = ({
	visibility,
	titleValue,
	setCurrentVariantsTab,
	currentVariantsTab,
	deliveryConfigurationKeys,
	isZeroStockDone,
	criticalAlerts,
	currentVariantIndex,
}) => {
	const onAdvisorClickHandler = (tab: ProductVariantsTab, id: string) => {
		setCurrentVariantsTab({
			...currentVariantsTab,
			[currentVariantIndex]: tab,
		})
		scrollIntoViewAndFocus(id)
	}

	return (
		<div className={styles.productAdvisor}>
			<header className={styles.header}>
				<div className={styles.criticalAlert}>
					<SpriteIcon iconId={'exclamationMarkTriangle'} width={16} height={16} />
					<p>
						{criticalAlerts.filter(boolean => boolean).length} Critical Alert
						{criticalAlerts.filter(boolean => boolean).length > 1 ? 's' : ''}
					</p>
				</div>
				<p className={styles.description}>
					Before you create this product, we recommend fixing these critical issues.
				</p>
			</header>

			<div className={styles.body}>
				<div className={`${styles.advices} ${styles.mt0}`}>
					{!titleValue && (
						<div
							className={`${styles.adviceItem} ${styles.alert}`}
							onClick={() => scrollIntoViewAndFocus('productTitle')}
						>
							<div>
								<h3 className={styles.adviceTitle}>Title</h3>
								<p className={styles.adviceDescription}>Add a short title to your product.</p>
							</div>

							<SpriteIcon
								className={styles.asteriskSymbol}
								iconId={'asteriskSymbol'}
								width={10}
								height={10}
							/>
						</div>
					)}
					{visibility !== ListingVisibility.PUBLIC && (
						<div className={`${styles.adviceItem} ${styles.alert}`}>
							<div>
								<h3 className={styles.adviceTitle}>Visibility</h3>
								<p className={styles.adviceDescription}>Set your product visibility to PUBLIC.</p>
							</div>
							<SpriteIcon
								className={styles.asteriskSymbol}
								iconId={'asteriskSymbol'}
								width={10}
								height={10}
							/>
						</div>
					)}
					{!isZeroStockDone && (
						<div
							className={`${styles.adviceItem} ${styles.alert}`}
							onClick={() =>
								onAdvisorClickHandler(ProductVariantsTab.DELIVERY, CreateProductSectionIds.VARIANTS)
							}
						>
							<div>
								<h3 className={styles.adviceTitle}>Add Deliverable(s)</h3>
								<p className={styles.adviceDescription}>
									Select from serials, downloads, service, etc.
								</p>
							</div>

							<SpriteIcon
								className={styles.asteriskSymbol}
								iconId={'asteriskSymbol'}
								width={10}
								height={10}
							/>
						</div>
					)}
					{!isZeroStockDone && (
						<div
							className={`${styles.adviceItem} ${styles.alert} `}
							onClick={() =>
								onAdvisorClickHandler(ProductVariantsTab.DELIVERY, CreateProductSectionIds.VARIANTS)
							}
						>
							<div>
								<h3 className={styles.adviceTitle}>Zero Stock</h3>
								<p className={styles.adviceDescription}>Add stock to all deliverables</p>
							</div>

							<SpriteIcon
								className={styles.asteriskSymbol}
								iconId={'asteriskSymbol'}
								width={10}
								height={10}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default ProductAdvisorCriticalAlerts
