import React, { FC, useState } from 'react'
import styles from './Tables.module.scss'
import { PartOrder } from '../../types/PartOrder/PartOrder'
import getSymbolFromCurrency from 'currency-symbol-map'
import PartOrderStatusBadge from '../UI/StatusBadges/PartOrderStatusBadge'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import { ActionMenuOption } from '../../types/ActionMenuOption/ActionMenuOption'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { PartOrderDeliveryStatus } from '../../types/PartOrderDeliveryStatus/PartOrderDeliveryStatus'
import { NavLink } from 'react-router-dom'

interface PartOrdersTableProps {
	partOrders: PartOrder[]
	onViewClick: (id: number) => void
	onReplacementClick: (id: number, quantity: number) => void
	isOrdersModifyAvailable: boolean
}

interface PartOrdersTableBodyItemProps {
	partOrder: PartOrder
	onViewClick: (id: number) => void
	onReplacementClick: (id: number, quantity: number) => void
	isOrdersModifyAvailable: boolean
}

const PartOrdersTableBodyItem: FC<PartOrdersTableBodyItemProps> = ({
	partOrder,
	onViewClick,
	onReplacementClick,
	isOrdersModifyAvailable,
}) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)

	const actionButtons: ActionMenuOption[] = [
		{
			title: 'View Delivered Goods',
			icon: {
				id: 'truck',
				height: 10,
				width: 14,
			},
			onClick: () => onViewClick(partOrder.id),
		},
	]

	isOrdersModifyAvailable &&
		actionButtons.push({
			title: 'Issue Replacement',
			icon: {
				id: 'send',
				width: 12,
				height: 14,
			},
			onClick: () => onReplacementClick(partOrder.id, partOrder.quantity),
		})

	return (
		<div
			className={styles.trWrapper}
			style={{
				paddingBottom: isActionMenuActive ? +actionButtons.length * 50 : 5,
			}}
		>
			<tr>
				<td className={styles.large}>
					<div className={styles.itemInfo}>
						<div className={styles.itemImage}></div>
						<div>
							<p>
								<NavLink to={`/products/edit/${partOrder.productWithVariant.id}`}>
									{partOrder.productWithVariant.name}
								</NavLink>
							</p>
							<span>Variant: {partOrder.productWithVariant.variantName}</span>
						</div>
					</div>
				</td>
				<td>
					<p>
						{getSymbolFromCurrency(partOrder.productWithVariant.price.currency)}
						{partOrder.productWithVariant.price.amount}
					</p>
					<span>{partOrder.quantity} quantity</span>
				</td>
				<td>
					<PartOrderStatusBadge partOrderStatus={partOrder.deliveryStatus} />
				</td>
				<td>
					{partOrder.deliveryStatus !== PartOrderDeliveryStatus.NOT_DELIVERED ? (
						<Button
							style={ButtonStyles.BORDERED}
							disableShadow={true}
							icon={{
								id: 'plus',
								width: 13,
								height: 13,
								align: ButtonsIconAlign.LEFT,
							}}
							onClick={() => onViewClick(partOrder.id)}
						>
							View
						</Button>
					) : (
						'-'
					)}
				</td>

				<td className={styles.small}>
					<ActionMenu
						actionButtons={actionButtons}
						isActive={isActionMenuActive}
						setIsActive={setIsActionMenuActive}
					/>
				</td>
			</tr>
		</div>
	)
}

const PartOrdersTable: FC<PartOrdersTableProps> = ({
	partOrders,
	onViewClick,
	onReplacementClick,
	isOrdersModifyAvailable,
}) => {
	return (
		<table className={`${styles.table} ${styles.fullWidth}`}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.large}>Product</th>
					<th>Details</th>
					<th>Status</th>
					<th>DELIVERY</th>
					<th className={styles.small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{partOrders.map(partOrder => (
					<PartOrdersTableBodyItem
						partOrder={partOrder}
						key={partOrder.id}
						onViewClick={onViewClick}
						onReplacementClick={onReplacementClick}
						isOrdersModifyAvailable={isOrdersModifyAvailable}
					/>
				))}
			</tbody>
		</table>
	)
}

export default PartOrdersTable
