import { privateAPI } from './API'
import { TrustpilotRequestType } from '../types/TrustpilotRequestType/TrustpilotRequestType'

export const IntegrationsSettingsService = {
	getIntegrations: async (shopId: string) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/settings/integrations`)
	},
	updateTrustpilot: async (
		shopId: string,
		data: {
			bccEmail: string
			type: TrustpilotRequestType
		}
	) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/integrations/trustpilot`, data)
	},
}
