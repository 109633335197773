export enum NotificationEvent {
	ORDER_CREATED = 'ORDER_CREATED',
	ORDER_EXPIRED = 'ORDER_EXPIRED',
	ORDER_CANCELLED = 'ORDER_CANCELLED',
	ORDER_PENDING = 'ORDER_PENDING',
	ORDER_COMPLETED = 'ORDER_COMPLETED',
	ORDER_FULL_DELIVERY_FAILURE = 'ORDER_FULL_DELIVERY_FAILURE',
	ORDER_PARTIALLY_DELIVERED = 'ORDER_PARTIALLY_DELIVERED',
	ORDER_REFUNDED = 'ORDER_REFUNDED',

	CHARGE_CREATED = 'CHARGE_CREATED',
	CHARGE_PENDING = 'CHARGE_PENDING',
	CHARGE_PAID = 'CHARGE_PAID',
	CHARGE_EXPIRED = 'CHARGE_EXPIRED',
	CHARGE_CANCELLED = 'CHARGE_CANCELLED',
	CHARGE_REFUNDED = 'CHARGE_REFUNDED',

	REVIEW_CREATED = 'REVIEW_CREATED',
	REVIEW_EDITED = 'REVIEW_EDITED',
	REVIEW_SELLER_ANSWERED = 'REVIEW_SELLER_ANSWERED',
	REVIEW_APPEALED = 'REVIEW_APPEALED',

	WAREHOUSE_LOW_STOCK = 'WAREHOUSE_LOW_STOCK',
	WAREHOUSE_OUT_OF_STOCK = 'WAREHOUSE_OUT_OF_STOCK',
	TICKET_CREATED = 'TICKET_CREATED',
	TICKET_MESSAGE_RECEIVED = 'TICKET_MESSAGE_RECEIVED',
}
