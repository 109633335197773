export enum DeliveryConfigurationType {
	TEXT = 'TEXT',
	SERIALS = 'SERIALS',
	DISCORD = 'DISCORD',
	TELEGRAM = 'TELEGRAM',
	EBOOK = 'EBOOK',
	LINK = 'LINK',
	DOWNLOAD = 'DOWNLOAD',
	CUSTOM = 'CUSTOM',
}
