import React from 'react'
import styles from './Checkbox.module.scss'

interface CheckboxProps {
	id: string
	checked: boolean
	disabled?: boolean
	contrast?: boolean
	setChecked: (value: boolean) => void
	onClick?: (event: React.MouseEvent) => void
}

const Checkbox: React.FC<CheckboxProps> = ({
	id,
	setChecked,
	checked,
	onClick,
	disabled,
	contrast = false,
}) => {
	return (
		<div
			className={`${styles.customCheckbox} ${contrast ? styles.contrastCheckbox : ''} ${
				disabled ? styles.disabled : ''
			}`}
			onClick={onClick}
		>
			<input
				type='checkbox'
				id={id}
				checked={checked}
				onChange={event => setChecked(event.target.checked)}
				disabled={disabled}
			/>
			<label htmlFor={id} />
		</div>
	)
}

export default Checkbox
