import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Textarea from '../../UI/FormComponents/Textarea'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BulkUpdateModalsProps } from '../../../types/BulkUpdateModalsProps/BulkUpdateModalsProps'
import { useAPI } from '../../../hooks/useAPI'
import { BulkUpdateProductsService } from '../../../API/BulkUpdateProductsService'
import { useAppContext } from '../../../hooks/useAppContext'
import CustomMdEditor from '../../UI/CustomMDEditor/CustomMDEditor'

const UpdateCustomerNoteModal: FC<BulkUpdateModalsProps> = ({
	productIds,
	onClose,
	getProducts,
}) => {
	const [customerNote, setCustomerNote] = useState<undefined | string>('')
	const { shop } = useAppContext()

	const [isUpdateCustomerNoteLoading, , updateCustomerNote] = useAPI(
		() =>
			BulkUpdateProductsService.updateCustomerNote(shop?.id || '', {
				customerNote: customerNote || '',
				productIds,
			}),
		false,
		'Products successfully updated',
		{
			onSuccess: () => {
				onClose()
				getProducts()
			},
		}
	)

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Update Customer Note</h1>
			</header>

			<div className={styles.body}>
				<p className={styles.description}>
					Enter the custom note you'd like your customers to see when they access their deliverables
					via the customer dashboard.
				</p>

				<div className={styles.fieldWrapper}>
					<h2>Note to Customer</h2>
					<CustomMdEditor value={customerNote} setValue={setCustomerNote} />
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						disableShadow={true}
						icon={{
							id: 'download',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						onClick={updateCustomerNote}
						isLoading={isUpdateCustomerNoteLoading}
					>
						Save Update
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default UpdateCustomerNoteModal
