import React, { FC, useEffect, useState } from 'react'
import styles from './DateController.module.scss'
import { format } from 'date-fns'
import CustomSelect, { SelectStyles } from '../CustomSelect/CustomSelect'
import { DatePeriod } from '../../../types/DatePeriod/DatePeriod'
import { datePeriodsSelectOptions } from '../../../helpers/datePeriodsSelectOptions'
import DateRangePicker from '../DateRangePicker/DateRangePicker'
import Spinner from '../Spinner/Spinner'
import { getDatePeriod } from '../../../helpers/getDatePeriod'

interface DateControllerProps {
	dateRanges: {
		fromDate: Date | null
		toDate: Date | null
	}
	setDateRanges: (value: { fromDate: Date | null; toDate: Date | null }) => void
	selectDate: DatePeriod
	setSelectDate: (value: DatePeriod) => void
	isLoading: boolean
}

const DateController: FC<DateControllerProps> = ({
	selectDate,
	setSelectDate,
	setDateRanges,
	dateRanges,
	isLoading,
}) => {
	const [fromDate, setFromDate] = useState<Date | null>(dateRanges.fromDate)
	const [toDate, setToDate] = useState<Date | null>(dateRanges.toDate)

	const getDateRanges = () => {
		const dates: {
			fromDate: Date | null
			toDate: Date | null
		} = {
			fromDate: new Date(),
			toDate: new Date(),
		}

		switch (selectDate) {
			case DatePeriod.allTime:
				dates.fromDate = new Date('2021-01-01')
				dates.toDate = new Date()
				break
			case DatePeriod.lastDay:
				dates.fromDate = getDatePeriod.getLastDayDate()
				dates.toDate = new Date()
				break
			case DatePeriod.lastMonth:
				dates.fromDate = getDatePeriod.getLastMonthDate()
				dates.toDate = new Date()
				break
			case DatePeriod.last3Months:
				dates.fromDate = getDatePeriod.getLastMonthDate(3)
				dates.toDate = new Date()
				break
			case DatePeriod.lastWeek:
				dates.fromDate = getDatePeriod.getLastWeeksDate()
				dates.toDate = new Date()
				break
			case DatePeriod.lastYear:
				dates.fromDate = getDatePeriod.getLastYearDate()
				dates.toDate = new Date()
				break
		}

		return dates
	}

	useEffect(() => {
		const dates = getDateRanges()
		setDateRanges(dates)
		setFromDate(dates.fromDate)
		setToDate(dates.toDate)
	}, [selectDate])

	useEffect(() => {
		setDateRanges({
			fromDate: fromDate,
			toDate: toDate,
		})
	}, [toDate, fromDate])

	return (
		<div className={styles.dateControl}>
			<p className={styles.dateControlText}>
				Showing data for{' '}
				{dateRanges.fromDate && dateRanges.toDate
					? `${format(dateRanges.fromDate, 'MMMM dd, yyyy')} - ${format(
							dateRanges.toDate,
							'MMMM dd, yyyy'
					  )}`
					: 'All Time'}
			</p>

			<div className={styles.dateControlEnd}>
				<CustomSelect
					style={SelectStyles.light}
					value={selectDate}
					setValue={value => setSelectDate(value as DatePeriod)}
					options={datePeriodsSelectOptions}
				/>

				{isLoading ? (
					<Spinner className={styles.spinner} />
				) : (
					<DateRangePicker
						fromDate={fromDate}
						setFromDate={setFromDate}
						toDate={toDate}
						setToDate={setToDate}
					/>
				)}
			</div>
		</div>
	)
}

export default DateController
