import React, { FC, useState } from 'react'
import styles from './Tables.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { NavLink } from 'react-router-dom'
import Checkbox from '../UI/Checkbox/Checkbox'
import ProductTypeBadge from '../UI/ProductTypeBadge/ProductTypeBadge'
import { Customer } from '../../types/Customer/Customer'
import { cleanEmail } from '../../helpers/cleanEmail'
import getSymbolFromCurrency from 'currency-symbol-map'
import { format } from 'date-fns'
import { cutOffEmail } from '../../helpers/cutOffEmail'

interface CustomersTableProps {
	customers: Customer[]
}

interface CustomersTableBodyItemProps {
	customer: Customer
}

const CustomersTableBodyItem: FC<CustomersTableBodyItemProps> = ({ customer }) => {
	const cleanedEmail = cleanEmail(customer?.email || '')

	return (
		<NavLink className={styles.trWrapper} to={`/customers/${customer.id}`}>
			<tr>
				<td className={styles.large}>
					<div className={styles.itemInfo}>
						<div className={styles.itemImage}>
							{cleanedEmail[0]}
							{cleanedEmail[cleanedEmail.length - 1]}
						</div>
						<div>
							<p>{cutOffEmail(customer?.email || '')}</p>
							<span>{customer.email}</span>
						</div>
					</div>
				</td>
				<td>
					{customer.balance && getSymbolFromCurrency(customer.balance.currency)}
					{customer.balance && customer.balance.amount}
				</td>
				<td>{customer.totalOrders}</td>
				<td>${customer.totalSpendUSD}</td>
				<td>
					<p>
						{customer.firstPurchase && format(new Date(customer.firstPurchase), 'MMM dd, yyyy')}
					</p>
					<span>
						{customer.firstPurchase && format(new Date(customer.firstPurchase), 'hh:mm aa z')}
					</span>
				</td>
				<td className={styles.small}>
					<SpriteIcon className={styles.arrowRight} iconId={'arrowDown'} width={10} height={4} />
				</td>
			</tr>
		</NavLink>
	)
}

const CustomersTable: FC<CustomersTableProps> = ({ customers }) => {
	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.large}>Customer</th>
					<th>Balance</th>
					<th>Total Orders</th>
					<th>Total Spend</th>
					<th>First Purchase</th>
					<th className={styles.small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{customers.map(customer => (
					<CustomersTableBodyItem customer={customer} key={customer.id} />
				))}
			</tbody>
		</table>
	)
}

export default CustomersTable
