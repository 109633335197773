import React, { useEffect, useState } from 'react'
import { useAPI } from '../../hooks/useAPI'
import { UserService } from '../../API/UserService'
import styles from './VerifyEmailPage.module.scss'
import Spinner from '../../components/UI/Spinner/Spinner'
import { SpriteIcon } from '../../components/UI/SpriteIcon/SpriteIcon'
import { useLocation } from 'react-router-dom'

const VerifyEmailPage = () => {
	const location = useLocation()
	const params = new URLSearchParams(location.search)
	const token = params.get('token')
	const [isVerified, setIsVerified] = useState(false)

	const [isCompleteVerificationLoading, , completeVerification] = useAPI(
		() => UserService.completeEmailVerification(token || ''),
		false,
		'Email successfully verified',
		{
			onSuccess: () => {
				setIsVerified(true)
			},
		}
	)

	useEffect(() => {
		completeVerification()
	}, [])

	if (isCompleteVerificationLoading && !isVerified)
		return (
			<div className={styles.informationBlock}>
				<Spinner />
			</div>
		)

	if (!isVerified) {
		return (
			<div className={styles.informationBlock}>
				<SpriteIcon className={styles.red} iconId={'circleX'} width={50} height={50} />
				<h1>Email Not Verified</h1>
				<p>Something went wrong when trying to verify your email address.</p>
			</div>
		)
	}

	return (
		<div className={styles.informationBlock}>
			<SpriteIcon className={styles.green} iconId={'checkedCircle'} width={50} height={50} />
			<h1>Email Verified</h1>
			<p>Your email address was successfully verified. You can continue using the application.</p>
		</div>
	)
}

export default VerifyEmailPage
