import React, { FC, useEffect, useState } from 'react'
import styles from './PaymentSetting.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { CustomPaymentAccountsFields } from '../../types/PaymentAccountsFields/CustomPaymentAccountsFields'
import { PaymentAccountStatus } from '../../types/CashAppAccountStatus/PaymentAccountStatus'
import { CustomGatewayAccount } from '../../types/PaymentAccounts/CustomGatewayAccount/CustomGatewayAccount'
import { ExplorerPlugin } from '../../types/Plugin/ExplorerPlugin'
import { getImageUrl } from '../../helpers/getImageUrl'
import PaymentAccountsTable from '../Tables/PaymentAccountsTables/PaymentAccountsTable'
import { PaymentsSettingsService } from '../../API/PaymentsSettingsService'
import { useAPI } from '../../hooks/useAPI'
import { Shop } from '../../types/Shop/Shop'
import Modal from '../Modals/Modal'
import ConfirmModal from '../Modals/ConfirmModal/ConfirmModal'
import { closeModal } from '../../helpers/closeModal'
import { PaymentMethod } from '../../types/PaymentMethod/PaymentMethod'

interface CustomPaymentSettingProps {
	paymentAccounts: CustomGatewayAccount[] | undefined
	overridePaymentMethods: PaymentMethod[] | undefined
	plugin: ExplorerPlugin
	shop: Shop | null
	onEditClick: () => void
	activateModal: () => void
	setEditingAccountId: (value: number | null) => void
	getPayments: () => void
	isSettingsPaymentsModifyAvailable: boolean
}

const CustomPaymentSetting: FC<CustomPaymentSettingProps> = ({
	activateModal,
	onEditClick,
	paymentAccounts,
	shop,
	setEditingAccountId,
	getPayments,
	plugin,
	isSettingsPaymentsModifyAvailable,
	overridePaymentMethods,
}) => {
	const [paymentAccountsFields, setPaymentAccountsFields] = useState<CustomPaymentAccountsFields>(
		{}
	)
	const [deletingGatewayId, setDeletingGatewayId] = useState<number | null>(null)
	const [isDeleteModalActive, setIsDeleteModalActive] = useState(false)
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

	const [isDeleteGatewayLoading, , deleteGateway] = useAPI(
		() =>
			PaymentsSettingsService.updateGateway(shop?.id || '', plugin?.name || '', {
				accounts: [
					...(paymentAccounts
						?.filter(
							account => account.gatewayName === plugin?.name && account.id !== deletingGatewayId
						)
						.map(account => ({
							id: account.id,
							savedSettings: account.savedSettings,
							isEnabled: account.isEnabled,
							maxAmountOfOrdersPerDay: account.maxAmountOfOrdersPerDay,
							maxAmountOfOrdersPerMonth: account.maxAmountOfOrdersPerMonth,
							maxAmountOfOrdersPerWeek: account.maxAmountOfOrdersPerWeek,
							maxRevenuePerDay: account.maxRevenuePerDay,
							maxRevenuePerMonth: account.maxRevenuePerMonth,
							maxRevenuePerWeek: account.maxRevenuePerWeek,
							priority: account.priority,
							blockVpnProxy: account?.blockVpnProxy || false,
							status: account.status,
						})) || []),
				],
				overrideShownPaymentMethods: overridePaymentMethods || null,
			}),
		false,
		'Payment account successfully deleted',
		{
			onSuccess: () => {
				getPayments()
			},
		}
	)

	const [isUpdateGatewayLoading, , updateGateway] = useAPI(
		() =>
			PaymentsSettingsService.updateGateway(shop?.id || '', plugin.name, {
				accounts: Object.keys(paymentAccountsFields).map(key => {
					const prevData = paymentAccounts?.find(account => account.id === +key)

					return {
						id: prevData?.id,
						priority: paymentAccountsFields[+key].priority,
						savedSettings: {
							...prevData?.savedSettings,
							[paymentAccountsFields[+key].mainField.name]:
								paymentAccountsFields[+key].mainField.value,
						},
						maxRevenuePerWeek: prevData?.maxRevenuePerWeek,
						maxRevenuePerDay: prevData?.maxRevenuePerDay,
						maxRevenuePerMonth: prevData?.maxRevenuePerMonth,
						maxAmountOfOrdersPerWeek: prevData?.maxAmountOfOrdersPerWeek,
						maxAmountOfOrdersPerMonth: prevData?.maxAmountOfOrdersPerMonth,
						maxAmountOfOrdersPerDay: prevData?.maxAmountOfOrdersPerDay,
						blockVpnProxy: prevData?.blockVpnProxy || false,
					}
				}),
				overrideShownPaymentMethods: overridePaymentMethods || null,
			}),
		false,
		'Payment accounts successfully saved',
		{
			onSuccess: () => {
				getPayments()
				closeDeleteModal()
			},
		}
	)

	const closeDeleteModal = () => {
		closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)
		setDeletingGatewayId(null)
	}

	const onDeleteClickHandler = (gatewayId: number) => {
		setDeletingGatewayId(gatewayId)
		setIsDeleteModalActive(true)
	}

	useEffect(() => {
		if (paymentAccounts) {
			const object: CustomPaymentAccountsFields = {}
			const mainField = plugin.setupParameters.find(item => item.isMain)

			paymentAccounts
				?.filter(paymentAccount => paymentAccount.gatewayName === plugin.name)
				.map(paymentAccount => {
					object[paymentAccount.id] = {
						isEnabled: paymentAccount.status !== PaymentAccountStatus.DISABLED,
						id: paymentAccount.id,
						mainField: {
							name: mainField?.variableName || '',
							value: paymentAccount.savedSettings[mainField?.variableName || ''],
						},
						priority: paymentAccount.priority,
					}
				})

			setPaymentAccountsFields(object)
		}
	}, [paymentAccounts])

	return (
		<>
			{isDeleteModalActive && (
				<Modal
					closeModal={closeDeleteModal}
					visible={isDeleteModalVisible}
					setVisible={setIsDeleteModalVisible}
				>
					<ConfirmModal
						title={`Delete ${plugin.displayName} Account`}
						description={`You’re about to delete ${plugin.displayName} account. Are you sure you want to delete this account? This cannot be undone.`}
						onConfirm={deleteGateway}
						confirmButtonText={`Delete ${plugin.displayName}  Account`}
						buttonIcon={{
							id: 'trashBin',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						onClose={closeDeleteModal}
						isButtonLoading={isDeleteGatewayLoading}
					/>
				</Modal>
			)}
			<div className={`${styles.paymentSetting}`}>
				<div className={styles.paymentSettingInner}>
					<div className={styles.payment}>
						<div className={styles.logo}>
							<img src={getImageUrl(plugin.logoCfImageId)} alt={plugin.name} />
						</div>
						<div>
							<p>{plugin.displayName}</p>
							<span>{plugin.description}</span>
						</div>
					</div>
				</div>

				<div className={styles.body}>
					{paymentAccounts && (
						<PaymentAccountsTable
							onDeleteClick={onDeleteClickHandler}
							onEditClick={(id: number) => {
								setEditingAccountId(id)
								activateModal()
							}}
							mainFieldSetupParameter={plugin.setupParameters.find(item => item.isMain)}
							paymentsAccounts={paymentAccounts.filter(
								account => account.gatewayName === plugin.name
							)}
							paymentAccountsFields={paymentAccountsFields}
							setPaymentAccountsFields={setPaymentAccountsFields}
							isSettingsPaymentsModifyAvailable={isSettingsPaymentsModifyAvailable}
						/>
					)}
				</div>

				{isSettingsPaymentsModifyAvailable && (
					<footer className={styles.footer}>
						<Button
							style={ButtonStyles.BORDERED}
							icon={{
								id: 'plus',
								width: 13,
								height: 13,
								align: ButtonsIconAlign.LEFT,
							}}
							disableShadow={true}
							onClick={activateModal}
						>
							Add New Account
						</Button>

						<Button
							style={ButtonStyles.BORDERED}
							icon={{
								id: 'download',
								width: 13,
								height: 13,
								align: ButtonsIconAlign.LEFT,
							}}
							disableShadow={true}
							onClick={updateGateway}
							isLoading={isUpdateGatewayLoading}
						>
							Save Settings
						</Button>
					</footer>
				)}
			</div>
		</>
	)
}

export default CustomPaymentSetting
