import React, { FC } from 'react'
import styles from './ImageViewer.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

interface ImageViewerProps {
	path: string
	onDeleteClick: () => void
}

const ImageViewer: FC<ImageViewerProps> = ({ path, onDeleteClick }) => {
	return (
		<div className={`${styles.imageViewer} `}>
			<img src={`https://imagedelivery.net/${path}/productCard`} alt={path} />
			<button className={styles.deleteButton} onClick={onDeleteClick}>
				<SpriteIcon iconId={'trashBin'} width={14} height={15} />
			</button>
		</div>
	)
}

export default ImageViewer
