import React, { useRef, useState } from 'react'
import styles from './ShopSelect.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import { useAppContext } from '../../hooks/useAppContext'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { useNavigate } from 'react-router-dom'
import { Shop } from '../../types/Shop/Shop'
import { Subscriptions } from '../../types/Subscriptions/Subscriptions'
import ShopStatusBadge from '../ShopStatusBadge/ShopStatusBadge'

const ShopSelect = () => {
	const [isActive, setIsActive] = useState(false)
	const {
		user,
		shop: currentShop,
		setShop,
		setRequiredSubscription,
		setIsSubscriptionModalActive,
	} = useAppContext()
	const profileMenuRef = useRef<HTMLDivElement>(null)
	const navigate = useNavigate()
	useOutsideClick(profileMenuRef, () => setIsActive(false), !isActive)
	const selectShop = (shop: Shop) => {
		setShop(shop)
		localStorage.setItem('shopId', shop.id.toString())
		navigate('/')
	}

	return (
		<div className={styles.profileMenu} ref={profileMenuRef}>
			<div
				className={`${styles.userWrapper} ${isActive && styles.active}`}
				onClick={() => setIsActive(!isActive)}
			>
				<div className={styles.iconWrapper}>
					<SpriteIcon iconId={'market'} width={14} height={14} />
				</div>
				<div className={styles.title}>
					<h2>{currentShop?.name}</h2>
					<ShopStatusBadge statusKey={currentShop?.status} />
				</div>
				<div className={styles.ellipses}>
					<SpriteIcon iconId={'ellipses'} width={12} height={3} />
				</div>
			</div>

			<div className={`${styles.shops} ${isActive && styles.active}`}>
				{user?.shops.map(shop => (
					<div
						className={`${styles.shop} ${currentShop?.id === shop.id && styles.selected}`}
						onClick={() => selectShop(shop)}
						key={shop.id}
					>
						<div>
							<p>{shop.name}</p>
						</div>
						<ShopStatusBadge statusKey={shop.status} />
						<div style={{
							padding: '0 5px 0 10px',
						}}><SpriteIcon className={styles.checked} iconId={'checked2'} width={15} height={11} /></div>
					</div>
				))}

				<button
					className={styles.createStoreButton}
					onClick={() => {
						if (user && user.shops.length < user.subscription.perks.shopsLimit) {
							navigate('/auth/setup-store')
						} else {
							setIsSubscriptionModalActive(true)
							setRequiredSubscription(Subscriptions.CORE_PLUS)
						}
					}}
				>
					<SpriteIcon iconId='plus' height={15} width={15} />
					<p>Create New Store</p>
				</button>
			</div>
		</div>
	)
}

export default ShopSelect
