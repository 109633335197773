import { privateAPI } from './API'
import { CreateCustomGateway } from '../types/CreateCustomGateway/CreateCustomGateway'
import { PluginApprovalStatus } from '../types/PluginApprovalStatus/PluginApprovalStatus'

export const CustomGatewaysService = {
	getAll: async (searchString?: string, PageNumber?: number, PageSize?: number) => {
		return await privateAPI.get(`/v1/dash/gateway-plugins/me`, {
			params: {
				PageNumber,
				searchString,
				PageSize,
			},
		})
	},
	create: async (data: CreateCustomGateway) => {
		return await privateAPI.post(`/v1/dash/gateway-plugins/me`, data)
	},
	getOne: async (pluginId: number) => {
		return await privateAPI.get(`/v1/dash/gateway-plugins/me/${pluginId}`)
	},
	delete: async (pluginId: number) => {
		return await privateAPI.delete(`/v1/dash/gateway-plugins/me/${pluginId}`)
	},
	update: async (pluginId: number, data: CreateCustomGateway) => {
		return await privateAPI.put(`/v1/dash/gateway-plugins/me/${pluginId}`, data)
	},
	approve: async (pluginId: number) => {
		return await privateAPI.post(`/v1/dash/gateway-plugins/me/${pluginId}/approve`)
	},
	getManifest: async (pluginId: number) => {
		return await privateAPI.get(`/v1/dash/gateway-plugins/me/${pluginId}/manifest`)
	},
	getVersions: async (
		pluginId: number,
		PageNumber?: number,
		PageSize?: number,
		approvalStatusFilter?: PluginApprovalStatus | null
	) => {
		return await privateAPI.get(`/v1/dash/gateway-plugins/me/${pluginId}/versions`, {
			params: {
				PageNumber,
				PageSize,
				approvalStatusFilter,
			},
		})
	},
	getVersionManifest: async (pluginId: number, pluginVersionId: number) => {
		return await privateAPI.get(
			`/v1/dash/gateway-plugins/me/${pluginId}/versions/${pluginVersionId}/manifest`
		)
	},
	deleteVersion: async (pluginId: number, pluginVersionId: number) => {
		return await privateAPI.delete(
			`/v1/dash/gateway-plugins/me/${pluginId}/versions/${pluginVersionId}`
		)
	},
	activateVersion: async (pluginId: number, pluginVersionId: number) => {
		return await privateAPI.post(
			`/v1/dash/gateway-plugins/me/${pluginId}/versions/${pluginVersionId}/activate`
		)
	},
}
