import React, { FC } from 'react'
import styles from './HelpLink.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

interface HelpLinkProps {
	href: string
	text: string
}
const HelpLink: FC<HelpLinkProps> = ({ text, href }) => {
	return (
		<a className={styles.helpLink} target={'_blank'} href={href}>
			{text}
			<SpriteIcon iconId={'blankWindow'} width={10} height={10} />
		</a>
	)
}

export default HelpLink
