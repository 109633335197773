import React, { useEffect, useState } from 'react'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import styles from '../Settings.module.scss'
import Input from '../../../components/UI/FormComponents/Input'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../components/UI/Button/Button'
import GoogleAuthenticator from '../../../components/GoogleAuthenticator/GoogleAuthenticator'
import Modal from '../../../components/Modals/Modal'
import { closeModal } from '../../../helpers/closeModal'
import SetupGoogleTFAModal from '../../../components/Modals/SetupGoogleTFAModal/SetupGoogleTFAModal'
import { useAPI } from '../../../hooks/useAPI'
import { SecuritySettings } from '../../../types/SecuritySettings/SecuritySettings'
import { UserSecurityService } from '../../../API/UserSecurityService'
import PageLoading from '../../../components/UI/PageLoading/PageLoading'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'
import { useAppContext } from '../../../hooks/useAppContext'
import { User } from '../../../types/User/User'
import { UserService } from '../../../API/UserService'

const SettingsAccountPage = () => {
	const [isGoogleTFAModalActive, setIsGoogleTFAModalActive] = useState(false)
	const [isGoogleTFAModalVisible, setIsGoogleTFAModalVisible] = useState(false)
	const [email, setEmail] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [confirmNewPassword, setConfirmNewPassword] = useState('')
	const [oldPassword, setOldPassword] = useState('')
	const [errors, setErrors] = useState<FormErrors>({})
	const { setUser } = useAppContext()

	const [isGetSecuritySettingsLoading, securitySettings, getSecuritySettings] =
		useAPI<SecuritySettings>(() => UserSecurityService.getSecurity(), false)

	const [, , getUser] = useAPI<User>(() => UserService.get(), false, undefined, {
		onSuccess: response => setUser(response.data.data),
	})

	const [isChangeEmailLoading, , changeEmail] = useAPI(
		() => UserSecurityService.changeEmail({ email: email }),
		false,
		'Email successfully changed',
		{
			validator: () => {
				const emailValidator = fieldValidators.emailValidator(email)

				setErrors({
					...errors,
					email: emailValidator,
				})

				return !emailValidator
			},
			onSuccess: () => {
				getSecuritySettings()
				getUser()
			},
		}
	)

	const [isChangePasswordLoading, , changePassword] = useAPI(
		() =>
			UserSecurityService.changePassword({ oldPassword: oldPassword, newPassword: newPassword }),
		false,
		'Password successfully changed',
		{
			validator: () => {
				const oldPasswordValidator = fieldValidators.passwordValidator(oldPassword)
				const newPasswordValidator = fieldValidators.passwordValidator(newPassword)
				const confirmNewPasswordValidator =
					newPassword !== confirmNewPassword ? 'Passwords did not match' : ''

				setErrors({
					...errors,
					oldPassword: oldPasswordValidator,
					newPassword: newPasswordValidator,
					confirmNewPassword: confirmNewPasswordValidator,
				})

				return !(oldPasswordValidator || newPasswordValidator || confirmNewPasswordValidator)
			},
		}
	)

	useEffect(() => {
		getSecuritySettings()
	}, [])

	useEffect(() => {
		if (securitySettings) {
			setEmail(securitySettings.email)
		}
	}, [securitySettings])

	if (isGetSecuritySettingsLoading) return <PageLoading />

	return (
		<PageWrapper>
			{isGoogleTFAModalActive && (
				<Modal
					closeModal={() => closeModal(setIsGoogleTFAModalActive, setIsGoogleTFAModalVisible)}
					visible={isGoogleTFAModalVisible}
					setVisible={setIsGoogleTFAModalVisible}
				>
					<SetupGoogleTFAModal
						onSuccess={getSecuritySettings}
						onClose={() => closeModal(setIsGoogleTFAModalActive, setIsGoogleTFAModalVisible)}
						isEnabled={!!securitySettings?.authDetails.googleTfa?.isEnabled}
					/>
				</Modal>
			)}

			<div className={styles.settingsPageInnerBody}>
				<div className={styles.settingSection}>
					<header className={styles.settingSectionHeader}>
						<h1 className={styles.settingSectionTitle}>Account Settings</h1>
					</header>
					<div className={styles.settingSectionBody}>
						<div className={styles.fieldWrapper}>
							<p className={styles.fieldWrapperTitle}>Email Address</p>
							<div className={styles.formWrapper}>
								<Input
									value={email}
									setValue={setEmail}
									placeholder={'dunn@example.com'}
									errorMessage={errors['email']}
									onBlur={() =>
										setErrors({
											...errors,
											email: fieldValidators.emailValidator(email),
										})
									}
								/>
							</div>
							<p className={styles.fieldWrapperDescription}>
								This is the email you use to sign in to your account.
							</p>
						</div>

						{email !== securitySettings?.email && (
							<div className={styles.mtMediumLarge}>
								<Button
									style={ButtonStyles.BORDERED}
									icon={{
										id: 'download',
										width: 13,
										height: 13,
										align: ButtonsIconAlign.LEFT,
									}}
									disableShadow={true}
									isLoading={isChangeEmailLoading}
									onClick={changeEmail}
								>
									Save Settings
								</Button>
							</div>
						)}
					</div>
				</div>

				<div className={styles.settingSection}>
					<header className={styles.settingSectionHeader}>
						<h1 className={styles.settingSectionTitle}>Two Factor Authentication</h1>
					</header>
					<div className={styles.settingSectionBody}>
						<GoogleAuthenticator
							onEditButtonClick={() => setIsGoogleTFAModalActive(true)}
							isActive={!!securitySettings?.authDetails.googleTfa?.isEnabled}
						/>
					</div>
				</div>

				<div className={styles.settingSection}>
					<header className={styles.settingSectionHeader}>
						<h1 className={styles.settingSectionTitle}>Change Password</h1>
					</header>
					<div className={styles.settingSectionBody}>
						<div className={styles.fieldWrapper}>
							<p className={styles.fieldWrapperTitle}>Current Password</p>
							<div className={styles.formWrapper}>
								<Input
									value={oldPassword}
									type={'password'}
									setValue={setOldPassword}
									placeholder={'*****************'}
									autoComplete={'off'}
									errorMessage={errors['oldPassword']}
									onBlur={() =>
										setErrors({
											...errors,
											oldPassword: fieldValidators.passwordValidator(oldPassword),
										})
									}
								/>
							</div>
							<p className={styles.fieldWrapperDescription}>
								To change your password, you’ll have to enter your existing account password.
							</p>
						</div>
						<div className={styles.mtMediumLarge}>
							<div className={styles.twoColumns}>
								<div className={styles.fieldWrapper}>
									<p className={styles.fieldWrapperTitle}>New Password</p>
									<div className={styles.formWrapper}>
										<Input
											value={newPassword}
											type={'password'}
											setValue={setNewPassword}
											placeholder={'*****************'}
											errorMessage={errors['newPassword']}
											onBlur={() =>
												setErrors({
													...errors,
													newPassword: fieldValidators.passwordValidator(newPassword),
												})
											}
										/>
									</div>
									<p className={styles.fieldWrapperDescription}>
										Be sure to use a long, randomized password to stay secure.
									</p>
								</div>
								<div className={styles.fieldWrapper}>
									<p className={styles.fieldWrapperTitle}>Confirm Password</p>
									<div className={styles.formWrapper}>
										<Input
											value={confirmNewPassword}
											type={'password'}
											setValue={setConfirmNewPassword}
											placeholder={'*****************'}
											errorMessage={errors['confirmNewPassword']}
											onBlur={() =>
												setErrors({
													...errors,
													confirmNewPassword:
														newPassword !== confirmNewPassword ? 'Passwords did not match' : '',
												})
											}
										/>
									</div>
									<p className={styles.fieldWrapperDescription}>
										To save your new password, please re-enter it above.
									</p>
								</div>
							</div>
						</div>

						{oldPassword && (
							<div className={styles.mtMediumLarge}>
								<Button
									style={ButtonStyles.BORDERED}
									icon={{
										id: 'download',
										width: 13,
										height: 13,
										align: ButtonsIconAlign.LEFT,
									}}
									disableShadow={true}
									isLoading={isChangePasswordLoading}
									onClick={changePassword}
								>
									Save Settings
								</Button>
							</div>
						)}
					</div>
				</div>

				{/*<div className={styles.settingSection}>*/}
				{/*	<header className={styles.settingSectionHeader}>*/}
				{/*		<h1 className={styles.settingSectionTitle}>Business Verification</h1>*/}
				{/*	</header>*/}
				{/*	<div className={styles.settingSectionBody}>*/}
				{/*		<p className={styles.settingSectionDescription}>*/}
				{/*			This information will not be public to members. To unlock a golden verification badge,*/}
				{/*			you will be required to enter business details below. If you are not a registered*/}
				{/*			company, add your personal details.*/}
				{/*		</p>*/}
				{/*		<div className={styles.mtMediumLarge}>*/}
				{/*			<div className={styles.twoColumns}>*/}
				{/*				<div className={styles.fieldWrapper}>*/}
				{/*					<p className={styles.fieldWrapperTitle}>Country</p>*/}
				{/*					<div className={styles.formWrapper}>*/}
				{/*						<CustomSelect*/}
				{/*							style={SelectStyles.secondary}*/}
				{/*							value={''}*/}
				{/*							setValue={() => {}}*/}
				{/*							options={[*/}
				{/*								{ label: 'United States of America', value: 'United States of America' },*/}
				{/*							]}*/}
				{/*						/>*/}
				{/*					</div>*/}
				{/*				</div>*/}
				{/*				<div className={styles.fieldWrapper}>*/}
				{/*					<p className={styles.fieldWrapperTitle}>Phone Number</p>*/}
				{/*					<div className={styles.formWrapper}>*/}
				{/*						<Input value={''} setValue={() => {}} placeholder={'+0 (000) 000-0000'} />*/}
				{/*					</div>*/}
				{/*				</div>*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*		<div className={styles.mtMediumLarge}>*/}
				{/*			<div className={styles.twoColumns}>*/}
				{/*				<div className={styles.fieldWrapper}>*/}
				{/*					<p className={styles.fieldWrapperTitle}>Street Address</p>*/}
				{/*					<div className={styles.formWrapper}>*/}
				{/*						<Input value={''} setValue={() => {}} placeholder={'1 Hacker Way'} />*/}
				{/*					</div>*/}
				{/*				</div>*/}
				{/*				<div className={styles.fieldWrapper}>*/}
				{/*					<p className={styles.fieldWrapperTitle}>Apt, Suite, etc (optional)</p>*/}
				{/*					<div className={styles.formWrapper}>*/}
				{/*						<Input value={''} setValue={() => {}} placeholder={'Suite 100 (optional)'} />*/}
				{/*					</div>*/}
				{/*				</div>*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*		<div className={styles.mtMediumLarge}>*/}
				{/*			<div className={styles.twoColumns}>*/}
				{/*				<div className={styles.fieldWrapper}>*/}
				{/*					<p className={styles.fieldWrapperTitle}>City</p>*/}
				{/*					<div className={styles.formWrapper}>*/}
				{/*						<Input value={''} setValue={() => {}} placeholder={'California'} />*/}
				{/*					</div>*/}
				{/*				</div>*/}
				{/*				<div className={styles.fieldWrapper}>*/}
				{/*					<p className={styles.fieldWrapperTitle}>State, Province, or Region (optional)</p>*/}
				{/*					<div className={styles.formWrapper}>*/}
				{/*						<Input value={''} setValue={() => {}} placeholder={'Menlo Park'} />*/}
				{/*					</div>*/}
				{/*				</div>*/}
				{/*			</div>*/}
				{/*		</div>*/}

				{/*		<div className={styles.mtMediumLarge}>*/}
				{/*			<div className={styles.fieldWrapper}>*/}
				{/*				<p className={styles.fieldWrapperTitle}>Zip Code</p>*/}
				{/*				<div className={styles.formWrapper}>*/}
				{/*					<Input value={''} setValue={() => {}} placeholder={'00000-00000'} />*/}
				{/*				</div>*/}
				{/*			</div>*/}
				{/*		</div>*/}

				{/*		<div className={styles.mtMediumLarge}>*/}
				{/*			<div className={styles.fieldWrapper}>*/}
				{/*				<p className={styles.fieldWrapperTitle}>Business Description</p>*/}
				{/*				<div className={styles.formWrapper}>*/}
				{/*					<Textarea*/}
				{/*						value={''}*/}
				{/*						setValue={() => {}}*/}
				{/*						placeholder={*/}
				{/*							'Give us the elevator pitch about your community, procut, or business. Tell us about how you’re using Sellpass.'*/}
				{/*						}*/}
				{/*					/>*/}
				{/*				</div>*/}
				{/*			</div>*/}
				{/*		</div>*/}

				{/*		<div className={styles.mtMediumLarge}>*/}
				{/*			<Button*/}
				{/*				style={ButtonStyles.BORDERED}*/}
				{/*				icon={{*/}
				{/*					id: 'download',*/}
				{/*					width: 13,*/}
				{/*					height: 13,*/}
				{/*					align: ButtonsIconAlign.LEFT,*/}
				{/*				}}*/}
				{/*				disableShadow={true}*/}
				{/*			>*/}
				{/*				Save Settings*/}
				{/*			</Button>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*</div>*/}
			</div>
		</PageWrapper>
	)
}

export default SettingsAccountPage
