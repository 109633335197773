import React, { FC, useEffect, useState } from 'react'
import modalStyles from '../Modals.module.scss'
import styles from './AddListingsModal.module.scss'
import Input from '../../UI/FormComponents/Input'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import SearchInput, { SearchInputStyles } from '../../UI/SearchInput/SearchInput'
import Checkbox from '../../UI/Checkbox/Checkbox'
import { SpriteIcon } from '../../UI/SpriteIcon/SpriteIcon'
import Pagination from '../../UI/Pagination/Pagination'
import { Listing } from '../../../types/Listing/Listing'
import { Product } from '../../../types/Product/Product'

interface AddListingsModalProps extends BaseModalProps {
	searchedValue: string
	listings?: Listing[]
	setHandledListings: (listings: Listing[]) => void
	handledListings: Listing[]
}

const AddListingsModal: FC<AddListingsModalProps> = ({
	onClose,
	searchedValue,
	setHandledListings,
	handledListings,
	listings,
}) => {
	const [searchValue, setSearchValue] = useState('')
	const [currentPage, setCurrentPage] = useState(0)
	const [viewingListings, setViewingListings] = useState<Listing[]>([])
	const [selectedListings, setSelectedListings] = useState<Listing[]>([])
	const pageSize = 6

	const onAddProductsClickHandler = () => {
		setHandledListings(selectedListings.map((listing, index) => ({ ...listing, position: index })))
		onClose()
	}

	useEffect(() => {
		setSearchValue(searchedValue)
		setSelectedListings(handledListings)
	}, [])

	useEffect(() => {
		setViewingListings(
			listings
				?.filter(
					listing =>
						listing.name.toLowerCase().includes(searchValue.toLowerCase()) ||
						listing.id.toString().includes(searchValue)
				)
				.filter(
					(listing, index) =>
						index + 1 > pageSize * currentPage && index < pageSize * (currentPage + 1)
				) || []
		)
	}, [currentPage, searchValue])

	return (
		<div className={modalStyles.modalInner}>
			<header className={modalStyles.header}>
				<h1>Add Products</h1>
			</header>

			<div className={modalStyles.body}>
				<SearchInput
					style={SearchInputStyles.bordered}
					value={searchValue}
					setValue={setSearchValue}
					placeholder={'Search for products...'}
				/>

				{searchValue && !viewingListings.length ? (
					<p className={styles.noResultsText}>
						No results found for “{searchValue}”. Try again by searching for another product.
					</p>
				) : (
					<>
						<div className={styles.listings}>
							{viewingListings.map(listing => (
								<label className={styles.listing} key={listing.id}>
									<Checkbox
										id={listing.id.toString()}
										checked={
											!!selectedListings.find(selectedListing => selectedListing.id === listing.id)
										}
										setChecked={checked => {
											if (checked) {
												setSelectedListings([...selectedListings, listing])
											} else {
												setSelectedListings(
													selectedListings.filter(
														selectedListing => selectedListing.id !== listing.id
													)
												)
											}
										}}
									/>
									<div className={styles.listingImage}>
										{listing.imageCfId ? (
											<img
												src={`https://imagedelivery.net/${listing.imageCfId}/productCard`}
												alt={listing.name}
											/>
										) : (
											<SpriteIcon iconId={'image'} width={12} height={12} />
										)}
									</div>
									<p className={styles.listingName}>{listing.name}</p>
									<p className={styles.listingId}>
										(<span>{listing.id}</span>)
									</p>
								</label>
							))}
						</div>

						<div className={modalStyles.mtMediumLarge}>
							<Pagination
								withoutPageSizeSelector={true}
								totalItems={
									listings?.filter(
										listing =>
											listing.name.toLowerCase().includes(searchValue.toLowerCase()) ||
											listing.id.toString().includes(searchValue)
									).length || 0
								}
								pageSize={pageSize}
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
							/>
						</div>
					</>
				)}
			</div>

			<footer className={modalStyles.footer}>
				<div className={modalStyles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'checkedCircle',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
							className: modalStyles.green,
						}}
						disableShadow={true}
						onClick={onAddProductsClickHandler}
						isDisabled={!selectedListings.length}
					>
						Add Products
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default AddListingsModal
