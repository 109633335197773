import React, { FC } from 'react'
import styles from './StatusBadges.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import { WarehouseStatus } from '../../../types/WarehouseStatus/WarehouseStatus'
import { OrderStatus } from '../../../types/OrderStatus/OrderStatus'

interface OrdersStatusBadgeProps {
	orderStatus: OrderStatus
}

const OrdersStatusBadge: FC<OrdersStatusBadgeProps> = ({ orderStatus }) => {
	const orderStatusData = {
		[OrderStatus.NEW]: {
			name: 'New',
			className: 'brown',
			iconId: 'exclamationMarkCircle',
		},
		[OrderStatus.EXPIRED]: {
			name: 'Expired',
			className: 'red',
			iconId: 'xMarkCircle',
		},
		[OrderStatus.CANCELLED]: {
			name: 'Cancelled',
			className: 'red',
			iconId: 'xMarkCircle',
		},
		[OrderStatus.COMPLETED]: {
			name: 'Completed',
			className: 'green',
			iconId: 'checkedCircle',
		},
		[OrderStatus.FULL_DELIVERY_FAILURE]: {
			name: 'Delivery Failed',
			className: 'red',
			iconId: 'xMarkCircle',
		},
		[OrderStatus.PARTIALLY_DELIVERED]: {
			name: 'Partially Delivered',
			className: 'brown',
			iconId: 'exclamationMarkCircle',
		},
		[OrderStatus.PENDING]: {
			name: 'Pending',
			className: 'brown',
			iconId: 'exclamationMarkCircle',
		},
		[OrderStatus.REFUNDED]: {
			name: 'Refunded',
			className: 'green',
			iconId: 'checkedCircle',
		},
	}

	return (
		<div className={`${styles.statusBadge} ${styles[orderStatusData[orderStatus].className]}`}>
			{orderStatusData[orderStatus].name}
			<SpriteIcon iconId={orderStatusData[orderStatus].iconId} width={16} height={16} />
		</div>
	)
}

export default OrdersStatusBadge
