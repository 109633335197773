import { privateAPI } from './API'

export const AnalyticsVisitsService = {
	getVisits: async (shopId: string, fromDate: Date | null, toDate: Date | null) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/analytics/visits`, {
			params: {
				fromDate,
				toDate,
			},
		})
	},
	getConversionRates: async (shopId: string, fromDate: Date | null, toDate: Date | null) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/analytics/conversion-rates`, {
			params: {
				fromDate,
				toDate,
			},
		})
	},
}
