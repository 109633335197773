import React, { useEffect, useState } from 'react'
import styles from './ManageStockGroupPage.module.scss'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import StockTextarea from '../../components/UI/FormComponents/StockTextarea'
import Switch from '../../components/UI/Switch/Switch'
import CustomSelect, { SelectStyles } from '../../components/UI/CustomSelect/CustomSelect'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import Input from '../../components/UI/FormComponents/Input'
import { useParams } from 'react-router-dom'
import { useAPI } from '../../hooks/useAPI'
import { Stock } from '../../types/Stock/Stock'
import { StockSystemService } from '../../API/StockSystemService'
import { StockGroup } from '../../types/StockGroup/StockGroup'
import { useAppContext } from '../../hooks/useAppContext'
import Spinner from '../../components/UI/Spinner/Spinner'
import { stockDelimiterHandler } from '../../helpers/stockDelimiterHandler'
import { StockGroupDetailed } from '../../types/StockGroup/StockGroupDetailed'
import Modal from '../../components/Modals/Modal'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import { closeModal } from '../../helpers/closeModal'
import { usePageTitle } from '../../hooks/usePageTitle'

const EditStockPage = () => {
	const [stock, setStock] = useState('')
	const [removePreviouslySoldSerials, setRemovePreviouslySoldSerials] = useState(false)
	const [isClearStockModalActive, setIsClearStockModalActive] = useState(false)
	const [isClearStockModalVisible, setIsClearStockModalVisible] = useState(false)
	const [isAddToStockModalActive, setIsAddToStockModalActive] = useState(false)
	const [isAddToStockModalVisible, setIsAddToStockModalVisible] = useState(false)
	const [delimiter, setDelimiter] = useState('')
	const [customDelimiter, setCustomDelimiter] = useState('')
	const params = useParams()
	const stockGroupId = params.id
	const { shop } = useAppContext()
	usePageTitle(`Edit Stocks`)

	const [isGetStocksLoading, stocks, getStocks] = useAPI<{
		serials: string[]
	}>(() => StockSystemService.getWarehouseStockRaw(shop?.id || '', Number(stockGroupId)), false)

	const [isGetWarehouseLoading, warehouse, getWarehouse] = useAPI<StockGroupDetailed>(
		() => StockSystemService.getWarehouse(shop?.id || '', Number(stockGroupId)),
		false
	)

	const [isEditStockLoading, , editStock] = useAPI(
		() =>
			StockSystemService.editWarehouseStock(shop?.id || '', Number(stockGroupId), {
				serials: stock.split(stockDelimiterHandler(delimiter, false, customDelimiter)),
				// removePreviouslySoldSerials: removePreviouslySoldSerials,
				defaultDelimiter: stockDelimiterHandler(delimiter, false, customDelimiter),
			}),
		false,
		'Stock successfully edited',
		{
			onSuccess: () => {
				setIsAddToStockModalActive(false)
			},
		}
	)

	const onConfirmCleanStockHandler = () => {
		setStock('')
		setIsClearStockModalActive(false)
	}

	const onConfirmAddToStockHandler = () => {
		editStock()
	}

	useEffect(() => {
		if (shop) {
			getWarehouse()
			getStocks()
		}
	}, [shop])

	useEffect(() => {
		if (stocks && warehouse) {
			const delimiterType = stockDelimiterHandler(warehouse?.defaultDelimiter, true)
			setStock(stocks.serials.join(warehouse?.defaultDelimiter))
			setDelimiter(delimiterType)
			if (delimiterType === 'Custom') {
				setCustomDelimiter(warehouse?.defaultDelimiter)
			}
		}
	}, [stocks, warehouse])

	if (isGetWarehouseLoading) return <Spinner />

	return (
		<PageWrapper>
			{isClearStockModalActive && (
				<Modal
					closeModal={() => closeModal(setIsClearStockModalActive, setIsClearStockModalVisible)}
					visible={isClearStockModalVisible}
					setVisible={setIsClearStockModalVisible}
				>
					<ConfirmModal
						title={'Clear Stock'}
						description={`You’re about to delete ${
							stock.split(stockDelimiterHandler(delimiter, false, customDelimiter)).length
						} stock entries. Are you sure you want to delete these entries? This cannot be undone.`}
						onConfirm={onConfirmCleanStockHandler}
						onClose={() => closeModal(setIsClearStockModalActive, setIsClearStockModalVisible)}
						buttonIcon={{
							id: 'trashBin',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						confirmButtonText={'Delete Stock Entry'}
					/>
				</Modal>
			)}
			{isAddToStockModalActive && (
				<Modal
					closeModal={() => closeModal(setIsAddToStockModalActive, setIsAddToStockModalVisible)}
					visible={isAddToStockModalVisible}
					setVisible={setIsAddToStockModalVisible}
				>
					<ConfirmModal
						title={'Add Stock'}
						description={`You’re about to add ${
							stock.split(stockDelimiterHandler(delimiter, false, customDelimiter)).length
						} stock entries. Are you sure you want to add these entries?`}
						onConfirm={onConfirmAddToStockHandler}
						onClose={() => closeModal(setIsAddToStockModalActive, setIsAddToStockModalVisible)}
						isButtonLoading={isEditStockLoading}
						buttonIcon={{
							id: 'plus',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						confirmButtonText={'Add Stock Entry'}
					/>
				</Modal>
			)}
			<PageContainer>
				<div className={styles.manageStockGroup}>
					<section className={styles.section}>
						<div className={styles.sectionBody}>
							<div className={styles.stockSectionGrid}>
								<div className={styles.fieldWrapper}>
									<h2 className={styles.fieldTitle}>STOCK</h2>
									<div className={styles.formWrapper}>
										<StockTextarea
											value={stock}
											setValue={setStock}
											stockInDatabase={warehouse?.stock || 0}
											stockInEditor={
												stock
													? stock.split(stockDelimiterHandler(delimiter, false, customDelimiter))
															.length
													: 0
											}
											isEditing={true}
										/>

										<p className={styles.fieldDescription}>
											Duplicated items are automatically removed from your stock.
										</p>
									</div>
								</div>
								<div className={styles.fieldWrapper}>
									<h2 className={styles.fieldTitle}>Delimiter</h2>

									<div className={styles.formWrapper}>
										<CustomSelect
											style={SelectStyles.secondary}
											value={delimiter}
											setValue={setDelimiter}
											options={[
												{ label: 'Comma', value: 'Comma' },
												{ label: 'New Line', value: 'NewLine' },
												{ label: 'Custom', value: 'Custom' },
											]}
										/>
									</div>
									<p className={styles.fieldDescription}>
										Separates the serials in your stock group.
									</p>
									{delimiter === 'Custom' && (
										<div className={styles.formWrapper}>
											<Input
												value={customDelimiter}
												setValue={setCustomDelimiter}
												placeholder={'Custom delimiter'}
											/>
										</div>
									)}

									<div className={styles.buttons}>
										<Button
											style={ButtonStyles.BORDERED}
											icon={{
												id: 'download',
												width: 13,
												height: 13,
												align: ButtonsIconAlign.LEFT,
											}}
											disableShadow={true}
											onClick={() => setIsAddToStockModalActive(true)}
										>
											Save Stock
										</Button>
										<Button
											style={ButtonStyles.BORDERED}
											disableShadow={true}
											onClick={() => setIsClearStockModalActive(true)}
										>
											Clear Stock
										</Button>
									</div>
									{/*<div className={styles.switchWrapper}>*/}
									{/*	<Switch*/}
									{/*		checked={removePreviouslySoldSerials}*/}
									{/*		setChecked={setRemovePreviouslySoldSerials}*/}
									{/*	/>*/}
									{/*	<p>Remove duplicate serials</p>*/}
									{/*</div>*/}
								</div>
							</div>
						</div>
					</section>
				</div>
			</PageContainer>
		</PageWrapper>
	)
}

export default EditStockPage
