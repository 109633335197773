import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAPI } from '../../../hooks/useAPI'
import { AuthService } from '../../../API/AuthService'
import Spinner from '../../../components/UI/Spinner/Spinner'
import { usePageTitle } from '../../../hooks/usePageTitle'

const AuthDiscord = () => {
	const location = useLocation()
	const code = location.search.slice(6)
	const [, , loginDiscord] = useAPI<{ accessToken: string }>(
		() =>
			AuthService.loginDiscord({
				code,
				redirectUrl: `${
					window.location.hostname === 'localhost'
						? 'http://localhost:3000'
						: 'https://dash.billgang.com'
				}/auth/discord/callback`,
			}),
		true,
		'Successfully sign in',
		{
			onSuccess: response => {
				localStorage.setItem('accessToken', response.data.data.accessToken)
				window.close()
			},
		}
	)
	usePageTitle('Discord Auth')

	useEffect(() => {
		if (code) {
			loginDiscord()
		}
	}, [code])

	return (
		<div>
			<Spinner />
		</div>
	)
}

export default AuthDiscord
