import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import styles from './CreatePostPage.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import DropdownSection from '../../components/UI/DropdownSection/DropdownSection'
import PostGeneral from './components/PostGeneral/PostGeneral'
import PostSEO from './components/PostSEO/PostSEO'
import PostEdits from './components/PostEdits/PostEdits'
import { useAPI } from '../../hooks/useAPI'
import { AnnouncementsService } from '../../API/AnnouncementsService'
import { useAppContext } from '../../hooks/useAppContext'
import { ListingVisibility } from '../../types/ListingVisibility/ListingVisibility'
import { useNavigate, useParams } from 'react-router-dom'
import { ProductDetailed } from '../../types/Product/ProductDetailed'
import { ProductCreate } from '../../types/Product/ProductCreate'
import { ProductsService } from '../../API/ProductsService'
import { AnnouncementDetailed } from '../../types/Announcement/AnnouncementDetailed'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import { AnnouncementCreate } from '../../types/Announcement/AnnouncementCreate'
import FormErrorsDisplayBadge from '../../components/UI/FormErrorsDisplayBadge/FormErrorsDisplayBadge'
import { FormErrors } from '../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../helpers/fieldValidators'
import { Image } from '../../types/Image/Image'
import { usePageTitle } from '../../hooks/usePageTitle'
import SEOSection from '../../components/SEOSection/SEOSection'

const CreatePostPage = () => {
	const [headerTitle, setHeaderTitle] = useState('')
	const [slugUrl, setSlugUrl] = useState('')
	const [buttonText, setButtonText] = useState('')
	const [buttonLink, setButtonLink] = useState('')
	const [metaTitle, setMetaTitle] = useState('')
	const [metaDescription, setMetaDescription] = useState('')
	const [visibility, setVisibility] = useState<ListingVisibility>(ListingVisibility.PUBLIC)
	const [metaImage, setMetaImage] = useState<Image | null>(null)
	const [image, setImage] = useState<Image | null>(null)
	const [subheaderTitle, setSubheaderTitle] = useState('')
	const [description, setDescription] = useState<string | undefined>('')
	const { shop, shopDomain } = useAppContext()
	const params = useParams()
	const postId = params.id
	const navigate = useNavigate()
	const [showErrorsDisplay, setShowErrorsDisplay] = useState(false)
	const [errors, setErrors] = useState<FormErrors>({
		headerTitle: '',
		slugUrl: '',
	})
	usePageTitle('Create Post')
	const errorMessages = Object.keys(errors)
		.map(key => errors[key])
		.filter(error => error)

	const [isCreateAnnouncementLoading, , createAnnouncement] = useAPI(
		(data: AnnouncementCreate) => AnnouncementsService.create(shop?.id || '', data),
		false,
		'Announcement successfully created',
		{
			onSuccess: () => {
				navigate('/content/posts')
			},
		}
	)

	const [isUpdateAnnouncementLoading, , updateAnnouncement] = useAPI(
		(data: AnnouncementCreate) => AnnouncementsService.update(shop?.id || '', Number(postId), data),
		false,
		'Announcement successfully updated',
		{
			onSuccess: () => {
				navigate('/content/posts')
			},
		}
	)

	const submitValidator = () => {
		const headerTitleValidator = fieldValidators.blankValidator('Header title', headerTitle)
		const slugURLValidator = fieldValidators.blankValidator('Slug URL', slugUrl)

		setErrors({
			headerTitle: headerTitleValidator,
			slugUrl: slugURLValidator,
		})

		return !(headerTitleValidator || slugURLValidator)
	}

	const onSubmitClick = () => {
		if (submitValidator()) {
			const data: AnnouncementCreate = {
				buttonLink: buttonLink || null,
				uniquePath: slugUrl,
				buttonText: buttonText || null,
				title: headerTitle,
				description: description || '',
				shortDescription: subheaderTitle,
				visibility: visibility,
				imageId: image?.id,
				seo: {
					pageDescription: metaDescription,
					pageTitle: metaTitle,
					pageImageId: metaImage?.id,
				},
			}

			if (!postId) {
				createAnnouncement(data)
			} else {
				updateAnnouncement(data)
			}
		} else {
			setShowErrorsDisplay(true)
		}
	}

	const [isGetAnnouncementLoading, announcement, getAnnouncement] = useAPI<AnnouncementDetailed>(
		(data: ProductCreate) => AnnouncementsService.getOne(shop?.id || '', Number(postId)),
		false
	)

	useEffect(() => {
		if (shop && postId) {
			getAnnouncement()
		}
	}, [shop])

	useEffect(() => {
		if (announcement) {
			setVisibility(announcement.visibility)
			setHeaderTitle(announcement.title)
			setDescription(announcement.description)
			setSubheaderTitle(announcement.shortDescription)
			setButtonLink(announcement?.buttonLink || '')
			setButtonText(announcement?.buttonText || '')
			setMetaDescription(announcement.seo.pageDescription)
			setMetaTitle(announcement.seo.pageTitle)
			setMetaImage(announcement.seo.pageImage || null)
			setImage(announcement.image || null)
			setSlugUrl(announcement.uniquePath)
		}
	}, [announcement])

	useEffect(() => {
		if (!announcement?.uniquePath) {
			setSlugUrl(headerTitle.toLowerCase().replace(/[^a-z1-9-]+/g, '-'))
		}

		if (!announcement?.seo.pageTitle) {
			setMetaTitle(`${headerTitle} by ${shop?.name}`)
		}
	}, [headerTitle])

	if (isGetAnnouncementLoading) return <PageLoading />

	return (
		<PageContainer>
			{showErrorsDisplay && !!errorMessages.length && (
				<FormErrorsDisplayBadge
					title={`Post ${postId ? 'Edit' : 'Create'} Error`}
					errors={errorMessages}
				/>
			)}
			<PageWrapper>
				<div className={styles.createPostPage}>
					<PageHeader
						title={`${postId ? 'Edit' : 'Create'} Post`}
						backButton={{ isActive: true }}
					></PageHeader>

					<div className={styles.body}>
						<div className={styles.column}>
							<DropdownSection title={'General Information'} maxHeight={681}>
								<PostGeneral
									buttonText={buttonText}
									buttonLink={buttonLink}
									setButtonText={setButtonText}
									setButtonLink={setButtonLink}
									headerTitle={headerTitle}
									setHeaderTitle={setHeaderTitle}
									setSubHeaderTitle={setSubheaderTitle}
									subHeaderTitle={subheaderTitle}
									description={description}
									setDescription={setDescription}
									setErrors={setErrors}
									errors={errors}
								/>
							</DropdownSection>
							<DropdownSection title={'SEO'} maxHeight={670}>
								<SEOSection
									url={`${shopDomain}/posts/`}
									slugUrl={slugUrl}
									setSlugUrl={setSlugUrl}
									metaTitle={metaTitle}
									setMetaTitle={setMetaTitle}
									metaDescription={metaDescription}
									setMetaDescription={setMetaDescription}
									metaImage={metaImage}
									setMetaImage={setMetaImage}
									errors={errors}
									setErrors={setErrors}
									isMostlyOptional={true}
								/>
							</DropdownSection>
						</div>

						<div className={styles.column}>
							<Button
								style={ButtonStyles.SECONDARY}
								icon={{
									id: 'plus',
									width: 10,
									height: 10,
									align: ButtonsIconAlign.RIGHT,
								}}
								onClick={onSubmitClick}
								isLoading={isCreateAnnouncementLoading || isUpdateAnnouncementLoading}
							>
								{postId ? 'Edit' : 'Create'} Post
							</Button>
							<DropdownSection title={'Post Edits'} maxHeight={490}>
								<PostEdits
									image={image}
									setImage={setImage}
									visibility={visibility}
									setVisibility={setVisibility}
								/>
							</DropdownSection>
						</div>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default CreatePostPage
