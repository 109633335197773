import React, { FC } from 'react'
import styles from '../Modals.module.scss'
import Input from '../../UI/FormComponents/Input'
import InputWithText from '../../UI/FormComponents/InputWithText'

interface ConfigurePaymentMethodModalsEditLimitsProps {
	maxRevenuePerDay: number
	setMaxRevenuePerDay: (value: number) => void
	maxRevenuePerWeek: number
	setMaxRevenuePerWeek: (value: number) => void
	maxRevenuePerMonth: number
	setMaxRevenuePerMonth: (value: number) => void
	maxAmountOfOrdersPerDay: number
	setMaxAmountOfOrdersPerDay: (value: number) => void
	maxAmountOfOrdersPerWeek: number
	setMaxAmountOfOrdersPerWeek: (value: number) => void
	maxAmountOfOrdersPerMonth: number
	setMaxAmountOfOrdersPerMonth: (value: number) => void
	cashAppTag?: string
}

const ConfigurePaymentMethodModalsEditLimits: FC<ConfigurePaymentMethodModalsEditLimitsProps> = ({
	maxAmountOfOrdersPerMonth,
	maxAmountOfOrdersPerDay,
	maxAmountOfOrdersPerWeek,
	maxRevenuePerDay,
	maxRevenuePerMonth,
	maxRevenuePerWeek,
	setMaxRevenuePerWeek,
	setMaxAmountOfOrdersPerDay,
	setMaxRevenuePerDay,
	setMaxRevenuePerMonth,
	setMaxAmountOfOrdersPerMonth,
	setMaxAmountOfOrdersPerWeek,
	cashAppTag,
}) => {
	return (
		<div className={styles.body}>
			{cashAppTag && (
				<div className={styles.fieldWrapper}>
					<h2>PAYMENT ACCOUNT</h2>
					<Input value={cashAppTag} setValue={() => {}} disabled={true} placeholder={'$cashtag'} />
				</div>
			)}

			<div className={styles.fieldWrapper}>
				<header>
					<span>
						Set custom thresholds for daily, weekly, or monthly limits for revenue ($) and payments
						(orders)
					</span>
				</header>

				<div className={styles.fieldWrapper}>
					<h2>MAX REVENUE</h2>
					<div className={styles.mt10}>
						<InputWithText
							value={maxRevenuePerDay}
							setValue={setMaxRevenuePerDay}
							text={'Per Day'}
							type={'number'}
						/>
					</div>
					<div className={styles.mt10}>
						<InputWithText
							value={maxRevenuePerWeek}
							setValue={setMaxRevenuePerWeek}
							text={'Per Week'}
							type={'number'}
						/>
					</div>
					<div className={styles.mt10}>
						<InputWithText
							value={maxRevenuePerMonth}
							setValue={setMaxRevenuePerMonth}
							text={'Per Month'}
							type={'number'}
						/>
					</div>
				</div>

				<div className={styles.fieldWrapper}>
					<h2>MAX PAYMENTS</h2>
					<div className={styles.mt10}>
						<InputWithText
							value={maxAmountOfOrdersPerDay}
							setValue={setMaxAmountOfOrdersPerDay}
							text={'Per Day'}
							type={'number'}
						/>
					</div>
					<div className={styles.mt10}>
						<InputWithText
							value={maxAmountOfOrdersPerWeek}
							setValue={setMaxAmountOfOrdersPerWeek}
							text={'Per Week'}
							type={'number'}
						/>
					</div>
					<div className={styles.mt10}>
						<InputWithText
							value={maxAmountOfOrdersPerMonth}
							setValue={setMaxAmountOfOrdersPerMonth}
							text={'Per Month'}
							type={'number'}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ConfigurePaymentMethodModalsEditLimits
