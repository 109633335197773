import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import CustomSelect from '../../UI/CustomSelect/CustomSelect'
import Textarea from '../../UI/FormComponents/Textarea'
import { fieldValidators } from '../../../helpers/fieldValidators'
import { useAPI } from '../../../hooks/useAPI'
import { ReviewsService } from '../../../API/ReviewsService'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { useAppContext } from '../../../hooks/useAppContext'
import { AppealReviewReason } from '../../../types/AppealReviewReason/AppealReviewReason'

interface AppealReviewModalProps extends BaseModalProps {
	reviewId: number | null
	getReviews: () => void
}

const AppealReviewModal: FC<AppealReviewModalProps> = ({ onClose, reviewId, getReviews }) => {
	const [appeal, setAppeal] = useState<AppealReviewReason>(AppealReviewReason.FALSE)
	const [description, setDescription] = useState('')
	const [errors, setErrors] = useState<FormErrors>({})
	const { shop } = useAppContext()

	const appealReviewValidator = () => {
		const descriptionValidator = fieldValidators.blankValidator('Description', description)

		setErrors({
			description: descriptionValidator,
		})

		return !descriptionValidator
	}

	const [isAppealReviewLoading, , appealReview] = useAPI(
		() =>
			ReviewsService.appealReview(shop?.id || '', Number(reviewId), {
				description: description,
				reason: appeal,
			}),
		false,
		'Appeal successfully send',
		{
			validator: appealReviewValidator,
			onSuccess: () => {
				onClose()
				getReviews()
			},
		}
	)

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Appeal Review</h1>
			</header>

			<div className={styles.body}>
				<div className={styles.fieldWrapper}>
					<h2>Appeal</h2>
					<CustomSelect
						value={appeal}
						setValue={value => setAppeal(value as AppealReviewReason)}
						options={[
							{ value: AppealReviewReason.FALSE, label: 'False or misleading' },
							{ value: AppealReviewReason.ANOTHER_SHOP, label: 'Referring to another shop' },
							{ value: AppealReviewReason.INVOICE_REPLACED, label: 'Invoice replaced' },
							{ value: AppealReviewReason.WRONG_VALUATION, label: 'Wrong valuation' },
							{ value: AppealReviewReason.OTHER, label: 'Other' },
						]}
					/>
				</div>
				<div className={styles.fieldWrapper}>
					<h2>Description</h2>
					<Textarea
						value={description}
						setValue={setDescription}
						errorMessage={errors['description']}
						onBlur={() => appealReviewValidator()}
						placeholder={'Type a note so our team can investigate this dispute...'}
					/>
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'send',
							height: 14,
							width: 12,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={appealReview}
						isLoading={isAppealReviewLoading}
					>
						Appeal Review
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default AppealReviewModal
