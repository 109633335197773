import React, { FC } from 'react'
import styles from '../SettingsMigratePage.module.scss'
import { SpriteIcon } from '../../../../components/UI/SpriteIcon/SpriteIcon'
import { MigratingStep } from '../SettingsMigratePage'

interface migratingStepsProps {
	currentStep: MigratingStep
	setCurrentStep: (value: MigratingStep) => void
}

const MigratingStepper: FC<migratingStepsProps> = ({ currentStep, setCurrentStep }) => {
	return (
		<div className={styles.migratingSteps}>
			<div className={styles.migratingStep}>
				<div
					className={`${styles.migratingStepStatus} ${
						currentStep === MigratingStep.API_KEY && styles.active
					} ${currentStep > MigratingStep.API_KEY && styles.done}`}
				>
					<p>1</p>
					<SpriteIcon iconId={'checked'} width={13} height={13} />
				</div>
				<h2 className={styles.migratingPathName}>Enter API Key</h2>
			</div>
			<SpriteIcon className={styles.arrowNext} iconId={'arrowHead'} width={14} height={14} />
			<div className={styles.migratingStep}>
				<div
					className={`${styles.migratingStepStatus} ${
						currentStep === MigratingStep.SELECT_DATA_TRANSFER && styles.active
					} ${currentStep > MigratingStep.SELECT_DATA_TRANSFER && styles.done}`}
				>
					<p>2</p>
					<SpriteIcon iconId={'checked'} width={13} height={13} />
				</div>
				<h2 className={styles.migratingPathName}>Select Data Transfer</h2>
			</div>
			<SpriteIcon className={styles.arrowNext} iconId={'arrowHead'} width={14} height={14} />
			<div className={styles.migratingStep}>
				<div
					className={`${styles.migratingStepStatus} ${
						currentStep === MigratingStep.ADDITIONAL_DATA_TRANSFER && styles.active
					} ${currentStep > MigratingStep.ADDITIONAL_DATA_TRANSFER && styles.done}`}
				>
					<p>3</p>
					<SpriteIcon iconId={'checked'} width={13} height={13} />
				</div>
				<h2 className={styles.migratingPathName}>Additional Data Transfer</h2>
			</div>
			<SpriteIcon className={styles.arrowNext} iconId={'arrowHead'} width={14} height={14} />
			<div className={styles.migratingStep}>
				<div
					className={`${styles.migratingStepStatus} ${
						currentStep === MigratingStep.VERIFY_MIGRATION && styles.active
					} ${currentStep > MigratingStep.VERIFY_MIGRATION && styles.done}`}
				>
					<p>4</p>
					<SpriteIcon iconId={'checked'} width={13} height={13} />
				</div>
				<h2 className={styles.migratingPathName}>Verify Migration</h2>
			</div>
		</div>
	)
}

export default MigratingStepper
