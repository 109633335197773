import React, { FC, useEffect, useState } from 'react'
import { VariantsGeneral } from '../../types/CreateVariants/VariantsGeneral'
import { VariantsPricing } from '../../types/CreateVariants/VariantsPricing'
import { VariantsDelivery } from '../../types/CreateVariants/VariantsDelivery'
import { VariantsCustomFields } from '../../types/CreateVariants/VariantsCustomFields'
import { VariantsDiscordSettings } from '../../types/CreateVariants/VariantsDiscordSettings'
import { VariantsAdvanced } from '../../types/CreateVariants/VariantsAdvanced'
import { Image } from '../../types/Image/Image'
import { ListingVisibility } from '../../types/ListingVisibility/ListingVisibility'
import { CreateProductSectionIds } from '../../types/CreateProductSectionIds/CreateProductSectionIds'
import ProductAdvisorGeneral from './ProductAdvisorGeneral'
import ProductAdvisorDesign from './ProductAdvisorDesign'
import ProductAdvisorVariants from './ProductAdvisorVariants'
import ProductAdvisorSEO from './ProductAdvisorSEO'
import { ProductVariantsTab } from '../../types/ProductVariantsTab/ProductVariantsTab'
import styles from './ProductAdvisor.module.scss'
import ProductAdvisorCriticalAlerts from './ProductAdvisorCriticalAlerts'
import { DeliveryType } from '../../types/DeliveryType/DeliveryType'

interface ProductAdvisorProps {
	titleValue: string
	shortDescription: string
	description: string | undefined
	variantsGeneral: VariantsGeneral
	variantsPricing: VariantsPricing
	currentVariantIndex: string
	variantsDelivery: VariantsDelivery
	variantsCustomFields: VariantsCustomFields
	variantsDiscordSettings: VariantsDiscordSettings
	variantsAdvanced: VariantsAdvanced
	images: Image[]
	metaImage: Image | null
	visibility: ListingVisibility
	metaTitle: string
	metaDescription: string
	currentVariantsTab: { [variantIndex: string]: ProductVariantsTab }
	setCurrentVariantsTab: (value: { [variantIndex: string]: ProductVariantsTab }) => void
}

const ProductAdvisor: FC<ProductAdvisorProps> = ({
	images,
	metaDescription,
	metaTitle,
	description,
	titleValue,
	visibility,
	shortDescription,
	variantsAdvanced,
	variantsDelivery,
	variantsPricing,
	variantsCustomFields,
	variantsDiscordSettings,
	variantsGeneral,
	currentVariantIndex,
	setCurrentVariantsTab,
	currentVariantsTab,
	metaImage,
}) => {
	const [percent, setPercent] = useState(0)
	const [areValuesPercentHandled, setAreValuesPercentHandled] = useState<{
		[valueName: string]: boolean
	}>({})
	const [currentSectionId, setCurrenSectionId] = useState<CreateProductSectionIds>(
		CreateProductSectionIds.GENERAL
	)
	const variantDelivery = variantsDelivery[currentVariantIndex]
	const deliveryConfigurationKeys = Object.keys(variantDelivery.deliveryConfiguration)

	const isZeroStockDone =
		variantDelivery.deliveryType === DeliveryType.PRESET
			? deliveryConfigurationKeys.length &&
			  deliveryConfigurationKeys.every(key =>
					variantDelivery.deliveryConfiguration[key].stock === null ||
					variantDelivery.deliveryConfiguration[key].stock === undefined
						? true
						: variantDelivery.deliveryConfiguration[key].stock
			  )
			: !!variantDelivery.dynamicDeliveryUrl

	const criticalAlerts = [
		!isZeroStockDone,
		variantDelivery.deliveryType === DeliveryType.DYNAMIC
			? !variantDelivery.dynamicDeliveryUrl
			: !deliveryConfigurationKeys.length,
		!titleValue,
		visibility !== ListingVisibility.PUBLIC,
	].filter(boolean => boolean)

	useEffect(() => {
		let temporaryPercent = percent
		const areValuesPercentHandledTemporary = areValuesPercentHandled

		const handleValuePercentage = (
			value: any[] | string | number | undefined | boolean | null | Image | ListingVisibility,
			valueName: string,
			isVisibility?: boolean
		) => {
			if (
				(Array.isArray(value)
					? value.length
					: isVisibility
					? value === ListingVisibility.PUBLIC
					: value) &&
				!areValuesPercentHandled[valueName]
			) {
				areValuesPercentHandledTemporary[valueName] = true
				temporaryPercent = temporaryPercent + 10
			} else if (
				!(Array.isArray(value)
					? value.length
					: isVisibility
					? value === ListingVisibility.PUBLIC
					: value) &&
				areValuesPercentHandled[valueName]
			) {
				areValuesPercentHandledTemporary[valueName] = false
				temporaryPercent = temporaryPercent - 10
			}
		}

		handleValuePercentage(images, 'images')
		handleValuePercentage(metaDescription, 'metaDescription')
		handleValuePercentage(metaTitle, 'metaTitle')
		handleValuePercentage(description, 'description')
		handleValuePercentage(titleValue, 'titleValue')
		handleValuePercentage(shortDescription, 'shortDescription')
		handleValuePercentage(
			variantsPricing[currentVariantIndex].compareAtPrice?.amount,
			'compareAtPrice'
		)
		handleValuePercentage(variantsPricing[currentVariantIndex].costPerItem?.amount, 'costPerItem')

		handleValuePercentage(variantsCustomFields[currentVariantIndex].customFields, 'customFields')
		handleValuePercentage(metaImage, 'metaImage')
		handleValuePercentage(
			variantsDiscordSettings[currentVariantIndex].isEnabled,
			'discordIntegrations'
		)
		handleValuePercentage(
			variantsPricing[currentVariantIndex].gateways.some(gateway => gateway.overrodePrice?.amount),
			'discountPricing'
		)

		setPercent(temporaryPercent)
		setAreValuesPercentHandled(areValuesPercentHandledTemporary)
	}, [
		images,
		metaDescription,
		metaTitle,
		description,
		titleValue,
		visibility,
		shortDescription,
		variantsAdvanced,
		variantsDelivery,
		variantsPricing,
		variantsCustomFields,
		variantsDiscordSettings,
		variantsGeneral,
		currentVariantIndex,
		metaImage,
	])

	if (criticalAlerts.length) {
		return (
			<ProductAdvisorCriticalAlerts
				titleValue={titleValue}
				visibility={visibility}
				currentVariantsTab={currentVariantsTab}
				currentVariantIndex={currentVariantIndex}
				setCurrentVariantsTab={setCurrentVariantsTab}
				deliveryConfigurationKeys={deliveryConfigurationKeys}
				isZeroStockDone={isZeroStockDone}
				criticalAlerts={criticalAlerts}
			/>
		)
	}

	return (
		<div>
			{percent >= 100 ? (
				<div className={styles.productAdvisor}>
					<header className={styles.header}>
						<h2 className={styles.title}>Product Advisor</h2>
						<p className={styles.progressPercent}>{percent}%</p>

						<div className={styles.progressBar}>
							<div
								className={styles.progressBarActiveLine}
								style={{
									width: `100%`,
								}}
							/>
							<div
								className={styles.progressBarWillActiveLine}
								style={{
									width: `100%`,
								}}
							/>
						</div>

						<p className={styles.description}>
							You’ve successfully completed our suggested requirements for your product.
						</p>
					</header>
				</div>
			) : (
				<>
					{currentSectionId === CreateProductSectionIds.GENERAL && (
						<ProductAdvisorGeneral
							titleValue={titleValue}
							description={description}
							shortDescription={shortDescription}
							percent={percent}
							setCurrenSectionId={setCurrenSectionId}
						/>
					)}
					{currentSectionId === CreateProductSectionIds.DESIGN && (
						<ProductAdvisorDesign
							percent={percent}
							setCurrenSectionId={setCurrenSectionId}
							visibility={visibility}
							images={images}
						/>
					)}
					{currentSectionId === CreateProductSectionIds.VARIANTS && (
						<ProductAdvisorVariants
							percent={percent}
							setCurrenSectionId={setCurrenSectionId}
							variantsGeneral={variantsGeneral}
							variantsDiscordSettings={variantsDiscordSettings}
							variantsDelivery={variantsDelivery}
							variantsPricing={variantsPricing}
							currentVariantIndex={currentVariantIndex}
							variantsCustomFields={variantsCustomFields}
							variantsAdvanced={variantsAdvanced}
							currentVariantsTab={currentVariantsTab}
							setCurrentVariantsTab={setCurrentVariantsTab}
							isZeroStockDone={isZeroStockDone}
						/>
					)}
					{currentSectionId === CreateProductSectionIds.SEO && (
						<ProductAdvisorSEO
							percent={percent}
							setCurrenSectionId={setCurrenSectionId}
							metaTitle={metaTitle}
							metaDescription={metaDescription}
							metaImage={metaImage}
						/>
					)}
				</>
			)}
		</div>
	)
}

export default ProductAdvisor
