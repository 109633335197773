import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import styles from './CustomerDetailedPage.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import CustomerTransactionHistory from './components/CustomerTransactionHistory/CustomerTransactionHistory'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppContext } from '../../hooks/useAppContext'
import { useAPI } from '../../hooks/useAPI'
import { CustomersService } from '../../API/CustomersService'
import { DetailedCustomer } from '../../types/DetailedCustomer/DetailedCustomer'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import { cleanEmail } from '../../helpers/cleanEmail'
import { SpriteIcon } from '../../components/UI/SpriteIcon/SpriteIcon'
import ActionMenu, { ActionMenuStyles } from '../../components/UI/ActionMenu/ActionMenu'
import Textarea from '../../components/UI/FormComponents/Textarea'
import { DatePeriod } from '../../types/DatePeriod/DatePeriod'
import { cutOffEmail } from '../../helpers/cutOffEmail'
import Modal from '../../components/Modals/Modal'
import EditCustomerBalanceModal from '../../components/Modals/EditCustomerBalanceModal/EditCustomerBalanceModal'
import { closeModal } from '../../helpers/closeModal'
import { usePageTitle } from '../../hooks/usePageTitle'
import EditCustomerDataModal from '../../components/Modals/EditCustomerDataModal/EditCustomerDataModal'
import { format } from 'date-fns'
import BlacklistCustomerModal from '../../components/Modals/BlacklistCustomerModal/BlacklistCustomerModal'
import ShieldInformation from './components/ShieldInformation/ShieldInformation'
import CustomerInformation from './components/CustomerInformation/CustomerInformation'
import TopPayments from '../../components/TopPayments/TopPayments'
import paymentType from '../../components/PaymentType/PaymentType'
import ConnectedDiscordAccountsModal from '../../components/Modals/ConnectedDiscordAccountsModal/ConnectedDiscordAccountsModal'
import { ActionMenuOption } from '../../types/ActionMenuOption/ActionMenuOption'
import { useCopy } from '../../hooks/useCopy'
import { OrdersService } from '../../API/OrdersService'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import RefundOrderModal from '../../components/Modals/RefundOrderModal/RefundOrderModal'
import { Order } from '../../types/Order/Order'
import StatusAlertBadge from '../../components/UI/StatusAlertBadge/StatusAlertBadge'
import { StatusAlert } from '../../types/StatusAlert/StatusAlert'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

const CustomerDetailedPage = () => {
	const params = useParams()
	const customerId = params.id
	const navigate = useNavigate()
	const { shop } = useAppContext()
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)
	const [isEditBalanceModalActive, setIsEditBalanceModalActive] = useState(false)
	const [isEditBalanceModalVisible, setIsEditBalanceModalVisible] = useState(false)
	const [isEditCustomerModalActive, setIsEditCustomerModalActive] = useState(false)
	const [isEditCustomerModalVisible, setIsEditCustomerModalVisible] = useState(false)
	const [isBlacklistCustomerModalActive, setIsBlacklistCustomerModalActive] = useState(false)
	const [isBlacklistCustomerModalVisible, setIsBlacklistCustomerModalVisible] = useState(false)
	const [isRefundLastOrderModalActive, setIsRefundLastOrderModalActive] = useState(false)
	const [isRefundLastOrderModalVisible, setIsRefundLastOrderModalVisible] = useState(false)
	const [isConnectedDiscordAccountsModalActive, setIsConnectedDiscordAccountsModalActive] =
		useState(false)
	const [isConnectedDiscordAccountsModalVisible, setIsConnectedDiscordAccountsModalVisible] =
		useState(false)
	const copy = useCopy()
	const [lastOrder, setLastOrder] = useState<Order>()
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isCustomersModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.CUSTOMERS_MODIFY) || isAdmin
	const isOrdersModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.ORDERS_MODIFY) || isAdmin
	const isOrdersViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.ORDERS_VIEW) || isAdmin
	const isFraudRulesModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.FRAUD_MODIFY) || isAdmin

	const [customerNote, setCustomerNote] = useState('')
	usePageTitle('Customer Details')

	const actionMenuOptions: ActionMenuOption[] = [
		{
			title: 'Copy Customer ID',
			icon: {
				id: 'copy',
				width: 13,
				height: 13,
			},
			onClick: () => copy(customerId || ''),
		},
	]

	isOrdersModifyAvailable &&
		actionMenuOptions.splice(0, 0, {
			title: 'Refund Last Payment',
			icon: {
				id: 'dollarCircle',
				width: 13,
				height: 13,
			},
			onClick: () => setIsRefundLastOrderModalActive(true),
		})

	const [isGetCustomerLoading, customer, getCustomer] = useAPI<DetailedCustomer>(
		() => CustomersService.getOne(shop?.id || '', customerId),
		false
	)
	const cleanedEmail = cleanEmail(customer?.email || '')

	const [isEditCustomerNoteLoading, , editCustomerNote] = useAPI(
		() =>
			CustomersService.editCustomerNote(shop?.id || '', customer?.id, {
				customerNote: customerNote,
			}),
		false,
		'Customer note successfully saved',
		{
			onSuccess: () => {
				getCustomer()
			},
		}
	)

	useEffect(() => {
		if (shop) {
			getCustomer()
		}
	}, [shop])

	useEffect(() => {
		if (customer) {
			setCustomerNote(customer?.note || '')
		}
	}, [customer])

	if (isGetCustomerLoading) return <PageLoading />

	return (
		<PageContainer>
			{isEditBalanceModalActive && (
				<Modal
					closeModal={() => closeModal(setIsEditBalanceModalActive, setIsEditBalanceModalVisible)}
					visible={isEditBalanceModalVisible}
					setVisible={setIsEditBalanceModalVisible}
				>
					<EditCustomerBalanceModal
						balance={
							customer?.balance || {
								amount: 0,
								currency: 'USD',
							}
						}
						customerId={customer?.id}
						customerName={customer?.firstName || cutOffEmail(customer?.email || '')}
						getCustomer={getCustomer}
						onClose={() => closeModal(setIsEditBalanceModalActive, setIsEditBalanceModalVisible)}
					/>
				</Modal>
			)}

			{isRefundLastOrderModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsRefundLastOrderModalActive, setIsRefundLastOrderModalVisible)
					}
					visible={isRefundLastOrderModalVisible}
					setVisible={setIsRefundLastOrderModalVisible}
				>
					<RefundOrderModal
						onSuccess={() => {}}
						shopId={shop?.id || ''}
						customerEmail={customer?.email || ''}
						order={lastOrder}
						onClose={() =>
							closeModal(setIsRefundLastOrderModalActive, setIsRefundLastOrderModalVisible)
						}
					/>
				</Modal>
			)}

			{isEditCustomerModalActive && (
				<Modal
					closeModal={() => closeModal(setIsEditCustomerModalActive, setIsEditCustomerModalVisible)}
					visible={isEditCustomerModalVisible}
					setVisible={setIsEditCustomerModalVisible}
				>
					<EditCustomerDataModal
						getCustomer={getCustomer}
						customer={customer}
						onClose={() => closeModal(setIsEditCustomerModalActive, setIsEditCustomerModalVisible)}
					/>
				</Modal>
			)}

			{isConnectedDiscordAccountsModalActive && (
				<Modal
					closeModal={() =>
						closeModal(
							setIsConnectedDiscordAccountsModalActive,
							setIsConnectedDiscordAccountsModalVisible
						)
					}
					visible={isConnectedDiscordAccountsModalVisible}
					setVisible={setIsConnectedDiscordAccountsModalVisible}
				>
					<ConnectedDiscordAccountsModal
						discordAccounts={customer?.discordSocialConnects || []}
						customerEmail={customer?.email || ''}
						onClose={() =>
							closeModal(
								setIsConnectedDiscordAccountsModalActive,
								setIsConnectedDiscordAccountsModalVisible
							)
						}
					/>
				</Modal>
			)}

			{isBlacklistCustomerModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsBlacklistCustomerModalActive, setIsBlacklistCustomerModalVisible)
					}
					visible={isBlacklistCustomerModalVisible}
					setVisible={setIsBlacklistCustomerModalVisible}
				>
					<BlacklistCustomerModal
						onSuccess={getCustomer}
						email={customer?.email || ''}
						onClose={() =>
							closeModal(setIsBlacklistCustomerModalActive, setIsBlacklistCustomerModalVisible)
						}
					/>
				</Modal>
			)}
			<PageWrapper>
				<div className={styles.customerDetailedPage}>
					{customer?.isBlacklisted && (
						<div className={styles.mbMediumLarge}>
							<StatusAlertBadge
								title={'Customer Blacklisted'}
								description={'This customer has been blacklisted and can’t purchase products.'}
								statusAlertType={StatusAlert.DANGER}
								// button={{
								// 	text: 'Remove Blacklist',
								// 	icon: {
								// 		id: 'lock2',
								// 		align: ButtonsIconAlign.LEFT,
								// 		height: 15,
								// 		width: 13,
								// 	},
								// }}
							/>
						</div>
					)}

					<header className={`${styles.pageHeader} ${styles.mbMediumLarge}`}>
						<div className={styles.customerInfo}>
							<div className={styles.backButton} onClick={() => navigate(-1)}>
								<SpriteIcon iconId={'arrow'} width={14} height={12} />
							</div>
							<div className={styles.customerAva}>
								{cleanedEmail[0]}
								{cleanedEmail[cleanedEmail.length - 1]}
							</div>
							<div>
								<h1 className={styles.title}>{cutOffEmail(customer?.email || '')}</h1>
								<p className={styles.customerAdditionalText}>
									{customer?.email}
									{customer?.firstPurchase && (
										<>
											<span>•</span>
											Customer since {format(new Date(customer?.firstPurchase), "MMMM '‘'dd")}
										</>
									)}
								</p>
							</div>
						</div>

						<div className={styles.headerEnd}>
							{isFraudRulesModifyAvailable && (
								<Button
									style={ButtonStyles.SECONDARY}
									icon={{
										id: 'lock2',
										width: 12,
										height: 14,
										align: ButtonsIconAlign.LEFT,
									}}
									onClick={() => setIsBlacklistCustomerModalActive(true)}
								>
									Blacklist Customer
								</Button>
							)}

							{isCustomersModifyAvailable && (
								<Button
									style={ButtonStyles.SECONDARY}
									icon={{
										id: 'pen',
										width: 13,
										height: 13,
										align: ButtonsIconAlign.LEFT,
									}}
									onClick={() => setIsEditCustomerModalActive(true)}
								>
									Edit Customer
								</Button>
							)}
							<ActionMenu
								style={ActionMenuStyles.light}
								actionButtons={actionMenuOptions}
								isActive={isActionMenuActive}
								setIsActive={setIsActionMenuActive}
							/>
						</div>
					</header>

					{customer && (
						<div className={styles.body}>
							<div className={styles.column}>
								<CustomerInformation
									isCustomersModifyAvailable={isCustomersModifyAvailable}
									customer={customer}
									setIsEditBalanceModalActive={setIsEditBalanceModalActive}
									setIsConnectedDiscordAccountsModalActive={
										setIsConnectedDiscordAccountsModalActive
									}
								/>
								{isCustomersModifyAvailable && (
									<div className={styles.section}>
										<header className={styles.header}>
											<h1>Notes</h1>
										</header>

										<div className={styles.sectionBody}>
											<Textarea
												value={customerNote}
												setValue={setCustomerNote}
												placeholder={
													'Use this space to add a private note. This member will not be notified or messaged.'
												}
												height={164}
												resizable={false}
											/>
										</div>

										<footer className={styles.sectionFooter}>
											<Button
												style={ButtonStyles.BORDERED}
												icon={{
													id: customer.note ? 'download' : 'plus',
													width: 13,
													height: 13,
													align: ButtonsIconAlign.LEFT,
												}}
												disableShadow={true}
												width={'100%'}
												onClick={editCustomerNote}
												isLoading={isEditCustomerNoteLoading}
											>
												{customer.note ? 'Save' : 'Create'} Note
											</Button>
										</footer>
									</div>
								)}
							</div>
							<div className={styles.wideColumn}>
								{isOrdersViewAvailable && (
									<CustomerTransactionHistory customer={customer} setLastOrder={setLastOrder} />
								)}
								<div className={styles.wideColumnRow}>
									{!!customer.paymentTypes.length && (
										<TopPayments
											datePeriod={DatePeriod.allTime}
											data={customer.paymentTypes.map(paymentType => ({
												amount: paymentType.amountDefaultCurrency,
												count: paymentType.orders,
												gatewayName: paymentType.gatewayName,
											}))}
										/>
									)}

									{!!customer.ipDetails.length && (
										<ShieldInformation ipDetails={customer.ipDetails} />
									)}
								</div>

								{/*<div className={styles.section}>*/}
								{/*	<header>*/}
								{/*		<h1 className={styles.sectionTitle}>Order History</h1>*/}
								{/*	</header>*/}
								{/*	{Object.keys(customer.orderStatistics).map(key => (*/}
								{/*		<div className={styles.titleValueWrapper} key={key}>*/}
								{/*			<OrdersStatusBadge orderStatus={key as OrderStatus} />*/}
								{/*			<p>{customer.orderStatistics[key]}</p>*/}
								{/*		</div>*/}
								{/*	))}*/}
								{/*</div>*/}
							</div>
						</div>
					)}
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default CustomerDetailedPage
