import React, { FC } from 'react'
import styles from './MessageInput.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import Button from '../Button/Button'

interface MessageInputProps {
	value: string
	setValue: (value: string) => void
	placeholder?: string
	style?: 'secondary'
	onClick?: () => void
}

const MessageInput: FC<MessageInputProps> = ({ value, setValue, onClick, placeholder, style }) => {
	return (
		<div className={`${styles.messageInput} ${style && styles[style]}`}>
			<textarea
				placeholder={placeholder}
				value={value}
				onChange={event => setValue(event.target.value)}
			/>

			<div className={styles.buttonWrapper}>
				<Button onClick={onClick}>Send Message</Button>
			</div>
		</div>
	)
}

export default MessageInput
