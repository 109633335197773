import React, { FC, ReactNode } from 'react'
import styles from './AlertBadge.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import { Alert } from '../../../types/Alert/Alert'

interface AlertBadgeProps {
	alert: Alert
	children: ReactNode
	disableShadow?: boolean
}

const AlertBadge: FC<AlertBadgeProps> = ({ alert, children, disableShadow }) => {
	const alertOptions = {
		[Alert.DANGER]: {
			iconId: 'exclamationMarkCircle',
			className: 'danger',
		},
		[Alert.SUCCESS]: {
			iconId: 'checkedCircle',
			className: 'success',
		},
		[Alert.INFORMATION]: {
			iconId: 'informationMark',
			className: 'info',
		},
		[Alert.WARNING]: {
			iconId: 'exclamationMarkTriangle',
			className: 'warning',
		},
	}

	return (
		<div
			className={`${styles.alertBadge} ${styles[alertOptions[alert].className]} ${
				disableShadow && styles.disableShadow
			}`}
		>
			<SpriteIcon iconId={alertOptions[alert].iconId} width={16} height={16} />
			{children}
		</div>
	)
}

export default AlertBadge
