import React, { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

interface RedirectPageProps {
	url: string
}

const RedirectPage: FC<RedirectPageProps> = ({ url }) => {
	const navigate = useNavigate()

	useEffect(() => {
		navigate(url)
	}, [])

	return <div></div>
}

export default RedirectPage
