import React, { FC, useEffect, useState } from 'react'
import styles from '../Modals.module.scss'
import Input from '../../UI/FormComponents/Input'
import Switch from '../../UI/Switch/Switch'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { NotificationEvent } from '../../../types/NotificationEvent/NotificationEvent'
import { useAPI } from '../../../hooks/useAPI'
import { DeveloperSettingsService } from '../../../API/DeveloperSettingsService'
import { useAppContext } from '../../../hooks/useAppContext'
import Textarea from '../../UI/FormComponents/Textarea'
import { Product } from '../../../types/Product/Product'
import { ProductsService } from '../../../API/ProductsService'
import CustomMultiSelect from '../../UI/CustomMultiSelect/CustomMultiSelect'
import { MultiValue } from 'react-select'
import Checkbox from '../../UI/Checkbox/Checkbox'
import FormErrorsDisplayBadge from '../../UI/FormErrorsDisplayBadge/FormErrorsDisplayBadge'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'
import FieldErrorMessage from '../../UI/FieldErrorMessage/FieldErrorMessage'
import RequiredBadge from '../../UI/RequiredBadge/RequiredBadge'

interface CreateWebhookEndpointModalProps extends BaseModalProps {
	simulate: boolean
	getWebhooks: () => void
}

const CreateWebhookEndpointModal: FC<CreateWebhookEndpointModalProps> = ({
	simulate,
	onClose,
	getWebhooks,
}) => {
	const [url, setUrl] = useState('')
	const [description, setDescription] = useState('')
	const [events, setEvents] = useState<string[]>([])
	const [productsSelectValue, setProductsSelectValue] = useState<string[]>([])
	const { shop } = useAppContext()
	const [showErrorsDisplay, setShowErrorsDisplay] = useState(false)
	const [errors, setErrors] = useState<FormErrors>({
		url: '',
		events: '',
	})

	const errorMessages = Object.keys(errors)
		.map(key => errors[key])
		.filter(error => error)

	const createValidator = () => {
		const urlValidator = fieldValidators.URLValidator('Webhook URI', url)
		const eventsValidator = events.length ? '' : "Webhook events can't be blank "

		setErrors({
			url: urlValidator,
			events: eventsValidator,
		})

		return !(urlValidator || eventsValidator)
	}

	const [isCreateWebhookLoading, , createWebhook] = useAPI(
		() =>
			DeveloperSettingsService.createWebhook(shop?.id || '', {
				events: events as NotificationEvent[],
				url,
				productIds: productsSelectValue?.map(product => +product),
				description: description,
			}),
		false,
		'Webhook successfully created',
		{
			onSuccess: () => {
				getWebhooks()
				onClose()
			},
		}
	)

	const [isGetProductsLoading, products, getProducts] = useAPI<Product[]>(
		() => ProductsService.getProducts(shop?.id || ''),
		false
	)

	const onCreateClickHandler = () => {
		if (createValidator()) {
			createWebhook()
		} else {
			setShowErrorsDisplay(true)
		}
	}

	useEffect(() => {
		getProducts()
	}, [])

	return (
		<div className={styles.modalInner}>
			{/*{showErrorsDisplay && !!errorMessages.length && (*/}
			{/*	<FormErrorsDisplayBadge*/}
			{/*		title={`Wehook Create Error`}*/}
			{/*		errors={errorMessages}*/}
			{/*		isModal={true}*/}
			{/*	/>*/}
			{/*)}*/}

			<header className={styles.header}>
				<h1>{simulate ? 'Simulate Webhook' : 'Create Webhook Endpoint'}</h1>
			</header>

			<div className={styles.body}>
				<div className={styles.fieldWrapper}>
					<h2>
						Webhook URl <RequiredBadge />
					</h2>
					<Input
						value={url}
						setValue={setUrl}
						placeholder={'https://'}
						errorMessage={errors['url']}
						onBlur={() =>
							setErrors({
								...errors,
								url: fieldValidators.URLValidator('Webhook URI', url),
							})
						}
					/>
				</div>
				<div className={styles.fieldWrapper}>
					<h2>Description</h2>
					<Textarea value={description} setValue={setDescription} placeholder={'Description'} />
				</div>
				<div className={styles.fieldWrapper}>
					<h2>Products</h2>
					<CustomMultiSelect
						options={
							products?.map(product => ({
								label: product.name,
								value: product.id.toString(),
							})) || []
						}
						value={productsSelectValue}
						setValue={setProductsSelectValue}
					/>
				</div>
				<div className={styles.fieldWrapper}>
					<h2>
						Events <RequiredBadge />
					</h2>
					<div className={styles.oneColumn}>
						{Object.keys(NotificationEvent).map(key => (
							<div className={styles.borderedSwitchWrapper} key={key}>
								<p>{key.toLowerCase().replaceAll('_', ':')}</p>
								<Checkbox
									id={key}
									checked={events.includes(key)}
									setChecked={checked => {
										if (checked && !events.includes(key)) {
											setEvents([...events, key])
										} else {
											setEvents(events.filter(event => event !== key))
										}
									}}
								/>
							</div>
						))}
					</div>
					{errors['events'] && <FieldErrorMessage errorMessage={errors['events']} />}
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Close
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						disableShadow={true}
						icon={{
							id: 'plus',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						isLoading={isCreateWebhookLoading}
						onClick={onCreateClickHandler}
					>
						Create webhook endpoint
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default CreateWebhookEndpointModal
