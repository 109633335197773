import React, { FC } from 'react'
import styles from '../../CustomerDetailedPage.module.scss'
import UpdatedOnDateBadge from '../../../../components/UI/UpdatedOnDateBadge/UpdatedOnDateBadge'
import { SpriteIcon } from '../../../../components/UI/SpriteIcon/SpriteIcon'
import getSymbolFromCurrency from 'currency-symbol-map'
import { DetailedCustomer } from '../../../../types/DetailedCustomer/DetailedCustomer'

interface CustomerInformationProps {
	customer: DetailedCustomer
	setIsEditBalanceModalActive: (value: boolean) => void
	setIsConnectedDiscordAccountsModalActive: (value: boolean) => void
	isCustomersModifyAvailable: boolean
}

const CustomerInformation: FC<CustomerInformationProps> = ({
	customer,
	setIsEditBalanceModalActive,
	setIsConnectedDiscordAccountsModalActive,
	isCustomersModifyAvailable,
}) => {
	return (
		<div className={styles.section}>
			<header className={styles.header}>
				<h1 className={styles.sectionTitle}>Customer Information</h1>
				<UpdatedOnDateBadge date={new Date()} />
			</header>

			<div className={styles.dataItem}>
				<div className={styles.dataItemHead}>
					<div className={styles.icon}>
						<SpriteIcon iconId={'dollarCircle'} width={18} height={18} />
					</div>
					<div>
						<h2 className={styles.dataTitle}>${customer.totalSpendUSD}</h2>
						<p className={styles.dataSubtitle}>Lifetime Revenue</p>
					</div>
				</div>
			</div>
			{customer?.balance && (
				<div className={styles.dataItem}>
					<div className={styles.dataItemHead}>
						<div className={styles.icon}>
							<SpriteIcon iconId={'wallet'} width={18} height={18} />
						</div>
						<div>
							<h2 className={styles.dataTitle}>
								{getSymbolFromCurrency(customer?.balance?.currency || 'usd')}
								{customer?.balance?.amount}
							</h2>
							<p className={styles.dataSubtitle}>Store Balance</p>
						</div>
					</div>
					{isCustomersModifyAvailable && (
						<SpriteIcon
							className={styles.cursorPointer}
							iconId={'pen'}
							width={13}
							height={13}
							onClick={() => setIsEditBalanceModalActive(true)}
						/>
					)}
				</div>
			)}
			{customer.discordSocialConnects[0] && (
				<div className={styles.dataItem}>
					<div className={styles.dataItemHead}>
						<div className={styles.icon}>
							<SpriteIcon iconId={'discord3'} width={20} height={15} />
						</div>
						<div>
							<h2 className={styles.dataTitle}>{customer.discordSocialConnects[0].username}</h2>
							<p className={styles.dataSubtitle}>{customer.discordSocialConnects[0].id}</p>
						</div>
					</div>

					<SpriteIcon
						className={styles.cursorPointer}
						iconId={'search'}
						width={13}
						height={13}
						onClick={() => setIsConnectedDiscordAccountsModalActive(true)}
					/>
				</div>
			)}

			{/*<div className={styles.dataItem}>*/}
			{/*	<div className={styles.dataItemHead}>*/}
			{/*		<div className={styles.icon}>*/}
			{/*			<SpriteIcon iconId={'telegram'} width={20} height={15} />*/}
			{/*		</div>*/}
			{/*		<div>*/}
			{/*			<h2 className={styles.dataTitle}>Demo</h2>*/}
			{/*			<p className={styles.dataSubtitle}>@demodotcom</p>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}

			<div className={styles.dataItem}>
				<div className={styles.dataItemHead}>
					<div className={styles.icon}>
						<SpriteIcon iconId={'atSymbol'} width={20} height={15} />
					</div>
					<div>
						<h2 className={styles.dataTitle}>{customer?.email}</h2>
						<p className={styles.dataSubtitle}>Email Address</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CustomerInformation
