import React, { FC } from 'react'
import styles from './FormComponents.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage'

export enum InputIconAligns {
	RIGHT = 'right',
	LEFT = 'left',
}

interface InputProps {
	value: string | number | undefined
	setValue: (value: any) => void
	placeholder?: string
	type?: string
	required?: boolean
	pattern?: string
	errorMessage?: string
	autoComplete?: string
	disabled?: boolean
	icon?: {
		id: string
		height: number
		width: number
		align?: InputIconAligns
		onClick?: () => void
		className?: string
	}
	maxLength?: number
	min?: number
	max?: number
	id?: string
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
}

const Input: FC<InputProps> = ({
	value,
	setValue,
	placeholder,
	type,
	pattern,
	required,
	errorMessage,
	autoComplete,
	disabled,
	icon,
	maxLength,
	onBlur,
	id,
	min,
	max,
}) => {
	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value: string | number = event.target.value

		setValue(value)
	}

	return (
		<div className={`${styles.inputWrapper} ${icon?.align && styles[icon?.align]}`}>
			{icon && (
				<div className={styles.iconWrapper} onClick={icon.onClick}>
					<SpriteIcon
						className={icon.className}
						iconId={icon.id}
						width={icon.width}
						height={icon.height}
					/>
				</div>
			)}
			<input
				id={id}
				className={`${styles.input} ${errorMessage ? styles.error : ''} ${icon && styles.withIcon}`}
				placeholder={placeholder}
				value={value}
				type={type}
				onChange={onChangeHandler}
				pattern={pattern}
				required={required}
				autoComplete={autoComplete}
				disabled={disabled}
				maxLength={maxLength}
				onBlur={onBlur}
				min={min}
				max={max}
			/>
			{errorMessage && <FieldErrorMessage errorMessage={errorMessage} />}
		</div>
	)
}

export default Input
