import React, { FC } from 'react'
import styles from './RewardRuleModal.module.scss'
import modalStyles from '../Modals.module.scss'
import Input from '../../UI/FormComponents/Input'
import RewardTypeForm from './RewardTypeForm'
import Radio from '../../UI/Radio/Radio'
import RadioWrapper from '../../UI/RadioWrapper/RadioWrapper'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'
import RequiredBadge from '../../UI/RequiredBadge/RequiredBadge'

interface ReviewRuleFormProps {
	minReviews: number
	setMinReviews: (value: number) => void
	minStars: number
	setMinStars: (value: number) => void
	errors: FormErrors
	setErrors: (value: FormErrors) => void
}

const ReviewRuleForm: FC<ReviewRuleFormProps> = ({
	minReviews,
	setMinReviews,
	setMinStars,
	minStars,
	errors,
	setErrors,
}) => {
	return (
		<div className={modalStyles.mtMediumLarge}>
			<div className={modalStyles.fieldWrapper}>
				<h2>
					Minimum Reviews <RequiredBadge />
				</h2>
				<Input
					value={minReviews}
					setValue={setMinReviews}
					placeholder={'0'}
					type={'number'}
					errorMessage={errors['minReviews']}
					onBlur={() =>
						setErrors({
							...errors,
							minReviews: fieldValidators.blankValidator('Minimum reviews', minReviews),
						})
					}
				/>
				<p className={modalStyles.fieldDescription}>
					How much reviews should customers leave before unlocking this reward?
				</p>
			</div>

			<div className={modalStyles.fieldWrapper}>
				<div className={modalStyles.spaceBetween}>
					<RadioWrapper
						id={'positive'}
						value={'4'}
						setValue={() => setMinStars(4)}
						checked={minStars === 4}
						title={'POSITIVE'}
						description={'4-5 stars'}
					/>

					<RadioWrapper
						id={'neutral'}
						setValue={() => setMinStars(3)}
						value={''}
						checked={minStars === 3}
						title={'NEUTRAL'}
						description={'3 stars'}
					/>

					<RadioWrapper
						id={'negative'}
						setValue={() => setMinStars(1)}
						value={''}
						checked={minStars === 1}
						title={'NEGATIVE'}
						description={'1-2 stars'}
					/>
				</div>
			</div>
		</div>
	)
}

export default ReviewRuleForm
