import React, { FC } from 'react'
import styles from './Tables.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { NavLink } from 'react-router-dom'
import StatusBadge from '../UI/StatusBadge/StatusBadge'
import PaymentTypeBadge from '../PaymentTypeBadge/PaymentTypeBadge'
import { Order } from '../../types/Order/Order'
import { cleanEmail } from '../../helpers/cleanEmail'
import OrdersStatusBadge from '../UI/StatusBadges/OrdersStatusBadge'
import { format } from 'date-fns'
import getSymbolFromCurrency from 'currency-symbol-map'

interface OrdersTableProps {
	orders: Order[]
}

interface OrdersTableBodyItemProps {
	order: Order
}

const OrdersTableBodyItem: FC<OrdersTableBodyItemProps> = ({ order }) => {
	const cleanedEmail = cleanEmail(order.customerEmail)

	return (
		<NavLink className={styles.trWrapper} to={`/finances/orders/${order.id}`}>
			<tr>
				<td className={styles.large}>
					<div className={styles.itemInfo}>
						<div className={styles.itemImage}>
							{cleanedEmail[0]}
							{cleanedEmail[cleanedEmail.length - 1]}
						</div>
						<div>
							<p>{order.customerEmail}</p>
							<span>ID: {order.id}</span>
						</div>
					</div>
				</td>
				<td>
					<OrdersStatusBadge orderStatus={order.status} />
				</td>
				<td>
					<PaymentTypeBadge gateway={order.gateway} />
				</td>
				<td>
					<p>{format(new Date(order.createdAt), 'MMM dd, yyyy')}</p>
					<span>{format(new Date(order.createdAt), 'hh:mm aa z')}</span>
				</td>
				<td>
					<p>
						{getSymbolFromCurrency(order.endPrice.currency)}
						{order.endPrice.amount}
					</p>
				</td>
				<td className={styles.small}>
					<SpriteIcon className={styles.arrowRight} iconId={'arrowDown'} width={10} height={4} />
				</td>
			</tr>
		</NavLink>
	)
}

const OrdersTable: FC<OrdersTableProps> = ({ orders }) => {
	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.large}>Customer Info</th>
					<th>Status</th>
					<th>Payment Type</th>
					<th>Date & Time</th>
					<th>Revenue</th>
					<th className={styles.small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{orders.map(order => (
					<OrdersTableBodyItem order={order} key={order.id} />
				))}
			</tbody>
		</table>
	)
}

export default OrdersTable
