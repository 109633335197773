import React, { FC, useState } from 'react'
import styles from './Tables.module.scss'
import { PartOrder } from '../../types/PartOrder/PartOrder'
import getSymbolFromCurrency from 'currency-symbol-map'
import PartOrderStatusBadge from '../UI/StatusBadges/PartOrderStatusBadge'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import { ActionMenuOption } from '../../types/ActionMenuOption/ActionMenuOption'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { PartOrderDeliveryStatus } from '../../types/PartOrderDeliveryStatus/PartOrderDeliveryStatus'
import { PartOrderReplacement } from '../../types/PartOrderReplacement/PartOrderReplacement'

interface OrderReplacementsTableProps {
	partOrderReplacements: PartOrderReplacement[]
	onViewClick: (productId: number, replacementId: number) => void
}

interface OrderReplacementsTableBodyItemProps {
	partOrderReplacement: PartOrderReplacement
	onViewClick: (productId: number, replacementId: number) => void
}

const OrderReplacementsTableBodyItem: FC<OrderReplacementsTableBodyItemProps> = ({
	partOrderReplacement,
	onViewClick,
}) => {
	return (
		<div className={styles.trWrapper}>
			<tr>
				<td className={styles.large}>
					<div className={styles.itemInfo}>
						<div className={styles.itemImage}>
							{/*<img src={`https://imagedelivery.net/${payment?.logoCfImageId}/productCard`} alt=""/>*/}
						</div>
						<div>
							<p>{partOrderReplacement.productWithVariant.name}</p>
							<span>ID: {partOrderReplacement.productWithVariant.id}</span>
						</div>
					</div>
				</td>
				<td>
					<p>{partOrderReplacement.productWithVariant.variantName}</p>
				</td>
				<td>
					<p>
						{getSymbolFromCurrency(partOrderReplacement.productWithVariant.price.currency)}
						{partOrderReplacement.productWithVariant.price.amount}
					</p>
				</td>
				<td>
					<p>{partOrderReplacement.quantity}</p>
				</td>
				<td>
					<PartOrderStatusBadge partOrderStatus={partOrderReplacement.deliveryStatus} />
				</td>
				<td>
					{partOrderReplacement.deliveryStatus !== PartOrderDeliveryStatus.NOT_DELIVERED ? (
						<Button
							style={ButtonStyles.BORDERED}
							disableShadow={true}
							icon={{
								id: 'plus',
								width: 13,
								height: 13,
								align: ButtonsIconAlign.LEFT,
							}}
							onClick={() => onViewClick(partOrderReplacement.partOrderId, partOrderReplacement.id)}
						>
							View
						</Button>
					) : (
						'-'
					)}
				</td>

				{/*<td className={styles.small}>*/}
				{/*	<ActionMenu*/}
				{/*		actionButtons={actionButtons}*/}
				{/*		isActive={isActionMenuActive}*/}
				{/*		setIsActive={setIsActionMenuActive}*/}
				{/*	/>*/}
				{/*</td>*/}
			</tr>
		</div>
	)
}

const OrderReplacementTable: FC<OrderReplacementsTableProps> = ({
	partOrderReplacements,
	onViewClick,
}) => {
	return (
		<table className={`${styles.table} ${styles.fullWidth}`}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.large}>Product</th>
					<th>Variant</th>
					<th>Price</th>
					<th>Quantity</th>
					<th>Status</th>
					<th>DELIVERY</th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{partOrderReplacements.map(partOrderReplacement => (
					<OrderReplacementsTableBodyItem
						partOrderReplacement={partOrderReplacement}
						onViewClick={onViewClick}
						key={partOrderReplacement.id}
					/>
				))}
			</tbody>
		</table>
	)
}

export default OrderReplacementTable
