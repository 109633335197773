import React, { useEffect, useState } from 'react'
import styles from './AffiliatesMembersPage.module.scss'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageHeader from '../../components/PageHeader/PageHeader'
import SearchInput, { SearchInputStyles } from '../../components/UI/SearchInput/SearchInput'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import CustomSelect from '../../components/UI/CustomSelect/CustomSelect'
import AffiliatesMember from '../../components/AffiliatesMember/AffiliatesMember'
import Pagination from '../../components/UI/Pagination/Pagination'
import PageTableHeader from '../../components/PageTableHeader/PageTableHeader'
import { useAPI } from '../../hooks/useAPI'
import { AffiliatesService } from '../../API/AffiliatesService'
import { useAppContext } from '../../hooks/useAppContext'
import { Affiliate } from '../../types/Affiliate/Affiliate'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import { useDebounce } from '../../hooks/useDebounce'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import noMembersImage from '../../assets/images/noMembers.png'
import { usePageTitle } from '../../hooks/usePageTitle'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

const AffiliatesMembersPage = () => {
	const [searchValue, setSearchValue] = useState('')
	const [totalItems, setTotalItems] = useState(0)
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const { shop } = useAppContext()
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isAffiliatesViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.AFFILIATES_VIEW) || isAdmin
	usePageTitle('Members')

	const [isGetAffiliatesLoading, affiliates, getAffiliates] = useAPI<Affiliate[]>(
		() => AffiliatesService.getAll(shop?.id || '', searchValue, currentPage + 1, pageSize),
		false
	)
	console.log(affiliates)

	const isFirstLoading = useFirstLoading(isGetAffiliatesLoading)
	useDebounce(searchValue, getAffiliates, 400)

	useEffect(() => {
		if (shop && isAffiliatesViewAvailable) {
			getAffiliates()
		}
	}, [shop, currentPage, pageSize])

	if (isFirstLoading) {
		return <PageLoading />
	}

	if (!isAffiliatesViewAvailable)
		return (
			<NoDataComponent
				imageSrc={noMembersImage}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)

	if (!affiliates?.length && !searchValue) {
		return (
			<NoDataComponent
				imageSrc={noMembersImage}
				title={'No Members'}
				description={'When a customer joins your affiliate program, they will appear here.'}
			/>
		)
	}

	return (
		<PageContainer>
			<PageWrapper>
				<div className={styles.affiliatesMembersPage}>
					<PageHeader title={'Members'}>
						<div className={styles.headerEnd}>
							<SearchInput
								value={searchValue}
								setValue={setSearchValue}
								style={SearchInputStyles.secondary}
								placeholder={'Search for a member by name or email'}
							/>
						</div>
					</PageHeader>

					<PageTableHeader
						listItemNamePlural={'members'}
						totalItems={totalItems}
						pageSize={pageSize}
						setPageSize={setPageSize}
						setCurrentPage={setCurrentPage}
					/>

					<div className={styles.body}>
						{affiliates?.map(affiliate => (
							<AffiliatesMember affiliate={affiliate} />
						))}
					</div>

					<div className={styles.paginationWrapper}>
						<Pagination
							withoutPageSizeSelector={true}
							totalItems={totalItems}
							pageSize={pageSize}
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
						/>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default AffiliatesMembersPage
