import React, { useState } from 'react'
import styles from '../DiscordBotBuilderPage.module.scss'
import discordTokenImg from '../../../assets/images/discordToken.png'
import discordClientIdSecretImg from '../../../assets/images/discordClientIdSecret.png'
import Input from '../../../components/UI/FormComponents/Input'
import AlertBadge from '../../../components/UI/AlertBadge/AlertBadge'
import { Alert } from '../../../types/Alert/Alert'
import ColorInput from '../../../components/UI/FormComponents/ColorInput'
import CustomSelect from '../../../components/UI/CustomSelect/CustomSelect'
import { NavLink } from 'react-router-dom'
import Switch from '../../../components/UI/Switch/Switch'

const VerifyBotStep = () => {
	return (
		<div className={styles.stepWrapper}>
			<p className={styles.defaultTitle}>Confirm Bot Setup</p>
			<p className={`${styles.secondaryText} ${styles.mt10}`}>
				Before you save your bot, please confirm that the bot below is the bot you’ve set connected
				to Billgang.
			</p>
			<div className={`${styles.mtMediumLarge}`}>
				<p className={styles.defaultTitle}>Primary Bot</p>
				<div className={`${styles.readyBot} ${styles.mt10}`}>
					<div className={styles.readyBotLogo}>bla</div>
					<h2 className={styles.readyBotName}>Gang#9012</h2>
				</div>
			</div>

			<div className={`${styles.mtMediumLarge}`}>
				<p className={styles.defaultTitle}>Secondary Bot</p>
				<div className={`${styles.readyBot} ${styles.mt10}`}>
					<div className={styles.readyBotLogo}>bla</div>
					<h2 className={styles.readyBotName}>Gang#9012</h2>
				</div>
			</div>

			<p className={`${styles.secondaryText} ${styles.mtMediumLarge}`}>
				If your bot(s) look correct, feel free to tap on the “Create Bot” button below, we’ll bring
				your bot(s) online based on your subscription type.
			</p>
		</div>
	)
}

export default VerifyBotStep
