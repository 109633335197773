import React, { FC, useEffect } from 'react'
import modalStyles from '../Modals.module.scss'
import JSONWrapper from '../../UI/JSONWrapper/JSONWrapper'
import Button, { ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import Input from '../../UI/FormComponents/Input'
import { AuditLog } from '../../../types/AuditLog/AuditLog'
import { format } from 'date-fns'
import { useAPI } from '../../../hooks/useAPI'
import { AuditLogsService } from '../../../API/AuditLogsService'
import { useAppContext } from '../../../hooks/useAppContext'
import Spinner from '../../UI/Spinner/Spinner'
import { AuditLogDetailed } from '../../../types/AuditLog/AuditLogDetailed'

interface TeamMemberEventDetailsProps extends BaseModalProps {
	currentAuditLog: AuditLog | null
}

const TeamMemberEventDetailsModal: FC<TeamMemberEventDetailsProps> = ({
	onClose,
	currentAuditLog,
}) => {
	const { shop } = useAppContext()
	const [isGetAuditLogDetailed, auditLogDetailed, getAuditLogDetailed] = useAPI<AuditLogDetailed>(
		() => AuditLogsService.getSpecific(shop?.id || '', currentAuditLog?.id || ''),
		false
	)

	useEffect(() => {
		getAuditLogDetailed()
	}, [])

	if (isGetAuditLogDetailed) return <Spinner />

	return (
		<div className={modalStyles.modalInner}>
			<header className={modalStyles.header}>
				<h1>Event Details</h1>
			</header>
			<div className={modalStyles.body}>
				<div className={modalStyles.fieldWrapper}>
					<h2>Team Member</h2>
					<Input value={currentAuditLog?.user.email} setValue={() => {}} disabled={true} />
				</div>
				<div className={modalStyles.fieldWrapper}>
					<h2>Date</h2>
					<Input
						value={format(
							new Date(currentAuditLog?.createdAt || ''),
							"MMM dd, yyyy 'at' hh:mm aa z"
						)}
						setValue={() => {}}
						disabled={true}
					/>
				</div>
				<div className={modalStyles.fieldWrapper}>
					<h2>Details</h2>
					<JSONWrapper
						style={'secondary'}
						json={JSON.parse(auditLogDetailed?.responseBody || '{}')}
					/>
				</div>
			</div>
			<footer className={modalStyles.footer}>
				<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
					Cancel
				</Button>
			</footer>
		</div>
	)
}

export default TeamMemberEventDetailsModal
