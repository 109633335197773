import React, { useState } from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'
import styles from '../DiscordBotBuilderPage.module.scss'
import SearchInput, { SearchInputStyles } from '../../../components/UI/SearchInput/SearchInput'
import PageTableHeader from '../../../components/PageTableHeader/PageTableHeader'
import Pagination from '../../../components/UI/Pagination/Pagination'
import DiscordServersTable from '../../../components/Tables/DiscordServersTable'

const DiscordBotBuilderServersTab = () => {
	const [search, setSearch] = useState('')
	const [pageSize, setPageSize] = useState(10)
	const [currentPage, setCurrentPage] = useState(0)

	return (
		<div className={styles.mtMediumLarge}>
			<PageHeader title={'Servers'}>
				<SearchInput
					style={SearchInputStyles.secondary}
					value={search}
					setValue={setSearch}
					placeholder={'Search for a server by id or name'}
				/>
			</PageHeader>

			<PageTableHeader
				listItemNamePlural={'servers'}
				totalItems={2}
				pageSize={pageSize}
				setPageSize={setPageSize}
				setCurrentPage={setCurrentPage}
			/>

			<div className={`${styles.section} ${styles.table}`}>
				<DiscordServersTable />
			</div>

			<div className={styles.mtMediumLarge}>
				<Pagination
					withoutPageSizeSelector={true}
					totalItems={2}
					pageSize={pageSize}
					setCurrentPage={setCurrentPage}
					currentPage={currentPage}
				/>
			</div>
		</div>
	)
}

export default DiscordBotBuilderServersTab
