import React, { FC, useEffect, useState } from 'react'
import styles from './TopPayments.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import FeedbackActionMenu from '../FeedbackActionMenu/FeedbackActionMenu'
import { DatePeriod } from '../../types/DatePeriod/DatePeriod'
import UpdatedOnDateBadge from '../UI/UpdatedOnDateBadge/UpdatedOnDateBadge'
import Tabs from '../UI/Tabs/Tabs'
import { GetSalesChart } from '../../types/GetSalesChart/GetSalesChart'
import getSymbolFromCurrency from 'currency-symbol-map'
import { useAppContext } from '../../hooks/useAppContext'
import { getGatewaysOptions } from '../../helpers/getGatewaysOptions'
import { formatNumberWithDecimalsAndCommas } from '../../helpers/formatNumberWithDecimalsAndCommas'

enum TopPaymentsTabs {
	orders,
	revenue,
}

interface TrafficAcquisitionProps {
	datePeriod: DatePeriod
	data:
		| {
				amount: number
				count: number
				gatewayName: string
		  }[]
		| undefined
}

const TopPayments: FC<TrafficAcquisitionProps> = ({ datePeriod, data }) => {
	const [currentTab, setCurrentTab] = useState<TopPaymentsTabs>(TopPaymentsTabs.revenue)
	const [updatedDate, setUpdatedDate] = useState(new Date())
	const { shop, availablePayments } = useAppContext()

	const sortedGatewayCounts = data?.sort((a, b) => {
		if (currentTab === TopPaymentsTabs.orders) {
			return b.count - a.count
		}

		return b.amount - a.amount
	})

	const top4Gateways = sortedGatewayCounts?.slice(0, 4)
	const restOfData: { amount: number; count: number } | undefined = sortedGatewayCounts
		?.slice(4)
		.reduce(
			(acc, curr) => {
				acc = {
					count: (acc?.count || 0) + curr.count,
					amount: (acc?.amount || 0) + curr.amount,
				}
				return acc
			},
			{ amount: 0, count: 0 }
		)

	const getDatePeriodInString = () => {
		switch (datePeriod) {
			case DatePeriod.allTime:
				return 'All time'
			case DatePeriod.lastDay:
				return 'Last 24 hours'
			case DatePeriod.lastMonth:
				return 'Last 30 days'
			case DatePeriod.lastWeek:
				return 'Last 7 days'
			case DatePeriod.lastYear:
				return 'Last 12 months'
		}
	}

	useEffect(() => {
		setUpdatedDate(new Date())
	}, [data])

	return (
		<div className={styles.topProducts}>
			<header className={styles.header}>
				<h1>Payment Types</h1>
				<FeedbackActionMenu />
			</header>

			<header className={styles.subHeader}>
				<div>
					<time className={styles.time}>{getDatePeriodInString()}</time>
				</div>

				<UpdatedOnDateBadge date={new Date()} />
			</header>
			<div className={styles.tabsWrapper}>
				<Tabs
					tabs={[
						{
							label: 'Revenue',
							value: TopPaymentsTabs.revenue,
						},
						{
							label: 'Orders',
							value: TopPaymentsTabs.orders,
						},
					]}
					currentTab={currentTab}
					setCurrentTab={setCurrentTab}
				/>
			</div>

			<div className={styles.statistics}>
				{top4Gateways?.map(gateway => (
					<div className={styles.statisticsItem} key={gateway.gatewayName}>
						<div className={styles.statisticsItemHead}>
							<div className={`${styles.color} ${styles[gateway.gatewayName]}`} />
							<p>
								{
									availablePayments?.find(
										availablePayment => availablePayment.name === gateway.gatewayName
									)?.displayName
								}
							</p>
						</div>
						<span>
							{currentTab === TopPaymentsTabs.revenue
								? `${getSymbolFromCurrency(
										shop?.currency || 'USD'
								  )}${formatNumberWithDecimalsAndCommas(gateway.amount, 2)}`
								: gateway.count.toLocaleString()}{' '}
							{currentTab === TopPaymentsTabs.orders && (gateway.count > 1 ? 'orders' : 'order')}
						</span>
					</div>
				))}
				{!!restOfData?.count && (
					<div className={styles.statisticsItem}>
						<div className={styles.statisticsItemHead}>
							<div className={`${styles.color} `} />
							<p>Other</p>
						</div>
						<span>
							{currentTab === TopPaymentsTabs.revenue
								? `${getSymbolFromCurrency(shop?.currency || 'USD')}${restOfData.amount}`
								: restOfData.count.toLocaleString()}{' '}
							{currentTab === TopPaymentsTabs.orders && (restOfData.count > 1 ? 'orders' : 'order')}
						</span>
					</div>
				)}
			</div>
		</div>
	)
}

export default TopPayments
