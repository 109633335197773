import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import styles from './AllCategoriesPage.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import SearchInput, { SearchInputStyles } from '../../components/UI/SearchInput/SearchInput'
import { useNavigate } from 'react-router-dom'
import Pagination from '../../components/UI/Pagination/Pagination'
import CategoriesTable from '../../components/Tables/CategoriesTable'
import { useAPI } from '../../hooks/useAPI'
import { useAppContext } from '../../hooks/useAppContext'
import { Category } from '../../types/Category/Category'
import { CategoriesService } from '../../API/CategoriesService'
import Spinner from '../../components/UI/Spinner/Spinner'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import noCategoriesImage from '../../assets/images/noCategories.png'
import Modal from '../../components/Modals/Modal'
import { closeModal } from '../../helpers/closeModal'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import PageTableHeader from '../../components/PageTableHeader/PageTableHeader'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import { useDebounce } from '../../hooks/useDebounce'
import { usePageTitle } from '../../hooks/usePageTitle'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

const AllCategoriesPage = () => {
	const [searchValue, setSearchValue] = useState('')
	const [totalItems, setTotalItems] = useState(23)
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [currentCategoryId, setCurrentCategoryId] = useState<number | null>(null)
	const [isDeleteModalActive, setIsDeleteModalActive] = useState(false)
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
	const navigate = useNavigate()
	const { shop } = useAppContext()
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isCategoriesViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.CATEGORIES_VIEW) || isAdmin
	const isCategoriesModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.CATEGORIES_MODIFY) || isAdmin
	const isCategoriesDeleteAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.CATEGORIES_DELETE) || isAdmin
	usePageTitle('Categories')

	const [isGetCategoriesLoading, categories, getCategories] = useAPI<Category[]>(
		(searchValue: string) =>
			CategoriesService.getAll(shop?.id || '', searchValue, currentPage + 1, pageSize),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	useDebounce(searchValue, getCategories, 400)

	const isFirstLoading = useFirstLoading(isGetCategoriesLoading)

	const [isDeleteCategoryLoading, , deleteCategory] = useAPI(
		() => CategoriesService.delete(shop?.id || '', currentCategoryId),
		false,
		'Category successfully deleted',
		{
			onSuccess: () => {
				getCategories()
			},
		}
	)

	const onCategoryDeleteClickHandler = (id: number) => {
		setCurrentCategoryId(id)
		setIsDeleteModalActive(true)
	}

	const closeDeleteModalHandler = () => {
		closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)
		setCurrentCategoryId(null)
	}

	useEffect(() => {
		if (shop && isCategoriesViewAvailable) {
			getCategories()
		}
	}, [shop, currentPage, pageSize])

	if (isFirstLoading) return <PageLoading />

	if (!isCategoriesViewAvailable)
		return (
			<NoDataComponent
				imageSrc={noCategoriesImage}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)

	if (!categories?.length && !searchValue) {
		return (
			<NoDataComponent
				imageSrc={noCategoriesImage}
				title={'No Categories'}
				description={
					'You don’t have any categories. To create a category, tap on the button below.'
				}
				button={
					isCategoriesModifyAvailable
						? {
								icon: {
									id: 'plus',
									height: 13,
									width: 13,
									align: ButtonsIconAlign.LEFT,
								},
								onClick: () => navigate('create'),
								text: 'Create Category',
						  }
						: null
				}
			/>
		)
	}

	return (
		<PageContainer>
			{isDeleteModalActive && (
				<Modal
					closeModal={closeDeleteModalHandler}
					visible={isDeleteModalVisible}
					setVisible={setIsDeleteModalVisible}
				>
					<ConfirmModal
						title={'Delete Category'}
						description={`You are about to delete ${
							categories?.find(category => category.id === currentCategoryId)?.name
						}. Are you sure you want to delete this category? This cannot be undone.`}
						onConfirm={deleteCategory}
						onClose={closeDeleteModalHandler}
						isButtonLoading={isDeleteCategoryLoading}
						confirmButtonText={'Delete'}
						buttonIcon={{
							id: 'trashBin',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
					/>
				</Modal>
			)}
			<PageWrapper>
				<div className={styles.allGroupsPage}>
					<PageHeader title={'Categories'}>
						<div className={styles.headerEnd}>
							<SearchInput
								value={searchValue}
								setValue={setSearchValue}
								style={SearchInputStyles.secondary}
								placeholder={'Search for a category'}
							/>
							{isCategoriesModifyAvailable && (
								<Button
									style={ButtonStyles.SECONDARY}
									icon={{
										id: 'plus',
										height: 13,
										width: 13,
										align: ButtonsIconAlign.LEFT,
									}}
									onClick={() => navigate('create')}
								>
									Create Category
								</Button>
							)}
						</div>
					</PageHeader>

					<PageTableHeader
						pageSize={pageSize}
						setPageSize={setPageSize}
						totalItems={totalItems}
						listItemNamePlural={'categories'}
						setCurrentPage={setCurrentPage}
					/>

					{isGetCategoriesLoading ? (
						<Spinner />
					) : (
						categories && (
							<div className={styles.groupsList}>
								<CategoriesTable
									categories={categories}
									onDeleteClick={onCategoryDeleteClickHandler}
									isCategoriesModifyAvailable={isCategoriesModifyAvailable}
									isCategoriesDeleteAvailable={isCategoriesDeleteAvailable}
								/>
							</div>
						)
					)}

					<div className={styles.paginationWrapper}>
						<Pagination
							withoutPageSizeSelector={true}
							totalItems={totalItems}
							pageSize={pageSize}
							setPageSize={setPageSize}
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
						/>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default AllCategoriesPage
