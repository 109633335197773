import React, { FC } from 'react'
import styles from './StatusBadges.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import { WarehouseStatus } from '../../../types/WarehouseStatus/WarehouseStatus'
import { OrderStatus } from '../../../types/OrderStatus/OrderStatus'
import { DomainStatus } from '../../../types/DomainStatus/DomainStatus'

interface DomainStatusBadgeProps {
	domainStatus: DomainStatus
}

const DomainStatusBadge: FC<DomainStatusBadgeProps> = ({ domainStatus }) => {
	const domainStatusData = {
		[DomainStatus.ACTIVE]: {
			name: 'Active',
			className: 'green',
			iconId: 'checkedCircle',
		},
		[DomainStatus.INACTIVE]: {
			name: 'Inactive',
			className: 'red',
			iconId: 'xMarkCircle',
		},
		[DomainStatus.PENDING_VERIFICATION]: {
			name: 'Pending',
			className: 'brown',
			iconId: 'exclamationMarkCircle',
		},
	}

	return (
		<div className={`${styles.statusBadge} ${styles[domainStatusData[domainStatus].className]}`}>
			{domainStatusData[domainStatus].name}
			<SpriteIcon iconId={domainStatusData[domainStatus].iconId} width={16} height={16} />
		</div>
	)
}

export default DomainStatusBadge
