import React, { FC, useEffect, useState } from 'react'
import styles from '../Modals.module.scss'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import Input from '../../UI/FormComponents/Input'
import { fieldValidators } from '../../../helpers/fieldValidators'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import ImagePicker from '../../ImagePicker/ImagePicker'
import { Image } from '../../../types/Image/Image'
import CustomMDEditor from '../../UI/CustomMDEditor/CustomMDEditor'
import { CustomGatewayVisibility } from '../../../types/CustomGatewayVisibility/CustomGatewayVisibility'
import { useAPI } from '../../../hooks/useAPI'
import { CustomGatewaysService } from '../../../API/CustomGatewaysService'
import RadioWrapper from '../../UI/RadioWrapper/RadioWrapper'
import JsonFileInput from '../../UI/JsonFileInput/JsonFileInput'
import { CreateCustomGateway } from '../../../types/CreateCustomGateway/CreateCustomGateway'
import FieldErrorMessage from '../../UI/FieldErrorMessage/FieldErrorMessage'
import { CustomPlugin } from '../../../types/Plugin/CustomPlugin'
import Textarea from '../../UI/FormComponents/Textarea'
import AlertBadge from '../../UI/AlertBadge/AlertBadge'
import { Alert } from '../../../types/Alert/Alert'
import { PaymentMethod } from '../../../types/PaymentMethod/PaymentMethod'
import CustomSelect from '../../UI/CustomSelect/CustomSelect'
import CustomMultiSelect from '../../UI/CustomMultiSelect/CustomMultiSelect'
import OptionalBadge from '../../UI/OptionalBadge/OptionalBadge'

interface CreateCustomGatewayModalProps extends BaseModalProps {
	// plugins?: CustomPlugin[]
	getPlugins: () => void
	editingPluginId?: number | null
}

const CreateCustomGatewayModal: FC<CreateCustomGatewayModalProps> = ({
	onClose,
	getPlugins,
	editingPluginId,
}) => {
	const [name, setName] = useState('')
	const [displayName, setDisplayName] = useState('')
	const [notes, setNotes] = useState('')
	const [description, setDescription] = useState<string>()
	const [errors, setErrors] = useState<FormErrors>({})
	const [image, setImage] = useState<Image | null>(null)
	const [visibility, setVisibility] = useState<CustomGatewayVisibility>(
		CustomGatewayVisibility.PUBLIC
	)
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])
	const [jsonFile, setJsonFile] = useState<File | null>(null)

	const [isCreatePluginLoading, , createPlugin] = useAPI(
		async data => CustomGatewaysService.create(data),
		false,
		'Payment plugin successfully created',
		{
			onSuccess: () => {
				onClose()
				getPlugins()
			},
		}
	)

	const [isGetEditingPluginLoading, editingPlugin, getEditingPlugin] = useAPI<CustomPlugin>(
		pluginId => CustomGatewaysService.getOne(pluginId),
		false
	)

	const [isUpdatePluginLoading, , updatePlugin] = useAPI(
		async data => CustomGatewaysService.update(Number(editingPluginId), data),
		false,
		'Payment plugin successfully updated',
		{
			onSuccess: () => {
				onClose()
				getPlugins()
			},
		}
	)

	const [, manifest, getManifest] = useAPI(
		pluginId => CustomGatewaysService.getManifest(pluginId),
		false
	)

	const submitValidator = () => {
		const nameValidator = fieldValidators.blankValidator('Plugin name', name)
		const descriptionValidator = fieldValidators.blankValidator('Plugin description', description)
		const imageValidator = image ? '' : "Plugin image can't be empty"
		const jsonValidator = jsonFile ? '' : "Manifest JSON can't be empty"
		const notesValidator = !editingPluginId
			? ''
			: fieldValidators.blankValidator('Plugin notes', notes)

		setErrors({
			name: nameValidator,
			description: descriptionValidator,
			image: imageValidator,
			json: jsonValidator,
			notes: notesValidator,
		})

		return !(
			imageValidator ||
			nameValidator ||
			descriptionValidator ||
			jsonValidator ||
			notesValidator
		)
	}

	const onSubmitClick = () => {
		const reader = new FileReader()
		jsonFile && reader.readAsText(jsonFile)

		const submitData = (json?: any) => {
			const data: CreateCustomGateway = {
				description: description,
				name,
				displayName,
				visibility: visibility,
				logoImageId: image?.id || null,
				manifestEntityJson: json || manifest,
				paymentMethods: paymentMethods,
				notes: notes || null,
			}

			if (editingPluginId) {
				updatePlugin<CreateCustomGateway>(data)
			} else {
				createPlugin<CreateCustomGateway>(data)
			}
		}

		if (submitValidator()) {
			if (jsonFile) {
				reader.onload = event => {
					const fileContent = event.target?.result

					const json = typeof fileContent === 'string' && JSON.parse(fileContent)

					submitData(json)
				}
			} else {
				submitData()
			}
		}
	}

	useEffect(() => {
		if (editingPluginId) {
			getEditingPlugin(editingPluginId)
			// getManifest(editingPluginId)
		}
	}, [editingPluginId])

	useEffect(() => {
		if (editingPlugin) {
			setName(editingPlugin.name)
			setDisplayName(editingPlugin.displayName)
			setDescription(editingPlugin.description)
			setVisibility(editingPlugin.visibility)
			setImage({
				id: editingPlugin.logoImage.id,
				cfId: editingPlugin.logoImage.cfId,
				fileName: editingPlugin.logoImage.cfId,
				createdAt: new Date(),
			})
			setPaymentMethods(editingPlugin.paymentMethods)
		}
	}, [editingPlugin])

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>{editingPluginId ? 'Update' : 'Create'} Payment Plugin</h1>
			</header>

			<div className={styles.body}>
				{!editingPluginId && (
					<AlertBadge alert={Alert.INFORMATION} disableShadow={true}>
						<p>
							Follow this <a href='developer article'>developer article</a> to create payment
							plugins
						</p>
					</AlertBadge>
				)}

				<div className={styles.fieldWrapper}>
					<h2>Plugin Name</h2>
					<Input
						value={name}
						setValue={setName}
						placeholder={'Payment Processor'}
						errorMessage={errors['name']}
						onBlur={() =>
							setErrors({
								...errors,
								name: fieldValidators.blankValidator(`Plugin name`, name),
							})
						}
					/>
				</div>
				<div className={styles.fieldWrapper}>
					<h2>Plugin Display Name</h2>
					<Input
						value={displayName}
						setValue={setDisplayName}
						placeholder={'Payment Processor'}
						errorMessage={errors['displayName']}
						onBlur={() =>
							setErrors({
								...errors,
								displayName: fieldValidators.blankValidator(`Plugin display name`, displayName),
							})
						}
					/>
				</div>
				{editingPluginId && (
					<div className={styles.fieldWrapper}>
						<h2>Plugin Notes</h2>
						<Textarea
							value={notes}
							setValue={setNotes}
							placeholder={'Notes'}
							errorMessage={errors['notes']}
							resizable={false}
							height={150}
							onBlur={() =>
								setErrors({
									...errors,
									notes: fieldValidators.blankValidator(`Plugin notes`, notes),
								})
							}
						/>
					</div>
				)}

				<div className={styles.fieldWrapper}>
					<h2>Plugin Image</h2>
					<ImagePicker
						setImages={images => setImage(images[0])}
						images={[image]}
						style={'secondary'}
					/>
					{errors['image'] && <FieldErrorMessage errorMessage={errors['image']} />}
				</div>

				<div className={styles.fieldWrapper}>
					<h2>Plugin Description</h2>
					<CustomMDEditor
						value={description}
						setValue={setDescription}
						placeholder={
							'This is a payment processor for internet companies. You can accept debit/credit cards in minutes with instant onboarding.'
						}
						errorMessage={errors['description']}
						onBlur={() =>
							setErrors({
								...errors,
								description: fieldValidators.blankValidator(`Plugin description`, description),
							})
						}
					/>
				</div>

				<div className={styles.fieldWrapper}>
					<h2>
						Supported Payments <OptionalBadge />
					</h2>
					<CustomMultiSelect
						value={paymentMethods}
						setValue={value => setPaymentMethods(value as PaymentMethod[])}
						options={Object.keys(PaymentMethod).map(key => ({
							value: key,
							label: key.replaceAll('_', ' '),
						}))}
					/>
				</div>

				<div className={styles.fieldWrapper}>
					<h2>Plugin Visibility</h2>
					<div className={styles.oneColumn}>
						<RadioWrapper
							value={CustomGatewayVisibility.PUBLIC}
							id={CustomGatewayVisibility.PUBLIC}
							checked={visibility === CustomGatewayVisibility.PUBLIC}
							setValue={() => setVisibility(CustomGatewayVisibility.PUBLIC)}
							title={'PUBLIC'}
							description={'Available to any customer via our plugins page after approval.'}
						/>
						<RadioWrapper
							value={CustomGatewayVisibility.PRIVATE}
							id={CustomGatewayVisibility.PRIVATE}
							checked={visibility === CustomGatewayVisibility.PRIVATE}
							setValue={() => setVisibility(CustomGatewayVisibility.PRIVATE)}
							title={'PRIVATE'}
							description={'Will not require platform approval and won’t count towards analytics. '}
						/>
					</div>
				</div>

				<div className={styles.fieldWrapper}>
					<h2>Manifest JSON</h2>
					<JsonFileInput file={jsonFile} setFile={setJsonFile} />
					{errors['json'] && <FieldErrorMessage errorMessage={errors['json']} />}
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>

					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'download',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={onSubmitClick}
						isLoading={isCreatePluginLoading || isUpdatePluginLoading}
					>
						{editingPluginId ? 'Save' : 'Create'} Plugin
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default CreateCustomGatewayModal
