import React, { useEffect, useState } from 'react'
import styles from './CustomersPage.module.scss'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageHeader from '../../components/PageHeader/PageHeader'
import SearchInput, { SearchInputStyles } from '../../components/UI/SearchInput/SearchInput'
import CustomersTable from '../../components/Tables/CustomersTable'
import Pagination from '../../components/UI/Pagination/Pagination'
import { useAPI } from '../../hooks/useAPI'
import { CustomersService } from '../../API/CustomersService'
import { useAppContext } from '../../hooks/useAppContext'
import Spinner from '../../components/UI/Spinner/Spinner'
import { Customer } from '../../types/Customer/Customer'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import noCustomersImage from '../../assets/images/noCustomers.png'
import PageTableHeader from '../../components/PageTableHeader/PageTableHeader'
import { useDebounce } from '../../hooks/useDebounce'
import { usePageTitle } from '../../hooks/usePageTitle'
import { DatePeriod } from '../../types/DatePeriod/DatePeriod'
import { getDatePeriod } from '../../helpers/getDatePeriod'
import MiniStat from '../../components/MiniStat/MiniStat'
import DateController from '../../components/UI/DateController/DateController'
import { GetCustomersStats } from '../../types/GetCustomersStats/GetCustomersStats'
import getSymbolFromCurrency from 'currency-symbol-map'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

const CustomersPage = () => {
	const [totalItems, setTotalItems] = useState(23)
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [searchValue, setSearchValue] = useState('')
	const [dateRanges, setDateRanges] = useState<{
		fromDate: Date | null
		toDate: Date | null
	}>({
		toDate: new Date(),
		fromDate: getDatePeriod.getLastDayDate(),
	})
	const [selectDate, setSelectDate] = useState<DatePeriod>(DatePeriod.lastDay)
	const { shop } = useAppContext()
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isCustomersViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.CUSTOMERS_VIEW) || isAdmin
	usePageTitle('Customers')

	const [isGetCustomersLoading, customers, getCustomers] = useAPI<Customer[]>(
		() => CustomersService.getAll(shop?.id || '', searchValue, currentPage + 1, pageSize),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	const [isGetStatsLoading, stats, getStats] = useAPI<GetCustomersStats>(
		() => CustomersService.getStats(shop?.id || '', dateRanges.fromDate, dateRanges.toDate),
		false
	)

	const isFirstLoading = useFirstLoading(isGetCustomersLoading)
	useDebounce(searchValue, getCustomers, 400)

	useEffect(() => {
		if (shop) {
			getCustomers()
			getStats()
		}
	}, [shop, pageSize, currentPage])

	useEffect(() => {
		if (shop && isCustomersViewAvailable) {
			getStats()
		}
	}, [dateRanges])

	if (isFirstLoading) {
		return <PageLoading />
	}

	if (!isCustomersViewAvailable)
		return (
			<NoDataComponent
				imageSrc={noCustomersImage}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)

	if (!customers?.length && !searchValue) {
		return (
			<NoDataComponent
				imageSrc={noCustomersImage}
				title={'No Customers'}
				description={
					'When a customer creates an account or completes an order, they will appear here.'
				}
			/>
		)
	}

	return (
		<PageContainer>
			<PageWrapper>
				<div className={styles.customersPage}>
					<PageHeader title={'Customers'}>
						<div className={styles.headerEnd}>
							<SearchInput
								value={searchValue}
								setValue={setSearchValue}
								style={SearchInputStyles.secondary}
								placeholder={'Search for a customer'}
							/>
							{/*<Button*/}
							{/*	style={ButtonStyles.SECONDARY}*/}
							{/*	icon={{*/}
							{/*		id: 'send',*/}
							{/*		width: 12,*/}
							{/*		height: 14,*/}
							{/*		align: ButtonsIconAlign.LEFT,*/}
							{/*	}}*/}
							{/*>*/}
							{/*	Message {totalItems}*/}
							{/*</Button>*/}
							{/*<Button*/}
							{/*	style={ButtonStyles.SECONDARY}*/}
							{/*	icon={{*/}
							{/*		id: 'plus',*/}
							{/*		width: 13,*/}
							{/*		height: 13,*/}
							{/*		align: ButtonsIconAlign.LEFT,*/}
							{/*	}}*/}
							{/*>*/}
							{/*	New Customer*/}
							{/*</Button>*/}
						</div>
					</PageHeader>

					<DateController
						dateRanges={dateRanges}
						setDateRanges={setDateRanges}
						selectDate={selectDate}
						setSelectDate={setSelectDate}
						isLoading={isGetStatsLoading}
					/>

					{isGetStatsLoading ? (
						<Spinner />
					) : (
						stats && (
							<div className={styles.miniStats}>
								<MiniStat title={'Customers'} value={stats?.customersCounter} />
								<MiniStat title={'Paid Customers'} value={stats?.paidCustomersCounter} />
								<MiniStat
									title={'Avg. Spend per Customer'}
									value={`${getSymbolFromCurrency(shop?.currency || 'USD')}${
										stats.totalSpentDefaultCurrency !== 0
											? stats.totalSpentDefaultCurrency / stats.paidCustomersCounter
											: 0
									}`}
								/>
								<MiniStat
									title={'Avg. Orders per Customer'}
									value={Math.round(
										stats.totalOrders !== 0 ? stats.totalOrders / stats.paidCustomersCounter : 0
									)}
								/>
							</div>
						)
					)}

					<PageTableHeader
						listItemNamePlural={'customers'}
						totalItems={totalItems}
						pageSize={pageSize}
						setPageSize={setPageSize}
						setCurrentPage={setCurrentPage}
					/>

					{isGetCustomersLoading ? (
						<Spinner />
					) : customers ? (
						<div className={styles.tableWrapper}>
							<CustomersTable customers={customers} />
						</div>
					) : null}

					<div className={styles.paginationWrapper}>
						<Pagination
							withoutPageSizeSelector={true}
							totalItems={totalItems}
							pageSize={pageSize}
							setPageSize={setPageSize}
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
						/>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default CustomersPage
