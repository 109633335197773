import React, { FC, ReactNode, useEffect, useRef } from 'react'
import styles from './RatingBadge.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

interface RatingBadgeProps {
	rating: number
}

const RatingBadge: FC<RatingBadgeProps> = ({ rating }) => {
	const handleStars = () => {
		const stars: ReactNode[] = []

		for (let i = 0; i < 5; i++) {
			if (i < rating) {
				stars.push(<SpriteIcon iconId={'star'} width={16} height={16} />)
			} else {
				stars.push(<SpriteIcon iconId={'starStroke'} width={16} height={16} />)
			}
		}

		return stars
	}

	useEffect(() => {
		if (rating) {
			handleStars()
		}
	}, [rating])

	return (
		<div
			className={`${styles.ratingBadge} ${
				rating > 3 ? styles.green : rating === 3 ? styles.brown : styles.red
			}`}
		>
			{handleStars()}
		</div>
	)
}

export default RatingBadge
