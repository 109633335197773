import React, { useState } from 'react'
import { ActionMenuOption } from '../../types/ActionMenuOption/ActionMenuOption'
import ActionMenu from '../UI/ActionMenu/ActionMenu'

const FeedbackActionMenu = () => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)

	const actionMenuButtons: ActionMenuOption[] = [
		{
			title: 'This is useful',
			icon: {
				id: 'like',
				width: 15,
				height: 13,
			},
			onClick: () => {},
		},
		{
			title: 'This is not useful',
			icon: {
				id: 'dislike',
				width: 15,
				height: 13,
			},
			onClick: () => {},
		},
		{
			title: 'Make a suggestion',
			icon: {
				id: 'fire',
				width: 13,
				height: 16,
			},
			onClick: () => window.open('https://suggestions.billgang.com/', '_blank', 'noopener'),
		},
	]

	return (
		<ActionMenu
			actionButtons={actionMenuButtons || []}
			isActive={isActionMenuActive}
			setIsActive={setIsActionMenuActive}
		/>
	)
}

export default FeedbackActionMenu
