import React, { useEffect, useState } from 'react'
import styles from './TicketsInboxPage.module.scss'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import Button, { ButtonStyles } from '../../components/UI/Button/Button'
import CustomerTicket from '../../components/CustomerTicket/CustomerTicket'
import TicketMessage from '../../components/TicketMessage/TicketMessage'
import MessageInput from '../../components/UI/MessageInput/MessageInput'
import { useAPI } from '../../hooks/useAPI'
import { closeModal } from '../../helpers/closeModal'
import { useAppContext } from '../../hooks/useAppContext'
import { TicketsService } from '../../API/TicketsService'
import { Ticket } from '../../types/Ticket/Ticket'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import noTickets from '../../assets/images/noTickets.png'
import { TicketDetailed } from '../../types/TicketDetailed/TicketDetailed'
import Spinner from '../../components/UI/Spinner/Spinner'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import Modal from '../../components/Modals/Modal'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import CustomSelect, { SelectStyles } from '../../components/UI/CustomSelect/CustomSelect'
import { SpriteIcon } from '../../components/UI/SpriteIcon/SpriteIcon'
import { usePageTitle } from '../../hooks/usePageTitle'
import { TicketStatus } from '../../types/TicketStatus/TicketStatus'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

enum SortingValue {
	NEWEST = 'NEWEST',
	OLDEST = 'OLDEST',
}

const TicketsInboxPage = () => {
	const [searchValue, setSearchValue] = useState('')
	const [totalItems, setTotalItems] = useState(23)
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [closingTicketId, setClosingTicketId] = useState<number | null>(null)
	const [openedTicket, setOpenedTicket] = useState<Ticket | null>(null)
	const [replyMessage, setReplyMessage] = useState('')
	const [isCloseTicketModalActive, setIsCloseTicketModalActive] = useState(false)
	const [isCloseTicketModalVisible, setIsCloseTicketModalVisible] = useState(false)
	const [isTicketOpened, setIsTicketOpened] = useState(false)
	const [statusFilter, setStatusFilter] = useState<TicketStatus | 'null'>(TicketStatus.NEW)
	const [sortingValue, setSortingValue] = useState<SortingValue>(SortingValue.NEWEST)
	const [sortedTickets, setSortedTickets] = useState<Ticket[]>([])
	const { shop } = useAppContext()
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isTicketsViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.TICKETS_VIEW) || isAdmin
	const isTicketsModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.TICKETS_MODIFY) || isAdmin
	usePageTitle(`Tickets Inbox`)

	const [isGetTicketsLoading, tickets, getTickets] = useAPI<Ticket[]>(
		() => TicketsService.getAll(shop?.id || '', statusFilter === 'null' ? null : statusFilter),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
				setOpenedTicket(null)
			},
		}
	)

	const isFirstLoading = useFirstLoading(isGetTicketsLoading)

	const [isGetDetailedTicketLoading, detailedTicket, getDetailedTicket] = useAPI<TicketDetailed>(
		() => TicketsService.getOne(shop?.id || '', openedTicket?.id || null),
		false
	)

	const [isAnswerTicketLoading, , answerTicket] = useAPI(
		() =>
			TicketsService.answerTicket(shop?.id || '', openedTicket?.id || null, {
				message: replyMessage,
			}),
		false,
		undefined,
		{
			onSuccess: () => {
				getDetailedTicket()
			},
		}
	)

	const [isCloseTicketLoading, , closeTicket] = useAPI(
		() => TicketsService.closeTicket(shop?.id || '', openedTicket?.id || null),
		false,
		'Ticket successfully closed',
		{
			onSuccess: () => {
				getTickets()
				closeModal(setIsCloseTicketModalActive, setIsCloseTicketModalVisible)
			},
		}
	)

	const replyMessageHandler = () => {
		answerTicket()
		setReplyMessage('')
	}

	const onCustomerTicketClickHandler = (ticket: Ticket) => {
		setOpenedTicket(ticket)
		setIsTicketOpened(true)
	}

	const onBackButtonClickHandler = () => {
		setOpenedTicket(null)
		setIsTicketOpened(false)
	}

	const sortTickets = () => {
		return (
			tickets?.sort((a, b) => {
				if (sortingValue === SortingValue.OLDEST) {
					return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
				}
				return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
			}) || []
		)
	}

	useEffect(() => {
		if (shop && isTicketsViewAvailable) {
			getTickets()
		}
	}, [shop, statusFilter])

	useEffect(() => {
		if (openedTicket) {
			getDetailedTicket()
		}
	}, [openedTicket])

	useEffect(() => {
		setSortedTickets(sortTickets())
	}, [sortingValue, tickets])

	if (isFirstLoading) {
		return <PageLoading />
	}

	if (!isTicketsViewAvailable)
		return (
			<NoDataComponent
				imageSrc={noTickets}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)

	if (!tickets?.length && !searchValue && !isGetTicketsLoading && statusFilter === 'null') {
		return (
			<NoDataComponent
				imageSrc={noTickets}
				title={'No Tickets'}
				description={'Once customers start opening tickets, you’ll see active tickets here.'}
			/>
		)
	}

	return (
		<>
			{isCloseTicketModalActive && (
				<Modal
					closeModal={() => closeModal(setIsCloseTicketModalActive, setIsCloseTicketModalVisible)}
					visible={isCloseTicketModalVisible}
					setVisible={setIsCloseTicketModalVisible}
				>
					<ConfirmModal
						title={'Close Ticket'}
						description={
							'Are you sure you want to close this ticket? You can always re-open this ticket in the future.'
						}
						onConfirm={closeTicket}
						isButtonLoading={isCloseTicketLoading}
						onClose={() => closeModal(setIsCloseTicketModalActive, setIsCloseTicketModalVisible)}
						confirmButtonText={'Close Ticket'}
					/>
				</Modal>
			)}
			{/*{isIssueReplacementModalActive && (*/}
			{/*	<Modal*/}
			{/*		closeModal={() =>*/}
			{/*			closeModal(setIsIssueReplacementModalActive, setIsIssueReplacementModalVisible)*/}
			{/*		}*/}
			{/*		visible={isIssueReplacementModalVisible}*/}
			{/*		setVisible={setIsIssueReplacementModalVisible}*/}
			{/*	>*/}
			{/*		<IssueReplacementModal*/}
			{/*			shopId={shop?.id || ''}*/}
			{/*			getOrder={() => {}}*/}
			{/*			orderId={detailedTicket.}*/}
			{/*			partOrderQuantity={Number(currentDeliveredProductQuantity)}*/}
			{/*			partOrderId={currentDeliveredProductId}*/}
			{/*			onClose={() =>*/}
			{/*				closeModal(setIsIssueReplacementModalActive, setIsIssueReplacementModalVisible)*/}
			{/*			}*/}
			{/*		/>*/}
			{/*	</Modal>*/}
			{/*)}*/}
			<PageWrapper>
				<div className={styles.ticketsPage}>
					<div className={styles.sidebar}>
						<header className={styles.sidebarHeader}>
							<h1 className={styles.h1}>Inbox</h1>
						</header>

						<div className={styles.filters}>
							<div className={styles.selectWrapper}>
								<CustomSelect
									style={SelectStyles.light}
									size={'medium'}
									value={statusFilter as string}
									setValue={value => setStatusFilter(value as TicketStatus)}
									options={[
										{ value: 'null', label: 'All' },
										{ value: TicketStatus.NEW, label: 'New' },
										{ value: TicketStatus.CLOSED, label: 'Closed' },
										{ value: TicketStatus.ANSWERED, label: 'Answered' },
										{ value: TicketStatus.NOT_ANSWERED, label: 'Not Answered' },
									]}
								/>
							</div>
							<div className={styles.selectWrapper}>
								<CustomSelect
									style={SelectStyles.light}
									size={'medium'}
									value={sortingValue as string}
									setValue={value => setSortingValue(value as SortingValue)}
									options={[
										{ value: SortingValue.NEWEST, label: 'Newest' },
										{ value: SortingValue.OLDEST, label: 'Oldest' },
									]}
								/>
							</div>
						</div>

						<div className={`${styles.sidebarBody} ${isTicketOpened && styles.opened}`}>
							<div className={styles.tickets}>
								{isGetTicketsLoading && <Spinner />}
								{sortedTickets.map(ticket => (
									<CustomerTicket
										isActive={ticket.id === openedTicket?.id}
										ticket={ticket}
										onClick={() => onCustomerTicketClickHandler(ticket)}
										key={ticket.id}
									/>
								))}
							</div>
						</div>
					</div>
					<div>
						{isTicketOpened && openedTicket && detailedTicket ? (
							<div className={`${styles.openTicket} ${isTicketOpened && styles.opened}`}>
								<header className={styles.openTicketHeader}>
									<div className={styles.openTicketHeaderDetails}>
										<div className={styles.backButton} onClick={onBackButtonClickHandler}>
											<SpriteIcon iconId={'arrow'} width={14} height={12} />
										</div>
										<div>
											<p>{detailedTicket.customer.email}</p>
											<p className={styles.customerId}>{detailedTicket.customer.id}</p>
										</div>
									</div>
									{isTicketsModifyAvailable && (
										<Button
											style={ButtonStyles.LIGHT}
											onClick={() => setIsCloseTicketModalActive(true)}
										>
											Close
										</Button>
									)}
								</header>
								<header className={styles.openTicketSecondHeader}>
									<div className={styles.addressWrapper}>
										<p>
											IP:{' '}
											{(detailedTicket.customer.ips[0] && detailedTicket.customer.ips[0].ip) || '-'}
										</p>
										<p>
											Country:{' '}
											{(detailedTicket.customer.ips[0] && detailedTicket.customer.ips[0].country) ||
												'-'}
										</p>
									</div>
									{/*<button>Issue Replacement</button>*/}
								</header>
								<div className={styles.openTicketBody}>
									<div className={styles.messagesWrapper}>
										{detailedTicket.messages.map(message => (
											<TicketMessage
												message={message}
												customerEmail={detailedTicket?.customer.email}
												shopName={shop?.name || ''}
												key={message.id}
											/>
										))}
									</div>
									{isTicketsModifyAvailable && (
										<div className={styles.messageFormWrapper}>
											<MessageInput
												value={replyMessage}
												setValue={setReplyMessage}
												placeholder={'Type a message...'}
												onClick={replyMessageHandler}
											/>
										</div>
									)}
								</div>
							</div>
						) : (
							<div className={styles.noTicketsSelected}>
								<NoDataComponent
									imageSrc={noTickets}
									title={'No tickets selected'}
									description={' '}
								/>
							</div>
						)}

						{isGetDetailedTicketLoading && (
							<div className={styles.spinner}>
								<Spinner />
							</div>
						)}
					</div>
				</div>
			</PageWrapper>
		</>
	)
}

export default TicketsInboxPage
