import React from 'react'
import styles from './EmbedsPage.module.scss'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageHeader from '../../components/PageHeader/PageHeader'
import { usePageTitle } from '../../hooks/usePageTitle'
import EmbedsBanner from '../../components/EmbedsBanner/EmbedsBanner'
import Section from '../../components/UI/Section/Section'
import CodeWrapper from '../../components/UI/CodeWrapper/CodeWrapper'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import { useAppContext } from '../../hooks/useAppContext'

const EmbedsPage = () => {
	usePageTitle('Embeds')

	return (
		<PageWrapper>
			<PageContainer>
				<div className={styles.embedsPage}>
					<PageHeader title={'Embeds'} />

					<EmbedsBanner />

					<div className={styles.embedsPageBody}>
						<Section title={'Step One'}>
							<p className={styles.defaultText}>
								{
									"You’ll first have to add this snippet of code to the <head> section of your website and we'll take care of the rest."
								}
							</p>
							<div className={styles.mtMediumLarge}>
								<CodeWrapper
									title={'Embed Javascript'}
									code={'<script src="https://embed.billgang.store/embed.js"></script>'}
									language={'html'}
								/>
							</div>
						</Section>
						<Section title={'Step Two'}>
							<p className={styles.defaultText}>
								Add this code to your button and once the customer taps the button, the embed will
								pop up for them to continue with the payment flow.
							</p>
							<div className={styles.mtMediumLarge}>
								<CodeWrapper
									title={'Product Button'}
									code={
										'<button\n' +
										'  data-billgang-product-path="SEO_PATH"\n' +
										'  data-billgang-domain="your_billgang_domain.billgang.store"\n' +
										'>\n' +
										'  Purchase\n' +
										'</button>'
									}
									language={'htmlbars'}
								/>
							</div>

							<p className={`${styles.defaultText} ${styles.mtMediumLarge}`}>
								Replace the <span className={styles.code}>SEO_PATH</span> with your product path,
								this can be found in the SEO section of your product edit page (or its name).
							</p>
							<p className={`${styles.defaultText} ${styles.mtMediumLarge}`}>
								Replace the <span className={styles.code}>DOMAIN.BILLGANG.STORE</span> with your
								Billgang-powered domain (e.g demo.billgang.store), not your custom domain.
							</p>
						</Section>

						<div className={styles.helpBanner}>
							<div>
								<p className={styles.defaultTitle}>Anything we can do to help?</p>
								<p className={`${styles.defaultText} ${styles.mt10}`}>
									If you’re having trouble setting up product embeds and you’re on a paid
									subscription plan, feel free to get in touch with our community developers or a
									member on our team.
								</p>
							</div>

							<Button
								style={ButtonStyles.BORDERED}
								icon={{
									id: 'blankWindow',
									width: 13,
									height: 13,
									align: ButtonsIconAlign.LEFT,
								}}
								disableShadow={true}
								onClick={() => window.open('https://discord.gg/5MSmTbbvxJ', 'blank', 'noopener')}
							>
								Join our Discord
							</Button>
						</div>
					</div>
				</div>
			</PageContainer>
		</PageWrapper>
	)
}

export default EmbedsPage
