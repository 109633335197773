import React, { FC, useEffect, useState } from 'react'
import SearchInput, { SearchInputStyles } from '../UI/SearchInput/SearchInput'
import styles from './ChooseAndSortListings.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { Listing } from '../../types/Listing/Listing'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import Modal from '../Modals/Modal'
import { closeModal } from '../../helpers/closeModal'
import AddListingsModal from '../Modals/AddListingsModal/AddListingsModal'
import ConfirmModal from '../Modals/ConfirmModal/ConfirmModal'
import { ButtonsIconAlign } from '../UI/Button/Button'
import { useAPI } from '../../hooks/useAPI'
import { ListingsService } from '../../API/ListingsService'
import { useAppContext } from '../../hooks/useAppContext'

interface ChooseAndSortListingsProps {
	listings: Listing[] | undefined
	handledListings: Listing[]
	setHandledListings: (value: Listing[]) => void
	hideSearchInput?: boolean
}

const ChooseAndSortListings: FC<ChooseAndSortListingsProps> = ({
	setHandledListings,
	handledListings,
	listings,
	hideSearchInput,
}) => {
	const [animationParent] = useAutoAnimate()
	const [currentListing, setCurrentListing] = useState<Listing | null>(null)
	const [searchValue, setSearchValue] = useState('')
	const [deletingListing, setDeletingListing] = useState<Listing | null>(null)
	const [isAddListingsModalActive, setIsAddListingsModalActive] = useState(false)
	const [isAddListingsModalVisible, setIsAddListingsModalVisible] = useState(false)
	const [isDeleteListingModalActive, setIsDeleteModalActive] = useState(false)
	const [isDeleteListingModalVisible, setIsDeleteModalVisible] = useState(false)
	const { shop } = useAppContext()

	const dragStartHandler = (event: React.DragEvent<HTMLDivElement>, listing: Listing) => {
		setCurrentListing(listing)
	}

	const dropHandler = (event: React.DragEvent<HTMLDivElement>, listing: Listing) => {
		event.preventDefault()
		setHandledListings(
			handledListings.map(item => {
				if (item.id === listing.id) {
					return { ...item, position: currentListing?.position || 0 }
				}
				if (currentListing && item.id === currentListing.id) {
					return { ...item, position: listing.position }
				}
				return item
			})
		)
	}

	const moveUpListing = (currentListing: Listing) => {
		if (currentListing.position === 0) return

		const nextListing = handledListings.find(
			listing => listing.position === currentListing.position - 1
		)

		if (nextListing) {
			setHandledListings(
				handledListings.map(listing => {
					return listing.id === nextListing.id
						? {
								...listing,
								position: currentListing.position,
						  }
						: listing.id === currentListing.id
						? {
								...listing,
								position: currentListing.position - 1,
						  }
						: listing
				})
			)
		}
	}

	const moveDownListing = (currentListing: Listing) => {
		const prevListing = handledListings.find(
			listing => listing.position === currentListing.position + 1
		)

		if (prevListing) {
			setHandledListings(
				handledListings.map(listing => {
					return listing.id === prevListing.id
						? {
								...listing,
								position: currentListing.position,
						  }
						: listing.id === currentListing.id
						? {
								...listing,
								position: currentListing.position + 1,
						  }
						: listing
				})
			)
		}
	}

	const closeDeleteModal = () => {
		closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)
	}

	return (
		<div className={styles.chooseAndSortListings}>
			{isAddListingsModalActive && (
				<Modal
					closeModal={() => closeModal(setIsAddListingsModalActive, setIsAddListingsModalVisible)}
					visible={isAddListingsModalVisible}
					setVisible={setIsAddListingsModalVisible}
				>
					<AddListingsModal
						handledListings={handledListings}
						setHandledListings={setHandledListings}
						listings={listings}
						searchedValue={searchValue}
						onClose={() => closeModal(setIsAddListingsModalActive, setIsAddListingsModalVisible)}
					/>
				</Modal>
			)}

			{isDeleteListingModalActive && (
				<Modal
					closeModal={closeDeleteModal}
					visible={isDeleteListingModalVisible}
					setVisible={setIsDeleteModalVisible}
				>
					<ConfirmModal
						title={'Delete Product'}
						description={`You're about to delete ${deletingListing?.name}. Are you sure you want to delete this product? This cannot be undone.`}
						onConfirm={() => {
							setHandledListings(
								handledListings.filter(listing => listing.id !== deletingListing?.id)
							)
							closeDeleteModal()
						}}
						confirmButtonText={'Delete Product'}
						buttonIcon={{
							id: 'trashBin',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						onClose={closeDeleteModal}
					/>
				</Modal>
			)}

			<div>
				{!hideSearchInput && (
					<SearchInput
						style={SearchInputStyles.bordered}
						value={searchValue}
						setValue={value => {
							setSearchValue(value)
							setIsAddListingsModalActive(true)
						}}
						placeholder={'Search for products...'}
					/>
				)}
			</div>

			{!!handledListings.length && (
				<div className={styles.products} ref={animationParent}>
					{handledListings
						?.sort((a, b) => a.position - b.position)
						.map(listing => (
							<div
								className={styles.product}
								key={listing.id}
								draggable={true}
								onDragStart={event => dragStartHandler(event, listing)}
								onDragLeave={event => {
									event.preventDefault()
									event.currentTarget.classList.remove(styles.dragOver)
								}}
								onDragEnd={event => {
									event.preventDefault()
									event.currentTarget.classList.remove(styles.dragOver)
								}}
								onDragOver={event => {
									event.preventDefault()
									event.currentTarget.classList.add(styles.dragOver)
								}}
								onDrop={event => {
									dropHandler(event, listing)
									event.currentTarget.classList.remove(styles.dragOver)
								}}
							>
								<div className={styles.productHead}>
									<SpriteIcon iconId={'dragAndDrop'} width={6} height={10} />

									<span>{listing.position}.</span>
									<p>{listing.name}</p>
									<p>
										(<span>{listing.id}</span>)
									</p>
								</div>
								<div className={styles.productTail}>
									<div className={styles.iconWrapper} onClick={() => moveUpListing(listing)}>
										<SpriteIcon
											className={styles.arrowUp}
											iconId={'arrowDown'}
											width={11}
											height={6}
										/>
									</div>
									<div className={styles.iconWrapper} onClick={() => moveDownListing(listing)}>
										<SpriteIcon iconId={'arrowDown'} width={11} height={6} />
									</div>

									<div
										className={styles.iconWrapper}
										onClick={() => {
											setIsDeleteModalActive(true)
											setDeletingListing(listing)
										}}
									>
										<SpriteIcon iconId={'trashBin'} width={11} height={12} />
									</div>
								</div>
							</div>
						))}
				</div>
			)}
		</div>
	)
}

export default ChooseAndSortListings
