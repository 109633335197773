import { privateAPI } from './API'
import { CreateDiscordClusterBot } from '../types/CreateDiscordClusterBot/CreateDiscordClusterBot'
import { CreateDiscordCluster } from '../types/CreateDiscordCluster/CreateDiscordCluster'

export const DiscordBotsService = {
	get: async (shopId: string) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/discord-cluster`)
	},
	post: async (shopId: string, data: CreateDiscordCluster) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/discord-cluster`, data)
	},
	put: async (shopId: string, data: CreateDiscordCluster) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/discord-cluster`, data)
	},
	delete: async (shopId: string) => {
		return await privateAPI.delete(`/v1/dash/shops/${shopId}/discord-cluster`)
	},
}
