import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import styles from './WebhookLogsPage.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import SearchInput, { SearchInputStyles } from '../../components/UI/SearchInput/SearchInput'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import Pagination from '../../components/UI/Pagination/Pagination'
import Modal from '../../components/Modals/Modal'
import { closeModal } from '../../helpers/closeModal'
import CreateWebhookEndpointModal from '../../components/Modals/CreateWebhookEndpointModal/CreateWebhookEndpointModal'
import WebhookLogsTable from '../../components/Tables/WebhookLogsTable'
import { useAPI } from '../../hooks/useAPI'
import { Category } from '../../types/Category/Category'
import { CategoriesService } from '../../API/CategoriesService'
import { WebhooksService } from '../../API/WebhooksService'
import { useAppContext } from '../../hooks/useAppContext'
import { WebhookLog } from '../../types/WebhookLog/WebhookLog'
import Spinner from '../../components/UI/Spinner/Spinner'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import PageTableHeader from '../../components/PageTableHeader/PageTableHeader'
import { useDebounce } from '../../hooks/useDebounce'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import noWebhookLogs from '../../assets/images/noWebhookLogs.png'
import { usePageTitle } from '../../hooks/usePageTitle'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

const WebhookLogsPage = () => {
	const [searchValue, setSearchValue] = useState('')
	const [totalItems, setTotalItems] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [isCreateModalActive, setIsCreateModalActive] = useState(false)
	const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
	const [previousLastLogIds, setPreviousLastLogIds] = useState<(string | null)[]>([])
	const [currentLastLogId, setCurrentLastLogId] = useState<string | null>(null)
	const { shop } = useAppContext()
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isDevelopersViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.DEVELOPERS_VIEW) || isAdmin
	usePageTitle(`Webhook Logs`)

	const [isGetLogsLoading, logs, getLogs] = useAPI<WebhookLog[]>(
		() => WebhooksService.getAllLogs(shop?.id || '', currentLastLogId, pageSize),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	const isFirstLoading = useFirstLoading(isGetLogsLoading)

	const onPrevClickHandler = () => {
		setCurrentLastLogId(previousLastLogIds[previousLastLogIds.length - 1])
		setPreviousLastLogIds(
			previousLastLogIds.filter(item => item !== previousLastLogIds[previousLastLogIds.length - 1])
		)
	}

	const onNextClickHandler = () => {
		if (logs && logs[pageSize - 1]?.externalMessageId) {
			setPreviousLastLogIds([...previousLastLogIds, currentLastLogId])
			setCurrentLastLogId(logs[pageSize - 1].externalMessageId)
		}
	}

	useEffect(() => {
		if (shop && isDevelopersViewAvailable) {
			getLogs()
		}
	}, [shop, currentLastLogId, pageSize])

	useEffect(() => {
		setPreviousLastLogIds([])
		setCurrentLastLogId(null)
	}, [pageSize])

	if (isFirstLoading) {
		return <PageLoading />
	}

	if (!isDevelopersViewAvailable)
		return (
			<NoDataComponent
				imageSrc={noWebhookLogs}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)

	if (!logs?.length && !searchValue) {
		return (
			<NoDataComponent
				imageSrc={noWebhookLogs}
				title={'No Logs'}
				description={'Once you start receiving webhook payloads, they will appear here.'}
			/>
		)
	}

	return (
		<PageContainer>
			{isCreateModalActive && (
				<Modal
					closeModal={() => closeModal(setIsCreateModalActive, setIsCreateModalVisible)}
					visible={isCreateModalVisible}
					setVisible={setIsCreateModalVisible}
				>
					<CreateWebhookEndpointModal simulate={true} onClose={() => {}} getWebhooks={() => {}} />
				</Modal>
			)}
			<PageWrapper>
				<div className={styles.webhooksPage}>
					<PageHeader title={'Webhook Logs'}>
						<div className={styles.headerEnd}>
							<SearchInput
								style={SearchInputStyles.secondary}
								value={searchValue}
								setValue={setSearchValue}
								placeholder={'Search for webhook logs by URL or event'}
							/>
							{/*<Button*/}
							{/*	style={ButtonStyles.SECONDARY}*/}
							{/*	icon={{*/}
							{/*		id: 'plus',*/}
							{/*		height: 13,*/}
							{/*		width: 13,*/}
							{/*		align: ButtonsIconAlign.LEFT,*/}
							{/*	}}*/}
							{/*	onClick={() => setIsCreateModalActive(true)}*/}
							{/*>*/}
							{/*	Simulate Webhook*/}
							{/*</Button>*/}
						</div>
					</PageHeader>

					<PageTableHeader
						listItemNamePlural={'webhook logs'}
						totalItems={totalItems}
						pageSize={pageSize}
						setPageSize={setPageSize}
						setCurrentPage={() => {}}
					/>

					{isGetLogsLoading ? (
						<Spinner />
					) : (
						logs && (
							<div className={styles.tableWrapper}>
								<WebhookLogsTable webhookLogs={logs} />
							</div>
						)
					)}

					<div className={styles.paginationWrapper}>
						<Pagination
							withoutPageSizeSelector={true}
							totalItems={totalItems}
							pageSize={pageSize}
							setPageSize={setPageSize}
							setCurrentPage={() => {}}
							currentPage={0}
							withoutPages={true}
							onPrevClick={onPrevClickHandler}
							onNextClick={onNextClickHandler}
						/>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default WebhookLogsPage
