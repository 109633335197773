import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import CustomSelect from '../../UI/CustomSelect/CustomSelect'
import Textarea from '../../UI/FormComponents/Textarea'
import { fieldValidators } from '../../../helpers/fieldValidators'
import { useAPI } from '../../../hooks/useAPI'
import { ReviewsService } from '../../../API/ReviewsService'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { useAppContext } from '../../../hooks/useAppContext'
import { AppealReviewReason } from '../../../types/AppealReviewReason/AppealReviewReason'
import InputWithText from '../../UI/FormComponents/InputWithText'
import { DiscordSocialConnectForCustomers } from '../../../types/DiscordSocialConnectForCustomers/DiscordSocialConnectForCustomers'

interface ConnectedDiscordAccountsModalProps extends BaseModalProps {
	discordAccounts: DiscordSocialConnectForCustomers[]
	customerEmail: string
}

const ConnectedDiscordAccountsModal: FC<ConnectedDiscordAccountsModalProps> = ({
	onClose,
	discordAccounts,
	customerEmail,
}) => {
	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Connected Discord Accounts</h1>
			</header>

			<div className={styles.body}>
				<p className={styles.description}>
					{customerEmail} has connected {discordAccounts.length} Discord account
					{discordAccounts.length > 1 && 's'} to their customer account.
				</p>

				{discordAccounts.map(discordAccount => (
					<div className={styles.mtMediumLarge}>
						<InputWithText
							value={discordAccount.username}
							setValue={() => {}}
							text={discordAccount.id}
						/>
					</div>
				))}
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default ConnectedDiscordAccountsModal
