import React, { FC } from 'react'
import styles from './ProductCustomField.module.scss'
import { SpriteIcon } from '../../../../components/UI/SpriteIcon/SpriteIcon'
import { CustomField } from '../../../../types/CustomField/CustomField'

interface ProductCustomFieldProps {
	customField: CustomField
	onDeleteClick: () => void
	onEditClick: () => void
}

const ProductCustomField: FC<ProductCustomFieldProps> = ({
	customField,
	onEditClick,
	onDeleteClick,
}) => {
	return (
		<div className={styles.customField}>
			<div className={styles.head}>
				<h1>{customField.name}</h1>
				{customField.required && (
					<div className={styles.required}>
						Required <SpriteIcon iconId={'checkedCircle'} width={16} height={16} />
					</div>
				)}
				{/*<div className={styles.info}>*/}
				{/*	/!*<p>Type: {customField.type}</p>*!/*/}

				{/*</div>*/}
			</div>
			<div className={styles.tail}>
				<button onClick={onEditClick}>
					<SpriteIcon iconId={'pen'} width={15} height={15} />
				</button>
				<button onClick={onDeleteClick}>
					<SpriteIcon iconId={'trashBin'} width={14} height={15} />
				</button>
			</div>
		</div>
	)
}

export default ProductCustomField
