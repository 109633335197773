import React, { FC } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { GetDeliverySerial } from '../../../types/GetDeliverySerial/GetDeliverySerial'
import PreviouslySoldSerials from '../../PreviouslySoldSerials/PreviouslySoldSerials'

interface PreviouslySoldSerialsModalProps extends BaseModalProps {
	previouslySoldSerials: GetDeliverySerial[]
	isRemoved: boolean
}

const PreviouslySoldSerialsModal: FC<PreviouslySoldSerialsModalProps> = ({
	previouslySoldSerials,
	isRemoved,
	onClose,
}) => {
	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Serials</h1>
			</header>

			<div className={styles.body}>
				<PreviouslySoldSerials
					previouslySoldSerials={previouslySoldSerials}
					isRemoved={isRemoved}
				/>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default PreviouslySoldSerialsModal
