import React, { FC } from 'react'
import modalStyles from '../Modals.module.scss'
import Input from '../../UI/FormComponents/Input'

interface OrdersRuleFormProps {
	percentage: number
	setPercentage: (value: number) => void
}

const OrdersRuleForm: FC<OrdersRuleFormProps> = ({ percentage, setPercentage }) => {
	return (
		<div>
			<div className={modalStyles.fieldWrapper}>
				<h2>PERCENTAGE</h2>
				<Input
					value={percentage}
					setValue={setPercentage}
					placeholder={'e.g: 5%'}
					type={'number'}
				/>
			</div>
		</div>
	)
}

export default OrdersRuleForm
