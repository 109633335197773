import { privateAPI } from './API'
import { NotificationSettingsUpdate } from '../types/NotificationSettingsUpdate/NotificationSettingsUpdate'

export const NotificationSettingsService = {
	get: async (shopId: string) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/settings/notifications`)
	},
	updateNotifications: async (shopId: string, data: NotificationSettingsUpdate) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/notifications`, data)
	},
	updateNotificationsDiscordWebhook: async (shopId: string, data: { url: string }) => {
		return await privateAPI.put(
			`/v1/dash/shops/${shopId}/settings/notifications/discord-webhook`,
			data
		)
	},
}
