import React, { FC, useEffect, useState } from 'react'
import styles from '../Modals.module.scss'
import Input from '../../UI/FormComponents/Input'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import CustomSelect, { SelectStyles } from '../../UI/CustomSelect/CustomSelect'
import Switch from '../../UI/Switch/Switch'
import StockTextarea from '../../UI/FormComponents/StockTextarea'
import { useAppContext } from '../../../hooks/useAppContext'
import { useAPI } from '../../../hooks/useAPI'
import { StockSystemService } from '../../../API/StockSystemService'
import { stockDelimiterHandler } from '../../../helpers/stockDelimiterHandler'
import { StockGroup } from '../../../types/StockGroup/StockGroup'
import Spinner from '../../UI/Spinner/Spinner'
import Tabs from '../../UI/Tabs/Tabs'
import Modal from '../Modal'
import ConfirmModal from '../ConfirmModal/ConfirmModal'
import { closeModal } from '../../../helpers/closeModal'
import { AddStockResponse } from '../../../types/AddStockResponse/AddStockResponse'
import PreviouslySoldSerials from '../../PreviouslySoldSerials/PreviouslySoldSerials'

enum StockTextareaTabs {
	edit = 'edit',
	add = 'add',
}

interface ChooseProductTypeModalSerialsProps {
	currentWarehouseId: string
	setCurrentWarehouseId: (value: string) => void
	setStock: (value: number | null) => void
}

const ChooseProductTypeModalSerials: FC<ChooseProductTypeModalSerialsProps> = ({
	currentWarehouseId,
	setCurrentWarehouseId,
	setStock,
}) => {
	const [warehouseName, setWarehouseName] = useState('')
	const [stockValues, setStockValues] = useState('')
	const [delimiter, setDelimiter] = useState('')
	const [customDelimiter, setCustomDelimiter] = useState('')
	const [removePreviouslySoldSerials, setRemovePreviouslySoldSerials] = useState(false)
	const [currentStockTextareaTab, setCurrentTextareaTab] = useState<StockTextareaTabs | null>(null)
	const [isClearStockModalActive, setIsClearStockModalActive] = useState(false)
	const [isClearStockModalVisible, setIsClearStockModalVisible] = useState(false)
	const { shop } = useAppContext()

	const [isCreateWarehouseLoading, , createWarehouse] = useAPI(
		() =>
			StockSystemService.createWarehouse(shop?.id || '', {
				name: warehouseName,
			}),
		false,
		'Stock group successfully created',
		{
			onSuccess: () => {
				getWarehouses()
			},
		}
	)

	const [isGetStocksLoading, stocks, getStocks] = useAPI<{
		serials: string[]
	}>(
		() => StockSystemService.getWarehouseStockRaw(shop?.id || '', Number(currentWarehouseId)),
		false
	)

	const [isAddStockLoading, addStockResponse, addStock] = useAPI<AddStockResponse>(
		() =>
			StockSystemService.addWarehouseStock(shop?.id || '', Number(currentWarehouseId), {
				serials: stockValues.split(stockDelimiterHandler(delimiter, false, customDelimiter)),
				removePreviouslySoldSerials: removePreviouslySoldSerials,
				defaultDelimiter: stockDelimiterHandler(delimiter, false, customDelimiter),
			}),
		false,
		'Stock successfully added',
		{
			onSuccess: () => {
				setCurrentTextareaTab(null)
				getWarehouses()
				getStocks()
			},
		}
	)

	const [isGetWarehousesLoading, warehouses, getWarehouses] = useAPI<StockGroup[]>(
		() => StockSystemService.getWarehouses(shop?.id || ''),
		false,
		undefined,
		{
			onSuccess: response => {
				setCurrentTextareaTab(null)
			},
		}
	)

	const [isEditStockLoading, , editStock] = useAPI(
		() =>
			StockSystemService.editWarehouseStock(shop?.id || '', Number(currentWarehouseId), {
				serials: stockValues.split(stockDelimiterHandler(delimiter, false, customDelimiter)),
				// removePreviouslySoldSerials: removePreviouslySoldSerials,
				defaultDelimiter: stockDelimiterHandler(delimiter, false, customDelimiter),
			}),
		false,
		'Stock successfully edited',
		{
			onSuccess: () => {
				getWarehouses()
				getStocks()
			},
		}
	)

	const onTabClickHandler = (tab: any) => {
		if (tab === StockTextareaTabs.add) {
			setStockValues('')
		} else {
			getStocks()
		}
		setCurrentTextareaTab(tab)
	}

	const closeClearStockModal = () => {
		closeModal(setIsClearStockModalActive, setIsClearStockModalVisible)
	}

	useEffect(() => {
		getWarehouses()
	}, [])

	useEffect(() => {
		if (warehouses?.length && !currentWarehouseId) {
			setCurrentWarehouseId(warehouses[0].id.toString())
		}
	}, [warehouses])

	useEffect(() => {
		if (currentWarehouseId) {
			const defaultDelimiter = warehouses?.find(
				warehouse => warehouse.id === +currentWarehouseId
			)?.defaultDelimiter

			if (defaultDelimiter && stocks) {
				const delimiterType = stockDelimiterHandler(defaultDelimiter, true)
				setStockValues(stocks.serials.join(defaultDelimiter))
				setDelimiter(delimiterType)
				if (delimiterType === 'Custom') {
					setCustomDelimiter(defaultDelimiter)
				}
			}
		}
	}, [stocks])

	useEffect(() => {
		if (warehouses && currentWarehouseId) {
			const stock = warehouses.find(warehouse => warehouse.id === +currentWarehouseId)?.stock
			setStock(stock === 0 ? 0 : stock || null)
		}
	}, [currentWarehouseId, warehouses])

	if (isGetWarehousesLoading) return <Spinner />

	if (addStockResponse?.previouslySoldSerials.length) {
		return (
			<PreviouslySoldSerials
				previouslySoldSerials={addStockResponse.previouslySoldSerials}
				isRemoved={removePreviouslySoldSerials}
			/>
		)
	}

	return (
		<div>
			{isClearStockModalActive && (
				<Modal
					closeModal={closeClearStockModal}
					visible={isClearStockModalVisible}
					setVisible={setIsClearStockModalVisible}
				>
					<ConfirmModal
						title={'Clear Stock'}
						description={`Are you sure you want to clear stock for ${
							warehouses?.find(warehouse => warehouse.id.toString() === currentWarehouseId)?.name
						}? This will remove stock within the editor forever.`}
						onConfirm={() => {
							setStockValues('')
							closeClearStockModal()
						}}
						confirmButtonText={'Confirm'}
						onClose={closeClearStockModal}
					/>
				</Modal>
			)}
			{warehouses && warehouses.length === 0 ? (
				<div className={styles.fieldWrapper}>
					<header>
						<span>
							Enter a stock name you’ll remember internally. Once created, you’ll then be able to
							add stock to this group for your products.
						</span>
					</header>
					<Input
						value={warehouseName}
						setValue={setWarehouseName}
						placeholder={'Demo Stock Group'}
					/>
					<div className={styles.mt10}>
						<div className={styles.secondaryButton}>
							<Button
								style={ButtonStyles.BORDERED}
								width={'100%'}
								disableShadow={true}
								icon={{
									id: 'plus',
									height: 13,
									width: 13,
									align: ButtonsIconAlign.LEFT,
								}}
								onClick={createWarehouse}
								isLoading={isCreateWarehouseLoading}
							>
								Create Warehouse
							</Button>
						</div>
					</div>
				</div>
			) : (
				<div className={styles.fieldWrapper}>
					<header>
						<span>Choose a serials group from your digital warehouse to deliver to customers.</span>
					</header>
					<CustomSelect
						isSearchable={true}
						style={SelectStyles.secondary}
						value={currentWarehouseId.toString()}
						setValue={setCurrentWarehouseId}
						options={
							warehouses?.map(warehouse => ({
								value: warehouse.id.toString(),
								label: warehouse.name,
							})) || []
						}
					/>
				</div>
			)}

			{currentWarehouseId && (
				<div className={styles.fieldWrapper}>
					<Tabs
						tabs={[
							{
								value: StockTextareaTabs.add,
								label: 'Add Stock',
							},
							{
								value: StockTextareaTabs.edit,
								label: 'Edit Stock',
							},
						]}
						currentTab={currentStockTextareaTab}
						setCurrentTab={onTabClickHandler}
					/>
				</div>
			)}

			{currentStockTextareaTab && (
				<>
					<div className={styles.fieldWrapper}>
						<h2>STOCK</h2>
						<StockTextarea
							value={stockValues}
							setValue={setStockValues}
							stockInDatabase={stocks?.serials.length || 0}
							stockInEditor={
								stockValues.split(stockDelimiterHandler(delimiter, false, customDelimiter)).length
							}
							isEditing={currentStockTextareaTab === StockTextareaTabs.edit}
						/>
						<p className={styles.fieldDescription}>
							Duplicated items are automatically removed from your stock.
						</p>

						{currentStockTextareaTab === StockTextareaTabs.add && (
							<div className={styles.switchWrapper}>
								<Switch
									checked={removePreviouslySoldSerials}
									setChecked={setRemovePreviouslySoldSerials}
								/>
								<p>Remove previously sold serials</p>
							</div>
						)}
					</div>
					<div className={styles.fieldWrapper}>
						<header>
							<div>
								<h2>Delimiter</h2>
							</div>
						</header>

						<CustomSelect
							style={SelectStyles.secondary}
							value={delimiter}
							setValue={setDelimiter}
							options={[
								{
									value: 'Comma',
									label: 'Comma',
								},
								{
									value: 'NewLine',
									label: 'New Line',
								},
								{
									value: 'Custom',
									label: 'Custom',
								},
							]}
						/>

						{delimiter === 'Custom' && (
							<div className={styles.mt10}>
								<Input
									value={customDelimiter}
									setValue={setCustomDelimiter}
									placeholder={'Custom delimiter'}
								/>
							</div>
						)}

						<p className={styles.fieldDescription}>Separates the serials in your stock group.</p>

						{currentStockTextareaTab === StockTextareaTabs.add && (
							<div className={styles.mtMediumLarge}>
								<Button
									icon={{
										id: 'plus',
										height: 13,
										width: 13,
										align: ButtonsIconAlign.LEFT,
									}}
									style={ButtonStyles.BORDERED}
									disableShadow={true}
									width={'100%'}
									onClick={addStock}
									isLoading={isAddStockLoading}
								>
									Add to Stock
								</Button>
							</div>
						)}
						{currentStockTextareaTab === StockTextareaTabs.edit && (
							<>
								<div className={styles.mt10}>
									<Button
										style={ButtonStyles.PRIMARY}
										icon={{
											id: 'download',
											width: 13,
											height: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										width={'100%'}
										onClick={editStock}
										isLoading={isEditStockLoading}
									>
										Save Stock
									</Button>
								</div>
								<div className={styles.mt10}>
									<Button
										style={ButtonStyles.THIRD}
										width={'100%'}
										onClick={() => setIsClearStockModalActive(true)}
									>
										Clear Stock
									</Button>
								</div>
							</>
						)}
					</div>
				</>
			)}
		</div>
	)
}

export default ChooseProductTypeModalSerials
