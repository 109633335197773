import { DatePeriod } from '../types/DatePeriod/DatePeriod'

export const datePeriodsSelectOptions = [
	{
		label: 'Today',
		value: DatePeriod.lastDay,
	},
	{
		label: 'Last 7 days',
		value: DatePeriod.lastWeek,
	},
	{
		label: 'Last 4 weeks',
		value: DatePeriod.lastMonth,
	},
	{
		label: 'Last 3 months',
		value: DatePeriod.last3Months,
	},
	{
		label: 'Last 12 months',
		value: DatePeriod.lastYear,
	},
	{
		label: 'All time',
		value: DatePeriod.allTime,
	},
]
