import React, { FC, useEffect, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { useAPI } from '../../../hooks/useAPI'
import { ImagesService } from '../../../API/ImagesService'
import { useAppContext } from '../../../hooks/useAppContext'
import ImageSelector from '../../ImageSelector/ImageSelector'
import Pagination from '../../UI/Pagination/Pagination'
import { AxiosProgressEvent } from 'axios'
import { Image } from '../../../types/Image/Image'
import Spinner from '../../UI/Spinner/Spinner'
import Modal from '../Modal'
import ConfirmModal from '../ConfirmModal/ConfirmModal'
import { closeModal } from '../../../helpers/closeModal'

interface SelectImageModalProps extends BaseModalProps {
	onSelectImages: (images: Image[]) => void
}

const SelectImageModal: FC<SelectImageModalProps> = ({ onClose, onSelectImages }) => {
	const [totalItems, setTotalItems] = useState(0)
	const [currentPage, setCurrentPage] = useState(0)
	const [imageFile, setImageFile] = useState<File | null>(null)
	const [uploadProgress, setUploadProgress] = useState<
		| {
				file: File | null
				progress: number
		  }[]
	>([])
	const { shop } = useAppContext()
	const [isDeleteImageModalVisible, setIsDeleteImageModalVisible] = useState(false)
	const [isDeleteImageModalActive, setIsDeleteImageModalActive] = useState(false)
	const [deletingImageId, setDeletingImageId] = useState<number | null>(null)
	const [selectedImages, setSelectedImages] = useState<Image[]>([])

	const onProgressUploadHandler = (data: AxiosProgressEvent) => {
		if (data?.total) {
			setUploadProgress([
				...uploadProgress,
				{
					file: imageFile,
					progress: Math.round((data.loaded / (data.total || 0)) * 100),
				},
			])
			setImageFile(null)
		}
	}

	const [isGetImagesLoading, images, getImages] = useAPI<Image[]>(
		() => ImagesService.getAll(shop?.id || '', undefined, currentPage + 1, 5),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	const [isDeleteImageLoading, , deleteImage] = useAPI(
		() => ImagesService.delete(shop?.id || '', deletingImageId),
		false,
		undefined,
		{
			onSuccess: response => {
				getImages()
				closeDeleteModal()
			},
		}
	)

	const onImageClick = (image: Image) => {
		if (!selectedImages?.find(selectImage => selectImage.id === image.id)) {
			return setSelectedImages([...selectedImages, image])
		}
		return setSelectedImages(selectedImages.filter(imageItem => imageItem.id !== image.id))
	}

	const closeDeleteModal = () => {
		closeModal(setIsDeleteImageModalActive, setIsDeleteImageModalVisible)
		setDeletingImageId(null)
	}

	const onDeleteClickHandler = (imageId: number) => {
		setDeletingImageId(imageId)
		setIsDeleteImageModalActive(true)
	}

	const onSelectImageClickHandler = () => {
		if (selectedImages) {
			onSelectImages(selectedImages)
			onClose()
		}
	}

	const [isUploadImageLoading, , uploadImage] = useAPI<Image>(
		() => {
			const formData = new FormData()

			formData.append('file', imageFile || '')

			return ImagesService.upload(shop?.id || '', formData, onProgressUploadHandler)
		},
		false,
		'Images successfully uploaded',
		{
			onSuccess: response => {
				getImages()
				setImageFile(null)
				setUploadProgress([])
				onSelectImages([...selectedImages, response.data.data])
				onClose()
			},
		}
	)

	useEffect(() => {
		if (shop) {
			getImages()
		}
	}, [shop, currentPage])

	return (
		<>
			{isDeleteImageModalActive && (
				<Modal
					closeModal={closeDeleteModal}
					visible={isDeleteImageModalVisible}
					setVisible={setIsDeleteImageModalVisible}
				>
					<ConfirmModal
						title={'Delete Image'}
						description={
							'This image will be deleted from your gallery forever. You will not be able to access it in the future. Are you sure you want to delete this image?'
						}
						onConfirm={deleteImage}
						isButtonLoading={isDeleteImageLoading}
						onClose={closeDeleteModal}
						buttonIcon={{
							id: 'trashBin',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						confirmButtonText={'Delete Image'}
					/>
				</Modal>
			)}

			<div className={styles.modalInner}>
				<header className={styles.header}>
					<h1>Select Image</h1>
				</header>

				<div className={styles.body}>
					{isGetImagesLoading && <Spinner />}
					<ImageSelector
						onImageClick={onImageClick}
						file={imageFile}
						setFile={setImageFile}
						uploadProgress={uploadProgress}
						uploadImage={uploadImage}
						images={images}
						onDeleteClick={onDeleteClickHandler}
						selectedImages={selectedImages}
					/>
					<div className={styles.mtExtraLarge}>
						<Pagination
							totalItems={totalItems}
							pageSize={5}
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							withoutPageSizeSelector={true}
						/>
					</div>
				</div>

				<footer className={styles.footer}>
					<div className={styles.buttons}>
						<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
							Close
						</Button>
						<Button
							style={ButtonStyles.BORDERED}
							icon={{
								id: 'download',
								height: 13,
								width: 13,
								align: ButtonsIconAlign.LEFT,
							}}
							disableShadow={true}
							onClick={onSelectImageClickHandler}
							isLoading={isUploadImageLoading}
						>
							Select Image{selectedImages.length > 1 && 's'}
						</Button>
					</div>
				</footer>
			</div>
		</>
	)
}

export default SelectImageModal
