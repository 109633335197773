import React, { FC } from 'react'
import styles from './StatusBadges.module.scss'
import { PaymentAccountStatus } from '../../../types/CashAppAccountStatus/PaymentAccountStatus'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import { PluginApprovalStatus } from '../../../types/PluginApprovalStatus/PluginApprovalStatus'

interface PluginApprovalStatusBadge {
	status: PluginApprovalStatus
}

const PluginApprovalStatusBadge: FC<PluginApprovalStatusBadge> = ({ status }) => {
	const statusData = {
		[PluginApprovalStatus.DECLINED]: {
			name: 'Declined',
			className: 'red',
			iconId: 'xMarkCircle',
		},
		[PluginApprovalStatus.AWAITING_REVIEW]: {
			name: 'Awaiting Review',
			className: 'brown',
			iconId: 'exclamationMarkCircle',
		},
		[PluginApprovalStatus.CANCELLED_BY_USER]: {
			name: 'Cancelled By User',
			className: 'red',
			iconId: 'xMarkCircle',
		},
		[PluginApprovalStatus.ACCEPTED]: {
			name: 'Accepted',
			className: 'green',
			iconId: 'checkedCircle',
		},
	}

	return (
		<div className={`${styles.statusBadge} ${styles[statusData[status].className]}`}>
			{statusData[status].name}
			<SpriteIcon iconId={statusData[status].iconId} width={16} height={16} />
		</div>
	)
}

export default PluginApprovalStatusBadge
