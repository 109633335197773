import React, { useEffect, useState } from 'react'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import styles from '../Settings.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../components/UI/Button/Button'
import Input from '../../../components/UI/FormComponents/Input'
import CopyField from '../../../components/UI/CopyField/CopyField'
import IconField from '../../../components/UI/IconField/IconField'
import { useAPI } from '../../../hooks/useAPI'
import { AuthService } from '../../../API/AuthService'
import { DiscordUserService } from '../../../API/DiscordUserService'
import Modal from '../../../components/Modals/Modal'
import SetupGoogleTFAModal from '../../../components/Modals/SetupGoogleTFAModal/SetupGoogleTFAModal'
import { closeModal } from '../../../helpers/closeModal'
import { UserSecurityService } from '../../../API/UserSecurityService'
import { SecuritySettings } from '../../../types/SecuritySettings/SecuritySettings'
import PageLoading from '../../../components/UI/PageLoading/PageLoading'

const SettingsSecurityPage = () => {
	const [newWindow, setNewWindow] = useState<Window | null>(null)
	const [isGoogleTFAModalActive, setIsGoogleTFAModalActive] = useState(false)
	const [isGoogleTFAModalVisible, setIsGoogleTFAModalVisible] = useState(false)

	const [isGetSecuritySettingsLoading, securitySettings, getSecuritySettings] =
		useAPI<SecuritySettings>(() => UserSecurityService.getSecurity(), false)

	useEffect(() => {
		if (newWindow) {
			const Interval = setInterval(() => {
				if (newWindow.closed) {
					clearInterval(Interval)
				}
			}, 500)
		}
	}, [newWindow])

	useEffect(() => {
		getSecuritySettings()
	}, [])

	if (isGetSecuritySettingsLoading) return <PageLoading />

	return (
		<PageWrapper>
			{isGoogleTFAModalActive && (
				<Modal
					closeModal={() => closeModal(setIsGoogleTFAModalActive, setIsGoogleTFAModalVisible)}
					visible={isGoogleTFAModalVisible}
					setVisible={setIsGoogleTFAModalVisible}
				>
					<SetupGoogleTFAModal
						onClose={() => closeModal(setIsGoogleTFAModalActive, setIsGoogleTFAModalVisible)}
						onSuccess={getSecuritySettings}
						isEnabled={!!securitySettings?.authDetails.googleTfa?.isEnabled}
					/>
				</Modal>
			)}
			<div className={styles.settingsPageInnerBody}>
				<div className={`${styles.settingSection}`}>
					<header className={styles.settingSectionHeader}>
						<p className={styles.settingSectionTitle}>Account Email</p>
						<p className={styles.settingSectionDescription}>
							We'll use this email address to contact you. If you want to change it, you’ll receive
							a verification email to your original email.
						</p>
					</header>
					<div className={styles.formWrapper}>
						<Input
							value={securitySettings?.email || ''}
							setValue={() => {}}
							placeholder={'demo+1@gmail.com'}
						/>
					</div>
					<footer className={styles.settingSectionFooter}>
						<p className={styles.helperText}>Please use a valid email.</p>
						<Button style={ButtonStyles.THIRD}>Save Settings</Button>
					</footer>
				</div>

				<div className={`${styles.settingSection} ${styles.oneLine}`}>
					<header className={styles.settingSectionHeader}>
						<p className={styles.settingSectionTitle}>TWO FACTOR AUTHENTICATION</p>
						<p className={styles.settingSectionDescription}>
							Enable time-based one-time 6-digit codes with Google Authenticator.
						</p>
					</header>
					<Button style={ButtonStyles.THIRD} onClick={() => setIsGoogleTFAModalActive(true)}>
						{securitySettings?.authDetails.googleTfa?.isEnabled ? 'Disable' : 'Setup'} Two Factor
					</Button>
				</div>

				<div className={`${styles.settingSection}`}>
					<header className={styles.settingSectionHeader}>
						<p className={styles.settingSectionTitle}>STORE ID</p>
						<p className={styles.settingSectionDescription}>
							This is your own identification number to access and interact with our public API.
						</p>
					</header>
					<div className={styles.formWrapper}>
						<CopyField value={'1784'} />
					</div>
				</div>

				<div className={`${styles.settingSection} ${styles.oneLine}`}>
					<header className={styles.settingSectionHeader}>
						<p className={styles.settingSectionTitle}>ACCOUNT PASSWORD</p>
						<p className={styles.settingSectionDescription}>
							To change your account password, tap on the button below. We recommend changing this
							once every few months.
						</p>
					</header>
					<Button
						style={ButtonStyles.THIRD}
						icon={{
							id: 'blankWindow',
							width: 12,
							height: 12,
							align: ButtonsIconAlign.RIGHT,
						}}
					>
						Change Password
					</Button>
				</div>

				<div className={`${styles.settingSection}`}>
					<header className={styles.settingSectionHeader}>
						<p className={styles.settingSectionTitle}>API KEY</p>
						<p className={styles.settingSectionDescription}>
							This API keys will allow you to authenticate your API requests and interact with our
							developer API.
						</p>
					</header>
					<div className={styles.fieldWithButton}>
						<div className={styles.field}>
							<CopyField
								value={
									'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjgiLCJleHAiOjE2NzU1NDM2OD.eyJhbGciOiJIUz'
								}
							/>
						</div>
						<Button style={ButtonStyles.THIRD}>Reset Key</Button>
					</div>
				</div>

				<div className={`${styles.settingSection}`}>
					<header className={styles.settingSectionHeader}>
						<p className={styles.settingSectionTitle}>SOCIAL ACCOUNTS</p>
						<p className={styles.settingSectionDescription}>
							Connect or disconnect social accounts for signing in to your business dashboard.
						</p>
					</header>
					{/*<div className={styles.fieldWithButton}>*/}
					{/*	<div className={styles.field}>*/}
					{/*		<IconField*/}
					{/*			value={'demo+2@gmail.com'}*/}
					{/*			icon={{*/}
					{/*				id: 'google',*/}
					{/*				height: 15,*/}
					{/*				width: 15,*/}
					{/*			}}*/}
					{/*		/>*/}
					{/*	</div>*/}
					{/*	<Button*/}
					{/*		style={ButtonStyles.THIRD}*/}
					{/*		onClick={() =>*/}
					{/*			setNewWindow(*/}
					{/*				window.open(*/}
					{/*					`https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=${*/}
					{/*						window.location.hostname === 'localhost'*/}
					{/*							? 'http%3A%2F%2Flocalhost%3A3000'*/}
					{/*							: 'https%3A%2F%2Fdash.billgang.com'*/}
					{/*					}%2Fsettings%2Fconnects%2Fgoogle&prompt=consent&response_type=code&client_id=165295676705-d81fbtjp5tn4uni6mljmssjp96ll01tq.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline&flowName=GeneralOAuthFlow`,*/}
					{/*					'_blank',*/}
					{/*					'width=480, height=720'*/}
					{/*				)*/}
					{/*			)*/}
					{/*		}*/}
					{/*	>*/}
					{/*		Connect*/}
					{/*	</Button>*/}
					{/*</div>*/}
					<div className={styles.fieldWithButton}>
						<div className={styles.field}>
							<IconField
								value={
									securitySettings?.authDetails.discordOauth
										? securitySettings?.authDetails.discordOauth.username
										: 'Not Connected'
								}
								icon={{
									id: 'discord3',
									height: 14,
									width: 18,
								}}
							/>
						</div>
						<Button
							style={ButtonStyles.THIRD}
							onClick={() =>
								setNewWindow(
									window.open(
										`https://discord.com/api/oauth2/authorize?client_id=1032724229172510740&redirect_uri=${
											window.location.hostname === 'localhost'
												? 'http%3A%2F%2Flocalhost%3A3000'
												: 'https%3A%2F%2Fdash.billgang.com'
										}%2Fsettings%2Fconnects%2Fdiscord&response_type=code&scope=identify%20guilds%20email%20guilds.join`,
										'_blank',
										'width=480, height=720'
									)
								)
							}
						>
							{securitySettings?.authDetails.discordOauth ? 'Reconnect' : 'Connect'}
						</Button>
					</div>
				</div>
			</div>
		</PageWrapper>
	)
}

export default SettingsSecurityPage
