import { privateAPI } from './API'
import { TicketCreate } from '../types/TicketCreate/TicketCreate'
import { TicketStatus } from '../types/TicketStatus/TicketStatus'

export const TicketsService = {
	getAll: async (
		shopId: string,
		statusFilter?: TicketStatus | null,
		searchString?: string,
		PageNumber?: number,
		PageSize?: number
	) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/tickets`, {
			params: {
				searchString,
				PageNumber,
				PageSize,
				statusFilter,
			},
		})
	},
	create: async (shopId: string, data: TicketCreate) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/tickets`, data)
	},
	getOne: async (shopId: string, id: number | null) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/tickets/${id}`)
	},
	answerTicket: async (shopId: string, id: number | null, data: { message: string }) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/tickets/${id}/answer`, data)
	},
	closeTicket: async (shopId: string, id: number | null) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/tickets/${id}/close`)
	},
}
