import React, { FC, useEffect, useState } from 'react'
import styles from './ProductVariantsDelivery.module.scss'
import ProductDeliveryDynamic from './ProductDeliveryDynamic'
import ProductDeliveryProduct from './ProductDeliveryProduct'
import Modal from '../../../../components/Modals/Modal'
import ChooseProductTypeModal from '../../../../components/Modals/ChooseProductTypeModal/ChooseProductTypeModal'
import { closeModal } from '../../../../helpers/closeModal'
import { VariantsDelivery } from '../../../../types/CreateVariants/VariantsDelivery'
import { DeliveryType } from '../../../../types/DeliveryType/DeliveryType'
import generalStyles from '../../CreateProductPage.module.scss'
import Switch from '../../../../components/UI/Switch/Switch'
import CustomSelect, { SelectStyles } from '../../../../components/UI/CustomSelect/CustomSelect'
import Input from '../../../../components/UI/FormComponents/Input'
import RadioWrapper from '../../../../components/UI/RadioWrapper/RadioWrapper'
import InputWithText from '../../../../components/UI/FormComponents/InputWithText'
import { deliveryTimeOptions } from '../../../../helpers/deliveryTimeOptions'

interface ProductVariantsDeliveryProps {
	variantsDelivery: VariantsDelivery
	setVariantsDelivery: (value: VariantsDelivery) => void
	currentVariantIndex: string
}

const ProductVariantsDelivery: FC<ProductVariantsDeliveryProps> = ({
	setVariantsDelivery,
	variantsDelivery,
	currentVariantIndex,
}) => {
	const [currentTab, setCurrentTab] = useState<DeliveryType>(
		variantsDelivery[currentVariantIndex].deliveryType
	)
	const [isChooseProductTypeModalActive, setIsChooseProductTypeModalActive] = useState(false)
	const [isChooseProductTypeModalVisible, setIsChooseProductTypeModalVisible] = useState(false)
	const [editingDeliveryConfigurationId, setEditingDeliveryConfigurationId] = useState<
		string | null
	>(null)

	useEffect(() => {
		if (!isChooseProductTypeModalActive) {
			setEditingDeliveryConfigurationId(null)
		}
	}, [isChooseProductTypeModalActive])

	useEffect(() => {
		setVariantsDelivery({
			...variantsDelivery,
			[currentVariantIndex]: {
				...variantsDelivery[currentVariantIndex],
				deliveryType: currentTab,
			},
		})
	}, [currentTab])

	return (
		<>
			{isChooseProductTypeModalActive && (
				<Modal
					closeModal={() => {}}
					visible={isChooseProductTypeModalVisible}
					setVisible={setIsChooseProductTypeModalVisible}
				>
					<ChooseProductTypeModal
						setVariantsDelivery={setVariantsDelivery}
						variantsDelivery={variantsDelivery}
						currentVariantIndex={currentVariantIndex}
						onClose={() => {
							closeModal(setIsChooseProductTypeModalActive, setIsChooseProductTypeModalVisible)
						}}
						editId={editingDeliveryConfigurationId}
					/>
				</Modal>
			)}
			<div className={styles.productVariantsDelivery}>
				{/*<div className={styles.tabs}>*/}
				{/*	<div*/}
				{/*		className={`${styles.tab} ${DeliveryType.PRESET === currentTab && styles.active}`}*/}
				{/*		onClick={() => setCurrentTab(DeliveryType.PRESET)}*/}
				{/*	>*/}
				{/*		<SpriteIcon iconId={'box'} width={14} height={14} />*/}
				{/*		<p>Product</p>*/}
				{/*	</div>*/}
				{/*	<div*/}
				{/*		className={`${styles.tab} ${DeliveryType.DYNAMIC === currentTab && styles.active}`}*/}
				{/*		onClick={() => setCurrentTab(DeliveryType.DYNAMIC)}*/}
				{/*	>*/}
				{/*		<SpriteIcon iconId={'network'} width={14} height={14} />*/}
				{/*		<p>Dynamic</p>*/}
				{/*	</div>*/}
				{/*</div>*/}

				<div className={generalStyles.doubleFields}>
					<RadioWrapper
						id={'MANAGED'}
						value={'MANAGED'}
						setValue={() => setCurrentTab(DeliveryType.PRESET)}
						checked={currentTab === DeliveryType.PRESET}
						title={'MANAGED'}
						description={'Automatically deliver products via our platform.'}
					/>
					<RadioWrapper
						id={'Dynamic'}
						value={'Dynamic'}
						setValue={() => setCurrentTab(DeliveryType.DYNAMIC)}
						checked={currentTab === DeliveryType.DYNAMIC}
						title={'Dynamic'}
						description={'Use our API to deliver products to customers.'}
					/>
				</div>

				{currentTab === DeliveryType.DYNAMIC && (
					<ProductDeliveryDynamic
						currentVariantIndex={currentVariantIndex}
						variantsDelivery={variantsDelivery}
						setVariantsDelivery={setVariantsDelivery}
					/>
				)}
				{currentTab === DeliveryType.PRESET && (
					<ProductDeliveryProduct
						setVariantsDelivery={setVariantsDelivery}
						variantsDelivery={variantsDelivery}
						currentVariantIndex={currentVariantIndex}
						onAddClickHandler={() => setIsChooseProductTypeModalActive(true)}
						setEditingDeliveryConfigurationId={setEditingDeliveryConfigurationId}
					/>
				)}

				{/*<ProductDeliverySerials />*/}
				<div className={generalStyles.fieldWrapper}>
					<header>
						<div>
							<p className={generalStyles.fieldTitle}>Delivery Time</p>
							<p className={generalStyles.fieldDescription}>
								Guaranteed delivery time on your product page.
							</p>
						</div>
						<Switch
							checked={variantsDelivery[currentVariantIndex].isDeliveryTimeEnabled}
							setChecked={checked =>
								setVariantsDelivery({
									...variantsDelivery,
									[currentVariantIndex]: {
										...variantsDelivery[currentVariantIndex],
										isDeliveryTimeEnabled: checked,
									},
								})
							}
						/>
					</header>
					{variantsDelivery[currentVariantIndex].isDeliveryTimeEnabled && (
						<div className={styles.mt10}>
							<CustomSelect
								style={SelectStyles.secondary}
								value={variantsDelivery[currentVariantIndex].deliveryTimeSeconds}
								setValue={value =>
									setVariantsDelivery({
										...variantsDelivery,
										[currentVariantIndex]: {
											...variantsDelivery[currentVariantIndex],
											deliveryTimeSeconds: value,
										},
									})
								}
								options={deliveryTimeOptions}
							/>
						</div>
					)}
					{variantsDelivery[currentVariantIndex].deliveryTimeSeconds === 'custom' && (
						<div className={styles.mtMediumLarge}>
							<InputWithText
								value={variantsDelivery[currentVariantIndex].customDeliveryTimeSeconds}
								setValue={value =>
									setVariantsDelivery({
										...variantsDelivery,
										[currentVariantIndex]: {
											...variantsDelivery[currentVariantIndex],
											customDeliveryTimeSeconds: value,
										},
									})
								}
								text={'seconds'}
							/>
						</div>
					)}
				</div>

				<div className={generalStyles.horLine} />

				<div className={generalStyles.doubleFields}>
					<div className={generalStyles.fieldWrapper}>
						<p className={generalStyles.fieldTitle}>Min. Order Quantity</p>
						<Input
							value={variantsDelivery[currentVariantIndex].minQuantity || ''}
							setValue={value =>
								setVariantsDelivery({
									...variantsDelivery,
									[currentVariantIndex]: {
										...variantsDelivery[currentVariantIndex],
										minQuantity: +value,
									},
								})
							}
							type={'number'}
							placeholder={''}
						/>
					</div>
					<div className={generalStyles.fieldWrapper}>
						<p className={generalStyles.fieldTitle}>Max. Order Quantity</p>

						<Input
							value={variantsDelivery[currentVariantIndex].maxQuantity || ''}
							setValue={value =>
								setVariantsDelivery({
									...variantsDelivery,
									[currentVariantIndex]: {
										...variantsDelivery[currentVariantIndex],
										maxQuantity: +value,
									},
								})
							}
							type={'number'}
							placeholder={''}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default ProductVariantsDelivery
