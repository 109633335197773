import React, { FC } from 'react'
import styles from './FormComponents.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage'

export enum InputIconAligns {
	RIGHT = 'right',
	LEFT = 'left',
}

interface InputProps {
	value: string | number
	setValue: (value: any) => void
	placeholder?: string
	type?: string
	icon?: {
		id: string
		height: number
		width: number
		align?: InputIconAligns
	}
	text: string
	errorMessage?: string
}

const InputWithText: FC<InputProps> = ({
	value,
	setValue,
	placeholder,
	type,
	icon,
	text,
	errorMessage,
}) => {
	return (
		<>
			<div className={`${styles.inputWithTextWrapper} ${icon?.align && styles[icon?.align]}`}>
				{icon && (
					<div className={styles.iconWrapper}>
						<SpriteIcon iconId={icon.id} width={icon.width} height={icon.height} />
					</div>
				)}
				<input
					style={{ paddingRight: 10 * text.length }}
					className={`${styles.input} ${errorMessage ? styles.error : ''}`}
					placeholder={placeholder}
					value={value}
					type={type}
					onChange={event => setValue(event.target.value)}
				/>
				<div className={`${styles.inputText} ${icon && styles.withIcon}`}>{text}</div>
			</div>

			{errorMessage && <FieldErrorMessage errorMessage={errorMessage} />}
		</>
	)
}

export default InputWithText
