import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { useAPI } from '../../../hooks/useAPI'
import { OrdersService } from '../../../API/OrdersService'
import Input from '../../UI/FormComponents/Input'
import { DetailedOrder } from '../../../types/DetailedOrder/DetailedOrder'
import getSymbolFromCurrency from 'currency-symbol-map'
import { Order } from '../../../types/Order/Order'

interface RefundOrderModalProps extends BaseModalProps {
	order?: DetailedOrder | Order
	customerEmail: string
	shopId: string
	onSuccess: () => void
}

const RefundOrderModal: FC<RefundOrderModalProps> = ({
	onClose,
	order,
	customerEmail,
	shopId,
	onSuccess,
}) => {
	const [isRefundOrderLoading, , refundOrder] = useAPI(
		() => OrdersService.refundOrder(shopId, order?.id),
		false,
		'Order successfully refunded',
		{
			onSuccess: () => {
				onClose()
				onSuccess()
			},
		}
	)

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Refund Order</h1>
			</header>

			<div className={styles.body}>
				<p id={styles.description}>
					You’re about to refund {customerEmail}’s recent payment. Are you sure you want to refund
					this payment? They’ll receive {getSymbolFromCurrency(order?.endPrice.currency || 'USD')}
					{order?.endPrice.amount} directly to their store balance.
				</p>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Close
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'dollarCircle',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={refundOrder}
						isLoading={isRefundOrderLoading}
					>
						Refund Customer
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default RefundOrderModal
