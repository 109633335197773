import React, { useEffect, useState } from 'react'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import styles from './PluginsPage.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import SearchInput, { SearchInputStyles } from '../../components/UI/SearchInput/SearchInput'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import { useAPI } from '../../hooks/useAPI'
import { CustomGatewaysService } from '../../API/CustomGatewaysService'
import PageTableHeader from '../../components/PageTableHeader/PageTableHeader'
import Modal from '../../components/Modals/Modal'
import { closeModal } from '../../helpers/closeModal'
import CreateCustomGatewayModal from '../../components/Modals/CreateCustomGatewayModal/CreateCustomGatewayModal'
import { CustomPlugin } from '../../types/Plugin/CustomPlugin'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import noPluginsImg from '../../assets/images/noPlugins.png'
import PluginsTable from '../../components/Tables/PluginsTable'
import Spinner from '../../components/UI/Spinner/Spinner'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import ViewPluginManifestModal from '../../components/Modals/ViewPluginManifestModal/ViewPluginManifestModal'
import { useDebounce } from '../../hooks/useDebounce'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import Pagination from '../../components/UI/Pagination/Pagination'
import { usePageTitle } from '../../hooks/usePageTitle'

const PluginsPage = () => {
	const [search, setSearch] = useState('')
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [totalItems, setTotalItems] = useState(0)
	const [isCreateModalActive, setIsCreateModalActive] = useState(false)
	const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
	const [isDeleteModalActive, setIsDeleteModalActive] = useState(false)
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
	const [isViewManifestModalActive, setIsViewManifestModalActive] = useState(false)
	const [isViewManifestModalVisible, setIsViewManifestModalVisible] = useState(false)
	usePageTitle('Plugin Development Center')

	const [usingPluginId, setUsingPluginId] = useState<number | null>(null)

	const [isGetPluginsLoading, plugins, getPlugins] = useAPI<CustomPlugin[]>(
		() => CustomGatewaysService.getAll(search, currentPage + 1, pageSize),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	const [isDeletePluginLoading, , deletePlugin] = useAPI(
		() => CustomGatewaysService.delete(Number(usingPluginId)),
		false,
		'Plugin successfully deleted',
		{
			onSuccess: () => {
				closeDeleteModal()
				getPlugins()
			},
		}
	)

	useDebounce(search, getPlugins, 400)
	const isFirstLoading = useFirstLoading(isGetPluginsLoading)

	const onDeletePluginClick = (id: number) => {
		setUsingPluginId(id)
		setIsDeleteModalActive(true)
	}

	const onEditPluginClick = (id: number) => {
		setUsingPluginId(id)
		setIsCreateModalActive(true)
	}

	const closeDeleteModal = () => {
		closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)
		setUsingPluginId(null)
	}

	const closeCreateModal = () => {
		closeModal(setIsCreateModalActive, setIsCreateModalVisible)
		setUsingPluginId(null)
	}

	useEffect(() => {
		getPlugins()
	}, [currentPage, pageSize])

	if (isFirstLoading) return <PageLoading />

	return (
		<PageWrapper>
			{isCreateModalActive && (
				<Modal
					closeModal={closeCreateModal}
					visible={isCreateModalVisible}
					setVisible={setIsCreateModalVisible}
				>
					<CreateCustomGatewayModal
						onClose={closeCreateModal}
						getPlugins={getPlugins}
						editingPluginId={usingPluginId}
					/>
				</Modal>
			)}
			{isViewManifestModalActive && (
				<Modal
					closeModal={() => closeModal(setIsViewManifestModalActive, setIsViewManifestModalVisible)}
					visible={isViewManifestModalVisible}
					setVisible={setIsViewManifestModalVisible}
				>
					<ViewPluginManifestModal
						onClose={() => closeModal(setIsViewManifestModalActive, setIsViewManifestModalVisible)}
						pluginId={usingPluginId}
					/>
				</Modal>
			)}
			{isDeleteModalActive && (
				<Modal
					closeModal={closeDeleteModal}
					visible={isDeleteModalVisible}
					setVisible={setIsDeleteModalVisible}
				>
					<ConfirmModal
						onClose={closeDeleteModal}
						description={
							'You are about to delete plugin. Are you sure you want to delete this plugin? This cannot be undone.'
						}
						title={'Delete Plugin'}
						onConfirm={deletePlugin}
						confirmButtonText={'Delete Plugin'}
						buttonIcon={{
							id: 'trashBin',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						isButtonLoading={isDeletePluginLoading}
					/>
				</Modal>
			)}
			<PageContainer>
				{!plugins?.length && !search ? (
					<NoDataComponent
						imageSrc={noPluginsImg}
						title={'No Plugins'}
						description={
							'You haven’t created any payment plugins. To create a plugin, tap on the button below.'
						}
						button={{
							icon: {
								id: 'plus',
								height: 13,
								width: 13,
								align: ButtonsIconAlign.RIGHT,
							},
							onClick: () => setIsCreateModalActive(true),
							text: 'New Plugin',
						}}
					/>
				) : (
					<div className={styles.pluginsPage}>
						<PageHeader title={'Plugin Development Center'}>
							<div className={styles.headerEnd}>
								<SearchInput
									style={SearchInputStyles.secondary}
									value={search}
									setValue={setSearch}
									placeholder={'Search for a plugin by name'}
								/>
								<Button
									style={ButtonStyles.SECONDARY}
									icon={{
										id: 'plus',
										height: 13,
										width: 13,
										align: ButtonsIconAlign.LEFT,
									}}
									onClick={() => setIsCreateModalActive(true)}
								>
									New Plugin
								</Button>
							</div>
						</PageHeader>

						<PageTableHeader
							listItemNamePlural={'plugins'}
							totalItems={totalItems}
							pageSize={pageSize}
							setPageSize={setPageSize}
							setCurrentPage={setCurrentPage}
						/>
						{!isGetPluginsLoading ? (
							plugins && (
								<div className={styles.tableWrapper}>
									<PluginsTable
										plugins={plugins}
										onDeleteClick={onDeletePluginClick}
										onEditClick={onEditPluginClick}
									/>
								</div>
							)
						) : (
							<Spinner />
						)}

						<div className={styles.paginationWrapper}>
							<Pagination
								withoutPageSizeSelector={true}
								totalItems={totalItems}
								pageSize={pageSize}
								setPageSize={setPageSize}
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
							/>
						</div>
					</div>
				)}
			</PageContainer>
		</PageWrapper>
	)
}

export default PluginsPage
