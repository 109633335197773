import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import styles from './CustomSelect.module.scss'
import { CustomSelectOption } from '../../../types/CustomSelectOption/CustomSelectOption'
import Select from 'react-select'
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

export enum SelectStyles {
	secondary = 'secondary',
	transparent = 'transparent',
	light = 'light',
}

interface SelectProps {
	value: string
	setValue: (value: string) => void
	options: CustomSelectOption[]
	style?: SelectStyles
	errorMessage?: string
	size?: 'small' | 'medium'
	controlClassName?: string
	controlClassNameFocused?: string
	onFocus?: () => void
	onBlur?: () => void
	isSearchable?: boolean
}

const CustomSelect: FC<SelectProps> = ({
	setValue,
	value,
	style,
	errorMessage,
	options,
	size,
	controlClassName,
	controlClassNameFocused,
	onFocus,
	onBlur,
	isSearchable = false,
}) => {
	const [localValue, setLocalValue] = useState<{ value: string; label: string | ReactNode }>()
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	const handleOnChange = (newValue: any) => {
		setValue(newValue.value)
		setLocalValue(newValue)
		onBlur && onBlur()
	}

	const onFocusHandler = () => {
		onFocus && onFocus()
		setIsMenuOpen(true)
	}

	const onBlurHandler = () => {
		onBlur && onBlur()
		setIsMenuOpen(false)
	}

	useEffect(() => {
		setLocalValue(options.find(option => option.value === value))
	}, [value])

	return (
		<>
			<Select
				className={`${styles.select} ${style && styles[style]} ${size && styles[size]}`}
				isSearchable={isSearchable}
				classNames={{
					control: ({ isFocused }) =>
						isFocused
							? controlClassNameFocused || styles.controlFocused
							: controlClassName || styles.control,

					menuList: ({}) => styles.menuList,
					menu: () => styles.menu,
					option: ({ isSelected }) => (isSelected ? styles.optionSelected : styles.option),
					singleValue: () => styles.singleValue,
					indicatorSeparator: () => styles.indicatorSeparator,
					indicatorsContainer: () =>
						isMenuOpen ? styles.indicatorsContainerActive : styles.indicatorsContainer,
					dropdownIndicator: () => styles.dropdownIndicator,
					valueContainer: () => styles.valueContainer,
				}}
				options={options}
				onChange={handleOnChange}
				onFocus={onFocusHandler}
				onBlur={onBlurHandler}
				blurInputOnSelect={true}
				captureMenuScroll={false}
				value={localValue}
				components={{
					DropdownIndicator: () => {
						return (
							<SpriteIcon
								className={`${styles.arrowHead}`}
								iconId={'arrowDown'}
								width={12}
								height={6}
							/>
						)
					},
				}}
			/>
			{errorMessage && <FieldErrorMessage errorMessage={errorMessage} />}
		</>
	)
}

export default CustomSelect
