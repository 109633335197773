import React, { FC } from 'react'
import styles from './JSONWrapper.module.scss'
import { JsonViewer } from '@textea/json-viewer'

interface JsonWrapperProps {
	style?: 'secondary'
	json: JSON | string
}

const JsonWrapper: FC<JsonWrapperProps> = ({ json, style }) => {
	return (
		<div className={`${styles.jsonWrapper} ${style && styles[style]}`}>
			<JsonViewer value={json} />
		</div>
	)
}

export default JsonWrapper
