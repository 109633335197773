import React, { FC } from 'react'
import styles from './StatusBadges.module.scss'
import { PaymentAccountStatus } from '../../../types/CashAppAccountStatus/PaymentAccountStatus'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

interface CashAppAccountStatusBadgeProps {
	cashAppAccountStatus: PaymentAccountStatus
}

const CashAppAccountStatusBadge: FC<CashAppAccountStatusBadgeProps> = ({
	cashAppAccountStatus,
}) => {
	const cashAppAccountStatusData = {
		[PaymentAccountStatus.DISABLED]: {
			name: 'Disabled',
			className: 'red',
			iconId: 'xMarkCircle',
		},
		[PaymentAccountStatus.ACTIVE]: {
			name: 'Activated',
			className: 'green',
			iconId: 'checkedCircle',
		},
		[PaymentAccountStatus.LIMITS_REACHED]: {
			name: 'Limits Reached',
			className: 'brown',
			iconId: 'exclamationMarkCircle',
		},
	}

	return (
		<div
			className={`${styles.statusBadge} ${
				styles[cashAppAccountStatusData[cashAppAccountStatus].className]
			}`}
		>
			{cashAppAccountStatusData[cashAppAccountStatus].name}
			<SpriteIcon
				iconId={cashAppAccountStatusData[cashAppAccountStatus].iconId}
				width={16}
				height={16}
			/>
		</div>
	)
}

export default CashAppAccountStatusBadge
