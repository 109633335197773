import React, { FC } from 'react'
import ReactInputMask from 'react-input-mask'
import styles from './FormComponents.module.scss'
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage'

interface InputMaskProps {
	mask: string
	value: string
	setValue: (value: string) => void
	placeholder?: string
	onBlur?: () => void
	errorMessage?: string
}

const InputMask: FC<InputMaskProps> = ({
	mask,
	setValue,
	value,
	placeholder,
	onBlur,
	errorMessage,
}) => {
	return (
		<>
			<ReactInputMask
				className={styles.input}
				mask={mask}
				value={value}
				onChange={event => setValue(event.target.value)}
				placeholder={placeholder}
				onBlur={onBlur}
			/>
			{errorMessage && <FieldErrorMessage errorMessage={errorMessage} />}
		</>
	)
}

export default InputMask
