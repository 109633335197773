import { privateAPI } from './API'
import { ListingType } from '../types/ListingType/ListingType'

export const ListingsService = {
	getListings: async (shopId: string, typeFilter?: ListingType) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/listings`, {
			params: {
				typeFilter,
			},
		})
	},
}
