import React, { FC } from 'react'
import styles from './ProductVariantsGeneral.module.scss'
import generalStyles from '../../CreateProductPage.module.scss'
import Input from '../../../../components/UI/FormComponents/Input'
import CustomMDEditor from '../../../../components/UI/CustomMDEditor/CustomMDEditor'
import { VariantsGeneral } from '../../../../types/CreateVariants/VariantsGeneral'
import Switch from '../../../../components/UI/Switch/Switch'
import CustomSelect, { SelectStyles } from '../../../../components/UI/CustomSelect/CustomSelect'
import RequiredBadge from '../../../../components/UI/RequiredBadge/RequiredBadge'

interface ProductVariantsGeneralProps {
	setVariantsGeneral: (value: VariantsGeneral) => void
	variantsGeneral: VariantsGeneral
	currentVariantIndex: string
}

const ProductVariantsGeneral: FC<ProductVariantsGeneralProps> = ({
	setVariantsGeneral,
	variantsGeneral,
	currentVariantIndex,
}) => {
	return (
		<div className={styles.productVariantsGeneral}>
			<div className={generalStyles.doubleFields}>
				<div className={generalStyles.fieldWrapper}>
					<p className={generalStyles.fieldTitle}>
						Variant Title <RequiredBadge />
					</p>
					<Input
						value={variantsGeneral[currentVariantIndex].title}
						setValue={value =>
							setVariantsGeneral({
								...variantsGeneral,
								[currentVariantIndex]: {
									...variantsGeneral[currentVariantIndex],
									title: value,
								},
							})
						}
						placeholder={'Demo Variant'}
					/>
				</div>
				<div className={generalStyles.fieldWrapper}>
					<p className={generalStyles.fieldTitle}>Short Description</p>
					<Input
						value={variantsGeneral[currentVariantIndex].shortDescription}
						setValue={value =>
							setVariantsGeneral({
								...variantsGeneral,
								[currentVariantIndex]: {
									...variantsGeneral[currentVariantIndex],
									shortDescription: value,
								},
							})
						}
						placeholder={'An optional, concise summary of your product'}
					/>
				</div>
			</div>

			<div className={generalStyles.fieldWrapper}>
				<header>
					<p className={generalStyles.fieldTitle}>Variant Description</p>
					<Switch
						checked={variantsGeneral[currentVariantIndex].isDescriptionEnabled}
						setChecked={checked =>
							setVariantsGeneral({
								...variantsGeneral,
								[currentVariantIndex]: {
									...variantsGeneral[currentVariantIndex],
									isDescriptionEnabled: checked,
								},
							})
						}
					/>
				</header>
				{variantsGeneral[currentVariantIndex].isDescriptionEnabled && (
					<CustomMDEditor
						value={variantsGeneral[currentVariantIndex].description}
						setValue={value =>
							setVariantsGeneral({
								...variantsGeneral,
								[currentVariantIndex]: {
									...variantsGeneral[currentVariantIndex],
									description: value,
								},
							})
						}
					/>
				)}
			</div>
		</div>
	)
}

export default ProductVariantsGeneral
