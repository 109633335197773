import React, { FC } from 'react'
import styles from './StatusBadges.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import { WarehouseStatus } from '../../../types/WarehouseStatus/WarehouseStatus'
import { OrderStatus } from '../../../types/OrderStatus/OrderStatus'
import { OrderChargeStatus } from '../../../types/OrderChargeStatus/OrderChargeStatus'
import { PartOrderDeliveryStatus } from '../../../types/PartOrderDeliveryStatus/PartOrderDeliveryStatus'

interface PartOrderStatusBadgeProps {
	partOrderStatus: PartOrderDeliveryStatus
}

const PartOrderStatusBadge: FC<PartOrderStatusBadgeProps> = ({ partOrderStatus }) => {
	const partOrderStatusData = {
		[PartOrderDeliveryStatus.PARTIALLY_DELIVERED]: {
			name: 'Partially Delivered',
			className: 'brown',
		},
		[PartOrderDeliveryStatus.DELIVERED]: {
			name: 'Delivered',
			className: 'green',
		},
		[PartOrderDeliveryStatus.NOT_DELIVERED]: {
			name: 'Not Delivered',
			className: 'red',
		},
	}

	return (
		<div
			className={`${styles.statusBadge} ${styles[partOrderStatusData[partOrderStatus].className]}`}
		>
			{partOrderStatusData[partOrderStatus].name}
		</div>
	)
}

export default PartOrderStatusBadge
