import { useEffect, useState } from 'react'

export const useDebounce = (value: any, afterDebounce: (value: any) => void, wait: number) => {
	const [isMounted, setIsMounted] = useState(false)

	useEffect(() => {
		if (isMounted) {
			const timeoutId = setTimeout(() => {
				afterDebounce(value)
			}, wait)
			return () => clearTimeout(timeoutId)
		}
	}, [value, wait])

	useEffect(() => {
		setIsMounted(true)
	}, [isMounted])
}
