export function parseUserAgent(userAgent: string) {
	const browsers = {
		Chrome: /Chrome/i,
		Safari: /Safari/i,
		Firefox: /Firefox/i,
		Edge: /Edg/i,
		IE: /Trident/i,
	}

	const operatingSystems = {
		Windows: /Windows NT/i,
		macOS: /Macintosh/i,
		iOS: /iPhone|iPad|iPod/i,
		Android: /Android/i,
		Linux: /Linux/i,
	}

	const devices = {
		Mobile: /Mobile/i,
		Tablet: /Tablet/i,
		Desktop: /Macintosh|Windows/i,
	}

	let browser = 'Unknown'
	let os = 'Unknown'
	let device = 'Unknown'

	// Determine browser
	for (const [browserName, regex] of Object.entries(browsers)) {
		if (regex.test(userAgent)) {
			browser = browserName
			break
		}
	}

	// Determine operating system
	for (const [osName, regex] of Object.entries(operatingSystems)) {
		if (regex.test(userAgent)) {
			os = osName
			break
		}
	}

	// Determine device
	for (const [deviceName, regex] of Object.entries(devices)) {
		if (regex.test(userAgent)) {
			device = deviceName
			break
		}
	}

	return { browser, os, device }
}
