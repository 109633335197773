import { privateAPI } from './API'

export const CustomersService = {
	getStats: async (shopId: string, fromDate: Date | null, toDate: Date | null) => {
		return privateAPI.get(`/v1/dash/shops/${shopId}/customers/stats`, {
			params: {
				fromDate,
				toDate,
			},
		})
	},
	getAll: async (shopId: string, searchString?: string, PageNumber?: number, PageSize?: number) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/customers`, {
			params: {
				searchString,
				PageNumber,
				PageSize,
			},
		})
	},
	getOne: async (shopId: string, id: string | undefined) => {
		return privateAPI.get(`/v1/dash/shops/${shopId}/customers/${id}`)
	},
	editCustomer: async (
		shopId: string,
		id: number | undefined,
		data: {
			firstName: string
			lastName: string
		}
	) => {
		return privateAPI.put(`/v1/dash/shops/${shopId}/customers/${id}`, data)
	},
	editCustomerNote: async (
		shopId: string,
		id: number | undefined,
		data: {
			customerNote: string
		}
	) => {
		return privateAPI.put(`/v1/dash/shops/${shopId}/customers/${id}/note`, data)
	},
	editCustomersBalance: async (
		shopId: string,
		id: number | undefined,
		data: {
			amount: number
		}
	) => {
		return privateAPI.post(`/v1/dash/shops/${shopId}/customers/${id}/balance/add`, data)
	},
}
