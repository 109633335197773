import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import Input from '../../UI/FormComponents/Input'
import CustomSelect, { SelectStyles } from '../../UI/CustomSelect/CustomSelect'
import ProductCustomField from '../../../pages/CreateProductPage/components/ProductCustomField/ProductCustomField'
import { CustomFieldType } from '../../../types/CustomFieldType/CustomFieldType'
import { useAppContext } from '../../../hooks/useAppContext'
import { useAPI } from '../../../hooks/useAPI'
import { BulkUpdateProductsService } from '../../../API/BulkUpdateProductsService'
import { BulkUpdateModalsProps } from '../../../types/BulkUpdateModalsProps/BulkUpdateModalsProps'
import Textarea from '../../UI/FormComponents/Textarea'
import Checkbox from '../../UI/Checkbox/Checkbox'
import Switch from '../../UI/Switch/Switch'
import { CustomField } from '../../../types/CustomField/CustomField'
import uniqid from 'uniqid'
import CheckboxWrapper from '../../UI/CheckboxWrapper/CheckboxWrapper'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'
import OptionalBadge from '../../UI/OptionalBadge/OptionalBadge'

const UpdateCustomFieldsModal: FC<BulkUpdateModalsProps> = ({
	onClose,
	productIds,
	getProducts,
}) => {
	const { shop } = useAppContext()
	const [name, setName] = useState('')
	const [placeholder, setPlaceholder] = useState('')
	const [defaultValue, setDefaultValue] = useState<any>('')
	const [required, setRequired] = useState(false)
	const [regex, setRegex] = useState('')
	const [fieldType, setFieldType] = useState<string>(CustomFieldType.NUMBER)
	const [createdCustomFields, setCreatedCustomFields] = useState<CustomField[]>([])
	const [editingCustomFieldName, setEditingCustomFieldName] = useState<string | null>(null)
	const [errors, setErrors] = useState<FormErrors>({})

	const [isUpdateCustomFieldsLoading, , updateCustomFields] = useAPI(
		() =>
			BulkUpdateProductsService.updateCustomFields(shop?.id || '', {
				customFields: createdCustomFields,
				productIds,
			}),
		false,
		'Products successfully updated',
		{
			onSuccess: () => {
				onClose()
				getProducts()
			},
		}
	)

	const createCustomFieldValidator = () => {
		const nameValidator = fieldValidators.blankValidator('Custom Field Name', name)

		setErrors({
			...errors,
			name: nameValidator,
		})

		return !nameValidator
	}

	const onCreateClickHandler = () => {
		if (createCustomFieldValidator()) {
			if (editingCustomFieldName) {
				setCreatedCustomFields(
					createdCustomFields.map(customField =>
						customField.name === editingCustomFieldName
							? {
									type: fieldType as CustomFieldType,
									name: name,
									placeholder: placeholder,
									defaultValue: defaultValue,
									required: required,
									regex: regex,
							  }
							: customField
					)
				)
			} else {
				setCreatedCustomFields([
					...createdCustomFields,
					{
						type: fieldType as CustomFieldType,
						name: name,
						placeholder: placeholder,
						defaultValue: defaultValue,
						required: required,
						regex: regex,
					},
				])
			}

			setName('')
			setRequired(false)
			setPlaceholder('')
			setRegex('')
			setDefaultValue('')
			setFieldType(CustomFieldType.NUMBER)
			setEditingCustomFieldName(null)
		}
	}

	const onCustomFieldEditClickHandler = (customField: CustomField) => {
		setName(customField.name)
		setRequired(customField.required)
		setPlaceholder(customField.placeholder || '')
		setDefaultValue(customField.defaultValue || '')
		setFieldType(customField.type)
		setEditingCustomFieldName(customField.name)
		setRegex(customField.regex || '')
	}

	const onCustomFieldDeleteClickHandler = (name: string) => {
		setCreatedCustomFields(createdCustomFields.filter(customField => customField.name !== name))
	}

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Update Custom Fields</h1>
			</header>

			<div className={styles.body}>
				<p className={styles.description}>
					These updates will override all previous custom fields for your storefront.
				</p>

				<div className={styles.fieldWrapper}>
					<h2>Name</h2>
					<Input
						value={name}
						setValue={setName}
						placeholder={'Name'}
						errorMessage={errors['name']}
						onBlur={event =>
							setErrors({
								...errors,
								name: fieldValidators.blankValidator('Custom Field Name', event.target.value),
							})
						}
					/>
				</div>
				{fieldType !== CustomFieldType.CHECK_BOX && fieldType !== CustomFieldType.HIDDEN && (
					<div className={styles.fieldWrapper}>
						<h2>
							Placeholder <OptionalBadge />
						</h2>
						<Input value={placeholder} setValue={setPlaceholder} placeholder={'Placeholder'} />
					</div>
				)}

				{fieldType === CustomFieldType.NUMBER && (
					<div className={styles.fieldWrapper}>
						<h2>
							Default value <OptionalBadge />
						</h2>
						<Input
							value={defaultValue}
							setValue={setDefaultValue}
							placeholder={'Default value'}
							type={'number'}
						/>
					</div>
				)}

				{(fieldType === CustomFieldType.TEXT || fieldType === CustomFieldType.HIDDEN) && (
					<div className={styles.fieldWrapper}>
						<h2>
							Default value <OptionalBadge />
						</h2>
						<Input value={defaultValue} setValue={setDefaultValue} placeholder={'Default value'} />
					</div>
				)}

				{(fieldType === CustomFieldType.TEXT || fieldType === CustomFieldType.LARGE_TEXT) && (
					<div className={styles.fieldWrapper}>
						<h2>
							Regex <OptionalBadge />
						</h2>
						<Input value={regex} setValue={setRegex} placeholder={'Regex value'} />
					</div>
				)}

				{fieldType === CustomFieldType.LARGE_TEXT && (
					<div className={styles.fieldWrapper}>
						<h2>
							Default value <OptionalBadge />
						</h2>
						<Textarea
							value={defaultValue}
							setValue={setDefaultValue}
							placeholder={'Default value'}
						/>
					</div>
				)}

				{fieldType === CustomFieldType.CHECK_BOX && (
					<div className={styles.switchWrapper}>
						<Checkbox
							id={'customFieldCheckbox'}
							checked={defaultValue}
							setChecked={setDefaultValue}
						/>
						<p>Default value</p>
					</div>
				)}

				<div className={styles.fieldWrapper}>
					<h2>Input type</h2>
					<CustomSelect
						style={SelectStyles.secondary}
						value={fieldType}
						setValue={setFieldType}
						options={[
							{
								value: CustomFieldType.NUMBER,
								label: 'Number',
							},
							{
								value: CustomFieldType.TEXT,
								label: 'Textbox',
							},
							{
								value: CustomFieldType.LARGE_TEXT,
								label: 'Large Textbox',
							},
							{
								value: CustomFieldType.CHECK_BOX,
								label: 'Checkbox',
							},

							{
								value: CustomFieldType.HIDDEN,
								label: 'Hidden',
							},
						]}
					/>
				</div>
				<div className={styles.mtMediumLarge}>
					<CheckboxWrapper
						id={'required'}
						checked={required}
						setChecked={setRequired}
						title={'Require customer to fill in this field'}
					/>
				</div>

				<div className={styles.singleButton}>
					<Button
						style={ButtonStyles.BORDERED}
						disableShadow={true}
						icon={{
							id: 'plus',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						onClick={onCreateClickHandler}
					>
						{editingCustomFieldName ? 'Edit' : 'Add'} custom field
					</Button>
				</div>

				{createdCustomFields.map(customField => (
					<ProductCustomField
						customField={customField}
						onEditClick={() => onCustomFieldEditClickHandler(customField)}
						onDeleteClick={() => onCustomFieldDeleteClickHandler(customField.name)}
					/>
				))}
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						disableShadow={true}
						icon={{
							id: 'download',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						onClick={updateCustomFields}
						isLoading={isUpdateCustomFieldsLoading}
					>
						Save Update
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default UpdateCustomFieldsModal
