import React, { FC, useEffect, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import Input from '../../UI/FormComponents/Input'
import CheckboxWrapper from '../../UI/CheckboxWrapper/CheckboxWrapper'
import CustomSelect from '../../UI/CustomSelect/CustomSelect'
import CustomMultiSelect from '../../UI/CustomMultiSelect/CustomMultiSelect'
import { useAPI } from '../../../hooks/useAPI'
import { CustomersService } from '../../../API/CustomersService'
import { useAppContext } from '../../../hooks/useAppContext'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'
import { DetailedCustomer } from '../../../types/DetailedCustomer/DetailedCustomer'

interface EditCustomerDataModalProps extends BaseModalProps {
	customer: DetailedCustomer | undefined
	getCustomer: () => void
}

const EditCustomerDataModal: FC<EditCustomerDataModalProps> = ({
	onClose,
	customer,
	getCustomer,
}) => {
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [autoGrantAccessToProduct, setAutoGrantAccessToProduct] = useState(false)
	const [grantedProducts, setGrantedProducts] = useState<string[]>([])
	const [errors, setErrors] = useState<FormErrors>({})
	const { shop } = useAppContext()
	const firstNameValidator = fieldValidators.blankValidator('First Name', firstName)
	const lastNameValidator = fieldValidators.blankValidator('Last Name', lastName)
	const validator = () => {
		setErrors({
			firstName: firstNameValidator,
			lastName: lastNameValidator,
		})

		return !(firstNameValidator || lastNameValidator)
	}

	const [isEditCustomerLoading, , editCustomer] = useAPI(
		() =>
			CustomersService.editCustomer(shop?.id || '', customer?.id, {
				firstName,
				lastName,
			}),
		false,
		'Customer successfully edited',
		{
			validator: validator,
			onSuccess: () => {
				onClose()
				getCustomer()
			},
		}
	)

	useEffect(() => {
		setLastName(customer?.lastName || '')
		setFirstName(customer?.firstName || '')
	}, [])

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Edit Customer Data</h1>
			</header>

			<div className={styles.body}>
				<div className={styles.doubleFields}>
					<div className={styles.fieldWrapper}>
						<h2>First Name</h2>
						<Input
							value={firstName}
							setValue={setFirstName}
							placeholder={'John'}
							errorMessage={errors['firstName']}
							onBlur={() =>
								setErrors({
									...errors,
									firstName: firstNameValidator,
								})
							}
						/>
					</div>
					<div className={styles.fieldWrapper}>
						<h2>Last Name</h2>
						<Input
							value={lastName}
							setValue={setLastName}
							placeholder={'Doe'}
							errorMessage={errors['lastName']}
							onBlur={() =>
								setErrors({
									...errors,
									lastName: lastNameValidator,
								})
							}
						/>
					</div>
				</div>

				{/*<div className={styles.fieldWrapper}>*/}
				{/*	<h2>Email Address</h2>*/}
				{/*	<Input value={lastName} setValue={setLastName} placeholder={'john@gmail.com'} />*/}
				{/*</div>*/}

				{/*<div className={styles.fieldWrapper}>*/}
				{/*	<h2>Password</h2>*/}
				{/*	<Input value={lastName} setValue={setLastName} placeholder={'j#Oh@n!'} />*/}
				{/*</div>*/}

				{/*<div className={styles.mtMediumLarge}>*/}
				{/*	<CheckboxWrapper*/}
				{/*		id={'autoGrant'}*/}
				{/*		checked={autoGrantAccessToProduct}*/}
				{/*		setChecked={setAutoGrantAccessToProduct}*/}
				{/*		title={'Automatically grant access to specific products'}*/}
				{/*	/>*/}
				{/*</div>*/}

				{/*{autoGrantAccessToProduct && (*/}
				{/*	<div className={styles.mtMediumLarge}>*/}
				{/*		<CustomMultiSelect value={grantedProducts} setValue={setGrantedProducts} options={[]} />*/}
				{/*	</div>*/}
				{/*)}*/}
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'download',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={editCustomer}
						isLoading={isEditCustomerLoading}
					>
						Save Customer
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default EditCustomerDataModal
