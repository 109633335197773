import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import styles from './ManageStockGroupPage.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import Pagination from '../../components/UI/Pagination/Pagination'
import StockEntriesTable from '../../components/Tables/StockEntriesTable'
import StockTextarea from '../../components/UI/FormComponents/StockTextarea'
import Switch from '../../components/UI/Switch/Switch'
import CustomSelect, { SelectStyles } from '../../components/UI/CustomSelect/CustomSelect'
import SearchInput, { SearchInputStyles } from '../../components/UI/SearchInput/SearchInput'
import { useAPI } from '../../hooks/useAPI'
import { StockSystemService } from '../../API/StockSystemService'
import { useAppContext } from '../../hooks/useAppContext'
import Input from '../../components/UI/FormComponents/Input'
import Spinner from '../../components/UI/Spinner/Spinner'
import { format } from 'date-fns'
import { Stock } from '../../types/Stock/Stock'
import { stockDelimiterHandler } from '../../helpers/stockDelimiterHandler'
import StockAlertInput from '../../components/UI/FormComponents/StockAlertInput/StockAlertInput'
import { StockGroupDetailed } from '../../types/StockGroup/StockGroupDetailed'
import { WarehouseStatus } from '../../types/WarehouseStatus/WarehouseStatus'
import PageTableHeader from '../../components/PageTableHeader/PageTableHeader'
import Modal from '../../components/Modals/Modal'
import { closeModal } from '../../helpers/closeModal'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import { useDebounce } from '../../hooks/useDebounce'
import { usePageTitle } from '../../hooks/usePageTitle'
import MiniStat from '../../components/MiniStat/MiniStat'
import { AddStockResponse } from '../../types/AddStockResponse/AddStockResponse'
import PreviouslySoldSerials from '../../components/PreviouslySoldSerials/PreviouslySoldSerials'
import PreviouslySoldSerialsModal from '../../components/Modals/PreviouslySoldSerialsModal/PreviouslySoldSerialsModal'
import Dropdown from '../../components/UI/Dropdown/Dropdown'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

const ManageStockGroupPage = () => {
	// const [statusFilter, setStatusFilter] = useState('')
	// const [lastDates, setLastDates] = useState('')
	const [searchValue, setSearchValue] = useState('')
	// const [fromDate, setFromDate] = useState<Date | null>(null)
	// const [toDate, setToDate] = useState<Date | null>(null)
	const [totalItems, setTotalItems] = useState(23)
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [stock, setStock] = useState('')
	const [removePreviouslySoldSerials, setRemovePreviouslySoldSerials] = useState(false)
	const [isAddToStockModalActive, setIsAddToStockModalActive] = useState(false)
	const [isAddToStockModalVisible, setIsAddToStockModalVisible] = useState(false)
	const [isBulkDeleteStockModalActive, setIsBulkDeleteStockModalActive] = useState(false)
	const [isBulkDeleteStockModalVisible, setIsBulkDeleteStockModalVisible] = useState(false)
	const [isPreviouslySoldSerialsModalActive, setIsPreviouslySoldSerialsModalActive] =
		useState(false)
	const [isPreviouslySoldSerialsModalVisible, setIsPreviouslySoldSerialsModalVisible] =
		useState(false)
	const [delimiter, setDelimiter] = useState('')
	const [groupName, setGroupName] = useState('')
	const [customDelimiter, setCustomDelimiter] = useState('')
	const [lowStockTrigger, setLowStockTrigger] = useState(0)
	const [outOfStockTrigger, setOutOfStockTrigger] = useState(0)
	const { shop } = useAppContext()
	const params = useParams()
	const stockGroupId = params.id
	const navigate = useNavigate()
	const [updatedDate, setUpdatedDate] = useState<Date | null>(null)
	const [selectedStockEntries, setSelectedStockEntries] = useState<string[]>([])
	const [allStockEntriesChecked, setAllStockEntriesChecked] = useState(false)
	const isAdmin =
		shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isStockSystemDeleteAvailable =
		shop?.permissions.includes(ShopForUserPermissions.STOCK_SYSTEMS_DELETE) || isAdmin
	usePageTitle(`Manage Stock Group`)

	const [isAddStockLoading, addStockResponse, addStock] = useAPI<AddStockResponse>(
		() =>
			StockSystemService.addWarehouseStock(shop?.id || '', Number(stockGroupId), {
				serials: stock.split(stockDelimiterHandler(delimiter, false, customDelimiter)),
				removePreviouslySoldSerials: removePreviouslySoldSerials,
				defaultDelimiter: stockDelimiterHandler(delimiter, false, customDelimiter),
			}),
		false,
		'Stock successfully added',
		{
			onSuccess: response => {
				setIsAddToStockModalActive(false)
				getStocks()

				if (response.data.data.previouslySoldSerials.length) {
					setIsPreviouslySoldSerialsModalActive(true)
				}
			},
		}
	)

	const [isGetStocksLoading, stocks, getStocks] = useAPI<Stock[]>(
		() =>
			StockSystemService.getWarehouseStock(
				shop?.id || '',
				Number(stockGroupId),
				searchValue,
				currentPage + 1,
				pageSize
			),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	const [isDeleteStocksLoading, , deleteStocks] = useAPI(
		() =>
			StockSystemService.deleteStocks(shop?.id || '', Number(stockGroupId), {
				serialIds: allStockEntriesChecked ? null : selectedStockEntries,
			}),
		false,
		'Stock entries successfully deleted',
		{
			onSuccess: () => {
				closeModal(setIsBulkDeleteStockModalActive, setIsBulkDeleteStockModalVisible)
				getStocks()
			},
		}
	)

	useDebounce(searchValue, getStocks, 400)

	const [isGetWarehouseLoading, warehouse, getWarehouse] = useAPI<StockGroupDetailed>(
		() => StockSystemService.getWarehouse(shop?.id || '', Number(stockGroupId)),
		false,
		undefined,
		{
			onSuccess: response => {
				setGroupName(response.data.data.name)
				setOutOfStockTrigger(response.data.data.outOfStockTrigger)
				setLowStockTrigger(response.data.data.lowStockTrigger)
				setUpdatedDate(new Date())
			},
		}
	)

	const [isUpdateWarehouseLoading, , updateWarehouse] = useAPI(
		() =>
			StockSystemService.updateWarehouse(shop?.id || '', Number(stockGroupId), {
				name: groupName,
				lowStockTrigger,
				outOfStockTrigger,
				description: '',
			}),
		false,
		'Warehouse successfully updated',
		{ onSuccess: getWarehouse }
	)

	const onConfirmAddToStockHandler = () => {
		addStock()
	}

	useEffect(() => {
		if (shop) {
			getWarehouse()
		}
	}, [shop])

	useEffect(() => {
		if (shop) {
			getStocks()
		}
	}, [currentPage, pageSize, shop])

	useEffect(() => {
		if (stocks && warehouse) {
			const delimiterType = stockDelimiterHandler(warehouse?.defaultDelimiter, true)
			setDelimiter(delimiterType)
			if (delimiterType === 'Custom') {
				setCustomDelimiter(warehouse?.defaultDelimiter)
			}
		}
	}, [stocks, warehouse])

	if (isGetWarehouseLoading) {
		return <Spinner />
	}

	return (
		<PageContainer>
			{isAddToStockModalActive && (
				<Modal
					closeModal={() => closeModal(setIsAddToStockModalActive, setIsAddToStockModalVisible)}
					visible={isAddToStockModalVisible}
					setVisible={setIsAddToStockModalVisible}
				>
					<ConfirmModal
						title={'Add Stock'}
						description={`You’re about to add ${
							stock.split(stockDelimiterHandler(delimiter, false, customDelimiter)).length
						} stock entries. Are you sure you want to add these entries?`}
						onConfirm={onConfirmAddToStockHandler}
						onClose={() => closeModal(setIsAddToStockModalActive, setIsAddToStockModalVisible)}
						isButtonLoading={isAddStockLoading}
						buttonIcon={{
							id: 'plus',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						confirmButtonText={'Add Stock Entry'}
					/>
				</Modal>
			)}

			{isBulkDeleteStockModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsBulkDeleteStockModalActive, setIsBulkDeleteStockModalVisible)
					}
					visible={isBulkDeleteStockModalVisible}
					setVisible={setIsBulkDeleteStockModalVisible}
				>
					<ConfirmModal
						title={'Delete Stock Entries'}
						description={`You’re about to delete ${
							allStockEntriesChecked ? totalItems : selectedStockEntries.length
						} stock entries. Are you sure you want to delete these entries? This cannot be undone.`}
						onConfirm={deleteStocks}
						onClose={() =>
							closeModal(setIsBulkDeleteStockModalActive, setIsBulkDeleteStockModalVisible)
						}
						isButtonLoading={isDeleteStocksLoading}
						buttonIcon={{
							id: 'trashBin',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						confirmButtonText={'Delete Stock Entries'}
					/>
				</Modal>
			)}

			{isPreviouslySoldSerialsModalActive && (
				<Modal
					closeModal={() =>
						closeModal(
							setIsPreviouslySoldSerialsModalActive,
							setIsPreviouslySoldSerialsModalVisible
						)
					}
					visible={isPreviouslySoldSerialsModalVisible}
					setVisible={setIsPreviouslySoldSerialsModalVisible}
				>
					<PreviouslySoldSerialsModal
						previouslySoldSerials={addStockResponse?.previouslySoldSerials || []}
						isRemoved={removePreviouslySoldSerials}
						onClose={() =>
							closeModal(
								setIsPreviouslySoldSerialsModalActive,
								setIsPreviouslySoldSerialsModalVisible
							)
						}
					/>
				</Modal>
			)}
			<PageWrapper>
				<div className={styles.manageStockGroup}>
					<PageHeader
						title={`${warehouse?.name} (ID: ${stockGroupId})`}
						backButton={{ onClick: () => navigate('/products/warehouses'), isActive: true }}
						isRow={true}
					>
						<Button
							style={ButtonStyles.SECONDARY}
							icon={{
								id: 'pen',
								width: 13,
								height: 13,
								align: ButtonsIconAlign.LEFT,
							}}
							onClick={() => navigate(`/products/warehouse/stock-groups/edit/${stockGroupId}`)}
						>
							Edit stock
						</Button>
					</PageHeader>

					<div className={styles.miniStats}>
						<MiniStat
							title={'Available Stock'}
							value={warehouse?.stock}
							updatedDate={updatedDate}
						/>
						<MiniStat
							title={'Last Restock'}
							value={
								warehouse?.lastUpdated && format(new Date(warehouse?.lastUpdated), 'MMM dd, yyyy')
							}
							updatedDate={updatedDate}
						/>

						{/*<div className={styles.miniStatsItem}>*/}
						{/*	<h2 className={styles.miniStatsItemTitle}>Total Stock</h2>*/}
						{/*	<p className={styles.miniStatsItemValue}>{warehouse?.stock}</p>*/}
						{/*</div>*/}

						{/*<div className={styles.miniStatsItem}>*/}
						{/*	<h2 className={styles.miniStatsItemTitle}>Avg. Sold per Customer</h2>*/}
						{/*	<p className={styles.miniStatsItemValue}>100</p>*/}
						{/*</div>*/}
					</div>

					<section className={styles.section}>
						<div className={styles.sectionBody}>
							<div className={styles.stockSectionGrid}>
								<div className={styles.fieldWrapper}>
									<h2 className={styles.fieldTitle}>STOCK</h2>
									<div className={styles.formWrapper}>
										<StockTextarea
											value={stock}
											setValue={setStock}
											stockInDatabase={warehouse?.stock || 0}
											stockInEditor={
												stock
													? stock.split(stockDelimiterHandler(delimiter, false, customDelimiter))
															.length
													: 0
											}
										/>

										<p className={styles.fieldDescription}>
											Duplicated items are automatically removed from your stock.
										</p>
									</div>
								</div>
								<div className={styles.fieldWrapper}>
									<h2 className={styles.fieldTitle}>Delimiter</h2>

									<div className={styles.formWrapper}>
										<CustomSelect
											style={SelectStyles.secondary}
											value={delimiter}
											setValue={setDelimiter}
											options={[
												{ label: 'Comma', value: 'Comma' },
												{ label: 'New Line', value: 'NewLine' },
												{ label: 'Custom', value: 'Custom' },
											]}
										/>
									</div>
									<p className={styles.fieldDescription}>
										Separates the serials in your stock group.
									</p>
									{delimiter === 'Custom' && (
										<div className={styles.formWrapper}>
											<Input
												value={customDelimiter}
												setValue={setCustomDelimiter}
												placeholder={'Custom delimiter'}
											/>
										</div>
									)}

									<div className={styles.buttons}>
										<Button
											style={ButtonStyles.BORDERED}
											icon={{
												id: 'plus',
												width: 13,
												height: 13,
												align: ButtonsIconAlign.LEFT,
											}}
											disableShadow={true}
											onClick={() => setIsAddToStockModalActive(true)}
										>
											Add to Stock
										</Button>
									</div>
									<div className={styles.switchWrapper}>
										<Switch
											checked={removePreviouslySoldSerials}
											setChecked={setRemovePreviouslySoldSerials}
										/>
										<p>Remove previously sold serials</p>
									</div>
								</div>
							</div>
						</div>
					</section>

					<div className={styles.filtersGrid}>
						<SearchInput
							style={SearchInputStyles.secondary}
							value={searchValue}
							setValue={setSearchValue}
							placeholder={'Search for a stock entry'}
						/>
						{/*<Button*/}
						{/*	style={ButtonStyles.SECONDARY}*/}
						{/*	icon={{*/}
						{/*		id: 'download',*/}
						{/*		width: 14,*/}
						{/*		height: 14,*/}
						{/*		align: ButtonsIconAlign.LEFT,*/}
						{/*	}}*/}
						{/*	onClick={() => navigate('create')}*/}
						{/*>*/}
						{/*	Export CSV*/}
						{/*</Button>*/}
					</div>

					{selectedStockEntries.length > 0 && (
						<div className={styles.managementOptions}>
							<p className={styles.selectedInfo}>
								{allStockEntriesChecked ? totalItems : selectedStockEntries.length} serial
								{selectedStockEntries.length > 1 ? 's' : ''} selected
							</p>
							<div className={styles.option}>
								<Button
									style={ButtonStyles.LIGHT}
									icon={{
										id: 'opposedArrows',
										width: 14,
										height: 14,
										align: ButtonsIconAlign.LEFT,
									}}
									onClick={() => setAllStockEntriesChecked(true)}
								>
									Select All
								</Button>
							</div>
							<div className={styles.option}>
								<Button
									style={ButtonStyles.LIGHT}
									icon={{
										id: 'facingArrows',
										width: 14,
										height: 14,
										align: ButtonsIconAlign.LEFT,
									}}
									onClick={() => setAllStockEntriesChecked(false)}
								>
									Deselect
								</Button>
							</div>
							{isStockSystemDeleteAvailable && (
								<div className={styles.option}>
									<Button
										style={ButtonStyles.SECONDARY}
										icon={{
											id: 'trashBin',
											width: 12,
											height: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										onClick={() => setIsBulkDeleteStockModalActive(true)}
									>
										Delete
									</Button>
								</div>
							)}
						</div>
					)}

					<PageTableHeader
						listItemNamePlural={'stock entries'}
						totalItems={totalItems}
						pageSize={pageSize}
						setPageSize={setPageSize}
						setCurrentPage={setCurrentPage}
					/>

					{isGetStocksLoading ? (
						<Spinner />
					) : (
						stocks && (
							<div className={styles.warehouseTable}>
								<StockEntriesTable
									stocks={stocks}
									setSelectedStockEntries={setSelectedStockEntries}
									selectedStockEntries={selectedStockEntries}
									allStockEntriesChecked={allStockEntriesChecked}
									setAllStockEntriesChecked={setAllStockEntriesChecked}
								/>
							</div>
						)
					)}

					<div className={styles.paginationWrapper}>
						<Pagination
							withoutPageSizeSelector={true}
							totalItems={totalItems}
							pageSize={pageSize}
							setPageSize={setPageSize}
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
						/>
					</div>

					<div className={styles.section}>
						<header className={styles.sectionHeader}>
							<h1 className={styles.sectionTitle}>Stock Group Settings</h1>
						</header>
						<div className={styles.sectionBody}>
							<div className={styles.sectionGrid}>
								<div className={styles.fieldWrapper}>
									<h2 className={styles.fieldTitle}>Low Stock Alert</h2>
									<div className={styles.formWrapper}>
										<StockAlertInput
											value={lowStockTrigger}
											setValue={setLowStockTrigger}
											warehouseStatus={WarehouseStatus.LOW_STOCK}
										/>
									</div>
									<p className={styles.fieldDescription}>
										If stock is less than the amount above, set status as “Low Stock”.
									</p>
								</div>
								<div className={styles.fieldWrapper}>
									<h2 className={styles.fieldTitle}>Out of Stock Alert</h2>

									<div className={styles.formWrapper}>
										<StockAlertInput
											value={outOfStockTrigger}
											setValue={setOutOfStockTrigger}
											warehouseStatus={WarehouseStatus.OUT_OF_STOCK}
										/>
									</div>
									<p className={styles.fieldDescription}>
										If stock is less than the amount above, set status as “Out of Stock”.
									</p>
								</div>
							</div>
							<div className={styles.mtMediumLarge}>
								<div className={styles.fieldWrapper}>
									<h2 className={styles.fieldTitle}>STOCK GROUP NAME</h2>
									<div className={styles.formWrapper}>
										<Input
											value={groupName}
											setValue={setGroupName}
											placeholder={'Demo Stock Group'}
										/>
									</div>
								</div>
							</div>
							<div className={styles.mtMediumLarge}>
								<Button
									style={ButtonStyles.BORDERED}
									disableShadow={true}
									icon={{
										id: 'download',
										width: 13,
										height: 13,
										align: ButtonsIconAlign.LEFT,
									}}
									onClick={updateWarehouse}
									isLoading={isUpdateWarehouseLoading}
								>
									Save Settings
								</Button>
							</div>
						</div>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default ManageStockGroupPage
