import React, { FC, useEffect } from 'react'
import { SetupParameter } from '../../../types/Plugin/ExplorerPlugin'
import { CustomFieldType } from '../../../types/CustomFieldType/CustomFieldType'
import Textarea from '../FormComponents/Textarea'
import Checkbox from '../Checkbox/Checkbox'
import Input from '../FormComponents/Input'
import styles from './SetupField.module.scss'
import AlertBadge from '../AlertBadge/AlertBadge'
import { Alert } from '../../../types/Alert/Alert'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

interface SetupFieldProps {
	setupParameter: SetupParameter
	setValue: (value: string | number | boolean) => void
	value: string | number | boolean
	errorMessage?: string
	setErrors?: (errors: FormErrors) => void
	errors?: FormErrors
	withoutTitle?: boolean
}

const SetupField: FC<SetupFieldProps> = ({
	setupParameter,
	value,
	setValue,
	errorMessage,
	withoutTitle,
	setErrors,
	errors,
}) => {
	const onBlur = (event: React.FocusEvent<any>) => {
		if (setErrors) {
			!setupParameter.isReadOnly &&
				setupParameter.isRequired &&
				setErrors({
					...errors,
					[setupParameter.variableName]: fieldValidators.blankValidator(
						setupParameter.name,
						event.target.value
					),
				})
		}
	}

	const setValueHandler = (value: any) => {
		if (!setupParameter.isReadOnly) {
			setValue(value)
		}
	}

	const defineField = () => {
		switch (setupParameter.type) {
			case CustomFieldType.LARGE_TEXT:
				return (
					<Textarea
						value={value as string}
						setValue={setValueHandler}
						placeholder={setupParameter.placeholder}
						errorMessage={errorMessage}
						onBlur={onBlur}
					/>
				)
			case CustomFieldType.CHECK_BOX:
				return (
					<Checkbox
						id={setupParameter.name}
						checked={value as boolean}
						setChecked={setValueHandler}
					/>
				)
			case CustomFieldType.NUMBER:
				return (
					<Input
						type={'number'}
						value={value as string}
						setValue={setValueHandler}
						placeholder={setupParameter.placeholder}
						errorMessage={errorMessage}
						onBlur={onBlur}
					/>
				)
			case CustomFieldType.TEXT:
				return (
					<Input
						value={value as string}
						setValue={setValueHandler}
						placeholder={setupParameter.placeholder}
						errorMessage={errorMessage}
						onBlur={onBlur}
					/>
				)
			case CustomFieldType.HIDDEN:
				return (
					<Input
						type={'hidden'}
						value={value as string}
						setValue={setValueHandler}
						placeholder={setupParameter.placeholder}
						errorMessage={errorMessage}
					/>
				)
		}
	}

	useEffect(() => {
		if (setupParameter.defaultValue && !value) {
			setValue(setupParameter.defaultValue)
		}
	}, [])

	return (
		<div className={`${styles.fieldWrapper} ${withoutTitle && styles.withoutTitle}`}>
			{!withoutTitle && (
				<p className={styles.fieldTitle}>
					{setupParameter.name}{' '}
					{setupParameter.guideUrl && (
						<SpriteIcon
							className={styles.gray}
							onClick={() => window.open(setupParameter.guideUrl, '_blank', 'noopener')}
							iconId={'blankWindow'}
							width={10}
							height={10}
						/>
					)}
				</p>
			)}
			<div className={styles.field}>{defineField()}</div>
		</div>
	)
}

export default SetupField
