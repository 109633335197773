import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import PageLoading from '../UI/PageLoading/PageLoading'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import { useAppContext } from '../../hooks/useAppContext'
import ShopStatusBlock from '../ShopStatusBlock/ShopStatusBlock'

interface PageWrapperProps {
	children: ReactNode | ReactNode[],
	isNotRequired?: boolean
}




const PageWrapper: FC<PageWrapperProps> = ({ children, isNotRequired }) => {
	const {shop} = useAppContext();

	if (shop?.status === 'LIVE' || isNotRequired) {
		return <div>{children}</div>
	} else {
		return <ShopStatusBlock/>
	}
}

export default PageWrapper
