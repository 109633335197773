import React, { useEffect, useState } from 'react'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import generalStyles from '../Settings.module.scss'
import styles from './SettingsDomainsPage.module.scss'
import AlertBadge from '../../../components/UI/AlertBadge/AlertBadge'
import { Alert } from '../../../types/Alert/Alert'
import { SpriteIcon } from '../../../components/UI/SpriteIcon/SpriteIcon'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../components/UI/Button/Button'
import DomainDNSRecordForm from './DomainDNSRecordForm'
import DomainTXTRecordForm from './DomainTXTRecordForm'
import DomainCNAMERecordForm from './DomainCNAMERecordForm'
import StatusAlertBadge from '../../../components/UI/StatusAlertBadge/StatusAlertBadge'
import { StatusAlert } from '../../../types/StatusAlert/StatusAlert'
import { useAPI } from '../../../hooks/useAPI'
import { DomainsService } from '../../../API/DomainsService'
import { useAppContext } from '../../../hooks/useAppContext'
import Modal from '../../../components/Modals/Modal'
import ConnectDomainModal from '../../../components/Modals/ConnectDomainModal/ConnectDomainModal'
import { closeModal } from '../../../helpers/closeModal'
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent'
import PageLoading from '../../../components/UI/PageLoading/PageLoading'
import { Domain } from '../../../types/Domain/Domain'
import DomainStatusBadge from '../../../components/UI/StatusBadges/DomainStatusBadge'
import ConfirmModal from '../../../components/Modals/ConfirmModal/ConfirmModal'
import { DomainStatus } from '../../../types/DomainStatus/DomainStatus'
import { format } from 'date-fns'
import { Subscriptions } from '../../../types/Subscriptions/Subscriptions'
import { ShopForUserPermissions } from '../../../types/ShopForUserPermissions/ShopForUserPermissions'
import pageNotFoundImage from '../../../assets/images/404.png'
import Switch from '../../../components/UI/Switch/Switch'

enum domainConnectingSteps {
	DNS,
	TXT,
	CNAME,
	VERIFY,
}

const SettingsDomainsPage = () => {
	const [currentStep, setCurrentStep] = useState(domainConnectingSteps.DNS)
	const [isAddModalActive, setIsAddModalActive] = useState(false)
	const [isAddModalVisible, setIsAddModalVisible] = useState(false)
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
	const [isDeleteModalActive, setIsDeleteModalActive] = useState(false)
	const [isShortenedEnabled, setIsShortenedEnabled] = useState(false)
	const { shop, user, setIsSubscriptionModalActive, setRequiredSubscription, shopDomain } =
		useAppContext()
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isSettingsGeneralViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.SETTINGS_GENERAL_VIEW) || isAdmin
	const isSettingsGeneralModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.SETTINGS_GENERAL_MODIFY) || isAdmin

	const [isGetDomainsLoading, domains, getDomains] = useAPI<Domain[]>(
		() => DomainsService.getAll(shop?.id || ''),
		false
	)

	const customDomain = domains?.find(domain => domain.type === 'CUSTOM_DOMAIN')
	const shortenDomain = domains?.find(domain => domain.type === 'SHORTENED_DOMAIN')

	const isDomainActive = customDomain && customDomain.status === DomainStatus.ACTIVE

	const [isDeleteDomainLoading, , deleteDomain] = useAPI(
		() => DomainsService.delete(shop?.id || '', customDomain ? customDomain.id : 0),
		false,
		'Domain successfully deleted',
		{
			onSuccess: () => {
				getDomains()
				closeDeleteModal()
			},
		}
	)

	const [isUpdateShortenedLoading, , updateShortened] = useAPI(
		() => DomainsService.updateShortened(shop?.id || ''),
		false,
		'Settings successfully saved',
		{
			onSuccess: () => {
				getDomains()
			},
		}
	)

	const [isCheckRecordsLoading, , checkRecords] = useAPI(
		() => DomainsService.recheck(shop?.id || '', customDomain ? customDomain.id : 0),
		false,
		undefined,
		{
			onSuccess: getDomains,
		}
	)

	const closeDeleteModal = () => {
		closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)
	}

	const onNewDomainClickHandler = () => {
		if (user?.subscription.perks.customDomainsLimit) {
			setIsAddModalActive(true)
		} else {
			setIsSubscriptionModalActive(true)
			setRequiredSubscription(Subscriptions.BUSINESS)
		}
	}

	useEffect(() => {
		if (shop && isSettingsGeneralViewAvailable) {
			getDomains()
		}
	}, [shop])

	useEffect(() => {
		if (domains) {
			setIsShortenedEnabled(!!shortenDomain)
		}
	}, [domains])

	if (isGetDomainsLoading) return <PageLoading />

	if (!isSettingsGeneralViewAvailable)
		return (
			<NoDataComponent
				isSmallHeight={true}
				imageSrc={pageNotFoundImage}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)

	return (
		<PageWrapper>
			{isAddModalActive && (
				<Modal
					closeModal={() => closeModal(setIsAddModalActive, setIsAddModalVisible)}
					visible={isAddModalVisible}
					setVisible={setIsAddModalVisible}
				>
					<ConnectDomainModal
						onClose={() => closeModal(setIsAddModalActive, setIsAddModalVisible)}
						getDomains={getDomains}
					/>
				</Modal>
			)}
			{isDeleteModalActive && (
				<Modal
					closeModal={closeDeleteModal}
					visible={isDeleteModalVisible}
					setVisible={setIsDeleteModalVisible}
				>
					<ConfirmModal
						onClose={closeDeleteModal}
						onConfirm={deleteDomain}
						isButtonLoading={isDeleteDomainLoading}
						title={'Delete Domain'}
						description={`You are about to delete ${
							customDomain && customDomain.hostname
						} domain. Are you sure you want to delete this domain? This cannot be undone.`}
						confirmButtonText={'Delete Domain'}
						buttonIcon={{
							id: 'trashBin',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
					/>
				</Modal>
			)}
			<div className={generalStyles.settingsPageInnerBody}>
				<div className={generalStyles.settingSection}>
					<div className={generalStyles.settingSectionBody}>
						<div className={generalStyles.fieldWrapper}>
							<header className={generalStyles.fieldWrapperHeader}>
								<div>
									<p className={generalStyles.fieldWrapperTitle}>Enable Shortened Link</p>
									<p className={`${generalStyles.fieldWrapperDescription} `}>
										This add-on will allow you to use{' '}
										<a
											className={generalStyles.anchorLink}
											href={shopDomain.replace('billgang.store', 'bgng.io/')}
											target={'_blank'}
										>
											{shopDomain.replace('billgang.store', 'bgng.io/')}
										</a>{' '}
										as a short hyperlink to your storefront.
									</p>
								</div>
								<Switch checked={isShortenedEnabled} setChecked={setIsShortenedEnabled} />
							</header>

							{!!shortenDomain !== isShortenedEnabled && (
								<div className={generalStyles.mtMediumLarge}>
									<Button
										style={ButtonStyles.BORDERED}
										icon={{
											id: 'download',
											width: 13,
											height: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										disableShadow={true}
										onClick={updateShortened}
										isLoading={isUpdateShortenedLoading}
									>
										Save Settings
									</Button>
								</div>
							)}
						</div>
					</div>
				</div>
				{customDomain ? (
					<div className={styles.connectingDomainSection}>
						<header className={styles.connectingDomainSectionHeader}>
							<h2 className={styles.connectingDomainSectionTitle}>
								{isDomainActive ? 'Custom Domain' : customDomain.hostname}
							</h2>
							{!isDomainActive && (
								<div className={styles.connectingDomainSectionHeaderEnd}>
									<DomainStatusBadge domainStatus={customDomain.status} />
									<SpriteIcon
										iconId={'trashBin'}
										width={12}
										height={13}
										onClick={() => setIsDeleteModalActive(true)}
									/>
								</div>
							)}
						</header>

						{!isDomainActive ? (
							<div className={styles.connectingDomainSectionBody}>
								<AlertBadge alert={Alert.INFORMATION} disableShadow={true}>
									<p>
										Follow this{' '}
										<a
											href='https://support.billgang.com/en/articles/8915889-how-do-i-use-a-custom-domain-for-my-store'
											target={'_blank'}
										>
											help article
										</a>{' '}
										to connect your custom domain to Billgang.
									</p>
								</AlertBadge>

								<div className={styles.connectionDomainSteps}>
									<div className={styles.connectionDomainStep}>
										<div className={`${styles.connectionDomainStepStatus} ${styles.done}`}>
											<SpriteIcon iconId={'checked'} width={13} height={13} />
										</div>
										<h2 className={styles.connectionDomainPathName}>Domain</h2>
									</div>
									<SpriteIcon
										className={styles.arrowNext}
										iconId={'arrowHead'}
										width={14}
										height={14}
									/>
									<div className={styles.connectionDomainStep}>
										<div
											className={`${styles.connectionDomainStepStatus} ${
												currentStep === domainConnectingSteps.DNS && styles.active
											} ${currentStep > domainConnectingSteps.DNS && styles.done} `}
										>
											<p>2</p>
											<SpriteIcon iconId={'checked'} width={13} height={13} />
										</div>
										<h2 className={styles.connectionDomainPathName}>DNS Record</h2>
									</div>
									<SpriteIcon
										className={styles.arrowNext}
										iconId={'arrowHead'}
										width={14}
										height={14}
									/>

									<div className={styles.connectionDomainStep}>
										<div
											className={`${styles.connectionDomainStepStatus} ${
												currentStep === domainConnectingSteps.TXT && styles.active
											} ${currentStep > domainConnectingSteps.TXT && styles.done} `}
										>
											<p>3</p>
											<SpriteIcon iconId={'checked'} width={13} height={13} />
										</div>
										<h2 className={styles.connectionDomainPathName}>TXT Record</h2>
									</div>
									<SpriteIcon
										className={styles.arrowNext}
										iconId={'arrowHead'}
										width={14}
										height={14}
									/>

									<div className={styles.connectionDomainStep}>
										<div
											className={`${styles.connectionDomainStepStatus} ${
												currentStep === domainConnectingSteps.CNAME && styles.active
											} ${currentStep > domainConnectingSteps.CNAME && styles.done} `}
										>
											<p>4</p>
											<SpriteIcon iconId={'checked'} width={13} height={13} />
										</div>
										<h2 className={styles.connectionDomainPathName}>CNAME Record</h2>
									</div>
									<SpriteIcon
										className={styles.arrowNext}
										iconId={'arrowHead'}
										width={14}
										height={14}
									/>

									<div className={styles.connectionDomainStep}>
										<div
											className={`${styles.connectionDomainStepStatus} ${
												currentStep === domainConnectingSteps.VERIFY && styles.active
											} ${currentStep > domainConnectingSteps.VERIFY && styles.done} `}
										>
											<p>5</p>
											<SpriteIcon iconId={'checked'} width={13} height={13} />
										</div>
										<h2 className={styles.connectionDomainPathName}>Verify Domain</h2>
									</div>
								</div>

								{currentStep === domainConnectingSteps.DNS && (
									<DomainDNSRecordForm domain={customDomain} />
								)}
								{currentStep === domainConnectingSteps.TXT && (
									<DomainTXTRecordForm domain={customDomain} />
								)}
								{currentStep === domainConnectingSteps.CNAME && (
									<DomainCNAMERecordForm domain={customDomain} />
								)}
								{currentStep === domainConnectingSteps.VERIFY && (
									<div className={generalStyles.mtMediumLarge}>
										<StatusAlertBadge
											title={'Records Not Detected'}
											description={
												'Depending on your DNS provider, changes to DNS records can take several hours to propagate and take effect across the internet. You can close this page for now and check again later.'
											}
											statusAlertType={StatusAlert.WARNING}
											button={{
												text: 'Check Records',
												icon: {
													id: 'refresh',
													width: 13,
													height: 13,
													align: ButtonsIconAlign.LEFT,
												},
												onClick: checkRecords,
												isLoading: isCheckRecordsLoading,
											}}
										/>
									</div>
								)}

								<div className={styles.buttons}>
									<Button
										style={ButtonStyles.BORDERED}
										disableShadow={true}
										icon={{
											id: 'arrowLeft',
											width: 13,
											height: 12,
											align: ButtonsIconAlign.LEFT,
										}}
										onClick={() =>
											currentStep === domainConnectingSteps.DNS
												? setIsAddModalActive(true)
												: setCurrentStep(prevState => prevState - 1)
										}
									>
										Back
									</Button>
									{currentStep !== domainConnectingSteps.VERIFY && (
										<Button
											style={ButtonStyles.BORDERED}
											width={'100%'}
											disableShadow={true}
											icon={{
												id: 'arrowRight',
												width: 13,
												height: 12,
												align: ButtonsIconAlign.RIGHT,
											}}
											onClick={() => setCurrentStep(prevState => prevState + 1)}
										>
											Next
										</Button>
									)}
								</div>
							</div>
						) : (
							<div className={styles.domain}>
								<div>
									<p className={styles.domainTitle}>{customDomain.hostname}</p>
									<p className={styles.domainSubtitle}>
										Domain successfully connected on{' '}
										{format(new Date(customDomain.createdAt), 'MMMM dd, yyyy')}.
									</p>
								</div>
								<div className={styles.domainEnd}>
									<DomainStatusBadge domainStatus={customDomain.status} />
									<SpriteIcon
										iconId={'trashBin'}
										width={12}
										height={13}
										onClick={() => setIsDeleteModalActive(true)}
									/>
								</div>
							</div>
						)}
					</div>
				) : (
					<div className={generalStyles.settingSection}>
						<div className={generalStyles.settingSectionBody}>
							<div className={generalStyles.fieldWrapper}>
								<header className={generalStyles.fieldWrapperHeader}>
									<div>
										<p className={generalStyles.fieldWrapperTitle}>No Domain Connected</p>
										<p className={`${generalStyles.fieldWrapperDescription} `}>
											You don’t have a domain connected. Tap on the button below to connect your own
											domain.
										</p>
									</div>
									<Button
										icon={{
											id: 'plus',
											height: 13,
											width: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										style={ButtonStyles.BORDERED}
										disableShadow={true}
										onClick={onNewDomainClickHandler}
									>
										New Domain
									</Button>
								</header>
							</div>
						</div>
					</div>
				)}
			</div>
		</PageWrapper>
	)
}

export default SettingsDomainsPage
