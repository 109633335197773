import React, { FC, useEffect, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import Spinner from '../../UI/Spinner/Spinner'
import { useAPI } from '../../../hooks/useAPI'
import { DiscordUserService } from '../../../API/DiscordUserService'
import ConnectDiscord from '../../ConnectDiscord/ConnectDiscord'
import CustomSelect, { SelectStyles } from '../../UI/CustomSelect/CustomSelect'
import CustomMultiSelect from '../../UI/CustomMultiSelect/CustomMultiSelect'
import { DiscordGuild } from '../../../types/DiscordGuild/DiscordGuild'
import { useAppContext } from '../../../hooks/useAppContext'
import { DiscordGuildRole } from '../../../types/DiscordGuildRole/DiscordGuildRole'
import { MultiValue } from 'react-select'

interface ChooseProductTypeModalDiscordProps {
	discordGuild: string
	setDiscordGuild: (value: string) => void
	setDiscordRoles: (value: string[]) => void
	discordRoles: string[]
	editId?: string | null
}

const ChooseProductTypeModalDiscord: FC<ChooseProductTypeModalDiscordProps> = ({
	discordGuild,
	setDiscordRoles,
	discordRoles,
	setDiscordGuild,
	editId,
}) => {
	const [isDiscordConnected, setIsDiscordConnected] = useState(false)
	const [isBotAvailable, setIsBotAvailable] = useState(false)
	const { shop } = useAppContext()
	const [isEditingGuildRolesSet, setIsEditingGuildRolesSet] = useState(false)
	const [currentClientId, setCurrentClientId] = useState<string | null>(null)

	const [isGetGuildsLoading, guilds, getGuilds] = useAPI<DiscordGuild[]>(
		() => DiscordUserService.getGuilds(),
		false,
		undefined,
		{
			onError: error => {
				if (error.response?.status === 400) {
					setIsDiscordConnected(false)
				}
			},
			onSuccess: () => {
				setIsDiscordConnected(true)
			},
		}
	)

	const [isGetGuildLoading, guild, getGuild] = useAPI<{ roles: DiscordGuildRole[] }>(
		() => DiscordUserService.getGuild(discordGuild, shop?.id || ''),
		false,
		undefined,
		{
			onError: error => {
				if (error.response?.status === 404) {
					setIsBotAvailable(false)
					const data = error.response.data as {
						data: {
							applicationId: string
							id: number
						}
					}
					setCurrentClientId(data?.data.applicationId || null)
				}
			},
			onSuccess: () => {
				setIsBotAvailable(true)
			},
		}
	)

	useEffect(() => {
		getGuilds()
	}, [])

	useEffect(() => {
		if (discordGuild) {
			getGuild()
		}
	}, [discordGuild])

	useEffect(() => {
		if (discordRoles && editId && guild && !isEditingGuildRolesSet) {
			setIsEditingGuildRolesSet(true)
		}
	}, [guild])

	if (isGetGuildsLoading) return <Spinner />

	return (
		<div className={styles.fieldWrapper}>
			<header>
				<span>
					Sell custom access to your Discord community or grant exclusive roles with this purchase.
				</span>
			</header>
			{isDiscordConnected ? (
				<div className={`${styles.doubleFields} ${styles.itemsEnd}`}>
					<div className={styles.fieldWrapper}>
						<p className={styles.fieldTitle}>Choose Server</p>
						<div className={styles.mt10}>
							<CustomSelect
								style={SelectStyles.secondary}
								value={discordGuild}
								setValue={value => {
									setDiscordRoles([])
									setDiscordGuild(value)
								}}
								options={[
									{
										value: '',
										label: 'Choose server',
									},
									...(guilds?.map(guild => ({ value: guild.id, label: guild.name })) || []),
								]}
							/>
						</div>
					</div>
					<div className={`${styles.fieldWrapper} `}>
						{!isBotAvailable ? (
							<Button
								style={ButtonStyles.THIRD}
								icon={{
									id: 'discord',
									width: 12,
									height: 12,
									align: ButtonsIconAlign.LEFT,
								}}
								disableShadow={true}
								width={'100%'}
								onClick={() =>
									window.open(
										`https://discord.com/api/oauth2/authorize?client_id=${currentClientId}&permissions=8&scope=bot%20applications.commands`,
										'_blank',
										'noopener'
									)
								}
							>
								Invite Discord Bot
							</Button>
						) : (
							<>
								<p className={styles.fieldTitle}>Required Role(s)</p>
								<div className={styles.mt10}>
									<CustomMultiSelect
										options={guild?.roles.map(role => ({ value: role.id, label: role.name })) || []}
										value={discordRoles}
										setValue={setDiscordRoles}
									/>
								</div>
							</>
						)}
					</div>
				</div>
			) : (
				<ConnectDiscord />
			)}
		</div>
	)
}

export default ChooseProductTypeModalDiscord
