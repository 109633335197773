import React, { FC }  from 'react'
import styles from './ShopStatusBsdge.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { StatusMap } from '../../types/Status/Status';

interface ShopStatusBadgeProps {
	statusKey: string | undefined
}

const ShopStatusBadge: FC<ShopStatusBadgeProps> = ({ statusKey }) => {
	const status = StatusMap[statusKey as keyof typeof StatusMap]
	const statusClassName = styles?.[status?.name] || ''
	const statusTitle = status?.title || ''

	return <div className={`${styles.shopStatusBadge} ${statusClassName}`}>
		<SpriteIcon iconId={status?.icon} width={16} height={16} withBox/>
		<span>{statusTitle}</span>
	</div>
}

export default ShopStatusBadge