import React, { FC, useRef, useState } from 'react'
import styles from './Tables.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import OrangeBadge from '../UI/OrangeBadge/OrangeBadge'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import { Webhook } from '../../types/Webhook/Webhook'
import { format } from 'date-fns'
import { ActionMenuOption } from '../../types/ActionMenuOption/ActionMenuOption'
import WebhookEventBadge from '../UI/WebhookEventBadge/WebhookEventBadge'

interface WebhookEndpointsTableProps {
	webhooks: Webhook[]
	onDelete: (id: number) => void
	isDevelopersDeleteAvailable: boolean
}
interface WebhookEndpointsTableBodyItemProps {
	webhook: Webhook
	onDelete: (id: number) => void
	isDevelopersDeleteAvailable: boolean
}

const WebhookEndpointsTableBodyItem: FC<WebhookEndpointsTableBodyItemProps> = ({
	webhook,
	onDelete,
	isDevelopersDeleteAvailable,
}) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)
	const tableBodyRef = useRef<HTMLDivElement>(null)

	const actionMenuButtons: ActionMenuOption[] = []

	isDevelopersDeleteAvailable &&
		actionMenuButtons.push({
			onClick: () => onDelete(webhook.id),
			icon: {
				id: 'trashBin',
				width: 12,
				height: 12,
			},
			title: 'Delete webhook',
		})

	return (
		<div
			className={styles.trWrapper}
			ref={tableBodyRef}
			style={{
				paddingBottom: isActionMenuActive ? +actionMenuButtons.length * 50 : 5,
			}}
		>
			<tr>
				<td className={styles.large}>{webhook.url}</td>
				<td className={styles.extraLarge}>
					<div className={styles.row}>
						{webhook.events.map(event => (
							<WebhookEventBadge event={event} key={event} />
						))}
					</div>
				</td>
				<td>
					<p>{format(new Date(webhook.createdAt), 'MMM dd, yyyy')}</p>
					<span>{format(new Date(webhook.createdAt), 'hh:mm aa z')}</span>
				</td>
				<td className={styles.small}>
					<ActionMenu
						actionButtons={actionMenuButtons}
						isActive={isActionMenuActive}
						setIsActive={setIsActionMenuActive}
					/>
				</td>
			</tr>
		</div>
	)
}

const WebhookEndpointsTable: FC<WebhookEndpointsTableProps> = ({
	webhooks,
	onDelete,
	isDevelopersDeleteAvailable,
}) => {
	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.large}>URL</th>
					<th className={styles.extraLarge}>Events</th>
					<th>Date & Time</th>
					<th className={styles.small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{webhooks.map(webhook => (
					<WebhookEndpointsTableBodyItem
						webhook={webhook}
						key={webhook.id}
						onDelete={onDelete}
						isDevelopersDeleteAvailable={isDevelopersDeleteAvailable}
					/>
				))}
			</tbody>
		</table>
	)
}

export default WebhookEndpointsTable
