import React, { useState } from 'react'
import styles from './ProductAnalyticsPage.module.scss'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import CustomSelect from '../../components/UI/CustomSelect/CustomSelect'
import DateRangePicker from '../../components/UI/DateRangePicker/DateRangePicker'
import PageHeader from '../../components/PageHeader/PageHeader'
import StatisticsItem from '../../components/StatisticsItem/StatisticsItem'

const ProductAnalyticsPage = () => {
	const [selectDate, setSelectDate] = useState('lastMonth')
	const [fromDate, setFromDate] = useState<Date | null>(null)
	const [toDate, setToDate] = useState<Date | null>(null)

	return (
		<PageWrapper>
			<PageContainer>
				<div className={styles.productAnalyticsPage}>
					<PageHeader title={'Product Analytics (351532)'}>
						<div className={styles.headerEnd}>
							<div className={styles.headerEndItem}>
								<CustomSelect
									value={selectDate}
									setValue={setSelectDate}
									options={[
										{ label: 'Last 30 days', value: 'lastMonth' },
										{ label: 'Last 7 days', value: 'lastWeek' },
										{ label: 'Last 365 days', value: 'lastYear' },
									]}
								/>
							</div>
							<div className={styles.headerEndItem}>
								<DateRangePicker
									fromDate={fromDate}
									setFromDate={setFromDate}
									toDate={toDate}
									setToDate={setToDate}
								/>
							</div>
						</div>
					</PageHeader>

					<div className={styles.statsGrid}></div>
				</div>
			</PageContainer>
		</PageWrapper>
	)
}

export default ProductAnalyticsPage
