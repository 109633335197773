import React, { useEffect, useState } from 'react'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import styles from '../Settings.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../components/UI/Button/Button'
import { useAppContext } from '../../../hooks/useAppContext'
import SearchInput, { SearchInputStyles } from '../../../components/UI/SearchInput/SearchInput'
import Pagination from '../../../components/UI/Pagination/Pagination'
import AuditLogTable from '../../../components/Tables/AuditLogTable'
import Modal from '../../../components/Modals/Modal'
import TeamMemberEventDetailsModal from '../../../components/Modals/TeamMemberEventDetailsModal/TeamMemberEventDetailsModal'
import { closeModal } from '../../../helpers/closeModal'
import { useAPI } from '../../../hooks/useAPI'
import { AuditLogsService } from '../../../API/AuditLogsService'
import { AuditLog } from '../../../types/AuditLog/AuditLog'
import Spinner from '../../../components/UI/Spinner/Spinner'
import { useDebounce } from '../../../hooks/useDebounce'

const SettingsAuditLogPage = () => {
	const [searchValue, setSearchValue] = useState('')
	const [totalItems, setTotalItems] = useState(23)
	const [currentPage, setCurrentPage] = useState(0)
	const [isViewDetailsModalActive, setIsViewDetailsModalActive] = useState(false)
	const [isViewDetailsModalVisible, setIsViewDetailsModalVisible] = useState(false)
	const [currentAuditLog, setCurrentAuditLog] = useState<AuditLog | null>(null)

	const { shop } = useAppContext()

	const [isGetAuditLogsLoading, auditLogs, getAuditLogs] = useAPI<AuditLog[]>(
		() => AuditLogsService.getAll(shop?.id || '', searchValue, currentPage + 1, 10),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	useDebounce(searchValue, getAuditLogs, 400)

	useEffect(() => {
		if (shop) {
			getAuditLogs()
		}
	}, [shop, currentPage])

	return (
		<PageWrapper>
			{isViewDetailsModalActive && (
				<Modal
					closeModal={() => closeModal(setIsViewDetailsModalActive, setIsViewDetailsModalVisible)}
					visible={isViewDetailsModalVisible}
					setVisible={setIsViewDetailsModalVisible}
				>
					<TeamMemberEventDetailsModal
						currentAuditLog={currentAuditLog}
						onClose={() => closeModal(setIsViewDetailsModalActive, setIsViewDetailsModalVisible)}
					/>
				</Modal>
			)}

			<div className={styles.settingsPageInnerBody}>
				<header className={styles.pageInnerHeader}>
					<SearchInput
						style={SearchInputStyles.secondary}
						value={searchValue}
						setValue={setSearchValue}
						placeholder={'Search for event or team member'}
					/>
				</header>
				<div className={styles.settingSection}>
					<header className={styles.settingSectionHeader}>
						<h1 className={styles.settingSectionTitle}>Audit Log</h1>
					</header>
					<div className={styles.mtMediumLarge}>
						{isGetAuditLogsLoading && <Spinner />}
						{auditLogs && (
							<AuditLogTable
								onViewDetailsClick={auditLog => {
									setIsViewDetailsModalActive(true)
									setCurrentAuditLog(auditLog)
								}}
								auditLogs={auditLogs}
							/>
						)}
					</div>
				</div>

				<div className={styles.mtMediumLarge}>
					<Pagination
						withoutPageSizeSelector={true}
						totalItems={totalItems}
						pageSize={10}
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
					/>
				</div>
			</div>
		</PageWrapper>
	)
}

export default SettingsAuditLogPage
