import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import styles from './AllGroupsPage.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import SearchInput, { SearchInputStyles } from '../../components/UI/SearchInput/SearchInput'
import { useNavigate } from 'react-router-dom'
import Pagination from '../../components/UI/Pagination/Pagination'
import { useAPI } from '../../hooks/useAPI'
import { useAppContext } from '../../hooks/useAppContext'
import Spinner from '../../components/UI/Spinner/Spinner'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import noGroupsImage from '../../assets/images/noGroups.png'
import Modal from '../../components/Modals/Modal'
import { closeModal } from '../../helpers/closeModal'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import PageTableHeader from '../../components/PageTableHeader/PageTableHeader'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import { useDebounce } from '../../hooks/useDebounce'
import { usePageTitle } from '../../hooks/usePageTitle'
import GroupsTable from '../../components/Tables/GroupsTable'
import { GroupsService } from '../../API/GroupsService'
import { Group } from '../../types/Group/Group'

const AllGroupsPage = () => {
	const [searchValue, setSearchValue] = useState('')
	const [totalItems, setTotalItems] = useState(23)
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [currentGroupId, setCurrentGroupId] = useState<number | null>(null)
	const [isDeleteModalActive, setIsDeleteModalActive] = useState(false)
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
	const navigate = useNavigate()
	const { shop } = useAppContext()
	usePageTitle('Groups')

	const [isGetGroupsLoading, groups, getGroups] = useAPI<Group[]>(
		(searchValue: string) =>
			GroupsService.getAll(shop?.id || '', searchValue, null, currentPage + 1, pageSize),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	useDebounce(searchValue, getGroups, 400)

	const isFirstLoading = useFirstLoading(isGetGroupsLoading)

	const [isDeleteGroupLoading, , deleteGroup] = useAPI(
		() => GroupsService.delete(shop?.id || '', Number(currentGroupId)),
		false,
		'Group successfully deleted',
		{
			onSuccess: () => {
				getGroups()
				closeDeleteModalHandler()
			},
		}
	)

	const onGroupDeleteClickHandler = (id: number) => {
		setCurrentGroupId(id)
		setIsDeleteModalActive(true)
	}

	const closeDeleteModalHandler = () => {
		closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)
		setCurrentGroupId(null)
	}

	useEffect(() => {
		if (shop) {
			getGroups()
		}
	}, [shop, currentPage, pageSize])

	if (isFirstLoading) return <PageLoading />

	if (!groups?.length && !searchValue) {
		return (
			<NoDataComponent
				imageSrc={noGroupsImage}
				title={'No Groups'}
				description={'You don’t have any groups. To create a group, tap on the button below.'}
				button={{
					icon: {
						id: 'plus',
						height: 13,
						width: 13,
						align: ButtonsIconAlign.LEFT,
					},
					onClick: () => navigate('create'),
					text: 'New Group',
				}}
			/>
		)
	}

	return (
		<PageContainer>
			{isDeleteModalActive && (
				<Modal
					closeModal={closeDeleteModalHandler}
					visible={isDeleteModalVisible}
					setVisible={setIsDeleteModalVisible}
				>
					<ConfirmModal
						title={'Delete Group'}
						description={`You are about to delete ${
							groups?.find(group => group.id === currentGroupId)?.name
						}. Are you sure you want to delete this group? This cannot be undone.`}
						onConfirm={deleteGroup}
						onClose={closeDeleteModalHandler}
						isButtonLoading={isDeleteGroupLoading}
						confirmButtonText={'Delete'}
						buttonIcon={{
							id: 'trashBin',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
					/>
				</Modal>
			)}
			<PageWrapper>
				<div className={styles.allGroupsPage}>
					<PageHeader title={'Groups'}>
						<div className={styles.headerEnd}>
							<SearchInput
								value={searchValue}
								setValue={setSearchValue}
								style={SearchInputStyles.secondary}
								placeholder={'Search for a group'}
							/>
							<Button
								style={ButtonStyles.SECONDARY}
								icon={{
									id: 'plus',
									height: 13,
									width: 13,
									align: ButtonsIconAlign.LEFT,
								}}
								onClick={() => navigate('create')}
							>
								New Group
							</Button>
						</div>
					</PageHeader>

					<PageTableHeader
						pageSize={pageSize}
						setPageSize={setPageSize}
						totalItems={totalItems}
						listItemNamePlural={'groups'}
						setCurrentPage={setCurrentPage}
					/>

					{isGetGroupsLoading ? (
						<Spinner />
					) : (
						groups && (
							<div className={styles.groupsList}>
								<GroupsTable groups={groups} onDeleteClick={onGroupDeleteClickHandler} />
							</div>
						)
					)}

					<div className={styles.paginationWrapper}>
						<Pagination
							withoutPageSizeSelector={true}
							totalItems={totalItems}
							pageSize={pageSize}
							setPageSize={setPageSize}
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
						/>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default AllGroupsPage
