import React, { FC, useState } from 'react'
import styles from './ProductCard.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import ActionMenu, { ActionMenuStyles } from '../UI/ActionMenu/ActionMenu'
import OrangeBadge from '../UI/OrangeBadge/OrangeBadge'
import { Product } from '../../types/Product/Product'
import { useNavigate } from 'react-router-dom'
import Checkbox from '../UI/Checkbox/Checkbox'
import ListingVisibilityStatusBadge from '../UI/StatusBadges/ListingVisibilityStatusBadge'
import { useCopy } from '../../hooks/useCopy'
import { useAppContext } from '../../hooks/useAppContext'
import { ActionMenuOption } from '../../types/ActionMenuOption/ActionMenuOption'

interface ProductCardProps {
	isSelected?: boolean
	onClick: (id: number) => void
	onDeselect: () => void
	onDuplicateClick: (id: number) => void
	onDeleteClick: (product: Product) => void
	product: Product
	isProductsModifyAvailable?: boolean
	isProductsDeleteAvailable?: boolean
}

const ProductCard: FC<ProductCardProps> = ({
	isSelected,
	onClick,
	product,
	onDeselect,
	onDuplicateClick,
	onDeleteClick,
	isProductsModifyAvailable,
	isProductsDeleteAvailable,
}) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)
	const navigate = useNavigate()
	const { shopDomain } = useAppContext()
	const copy = useCopy()

	const actionButtons: ActionMenuOption[] = []

	isProductsModifyAvailable &&
		actionButtons.push(
			...[
				{
					title: 'Edit',
					icon: {
						id: 'pen',
						width: 14,
						height: 14,
					},
					onClick: () => navigate(`/products/edit/${product.id}`),
				},
				{
					title: 'Duplicate',
					icon: {
						id: 'copy',
						width: 14.5,
						height: 14.5,
					},
					onClick: () => onDuplicateClick(product.id),
				},
			]
		)

	isProductsDeleteAvailable &&
		actionButtons.push({
			title: 'Delete',
			icon: {
				id: 'trashBin',
				width: 14.5,
				height: 14.5,
			},
			onClick: () => onDeleteClick(product),
		})

	const onCheckboxChangeHandler = (checked: boolean) => {
		if (checked && !isSelected) {
			onClick(product.id)
		} else if (!checked && isSelected) {
			onDeselect()
		}
	}

	return (
		<div
			className={`${styles.productCard} ${isSelected && styles.selected}`}
			onClick={() => navigate(`/products/edit/${product.id}`)}
		>
			<div className={styles.main}>
				<div className={styles.checkboxWrapper} onClick={event => event.stopPropagation()}>
					<Checkbox
						id={product.id.toString()}
						checked={!!isSelected}
						setChecked={onCheckboxChangeHandler}
					/>
				</div>

				<div className={styles.image}>
					{product.imagePath ? (
						<img src={`https://imagedelivery.net/${product.imagePath}/productCard`} alt='' />
					) : (
						<SpriteIcon iconId={'image'} width={14} height={14} />
					)}
				</div>

				<div className={styles.info}>
					<p className={styles.title}>{product.name}</p>
					<div className={styles.details}>
						<span>${product.minPrice.amount}</span>
						<span className={styles.bullet}>•</span>
						<span>{product.isInStock ? 'In Stock' : 'Out of Stock'}</span>
						<span className={styles.bullet}>•</span>
						<ListingVisibilityStatusBadge visibility={product.visibility} />
					</div>
				</div>
				<div className={styles.options} onClick={event => event.stopPropagation()}>
					<div
						className={styles.iconButton}
						onClick={() => copy(`${shopDomain}/product/${product.uniquePath}`)}
					>
						<SpriteIcon iconId={'chain'} width={14} height={14} />
					</div>
					{!!actionButtons.length && (
						<ActionMenu
							actionButtons={actionButtons}
							isActive={isActionMenuActive}
							setIsActive={setIsActionMenuActive}
							style={ActionMenuStyles.secondary}
						/>
					)}
				</div>
			</div>
		</div>
	)
}

export default ProductCard
