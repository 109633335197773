import React, { useEffect, useState } from 'react'
import styles from './CreateCategoryPage.module.scss'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageHeader from '../../components/PageHeader/PageHeader'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import Input from '../../components/UI/FormComponents/Input'
import { useAPI } from '../../hooks/useAPI'
import { useAppContext } from '../../hooks/useAppContext'
import { useNavigate, useParams } from 'react-router-dom'
import { CategoryCreate } from '../../types/CategoryCreate/CategoryCreate'
import { CategoriesService } from '../../API/CategoriesService'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import { CategoryDetailed } from '../../types/CategoryDetailed/CategoryDetailed'
import FormErrorsDisplayBadge from '../../components/UI/FormErrorsDisplayBadge/FormErrorsDisplayBadge'
import { FormErrors } from '../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../helpers/fieldValidators'
import RequiredBadge from '../../components/UI/RequiredBadge/RequiredBadge'
import { ListingsService } from '../../API/ListingsService'
import { Listing } from '../../types/Listing/Listing'
import Spinner from '../../components/UI/Spinner/Spinner'
import { usePageTitle } from '../../hooks/usePageTitle'
import ChooseAndSortListings from '../../components/ChooseAndSortListings/ChooseAndSortListings'
import { ListingType } from '../../types/ListingType/ListingType'

const CreateCategoryPage = () => {
	const [titleValue, setTitleValue] = useState('')
	const { shop } = useAppContext()
	const params = useParams()
	const categoryId = params.id
	const navigate = useNavigate()
	const [errors, setErrors] = useState<FormErrors>({
		title: '',
	})
	const [showErrorsDisplay, setShowErrorsDisplay] = useState(false)
	const errorMessages = Object.keys(errors)
		.map(key => errors[key])
		.filter(error => error)
	const [handledListings, setHandledListings] = useState<Listing[]>([])
	usePageTitle('Create Category')

	const [isCreateCategoryLoading, , createCategory] = useAPI(
		(data: CategoryCreate) => CategoriesService.create(shop?.id || '', data),
		false,
		'Category successfully created',
		{
			onSuccess: () => {
				navigate('/products/categories')
			},
		}
	)

	const [isUpdateCategoryLoading, , updateCategory] = useAPI(
		(data: CategoryCreate) => CategoriesService.update(shop?.id || '', Number(categoryId), data),
		false,
		'Category successfully updated',
		{
			onSuccess: () => {
				navigate('/products/categories')
			},
		}
	)

	const [isGetCategoryLoading, category, getCategory] = useAPI<CategoryDetailed>(
		() => CategoriesService.getOne(shop?.id || '', Number(categoryId)),
		false
	)

	const [isGetListingsLoading, listings, getListings] = useAPI<Listing[]>(
		() => ListingsService.getListings(shop?.id || ''),
		false
	)

	const onSubmitHandler = () => {
		if (titleValue) {
			const data: CategoryCreate = {
				name: titleValue,
				listings: handledListings.map(listing => ({
					position: listing.position,
					id: listing.id,
				})),
			}

			if (categoryId) {
				updateCategory(data)
			} else {
				createCategory(data)
			}

			setShowErrorsDisplay(false)
		} else {
			setShowErrorsDisplay(true)
			setErrors({ ...errors, title: fieldValidators.blankValidator('Category title', titleValue) })
		}
	}

	useEffect(() => {
		if (shop) {
			if (categoryId) {
				getCategory()
			}
			getListings()
		}
	}, [shop])

	useEffect(() => {
		if (category && listings) {
			setTitleValue(category.name)
			setHandledListings(
				category.listings.map(listing => {
					const detailedListing = listings?.find(item => item.id === listing.id)
					return {
						position: listing.position,
						id: listing.id,
						name: detailedListing?.name || '',
						imageCfId: detailedListing?.imageCfId,
					}
				})
			)
		}
	}, [category, listings])

	if (isGetCategoryLoading) return <PageLoading />

	return (
		<PageContainer>
			{showErrorsDisplay && !!errorMessages.length && (
				<FormErrorsDisplayBadge
					title={`Category ${categoryId ? 'Edit' : 'Create'} Error`}
					errors={[errors['title']]}
				/>
			)}

			<PageWrapper>
				<div className={styles.createGroupPage}>
					<PageHeader title={`${categoryId ? 'Edit' : 'Create'} Category`}></PageHeader>

					<div className={styles.body}>
						<div className={styles.column}>
							<section className={styles.section}>
								<header className={styles.sectionHeader}>
									<h1 className={styles.sectionTitle}>Category Products</h1>
								</header>
								<div className={styles.sectionBody}>
									<div className={styles.fieldWrapper}>
										<p className={styles.fieldTitle}>
											Category Title <RequiredBadge />
										</p>
										<Input
											value={titleValue}
											setValue={setTitleValue}
											placeholder={'Demo Category'}
											onBlur={() =>
												setErrors({
													...errors,
													title: fieldValidators.blankValidator('Category title', titleValue),
												})
											}
											errorMessage={errors['title']}
										/>
									</div>
								</div>
							</section>
							<section className={styles.section}>
								<header className={styles.sectionHeader}>
									<h1 className={styles.sectionTitle}>Category Products</h1>
								</header>

								<div className={styles.sectionBody}>
									{isGetListingsLoading && <Spinner />}
									<ChooseAndSortListings
										listings={listings}
										handledListings={handledListings}
										setHandledListings={setHandledListings}
										hideSearchInput={category?.name === 'All'}
									/>
								</div>
							</section>
						</div>
						<div className={styles.column}>
							<Button
								style={ButtonStyles.SECONDARY}
								icon={{
									id: 'plus',
									width: 13,
									height: 13,
									align: ButtonsIconAlign.LEFT,
								}}
								isLoading={isCreateCategoryLoading || isUpdateCategoryLoading}
								onClick={onSubmitHandler}
							>
								{categoryId ? 'Edit' : 'Create'} category
							</Button>
						</div>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default CreateCategoryPage
