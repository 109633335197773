import { privateAPI } from './API'

export const AnalyticsService = {
	getSalesCharts: async (shopId: string, fromDate: Date | null, toDate: Date | null) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/analytics/sales-charts`, {
			params: {
				fromDate,
				toDate,
			},
		})
	},
	getRecentActions: async (shopId: string) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/analytics/recent-actions`)
	},
	getTopProductsCharts: async (shopId: string, fromDate: Date | null, toDate: Date | null) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/analytics/top-products-charts`, {
			params: {
				fromDate,
				toDate,
			},
		})
	},
}
