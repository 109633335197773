import { type FC } from 'react';
import styles from './ShopTeamMemberTag.module.scss';

const ShopTeamMemberTag: FC = () => {
    return (
        <div className={styles.tag}>
            <p>Team member</p>
        </div>
    )
};

export default ShopTeamMemberTag;