import React, { FC, useEffect, useState } from 'react'
import styles from '../../CustomerDetailedPage.module.scss'
import UpdatedOnDateBadge from '../../../../components/UI/UpdatedOnDateBadge/UpdatedOnDateBadge'
import IpDetailsTable from '../../../../components/Tables/IpDetailsTable'
import Pagination from '../../../../components/UI/Pagination/Pagination'
import { IpDetailForCustomers } from '../../../../types/IpDetailForCustomers/IpDetailForCustomers'

interface ShieldInformationProps {
	ipDetails: IpDetailForCustomers[]
}

const ShieldInformation: FC<ShieldInformationProps> = ({ ipDetails }) => {
	const [ipDetailsCurrentPage, setIpDetailsCurrentPage] = useState(0)
	const [ipDetailsForWork, setIpDetailsForWork] = useState<IpDetailForCustomers[]>([])

	useEffect(() => {
		setIpDetailsForWork(
			ipDetails.filter(
				(ipDetail, index) =>
					index + 1 > 6 * ipDetailsCurrentPage && index < 6 * (ipDetailsCurrentPage + 1)
			) || []
		)
	}, [ipDetailsCurrentPage])

	return (
		<div className={styles.section}>
			<header className={`${styles.header} ${styles.withoutBorderBottom}`}>
				<h1>Shield Information</h1>
				<UpdatedOnDateBadge date={new Date()} />
			</header>

			<IpDetailsTable ipDetails={ipDetailsForWork} />

			<footer className={`${styles.sectionFooter} ${styles.borderTop}`}>
				<div className={styles.mtMediumLarge}>
					<Pagination
						invertedStyle={true}
						withoutPageSizeSelector={true}
						totalItems={ipDetails.length}
						pageSize={6}
						setCurrentPage={setIpDetailsCurrentPage}
						currentPage={ipDetailsCurrentPage}
					/>
				</div>
			</footer>
		</div>
	)
}

export default ShieldInformation
