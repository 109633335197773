import React, { FC, useRef } from 'react'
import styles from './UrlInput.module.scss'
import formStyles from '../FormComponents.module.scss'
import { SpriteIcon } from '../../SpriteIcon/SpriteIcon'
import FieldErrorMessage from '../../FieldErrorMessage/FieldErrorMessage'

interface UrlInputProps {
	value: string
	setValue: (value: string) => void
	errorMessage?: string
	onBlur?: () => void
	URL: string
}

const UrlInput: FC<UrlInputProps> = ({ setValue, value, errorMessage, onBlur, URL }) => {
	const urlRef = useRef<HTMLDivElement>(null)

	return (
		<div>
			<div className={`${styles.urlInput} `}>
				<div className={styles.url} ref={urlRef}>
					{URL}
				</div>
				<input
					type='text'
					className={`${formStyles.input} ${errorMessage && formStyles.error}`}
					value={value}
					onChange={event => setValue(event.target.value)}
					style={{
						paddingLeft: urlRef.current ? urlRef.current?.offsetWidth + 5 : undefined,
					}}
					onBlur={onBlur}
				/>
			</div>
			{errorMessage && <FieldErrorMessage errorMessage={errorMessage} />}
		</div>
	)
}

export default UrlInput
