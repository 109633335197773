import React, { FC, useRef, useState } from 'react'
import styles from './Tables.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { NavLink } from 'react-router-dom'
import Checkbox from '../UI/Checkbox/Checkbox'
import ProductTypeBadge from '../UI/ProductTypeBadge/ProductTypeBadge'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import { Image } from '../../types/Image/Image'
import { splitImageNameAndExtension } from '../../helpers/splitImageNameAndExtension'
import WarehouseStatusBadge from '../UI/StatusBadges/WarehouseStatusBadge'
import StockStatusBadge from '../UI/StockStatusBadge/StockStatusBadge'
import { GetDeliverySerial } from '../../types/GetDeliverySerial/GetDeliverySerial'
import { useCopy } from '../../hooks/useCopy'

interface SerialsDeliveryTableBodyProps {
	serial: GetDeliverySerial
}

interface SerialsDeliveryTableProps {
	serials: GetDeliverySerial[]
}

const SerialsDeliveryTableBody: FC<SerialsDeliveryTableBodyProps> = ({ serial }) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)
	const tableBodyRef = useRef<HTMLDivElement>(null)
	const copy = useCopy()

	const actionMenuButtons = [
		// {
		// 	onClick: () => {},
		// 	icon: {
		// 		id: 'lock',
		// 		width: 13,
		// 		height: 15,
		// 	},
		// 	title: 'Remove Serials',
		// },
		{
			onClick: () => copy(serial.key),
			icon: {
				id: 'blank',
				width: 11,
				height: 14,
			},
			title: 'Copy Serials',
		},
	]

	return (
		<div
			className={`${styles.trWrapper} ${isActionMenuActive && styles.actionMenuActive}`}
			style={{
				paddingBottom: isActionMenuActive ? +actionMenuButtons.length * 50 : 5,
			}}
			ref={tableBodyRef}
		>
			<tr>
				<td className={styles.medium}>
					<p>{serial.key}</p>
					<span>ID: {serial.id}</span>
				</td>
				<td className={styles.medium}>
					<StockStatusBadge isAvailable={serial.isAvailable} />
				</td>
				<td className={styles.small}>
					<ActionMenu
						actionButtons={actionMenuButtons}
						isActive={isActionMenuActive}
						setIsActive={setIsActionMenuActive}
					/>
				</td>
			</tr>
		</div>
	)
}

const SerialsDeliveryTable: FC<SerialsDeliveryTableProps> = ({ serials }) => {
	return (
		<table className={`${styles.table} ${styles.fullWidth}`}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.medium}>SERIAL</th>
					<th className={styles.medium}>Status</th>
					<th className={styles.small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{serials.map(serial => (
					<SerialsDeliveryTableBody serial={serial} key={serial.id} />
				))}
			</tbody>
		</table>
	)
}

export default SerialsDeliveryTable
