import React, { FC, MutableRefObject, useEffect, useState } from 'react'
import styles from '../AuthPages.module.scss'
import { SpriteIcon } from '../../../components/UI/SpriteIcon/SpriteIcon'
import Input from '../../../components/UI/FormComponents/Input'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../components/UI/Button/Button'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAPI } from '../../../hooks/useAPI'
import { AuthService } from '../../../API/AuthService'
import InputMask from '../../../components/UI/FormComponents/InputMask'
import { useAppContext } from '../../../hooks/useAppContext'
import { StatusAlert } from '../../../types/StatusAlert/StatusAlert'
import StatusAlertBadge from '../../../components/UI/StatusAlertBadge/StatusAlertBadge'
import { fieldValidators } from '../../../helpers/fieldValidators'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { usePageTitle } from '../../../hooks/usePageTitle'
import AlternativeAuthVariants from '../../../components/AlternativeAuthVariants/AlternativeAuthVariants'

interface SignInPageProps {
	recaptchaRef: MutableRefObject<any>
}

const SignInPage: FC<SignInPageProps> = ({ recaptchaRef }) => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [tfaCode, setTfaCode] = useState('')
	const [errors, setErrors] = useState<FormErrors>({})
	const [isTFARequired, setIsTFARequired] = useState(false)
	const { redirectAfterAuthPath } = useAppContext()
	usePageTitle('Sign In')

	console.log('Sign in');

	const signInValidator = () => {
		const emailValidator = fieldValidators.emailValidator(email)
		const passwordValidator = fieldValidators.passwordValidator(password)

		setErrors({
			...errors,
			email: emailValidator,
			password: passwordValidator,
		})

		return !(emailValidator || passwordValidator)
	}

	const [isLoginBasicLoading, , loginBasic] = useAPI<{ accessToken: string }>(
		async () => {
			const recaptcha = await recaptchaRef.current.executeAsync()
			recaptchaRef.current.reset()

			return AuthService.loginBasic({
				email,
				password: password,
				recaptcha: recaptcha,
				tfaCode: tfaCode.replace('-', '') || undefined,
			})
		},
		false,
		'Successfully signed in',
		{
			validator: signInValidator,
			onSuccess: response => {
				localStorage.setItem('accessToken', response.data.data.accessToken)
				window.location.pathname = redirectAfterAuthPath ? redirectAfterAuthPath : '/'
			},
			showErrorValidator: error => {
				return (
					isTFARequired ||
					!(error.response?.data.errors && error.response?.data.errors[0] === '2FA code is invalid')
				)
			},
			onError: error => {
				if (error.response?.status === 403) {
					setIsTFARequired(true)
				}
			},
		}
	)
	useEffect(() => {
		const token = localStorage.getItem('accessToken')
		if (token) {
			window.location.pathname = redirectAfterAuthPath ? redirectAfterAuthPath : '/'
		}
	}, [])
	return (
		<>
			<div className={styles.authInner}>
				<div className={styles.logo}>
					<SpriteIcon iconId={'nameLogo'} width={124} height={25} />
				</div>
				<h1 className={styles.title}>
					{isTFARequired ? 'Secure two-factor authentication' : 'Sign in to your account'}
				</h1>

				{isTFARequired ? (
					<>
						<div className={styles.fieldWrapper}>
							<h2 className={styles.fieldTitle}>6-digit code</h2>
							<InputMask
								mask={'999-999'}
								value={tfaCode}
								setValue={setTfaCode}
								placeholder={'000-000'}
							/>
						</div>

						<div className={styles.button}>
							<Button
								style={ButtonStyles.PRIMARY}
								onClick={loginBasic}
								isLoading={isLoginBasicLoading}
							>
								Continue
							</Button>
						</div>
					</>
				) : (
					<>
						<div className={styles.fieldWrapper}>
							<h2 className={styles.fieldTitle}>Email address</h2>
							<Input
								value={email}
								setValue={setEmail}
								placeholder={'Enter your email address'}
								errorMessage={errors['email']}
								onBlur={() =>
									setErrors({
										...errors,
										email: fieldValidators.emailValidator(email),
									})
								}
							/>
						</div>

						<div className={styles.fieldWrapper}>
							<h2 className={styles.fieldTitle}>Password</h2>
							<Input
								value={password}
								setValue={setPassword}
								placeholder={'*****************'}
								type={'password'}
								errorMessage={errors['password']}
								onBlur={() =>
									setErrors({
										...errors,
										password: fieldValidators.passwordValidator(password),
									})
								}
							/>
						</div>

						<p className={styles.helperText}>
							<NavLink to='/auth/reset-password'>Forgot password?</NavLink>
						</p>

						<div className={styles.button}>
							<Button
								style={ButtonStyles.PRIMARY}
								onClick={loginBasic}
								isLoading={isLoginBasicLoading}
							>
								Sign In
							</Button>
						</div>

						<p className={styles.helperText}>
							By signing in, you agree to the Billgang{' '}
							<a href='https://billgang.com/privacy-policy' target={'_blank'}>
								Privacy Policy
							</a>{' '}
							and{' '}
							<a href='https://billgang.com/terms-of-service' target={'_blank'}>
								Terms of Service
							</a>
							.
						</p>

						<div className={styles.OR}>
							<span>OR</span>
						</div>

						<AlternativeAuthVariants />

						<p className={styles.helperText}>
							Don’t have an account? <NavLink to='/auth/sign-up'>Sign Up</NavLink>.
						</p>
					</>
				)}
			</div>
		</>
	)
}

export default SignInPage
