import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Textarea from '../../UI/FormComponents/Textarea'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import CustomMdEditor from '../../UI/CustomMDEditor/CustomMDEditor'

interface MessageCustomersModalProps extends BaseModalProps {}

const MessageCustomersModal: FC<MessageCustomersModalProps> = ({ onClose }) => {
	const [message, setMessage] = useState<string | undefined>('')

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Message Customers</h1>
			</header>

			<div className={styles.body}>
				<div className={styles.fieldWrapper}>
					<h2>Message (optional)</h2>
					<CustomMdEditor value={message} setValue={setMessage} />
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} onClick={onClose}>
						Close
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'send',
							width: 12,
							height: 14,
							align: ButtonsIconAlign.LEFT,
						}}
					>
						Message 5 Customers
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default MessageCustomersModal
