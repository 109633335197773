import { privateAPI } from './API'
import { CreateWarehouse } from '../types/CreateWarehouse/CreateWarehouse'
import { AddWarehouseStock } from '../types/AddWarehouseStock/AddWarehouseStock'
import { UpdateWarehouse } from '../types/UpdateWarehouse/UpdateWarehouse'
import { EditWarehouseStock } from '../types/EditWarehouseStock/EditWarehouseStock'

export const StockSystemService = {
	getWarehouses: async (
		shopId: string,
		searchString?: string,
		PageNumber?: number,
		PageSize?: number
	) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/warehouses`, {
			params: {
				searchString,
				PageNumber,
				PageSize,
			},
		})
	},
	createWarehouse: async (shopId: string, data: CreateWarehouse) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/warehouses`, data)
	},
	updateWarehouse: async (shopId: string, warehouseId: number, data: UpdateWarehouse) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/warehouses/${warehouseId}`, data)
	},
	deleteWarehouse: async (shopId: string, warehouseId: number) => {
		return await privateAPI.delete(`/v1/dash/shops/${shopId}/warehouses/${warehouseId}`)
	},
	getWarehouse: async (shopId: string, warehouseId: number | undefined) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/warehouses/${warehouseId}`)
	},
	getWarehouseStock: async (
		shopId: string,
		warehouseId: number,
		searchString?: string,
		PageNumber?: number,
		PageSize?: number
	) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/warehouses/${warehouseId}/stock`, {
			params: {
				searchString,
				PageNumber,
				PageSize,
			},
		})
	},
	getWarehouseStockRaw: async (shopId: string, warehouseId: number) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/warehouses/${warehouseId}/stock/raw`)
	},
	addWarehouseStock: async (
		shopId: string,
		warehouseId: number | undefined,
		data: AddWarehouseStock
	) => {
		return await privateAPI.post(
			`/v1/dash/shops/${shopId}/warehouses/${warehouseId}/add-stock`,
			data
		)
	},
	editWarehouseStock: async (
		shopId: string,
		warehouseId: number | undefined,
		data: EditWarehouseStock
	) => {
		return await privateAPI.post(
			`/v1/dash/shops/${shopId}/warehouses/${warehouseId}/edit-stock`,
			data
		)
	},
	deleteStocks: async (
		shopId: string,
		warehouseId: number | undefined,
		data: {
			serialIds: string[] | null
		}
	) => {
		return await privateAPI.post(
			`/v1/dash/shops/${shopId}/warehouses/${warehouseId}/delete-stock`,
			data
		)
	},
}
