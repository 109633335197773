import { privateAPI } from './API'
import { PaymentMethod } from '../types/PaymentMethod/PaymentMethod'

export const CustomGatewayPluginsExplorerService = {
	getAll: async (
		searchString?: string,
		filterPaymentMethod?: PaymentMethod | string,
		onlyMyPlugins?: boolean
	) => {
		return await privateAPI.get(`/v1/dash/gateway-plugins/explorer`, {
			params: {
				searchString,
				filterPaymentMethod,
				onlyMyPlugins,
			},
		})
	},
}
