import React, { FC } from 'react'
import styles from './SettingsDomainsPage.module.scss'
import generalStyles from '../Settings.module.scss'
import Input, { InputIconAligns } from '../../../components/UI/FormComponents/Input'
import { Domain } from '../../../types/Domain/Domain'
import { useCopy } from '../../../hooks/useCopy'

interface DomainTXTRecordFormProps {
	domain: Domain
}

const DomainTXTRecordForm: FC<DomainTXTRecordFormProps> = ({ domain }) => {
	const copy = useCopy()

	if (!domain.cloudflare) return null

	return (
		<div className={styles.connectionDomainStepForm}>
			<h2 className={styles.connectionDomainStepFormTitle}>Add TXT Record</h2>
			<p className={styles.connectionDomainStepFormDescription}>
				Log into your domain provider's dashboard, head to the DNS section, and then tap on "Add" or
				"Create" to input the new DNS record details.
			</p>

			<div className={generalStyles.mtMediumLarge}>
				<div className={generalStyles.fieldWrapper}>
					<p className={generalStyles.fieldWrapperTitle}>Type</p>
					<div className={generalStyles.formWrapper}>
						<Input
							value={domain.cloudflare.ownership_verification.type}
							setValue={() => {}}
							placeholder={'TXT'}
							icon={{
								id: 'copy',
								width: 14,
								height: 14,
								align: InputIconAligns.RIGHT,
								onClick: () => copy(domain.cloudflare?.ownership_verification.type || ''),
							}}
						/>
					</div>
				</div>
			</div>
			<div className={generalStyles.mtMediumLarge}>
				<div className={generalStyles.fieldWrapper}>
					<p className={generalStyles.fieldWrapperTitle}>Alias (or hostname)</p>
					<div className={generalStyles.formWrapper}>
						<Input
							value={'@'}
							setValue={() => {}}
							placeholder={'@'}
							icon={{
								id: 'copy',
								width: 14,
								height: 14,
								align: InputIconAligns.RIGHT,
								onClick: () => copy('@'),
							}}
						/>
					</div>
				</div>
			</div>
			<div className={generalStyles.mtMediumLarge}>
				<div className={generalStyles.fieldWrapper}>
					<p className={generalStyles.fieldWrapperTitle}>Hostname pre-validation TXT name</p>
					<div className={generalStyles.formWrapper}>
						<Input
							value={domain.cloudflare.ownership_verification.name}
							setValue={() => {}}
							placeholder={'_cf-custom-hostname.billgang.com'}
							icon={{
								id: 'copy',
								width: 14,
								height: 14,
								align: InputIconAligns.RIGHT,
								onClick: () => copy(domain.cloudflare?.ownership_verification.name || ''),
							}}
						/>
					</div>
				</div>
			</div>
			<div className={generalStyles.mtMediumLarge}>
				<div className={generalStyles.fieldWrapper}>
					<p className={generalStyles.fieldWrapperTitle}>Hostname pre-validation TXT value</p>
					<div className={generalStyles.formWrapper}>
						<Input
							value={domain.cloudflare.ownership_verification.value}
							setValue={() => {}}
							placeholder={'a1331187-e527-458b-8aa8-0b5461a61c29'}
							icon={{
								id: 'copy',
								width: 14,
								height: 14,
								align: InputIconAligns.RIGHT,
								onClick: () => copy(domain.cloudflare?.ownership_verification.value || ''),
							}}
						/>
					</div>
				</div>
			</div>
			<div className={generalStyles.mtMediumLarge}>
				<div className={generalStyles.fieldWrapper}>
					<p className={generalStyles.fieldWrapperTitle}>TTL</p>
					<div className={generalStyles.formWrapper}>
						<Input value={''} setValue={() => {}} placeholder={'Auto (default)'} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default DomainTXTRecordForm
