import React, { FC } from 'react'
import styles from '../../OrderDetailedPage.module.scss'
import StatusBadge from '../../../../components/UI/StatusBadge/StatusBadge'
import { SpriteIcon } from '../../../../components/UI/SpriteIcon/SpriteIcon'
import { DetailedOrder } from '../../../../types/DetailedOrder/DetailedOrder'
import { cleanEmail } from '../../../../helpers/cleanEmail'
import { format } from 'date-fns'
import OrderChargeStatusBadge from '../../../../components/UI/StatusBadges/OrderChargeStatusBadge'
import { getGatewaysOptions } from '../../../../helpers/getGatewaysOptions'
import UpdatedOnDateBadge from '../../../../components/UI/UpdatedOnDateBadge/UpdatedOnDateBadge'
import { useAppContext } from '../../../../hooks/useAppContext'

interface OrdersCustomerDataProps {
	order: DetailedOrder
}

const OrdersCustomerData: FC<OrdersCustomerDataProps> = ({ order }) => {
	const { availablePayments } = useAppContext()
	const payment = availablePayments?.find(payment => payment.name === order.charge.gateway)
	const discordSocialConnect = order.charge.customerForCharge?.discordSocialConnect
	return (
		<div className={styles.section}>
			<header className={styles.header}>
				<h1>Order Data</h1>
				<UpdatedOnDateBadge date={new Date()} />
			</header>

			<div className={styles.dataItem}>
				<div className={styles.dataItemHead}>
					<div className={styles.icon}>
						<img
							src={`https://imagedelivery.net/${payment?.logoCfImageId}/productCard`}
							alt={payment?.name}
						/>
					</div>
					<div>
						<h2 className={styles.dataTitle}>{payment?.displayName}</h2>
						<p className={styles.dataSubtitle}>Payment Type</p>
					</div>
				</div>
			</div>

			<div className={styles.dataItem}>
				<div className={styles.dataItemHead}>
					<div className={styles.icon}>
						<SpriteIcon iconId={'atSymbol'} width={20} height={20} />
					</div>
					<div>
						<h2 className={styles.dataTitle}>{order.charge.customerForCharge?.email}</h2>
						<p className={styles.dataSubtitle}>Email Address</p>
					</div>
				</div>

				<OrderChargeStatusBadge orderChargeStatus={order.charge.status} />
			</div>

			<div className={styles.dataItem}>
				<div className={styles.dataItemHead}>
					<div className={styles.icon}>
						<SpriteIcon iconId={'percent'} width={20} height={20} />
					</div>
					<div>
						<h2 className={styles.dataTitle}>
							{order.usedCoupon ? order.usedCoupon.name : 'Not Used'}
						</h2>
						<p className={styles.dataSubtitle}>Coupon Code</p>
					</div>
				</div>
			</div>

			<div className={styles.dataItem}>
				<div className={styles.dataItemHead}>
					<div className={styles.icon}>
						<SpriteIcon iconId={'calendar'} width={20} height={20} />
					</div>
					<div>
						<h2 className={styles.dataTitle}>
							{format(new Date(order.charge.createdAt), "MMMM dd, yyyy 'at' hh:mm aa")}
						</h2>
						<p className={styles.dataSubtitle}>Date and Time</p>
					</div>
				</div>
			</div>
			{discordSocialConnect && (
				<div className={styles.dataItem}>
					<div className={styles.dataItemHead}>
						<div className={styles.icon}>
							<SpriteIcon iconId={'discord3'} width={20} height={15} />
						</div>
						<div>
							<h2 className={styles.dataTitle}>{discordSocialConnect.username}</h2>
							<p className={styles.dataSubtitle}>{discordSocialConnect.id}</p>
						</div>
					</div>
				</div>
			)}

			{/*<div className={styles.dataItem}>*/}
			{/*	<div className={styles.dataItemHead}>*/}
			{/*		<div className={styles.icon}>*/}
			{/*			<SpriteIcon iconId={'telegram'} width={20} height={18} />*/}
			{/*		</div>*/}
			{/*		<div>*/}
			{/*			<h2 className={styles.dataTitle}>Demo</h2>*/}
			{/*			<p className={styles.dataSubtitle}>551218467945316362</p>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}
		</div>
	)
}

export default OrdersCustomerData
