import React, { useEffect } from 'react'
import styles from '../AuthPages.module.scss'
import { SpriteIcon } from '../../../components/UI/SpriteIcon/SpriteIcon'
import Input from '../../../components/UI/FormComponents/Input'
import Button, { ButtonStyles } from '../../../components/UI/Button/Button'
import { NavLink } from 'react-router-dom'
import { usePageTitle } from '../../../hooks/usePageTitle'

const EmailVerificationPage = () => {
	usePageTitle('Verify Email')

	return (
		<div className={styles.authInner}>
			<div className={styles.logo}>
				<SpriteIcon iconId={'nameLogo'} width={124} height={27} />
			</div>
			<h1 className={styles.title}>Verify domain@gmail.com</h1>

			<p className={styles.helperText}>
				To start selling your digital products and accepting payments with Billgang, you’ll have to
				verify your email address.
			</p>

			<div className={styles.button}>
				<Button style={ButtonStyles.PRIMARY} isDisabled={true}>
					Resend Verification Link (60)
				</Button>
			</div>
		</div>
	)
}

export default EmailVerificationPage
