import React, { FC } from 'react'
import styles from './PluginCard.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { ExplorerPlugin } from '../../types/Plugin/ExplorerPlugin'
import { getImageUrl } from '../../helpers/getImageUrl'

interface PluginCardProps {
	plugin: ExplorerPlugin
	onEnableClick: () => void
	onConfigureClick: () => void
	isActive: boolean
	isSettingsPaymentsModifyAvailable: boolean
}

const PluginCard: FC<PluginCardProps> = ({
	plugin,
	onEnableClick,
	onConfigureClick,
	isActive,
	isSettingsPaymentsModifyAvailable,
}) => {
	return (
		<div className={styles.pluginCard}>
			<header className={styles.pluginCardHeader}>
				<div className={styles.logo}>
					<img src={getImageUrl(plugin.logoCfImageId)} alt={plugin.displayName} />
				</div>
				<p className={styles.name}>{plugin.displayName}</p>
				{isActive ? (
					<SpriteIcon className={styles.green} iconId={'checkedCircle'} width={16} height={16} />
				) : (
					<SpriteIcon className={styles.red} iconId={'xMarkCircle'} width={16} height={16} />
				)}
			</header>

			<div className={styles.body}>
				<p className={styles.description}>{plugin.description}</p>
				{isSettingsPaymentsModifyAvailable && (
					<div className={styles.button}>
						{isActive ? (
							<Button
								style={ButtonStyles.LIGHT}
								width={'100%'}
								icon={{
									id: 'pen',
									width: 13,
									height: 13,
									align: ButtonsIconAlign.LEFT,
								}}
								onClick={onConfigureClick}
							>
								Configure
							</Button>
						) : (
							<Button
								style={ButtonStyles.DARK}
								width={'100%'}
								icon={{
									id: 'circlePlus',
									width: 13,
									height: 13,
									align: ButtonsIconAlign.LEFT,
								}}
								onClick={onEnableClick}
							>
								Enable
							</Button>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

export default PluginCard
