import { privateAPI } from './API'
import { AppealReviewReason } from '../types/AppealReviewReason/AppealReviewReason'

export const ReviewsService = {
	getStats: async (shopId: string) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/reviews/stats`, {})
	},
	getAll: async (shopId: string, searchString?: string, PageNumber?: number, PageSize?: number) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/reviews`, {
			params: {
				searchString,
				PageNumber,
				PageSize,
			},
		})
	},
	getOne: async (shopId: string, id: number) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/reviews/${id}`)
	},
	answerToReview: async (shopId: string, id: number, data: { reply: string }) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/reviews/${id}/answer`, data)
	},
	appealReview: async (
		shopId: string,
		id: number,
		data: { reason: AppealReviewReason; description: string }
	) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/reviews/${id}/appeal`, data)
	},
}
