import React, { FC } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import styles from './CodeWrapper.module.scss'
import { a11yLight, a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { useAppContext } from '../../../hooks/useAppContext'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import { useCopy } from '../../../hooks/useCopy'
interface CodeWrapperProps {
	code: string
	language: string
	title: string
}

const CodeWrapper: FC<CodeWrapperProps> = ({ language, code, title }) => {
	const { theme } = useAppContext()
	const copy = useCopy()

	return (
		<div className={styles.codeWrapper}>
			<header className={styles.codeWrapperHeader}>
				<p>{title}</p>
				<SpriteIcon iconId={'copy'} width={14} height={14} onClick={() => copy(code)} />
			</header>
			<div className={styles.codeWrapperBody}>
				<SyntaxHighlighter
					customStyle={{
						background: 'transparent',
						padding: 0,
					}}
					language={language}
					style={theme === 'light' ? a11yLight : a11yDark}
				>
					{code}
				</SyntaxHighlighter>
			</div>
		</div>
	)
}

export default CodeWrapper
