import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import styles from './CreateProductPage.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import ProductGeneral from './components/ProductGeneral/ProductGeneral'
import ProductSEO from './components/ProductSEO/ProductSEO'
import ProductGroupEdits from '../../components/ProductGroupEdits/ProductGroupEdits'
import { ChargeType } from '../../types/ChargeType/ChargeType'
import { VariantsPricing } from '../../types/CreateVariants/VariantsPricing'
import { VariantsDelivery } from '../../types/CreateVariants/VariantsDelivery'
import { VariantsGeneral } from '../../types/CreateVariants/VariantsGeneral'
import { VariantsCustomFields } from '../../types/CreateVariants/VariantsCustomFields'
import { ListingVisibility } from '../../types/ListingVisibility/ListingVisibility'
import { VariantsDiscordSettings } from '../../types/CreateVariants/VariantsDiscordSettings'
import { VariantsAdvanced } from '../../types/CreateVariants/VariantsAdvanced'
import { useAPI } from '../../hooks/useAPI'
import { ProductsService } from '../../API/ProductsService'
import { useAppContext } from '../../hooks/useAppContext'
import { ProductCreate } from '../../types/Product/ProductCreate'
import { DeliveryType } from '../../types/DeliveryType/DeliveryType'
import { useNavigate, useParams } from 'react-router-dom'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import { ProductDetailed } from '../../types/Product/ProductDetailed'
import FormErrorsDisplayBadge from '../../components/UI/FormErrorsDisplayBadge/FormErrorsDisplayBadge'
import { Image } from '../../types/Image/Image'
import { FormErrors } from '../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../helpers/fieldValidators'
import ProductAdvisor from '../../components/ProductAdvisor/ProductAdvisor'
import { ProductVariantsTab } from '../../types/ProductVariantsTab/ProductVariantsTab'
import { CreateProductSectionIds } from '../../types/CreateProductSectionIds/CreateProductSectionIds'

import ProductVariantsWrapper from './components/ProductVariants/ProductVariantsWrapper'
import { useCopy } from '../../hooks/useCopy'
import { DeliveryConfiguration } from '../../types/Product/DeliveryConfiguration'
import { warrantySelectOptions } from '../../helpers/warrantySelectOptions'
import Modal from '../../components/Modals/Modal'
import { closeModal } from '../../helpers/closeModal'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import { usePageTitle } from '../../hooks/usePageTitle'
import { deliveryTimeOptions } from '../../helpers/deliveryTimeOptions'

const CreateProductPage = () => {
	const [titleValue, setTitleValue] = useState('')
	const [description, setDescription] = useState<string | undefined>('')
	const [shortDescription, setShortDescription] = useState('')
	const [uniquePath, setUniquePath] = useState<string>('')
	const [pageTitle, setPageTitle] = useState<string>(``)
	const [pageDescription, setPageDescription] = useState<string>('')
	const [visibility, setVisibility] = useState<ListingVisibility>(ListingVisibility.PUBLIC)
	const [images, setImages] = useState<Image[]>([])
	const [metaImage, setMetaImage] = useState<Image | null>(null)
	const [currentVariantIndex, setCurrentVariantIndex] = useState('0')
	const [isDeleteModalActive, setIsDeleteModalActive] = useState(false)
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
	const [currentVariantsTab, setCurrentVariantsTab] = useState<{
		[variantIndex: string]: ProductVariantsTab
	}>({
		0: ProductVariantsTab.GENERAL,
	})
	const { shop, shopDomain } = useAppContext()
	const params = useParams()
	const productId = params.id
	const navigate = useNavigate()
	const [errors, setErrors] = useState<FormErrors>({
		title: '',
		uniquePath: '',
	})
	const copy = useCopy()

	const [showErrorsDisplay, setShowErrorsDisplay] = useState(false)
	const errorMessages = Object.keys(errors)
		.map(key => errors[key])
		.filter(error => error)
	usePageTitle(`${productId ? 'Edit' : 'Create'} Product`)

	const [variantsGeneral, setVariantsGeneral] = useState<VariantsGeneral>({
		0: {
			position: 0,
			title: '',
			description: '',
			shortDescription: '',

			isDescriptionEnabled: false,
		},
	})
	const [variantsCustomFields, setVariantsCustomFields] = useState<VariantsCustomFields>({
		0: {
			enableCustomFields: false,
			customFields: [],
		},
	})
	const [variantsPricing, setVariantsPricing] = useState<VariantsPricing>({
		0: {
			chargeType: ChargeType.ONE_TIME,
			chargeTypes: [ChargeType.ONE_TIME, ChargeType.RECURRING],
			subscriptionSettings: null,
			price: {
				amount: '0.00',
				currency: 'USD',
			},
			gateways: [],
			compareAtPrice: null,
			costPerItem: null,
		},
	})

	const [variantsDelivery, setVariantsDelivery] = useState<VariantsDelivery>({
		0: {
			deliveryConfiguration: {},
			deliveryType: DeliveryType.PRESET,
			maxQuantity: null,
			minQuantity: null,
			deliveryTimeSeconds: '0',
			isDeliveryTimeEnabled: true,
			customDeliveryTimeSeconds: '0',
		},
	})

	const [variantsAdvanced, setVariantsAdvanced] = useState<VariantsAdvanced>({
		0: {
			customWarranty: 0,
			redirectUrl: '',
			terms: '',
			warranty: {
				text: '',
				durationSeconds: '',
			},
			isRedirectUrlEnabled: false,
			isTermsEnabled: false,
			isWarrantyEnabled: false,
			customerNote: '',
			isCustomerNoteEnabled: false,
		},
	})

	const [variantsDiscordSettings, setVariantsDiscordSettings] = useState<VariantsDiscordSettings>({
		0: {
			beforePurchaseRequireRoles: {
				guildId: '',
				roleIds: [],
			},
			beforePurchaseAddUser: {
				guildId: '',
				roleIds: [],
			},
			isEnabled: false,
			isRequired: false,
			isBeforePurchaseRequireRolesEnabled: false,
			isBeforePurchaseAddUserEnabled: false,
		},
	})

	const [isCreateProductLoading, , createProduct] = useAPI(
		(data: ProductCreate) => ProductsService.createProduct(shop?.id || '', data),
		false,
		'Successfully created',
		{
			onSuccess: () => {
				navigate('/products')
			},
		}
	)

	const [isUpdateProductLoading, , updateProduct] = useAPI(
		(data: ProductCreate) => ProductsService.updateProduct(shop?.id || '', Number(productId), data),
		false,
		'Successfully edited',
		{
			onSuccess: () => {
				navigate('/products')
			},
		}
	)

	const [isGetProductLoading, productDetailed, getProduct] = useAPI<ProductDetailed>(
		() => ProductsService.getProduct(shop?.id || '', Number(productId)),
		false
	)

	const validateProductData = () => {
		const uniquePathValidator = fieldValidators.blankValidator('Product URL', uniquePath)
		const titleValidator = fieldValidators.blankValidator('Product title', titleValue)

		const deliverablesValidator = (
			variantsDelivery[currentVariantIndex].deliveryType === DeliveryType.DYNAMIC
				? !variantsDelivery[currentVariantIndex].dynamicDeliveryUrl
				: !Object.keys(variantsDelivery[currentVariantIndex].deliveryConfiguration).length
		)
			? "Product deliverable(s) can't be empty"
			: ''

		setErrors({
			title: titleValidator,
			uniquePath: uniquePathValidator,
			deliverables: deliverablesValidator,
		})

		return !(uniquePathValidator || titleValidator || deliverablesValidator)
	}

	const [isDeleteProductLoading, , deleteProduct] = useAPI<ProductDetailed>(
		() => ProductsService.deleteProduct(shop?.id || '', Number(productId)),
		false,
		'Product successfully deleted',
		{
			onSuccess: () => {
				navigate('/products')
			},
		}
	)

	const setProductData = (productDetailed: ProductDetailed) => {
		let variantsGeneralData: VariantsGeneral = {}
		let variantsCustomFieldsData: VariantsCustomFields = {}
		let variantsPricingData: VariantsPricing = {}
		let variantsDeliveryData: VariantsDelivery = {}
		let variantsDiscordSettingsData: VariantsDiscordSettings = {}
		let variantsAdvancedData: VariantsAdvanced = {}

		setPageTitle(productDetailed.seo.pageTitle)
		setPageDescription(productDetailed.seo.pageDescription)
		setDescription(productDetailed.description)
		setShortDescription(productDetailed?.shortDescription || '')
		setUniquePath(productDetailed.uniquePath)
		setTitleValue(productDetailed.name)
		setVisibility(productDetailed.visibility)
		setImages(productDetailed?.images || [])
		setMetaImage(productDetailed.seo.pageImage || null)

		productDetailed.variants.map(variant => {
			const isWarrantyManual = !warrantySelectOptions.find(
				option => option.value === variant.warranty?.durationSeconds?.toString()
			)
			const isDeliveryTimeCustom = !deliveryTimeOptions.find(
				option => option.value === variant.deliveryTimeSeconds?.toString()
			)
			let deliveryConfiguration: {
				[id: string]: DeliveryConfiguration
			} = {}

			variantsGeneralData = {
				...variantsGeneralData,
				[variant.id]: {
					id: variant.id,
					description: variant.description,
					title: variant.name,
					isDescriptionEnabled: !!variant.description,
					position: variant.position,
				},
			}

			variantsCustomFieldsData = {
				...variantsCustomFieldsData,
				[variant.id]: {
					customFields: variant.customFields,
					enableCustomFields: !!variant.customFields.length,
				},
			}

			variantsDiscordSettingsData = {
				...variantsDiscordSettingsData,
				[variant.id]: {
					...variant.discordSettings,
					isBeforePurchaseAddUserEnabled: !!variant.discordSettings.beforePurchaseAddUser,
					isBeforePurchaseRequireRolesEnabled: !!variant.discordSettings.beforePurchaseRequireRoles,
				},
			}

			variantsAdvancedData = {
				...variantsAdvancedData,
				[variant.id]: {
					isTermsEnabled: !!variant.terms,
					terms: variant.terms,
					redirectUrl: variant.redirectUrl,
					warranty: variant.warranty
						? {
								...variant.warranty,
								durationSeconds:
									!isWarrantyManual && variant.warranty?.durationSeconds !== undefined
										? variant.warranty?.durationSeconds.toString()
										: 'manual',
						  }
						: null,
					isRedirectUrlEnabled: !!variant.redirectUrl,
					isWarrantyEnabled: !!variant.warranty,
					customerNote: variant.customerNote,
					isCustomerNoteEnabled: !!variant.customerNote,
					customWarranty:
						isWarrantyManual && variant.warranty?.durationSeconds
							? +variant.warranty.durationSeconds
							: 0,
				},
			}

			variantsPricingData = {
				...variantsPricingData,
				[variant.id]: {
					chargeType: variant.chargeType,
					chargeTypes: variant.chargeTypes,
					subscriptionSettings: variant.subscriptionSettings,
					gateways: variant.gateways,
					price: variant.price,
					costPerItem: variant.costPerItem,
					compareAtPrice: variant.compareAtPrice,
				},
			}

			variant.deliveryConfigurations?.forEach(deliveryConfigurationItem => {
				deliveryConfiguration[deliveryConfigurationItem?.id || ''] = deliveryConfigurationItem
			})

			variantsDeliveryData = {
				...variantsDeliveryData,
				[variant.id]: {
					dynamicDeliveryUrl: variant.dynamicDeliveryUrl,
					deliveryConfiguration: deliveryConfiguration,
					deliveryType: variant.deliveryType,
					maxQuantity: variant?.maxQuantity || null,
					minQuantity: variant?.minQuantity || null,
					deliveryTimeSeconds:
						variant.deliveryTimeSeconds !== undefined
							? !isDeliveryTimeCustom
								? variant.deliveryTimeSeconds?.toString()
								: 'custom'
							: 'null',
					isDeliveryTimeEnabled: true,
					customDeliveryTimeSeconds: isDeliveryTimeCustom ? variant.deliveryTimeSeconds : '0',
				},
			}
		})

		setCurrentVariantIndex(productDetailed.variants[0].id.toString())

		setVariantsAdvanced(variantsAdvancedData)
		setVariantsDelivery(variantsDeliveryData)
		setVariantsPricing(variantsPricingData)
		setVariantsGeneral(variantsGeneralData)
		setVariantsCustomFields(variantsCustomFieldsData)
		setVariantsDiscordSettings(variantsDiscordSettingsData)
	}

	const onSubmitClickHandler = (duplicate?: boolean) => {
		if (validateProductData()) {
			const data: ProductCreate = {
				description: description || '',
				shortDescription: shortDescription,
				name: titleValue,
				visibility: visibility,
				uniquePath: duplicate ? `${uniquePath}-copy` : uniquePath,
				imageIds: images.map(image => image.id),
				seo: {
					pageDescription: pageDescription,
					pageTitle,
					pageImageId: metaImage?.id,
				},
				variants: Object.keys(variantsGeneral).map(variantId => {
					const variantGeneral = variantsGeneral[variantId]
					const variantPricing = variantsPricing[variantId]
					const variantDelivery = variantsDelivery[variantId]
					const variantCustomFields = variantsCustomFields[variantId]
					const variantAdvanced = variantsAdvanced[variantId]
					const variantDiscordSettings = variantsDiscordSettings[variantId]
					console.log({ variantPricing })

					return {
						position: variantGeneral.position,
						id: variantGeneral.id || null,
						description: variantGeneral.description,
						name: variantGeneral.title,
						price: variantPricing.price,
						customerNote: variantAdvanced.isCustomerNoteEnabled
							? variantAdvanced.customerNote || null
							: null,
						costPerItem: variantPricing.costPerItem,
						compareAtPrice:
							variantPricing.compareAtPrice && +variantPricing.compareAtPrice?.amount
								? variantPricing.compareAtPrice || null
								: null,
						gateways: variantPricing.gateways,
						shortDescription: variantGeneral.shortDescription,
						chargeType: variantPricing.chargeType,
						chargeTypes: variantPricing.chargeTypes,
						subscriptionSettings: variantPricing.subscriptionSettings,
						deliveryType: variantDelivery.deliveryType,
						redirectUrl: variantAdvanced.isRedirectUrlEnabled ? variantAdvanced.redirectUrl : null,
						terms: variantAdvanced.isTermsEnabled ? variantAdvanced.terms : null,
						deliveryTimeSeconds: variantDelivery.isDeliveryTimeEnabled
							? variantDelivery.deliveryTimeSeconds === 'custom'
								? +variantDelivery.customDeliveryTimeSeconds
								: +variantDelivery.deliveryTimeSeconds
							: null,
						warranty: variantAdvanced.isWarrantyEnabled
							? variantAdvanced.warranty?.durationSeconds === 'manual'
								? {
										text: variantAdvanced.warranty.text,
										durationSeconds: +variantAdvanced.customWarranty,
								  }
								: {
										...variantAdvanced.warranty,
										durationSeconds: +variantAdvanced.warranty.durationSeconds,
								  }
							: null,
						discordSettings: {
							beforePurchaseRequireRoles: variantDiscordSettings.isBeforePurchaseRequireRolesEnabled
								? variantDiscordSettings.beforePurchaseRequireRoles
								: null,
							beforePurchaseAddUser: variantDiscordSettings.isBeforePurchaseAddUserEnabled
								? variantDiscordSettings.beforePurchaseAddUser
								: null,
							isEnabled: variantDiscordSettings.isEnabled,
							isRequired: variantDiscordSettings.isRequired,
						},
						customFields: variantCustomFields.enableCustomFields
							? variantCustomFields.customFields
							: [],
						deliveryConfigurations:
							variantDelivery.deliveryType === DeliveryType.PRESET
								? Object.keys(variantDelivery.deliveryConfiguration).map(
										key => variantDelivery.deliveryConfiguration[key]
								  )
								: null,
						dynamicDeliveryUrl: variantDelivery.dynamicDeliveryUrl || null,
						maxQuantity: variantDelivery.maxQuantity || null,
						minQuantity: variantDelivery.minQuantity || null,
					}
				}),
			}

			if (productId && !duplicate) {
				updateProduct<ProductCreate>(data)
			} else {
				createProduct<ProductCreate>(data)
			}
		} else {
			setShowErrorsDisplay(true)
		}
	}

	useEffect(() => {
		if (productDetailed) setProductData(productDetailed)
	}, [productDetailed])

	useEffect(() => {
		if (shop && productId) {
			getProduct()
		}
	}, [shop])

	useEffect(() => {
		if (titleValue && !productDetailed?.uniquePath) {
			setUniquePath(titleValue.toLowerCase().replace(/[^a-z1-9-]+/g, '-'))
		}
		if (!productDetailed?.seo.pageTitle) {
			setPageTitle(`${titleValue} by ${shop?.name}`)
		}

		if (
			productDetailed?.variants.find(variant => variant.position === 0)?.name ===
			productDetailed?.name
		) {
			setVariantsGeneral({
				...variantsGeneral,
				[currentVariantIndex]: {
					...variantsGeneral[currentVariantIndex],
					title: titleValue,
				},
			})
		}
	}, [titleValue])

	useEffect(() => {
		let minPosition = 0
		Object.values(variantsGeneral).forEach(obj => {
			minPosition = Math.min(minPosition, obj.position)
		})

		Object.keys(variantsGeneral).map(
			variantIndex =>
				variantsGeneral[variantIndex].position === minPosition &&
				setCurrentVariantIndex(variantIndex)
		)
	}, [variantsGeneral])

	if (isGetProductLoading) return <PageLoading />

	return (
		<PageContainer>
			<PageWrapper>
				{showErrorsDisplay && !!errorMessages.length && (
					<FormErrorsDisplayBadge
						title={`Product ${productId ? 'Edit' : 'Create'} Error`}
						errors={errorMessages}
					/>
				)}

				{isDeleteModalActive && (
					<Modal
						closeModal={() => closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)}
						visible={isDeleteModalVisible}
						setVisible={setIsDeleteModalVisible}
					>
						<ConfirmModal
							onClose={() => closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)}
							onConfirm={deleteProduct}
							description={`You are about to delete ${productDetailed?.name}. Are you sure you want to delete this product? This cannot be undone.`}
							confirmButtonText={'Delete Product'}
							title={'Delete Product'}
							buttonIcon={{
								id: 'trashBin',
								width: 13,
								height: 13,
								align: ButtonsIconAlign.LEFT,
							}}
							isButtonLoading={isDeleteProductLoading}
						/>
					</Modal>
				)}

				<div className={styles.createProductPage}>
					<PageHeader
						title={`${productId ? 'Edit' : 'Create'} Product`}
						backButton={{ onClick: () => {}, isActive: true }}
					>
						<div className={styles.headerEnd}></div>
					</PageHeader>
					<div className={styles.body}>
						<div className={styles.row}>
							<div className={styles.column}>
								<section className={styles.section} id={CreateProductSectionIds.GENERAL}>
									<ProductGeneral
										titleValue={titleValue}
										setTitleValue={setTitleValue}
										description={description}
										setDescription={setDescription}
										shortDescription={shortDescription}
										setShortDescription={setShortDescription}
										errors={errors}
										setErrors={setErrors}
									/>
								</section>
								<section className={styles.section} id={CreateProductSectionIds.DESIGN}>
									<ProductGroupEdits
										images={images}
										setImages={setImages}
										setVisibility={setVisibility}
										visibility={visibility}
									/>
								</section>

								<ProductVariantsWrapper
									variantsGeneral={variantsGeneral}
									setVariantsGeneral={setVariantsGeneral}
									variantsPricing={variantsPricing}
									setVariantsPricing={setVariantsPricing}
									setCurrentVariantIndex={setCurrentVariantIndex}
									variantsDelivery={variantsDelivery}
									setVariantsDelivery={setVariantsDelivery}
									setVariantsCustomFields={setVariantsCustomFields}
									variantsCustomFields={variantsCustomFields}
									variantsDiscordSettings={variantsDiscordSettings}
									setVariantsDiscordSettings={setVariantsDiscordSettings}
									setVariantsAdvanced={setVariantsAdvanced}
									variantsAdvanced={variantsAdvanced}
									setCurrentVariantsTab={setCurrentVariantsTab}
									currentVariantsTab={currentVariantsTab}
								/>

								<section className={styles.section} id={CreateProductSectionIds.SEO}>
									<ProductSEO
										setMetaTitle={setPageTitle}
										metaDescription={pageDescription}
										metaTitle={pageTitle}
										setMetaDescription={setPageDescription}
										setSlugUrl={setUniquePath}
										slugUrl={uniquePath}
										setMetaImage={setMetaImage}
										metaImage={metaImage}
										errors={errors}
										setErrors={setErrors}
									/>
								</section>
							</div>

							<div className={styles.column}>
								<Button
									style={ButtonStyles.SECONDARY}
									icon={{
										id: 'plus',
										width: 13,
										height: 13,
										align: ButtonsIconAlign.LEFT,
									}}
									onClick={() => onSubmitClickHandler()}
									isLoading={isCreateProductLoading || isUpdateProductLoading}
								>
									{productId ? 'Edit' : 'Create'} product
								</Button>
								{productId && (
									<>
										<Button
											icon={{
												id: 'copy',
												width: 13,
												height: 13,
												align: ButtonsIconAlign.LEFT,
											}}
											onClick={() => onSubmitClickHandler(true)}
											isLoading={isCreateProductLoading}
										>
											Duplicate
										</Button>
										<Button
											icon={{
												id: 'chain',
												width: 13,
												height: 13,
												align: ButtonsIconAlign.LEFT,
											}}
											onClick={() => copy(`${shopDomain}/product/${productDetailed?.uniquePath}`)}
											isLoading={isCreateProductLoading}
										>
											Share link
										</Button>
										<Button
											style={ButtonStyles.SECONDARY}
											icon={{
												id: 'trashBin',
												width: 13,
												height: 13,
												align: ButtonsIconAlign.LEFT,
											}}
											onClick={() => setIsDeleteModalActive(true)}
											isLoading={isDeleteProductLoading}
										>
											Delete
										</Button>
									</>
								)}
								<div className={styles.productAdvisorWrapper}>
									<ProductAdvisor
										shortDescription={shortDescription}
										images={images}
										visibility={visibility}
										variantsAdvanced={variantsAdvanced}
										variantsCustomFields={variantsCustomFields}
										currentVariantIndex={currentVariantIndex}
										variantsDiscordSettings={variantsDiscordSettings}
										metaDescription={pageDescription}
										metaTitle={pageTitle}
										titleValue={titleValue}
										description={description}
										variantsPricing={variantsPricing}
										variantsDelivery={variantsDelivery}
										variantsGeneral={variantsGeneral}
										setCurrentVariantsTab={setCurrentVariantsTab}
										metaImage={metaImage}
										currentVariantsTab={currentVariantsTab}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default CreateProductPage
