import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { useAPI } from '../../../hooks/useAPI'
import { FraudRulesService } from '../../../API/FraudRulesService'
import { FraudRuleType } from '../../../types/FraudRuleType/FraudRuleType'
import { FraudRuleFactor } from '../../../types/FraudRuleFactor/FraudRuleFactor'
import Input from '../../UI/FormComponents/Input'
import { useAppContext } from '../../../hooks/useAppContext'

interface BlacklistCustomerModal extends BaseModalProps {
	email: string
	onSuccess?: () => void
}

const BlacklistCustomerModal: FC<BlacklistCustomerModal> = ({ onClose, email, onSuccess }) => {
	const [note, setNote] = useState('')
	const { shop } = useAppContext()

	const [isBlacklistCustomerLoading, , blacklistCustomer] = useAPI(
		() =>
			FraudRulesService.create(shop?.id || '', {
				type: FraudRuleType.BLOCK,
				factor: FraudRuleFactor.EMAIL,
				data: email,
				note,
				gatewayFilter: [],
			}),
		false,
		'Customer successfully blacklisted',
		{
			onSuccess: () => {
				onClose()
				onSuccess && onSuccess()
			},
		}
	)

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Blacklist Customer</h1>
			</header>

			<div className={styles.body}>
				<p className={styles.description}>
					You’re about to block <b>{email}</b>. Are you sure you want to block this customer?
					Members on your team can unblock them at any time in the future.
				</p>

				<div className={styles.fieldWrapper}>
					<h2>Note</h2>
					<Input
						value={note}
						setValue={setNote}
						placeholder={'Leave a note for future reference...'}
					/>
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'lock2',
							height: 14,
							width: 12,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={blacklistCustomer}
						isLoading={isBlacklistCustomerLoading}
					>
						Blacklist Customer
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default BlacklistCustomerModal
