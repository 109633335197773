import React, { FC } from 'react'
import styles from './DeviceHistory.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { formatDistanceToNow } from 'date-fns'
import { GetAuthSession } from '../../types/GetAuthSession/GetAuthSession'
import { parseUserAgent } from '../../helpers/parseUserAgent'
import { SessionAuthType } from '../../types/SesssionAuthType/SessionAuthType'

interface DeviceHistoryProps {
	onExitClick: (sessionId: number) => void
	session: GetAuthSession
	isSettingsGeneralModifyAvailable: boolean
}

const DeviceHistory: FC<DeviceHistoryProps> = ({
	onExitClick,
	session,
	isSettingsGeneralModifyAvailable,
}) => {
	const { browser, os, device } = parseUserAgent(session?.userAgent || '')

	const getAuthTypeOption = (authType: SessionAuthType) => {
		switch (authType) {
			case SessionAuthType.BASIC:
				return (
					<div data-tooltip-id={'my-tooltip'} data-tooltip-content={'Sign In with Password'}>
						<SpriteIcon iconId={'logo'} width={15} height={18} />
					</div>
				)
			case SessionAuthType.DISCORD_OAUTH:
				return (
					<div data-tooltip-id={'my-tooltip'} data-tooltip-content={'Sign In with Discord OAUTH'}>
						<SpriteIcon iconId={'discord'} width={16} height={12} />
					</div>
				)
			case SessionAuthType.GOOGLE_OAUTH:
				return (
					<div data-tooltip-id={'my-tooltip'} data-tooltip-content={'Sign In with Google OAUTH'}>
						<SpriteIcon iconId={'google'} width={15} height={15} />
					</div>
				)
		}
	}

	return (
		<div className={styles.deviceHistory}>
			<div className={styles.deviceHistoryHead}>
				<div className={styles.deviceHistoryIcon}>
					{device === 'Tablet' || device === 'Mobile' ? (
						<SpriteIcon iconId={'smartphone'} width={10} height={16} />
					) : (
						<SpriteIcon iconId={'monitor'} width={15} height={14} />
					)}
				</div>
				<div>
					<p className={styles.deviceName}>
						{browser}, {os}
					</p>
					<p className={styles.location}>
						{session.ipDetails?.city}, {session.ipDetails?.country} •{' '}
						{formatDistanceToNow(new Date(session.createdAt))} ago
					</p>
				</div>
			</div>
			<div className={styles.deviceHistoryEnd}>
				{getAuthTypeOption(session.authType)}
				{isSettingsGeneralModifyAvailable && (
					<div data-tooltip-id={'my-tooltip'} data-tooltip-content={'Sign Out'}>
						<SpriteIcon
							iconId={'exit'}
							width={13}
							height={13}
							onClick={() => onExitClick(session.id)}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default DeviceHistory
