import { RewardRuleType } from '../types/RewardRuleType/RewardRuleType'
import { BalanceRewardType } from '../types/BalanceRewardType/BalanceRewardType'

export const getRewardRuleTypeOption = (ruleType: RewardRuleType | BalanceRewardType) => {
	switch (ruleType) {
		case BalanceRewardType.cashback:
			return {
				title: 'Cashback Spending',
				description:
					'Gift your customers a percentage of the amount they spent back in the form of on-site credit through cash back rewards.',
				icon: {
					id: 'piggyBank',
					width: 26,
					height: 20,
				},
			}
		case BalanceRewardType.topUpBonus:
			return {
				title: 'Topup Bonus',
				description:
					'An exclusive bonus for customers that top up their balance with a minimum you set across any payment type.',
				icon: {
					id: 'packet',
					width: 22,
					height: 19,
				},
			}
		case RewardRuleType.REFER_FRIEND:
			return {
				title: 'Refer a Friend',
				description:
					'A built-in affiliate system that rewards customers based on who they invite at any time or based within a timeframe.',
				icon: {
					id: 'turningArrow',
					height: 20,
					width: 23,
				},
			}
		case RewardRuleType.ORDER_COUNT:
			return {
				title: 'On X Orders, Get Y',
				description:
					'Give rewards tied directly to the number of orders customers have paid for throughout a certain period.',
				icon: {
					id: 'lightning',
					height: 26,
					width: 19,
				},
			}
		// case RuleTypes.referrals:
		// 	return {
		// 		title: 'Earn X% from Referrals',
		// 		description:
		// 			'Give customers a percent of the payment via balance of the friends they refer to your store.',
		// 		icon: {
		// 			id: 'gamepad',
		// 			height: 14.5,
		// 			width: 14.5,
		// 		},
		// 	}
		case RewardRuleType.LEAVE_REVIEW:
			return {
				title: 'Leave a Review',
				description:
					'Automatically reward customers if they leave a positive review after they complete a purchase.',
				icon: {
					id: 'star2',
					width: 23,
					height: 23,
				},
			}
		case RewardRuleType.SPEND_AMOUNT:
			return {
				title: 'Spend $X, Get Y',
				description:
					'Reward your most loyal customers with exclusive drops and content based on how much they spend.',
				icon: {
					id: 'dollarCircle',
					width: 24,
					height: 24,
				},
			}
		default:
			return {
				title: 'Leave a Review',
				description:
					'Automatically reward customers if they leave a positive review after they complete a purchase.',
				icon: {
					id: 'star2',
					width: 23,
					height: 23,
				},
			}
	}
}
