import { privateAPI } from './API'
import { FAQCreate } from '../types/FAQ/FAQCreate'

export const FaqsService = {
	getAll: async (shopId: string, searchString?: string, PageNumber?: number, PageSize?: number) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/faqs`, {
			params: {
				searchString,
				PageNumber,
				PageSize,
			},
		})
	},
	create: async (shopId: string, data: FAQCreate) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/faqs`, data)
	},
	getOne: async (shopId: string, id: number) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/faqs/${id}`)
	},
	update: async (shopId: string, id: number, data: FAQCreate) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/faqs/${id}`, data)
	},
	delete: async (shopId: string, id: number | null) => {
		return await privateAPI.delete(`/v1/dash/shops/${shopId}/faqs/${id}`)
	},
}
