import React, { FC, useState } from 'react'
import Modal from '../Modals/Modal'
import { closeModal } from '../../helpers/closeModal'
import UpgradeSubscriptionModal from '../Modals/UpgradeSubscriptionModal/UpgradeSubscriptionModal'
import { Subscriptions } from '../../types/Subscriptions/Subscriptions'

interface UpgradeSubscriptionProps {
	isSubscriptionModalActive: boolean
	setIsSubscriptionModalActive: (value: boolean) => void
	requiredSubscription: Subscriptions
}

const UpgradeSubscription: FC<UpgradeSubscriptionProps> = ({
	isSubscriptionModalActive,
	setIsSubscriptionModalActive,
	requiredSubscription,
}) => {
	const [isSubscriptionModalVisible, setIsSubscriptionModalVisible] = useState(false)

	return (
		<>
			{isSubscriptionModalActive && (
				<Modal
					closeModal={() => closeModal(setIsSubscriptionModalActive, setIsSubscriptionModalVisible)}
					visible={isSubscriptionModalVisible}
					setVisible={setIsSubscriptionModalVisible}
				>
					<UpgradeSubscriptionModal
						subscription={requiredSubscription}
						onClose={() => closeModal(setIsSubscriptionModalActive, setIsSubscriptionModalVisible)}
					/>
				</Modal>
			)}
		</>
	)
}

export default UpgradeSubscription
