import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Switch from '../../UI/Switch/Switch'
import Input from '../../UI/FormComponents/Input'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { DeliveryProductType } from '../../../types/DeliveryProductType/DeliveryProductType'
import HelpLink from '../../UI/HelpLink/HelpLink'
import Textarea from '../../UI/FormComponents/Textarea'
import ChooseProductTypeModalSerials from './ChooseProductTypeModalSerials'
import { DeliveryConfiguration } from '../../../types/Product/DeliveryConfiguration'
import { DeliveryConfigurationType } from '../../../types/DeliveryConfigurationType/DeliveryConfigurationType'
import { useAPI } from '../../../hooks/useAPI'
import { DiscordUserService } from '../../../API/DiscordUserService'
import ChooseProductTypeModalDiscord from './ChooseProductTypeModalDiscord'
import { MultiValue } from 'react-select'
import OptionalBadge from '../../UI/OptionalBadge/OptionalBadge'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'

interface ChooseProductTypeModalInnerStepProps {
	limitStock: number
	setLimitStock: (value: number) => void
	selectedDeliveryConfigurationType: DeliveryConfigurationType
	currentWarehouseId: string
	setCurrentWarehouseId: (value: string) => void
	discordGuild: string
	setDiscordGuild: (value: string) => void
	setDiscordRoles: (value: string[]) => void
	discordRoles: string[]
	textData: string
	setTextData: (value: string) => void
	editId?: string | null
	setIsLimitStockEnabled: (value: boolean) => void
	isLimitStockEnabled: boolean
	setIsCustomNameEnabled: (value: boolean) => void
	isCustomNameEnabled: boolean
	setStock: (value: number | null) => void
	stock: number | null
	customName: string
	setCustomName: (value: string) => void
	errors: FormErrors
	setErrors: (errors: FormErrors) => void
}

const ChooseProductTypeModalInnerStep: FC<ChooseProductTypeModalInnerStepProps> = ({
	selectedDeliveryConfigurationType,
	setLimitStock,
	limitStock,
	currentWarehouseId,
	setCurrentWarehouseId,
	discordRoles,
	setDiscordRoles,
	setDiscordGuild,
	discordGuild,
	setIsLimitStockEnabled,
	isLimitStockEnabled,
	textData,
	setTextData,
	editId,
	stock,
	setStock,
	isCustomNameEnabled,
	setIsCustomNameEnabled,
	setCustomName,
	customName,
	errors,
	setErrors,
}) => {
	return (
		<div>
			{selectedDeliveryConfigurationType === DeliveryConfigurationType.DISCORD && (
				<ChooseProductTypeModalDiscord
					discordGuild={discordGuild}
					discordRoles={discordRoles}
					setDiscordGuild={setDiscordGuild}
					setDiscordRoles={setDiscordRoles}
					editId={editId}
				/>
			)}

			{selectedDeliveryConfigurationType === DeliveryConfigurationType.DOWNLOAD && (
				<div className={styles.fieldWrapper}>
					<header>
						<span>
							Upload your file to your own server or third-party content manager and paste the
							download link.
						</span>
					</header>
					<Input
						value={textData}
						setValue={setTextData}
						placeholder={'Link to access/download file'}
						errorMessage={errors['downloadLink']}
						onBlur={() =>
							setErrors({
								...errors,
								downloadLink: fieldValidators.URLValidator('Download Link', textData),
							})
						}
					/>

					<HelpLink href={''} text={'View detailed help article for download setup'} />
				</div>
			)}

			{selectedDeliveryConfigurationType === DeliveryConfigurationType.EBOOK && (
				<div className={styles.fieldWrapper}>
					<header>
						<span>
							Send educational content to your customers. Upload a direct download or document link
							below.
						</span>
					</header>
					<Input
						value={textData}
						setValue={setTextData}
						placeholder={'Link to document/ebook file'}
						errorMessage={errors['ebookLink']}
						onBlur={() =>
							setErrors({
								...errors,
								ebookLink: fieldValidators.URLValidator('Ebook Link', textData),
							})
						}
					/>

					<HelpLink href={''} text={'View detailed help article for ebook setup'} />
				</div>
			)}

			{selectedDeliveryConfigurationType === DeliveryConfigurationType.TEXT && (
				<div className={styles.fieldWrapper}>
					<header>
						<span>Send a custom note to customers or sell professional/freelance services.</span>
					</header>
					<Textarea
						value={textData}
						setValue={setTextData}
						placeholder={
							'Thanks for purchasing our one-time digital service. You will receive the service momentarily.'
						}
						height={150}
						errorMessage={errors['serviceNote']}
						onBlur={() =>
							setErrors({
								...errors,
								serviceNote: fieldValidators.blankValidator('Service Note', textData),
							})
						}
					/>
				</div>
			)}

			{selectedDeliveryConfigurationType === DeliveryConfigurationType.CUSTOM && (
				<div className={styles.fieldWrapper}>
					<header>
						<span>Deliver a customized product type.</span>
					</header>
					<Textarea
						value={textData}
						setValue={setTextData}
						placeholder={'Thanks for purchasing our one-time digital custom perk.'}
						height={150}
						errorMessage={errors['customNote']}
						onBlur={() =>
							setErrors({
								...errors,
								customNote: fieldValidators.blankValidator('Custom Note', textData),
							})
						}
					/>
				</div>
			)}

			{selectedDeliveryConfigurationType === DeliveryConfigurationType.LINK && (
				<div className={styles.fieldWrapper}>
					<header>
						<span>
							Sell access to custom links that your customers will receive after checkout.
						</span>
					</header>
					<Input
						value={textData}
						setValue={setTextData}
						placeholder={'Custom link (https://example.com)'}
						errorMessage={errors['customLink']}
						onBlur={() =>
							setErrors({
								...errors,
								customLink: fieldValidators.URLValidator('Custom Link', textData),
							})
						}
					/>
				</div>
			)}

			{/*{selectedDeliveryConfigurationType === DeliveryConfigurationType.TEXT && (*/}
			{/*	<div className={styles.fieldWrapper}>*/}
			{/*		<header>*/}
			{/*			<span>Set a custom perk that will be delivered to customers.</span>*/}
			{/*		</header>*/}
			{/*		<Textarea*/}
			{/*			value={text}*/}
			{/*			setValue={setText}*/}
			{/*			placeholder={'Thanks for purchasing our one-time digital custom perk.'}*/}
			{/*			height={150}*/}
			{/*		/>*/}
			{/*	</div>*/}
			{/*)}*/}

			{selectedDeliveryConfigurationType === DeliveryConfigurationType.SERIALS && (
				<ChooseProductTypeModalSerials
					currentWarehouseId={currentWarehouseId}
					setCurrentWarehouseId={setCurrentWarehouseId}
					setStock={setStock}
				/>
			)}

			<div className={styles.horLine} />

			<div className={styles.fieldWrapper}>
				<header>
					<div>
						<h2>
							LIMIT STOCK <OptionalBadge />
						</h2>
					</div>
					<Switch checked={isLimitStockEnabled} setChecked={setIsLimitStockEnabled} />
				</header>
				{isLimitStockEnabled && (
					<>
						<Input value={limitStock} setValue={setLimitStock} type={'number'} placeholder={'0'} />
						<p className={styles.fieldDescription}>
							Set a custom number of product stock/availability.
						</p>
					</>
				)}
			</div>
			<div className={styles.fieldWrapper}>
				<header>
					<div>
						<h2>
							CUSTOM NAME <OptionalBadge />
						</h2>
					</div>
					<Switch checked={isCustomNameEnabled} setChecked={setIsCustomNameEnabled} />
				</header>
				{isCustomNameEnabled && (
					<>
						<Input value={customName} setValue={setCustomName} placeholder={'Custom Name'} />
						<p className={styles.fieldDescription}>
							This custom name will be available via the dashboard and storefront.
						</p>
					</>
				)}
			</div>
		</div>
	)
}

export default ChooseProductTypeModalInnerStep
