import React, { FC, useRef, useState } from 'react'
import styles from './Tables.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { NavLink } from 'react-router-dom'
import Checkbox from '../UI/Checkbox/Checkbox'
import ProductTypeBadge from '../UI/ProductTypeBadge/ProductTypeBadge'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import { Image } from '../../types/Image/Image'
import { splitImageNameAndExtension } from '../../helpers/splitImageNameAndExtension'
import { format } from 'date-fns'

interface ImagesTableBodyProps {
	image: Image
	onDeleteClick: (id: number) => void
	isContentDeleteAvailable: boolean
}

interface ImagesTableProps {
	images: Image[]
	onDeleteClick: (id: number) => void
	isContentDeleteAvailable: boolean
}

const ImagesTableBody: FC<ImagesTableBodyProps> = ({
	image,
	onDeleteClick,
	isContentDeleteAvailable,
}) => {
	const [imageName, imageExtension] = splitImageNameAndExtension(image.fileName)
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)
	const tableBodyRef = useRef<HTMLDivElement>(null)

	const actionMenuButtons = []

	isContentDeleteAvailable &&
		actionMenuButtons.push({
			onClick: () => onDeleteClick(image.id),
			icon: {
				id: 'trashBin',
				width: 12,
				height: 12,
			},
			title: 'Delete image',
		})

	return (
		<div
			className={`${styles.trWrapper} ${isActionMenuActive && styles.actionMenuActive}`}
			style={{
				paddingBottom: isActionMenuActive ? +actionMenuButtons.length * 50 : 5,
			}}
			ref={tableBodyRef}
		>
			<tr>
				<td className={styles.fullWidth}>
					<div className={styles.itemInfo}>
						<div className={styles.itemImage}>
							<img src={`https://imagedelivery.net/${image.cfId}/productCard`} alt='' />
						</div>
						<div>
							<p>{imageName}</p>
							<span>{imageExtension.toUpperCase()}</span>
						</div>
					</div>
				</td>
				<td>
					<p>{format(new Date(image.createdAt), 'MMM dd, yyyy')}</p>
					<span>{format(new Date(image.createdAt), 'hh:mm aa z')}</span>
				</td>
				<td className={styles.small}>
					<ActionMenu
						actionButtons={actionMenuButtons}
						isActive={isActionMenuActive}
						setIsActive={setIsActionMenuActive}
					/>
				</td>
			</tr>
		</div>
	)
}

const ImagesTable: FC<ImagesTableProps> = ({ images, onDeleteClick, isContentDeleteAvailable }) => {
	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.fullWidth}>File Name</th>
					<th>Date Added</th>
					<th className={styles.small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{images.map(image => (
					<ImagesTableBody
						image={image}
						key={image.id}
						onDeleteClick={onDeleteClick}
						isContentDeleteAvailable={isContentDeleteAvailable}
					/>
				))}
			</tbody>
		</table>
	)
}

export default ImagesTable
