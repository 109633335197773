import { privateAPI } from './API'
import { ShopForUserPermissions } from '../types/ShopForUserPermissions/ShopForUserPermissions'

export const ManagersService = {
	getAll: async (shopId: string) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/managers`)
	},
	update: async (
		shopId: string,
		userId: number,
		data: {
			permissions: ShopForUserPermissions[]
		}
	) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/managers/${userId}`, data)
	},
	delete: async (shopId: string, userId: number) => {
		return await privateAPI.delete(`/v1/dash/shops/${shopId}/managers/${userId}`)
	},
}
