import React, { FC } from 'react'
import styles from './AverageRatingStatistics.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import gradientStarSrc from '../../assets/images/gradientStar.svg'
import { GetReviewsStats } from '../../types/GetReviewsStats/GetReviewsStats'

interface AverageRatingStatisticsProps {
	reviewsStats: GetReviewsStats
}

const AverageRatingStatistics: FC<AverageRatingStatisticsProps> = ({ reviewsStats }) => {
	const totalRatings =
		reviewsStats.stars5Count +
		reviewsStats.stars4Count +
		reviewsStats.stars3Count +
		reviewsStats.stars2Count +
		reviewsStats.stars1Count

	const weightedSum =
		5 * reviewsStats.stars5Count +
		4 * reviewsStats.stars4Count +
		3 * reviewsStats.stars3Count +
		2 * reviewsStats.stars2Count +
		reviewsStats.stars1Count

	const averageRating = totalRatings > 0 ? weightedSum / totalRatings : 0
	const roundToTwoDecimals = (value: number) => Math.round(value * 100) / 100

	const percentages = {
		stars5: roundToTwoDecimals((reviewsStats.stars5Count / totalRatings) * 100),
		stars4: roundToTwoDecimals((reviewsStats.stars4Count / totalRatings) * 100),
		stars3: roundToTwoDecimals((reviewsStats.stars3Count / totalRatings) * 100),
		stars2: roundToTwoDecimals((reviewsStats.stars2Count / totalRatings) * 100),
		stars1: roundToTwoDecimals((reviewsStats.stars1Count / totalRatings) * 100),
	}

	return (
		<div className={styles.averageRatingStatistics}>
			<header className={styles.averageRatingStatisticsHeader}>
				<img src={gradientStarSrc} alt={'gradient star'} />
				<p className={styles.ratingNumber}>{averageRating}</p>
				<p className={styles.ratingCount}>
					({totalRatings} review{totalRatings > 1 ? 's' : ''})
				</p>
			</header>

			<div className={styles.ratingStatistics}>
				<div className={styles.ratingStatisticsItem}>
					<div className={styles.stars}>
						<SpriteIcon iconId={'ratingStar'} width={16} height={16} />
						<SpriteIcon iconId={'ratingStar'} width={16} height={16} />
						<SpriteIcon iconId={'ratingStar'} width={16} height={16} />
						<SpriteIcon iconId={'ratingStar'} width={16} height={16} />
						<SpriteIcon iconId={'ratingStar'} width={16} height={16} />
					</div>
					<div className={styles.statistics}>
						<div className={styles.lineBar}>
							<span
								className={styles.lineBarActive}
								style={{
									width: percentages.stars5 + '%',
								}}
							/>
						</div>
						<p>{percentages.stars5}%</p>
					</div>
				</div>
				<div className={styles.ratingStatisticsItem}>
					<div className={styles.stars}>
						<SpriteIcon iconId={'ratingStar'} width={16} height={16} />
						<SpriteIcon iconId={'ratingStar'} width={16} height={16} />
						<SpriteIcon iconId={'ratingStar'} width={16} height={16} />
						<SpriteIcon iconId={'ratingStar'} width={16} height={16} />
					</div>
					<div className={styles.statistics}>
						<div className={styles.lineBar}>
							<span
								className={styles.lineBarActive}
								style={{
									width: percentages.stars4 + '%',
								}}
							/>
						</div>
						<p>{percentages.stars4}%</p>
					</div>
				</div>
				<div className={styles.ratingStatisticsItem}>
					<div className={styles.stars}>
						<SpriteIcon iconId={'ratingStar'} width={16} height={16} />
						<SpriteIcon iconId={'ratingStar'} width={16} height={16} />
						<SpriteIcon iconId={'ratingStar'} width={16} height={16} />
					</div>
					<div className={styles.statistics}>
						<div className={styles.lineBar}>
							<span
								className={styles.lineBarActive}
								style={{
									width: percentages.stars3 + '%',
								}}
							/>
						</div>
						<p>{percentages.stars3}%</p>
					</div>
				</div>
				<div className={styles.ratingStatisticsItem}>
					<div className={styles.stars}>
						<SpriteIcon iconId={'ratingStar'} width={16} height={16} />
						<SpriteIcon iconId={'ratingStar'} width={16} height={16} />
					</div>
					<div className={styles.statistics}>
						<div className={styles.lineBar}>
							<span
								className={styles.lineBarActive}
								style={{
									width: percentages.stars2 + '%',
								}}
							/>
						</div>
						<p>{percentages.stars2}%</p>
					</div>
				</div>
				<div className={styles.ratingStatisticsItem}>
					<div className={styles.stars}>
						<SpriteIcon iconId={'ratingStar'} width={16} height={16} />
					</div>
					<div className={styles.statistics}>
						<div className={styles.lineBar}>
							<span
								className={styles.lineBarActive}
								style={{
									width: percentages.stars1 + '%',
								}}
							/>
						</div>
						<p>{percentages.stars1}%</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AverageRatingStatistics
