import React, { FC, useRef, useState } from 'react'
import styles from './Tables.module.scss'
import { useNavigate } from 'react-router-dom'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { AuditLog } from '../../types/AuditLog/AuditLog'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'
import { format } from 'date-fns'
import { getAction } from '../../helpers/getActionFromEndpoints'

interface AuditLogTableItemProps {
	onViewDetailsClick: (auditLog: AuditLog) => void
	auditLog: AuditLog
}

const AuditLogTableItem: FC<AuditLogTableItemProps> = ({ onViewDetailsClick, auditLog }) => {
	const isAdmin =
		auditLog.user.permissions.includes(ShopForUserPermissions.ADMIN) ||
		auditLog.user.permissions.includes(ShopForUserPermissions.OWNER)
	const tableBodyRef = useRef<HTMLDivElement>(null)

	return (
		<div className={styles.trWrapper} ref={tableBodyRef}>
			<tr>
				<td className={styles.large}>
					<p>{getAction({ method: auditLog.method, path: auditLog.url })}</p>
				</td>
				<td>
					<div className={`${styles.itemInfo} ${styles.secondary}`}>
						{isAdmin ? (
							<SpriteIcon iconId={'crown'} width={14} height={14} />
						) : (
							<SpriteIcon iconId={'personCircle'} width={14} height={14} />
						)}
						<div>
							<p>{auditLog.user.email}</p>
						</div>
					</div>
				</td>
				<td>
					<p>{format(new Date(auditLog.createdAt), "MMM dd, yyyy 'at' hh:mm aa z")}</p>
				</td>

				<td className={styles.medium}>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'arrowRight',
							width: 12,
							height: 10,
							align: ButtonsIconAlign.RIGHT,
							className: styles.dark,
						}}
						disableShadow={true}
						onClick={() => onViewDetailsClick(auditLog)}
					>
						View Details
					</Button>
				</td>
			</tr>
		</div>
	)
}

interface AuditLogTableProps {
	onViewDetailsClick: (auditLog: AuditLog) => void
	auditLogs: AuditLog[]
}

const AuditLogTable: FC<AuditLogTableProps> = ({ onViewDetailsClick, auditLogs }) => {
	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.large}>Event</th>
					<th>Team Member</th>
					<th>Date</th>
					<th className={styles.medium}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{auditLogs.map(auditLog => (
					<AuditLogTableItem
						auditLog={auditLog}
						onViewDetailsClick={onViewDetailsClick}
						key={auditLog.id}
					/>
				))}
			</tbody>
		</table>
	)
}

export default AuditLogTable
