import React, { FC } from 'react'
import styles from './StockAlertInput.module.scss'
import formStyles from '../FormComponents.module.scss'
import StockStatusBadge from '../../StockStatusBadge/StockStatusBadge'
import WarehouseStatusBadge from '../../StatusBadges/WarehouseStatusBadge'
import { WarehouseStatus } from '../../../../types/WarehouseStatus/WarehouseStatus'

interface StockAlertInputProps {
	value: number
	setValue: (value: number) => void
	warehouseStatus: WarehouseStatus
}

const StockAlertInput: FC<StockAlertInputProps> = ({ setValue, value, warehouseStatus }) => {
	return (
		<div className={styles.stockAlertInput}>
			<input
				className={formStyles.input}
				type='number'
				value={value}
				onChange={event => setValue(+event.target.value)}
			/>

			<div className={styles.statusBadgeWrapper}>
				<WarehouseStatusBadge warehouseStatus={warehouseStatus} />
			</div>
		</div>
	)
}

export default StockAlertInput
