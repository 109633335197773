export const stockDelimiterHandler = (
	delimiter: string,
	isEdit?: boolean,
	customDelimiter?: string
) => {
	if (!isEdit) {
		switch (delimiter) {
			case 'Comma':
				return ','
			case 'NewLine':
				return '\n'
			case 'Custom':
				return customDelimiter || ''
			default:
				return ','
		}
	}

	switch (delimiter) {
		case ',':
			return 'Comma'
		case '\n':
			return 'NewLine'
		default:
			return 'Custom'
	}
}
