export enum Apps {
	GOOGLE_ANALYTICS,
	CRISP_CHAT,
	WOO_COMMERCE,
	PASSWORD_PROTECTION,
	SURVEYS,
	LANGUAGES,
	STOCK_ALERTS,
	SALES_PROOF,
	GLOBAL_BLACKLISTS,
	TELEGRAM,
	DISCORD,
	TAWK,
	GOOGLE_TAG_MANAGER,
}
