import React, { FC, MutableRefObject, useEffect, useState } from 'react'
import styles from '../AuthPages.module.scss'
import { SpriteIcon } from '../../../components/UI/SpriteIcon/SpriteIcon'
import Input from '../../../components/UI/FormComponents/Input'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../components/UI/Button/Button'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAPI } from '../../../hooks/useAPI'
import { AuthService } from '../../../API/AuthService'
import StatusAlertBadge from '../../../components/UI/StatusAlertBadge/StatusAlertBadge'
import { StatusAlert } from '../../../types/StatusAlert/StatusAlert'
import { fieldValidators } from '../../../helpers/fieldValidators'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fi } from 'date-fns/locale'
import { usePageTitle } from '../../../hooks/usePageTitle'
import AlternativeAuthVariants from '../../../components/AlternativeAuthVariants/AlternativeAuthVariants'

interface SignUpPageProps {
	recaptchaRef: MutableRefObject<any>
}

const SignUpPage: FC<SignUpPageProps> = ({ recaptchaRef }) => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [errors, setErrors] = useState<FormErrors>({
		email: '',
		password: '',
		confirmPassword: '',
	})
	const navigate = useNavigate()
	usePageTitle('Sign Up')

	const signUpValidator = () => {
		const isEmailMatchesToRegexp = email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
		const emailValidator = fieldValidators.emailValidator(email)
		const passwordValidator = fieldValidators.passwordValidator(password)
		const confirmPasswordValidator = password !== confirmPassword ? 'Passwords did not match' : ''

		setErrors({
			...errors,
			email: emailValidator,
			password: passwordValidator,
			confirmPassword: confirmPasswordValidator,
		})

		return !!email && !!isEmailMatchesToRegexp && !!password && password === confirmPassword
	}

	const [isRegisterBasicLoading, , registerBasic] = useAPI<{ accessToken: string }>(
		async () => {
			const recaptcha = await recaptchaRef.current.executeAsync()
			recaptchaRef.current.reset()

			return AuthService.registerBasic({
				email,
				password: password,
				recaptcha: recaptcha,
			})
		},
		false,
		'Successfully signed up',
		{
			validator: signUpValidator,
			onSuccess: response => {
				localStorage.setItem('accessToken', response.data.data.accessToken)
				window.location.pathname = '/auth/setup-store'
			},
		}
	)

	return (
		<>
			<div className={styles.authInner}>
				<div className={styles.logo}>
					<SpriteIcon iconId={'nameLogo'} width={124} height={27} />
				</div>
				<h1 className={styles.title}>Sign up to sell digital products</h1>

				<div className={styles.fieldWrapper}>
					<h2 className={styles.fieldTitle}>Email address</h2>
					<Input
						value={email}
						setValue={setEmail}
						placeholder={'Enter your email address'}
						errorMessage={errors.email}
						onBlur={() =>
							setErrors({
								...errors,
								email: fieldValidators.emailValidator(email),
							})
						}
					/>
				</div>

				<div className={styles.fieldWrapper}>
					<h2 className={styles.fieldTitle}>Password</h2>
					<Input
						value={password}
						setValue={setPassword}
						placeholder={'*****************'}
						type={'password'}
						errorMessage={errors.password}
						onBlur={() =>
							setErrors({
								...errors,
								password: fieldValidators.passwordValidator(password),
							})
						}
					/>
				</div>

				<div className={styles.fieldWrapper}>
					<h2 className={styles.fieldTitle}>Confirm password</h2>
					<Input
						value={confirmPassword}
						setValue={setConfirmPassword}
						placeholder={'*****************'}
						type={'password'}
						errorMessage={errors.confirmPassword}
						onBlur={() =>
							setErrors({
								...errors,
								confirmPassword: password !== confirmPassword ? 'Passwords did not match' : '',
							})
						}
					/>
				</div>

				<div className={styles.button}>
					<Button
						style={ButtonStyles.PRIMARY}
						onClick={registerBasic}
						isLoading={isRegisterBasicLoading}
					>
						Sign Up
					</Button>
				</div>

				<p className={styles.helperText}>
					By signing up, you agree to the Billgang{' '}
					<a href='https://billgang.com/privacy-policy' target={'_blank'}>
						Privacy Policy
					</a>{' '}
					and{' '}
					<a href='https://billgang.com/terms-of-service' target={'_blank'}>
						Terms of Service
					</a>
					.
				</p>

				<div className={styles.OR}>
					<span>OR</span>
				</div>

				<AlternativeAuthVariants />

				<p className={styles.helperText}>
					Already have an account? <NavLink to='/auth/sign-in'>Sign In</NavLink>.
				</p>
			</div>
		</>
	)
}

export default SignUpPage
