import React, { FC } from 'react'
import styles from './GoogleAuthenticator.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'

interface GoogleAuthenticatorProps {
	onEditButtonClick: () => void
	isActive: boolean
}

const GoogleAuthenticator: FC<GoogleAuthenticatorProps> = ({ onEditButtonClick, isActive }) => {
	return (
		<div className={styles.googleAuthenticator}>
			<div className={styles.googleAuthenticatorHead}>
				<SpriteIcon iconId={'googleAuthenticator'} width={27} height={24} />
				<div>
					<h2 className={styles.googleAuthenticatorTitle}>Google Authenticator</h2>
					<p className={styles.googleAuthenticatorDescription}>
						Secure time-based one-time 6-digit codes
					</p>
				</div>
			</div>
			<div className={styles.googleAuthenticatorEnd}>
				<SpriteIcon iconId={'pen'} width={15} height={15} onClick={onEditButtonClick} />
				{isActive ? (
					<SpriteIcon
						className={styles.checkedCircle}
						iconId={'checkedCircle'}
						width={16}
						height={16}
					/>
				) : (
					<SpriteIcon
						className={styles.xMarkCircle}
						iconId={'xMarkCircle'}
						width={16}
						height={16}
					/>
				)}
			</div>
		</div>
	)
}

export default GoogleAuthenticator
