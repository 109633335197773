import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { useAPI } from '../../../hooks/useAPI'
import { OrdersService } from '../../../API/OrdersService'
import Input from '../../UI/FormComponents/Input'

interface ResendOrderModalProps extends BaseModalProps {
	orderId: string | undefined
	customerEmail?: string
	shopId: string
	getOrder: () => void
}

const ResendOrderModal: FC<ResendOrderModalProps> = ({
	onClose,
	orderId,
	shopId,
	getOrder,
	customerEmail,
}) => {
	const [email, setEmail] = useState('')

	const [isResendOrderLoading, , resendOrder] = useAPI(
		() =>
			OrdersService.resendOrder(shopId, orderId, {
				email: email || customerEmail || '',
			}),
		false,
		'Order successfully resend',
		{
			onSuccess: () => {
				onClose()
				getOrder()
			},
		}
	)

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Resend Order</h1>
			</header>

			<div className={styles.body}>
				<p id={styles.description}>
					If your customer never received the product in their inbox, re-enter the customer email in
					the field below.
				</p>

				<div className={styles.mtMediumLarge}>
					<div className={styles.fieldWrapper}>
						<h2>Customer Email</h2>
						<Input value={email} setValue={setEmail} placeholder={'customer@gmail.com'} />
					</div>
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Close
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'dollarCircle',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={resendOrder}
						isLoading={isResendOrderLoading}
					>
						Resend Order
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default ResendOrderModal
