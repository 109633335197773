import React, { FC } from 'react'
import styles from '../SettingsMigratePage.module.scss'
import Input from '../../../../components/UI/FormComponents/Input'
import CheckboxWrapper from '../../../../components/UI/CheckboxWrapper/CheckboxWrapper'
import { MigrateOptions } from '../SettingsMigratePage'

interface SelectDataTransferStepProps {
	migrateOptions: MigrateOptions
	setMigrateOptions: (value: MigrateOptions) => void
}

const SelectDataTransferStep: FC<SelectDataTransferStepProps> = ({
	setMigrateOptions,
	migrateOptions,
}) => {
	return (
		<div className={styles.mtMediumLarge}>
			<p className={styles.secondaryText}>
				To complete your automated migration to Billgang from Sellpass, please select the data you
				want transferred from the previous platform.
			</p>
			<p className={`${styles.secondaryText} ${styles.mt10}`}>
				Note: If customer data overlaps, we’ll merge the data under one customer profile. For
				products, we’ll create a new copy without overriding previous data.
			</p>
			<p className={`${styles.secondaryText} ${styles.mt10}`}>
				To access more data transfers (such as analytics, tickets, blacklists, etc), you are
				REQUIRED to select both products and orders.
			</p>
			<div className={`${styles.twoColumns} ${styles.mtLarge}`}>
				<CheckboxWrapper
					isReversed={true}
					id={'Products'}
					checked={migrateOptions.migrateProducts}
					setChecked={checked =>
						setMigrateOptions({
							...migrateOptions,
							migrateProducts: checked,
						})
					}
					title={'Products And Warehouses'}
					description={'Transfer all products with deliverables.'}
				/>
				<CheckboxWrapper
					isReversed={true}
					id={'Orders'}
					checked={migrateOptions.migrateOrders}
					setChecked={checked =>
						setMigrateOptions({
							...migrateOptions,
							migrateOrders: checked,
						})
					}
					title={'Orders And Reviews'}
					description={'Transfer all orders, reviews and payment transaction history.'}
				/>
			</div>
		</div>
	)
}

export default SelectDataTransferStep
