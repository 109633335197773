import { pageLayoutId } from '../components/PagesLayout/PagesLayout'

export const scrollIntoViewAndFocus = (elementId: string) => {
	const element = document.getElementById(elementId)
	const container = document.getElementById(pageLayoutId)

	if (element) {
		// Calculate the offset to scroll the element into the center of the viewport
		const elementRect = element.getBoundingClientRect()
		const offset =
			elementRect.top -
			((container ? container.scrollHeight : window.innerHeight) / 2 - elementRect.height / 2)

		// Scroll to the calculated offset

		if (container) {
			container.scrollBy({
				top: offset,
				behavior: 'smooth',
			})
		}

		setTimeout(() => {
			element.focus()
		}, 500)
	}
}
