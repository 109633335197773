import { privateAPI } from './API'
import { CustomerAccountsStatus } from '../types/CustomerAccountsStatus/CustomerAccountsStatus'

export const SettingsService = {
	getCompany: async (shopId: string) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/settings/company`)
	},
	getLegal: async (shopId: string) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/settings/legal`)
	},
	updateSubdomain: async (shopId: string, subdomain: string) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/subdomain`, {
			subdomain,
		})
	},
	updateName: async (shopId: string, name: string) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/name`, {
			name,
		})
	},
	updateCompanyInfo: async (shopId: string, data: { phoneNumber: string; email: string }) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/company-info`, data)
	},
	updateWebsiteSettings: async (
		shopId: string,
		data: { onHold: boolean; password: string | null; redirectUrl: string | null }
	) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/website-settings`, data)
	},
	updateTimezoneAndCurrency: async (
		shopId: string,
		data: { timeZoneInfo: string; currency: string }
	) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/timezone-and-currency`, data)
	},
	updateAutomatedReviews: async (shopId: string, data: { isEnabled: boolean }) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/automated-reviews`, data)
	},
	updatePostPurchaseRequest: async (shopId: string, data: { days: number | null }) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/post-purchase-request`, data)
	},
	updateCustomerAccounts: async (shopId: string, data: { status: CustomerAccountsStatus }) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/customer-accounts`, data)
	},
	updateTermsOfService: async (
		shopId: string,
		data: { content: string | null; showOnProducts: boolean }
	) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/legal/terms-of-service`, data)
	},
	updateRefundPolicy: async (
		shopId: string,
		data: { content: string | null; showOnProducts: boolean }
	) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/legal/refund-policy`, data)
	},
	updatePrivacyPolicy: async (
		shopId: string,
		data: { content: string | null; showOnProducts: boolean }
	) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/legal/privacy-policy`, data)
	},
	updateLogo: async (shopId: string, imageId: number) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/settings/logo`, {
			imageId,
		})
	},
}
