import React, { useEffect } from 'react'
import Modal from '../../../components/Modals/Modal'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAPI } from '../../../hooks/useAPI'
import { StripeService } from '../../../API/StripeService'
import PageLoading from '../../../components/UI/PageLoading/PageLoading'
import { PaymentsSettingsService } from '../../../API/PaymentsSettingsService'
import { useAppContext } from '../../../hooks/useAppContext'
import { usePageTitle } from '../../../hooks/usePageTitle'

const StripeConnectPage = () => {
	const location = useLocation()
	const { shop } = useAppContext()
	const navigate = useNavigate()
	const params = new URLSearchParams(location.search)
	const codeValue = params.get('code')
	usePageTitle(`Connecting Stripe`)

	const [, , updateStripe] = useAPI(
		() =>
			PaymentsSettingsService.updateGateway(shop?.id || '', 'Stripe', {
				accounts: [
					{
						savedSettings: {
							OauthCode: codeValue,
						},
						maxAmountOfOrdersPerDay: null,
						maxAmountOfOrdersPerMonth: null,
						maxAmountOfOrdersPerWeek: null,
						maxRevenuePerDay: null,
						maxRevenuePerMonth: null,
						maxRevenuePerWeek: null,
						priority: 1,
						blockVpnProxy: false,
					},
				],
				overrideShownPaymentMethods: null,
			}),
		false,
		'Stripe successfully connected',
		{
			onSuccess: () => {
				navigate('/settings/payments')
			},
		}
	)

	useEffect(() => {
		if (codeValue && shop) {
			updateStripe()
		}
	}, [shop])

	return <PageLoading />
}

export default StripeConnectPage
