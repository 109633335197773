import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAPI } from '../../../hooks/useAPI'
import Spinner from '../../../components/UI/Spinner/Spinner'
import { DiscordUserService } from '../../../API/DiscordUserService'
import { UserSecurityService } from '../../../API/UserSecurityService'
import { usePageTitle } from '../../../hooks/usePageTitle'

const DiscordConnectPage = () => {
	const location = useLocation()
	const code = location.search.slice(6)
	usePageTitle(`Connecting Google`)

	const [, , connectGoogle] = useAPI(
		() =>
			UserSecurityService.connectGoogle({
				code,
				redirectUrl: `${
					window.location.hostname === 'localhost'
						? 'http://localhost:3000'
						: 'https://dash.billgang.com'
				}/settings/connects/discord`,
			}),
		false,
		'Successfully connected',
		{
			onSuccess: () => {
				window.close()
			},
		}
	)

	useEffect(() => {
		if (code) {
			connectGoogle()
		}
	}, [])

	return <Spinner />
}

export default DiscordConnectPage
