import React, { FC, useEffect } from 'react'
import styles from '../../OrderDetailedPage.module.scss'
import ProductsDeliveredTable from '../../../../components/Tables/PartOrdersTable'
import { PartOrderDeliveryCategory } from '../../../../types/PartOrderDeliveryCategory/PartOrderDeliveryCategory'
import { PartOrder } from '../../../../types/PartOrder/PartOrder'
import OrderReplacementsTable from '../../../../components/Tables/OrderReplacementsTable'
import { PartOrderReplacement } from '../../../../types/PartOrderReplacement/PartOrderReplacement'

interface OrdersReplacementHistoryProps {
	partOrders: PartOrder[]
	onViewDeliveredReplacementClick: (productId: number, replacementId: number) => void
}

const OrdersReplacementHistory: FC<OrdersReplacementHistoryProps> = ({
	partOrders,
	onViewDeliveredReplacementClick,
}) => {
	const partOrderReplacements: PartOrderReplacement[] = []

	useEffect(() => {
		partOrders.forEach(partOrder => {
			partOrder.replacements.forEach(good => {
				partOrderReplacements.push({
					...good,
					productWithVariant: partOrder.productWithVariant,
					quantity: partOrder.quantity,
					deliveryStatus: partOrder.deliveryStatus,
					partOrderId: partOrder.id,
				})
			})
		})
	}, [partOrders])

	if (!partOrderReplacements.length) return null

	return (
		<div className={styles.section}>
			<header className={styles.header}>
				<h1>Replacement History</h1>
			</header>

			<OrderReplacementsTable
				partOrderReplacements={partOrderReplacements}
				onViewClick={onViewDeliveredReplacementClick}
			/>

			<footer className={styles.footer}>
				<button className={styles.footerButton}>View Replacement Logs</button>
			</footer>
		</div>
	)
}

export default OrdersReplacementHistory
