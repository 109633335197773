import React, { FC } from 'react'
import styles from './DeliveryProductStock.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { DeliveryProductType } from '../../types/DeliveryProductType/DeliveryProductType'
import { DeliveryConfigurationType } from '../../types/DeliveryConfigurationType/DeliveryConfigurationType'
import { productDeliverablesOptions } from '../../helpers/productDeliverablesOptions'

interface DeliveryProductStockProps {
	deliveryConfigurationType: DeliveryConfigurationType
	customName?: string | null
	onEditClick: () => void
	onDeleteClick: () => void
	stock: number | null
}

const DeliveryProductStock: FC<DeliveryProductStockProps> = ({
	deliveryConfigurationType,
	onEditClick,
	onDeleteClick,
	stock,
	customName,
}) => {
	return (
		<div className={styles.deliveryProductStock}>
			<div className={styles.icon}>
				<SpriteIcon
					iconId={productDeliverablesOptions[deliveryConfigurationType].icon.id}
					width={productDeliverablesOptions[deliveryConfigurationType].icon.width}
					height={productDeliverablesOptions[deliveryConfigurationType].icon.height}
				/>
			</div>
			<div className={styles.info}>
				<h2>{customName || productDeliverablesOptions[deliveryConfigurationType].title}</h2>
				<p>Stock: {stock === 0 ? 0 : stock || '∞'}</p>
			</div>
			<div className={styles.buttons}>
				<SpriteIcon iconId={'pen'} width={15} height={15} onClick={onEditClick} />
				<SpriteIcon iconId={'trashBin'} width={14} height={15} onClick={onDeleteClick} />
			</div>
		</div>
	)
}

export default DeliveryProductStock
