import React, { FC } from 'react'
import modalStyles from '../Modals.module.scss'
import Input from '../../UI/FormComponents/Input'

interface TopUpRuleFormProps {
	bonusPercentage: number
	setBonusPercentage: (value: number) => void
	minimumTopUp: number
	setMinimumTopUp: (value: number) => void
}

const TopUpRuleForm: FC<TopUpRuleFormProps> = ({
	minimumTopUp,
	setMinimumTopUp,
	setBonusPercentage,
	bonusPercentage,
}) => {
	return (
		<div>
			<div className={modalStyles.fieldWrapper}>
				<h2>BONUS PERCENTAGE</h2>
				<Input
					value={bonusPercentage}
					setValue={setBonusPercentage}
					placeholder={'e.g: 5%'}
					type={'number'}
				/>
			</div>
			<div className={modalStyles.fieldWrapper}>
				<h2>Minimum Topup</h2>
				<Input value={minimumTopUp} setValue={setMinimumTopUp} placeholder={'$0'} type={'number'} />
			</div>
		</div>
	)
}

export default TopUpRuleForm
