import React, { useEffect, useState } from 'react'
import styles from './AllProductsPage.module.scss'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageHeader from '../../components/PageHeader/PageHeader'
import SearchInput, { SearchInputStyles } from '../../components/UI/SearchInput/SearchInput'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import Dropdown from '../../components/UI/Dropdown/Dropdown'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import { useNavigate } from 'react-router-dom'
import Modal from '../../components/Modals/Modal'
import UpdateCustomerNoteModal from '../../components/Modals/ProductUpdateModals/UpdateCustomerNoteModal'
import { closeModal } from '../../helpers/closeModal'
import UpdateVisibilityModal from '../../components/Modals/ProductUpdateModals/UpdateVisibilityModal'
import UpdateDiscordIntegrationModal from '../../components/Modals/ProductUpdateModals/UpdateDiscordIntegrationModal'
import UpdateCustomFieldsModal from '../../components/Modals/ProductUpdateModals/UpdateCustomFieldsModal'
import UpdatePaymentMethodsModal from '../../components/Modals/ProductUpdateModals/UpdatePaymentMethodsModal'
import ProductCard from '../../components/ProductCard/ProductCard'
import { useAPI } from '../../hooks/useAPI'
import { ProductsService } from '../../API/ProductsService'
import { useAppContext } from '../../hooks/useAppContext'
import { Product } from '../../types/Product/Product'
import Spinner from '../../components/UI/Spinner/Spinner'
import noProductsImage from '../../assets/images/noProducts.png'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import Pagination from '../../components/UI/Pagination/Pagination'
import PageTableHeader from '../../components/PageTableHeader/PageTableHeader'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import { useDebounce } from '../../hooks/useDebounce'
import { ProductDetailed } from '../../types/Product/ProductDetailed'
import { ProductCreate } from '../../types/Product/ProductCreate'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import { usePageTitle } from '../../hooks/usePageTitle'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

const AllProductsPage = () => {
	const [searchValue, setSearchValue] = useState('')
	const [totalItems, setTotalItems] = useState(0)
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [isCustomerNoteModalActive, setIsCustomerNoteModalActive] = useState(false)
	const [isCustomerNoteModalVisible, setIsCustomerNoteModalVisible] = useState(false)
	const [isCustomFieldsModalActive, setIsCustomFieldsModalActive] = useState(false)
	const [isCustomFieldsModalVisible, setIsCustomFieldsModalVisible] = useState(false)
	const [isDiscordIntegrationModalActive, setIsDiscordIntegrationModalActive] = useState(false)
	const [isDiscordIntegrationModalVisible, setIsDiscordIntegrationModalVisible] = useState(false)
	const [isVisibilityModalActive, setIsVisibilityModalActive] = useState(false)
	const [isVisibilityModalVisible, setIsVisibilityModalVisible] = useState(false)
	const [isPaymentMethodsModalActive, setIsPaymentMethodsModalActive] = useState(false)
	const [isPaymentMethodsModalVisible, setIsPaymentMethodsModalVisible] = useState(false)
	const [isDeleteModalActive, setIsDeleteModalActive] = useState(false)
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
	const [selectedProducts, setSelectedProducts] = useState<number[] | null>([])
	const selectedProductsLength = selectedProducts ? selectedProducts.length : totalItems
	const { shop } = useAppContext()
	const navigate = useNavigate()
	const [deletingProduct, setDeletingProduct] = useState<Product | null>()
	const isAdmin =
		shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isProductsViewAvailable =
		shop?.permissions.includes(ShopForUserPermissions.PRODUCTS_VIEW) || isAdmin
	const isProductsModifyAvailable =
		shop?.permissions.includes(ShopForUserPermissions.PRODUCTS_MODIFY) || isAdmin
	const isProductsDeleteAvailable =
		shop?.permissions.includes(ShopForUserPermissions.PRODUCTS_DELETE) || isAdmin
	usePageTitle('Products')

	const [isGetProductsLoading, products, getProducts] = useAPI<Product[]>(
		(searchValue: string) =>
			ProductsService.getProducts(shop?.id || '', searchValue, currentPage + 1, pageSize),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	const [, , createProduct] = useAPI(
		(data: ProductCreate) => ProductsService.createProduct(shop?.id || '', data),
		false,
		'Product successfully duplicated',
		{
			onSuccess: () => {
				getProducts()
			},
		}
	)

	const [isDeleteProductLoading, , deleteProduct] = useAPI(
		(productId: number) => ProductsService.deleteProduct(shop?.id || '', productId),
		false,
		'Product successfully deleted',
		{
			onSuccess: () => {
				getProducts()
				closeDeleteModal()
			},
		}
	)

	const [, , getProduct] = useAPI<ProductDetailed>(
		(productId: number) => ProductsService.getProduct(shop?.id || '', productId),
		false,
		undefined,
		{
			onSuccess: response => {
				const product = response.data.data as ProductDetailed

				createProduct<ProductCreate>({
					name: product.name,
					description: product.description,
					visibility: product.visibility,
					shortDescription: product.shortDescription,
					uniquePath: product.uniquePath + '-copy',
					seo: product.seo,
					imageIds: product.images?.map(image => image.id),
					variants: product.variants as any,
				})
			},
		}
	)

	useDebounce(searchValue, getProducts, 400)

	const isFirstLoading = useFirstLoading(isGetProductsLoading)

	const onDuplicateProductClickHandler = (id: number) => {
		getProduct(id)
	}

	const onDeleteProductClickHandler = (product: Product) => {
		setIsDeleteModalActive(true)
		setDeletingProduct(product)
	}

	const onProductClickHandler = (id: number) => {
		if (selectedProducts && !selectedProducts.includes(id)) {
			setSelectedProducts([...selectedProducts, id])
		}
	}

	const closeDeleteModal = () => {
		closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)
		setDeletingProduct(null)
	}

	const bulkUpdateOptions = [
		{
			text: 'Update Payment',
			icon: {
				id: 'dollarCircle',
				height: 13,
				width: 13,
			},
			onClick: () => setIsPaymentMethodsModalActive(true),
		},
		{
			text: 'Update Note',
			icon: {
				id: 'note',
				height: 14,
				width: 14,
			},
			onClick: () => setIsCustomerNoteModalActive(true),
		},

		{
			text: 'Update Custom Fields',
			icon: {
				id: 'inputField',
				height: 11,
				width: 15,
			},
			onClick: () => setIsCustomFieldsModalActive(true),
		},
		{
			text: 'Update Visibility',
			icon: {
				id: 'eye',
				height: 9,
				width: 15,
			},
			onClick: () => setIsVisibilityModalActive(true),
		},
		{
			text: 'Update Discord',
			icon: {
				id: 'discordStroke',
				height: 10,
				width: 14,
			},
			onClick: () => setIsDiscordIntegrationModalActive(true),
		},
	]

	useEffect(() => {
		if (shop && isProductsViewAvailable) {
			getProducts()
		}
	}, [shop, currentPage, pageSize])

	if (isFirstLoading) {
		return <PageLoading />
	}

	if (!isProductsViewAvailable) {
		return (
			<NoDataComponent
				imageSrc={noProductsImage}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)
	}

	if (!products?.length && !searchValue) {
		return (
			<NoDataComponent
				imageSrc={noProductsImage}
				title={'No Products'}
				description={'You don’t have any products. To create a product, tap on the button below.'}
				button={
					isProductsModifyAvailable
						? {
								icon: {
									id: 'plus',
									height: 13,
									width: 13,
									align: ButtonsIconAlign.LEFT,
								},
								onClick: () => navigate('create'),
								text: 'Create Product',
						  }
						: null
				}
			/>
		)
	}

	return (
		<PageContainer>
			{isDeleteModalActive && (
				<Modal
					closeModal={closeDeleteModal}
					visible={isDeleteModalVisible}
					setVisible={setIsDeleteModalVisible}
				>
					<ConfirmModal
						onClose={closeDeleteModal}
						onConfirm={() => deletingProduct && deleteProduct(deletingProduct.id)}
						description={`You are about to delete ${deletingProduct?.name}. Are you sure you want to delete this product? This cannot be undone.`}
						confirmButtonText={'Delete Product'}
						title={'Delete Product'}
						buttonIcon={{
							id: 'trashBin',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						isButtonLoading={isDeleteProductLoading}
					/>
				</Modal>
			)}

			{isCustomerNoteModalActive && (
				<Modal
					closeModal={() => closeModal(setIsCustomerNoteModalActive, setIsCustomerNoteModalVisible)}
					visible={isCustomerNoteModalVisible}
					setVisible={setIsCustomerNoteModalVisible}
				>
					<UpdateCustomerNoteModal
						onClose={() => closeModal(setIsCustomerNoteModalActive, setIsCustomerNoteModalVisible)}
						productIds={selectedProducts}
						getProducts={getProducts}
					/>
				</Modal>
			)}

			{isVisibilityModalActive && (
				<Modal
					closeModal={() => closeModal(setIsVisibilityModalActive, setIsVisibilityModalVisible)}
					visible={isVisibilityModalVisible}
					setVisible={setIsVisibilityModalVisible}
				>
					<UpdateVisibilityModal
						getProducts={getProducts}
						productIds={selectedProducts}
						onClose={() => closeModal(setIsVisibilityModalActive, setIsVisibilityModalVisible)}
					/>
				</Modal>
			)}

			{isDiscordIntegrationModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsDiscordIntegrationModalActive, setIsDiscordIntegrationModalVisible)
					}
					visible={isDiscordIntegrationModalVisible}
					setVisible={setIsDiscordIntegrationModalVisible}
				>
					<UpdateDiscordIntegrationModal
						productIds={selectedProducts}
						getProducts={getProducts}
						onClose={() =>
							closeModal(setIsDiscordIntegrationModalActive, setIsDiscordIntegrationModalVisible)
						}
					/>
				</Modal>
			)}

			{isCustomFieldsModalActive && (
				<Modal
					closeModal={() => closeModal(setIsCustomFieldsModalActive, setIsCustomFieldsModalVisible)}
					visible={isCustomFieldsModalVisible}
					setVisible={setIsCustomFieldsModalVisible}
				>
					<UpdateCustomFieldsModal
						onClose={() => closeModal(setIsCustomerNoteModalActive, setIsCustomerNoteModalVisible)}
						productIds={selectedProducts}
						getProducts={getProducts}
					/>
				</Modal>
			)}

			{isPaymentMethodsModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsPaymentMethodsModalActive, setIsPaymentMethodsModalVisible)
					}
					visible={isPaymentMethodsModalVisible}
					setVisible={setIsPaymentMethodsModalVisible}
				>
					<UpdatePaymentMethodsModal
						getProducts={getProducts}
						productIds={selectedProducts}
						onClose={() =>
							closeModal(setIsPaymentMethodsModalActive, setIsPaymentMethodsModalVisible)
						}
					/>
				</Modal>
			)}

			<PageWrapper>
				{products && (
					<div className={styles.allProductsPage}>
						<PageHeader title={'Products'}>
							<div className={styles.headerEnd}>
								<SearchInput
									customClass={styles.searchInput}
									value={searchValue}
									setValue={setSearchValue}
									style={SearchInputStyles.secondary}
									placeholder={'Search for a product'}
								/>
								{isProductsModifyAvailable && (
									<Button
										style={ButtonStyles.SECONDARY}
										icon={{
											id: 'plus',
											height: 13,
											width: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										onClick={() => navigate('create')}
									>
										Create Product
									</Button>
								)}
							</div>
						</PageHeader>

						<div className={styles.productsList}>
							{selectedProductsLength > 0 && (
								<div className={styles.managementOptions}>
									<p className={styles.selectedInfo}>
										{selectedProductsLength} product{selectedProductsLength > 1 ? 's' : ''} selected
									</p>
									<div className={styles.option}>
										<Button
											style={ButtonStyles.LIGHT}
											icon={{
												id: 'opposedArrows',
												width: 14,
												height: 14,
												align: ButtonsIconAlign.LEFT,
											}}
											onClick={() => setSelectedProducts(null)}
										>
											Select All
										</Button>
									</div>
									<div className={styles.option}>
										<Button
											style={ButtonStyles.LIGHT}
											icon={{
												id: 'facingArrows',
												width: 14,
												height: 14,
												align: ButtonsIconAlign.LEFT,
											}}
											onClick={() => setSelectedProducts([])}
										>
											Deselect
										</Button>
									</div>
									{isProductsModifyAvailable && (
										<div className={styles.option}>
											<Dropdown title={'Bulk Update'} options={bulkUpdateOptions} />
										</div>
									)}

									{isProductsDeleteAvailable && (
										<div className={styles.option}>
											<Button
												style={ButtonStyles.SECONDARY}
												icon={{
													id: 'trashBin',
													width: 12,
													height: 13,
													align: ButtonsIconAlign.LEFT,
												}}
											>
												Delete
											</Button>
										</div>
									)}
								</div>
							)}

							<PageTableHeader
								listItemNamePlural={'products'}
								totalItems={totalItems}
								pageSize={pageSize}
								setPageSize={setPageSize}
								setCurrentPage={setCurrentPage}
							/>

							{isGetProductsLoading ? (
								<Spinner />
							) : (
								<div className={styles.products}>
									{products?.map(product => (
										<ProductCard
											key={product.id}
											product={product}
											isSelected={selectedProducts ? selectedProducts.includes(product.id) : true}
											onDeselect={() =>
												selectedProducts &&
												setSelectedProducts(
													selectedProducts.filter(productId => productId !== product.id)
												)
											}
											onDuplicateClick={onDuplicateProductClickHandler}
											onDeleteClick={onDeleteProductClickHandler}
											onClick={onProductClickHandler}
											isProductsDeleteAvailable={isProductsDeleteAvailable}
											isProductsModifyAvailable={isProductsModifyAvailable}
										/>
									))}
								</div>
							)}
						</div>

						<div className={styles.paginationWrapper}>
							<Pagination
								withoutPageSizeSelector={true}
								totalItems={totalItems}
								pageSize={pageSize}
								setPageSize={setPageSize}
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
							/>
						</div>
					</div>
				)}
			</PageWrapper>
		</PageContainer>
	)
}

export default AllProductsPage
