import React, { FC } from 'react'
import styles from './TeamMember.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { Manager } from '../../types/Manager/Manager'
import { format } from 'date-fns'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'
import { Invite } from '../../types/Invite/Invite'

interface TeamMemberInviteProps {
	invite: Invite
	onDeleteClick: (invite: Invite) => void
}
const TeamMemberInvite: FC<TeamMemberInviteProps> = ({ invite, onDeleteClick }) => {
	return (
		<div className={styles.teamMember}>
			<div className={styles.teamMemberHead}>
				<div className={styles.teamMemberIcon}>
					{invite.permissions.includes(ShopForUserPermissions.ADMIN) ? (
						<SpriteIcon iconId={'crown'} width={16} height={12} />
					) : (
						<SpriteIcon iconId={'person'} width={16} height={12} />
					)}
				</div>
				<div>
					<p className={styles.teamMemberEmail}>{invite.inviteToEmail}</p>
					{invite.createdAt && (
						<time>
							Invite sent on {format(new Date(invite.createdAt), "MMM dd, yyyy 'at' hh:mm aa z")}
						</time>
					)}
				</div>
			</div>
			<div className={styles.teamMemberEnd}>
				<SpriteIcon
					iconId={'trashBin'}
					width={16}
					height={16}
					onClick={() => onDeleteClick(invite)}
				/>
			</div>
		</div>
	)
}

export default TeamMemberInvite
