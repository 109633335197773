import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import styles from './AllCouponsPage.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import SearchInput, { SearchInputStyles } from '../../components/UI/SearchInput/SearchInput'
import { useNavigate } from 'react-router-dom'
import Pagination from '../../components/UI/Pagination/Pagination'
import CouponsTable from '../../components/Tables/CouponsTable'
import { useAPI } from '../../hooks/useAPI'
import { CouponsService } from '../../API/CouponsService'
import { useAppContext } from '../../hooks/useAppContext'
import { Coupon } from '../../types/Coupon/Coupon'
import Spinner from '../../components/UI/Spinner/Spinner'
import PageTableHeader from '../../components/PageTableHeader/PageTableHeader'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import noCouponsImage from '../../assets/images/noCoupons.png'
import { useDebounce } from '../../hooks/useDebounce'
import Modal from '../../components/Modals/Modal'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import { closeModal } from '../../helpers/closeModal'
import { usePageTitle } from '../../hooks/usePageTitle'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

const AllCouponsPage = () => {
	const [searchValue, setSearchValue] = useState('')
	const [totalItems, setTotalItems] = useState(23)
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [deletingCouponId, setDeletingCouponId] = useState<null | number>(null)
	const [isDeleteModalActive, setIsDeleteModalActive] = useState(false)
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
	const navigate = useNavigate()
	const { shop } = useAppContext()
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isCouponsViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.COUPONS_VIEW) || isAdmin
	const isCouponsModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.COUPONS_MODIFY) || isAdmin
	const isCouponsDeleteAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.COUPONS_DELETE) || isAdmin
	usePageTitle('Coupons')

	const [isGetCouponsLoading, coupons, getCoupons] = useAPI<Coupon[]>(
		() => CouponsService.getAll(shop?.id || '', searchValue, currentPage + 1, pageSize),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	const [isDeleteCouponLoading, , deleteCoupon] = useAPI(
		(deletingCouponId: number) => CouponsService.delete(shop?.id || '', deletingCouponId),
		false,
		'Coupon successfully deleted',
		{
			onSuccess: () => {
				closeDeleteModal()
				getCoupons()
			},
		}
	)

	const isFirstLoading = useFirstLoading(isGetCouponsLoading)
	useDebounce(searchValue, getCoupons, 400)

	const onDeleteCouponClickHandler = (couponId: number) => {
		setDeletingCouponId(couponId)
		setIsDeleteModalActive(true)
	}

	const closeDeleteModal = () => {
		closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)
		setDeletingCouponId(null)
	}

	useEffect(() => {
		if (shop && isCouponsViewAvailable) {
			getCoupons()
		}
	}, [shop, currentPage, pageSize])

	if (isFirstLoading) {
		return <PageLoading />
	}

	if (!isCouponsViewAvailable) {
		return (
			<NoDataComponent
				imageSrc={noCouponsImage}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)
	}

	if (!coupons?.length && !searchValue) {
		return (
			<NoDataComponent
				imageSrc={noCouponsImage}
				title={'No Coupons'}
				description={'You don’t have any coupons. To create a coupon, tap on the button below.'}
				button={
					isCouponsModifyAvailable
						? {
								icon: {
									id: 'plus',
									height: 13,
									width: 13,
									align: ButtonsIconAlign.LEFT,
								},
								onClick: () => navigate('create'),
								text: 'New Coupon',
						  }
						: null
				}
			/>
		)
	}

	return (
		<PageContainer>
			{isDeleteModalActive && (
				<Modal
					closeModal={closeDeleteModal}
					visible={isDeleteModalVisible}
					setVisible={setIsDeleteModalVisible}
				>
					<ConfirmModal
						title={'Delete Coupon'}
						description={
							'You are about to delete coupon. Are you sure you want to delete this category? This cannot be undone.'
						}
						onConfirm={() => deleteCoupon(deletingCouponId)}
						isButtonLoading={isDeleteCouponLoading}
						onClose={closeDeleteModal}
						buttonIcon={{
							id: 'trashBin',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						confirmButtonText={'Delete Coupon'}
					/>
				</Modal>
			)}
			<PageWrapper>
				<div className={styles.allGroupsPage}>
					<PageHeader title={'Coupons'}>
						<div className={styles.headerEnd}>
							<SearchInput
								value={searchValue}
								setValue={setSearchValue}
								style={SearchInputStyles.secondary}
								placeholder={'Search for coupons by code or discount name'}
							/>
							{isCouponsModifyAvailable && (
								<Button
									style={ButtonStyles.SECONDARY}
									icon={{
										id: 'plus',
										height: 13,
										width: 13,
										align: ButtonsIconAlign.LEFT,
									}}
									onClick={() => navigate('create')}
								>
									Create Coupon
								</Button>
							)}
						</div>
					</PageHeader>

					<PageTableHeader
						listItemNamePlural={'coupons'}
						pageSize={pageSize}
						setPageSize={setPageSize}
						totalItems={totalItems}
						setCurrentPage={setCurrentPage}
					/>

					{isGetCouponsLoading ? (
						<Spinner />
					) : (
						coupons && (
							<div className={styles.groupsList}>
								<CouponsTable
									coupons={coupons}
									onDeleteClick={onDeleteCouponClickHandler}
									isCouponsModifyAvailable={isCouponsModifyAvailable}
									isCouponsDeleteAvailable={isCouponsDeleteAvailable}
								/>
							</div>
						)
					)}
					<div className={styles.paginationWrapper}>
						<Pagination
							withoutPageSizeSelector={true}
							totalItems={totalItems}
							pageSize={pageSize}
							setPageSize={setPageSize}
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
						/>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default AllCouponsPage
