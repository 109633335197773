import React, { FC, useRef, useState } from 'react'
import styles from './Tables.module.scss'
import FraudRuleFactorBadge from '../UI/FraudShieldTypeBadge/FraudRuleFactorBadge'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import { FraudRuleType } from '../../types/FraudRuleType/FraudRuleType'
import { FraudRule } from '../../types/FraudRule/FraudRule'
import { format, formatDistanceToNow } from 'date-fns'

interface FraudRulesTableProps {
	fraudRuleType: FraudRuleType
	fraudRules: FraudRule[]
	onDeleteClick: (fraudRuleId: number) => void
	isFraudDeleteAvailable: boolean
}

interface FraudRulesTableBodyProps {
	fraudRule: FraudRule
	onDeleteClick: (fraudRuleId: number) => void
	isFraudDeleteAvailable: boolean
}

const FraudRulesTableBody: FC<FraudRulesTableBodyProps> = ({
	fraudRule,
	onDeleteClick,
	isFraudDeleteAvailable,
}) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)

	const actionMenuButtons = []

	isFraudDeleteAvailable &&
		actionMenuButtons.push({
			onClick: () => onDeleteClick(fraudRule.id),
			icon: {
				id: 'trashBin',
				width: 12,
				height: 13,
			},
			title: `Delete ${fraudRule.type === FraudRuleType.ALLOW ? 'Whitelist' : 'Blacklist'}`,
		})

	return (
		<div
			className={styles.trWrapper}
			style={{
				paddingBottom: isActionMenuActive ? +actionMenuButtons.length * 50 : 5,
			}}
		>
			<tr>
				<td className={styles.large}>
					<div className={styles.itemInfo}>
						<div>
							<p>{fraudRule.data}</p>
							<span>ID: {fraudRule.id}</span>
						</div>
					</div>
				</td>
				<td>
					<FraudRuleFactorBadge factor={fraudRule.factor} />
				</td>
				<td className={styles.extraLarge}>{fraudRule.note || '-'}</td>
				<td>
					<p>{formatDistanceToNow(new Date(fraudRule.createdAt))} ago</p>
					<span>{format(new Date(fraudRule.createdAt), 'hh:mm aa z')} </span>
				</td>
				<td className={styles.small}>
					<ActionMenu
						actionButtons={actionMenuButtons}
						isActive={isActionMenuActive}
						setIsActive={setIsActionMenuActive}
					/>
				</td>
			</tr>
		</div>
	)
}

const FraudRulesTable: FC<FraudRulesTableProps> = ({
	fraudRuleType,
	fraudRules,
	onDeleteClick,
	isFraudDeleteAvailable,
}) => {
	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.large}>
						{fraudRuleType === FraudRuleType.ALLOW ? 'Allowed' : 'Blocked'} Data
					</th>
					<th>Type</th>
					<th className={styles.extraLarge}>Note</th>
					<th>Date & Time</th>
					<th className={styles.small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{fraudRules.map(fraudRule => (
					<FraudRulesTableBody
						fraudRule={fraudRule}
						key={fraudRule.id}
						onDeleteClick={onDeleteClick}
						isFraudDeleteAvailable={isFraudDeleteAvailable}
					/>
				))}
			</tbody>
		</table>
	)
}

export default FraudRulesTable
