import React, { useEffect, useState } from 'react'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import styles from '../Settings.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../components/UI/Button/Button'
import Input from '../../../components/UI/FormComponents/Input'
import NotificationsTable from '../../../components/Tables/NotificationsTable'
import { useAPI } from '../../../hooks/useAPI'
import { NotificationSettingsService } from '../../../API/NotificationSettingsService'
import { useAppContext } from '../../../hooks/useAppContext'
import { NotificationSettings } from '../../../types/NotificationSettings/NotificationSettings'
import PageLoading from '../../../components/UI/PageLoading/PageLoading'
import { ShopForUserNotificationType } from '../../../types/ShopForUserNotificationType/ShopForUserNotificationType'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'
import OrangeBadge from '../../../components/UI/OrangeBadge/OrangeBadge'
import { SpriteIcon } from '../../../components/UI/SpriteIcon/SpriteIcon'
import { NotificationEvent } from '../../../types/NotificationEvent/NotificationEvent'
import { ShopForUserPermissions } from '../../../types/ShopForUserPermissions/ShopForUserPermissions'
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent'
import pageNotFoundImage from '../../../assets/images/404.png'
import { Subscriptions } from '../../../types/Subscriptions/Subscriptions'

const SettingsNotificationsPage = () => {
	const { shop, user, setIsSubscriptionModalActive, setRequiredSubscription } = useAppContext()
	const [webhookUrl, _setWebhookUrl] = useState('')
	const [errors, setErrors] = useState<FormErrors>({})

	const [emailNotifications, _setEmailNotifications] = useState<NotificationEvent[]>([])
	const [discordNotifications, _setDiscordNotifications] = useState<NotificationEvent[]>([])
	const [telegramNotifications, _setTelegramNotifications] = useState<NotificationEvent[]>([])
	const setWebhookUrl = (e: string) => {
		if (user?.subscription.perks.discordNotifications) {
			_setWebhookUrl(e)
		} else {
			setIsSubscriptionModalActive(true)
			setRequiredSubscription(Subscriptions.BUSINESS)
		}
	}
	const setEmailNotifications = (e: NotificationEvent[]) => {
		if (user?.subscription.perks.emailNotifications) {
			_setEmailNotifications(e)
		} else {
			setIsSubscriptionModalActive(true)
			setRequiredSubscription(Subscriptions.BUSINESS)
		}
	}
	const setDiscordNotifications = (e: NotificationEvent[]) => {
		if (user?.subscription.perks.discordNotifications) {
			_setDiscordNotifications(e)
		} else {
			setIsSubscriptionModalActive(true)
			setRequiredSubscription(Subscriptions.BUSINESS)
		}
	}
	const setTelegramNotifications = (e: NotificationEvent[]) => {
		if (user?.subscription.perks.telegramNotifications) {
			_setTelegramNotifications(e)
		} else {
			setIsSubscriptionModalActive(true)
			setRequiredSubscription(Subscriptions.BUSINESS)
		}
	}
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isSettingsGeneralViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.SETTINGS_GENERAL_VIEW) || isAdmin
	const isSettingsGeneralModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.SETTINGS_GENERAL_MODIFY) || isAdmin

	const [isGetNotificationSettingsLoading, notificationSettings, getNotificationSettings] =
		useAPI<NotificationSettings>(() => NotificationSettingsService.get(shop?.id || ''), false)

	const [isUpdateNotificationSettingsLoading, , updateNotificationSettings] =
		useAPI<NotificationSettings>(
			() =>
				NotificationSettingsService.updateNotifications(shop?.id || '', {
					discordNotifications,
					emailNotifications,
					telegramNotifications,
				}),
			false,
			'Notification settings updated successfully',
			{
				onSuccess: getNotificationSettings,
			}
		)

	const [isUpdateNotificationDiscordWebhookLoading, , updateNotificationDiscordWebhook] =
		useAPI<NotificationSettings>(
			() =>
				NotificationSettingsService.updateNotificationsDiscordWebhook(shop?.id || '', {
					url: webhookUrl,
				}),
			false,
			'Discord Notification webhook updated successfully',
			{
				onSuccess: getNotificationSettings,
				validator: () => discordWebhookValidator(),
			}
		)

	const discordWebhookValidator = () => {
		const urlValidator =
			fieldValidators.blankValidator('Webhook URL', webhookUrl) ||
			fieldValidators.URLValidator('Webhook URL', webhookUrl)

		setErrors({
			webhookUrl: urlValidator,
		})

		return !urlValidator
	}

	useEffect(() => {
		if (shop) {
			getNotificationSettings()
		}
	}, [shop])

	useEffect(() => {
		if (notificationSettings) {
			_setEmailNotifications(notificationSettings.emailNotifications)
			_setTelegramNotifications(notificationSettings.telegramNotifications)
			_setDiscordNotifications(notificationSettings.discordNotifications)
			_setWebhookUrl(notificationSettings.discordWebhook)
		}
	}, [notificationSettings])

	if (isGetNotificationSettingsLoading) return <PageLoading />

	if (!isSettingsGeneralViewAvailable)
		return (
			<NoDataComponent
				isSmallHeight={true}
				imageSrc={pageNotFoundImage}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)

	return (
		<PageWrapper>
			<div className={styles.settingsPageInnerBody}>
				<div className={`${styles.settingSection} ${styles.table}`}>
					<header className={styles.settingSectionHeader}>
						<p className={styles.settingSectionTitle}>Event Notifications</p>
					</header>
					<div className={styles.tableWrapper}>
						<NotificationsTable
							discordNotifications={discordNotifications}
							emailNotifications={emailNotifications}
							setDiscordNotifications={setDiscordNotifications}
							telegramNotifications={telegramNotifications}
							setTelegramNotifications={setTelegramNotifications}
							setEmailNotifications={setEmailNotifications}
						/>
					</div>
					<div className={styles.settingSectionBody}>
						{isSettingsGeneralModifyAvailable && (
							<Button
								style={ButtonStyles.BORDERED}
								icon={{
									id: 'download',
									width: 13,
									height: 13,
									align: ButtonsIconAlign.LEFT,
								}}
								disableShadow={true}
								onClick={updateNotificationSettings}
								isLoading={isUpdateNotificationSettingsLoading}
							>
								Save Settings
							</Button>
						)}
					</div>
				</div>

				<div className={`${styles.settingSection}`}>
					<header className={styles.settingSectionHeader}>
						<p className={styles.settingSectionTitle}>Discord Notifications</p>
					</header>
					<div className={styles.settingSectionBody}>
						<div className={styles.fieldWrapper}>
							<p className={styles.fieldWrapperTitle}>Webhook URL</p>
							<div className={styles.formWrapper}>
								<Input
									value={webhookUrl}
									setValue={setWebhookUrl}
									placeholder={'https://discord.com/api/webhooks/1152484946879971338'}
									errorMessage={errors['webhookUrl']}
									onBlur={() => discordWebhookValidator()}
								/>
							</div>
							<p className={styles.fieldWrapperDescription}>
								Enter your channel webhook URL to receive notifications right in your Discord
								server.
							</p>
						</div>

						{isSettingsGeneralModifyAvailable &&
							webhookUrl !== notificationSettings?.discordWebhook && (
								<div className={styles.mtMediumLarge}>
									<Button
										style={ButtonStyles.BORDERED}
										icon={{
											id: 'download',
											width: 13,
											height: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										disableShadow={true}
										onClick={updateNotificationDiscordWebhook}
										isLoading={isUpdateNotificationDiscordWebhookLoading}
									>
										Save Settings
									</Button>
								</div>
							)}
					</div>
				</div>

				{/*<div className={`${styles.settingSection}`}>*/}
				{/*	<header className={styles.settingSectionHeader}>*/}
				{/*		<p className={styles.settingSectionTitle}>*/}
				{/*			Telegram Notifications <SpriteIcon iconId={'comingSoon'} width={70} height={20} />*/}
				{/*		</p>*/}
				{/*	</header>*/}
				{/*	<div className={styles.settingSectionBody}>*/}
				{/*		<div className={styles.fieldWrapper}>*/}
				{/*			<p className={styles.fieldWrapperTitle}>Telegram Account</p>*/}
				{/*			<div className={styles.formWrapper}>*/}
				{/*				<Button*/}
				{/*					style={ButtonStyles.BORDERED}*/}
				{/*					width={'100%'}*/}
				{/*					icon={{*/}
				{/*						id: 'blueTelegram',*/}
				{/*						width: 16,*/}
				{/*						height: 14,*/}
				{/*						align: ButtonsIconAlign.LEFT,*/}
				{/*					}}*/}
				{/*					disableShadow={true}*/}
				{/*				>*/}
				{/*					Connect Telegram*/}
				{/*				</Button>*/}
				{/*			</div>*/}
				{/*			<p className={styles.fieldWrapperDescription}>*/}
				{/*				Tap on the button above to begin receiving trigger notifications to your Telegram*/}
				{/*				account.*/}
				{/*			</p>*/}
				{/*		</div>*/}

				{/*		<div className={styles.mtMediumLarge}>*/}
				{/*			<Button*/}
				{/*				style={ButtonStyles.BORDERED}*/}
				{/*				icon={{*/}
				{/*					id: 'download',*/}
				{/*					width: 13,*/}
				{/*					height: 13,*/}
				{/*					align: ButtonsIconAlign.LEFT,*/}
				{/*				}}*/}
				{/*				disableShadow={true}*/}
				{/*				onClick={updateNotificationDiscordWebhook}*/}
				{/*				isLoading={isUpdateNotificationDiscordWebhookLoading}*/}
				{/*			>*/}
				{/*				Save Settings*/}
				{/*			</Button>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*</div>*/}
			</div>
		</PageWrapper>
	)
}

export default SettingsNotificationsPage
