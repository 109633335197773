import React, { useEffect } from 'react'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import Spinner from '../../components/UI/Spinner/Spinner'
import { useNavigate, useParams } from 'react-router-dom'
import { useAPI } from '../../hooks/useAPI'
import { UserInvitesService } from '../../API/UserInvitesService'
import { useAppContext } from '../../hooks/useAppContext'
import { User } from '../../types/User/User'
import { UserService } from '../../API/UserService'

const AcceptInvitePage = () => {
	const params = useParams()
	const token = params.token
	const { setUser } = useAppContext()
	const navigate = useNavigate()

	const [isGetUserLoading, , getUser] = useAPI<User>(() => UserService.get(), false, undefined, {
		onSuccess: response => {
			setUser(response.data.data)
		},
	})

	const [isAcceptInviteLoading, , acceptInvite] = useAPI(
		(token: string) =>
			UserInvitesService.acceptInvite({
				inviteToken: token,
			}),
		false,
		'Invite successfully accepted',
		{
			onSuccess: () => {
				getUser()
				navigate('/')
			},
		}
	)

	useEffect(() => {
		if (token) {
			acceptInvite(token)
		}
	}, [])

	return (
		<PageWrapper>
			<PageContainer>{isAcceptInviteLoading && isGetUserLoading && <Spinner />}</PageContainer>
		</PageWrapper>
	)
}

export default AcceptInvitePage
