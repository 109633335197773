import { privateAPI } from './API'

export const ChargesService = {
	getAll: async (shopId: string, searchString?: string, PageNumber?: number, PageSize?: number) => {
		return privateAPI.get(`/v1/dash/shops/${shopId}/charges`, {
			params: {
				searchString,
				PageNumber,
				PageSize,
			},
		})
	},
	getOne: async (shopId: string, id: number) => {
		return privateAPI.get(`/v1/dash/shops/${shopId}/charges/${id}`)
	},
	markAsPaid: async (
		shopId: string,
		id: string,
		data: {
			showInDashboardStats: boolean
		}
	) => {
		return privateAPI.post(`/v1/dash/shops/${shopId}/charges/${id}/mark-as-paid`, data)
	},
}
