import { FC, useEffect, useState } from 'react'
import styles from './DiscordSettings.module.scss'
import Checkbox from '../UI/Checkbox/Checkbox'
import Switch from '../UI/Switch/Switch'
import { DiscordGuildWithRoles } from '../../types/DiscordGuildWithRoles/DiscordGuildWithRoles'
import { useAPI } from '../../hooks/useAPI'
import { DiscordGuild } from '../../types/DiscordGuild/DiscordGuild'
import { DiscordSettingVariants } from '../../types/DiscordSettings/DiscordSettingVariants'
import { DiscordUserService } from '../../API/DiscordUserService'
import Spinner from '../UI/Spinner/Spinner'
import ConnectDiscord from '../ConnectDiscord/ConnectDiscord'
import DiscordSetting from './DiscordSetting'

interface DiscordSettingsProps {
	isVertical?: boolean
	isEnabled: boolean
	setIsEnabled: (value: boolean) => void
	isRequired: boolean
	setIsRequired: (value: boolean) => void
	beforePurchaseAddUser: DiscordGuildWithRoles | null
	setBeforePurchaseAddUser: (value: DiscordGuildWithRoles | null) => void
	beforePurchaseRequireRoles: DiscordGuildWithRoles | null
	setBeforePurchaseRequireRoles: (value: DiscordGuildWithRoles | null) => void
	discordDelivery?: DiscordGuildWithRoles | null
	setDiscordDelivery?: (value: DiscordGuildWithRoles | null) => void
	isBeforePurchaseAddUserEnabled: boolean
	isBeforePurchaseRequireRolesEnabled: boolean
	isDiscordDeliveryEnabled?: boolean
	setIsBeforePurchaseRequireRolesEnabled: (value: boolean) => void
	setIsBeforePurchaseAddUserEnabled: (value: boolean) => void
	setIsDiscordDeliveryEnabled?: (value: boolean) => void
	onRefreshClick?: () => void
	currentVariantIndex?: string
	showDiscordDelivery?: boolean
}

const DiscordSettings: FC<DiscordSettingsProps> = ({
	isVertical,
	beforePurchaseRequireRoles,
	beforePurchaseAddUser,
	discordDelivery,
	isRequired,
	isEnabled,
	setIsEnabled,
	setBeforePurchaseRequireRoles,
	setBeforePurchaseAddUser,
	setDiscordDelivery,
	setIsRequired,
	currentVariantIndex,
	setIsBeforePurchaseAddUserEnabled,
	setIsBeforePurchaseRequireRolesEnabled,
	setIsDiscordDeliveryEnabled,
	isBeforePurchaseAddUserEnabled = false,
	isBeforePurchaseRequireRolesEnabled = false,
	isDiscordDeliveryEnabled = false,
	onRefreshClick,
	showDiscordDelivery,
}) => {
	const [isDiscordConnected, setIsDiscordConnected] = useState(false)

	const [isGetGuildsLoading, guilds, getGuilds] = useAPI<DiscordGuild[]>(
		() => DiscordUserService.getGuilds(),
		false,
		undefined,
		{
			onError: error => {
				if (error.response?.status === 400) {
					setIsDiscordConnected(false)
				}
			},
			onSuccess: () => {
				setIsDiscordConnected(true)
			},
		}
	)

	const refresh = () => {
		if (onRefreshClick) {
			onRefreshClick()
		} else {
			setBeforePurchaseRequireRoles && setBeforePurchaseRequireRoles(null)
			setBeforePurchaseAddUser && setBeforePurchaseAddUser(null)
			setDiscordDelivery && setDiscordDelivery(null)
			setIsBeforePurchaseRequireRolesEnabled && setIsBeforePurchaseRequireRolesEnabled(false)
			setIsBeforePurchaseAddUserEnabled && setIsBeforePurchaseAddUserEnabled(false)
			setIsDiscordDeliveryEnabled && setIsDiscordDeliveryEnabled(false)
		}

		getGuilds()
	}

	useEffect(() => {
		if (isEnabled) {
			getGuilds()
		}
	}, [isEnabled])

	if (isGetGuildsLoading) return <Spinner />

	return (
		<div className={`${styles.discordSettings} ${isVertical ? styles.vertical : ''}`}>
			<div className={styles.sectionWrapper}>
				<header>
					<div>
						<p className={styles.sectionTitle}>Discord Integration</p>
						<p className={styles.sectionDescription}>
							Set custom rules for Discord roles and access.
						</p>
					</div>
					<Switch checked={isEnabled} setChecked={setIsEnabled} />
				</header>

				{!isDiscordConnected && isEnabled && (
					<div className={styles.discordSettings}>
						<ConnectDiscord />
					</div>
				)}

				{isDiscordConnected && isEnabled && (
					<div className={styles.checkboxWrapper}>
						<Checkbox
							id={`requireDiscord${currentVariantIndex}`}
							checked={isRequired}
							setChecked={setIsRequired}
						/>
						<p>Require customer to login with Discord</p>
					</div>
				)}
			</div>

			{isDiscordConnected && isEnabled && (
				<div className={styles.sectionWrapper}>
					<DiscordSetting
						title='Customer must have a specific Discord role'
						isEnabled={isBeforePurchaseRequireRolesEnabled}
						setIsEnabled={setIsBeforePurchaseRequireRolesEnabled}
						data={beforePurchaseRequireRoles}
						setData={setBeforePurchaseRequireRoles}
						guilds={guilds}
						currentVariantIndex={currentVariantIndex}
						type={DiscordSettingVariants.BEFORE_PURCHASE_REQUIRE_ROLES}
					/>

					<DiscordSetting
						title='Automatically add customer to specific Discord server before purchase'
						isEnabled={isBeforePurchaseAddUserEnabled}
						setIsEnabled={setIsBeforePurchaseAddUserEnabled}
						data={beforePurchaseAddUser}
						setData={setBeforePurchaseAddUser}
						guilds={guilds}
						currentVariantIndex={currentVariantIndex}
						type={DiscordSettingVariants.BEFORE_PURCHASE_ADD_USER}
					/>

					{showDiscordDelivery && (
						<DiscordSetting
							title='Automatically add customer to specific Discord server after purchase'
							isEnabled={isDiscordDeliveryEnabled ?? false}
							setIsEnabled={setIsDiscordDeliveryEnabled ?? (() => {})}
							data={discordDelivery ?? null}
							setData={setDiscordDelivery ?? (() => {})}
							guilds={guilds}
							currentVariantIndex={currentVariantIndex}
							type={DiscordSettingVariants.AFTER_PURCHASE_ADD_USER}
						/>
					)}
					<p className={styles.refreshText} onClick={refresh}>
						Not seeing roles or servers? Click here to refresh
					</p>
				</div>
			)}
		</div>
	)
}

export default DiscordSettings
