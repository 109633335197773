import React, { FC, useEffect, useState } from 'react'
import styles from './Tables.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { NavLink } from 'react-router-dom'
import Checkbox from '../UI/Checkbox/Checkbox'
import ProductTypeBadge from '../UI/ProductTypeBadge/ProductTypeBadge'
import OrangeBadge from '../UI/OrangeBadge/OrangeBadge'
import { Stock } from '../../types/Stock/Stock'
import { format } from 'date-fns'
import StockStatusBadge from '../UI/StockStatusBadge/StockStatusBadge'

interface StockEntriesTableProps {
	stocks: Stock[]
	setSelectedStockEntries: (value: string[]) => void
	selectedStockEntries: string[]
	allStockEntriesChecked: boolean
	setAllStockEntriesChecked: (value: boolean) => void
}

const StockEntriesTable: FC<StockEntriesTableProps> = ({
	stocks,
	setSelectedStockEntries,
	selectedStockEntries,
	setAllStockEntriesChecked,
	allStockEntriesChecked,
}) => {
	useEffect(() => {
		if (allStockEntriesChecked) {
			setSelectedStockEntries(stocks.map(stock => stock.id))
		} else {
			setSelectedStockEntries([])
		}
	}, [allStockEntriesChecked])

	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.small}>
						<Checkbox
							id={'allStocks'}
							checked={allStockEntriesChecked}
							setChecked={setAllStockEntriesChecked}
						/>
					</th>
					<th className={styles.large}>Stock Entry</th>
					<th>Status</th>
					<th>Sold To</th>
					<th>Stock Added</th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{stocks.map(stock => (
					<div className={styles.trWrapper} key={stock.id}>
						<tr>
							<td className={styles.small}>
								<Checkbox
									id={stock.id}
									checked={selectedStockEntries.includes(stock.id)}
									setChecked={checked => {
										if (checked) {
											setSelectedStockEntries([...selectedStockEntries, stock.id])
										} else {
											setSelectedStockEntries(
												selectedStockEntries.filter(
													selectedStockEntry => selectedStockEntry !== stock.id
												) || []
											)
										}
									}}
								/>
							</td>
							<td className={styles.large}>
								<p>{stock.key}</p>
							</td>
							<td>
								<StockStatusBadge isAvailable={stock.isAvailable} />
							</td>
							<td>{stock.soldToOrderIds[0] || '-'}</td>
							<td>
								<p>
									{format(new Date(stock.createdAt), 'MMM dd, yyyy')}{' '}
									<span>{format(new Date(stock.createdAt), 'hh:mm aa z')}</span>
								</p>
							</td>
						</tr>
					</div>
				))}
			</tbody>
		</table>
	)
}

export default StockEntriesTable
