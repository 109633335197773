import React, { FC } from 'react'
import styles from './AppCard.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { Apps } from '../../types/AppStore/Apps'
import tawkLogo from '../../assets/images/tawk.svg'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../../hooks/useAppContext'

interface AppCardProps {
	app: Apps
}

const AppCard: FC<AppCardProps> = ({ app }) => {
	const navigate = useNavigate()
	const { shop, builderLink } = useAppContext()

	const openBuilder = () => {
		window.open(builderLink, 'blank', 'noopener')
	}

	const appOption = (() => {
		switch (app) {
			case Apps.CRISP_CHAT:
				return {
					icon: {
						id: 'crispChat',
						width: 19,
						height: 16,
					},
					title: 'Crisp Chat',
					description:
						'Support customers with a built-in chat system and analyze customer interactions across your storefront.',
					isActive: false,
					isComingSoon: false,
					onInstallClick: openBuilder,
				}
			case Apps.GOOGLE_ANALYTICS:
				return {
					icon: {
						id: 'googleAnalytics',
						width: 16,
						height: 19,
					},
					title: 'Google Analytics',
					description:
						'Receive analytic data to your own Google Analytics dashboard to make more informed business decisions.',
					isActive: false,
					isComingSoon: false,
					onInstallClick: openBuilder,
				}
			case Apps.TELEGRAM:
				return {
					icon: {
						id: 'blueTelegram',
						width: 19,
						height: 16,
					},
					title: 'Telegram',
					description:
						'Connect your Telegram account to automatically invite customers to your channels and send stock alerts.',
					isActive: false,
					isComingSoon: false,
					onInstallClick: () => navigate('/settings/integrations'),
				}
			case Apps.DISCORD:
				return {
					icon: {
						id: 'discord',
						width: 20,
						height: 15,
					},
					title: 'Discord',
					description:
						'Connect your Discord account to automatically invite customers to your servers and send stock alerts.',
					isActive: false,
					isComingSoon: false,
					onInstallClick: () => navigate('/settings/integrations'),
				}
			case Apps.TAWK:
				return {
					iconURL: tawkLogo,
					title: 'Tawk',
					description:
						'Monitor and chat with the visitors on your website, respond to support tickets and empower customers to help themselves.\n',
					isActive: false,
					isComingSoon: false,
					onInstallClick: openBuilder,
				}
			case Apps.WOO_COMMERCE:
				return {
					icon: {
						id: 'wooCommerce',
						width: 22,
						height: 13,
					},
					title: 'WooCommerce',
					description:
						'Accept payments and sell digital products directly through your own WooCommerce-hosted storefront.',
					isActive: false,
					isComingSoon: true,
				}
			case Apps.PASSWORD_PROTECTION:
				return {
					icon: {
						id: 'passwordProtection',
						width: 14.5,
						height: 14.5,
					},
					title: 'Password Protection',
					description:
						'Protect your storefront with a password. Set once and experience a forever-protected store.',
					isActive: false,
					isComingSoon: false,
					onInstallClick: () => navigate('/settings/general'),
				}
			case Apps.SURVEYS:
				return {
					icon: {
						id: 'surveys',
						width: 14.5,
						height: 14.5,
					},
					title: 'Surveys',
					description:
						'Ask custom questions before or after a purchase to gain better customer insights and reduce support tickets.',
					isActive: false,
					isComingSoon: true,
				}
			case Apps.LANGUAGES:
				return {
					icon: {
						id: 'languages',
						width: 14.5,
						height: 14.5,
					},
					title: 'Languages',
					description:
						'Automatically translate your store to another language with Google Translate.',
					isActive: false,
					isComingSoon: true,
				}
			case Apps.STOCK_ALERTS:
				return {
					icon: {
						id: 'stockAlerts',
						width: 14.5,
						height: 14.5,
					},
					title: 'Stock Alerts',
					description:
						'Push notifications to your Telegram or Discord server with custom automatic alerts of product stock.',
					isActive: false,
					isComingSoon: true,
				}
			case Apps.SALES_PROOF:
				return {
					icon: {
						id: 'salesProof',
						width: 14.5,
						height: 14.5,
					},
					title: 'Sales Proof',
					description:
						'Instantly notify customers on-site with verified checkout information from other customers.',
					isActive: false,
					isComingSoon: true,
				}
			case Apps.GLOBAL_BLACKLISTS:
				return {
					icon: {
						id: 'globalBlacklists',
						width: 14.5,
						height: 14.5,
					},
					title: 'Global Blacklists',
					description:
						'Add customers to a blacklist and add block customers from other global blacklists.',
					isActive: false,
					isComingSoon: true,
				}
			case Apps.GOOGLE_TAG_MANAGER:
				return {
					icon: {
						id: 'googleTagManager',
						width: 14.5,
						height: 14.5,
					},
					title: 'Google Tag Manager',
					description:
						'Embed and connect third-party tools or services directly to your Billgang-hosted storefront without a single line of code.',
					isActive: false,
					isComingSoon: false,
					onInstallClick: openBuilder,
				}
			default:
				return {
					icon: {
						id: 'crispChat',
						width: 19,
						height: 16,
					},
					title: 'Crisp Chat',
					description:
						'Support customers with a built-in chat system and analyze customer interactions across your storefront.',
					isActive: false,
					isComingSoon: false,
				}
		}
	})()

	return (
		<div className={styles.appCard}>
			<header>
				<div className={styles.iconWrapper}>
					{appOption.icon && (
						<SpriteIcon
							iconId={appOption.icon.id}
							width={appOption.icon.width}
							height={appOption.icon.height}
						/>
					)}
					{appOption.iconURL && <img src={appOption.iconURL} alt={appOption.title} />}
				</div>

				<h1>{appOption.title}</h1>
			</header>
			<p className={styles.description}>{appOption.description}</p>

			<footer>
				{appOption.isComingSoon ? (
					<Button style={ButtonStyles.THIRD}>Coming Soon</Button>
				) : (
					<Button
						style={ButtonStyles.DARK}
						icon={{
							id: 'circlePlus',
							width: 14.5,
							height: 14.5,
							align: ButtonsIconAlign.LEFT,
						}}
						onClick={appOption.onInstallClick}
					>
						Install App
					</Button>
				)}
			</footer>
		</div>
	)
}

export default AppCard
