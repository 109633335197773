import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import CustomSelect, { SelectStyles } from '../../UI/CustomSelect/CustomSelect'
import Input from '../../UI/FormComponents/Input'
import Textarea from '../../UI/FormComponents/Textarea'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { FraudRuleType } from '../../../types/FraudRuleType/FraudRuleType'
import { FraudRuleFactor } from '../../../types/FraudRuleFactor/FraudRuleFactor'
import { useAPI } from '../../../hooks/useAPI'
import { FraudRulesService } from '../../../API/FraudRulesService'
import { useAppContext } from '../../../hooks/useAppContext'
import FormErrorsDisplayBadge from '../../UI/FormErrorsDisplayBadge/FormErrorsDisplayBadge'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'
import RequiredBadge from '../../UI/RequiredBadge/RequiredBadge'

interface CreateFraudRuleModalProps extends BaseModalProps {
	fraudRuleType: FraudRuleType
	getFraudRules: () => void
}

const CreateFraudRuleModal: FC<CreateFraudRuleModalProps> = ({
	fraudRuleType,
	getFraudRules,
	onClose,
}) => {
	const { shop } = useAppContext()
	const [ruleData, setRuleData] = useState('')
	const [note, setNote] = useState('')
	const [factor, setFactor] = useState<FraudRuleFactor>(FraudRuleFactor.EMAIL)
	const [showErrorsDisplay, setShowErrorsDisplay] = useState(false)
	const [errors, setErrors] = useState<FormErrors>({
		ruleData: '',
	})

	const [isCreateFraudRuleLoading, , createFraudRule] = useAPI(
		() =>
			FraudRulesService.create(shop?.id || '', {
				data: ruleData,
				note: note || null,
				factor: factor,
				type: fraudRuleType,
				gatewayFilter: [],
			}),
		false,
		'Fraud rule successfully created',
		{
			onSuccess: () => {
				onClose()
				getFraudRules()
			},
		}
	)

	const onCreateClickHandler = () => {
		if (ruleData) {
			createFraudRule()
		} else {
			setErrors({
				...errors,
				ruleData: fieldValidators.blankValidator(
					`${fraudRuleType === FraudRuleType.ALLOW ? 'Allowed' : 'Blocked'} data`,
					ruleData
				),
			})
			setShowErrorsDisplay(true)
		}
	}

	const placeholders = {
		[FraudRuleFactor.EMAIL]: 'customer+1@gmail.com',
		[FraudRuleFactor.FRAUD_SCORE]: '99',
		[FraudRuleFactor.IP]: '111.11.111.11',
		[FraudRuleFactor.COUNTRY]: 'US',
		[FraudRuleFactor.ISP]: 'Comcast Communications, LLC',
		[FraudRuleFactor.CITY]: 'New York',
		[FraudRuleFactor.EMAIL_DOMAIN]: '@example.com',
		[FraudRuleFactor.DISCORD_ID]: '0000000000000000000',
	}

	return (
		<div className={styles.modalInner}>
			{/*{showErrorsDisplay && !!errorMessages.length && (*/}
			{/*	<FormErrorsDisplayBadge*/}
			{/*		title={`Fraud Rule Create Error`}*/}
			{/*		errors={errorMessages}*/}
			{/*		isModal={true}*/}
			{/*	/>*/}
			{/*)}*/}
			<header className={styles.header}>
				<h1>Create {fraudRuleType === FraudRuleType.ALLOW ? 'Whitelist' : 'Blacklist'}</h1>
			</header>

			<div className={styles.body}>
				<div className={styles.fieldWrapper}>
					<h2>Type</h2>
					<CustomSelect
						value={factor}
						setValue={value => setFactor(value as FraudRuleFactor)}
						options={[
							{ label: 'Email Address', value: FraudRuleFactor.EMAIL },
							{ label: 'Fraud Score', value: FraudRuleFactor.FRAUD_SCORE },
							{ label: 'IP Address', value: FraudRuleFactor.IP },
							{ label: 'Country Code', value: FraudRuleFactor.COUNTRY },
							{ label: 'ISP', value: FraudRuleFactor.ISP },
							{ label: 'Discord ID', value: FraudRuleFactor.DISCORD_ID },
							{ label: 'Email Domain', value: FraudRuleFactor.EMAIL_DOMAIN },
							{ label: 'City', value: FraudRuleFactor.CITY },
						]}
					/>
				</div>
				<div className={styles.fieldWrapper}>
					<h2>
						{fraudRuleType === FraudRuleType.ALLOW ? 'Allowed' : 'Blocked'} Data <RequiredBadge />
					</h2>
					<Input
						value={ruleData}
						setValue={setRuleData}
						placeholder={placeholders[factor]}
						errorMessage={errors['ruleData']}
						onBlur={() =>
							setErrors({
								...errors,
								ruleData: fieldValidators.blankValidator(
									`${fraudRuleType === FraudRuleType.ALLOW ? 'Allowed' : 'Blocked'} data`,
									ruleData
								),
							})
						}
					/>
				</div>
				<div className={styles.fieldWrapper}>
					<h2>Note</h2>
					<Textarea
						value={note}
						setValue={setNote}
						placeholder={'Type a note for future reference here...'}
						height={155}
					/>
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						disableShadow={true}
						icon={{
							id: 'plus',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						onClick={onCreateClickHandler}
						isLoading={isCreateFraudRuleLoading}
					>
						Create {fraudRuleType === FraudRuleType.ALLOW ? 'Whitelist' : 'Blacklist'}
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default CreateFraudRuleModal
