import React, { FC } from 'react'
import styles from './MiniStat.module.scss'
import FeedbackActionMenu from '../FeedbackActionMenu/FeedbackActionMenu'
import UpdatedOnDateBadge from '../UI/UpdatedOnDateBadge/UpdatedOnDateBadge'

interface MiniStatProps {
	title: string
	value?: string | number
	updatedDate?: Date | null
}

const MiniStat: FC<MiniStatProps> = ({ value, updatedDate, title }) => {
	return (
		<div className={styles.miniStat}>
			<div className={styles.miniStatRow}>
				<h2 className={styles.title}>{title}</h2>
				<div className={styles.feedbackMenuWrapper}>
					<FeedbackActionMenu />
				</div>
			</div>
			<div className={`${styles.miniStatRow} ${styles.body}`}>
				<p className={styles.value}>{value}</p>
				{updatedDate && <UpdatedOnDateBadge date={updatedDate} />}
			</div>
		</div>
	)
}

export default MiniStat
