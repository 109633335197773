import axios from 'axios'

const baseUrl =
	localStorage.getItem('dv') === '1' ? 'https://localhost:5043' : 'https://g-api.billgang.com'

export const publicAPI = axios.create({
	baseURL: baseUrl,
})
export const privateAPI = axios.create({
	baseURL: baseUrl,
	headers: {
		Authorization: `bearer ${localStorage.getItem('accessToken')}`,
	},
})

privateAPI.interceptors.response.use(null, error => {
	if (
		error.response.status === 401 &&
		error.response.request.responseURL !== `${baseUrl}/v1/dash/user/new`
	) {
		window.location.pathname = '/'
		localStorage.removeItem('accessToken')
		localStorage.removeItem('shopId')
	}

	return Promise.reject(error)
})
