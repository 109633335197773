import React, { FC } from 'react'
import RequiredBadge from '../UI/RequiredBadge/RequiredBadge'
import UrlInput from '../UI/FormComponents/UrlInput/UrlInput'
import { fieldValidators } from '../../helpers/fieldValidators'
import Input from '../UI/FormComponents/Input'
import Textarea from '../UI/FormComponents/Textarea'
import ImagePicker from '../ImagePicker/ImagePicker'
import { Image } from '../../types/Image/Image'
import { FormErrors } from '../../types/FormErrors/FormErrors'
import styles from './SEOSection.module.scss'
import OptionalBadge from '../UI/OptionalBadge/OptionalBadge'

interface SeoSectionProps {
	slugUrl: string
	setSlugUrl: (value: string) => void
	metaTitle: string
	setMetaTitle: (value: string) => void
	metaDescription: string
	setMetaDescription: (value: string) => void
	metaImage: Image | null
	setMetaImage: (image: Image | null) => void
	errors: FormErrors
	setErrors: (value: FormErrors) => void
	url: string
	isMostlyOptional: boolean
}

const SeoSection: FC<SeoSectionProps> = ({
	slugUrl,
	setSlugUrl,
	url,
	setErrors,
	errors,
	metaImage,
	setMetaImage,
	setMetaTitle,
	metaDescription,
	setMetaDescription,
	metaTitle,
	isMostlyOptional,
}) => {
	return (
		<div>
			<div>
				<p className={styles.fieldTitle}>Slug URL {isMostlyOptional && <RequiredBadge />}</p>
				<p className={`${styles.fieldDescription} ${styles.mt5}`}>
					This is the link your customers will access from.
				</p>
				<div className={styles.mt10}>
					<UrlInput
						URL={url}
						value={slugUrl}
						setValue={setSlugUrl}
						errorMessage={errors['slugUrl']}
						onBlur={() =>
							setErrors({
								...errors,
								slugUrl: fieldValidators.blankValidator('Slug URL', slugUrl),
							})
						}
					/>
				</div>
			</div>
			<div className={styles.horLine} />
			<div className={styles.mtMediumLarge}>
				<p className={styles.fieldTitle}>Meta Title {isMostlyOptional && <RequiredBadge />}</p>
				<div className={styles.mt10}>
					<Input value={metaTitle} setValue={setMetaTitle} placeholder={'Meta Title'} />
				</div>
			</div>
			<div className={styles.horLine} />
			<div className={styles.doubleFields}>
				<div>
					<p className={styles.fieldTitle}>
						Meta Description {!isMostlyOptional && <OptionalBadge />}
					</p>
					<div className={styles.mt10}>
						<Textarea
							value={metaDescription}
							setValue={setMetaDescription}
							height={164}
							placeholder={'Meta Description'}
						/>
					</div>
				</div>
				<div>
					<p className={styles.fieldTitle}>Meta Image {!isMostlyOptional && <OptionalBadge />}</p>
					<div className={styles.mt10}>
						<ImagePicker setImages={images => setMetaImage(images[0])} images={[metaImage]} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default SeoSection
