import React, { FC } from 'react'
import styles from './FeatureBadge.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

interface FeatureBadgeProps {
	featureText: string
}

const FeatureBadge: FC<FeatureBadgeProps> = ({ featureText }) => {
	return (
		<div className={styles.featureBadge}>
			<SpriteIcon className={styles.green} iconId={'checked'} width={14} height={10} />
			<p>{featureText}</p>
		</div>
	)
}

export default FeatureBadge
