import React, { FC, useState } from 'react'
import modalStyles from '../Modals.module.scss'
import Input from '../../UI/FormComponents/Input'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { useAPI } from '../../../hooks/useAPI'
import { CustomersService } from '../../../API/CustomersService'
import { useAppContext } from '../../../hooks/useAppContext'
import InputWithText from '../../UI/FormComponents/InputWithText'
import { PriceContract } from '../../../types/PriceContract/PriceContract'
import getSymbolFromCurrency from 'currency-symbol-map'

interface EditCustomerBalanceModalProps extends BaseModalProps {
	customerName: string
	customerId?: number
	balance: { amount: number; currency: string }
	getCustomer: () => void
}

const EditCustomerBalanceModal: FC<EditCustomerBalanceModalProps> = ({
	onClose,
	customerName,
	customerId,
	balance,
	getCustomer,
}) => {
	const [balanceAmount, setBalanceAmount] = useState(0)
	const { shop } = useAppContext()

	const [isEditCustomersBalanceLoading, , editCustomersBalance] = useAPI(
		(isDeduct: boolean) =>
			CustomersService.editCustomersBalance(shop?.id || '', customerId, {
				amount: isDeduct ? -balanceAmount : +balanceAmount,
			}),
		false,
		'Balance successfully edited',
		{
			onSuccess: () => {
				onClose()
				getCustomer()
			},
		}
	)

	return (
		<div className={modalStyles.modalInner}>
			<header className={modalStyles.header}>
				<h1>Edit Customer Balance</h1>
			</header>
			<div className={modalStyles.body}>
				<p className={modalStyles.description}>
					You’re about to issue or deduct store balance for <b>{customerName}</b>. Are you sure you
					want to continue with this action? We recommend using this for refunds.
				</p>
				<div className={modalStyles.fieldWrapper}>
					<h2>Store Balance</h2>
					<InputWithText
						value={balanceAmount}
						setValue={value => value >= 0 && setBalanceAmount(value)}
						placeholder={'$0.00'}
						type={'number'}
						text={`${getSymbolFromCurrency(balance.currency)}`}
					/>
				</div>
			</div>
			<footer className={modalStyles.footer}>
				<div className={modalStyles.buttons}>
					<Button
						style={ButtonStyles.BORDERED}
						width={'100%'}
						disableShadow={true}
						onClick={onClose}
					>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						width={'100%'}
						icon={{
							id: 'circleX',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
							className: modalStyles.red,
						}}
						disableShadow={true}
						onClick={() => editCustomersBalance(true)}
						isLoading={isEditCustomersBalanceLoading}
					>
						Deduct Balance
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						width={'100%'}
						icon={{
							id: 'checkedCircle',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
							className: modalStyles.green,
						}}
						disableShadow={true}
						onClick={() => editCustomersBalance(false)}
						isLoading={isEditCustomersBalanceLoading}
					>
						Add Balance
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default EditCustomerBalanceModal
