import React from 'react'
import styles from './AppStorePage.module.scss'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageHeader from '../../components/PageHeader/PageHeader'
import AppCard from '../../components/AppCard/AppCard'
import { Apps } from '../../types/AppStore/Apps'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import { usePageTitle } from '../../hooks/usePageTitle'

const AppStorePage = () => {
	usePageTitle('App Store')

	return (
		<PageContainer>
			<PageWrapper>
				<div className={styles.appStorePage}>
					<PageHeader title={'App Store'} />

					<div className={styles.apps}>
						<AppCard app={Apps.GOOGLE_ANALYTICS} />
						<AppCard app={Apps.DISCORD} />
						<AppCard app={Apps.GOOGLE_TAG_MANAGER} />
						{/*<AppCard app={Apps.TELEGRAM} />*/}
						<AppCard app={Apps.CRISP_CHAT} />
						<AppCard app={Apps.TAWK} />
						<AppCard app={Apps.WOO_COMMERCE} />
						<AppCard app={Apps.PASSWORD_PROTECTION} />
						<AppCard app={Apps.SURVEYS} />
						<AppCard app={Apps.LANGUAGES} />
						<AppCard app={Apps.STOCK_ALERTS} />
						<AppCard app={Apps.SALES_PROOF} />
						<AppCard app={Apps.GLOBAL_BLACKLISTS} />
					</div>

					<div className={styles.suggestApp}>
						<div>
							<h2>Don’t see an app you want?</h2>
							<p>
								We’re always working on launching new apps, so if you don’t see an app available
								here and would like to see support for one, feel free to let us know.
							</p>
						</div>
						<Button
							style={ButtonStyles.BORDERED}
							disableShadow={true}
							icon={{
								id: 'blankWindow',
								height: 13,
								width: 13,
								align: ButtonsIconAlign.LEFT,
							}}
							onClick={() => window.open('https://suggestions.billgang.com/', '_blank', 'noopener')}
						>
							Request an App
						</Button>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default AppStorePage
