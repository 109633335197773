import React from 'react'
import styles from './ShopStatusBlock.module.scss'
import { useAppContext } from '../../hooks/useAppContext'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { StatusMap } from '../../types/Status/Status';
import Button, { ButtonStyles } from '../UI/Button/Button'
import { useNavigate } from "react-router-dom";


const ShopStatusBlock = () => {
	const {shop} = useAppContext();
	const navigate = useNavigate();
	const status = StatusMap[shop?.status as keyof typeof StatusMap]

	const redirect = (path: string, isExternalLink: boolean) => {
		if (isExternalLink) {
			window.open(path, '_blank', 'noopener')
		} else {
			navigate(path);
		}
		
	}
	
	return <div className={styles.shopStatusBlockWrapper}>
		<div className={styles.shopStatusBlock}>
			<div className={styles.shopStatusIconWrapper}>
				<SpriteIcon iconId={status?.icon} width={48} height={48} withBox/>
			</div>
			<div className={styles.shopStatusText}>
				<p>{status?.mainTitle}</p>
				<span>{status?.subtitle ? status?.subtitle : shop?.statusReason}</span>
			</div>
			<Button style={ButtonStyles.PRIMARY} onClick={() => redirect(status.path, status.isExternalLink)}>{status?.btnText}</Button>
		</div>
	</div>
}

export default ShopStatusBlock