import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import styles from './RewardsPage.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import RewardsCard from '../../components/RewardsCard/RewardsCard'
import Modal from '../../components/Modals/Modal'
import RewardRuleModal from '../../components/Modals/RewardRuleModal/RewardRuleModal'
import { useAPI } from '../../hooks/useAPI'
import { RewardsService } from '../../API/RewardsService'
import { useAppContext } from '../../hooks/useAppContext'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import { RewardRuleType } from '../../types/RewardRuleType/RewardRuleType'
import { closeModal } from '../../helpers/closeModal'
import { GetReward } from '../../types/GetReward/GetReward'
import RewardsReferralsCard from '../../components/RewardsCard/RewardsReferralsCard'
import ReferralsModal from '../../components/Modals/ReferralsModal/ReferralsModal'
import { usePageTitle } from '../../hooks/usePageTitle'
import { Subscriptions } from '../../types/Subscriptions/Subscriptions'
import { PaymentsSettingsService } from '../../API/PaymentsSettingsService'
import { BalanceRewardType } from '../../types/BalanceRewardType/BalanceRewardType'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import { useNavigate } from 'react-router-dom'
import { ButtonsIconAlign } from '../../components/UI/Button/Button'
import BalanceRewardModal from '../../components/Modals/RewardRuleModal/BalanceRewardModal'
import { GetBalanceRewards } from '../../types/GetBalanceRewards/GetBalanceRewards'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

const RewardsPage = () => {
	const [isRewardRuleModalActive, setIsRewardRuleModalActive] = useState(false)
	const [isRewardRuleModalVisible, setIsRewardRuleModalVisible] = useState(false)
	const [isReferralsModalActive, setIsReferralsModalActive] = useState(false)
	const [isReferralsModalVisible, setIsReferralsModalVisible] = useState(false)
	const [isEnableBalanceModalActive, setIsEnableBalanceModalActive] = useState(false)
	const [isEnableBalanceModalVisible, setIsEnableBalanceModalVisible] = useState(false)
	const [isBalanceRewardsModalActive, setIsBalanceRewardsModalActive] = useState(false)
	const [isBalanceRewardsModalVisible, setIsBalanceRewardsModalVisible] = useState(false)

	const [currentRule, setCurrentRule] = useState<RewardRuleType>(RewardRuleType.LEAVE_REVIEW)
	const [currentBalanceRewardType, setCurrentBalanceRewardType] = useState<BalanceRewardType>(
		BalanceRewardType.topUpBonus
	)
	const [isRuleEditing, setIsRuleEditing] = useState(false)
	const { shop, user, setIsSubscriptionModalActive, setRequiredSubscription } = useAppContext()
	const [isBalanceEnabled, setIsBalanceEnabled] = useState(false)
	const navigate = useNavigate()
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isAffiliatesViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.AFFILIATES_VIEW) || isAdmin
	const isAffiliatesModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.AFFILIATES_MODIFY) || isAdmin
	usePageTitle(`Rewards`)

	const [isGetRewardsLoading, rewardsData, getRewards] = useAPI<GetReward>(
		() => RewardsService.getAll(shop?.id || ''),
		false
	)

	const [isGetBalanceRewardsLoading, balanceRewards, getBalanceRewards] = useAPI<GetBalanceRewards>(
		() => PaymentsSettingsService.getBalanceRewards(shop?.id || ''),
		false,
		undefined,
		{
			onError: error => {
				if (error.response?.status === 404) {
					setIsBalanceEnabled(false)
				}
			},
			onSuccess: () => {
				setIsBalanceEnabled(true)
			},
		}
	)

	const onEditClickHandler = (rule: RewardRuleType) => {
		const perks = user?.subscription.perks
		if (perks?.rewardRulesTypes.includes(rule)) {
			setIsRuleEditing(true)
			setCurrentRule(rule)
			setIsRewardRuleModalActive(true)
		} else {
			setIsSubscriptionModalActive(true)
			setRequiredSubscription(Subscriptions.BUSINESS)
		}
	}

	const onEnableClickHandler = (rule: RewardRuleType) => {
		const perks = user?.subscription.perks
		if (perks?.rewardRulesTypes.includes(rule)) {
			setIsRuleEditing(false)
			setCurrentRule(rule)
			setIsRewardRuleModalActive(true)
		} else {
			setIsSubscriptionModalActive(true)
			setRequiredSubscription(Subscriptions.BUSINESS)
		}
	}

	const onReferralsEnableClickHandler = () => {
		const perks = user?.subscription.perks
		if (perks?.referralSystem) {
			setIsReferralsModalActive(true)
		} else {
			setIsSubscriptionModalActive(true)
			setRequiredSubscription(Subscriptions.BUSINESS)
		}
	}

	const onBalanceRewardsEnableClickHandler = (balanceRewardType: BalanceRewardType) => {
		const perks = user?.subscription.perks
		if (isBalanceEnabled) {
			if (
				(balanceRewardType === BalanceRewardType.topUpBonus ? perks?.topUpBonusRuleReward : true) ||
				(balanceRewardType === BalanceRewardType.cashback ? perks?.cashbackRuleReward : true)
			) {
				setIsBalanceRewardsModalActive(true)
				setCurrentBalanceRewardType(balanceRewardType)
			} else {
				setIsSubscriptionModalActive(true)
				setRequiredSubscription(Subscriptions.SCALE)
			}
		} else {
			setIsEnableBalanceModalActive(true)
		}
	}

	useEffect(() => {
		if (shop && isAffiliatesViewAvailable) {
			getRewards()
			getBalanceRewards()
		}
	}, [shop])

	if (isGetRewardsLoading) return <PageLoading />

	return (
		<PageContainer>
			{isRewardRuleModalActive && (
				<Modal
					closeModal={() => closeModal(setIsRewardRuleModalActive, setIsRewardRuleModalVisible)}
					visible={isRewardRuleModalVisible}
					setVisible={setIsRewardRuleModalVisible}
				>
					<RewardRuleModal
						ruleType={currentRule}
						getRewards={getRewards}
						rewards={rewardsData?.rewardRules}
						isEditing={isRuleEditing}
						onClose={() => closeModal(setIsRewardRuleModalActive, setIsRewardRuleModalVisible)}
					/>
				</Modal>
			)}

			{isEnableBalanceModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsEnableBalanceModalActive, setIsEnableBalanceModalVisible)
					}
					visible={isEnableBalanceModalVisible}
					setVisible={setIsEnableBalanceModalVisible}
				>
					<ConfirmModal
						title={'Action Required'}
						description={'To use this reward, you need to enable balance payment method'}
						onConfirm={() => navigate('/settings/payments')}
						confirmButtonText={'Enable Balance'}
						buttonIcon={{
							id: 'blankWindow',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						onClose={() =>
							closeModal(setIsEnableBalanceModalActive, setIsEnableBalanceModalVisible)
						}
					/>
				</Modal>
			)}

			{isBalanceRewardsModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsBalanceRewardsModalActive, setIsBalanceRewardsModalVisible)
					}
					visible={isBalanceRewardsModalVisible}
					setVisible={setIsBalanceRewardsModalVisible}
				>
					<BalanceRewardModal
						balanceRewards={balanceRewards}
						balanceRewardType={currentBalanceRewardType}
						getRewards={getBalanceRewards}
						onClose={() =>
							closeModal(setIsBalanceRewardsModalActive, setIsBalanceRewardsModalVisible)
						}
					/>
				</Modal>
			)}

			{isReferralsModalActive && (
				<Modal
					closeModal={() => closeModal(setIsReferralsModalActive, setIsReferralsModalVisible)}
					visible={isReferralsModalVisible}
					setVisible={setIsReferralsModalVisible}
				>
					<ReferralsModal
						referralSystem={rewardsData?.referralSystem}
						getRewards={getRewards}
						onClose={() => closeModal(setIsReferralsModalActive, setIsReferralsModalVisible)}
					/>
				</Modal>
			)}

			<PageWrapper>
				<div className={styles.rewardsPage}>
					<PageHeader title={'Rewards'} />

					<div className={styles.body}>
						<RewardsCard
							key={BalanceRewardType.cashback}
							ruleType={BalanceRewardType.cashback}
							onEnableClick={() => onBalanceRewardsEnableClickHandler(BalanceRewardType.cashback)}
							onEditClick={() => onBalanceRewardsEnableClickHandler(BalanceRewardType.cashback)}
							getRewards={getBalanceRewards}
							balanceRewards={balanceRewards}
							isAffiliatesModifyAvailable={isAffiliatesModifyAvailable}
						/>
						<RewardsCard
							key={BalanceRewardType.topUpBonus}
							ruleType={BalanceRewardType.topUpBonus}
							onEnableClick={() => onBalanceRewardsEnableClickHandler(BalanceRewardType.topUpBonus)}
							onEditClick={() => onBalanceRewardsEnableClickHandler(BalanceRewardType.topUpBonus)}
							getRewards={getBalanceRewards}
							balanceRewards={balanceRewards}
							isAffiliatesModifyAvailable={isAffiliatesModifyAvailable}
						/>

						{Object.keys(RewardRuleType).map(ruleType => (
							<RewardsCard
								key={ruleType}
								ruleType={ruleType as RewardRuleType}
								onEnableClick={() => onEnableClickHandler(ruleType as RewardRuleType)}
								onEditClick={() => onEditClickHandler(ruleType as RewardRuleType)}
								getRewards={getRewards}
								reward={rewardsData?.rewardRules?.find(reward => reward.ruleType === ruleType)}
								isAffiliatesModifyAvailable={isAffiliatesModifyAvailable}
							/>
						))}
						<RewardsReferralsCard
							onEnableClick={onReferralsEnableClickHandler}
							onEditClick={onReferralsEnableClickHandler}
							getRewards={getRewards}
							referralSystem={rewardsData?.referralSystem}
							isAffiliatesModifyAvailable={isAffiliatesModifyAvailable}
						/>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default RewardsPage
