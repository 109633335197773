import React, { FC, useState } from 'react'
import uniqid from 'uniqid'
import styles from './ProductVariantsPrice.module.scss'
import generalStyles from '../../CreateProductPage.module.scss'
import PriceInput from '../../../../components/UI/PriceInput/PriceInput'
import PaymentType from '../../../../components/PaymentType/PaymentType'
import { VariantsPricing } from '../../../../types/CreateVariants/VariantsPricing'
import { ChargeType } from '../../../../types/ChargeType/ChargeType'
import Input from '../../../../components/UI/FormComponents/Input'
import RadioWrapper from '../../../../components/UI/RadioWrapper/RadioWrapper'
import Modal from '../../../../components/Modals/Modal'
import EditPricingGatewaySettingsModal from '../../../../components/Modals/EditPricingGatewaySettingsModal/EditPricingGatewaySettingsModal'
import { closeModal } from '../../../../helpers/closeModal'
import { useAppContext } from '../../../../hooks/useAppContext'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../../components/UI/Button/Button'
import { useNavigate } from 'react-router-dom'
import RequiredBadge from '../../../../components/UI/RequiredBadge/RequiredBadge'
import Checkbox from '../../../../components/UI/Checkbox/Checkbox'
import CustomSelect, { SelectStyles } from '../../../../components/UI/CustomSelect/CustomSelect'
import Switch from '../../../../components/UI/Switch/Switch'

function safeSubtraction(a: number, b: number) {
	const scale = 1000000
	return (Math.round(a * scale) - Math.round(b * scale)) / scale
}

export const durationSelectOptions = [
	{ value: '1', label: 'Days', title: 'day' },
	{ value: '7', label: 'Weeks', title: 'week' },
	{ value: '30', label: 'Months', title: 'month' },
	{ value: '365', label: 'Years', title: 'year' },
]

const defaultSubscriptionSettings = {
	compareAtPrice: null,
	entryFee: null,
	price: null,
	recurringIntervalDays: 1,
}

interface VariantPriceInputProps extends ProductVariantsPriceProps {
	field: string
}

const VariantPriceInput: FC<VariantPriceInputProps> = ({
	field,
	variantsPricing,
	setVariantsPricing,
	currentVariantIndex,
}) => {
	const defaultPrice = {
		currency: 'USD',
		amount: '',
	}
	const variantPricing = variantsPricing[currentVariantIndex]
	const pricingField =
		field.split('.').reduce<any>((o, i) => o?.[i], variantPricing) || defaultPrice

	const setNestedField = (value: string, key: 'currency' | 'amount') => {
		const fields = field.split('.')
		let tempObj = { ...variantPricing }
		let tempRef: any = tempObj

		fields.slice(0, -1).forEach(f => {
			if (!tempRef[f]) tempRef[f] = {}
			tempRef = tempRef[f]
		})

		tempRef[fields[fields.length - 1]] = {
			...defaultPrice,
			...tempRef[fields[fields.length - 1]],
			[key]: value,
		}
		console.log({
			tempObj,
			tempRef,
			n: {
				...variantsPricing,
				[currentVariantIndex]: tempObj,
			},
		})
		setVariantsPricing({
			...variantsPricing,
			[currentVariantIndex]: tempObj,
		})
	}

	return (
		<PriceInput
			currency={pricingField.currency}
			setCurrency={currency => setNestedField(currency, 'currency')}
			price={pricingField.amount.toString()}
			setPrice={amount => setNestedField(amount, 'amount')}
		/>
	)
}

const ChargeTypeCheckbox = ({
	chargeType,
	variantsPricing,
	setVariantsPricing,
	currentVariantIndex,
	title,
}: ProductVariantsPriceProps & { chargeType: ChargeType; title: string }) => {
	const variantPricing = variantsPricing[currentVariantIndex]
	const chargeTypes = variantPricing.chargeTypes || []
	const id = chargeType + uniqid()
	const isChecked = chargeTypes.some(e => e === chargeType)
	const isOtherChecked = chargeTypes.length > 1
	const shouldDisable = !isOtherChecked && isChecked

	const handleChange = () => {
		const newIsChecked = !isChecked

		if (!newIsChecked && !isOtherChecked) return
		const updatedChargeTypes = newIsChecked
			? [...chargeTypes, chargeType]
			: chargeTypes.filter(e => e !== chargeType)

		setVariantsPricing({
			...variantsPricing,
			[currentVariantIndex]: {
				...variantPricing,
				chargeTypes: updatedChargeTypes,
				subscriptionSettings:
					chargeType === ChargeType.RECURRING && !newIsChecked
						? null
						: { ...defaultSubscriptionSettings, ...variantPricing.subscriptionSettings },
			},
		})
	}
	return (
		<div
			className={`${styles.сhargeTypeCheckbox} ${shouldDisable ? styles.disabled : ''} `}
			onClick={handleChange}
		>
			<Checkbox
				contrast
				id={id}
				checked={isChecked}
				setChecked={handleChange}
				disabled={shouldDisable}
				onClick={e => e.preventDefault()}
			/>
			<div>{title}</div>
		</div>
	)
}
interface ProductVariantsPriceProps {
	variantsPricing: VariantsPricing
	setVariantsPricing: (value: VariantsPricing) => void
	currentVariantIndex: string
}
const ProductVariantsPrice: FC<ProductVariantsPriceProps> = ({
	variantsPricing,
	setVariantsPricing,
	currentVariantIndex,
}) => {
	const [editingGateway, setEditingGateway] = useState<string | null>(null)
	const [isPricingGatewaySettingsModalActive, setIsPricingGatewaySettingsModalActive] =
		useState(false)
	const [isPricingGatewaySettingsModalVisible, setIsPricingGatewaySettingsModalVisible] =
		useState(false)
	const [intervalType, setIntervalType] = useState(durationSelectOptions[0].value)
	const { availablePayments } = useAppContext()
	const navigate = useNavigate()
	const variantPricing = variantsPricing[currentVariantIndex]
	const subscriptionSettings = variantPricing.subscriptionSettings || defaultSubscriptionSettings
	const gateways = variantPricing?.gateways
	const isStandart = variantPricing.chargeTypes?.some(e => e === ChargeType.ONE_TIME)
	const isSubscription = variantPricing.chargeTypes?.some(e => e === ChargeType.RECURRING)
	const intervalNumber = Math.ceil(
		Number(subscriptionSettings.recurringIntervalDays) / Number(intervalType)
	)

	return (
		<>
			{isPricingGatewaySettingsModalActive && (
				<Modal
					closeModal={() =>
						closeModal(
							setIsPricingGatewaySettingsModalActive,
							setIsPricingGatewaySettingsModalVisible
						)
					}
					visible={isPricingGatewaySettingsModalVisible}
					setVisible={setIsPricingGatewaySettingsModalVisible}
				>
					<EditPricingGatewaySettingsModal
						currentGateway={editingGateway}
						pricing={variantPricing.gateways.find(gateway => gateway.gateway === editingGateway)}
						setPricing={pricing =>
							setVariantsPricing({
								...variantsPricing,
								[currentVariantIndex]: {
									...variantPricing,
									gateways: variantPricing.gateways.map(gateway =>
										gateway.gateway === editingGateway ? pricing : gateway
									),
								},
							})
						}
						onClose={() =>
							closeModal(
								setIsPricingGatewaySettingsModalActive,
								setIsPricingGatewaySettingsModalVisible
							)
						}
					/>
				</Modal>
			)}
			<div className={styles.productVariantsPrice}>
				<div className={styles.priceTypes}>
					<RadioWrapper
						id={uniqid()}
						value={''}
						setValue={() => {}}
						checked={true}
						title='SELL'
						description='Accept multiple payment options.'
						className={`${styles.priceType} ${styles.active}`}
					>
						<div className={styles.checkboxContainer}>
							<ChargeTypeCheckbox
								chargeType={ChargeType.ONE_TIME}
								title='Standard'
								variantsPricing={variantsPricing}
								setVariantsPricing={setVariantsPricing}
								currentVariantIndex={currentVariantIndex}
							/>
							<ChargeTypeCheckbox
								chargeType={ChargeType.RECURRING}
								title='Subscription'
								variantsPricing={variantsPricing}
								setVariantsPricing={setVariantsPricing}
								currentVariantIndex={currentVariantIndex}
							/>
						</div>
					</RadioWrapper>
				</div>
				{isStandart && (
					<>
						<div className={styles.fieldHeading}>Standard</div>
						<div className={generalStyles.doubleFields}>
							<div className={generalStyles.fieldWrapper}>
								<p className={generalStyles.fieldTitle}>
									Pricing <RequiredBadge />
								</p>
								<VariantPriceInput
									field='price'
									variantsPricing={variantsPricing}
									setVariantsPricing={setVariantsPricing}
									currentVariantIndex={currentVariantIndex}
								/>
							</div>
							<div className={generalStyles.fieldWrapper}>
								<p className={generalStyles.fieldTitle}>Compare-at-price</p>
								<VariantPriceInput
									field='compareAtPrice'
									variantsPricing={variantsPricing}
									setVariantsPricing={setVariantsPricing}
									currentVariantIndex={currentVariantIndex}
								/>
							</div>
						</div>

						<div className={generalStyles.tripleFields}>
							<div className={generalStyles.fieldWrapper}>
								<p className={generalStyles.fieldTitle}>Cost per Item</p>
								<VariantPriceInput
									field='costPerItem'
									variantsPricing={variantsPricing}
									setVariantsPricing={setVariantsPricing}
									currentVariantIndex={currentVariantIndex}
								/>
							</div>
							<div className={generalStyles.fieldWrapper}>
								<p className={generalStyles.fieldTitle}>Profit</p>
								<Input
									value={
										variantPricing.costPerItem?.amount
											? safeSubtraction(
													+variantPricing.price.amount,
													+variantPricing.costPerItem?.amount
											  )
											: '--'
									}
									setValue={() => {}}
									disabled={true}
									placeholder={'--'}
								/>
							</div>
							<div className={generalStyles.fieldWrapper}>
								<p className={generalStyles.fieldTitle}>Margin</p>
								<Input
									value={
										variantPricing.costPerItem?.amount
											? ((+variantPricing.price.amount - +variantPricing.costPerItem?.amount) /
													+variantPricing.price.amount) *
													100 +
											  '%'
											: '--'
									}
									setValue={() => {}}
									disabled={true}
									placeholder={'--'}
								/>
							</div>
						</div>
						<div className={generalStyles.horLine} />
					</>
				)}
				{isSubscription && (
					<>
						<div className={styles.fieldHeading}>Subscribe</div>

						<div className={generalStyles.doubleFields}>
							<div className={generalStyles.fieldWrapper}>
								<p className={generalStyles.fieldTitle}>
									Pricing <RequiredBadge />
								</p>
								<VariantPriceInput
									field='subscriptionSettings.price'
									variantsPricing={variantsPricing}
									setVariantsPricing={setVariantsPricing}
									currentVariantIndex={currentVariantIndex}
								/>
							</div>
							<div className={generalStyles.fieldWrapper}>
								<p className={generalStyles.fieldTitle}>Compare-at-price</p>
								<VariantPriceInput
									field='subscriptionSettings.compareAtPrice'
									variantsPricing={variantsPricing}
									setVariantsPricing={setVariantsPricing}
									currentVariantIndex={currentVariantIndex}
								/>
							</div>
						</div>

						<div className={generalStyles.doubleFields}>
							<div className={generalStyles.fieldWrapper}>
								<p className={generalStyles.fieldTitle}>Repeat Payment Every</p>
								<Input
									value={intervalNumber}
									setValue={value =>
										setVariantsPricing({
											...variantsPricing,
											[currentVariantIndex]: {
												...variantPricing,
												subscriptionSettings: {
													...subscriptionSettings,
													recurringIntervalDays: Number(value) * Number(intervalType),
												},
											},
										})
									}
									type={'number'}
									placeholder={''}
								/>
							</div>
							<div className={`${generalStyles.fieldWrapper} ${styles.withouTitle}`}>
								<CustomSelect
									style={SelectStyles.secondary}
									value={intervalType}
									setValue={value => {
										setIntervalType(value)
										setVariantsPricing({
											...variantsPricing,
											[currentVariantIndex]: {
												...variantPricing,
												subscriptionSettings: {
													...subscriptionSettings,
													recurringIntervalDays: Number(value) * intervalNumber,
												},
											},
										})
									}}
									options={durationSelectOptions}
								/>
							</div>
						</div>
						<p className={generalStyles.fieldDescription}>
							Customers will be charged ${subscriptionSettings?.price?.amount} every{' '}
							{intervalNumber} {durationSelectOptions.find(e => e.value === intervalType)?.title}
							{intervalNumber === 1 ? '' : 's'}.
						</p>
						<div className={generalStyles.doubleFields}>
							<div className={generalStyles.fieldWrapper}>
								<header>
									<p className={generalStyles.fieldTitle}>One-Time Setup Fee</p>
									<Switch
										checked={!!subscriptionSettings.entryFee}
										setChecked={checked =>
											setVariantsPricing({
												...variantsPricing,
												[currentVariantIndex]: {
													...variantPricing,
													subscriptionSettings: {
														...subscriptionSettings,
														entryFee: checked ? { amount: '0.00', currency: 'USD' } : null,
													},
												},
											})
										}
									/>
								</header>

								{subscriptionSettings.entryFee && (
									<VariantPriceInput
										field='subscriptionSettings.entryFee'
										variantsPricing={variantsPricing}
										setVariantsPricing={setVariantsPricing}
										currentVariantIndex={currentVariantIndex}
									/>
								)}
								<p className={generalStyles.fieldDescription}>
									Add a one-time starting fee in addition to the subscription.{' '}
								</p>
							</div>
						</div>
						<div className={generalStyles.horLine} />
					</>
				)}
				{availablePayments?.length ? (
					<div className={generalStyles.fieldWrapper}>
						<p className={generalStyles.fieldTitle}>
							Payment Types <RequiredBadge />
						</p>
						<div className={styles.paymentTypes}>
							{availablePayments?.map(currentGateway => (
								<PaymentType
									key={currentGateway.id}
									checked={gateways.some(gateway => gateway.gateway === currentGateway.name)}
									setChecked={value => {
										if (value) {
											setVariantsPricing({
												...variantsPricing,
												[currentVariantIndex]: {
													...variantPricing,
													gateways: [
														...gateways,
														{
															gateway: currentGateway.name,
															overrodePrice: null,
														},
													],
												},
											})
										} else {
											setVariantsPricing({
												...variantsPricing,
												[currentVariantIndex]: {
													...variantPricing,
													gateways: gateways.filter(
														gateway => gateway.gateway !== currentGateway.name
													),
												},
											})
										}
									}}
									gateway={currentGateway}
									onEditButtonClick={() => {
										setEditingGateway(currentGateway.name)
										setIsPricingGatewaySettingsModalActive(true)
									}}
								/>
							))}
						</div>
					</div>
				) : (
					<div className={generalStyles.fieldWrapper}>
						<header>
							<div>
								<p className={generalStyles.fieldTitle}>Payment Set Up Required</p>
								<p className={generalStyles.fieldDescription}>
									No payment methods are connected. To start accepting payments, tap on the “Set Up
									Payments” button.
								</p>
							</div>
							<Button
								style={ButtonStyles.THIRD}
								disableShadow={true}
								icon={{
									id: 'blankWindow',
									height: 12,
									width: 12,
									align: ButtonsIconAlign.LEFT,
								}}
								onClick={() => navigate('/settings/payments')}
							>
								Set Up Payments
							</Button>
						</header>
					</div>
				)}
			</div>
		</>
	)
}

export default ProductVariantsPrice
