import React, { FC, useEffect, useRef } from 'react'
import styles from './BackgroundOverlay.module.scss'

interface BackgroundOverlayProps {
	isActive: boolean
	onClick?: () => void
}

const BackgroundOverlay: FC<BackgroundOverlayProps> = ({ isActive, onClick }) => {
	const ref = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (isActive) {
			ref.current?.classList.add(styles.active)
			setTimeout(() => {
				ref.current?.classList.add(styles.visible)
			}, 1)
		} else {
			ref.current?.classList.remove(styles.visible)
			setTimeout(() => {
				ref.current?.classList.remove(styles.active)
			}, 200)
		}
	}, [isActive])

	return <div className={styles.backgroundOverlay} ref={ref} onClick={onClick}></div>
}

export default BackgroundOverlay
