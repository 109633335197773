import React, { useEffect, useState } from 'react'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import generalStyles from '../Settings.module.scss'
import styles from './SettingsMigratePage.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../components/UI/Button/Button'
import MigratingStepper from './components/MigratingStepper'
import MigrateApiKeyStep from './components/MigrateApiKeyStep'
import SelectDataTransferStep from './components/SelectDataTransferStep'
import AdditionalDataTransferStep from './components/AdditionalDataTransferStep'
import StatusAlertBadge from '../../../components/UI/StatusAlertBadge/StatusAlertBadge'
import { StatusAlert } from '../../../types/StatusAlert/StatusAlert'
import loadingWTFImage from '../../../assets/images/loading-wtf.gif'
import { useAPI } from '../../../hooks/useAPI'
import { MigrationService } from '../../../API/MigrationService'
import { useAppContext } from '../../../hooks/useAppContext'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'
import { GetMigration } from '../../../types/GetMigration/GetMigration'
import Spinner from '../../../components/UI/Spinner/Spinner'
import { useNavigate } from 'react-router-dom'

export enum MigratingStep {
	API_KEY,
	SELECT_DATA_TRANSFER,
	ADDITIONAL_DATA_TRANSFER,
	VERIFY_MIGRATION,
}

export interface MigrateOptions {
	migrateProducts: boolean
	migrateBlacklists: boolean
	migrateCategories: boolean
	migrateGroups: boolean
	migrateOrders: boolean
	migrateTickets: boolean
	migrateContent: boolean
}

const SettingsMigratePage = () => {
	const { shop } = useAppContext()
	const [currentStep, setCurrentStep] = useState<MigratingStep>(MigratingStep.API_KEY)
	const [apiKey, setApiKey] = useState('')
	const [shopDomain, setShopDomain] = useState('')
	const [migrateOptions, setMigrateOptions] = useState<MigrateOptions>({
		migrateProducts: true,
		migrateBlacklists: true,
		migrateCategories: true,
		migrateGroups: true,
		migrateOrders: true,
		migrateTickets: true,
		migrateContent: true,
	})
	const [errors, setErrors] = useState<FormErrors>({})
	const navigate = useNavigate()

	const createMigrationValidator = () => {
		const apiKeyValidator = fieldValidators.blankValidator('Sellpass API Key', apiKey)
		const shopDomainValidator = fieldValidators.blankValidator('Sellpass Shop Domain', shopDomain)

		setErrors({
			apiKey: apiKeyValidator,
			shopDomain: shopDomainValidator,
		})

		return !(apiKeyValidator || shopDomainValidator)
	}

	const [isCreateMigrationLoading, , createMigration] = useAPI(
		() =>
			MigrationService.createMigration(shop?.id || '', {
				migrateBlacklists: migrateOptions.migrateBlacklists,
				migrateCategories: migrateOptions.migrateCategories,
				apiKey,
				migrateGroups: migrateOptions.migrateGroups,
				migrateOrders: migrateOptions.migrateOrders,
				migrateProducts: migrateOptions.migrateProducts,
				migrateTickets: migrateOptions.migrateTickets,
				migrateAnnouncements: migrateOptions.migrateContent,
				migrateFaqs: migrateOptions.migrateContent,
				fromShopDomain: shopDomain.replace(/^https?:\/\/|\/$/g, ''),
			}),
		false,
		'Migrate request successfully sent',
		{
			onSuccess: () => {
				getMigrationData()
			},
		}
	)

	const [isGetMigrationDataLoading, migrationData, getMigrationData] = useAPI<GetMigration>(
		() => MigrationService.getMigration(shop?.id || ''),
		false,
		undefined,
		{
			onSuccess: response => {
				if (response.data.data.id) {
					setCurrentStep(MigratingStep.VERIFY_MIGRATION)
				}
			},
		}
	)

	const onContinueClickHandler = () => {
		if (currentStep > 0 && currentStep < MigratingStep.VERIFY_MIGRATION) {
			setCurrentStep(currentStep => currentStep + 1)
		}

		if (currentStep === MigratingStep.ADDITIONAL_DATA_TRANSFER) {
			createMigration()
		}
	}

	useEffect(() => {
		if (shop) {
			getMigrationData()
		}
	}, [shop])

	return (
		<PageWrapper>
			<div className={generalStyles.settingsPageInnerBody}>
				<div className={`${styles.migrateSection}`}>
					{isGetMigrationDataLoading ? (
						<Spinner />
					) : (
						<>
							<p className={styles.defaultTitle}>Migrate to Billgang</p>
							<div className={`${styles.migrateSectionBody} ${styles.mtMediumLarge}`}>
								<MigratingStepper currentStep={currentStep} setCurrentStep={setCurrentStep} />

								{currentStep === MigratingStep.API_KEY && (
									<MigrateApiKeyStep
										apiKey={apiKey}
										setApiKey={setApiKey}
										shopDomain={shopDomain}
										setShopDomain={setShopDomain}
										errors={errors}
										setErrors={setErrors}
									/>
								)}
								{currentStep === MigratingStep.SELECT_DATA_TRANSFER && (
									<SelectDataTransferStep
										migrateOptions={migrateOptions}
										setMigrateOptions={setMigrateOptions}
									/>
								)}

								{currentStep === MigratingStep.ADDITIONAL_DATA_TRANSFER && (
									<AdditionalDataTransferStep
										migrateOptions={migrateOptions}
										setMigrateOptions={setMigrateOptions}
									/>
								)}

								{currentStep === MigratingStep.VERIFY_MIGRATION &&
									!migrationData?.isError &&
									!migrationData?.isMigrated && (
										<div className={styles.mtMediumLarge}>
											<StatusAlertBadge
												title={'Pending Migration'}
												description={
													<div>
														<p>
															Your migration is currently in progress. Our system is currently
															fetching data from Sellpass and storing them on BIllgang.
														</p>

														<div className={`${styles.loadingImageWrapper} ${styles.mtMedium}`}>
															<img src={loadingWTFImage} alt='loading' />
														</div>
													</div>
												}
												statusAlertType={StatusAlert.WARNING}
											/>
										</div>
									)}

								{currentStep === MigratingStep.VERIFY_MIGRATION && migrationData?.isError && (
									<div className={styles.mtMediumLarge}>
										<StatusAlertBadge
											title={'Data Migration Failed'}
											description={
												<p>
													Uh oh. While we were transferring your data from Sellpass to Billgang, our
													system was met with an unknown error. Don’t worry - our engineers have
													been notified.
													<br />
													<br />
													Please contact us through our support channels and provide us with your
													storefront link, email address, and with a brief summary of your business.
												</p>
											}
											button={{
												icon: {
													id: 'blankWindow',
													width: 13,
													height: 13,
													align: ButtonsIconAlign.LEFT,
												},
												text: 'Contact engineering support',
												onClick: () =>
													window.open('https://discord.gg/5MSmTbbvxJ', 'blank', 'noopener'),
											}}
											statusAlertType={StatusAlert.DANGER}
										/>
									</div>
								)}

								{currentStep === MigratingStep.VERIFY_MIGRATION && migrationData?.isMigrated && (
									<div className={styles.mtMediumLarge}>
										<StatusAlertBadge
											title={'Successfully Migrated Data'}
											description={
												<p>
													Welcome to Billgang. If you’re already here, you’re more than likely aware
													but here goes nothing - Billgang is the most powerful (and easiest)
													platform to sell digital goods online.
													<br />
													<br />
													Billgang powers builders, visionaries, the leaders of tomorrow, and people
													just like you.
											
												</p>
											}
											button={{
												icon: {
													id: 'blankWindow',
													width: 13,
													height: 13,
													align: ButtonsIconAlign.LEFT,
												},
												text: 'Visit business dashboard',
												onClick: () => navigate('/'),
											}}
											statusAlertType={StatusAlert.SUCCESS}
										/>
									</div>
								)}

								{!migrationData?.id && (
									<div className={styles.buttons}>
										{currentStep === MigratingStep.API_KEY ? (
											<Button
												style={ButtonStyles.BORDERED}
												icon={{
													id: 'arrowRight',
													width: 13,
													height: 12,
													align: ButtonsIconAlign.RIGHT,
												}}
												width={'100%'}
												disableShadow={true}
												onClick={() => {
													if (createMigrationValidator()) {
														setCurrentStep(currentStep + 1)
													}
												}}
											>
												Start Migration
											</Button>
										) : (
											<>
												<Button
													style={ButtonStyles.BORDERED}
													icon={{
														id: 'arrowLeft',
														width: 13,
														height: 12,
														align: ButtonsIconAlign.LEFT,
													}}
													disableShadow={true}
													onClick={() =>
														currentStep > 0 && setCurrentStep(currentStep => currentStep - 1)
													}
												>
													Back
												</Button>
												<Button
													style={ButtonStyles.BORDERED}
													icon={{
														id: 'arrowRight',
														width: 13,
														height: 12,
														align: ButtonsIconAlign.RIGHT,
													}}
													width={'100%'}
													disableShadow={true}
													onClick={onContinueClickHandler}
													isLoading={isCreateMigrationLoading}
												>
													{currentStep === MigratingStep.ADDITIONAL_DATA_TRANSFER
														? 'Start Migration'
														: 'Continue'}
												</Button>
											</>
										)}
									</div>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</PageWrapper>
	)
}

export default SettingsMigratePage
