import React, { FC, useEffect, useState } from 'react'
import styles from '../Modals.module.scss'
import Input from '../../UI/FormComponents/Input'
import Textarea from '../../UI/FormComponents/Textarea'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { useAPI } from '../../../hooks/useAPI'
import { FaqsService } from '../../../API/FaqsService'
import { useAppContext } from '../../../hooks/useAppContext'
import { FAQ } from '../../../types/FAQ/FAQ'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import FormErrorsDisplayBadge from '../../UI/FormErrorsDisplayBadge/FormErrorsDisplayBadge'
import { fieldValidators } from '../../../helpers/fieldValidators'

interface CreateFaqModalProps extends BaseModalProps {
	editingFaq: FAQ | null
	getFaqs: () => void
}

const CreateFaqModal: FC<CreateFaqModalProps> = ({ onClose, editingFaq, getFaqs }) => {
	const { shop } = useAppContext()
	const [question, setQuestion] = useState('')
	const [answer, setAnswer] = useState('')
	const [showErrorsDisplay, setShowErrorsDisplay] = useState(false)
	const [errors, setErrors] = useState<FormErrors>({
		question: '',
		answer: '',
	})
	const errorMessages = Object.keys(errors)
		.map(key => errors[key])
		.filter(error => error)

	const [isCreateFaqLoading, , createFaq] = useAPI(
		() =>
			FaqsService.create(shop?.id || '', {
				question,
				answer,
				position: 0,
			}),
		false,
		'FAQ successfully created',
		{
			onSuccess: () => {
				onClose()
				getFaqs()
			},
		}
	)

	const [isUpdateFaqLoading, , updateFaq] = useAPI(
		(editingId: number) =>
			FaqsService.update(shop?.id || '', editingId, {
				question,
				answer,
				position: 0,
			}),
		false,
		'FAQ successfully updated',
		{
			onSuccess: () => {
				onClose()
				getFaqs()
			},
		}
	)

	const submitValidator = () => {
		const questionValidator = fieldValidators.blankValidator('Question', question)
		const answerValidator = fieldValidators.blankValidator('Answer', answer)

		setErrors({
			question: questionValidator,
			answer: answerValidator,
		})

		return !(questionValidator || answerValidator)
	}

	const onSubmitHandler = () => {
		if (submitValidator()) {
			if (editingFaq) {
				updateFaq(editingFaq.id)
			} else {
				createFaq()
			}
		} else {
			setShowErrorsDisplay(true)
		}
	}

	useEffect(() => {
		if (editingFaq) {
			setAnswer(editingFaq.answer)
			setQuestion(editingFaq.question)
		}
	}, [editingFaq])

	return (
		<div className={styles.modalInner}>
			{/*{showErrorsDisplay && !!errorMessages.length && (*/}
			{/*	<FormErrorsDisplayBadge title={`FAQ Create Error`} errors={errorMessages} isModal={true} />*/}
			{/*)}*/}

			<header className={styles.header}>
				<h1> {editingFaq ? 'Edit' : 'Create'} FAQ</h1>
			</header>

			<div className={styles.body}>
				<div className={styles.fieldWrapper}>
					<h2>Question</h2>
					<Input
						value={question}
						setValue={setQuestion}
						placeholder={'Type your question here'}
						errorMessage={errors['question']}
						onBlur={() =>
							setErrors({
								...errors,
								question: fieldValidators.blankValidator('Question', question),
							})
						}
					/>
				</div>
				<div className={styles.fieldWrapper}>
					<h2>Answer</h2>
					<Textarea
						value={answer}
						setValue={setAnswer}
						placeholder={'Type a detailed answer for your question here...'}
						height={150}
						errorMessage={errors['answer']}
						onBlur={() =>
							setErrors({
								...errors,
								answer: fieldValidators.blankValidator('Answer', answer),
							})
						}
						resizable={false}
					/>
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'plus',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						isLoading={isCreateFaqLoading || isUpdateFaqLoading}
						onClick={onSubmitHandler}
					>
						{editingFaq ? 'Edit' : 'Create'} faq
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default CreateFaqModal
