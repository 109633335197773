import React, { FC, useState } from 'react'
import styles from './RewardsCard.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { getRewardRuleTypeOption } from '../../helpers/getRewardRuleTypeOption'
import { RewardRuleType } from '../../types/RewardRuleType/RewardRuleType'
import { useAPI } from '../../hooks/useAPI'
import { CreateRewardRule } from '../../types/CreateRewardRule/CreateRewardRule'
import { RewardsService } from '../../API/RewardsService'
import { GetReward } from '../../types/GetReward/GetReward'
import { useAppContext } from '../../hooks/useAppContext'
import { ReferralSystem } from '../../types/ReferralSystem/ReferralSystem'

interface RewardsReferralsCardProps {
	onEnableClick: () => void
	onEditClick: () => void
	referralSystem?: ReferralSystem
	getRewards: () => void
	isAffiliatesModifyAvailable: boolean
}

const RewardsReferralsCard: FC<RewardsReferralsCardProps> = ({
	onEditClick,
	onEnableClick,
	referralSystem,
	getRewards,
	isAffiliatesModifyAvailable,
}) => {
	const { shop } = useAppContext()

	const [isUpdateRewardLoading, , updateReward] = useAPI(
		() =>
			RewardsService.updateReferralsSystems(shop?.id || '', {
				earnPercent: referralSystem?.earnPercent || 0,
				isEnabled: false,
			}),
		false,
		'Referral system successfully disabled',
		{
			onSuccess: getRewards,
		}
	)

	return (
		<div className={styles.rewardsCard}>
			<header>
				<div className={styles.ruleIcon}>
					<SpriteIcon iconId={'gamepad'} width={26} height={20} />
				</div>
			</header>

			<div className={styles.body}>
				<h1 className={styles.title}>{'Earn X% from Referrals'}</h1>

				<p className={styles.description}>
					Give customers a percent of the payment via balance of the friends they refer to your
					store.
				</p>
			</div>

			{isAffiliatesModifyAvailable && (
				<footer className={styles.footer}>
					{referralSystem?.isEnabled ? (
						<div className={styles.buttons}>
							<Button
								style={ButtonStyles.BORDERED}
								disableShadow={true}
								icon={{
									id: 'pen',
									width: 15,
									height: 15,
									align: ButtonsIconAlign.LEFT,
									className: styles.dark,
								}}
								onClick={onEditClick}
							>
								Edit
							</Button>
							<Button
								style={ButtonStyles.BORDERED}
								disableShadow={true}
								icon={{
									id: 'trashBin',
									width: 12,
									height: 13,
									align: ButtonsIconAlign.LEFT,
									className: styles.dark,
								}}
								onClick={updateReward}
								isLoading={isUpdateRewardLoading}
							>
								Disable
							</Button>
						</div>
					) : (
						<Button
							style={ButtonStyles.DARK}
							icon={{
								id: 'circlePlus',
								width: 14.5,
								height: 14.5,
								align: ButtonsIconAlign.LEFT,
							}}
							disableShadow={true}
							onClick={onEnableClick}
						>
							Enable Reward
						</Button>
					)}
				</footer>
			)}
		</div>
	)
}

export default RewardsReferralsCard
