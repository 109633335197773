import React, { FC } from 'react'
import styles from './ProductAdvisor.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { CreateProductSectionIds } from '../../types/CreateProductSectionIds/CreateProductSectionIds'
import { VariantsGeneral } from '../../types/CreateVariants/VariantsGeneral'
import { VariantsPricing } from '../../types/CreateVariants/VariantsPricing'
import { VariantsDelivery } from '../../types/CreateVariants/VariantsDelivery'
import { VariantsCustomFields } from '../../types/CreateVariants/VariantsCustomFields'
import { VariantsDiscordSettings } from '../../types/CreateVariants/VariantsDiscordSettings'
import { VariantsAdvanced } from '../../types/CreateVariants/VariantsAdvanced'
import { scrollIntoViewAndFocus } from '../../helpers/scrollIntoViewAndFocus'
import { ProductVariantsTab } from '../../types/ProductVariantsTab/ProductVariantsTab'
import { DeliveryType } from '../../types/DeliveryType/DeliveryType'

interface ProductAdvisorVariantsProps {
	percent: number
	setCurrenSectionId: (value: CreateProductSectionIds) => void
	variantsGeneral: VariantsGeneral
	variantsPricing: VariantsPricing
	currentVariantIndex: string
	variantsDelivery: VariantsDelivery
	variantsCustomFields: VariantsCustomFields
	variantsDiscordSettings: VariantsDiscordSettings
	variantsAdvanced: VariantsAdvanced
	currentVariantsTab: { [variantIndex: string]: ProductVariantsTab }
	setCurrentVariantsTab: (value: { [variantIndex: string]: ProductVariantsTab }) => void
	isZeroStockDone: boolean | 0
}

const ProductAdvisorVariants: FC<ProductAdvisorVariantsProps> = ({
	variantsPricing,
	variantsGeneral,
	variantsAdvanced,
	variantsDelivery,
	percent,
	setCurrenSectionId,
	variantsDiscordSettings,
	currentVariantIndex,
	variantsCustomFields,
	setCurrentVariantsTab,
	currentVariantsTab,
	isZeroStockDone,
}) => {
	const variantDelivery = variantsDelivery[currentVariantIndex]
	const variantCustomFields = variantsCustomFields[currentVariantIndex]
	const variantDiscordSettings = variantsDiscordSettings[currentVariantIndex]
	const variantPricing = variantsPricing[currentVariantIndex]
	const deliveryConfigurationKeys = Object.keys(variantDelivery.deliveryConfiguration)

	const criticalAlerts = [
		!isZeroStockDone,
		variantDelivery.deliveryType === DeliveryType.DYNAMIC
			? !variantDelivery.dynamicDeliveryUrl
			: !deliveryConfigurationKeys.length,
	].filter(boolean => boolean)

	const onNextClickHandler = () => {
		setCurrenSectionId(CreateProductSectionIds.SEO)
		scrollIntoViewAndFocus(CreateProductSectionIds.SEO)
	}

	const onBackClickHandler = () => {
		setCurrenSectionId(CreateProductSectionIds.DESIGN)
		scrollIntoViewAndFocus(CreateProductSectionIds.DESIGN)
	}

	const onAdvisorClickHandler = (tab: ProductVariantsTab, id: string) => {
		setCurrentVariantsTab({
			...currentVariantsTab,
			[currentVariantIndex]: tab,
		})
		scrollIntoViewAndFocus(id)
	}

	return (
		<div className={styles.productAdvisor}>
			{criticalAlerts.length ? (
				<header className={styles.header}>
					<div className={styles.criticalAlert}>
						<SpriteIcon iconId={'exclamationMarkTriangle'} width={16} height={16} />
						<p>
							{criticalAlerts.length} Critical Alert{criticalAlerts.length > 1 ? 's' : ''}
						</p>
					</div>
					<p className={styles.description}>
						Before you create this product, we recommend fixing these critical issues.
					</p>
				</header>
			) : (
				<header className={styles.header}>
					<h2 className={styles.title}>Product Advisor</h2>
					<p className={styles.progressPercent}>{percent}%</p>

					<div className={styles.progressBar}>
						<div
							className={styles.progressBarActiveLine}
							style={{
								width: `${percent}%`,
							}}
						/>
						<div
							className={styles.progressBarWillActiveLine}
							style={{
								width: `${
									percent +
									(!variantCustomFields.customFields.length ? 10 : 0) +
									(!variantDiscordSettings.isEnabled ? 10 : 0) +
									(!variantPricing.compareAtPrice?.amount ? 10 : 0) +
									(!variantPricing.costPerItem?.amount ? 10 : 0) +
									(!variantPricing.gateways.some(gateway => gateway.overrodePrice?.amount) ? 10 : 0)
								}%`,
							}}
						/>
					</div>

					<p className={styles.description}>
						Use this space to create variants, add multiple deliverables and customize integrations.
					</p>
				</header>
			)}

			<div className={styles.body}>
				<h2 className={styles.title}>Variant Builder</h2>

				<div className={styles.advices}>
					<div
						className={`${styles.adviceItem} ${
							(
								variantDelivery.deliveryType === DeliveryType.DYNAMIC
									? variantDelivery.dynamicDeliveryUrl
									: deliveryConfigurationKeys.length
							)
								? styles.done
								: styles.alert
						}`}
						onClick={() =>
							onAdvisorClickHandler(ProductVariantsTab.DELIVERY, CreateProductSectionIds.VARIANTS)
						}
					>
						<div>
							<h3 className={styles.adviceTitle}>Add Deliverable(s)</h3>
							<p className={styles.adviceDescription}>
								Select from serials, downloads, service, etc.
							</p>
						</div>

						<SpriteIcon
							className={styles.asteriskSymbol}
							iconId={'asteriskSymbol'}
							width={10}
							height={10}
						/>
						<SpriteIcon
							className={styles.checked}
							iconId={'checkedCircle'}
							width={15}
							height={15}
						/>
					</div>
					<div
						className={`${styles.adviceItem} ${isZeroStockDone ? styles.done : styles.alert} `}
						onClick={() =>
							onAdvisorClickHandler(ProductVariantsTab.DELIVERY, CreateProductSectionIds.VARIANTS)
						}
					>
						<div>
							<h3 className={styles.adviceTitle}>Zero Stock</h3>
							<p className={styles.adviceDescription}>Add stock to all deliverables</p>
						</div>

						<SpriteIcon
							className={styles.asteriskSymbol}
							iconId={'asteriskSymbol'}
							width={10}
							height={10}
						/>
						<SpriteIcon
							className={styles.checked}
							iconId={'checkedCircle'}
							width={15}
							height={15}
						/>
					</div>

					<div
						className={styles.adviceItem}
						onClick={() => onAdvisorClickHandler(ProductVariantsTab.PRICING, 'productCostPerItem')}
					>
						<div>
							<h3 className={styles.adviceTitle}>Cost per Item</h3>
							<p className={styles.adviceDescription}>
								Optimize your analytics with profit margins.
							</p>
						</div>

						<div className={styles.advicePercent}>+10%</div>
						<SpriteIcon
							className={styles.checked}
							iconId={'checkedCircle'}
							width={15}
							height={15}
						/>
					</div>
					<div
						className={styles.adviceItem}
						onClick={() =>
							onAdvisorClickHandler(ProductVariantsTab.PRICING, 'productPriceComparison')
						}
					>
						<div>
							<h3 className={styles.adviceTitle}>Price Comparison</h3>
							<p className={styles.adviceDescription}>
								Add a price comparison (<s>$20.00</s>).
							</p>
						</div>

						<div className={styles.advicePercent}>+10%</div>
						<SpriteIcon
							className={styles.checked}
							iconId={'checkedCircle'}
							width={15}
							height={15}
						/>
					</div>
					<div
						className={`${styles.adviceItem} ${
							variantCustomFields.customFields.length && styles.done
						}`}
						onClick={() =>
							onAdvisorClickHandler(ProductVariantsTab.CHECKOUT, CreateProductSectionIds.VARIANTS)
						}
					>
						<div>
							<h3 className={styles.adviceTitle}>Custom Fields</h3>
							<p className={styles.adviceDescription}>Request customer info before checkout.</p>
						</div>

						<div className={styles.advicePercent}>+10%</div>
						<SpriteIcon
							className={styles.checked}
							iconId={'checkedCircle'}
							width={15}
							height={15}
						/>
					</div>
					<div
						className={`${styles.adviceItem} ${variantDiscordSettings.isEnabled && styles.done}`}
						onClick={() =>
							onAdvisorClickHandler(ProductVariantsTab.CHECKOUT, CreateProductSectionIds.VARIANTS)
						}
					>
						<div>
							<h3 className={styles.adviceTitle}>Discord Integration</h3>
							<p className={styles.adviceDescription}>
								Automatically invite customers to your server.
							</p>
						</div>

						<div className={styles.advicePercent}>+10%</div>
						<SpriteIcon
							className={styles.checked}
							iconId={'checkedCircle'}
							width={15}
							height={15}
						/>
					</div>
					<div
						className={`${styles.adviceItem} ${
							variantPricing.gateways.some(gateway => gateway.overrodePrice?.amount) && styles.done
						}`}
						onClick={() =>
							onAdvisorClickHandler(ProductVariantsTab.PRICING, CreateProductSectionIds.VARIANTS)
						}
					>
						<div>
							<h3 className={styles.adviceTitle}>Payment Discounts</h3>
							<p className={styles.adviceDescription}>Set gateway-specific discounts.</p>
						</div>

						<div className={styles.advicePercent}>+10%</div>
						<SpriteIcon
							className={styles.checked}
							iconId={'checkedCircle'}
							width={15}
							height={15}
						/>
					</div>
				</div>

				<footer className={styles.footer}>
					<div className={styles.buttons}>
						<Button
							style={ButtonStyles.BORDERED}
							disableShadow={true}
							width={'100%'}
							icon={{
								id: 'arrowLeft',
								height: 12,
								width: 13,
								align: ButtonsIconAlign.LEFT,
							}}
							onClick={onBackClickHandler}
						>
							Back
						</Button>
						<Button
							style={ButtonStyles.BORDERED}
							disableShadow={true}
							width={'100%'}
							icon={{
								id: 'arrowRight',
								height: 12,
								width: 13,
								align: ButtonsIconAlign.RIGHT,
							}}
							onClick={onNextClickHandler}
						>
							Next
						</Button>
					</div>
				</footer>
			</div>
		</div>
	)
}

export default ProductAdvisorVariants
