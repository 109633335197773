import React, { FC } from 'react'
import MDEditor from '@uiw/react-md-editor'
import './CustomMDEditor.scss'
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage'

interface CustomMdEditorProps {
	value: string | undefined
	setValue: (value: string | undefined) => void
	id?: string
	placeholder?: string
	errorMessage?: string
	onBlur?: () => void
}

const CustomMdEditor: FC<CustomMdEditorProps> = ({
	value,
	setValue,
	id,
	placeholder,
	errorMessage,
	onBlur,
}) => {
	return (
		<div className={'mdContainer'}>
			<MDEditor
				className={'customMDEditor'}
				value={value}
				onChange={setValue}
				id={id}
				onBlur={onBlur}
				placeholder={placeholder}
			/>
			{errorMessage && <FieldErrorMessage errorMessage={errorMessage} />}
		</div>
	)
}

export default CustomMdEditor
