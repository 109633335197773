import React, { FC } from 'react'
import styles from './ReplyAnswerBadge.module.scss'

interface ReplyAnswerBadgeProps {
	message: string
	author: string
}

const ReplyAnswerBadge: FC<ReplyAnswerBadgeProps> = ({ message, author }) => {
	return (
		<div className={styles.replyAnswerBadge}>
			<p className={styles.message}>{message}</p>
			<p className={styles.author}>{author}</p>
		</div>
	)
}

export default ReplyAnswerBadge
