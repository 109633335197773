import React, { FC } from 'react'
import styles from '../DiscordBotBuilderPage.module.scss'
import discordTokenImg from '../../../assets/images/discordToken.png'
import discordClientIdSecretImg from '../../../assets/images/discordClientIdSecret.png'
import Input from '../../../components/UI/FormComponents/Input'
import AlertBadge from '../../../components/UI/AlertBadge/AlertBadge'
import { Alert } from '../../../types/Alert/Alert'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'

interface ConnectBotStepProps {
	botSettings: {
		primary: {
			botToken: string
			clientId: string
			clientSecret: string
		}
		secondary: {
			botToken: string
			clientId: string
			clientSecret: string
		}
	}
	setBotSettings: (value: {
		primary: {
			botToken: string
			clientId: string
			clientSecret: string
		}
		secondary: {
			botToken: string
			clientId: string
			clientSecret: string
		}
	}) => void
	botType: 'secondary' | 'primary'
	formErrors: FormErrors
	setFromErrors: (value: FormErrors) => void
}

const ConnectBotStep: FC<ConnectBotStepProps> = ({
	setBotSettings,
	botSettings,
	botType,
	formErrors,
	setFromErrors,
}) => {
	return (
		<div className={styles.stepWrapper}>
			<p className={styles.defaultTitle}>Discord Bot Credentials</p>
			<p className={`${styles.secondaryText} ${styles.mt10}`}>
				Visit the applications page of Discord’s developer platform and create a new bot or edit any
				previously created bot. Once you’re there, begin entering the required credentials through
				the inputs below.
			</p>
			<div className={`${styles.doubleGrid} ${styles.mtMediumLarge}`}>
				<div className={styles.imageWrapper}>
					<img src={discordClientIdSecretImg} alt='discord client id & secret' />
				</div>
				<div className={styles.imageWrapper}>
					<img src={discordTokenImg} alt='discord token' />
				</div>
			</div>
			<div className={styles.mtMediumLarge}>
				<p className={styles.defaultTitle}>Client ID</p>
				<div className={styles.mt10}>
					<Input
						value={botSettings[botType].clientId}
						setValue={value =>
							setBotSettings({
								...botSettings,
								[botType]: {
									...botSettings[botType],
									clientId: value,
								},
							})
						}
						placeholder={'000000000000000000000000'}
						errorMessage={formErrors[botType + 'ClientId']}
						onBlur={() =>
							setFromErrors({
								...formErrors,
								[botType + 'ClientId']: fieldValidators.blankValidator(
									'Client Id',
									botSettings[botType].clientId
								),
							})
						}
					/>
				</div>
				<p className={`${styles.secondaryText} ${styles.mt10}`}>
					Copy and paste the application ID of your bot from this page:
					<a
						className={styles.link}
						href='https://discord.com/developers/applications'
						target={'_blank'}
					>
						https://discord.com/developers/applications
					</a>
					.
				</p>
			</div>
			<div className={styles.mtMediumLarge}>
				<AlertBadge alert={Alert.DANGER} disableShadow={true}>
					Important: When setting up your Discord bot, be sure to enable all required intents such
					as presence, server members, and message content intent toggles.
				</AlertBadge>
			</div>
			<div className={`${styles.doubleGrid} ${styles.mtMediumLarge}`}>
				<div>
					<p className={styles.defaultTitle}>Client Secret</p>
					<div className={styles.mt10}>
						<Input
							value={botSettings[botType].clientSecret}
							setValue={value =>
								setBotSettings({
									...botSettings,
									[botType]: {
										...botSettings[botType],
										clientSecret: value,
									},
								})
							}
							placeholder={'000000000000000000000000'}
							autoComplete={'off'}
							errorMessage={formErrors[botType + 'ClientSecret']}
							onBlur={() =>
								setFromErrors({
									...formErrors,
									[botType + 'ClientSecret']: fieldValidators.blankValidator(
										'Client Secret',
										botSettings[botType].clientSecret
									),
								})
							}
						/>
					</div>
					<p className={`${styles.secondaryText} ${styles.mt10}`}>
						Enter your client secret then copy the newly generated secret and paste it in the field
						above.
					</p>
				</div>
				<div>
					<p className={styles.defaultTitle}>Bot Token</p>
					<div className={styles.mt10}>
						<Input
							type={'password'}
							value={botSettings[botType].botToken}
							setValue={value =>
								setBotSettings({
									...botSettings,
									[botType]: {
										...botSettings[botType],
										botToken: value,
									},
								})
							}
							placeholder={'**************************************'}
							autoComplete={'off'}
							errorMessage={formErrors[botType + 'BotToken']}
							onBlur={() =>
								setFromErrors({
									...formErrors,
									[botType + 'BotToken']: fieldValidators.blankValidator(
										'Bot Token',
										botSettings[botType].botToken
									),
								})
							}
						/>
					</div>
					<p className={`${styles.secondaryText} ${styles.mt10}`}>
						Enter your bot token then copy the newly generated token and paste it in the field
						above.
					</p>
				</div>
			</div>
		</div>
	)
}

export default ConnectBotStep
