import React, { FC, useEffect } from 'react'
import modalStyles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import JSONWrapper from '../../UI/JSONWrapper/JSONWrapper'
import { useAPI } from '../../../hooks/useAPI'
import { CustomGatewaysService } from '../../../API/CustomGatewaysService'
import Spinner from '../../UI/Spinner/Spinner'

interface ViewPluginManifestModalProps extends BaseModalProps {
	pluginId: number | null
	pluginVersionId: number | null
}

const ViewPluginManifestModal: FC<ViewPluginManifestModalProps> = ({
	pluginId,
	pluginVersionId,
	onClose,
}) => {
	const [isGetManifestLoading, manifest, getManifest] = useAPI(
		pluginId => CustomGatewaysService.getVersionManifest(pluginId, Number(pluginVersionId)),
		false
	)

	useEffect(() => {
		if (pluginId && pluginVersionId) {
			getManifest(pluginId)
		}
	}, [])

	if (isGetManifestLoading) return <Spinner />

	return (
		<div className={modalStyles.modalInner}>
			<header className={modalStyles.header}>
				<h1>Plugin Version Manifest JSON</h1>
			</header>
			<div className={modalStyles.body}>
				<JSONWrapper json={manifest as JSON} />
			</div>
			<footer className={modalStyles.footer}>
				<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
					Cancel
				</Button>
			</footer>
		</div>
	)
}

export default ViewPluginManifestModal
