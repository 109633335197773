import React, { FC, useEffect } from 'react'
import modalStyles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { StockGroup } from '../../../types/StockGroup/StockGroup'
import Input, { InputIconAligns } from '../../UI/FormComponents/Input'
import IconField from '../../UI/IconField/IconField'
import { useAPI } from '../../../hooks/useAPI'
import { StockSystemService } from '../../../API/StockSystemService'
import { useAppContext } from '../../../hooks/useAppContext'
import { StockGroupDetailed } from '../../../types/StockGroup/StockGroupDetailed'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../UI/Spinner/Spinner'

interface DeleteWarehouseModalProps extends BaseModalProps {
	onSuccess: () => void
	deletingWarehouse: StockGroup | null
}

const DeleteWarehouseModal: FC<DeleteWarehouseModalProps> = ({
	onClose,
	deletingWarehouse,
	onSuccess,
}) => {
	const { shop } = useAppContext()
	const navigate = useNavigate()

	const [isDeleteWarehouseLoading, , deleteWarehouse] = useAPI(
		() => StockSystemService.deleteWarehouse(shop?.id || '', Number(deletingWarehouse?.id)),
		false,
		'Warehouse successfully deleted',
		{
			onSuccess: () => {
				onSuccess()
				onClose()
			},
		}
	)

	const [isGetWarehouseLoading, warehouse, getWarehouse] = useAPI<StockGroupDetailed>(
		() => StockSystemService.getWarehouse(shop?.id || '', Number(deletingWarehouse?.id)),
		false
	)

	useEffect(() => {
		if (deletingWarehouse) {
			getWarehouse()
		}
	}, [])

	if (isGetWarehouseLoading)
		return (
			<div className={modalStyles.modalInner}>
				<Spinner />
			</div>
		)

	return (
		<div className={modalStyles.modalInner}>
			<header className={modalStyles.header}>
				<h1>Delete Warehouse</h1>
			</header>

			<div className={modalStyles.body}>
				{warehouse?.usedInProducts.length ? (
					<p className={modalStyles.description}>
						This warehouse is connected to the following product
						{(warehouse?.usedInProducts.length || 0) > 1 && 's'} below. Are you sure you want to
						delete this warehouse?
					</p>
				) : (
					<p className={modalStyles.description}>
						You're about to delete <b>{warehouse?.name}</b> warehouse. Are you sure you want to
						delete this warehouse?
					</p>
				)}

				{warehouse?.usedInProducts.map(product => (
					<div className={modalStyles.fieldWrapper} key={product.id}>
						<Input
							value={product.name}
							setValue={() => {}}
							icon={{
								id: 'blankWindow',
								height: 13,
								width: 13,
								align: InputIconAligns.RIGHT,
								onClick: () => navigate(`/products/edit/${product.id}`),
								className: modalStyles.primaryColor,
							}}
						/>
					</div>
				))}
			</div>

			<footer className={modalStyles.footer}>
				<div className={modalStyles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'trashBin',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={deleteWarehouse}
						isLoading={isDeleteWarehouseLoading}
					>
						Delete Warehouse
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default DeleteWarehouseModal
