import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Input from '../../UI/FormComponents/Input'
import Textarea from '../../UI/FormComponents/Textarea'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import Checkbox from '../../UI/Checkbox/Checkbox'
import CustomMultiSelect from '../../UI/CustomMultiSelect/CustomMultiSelect'

interface CreateCustomerModalProps extends BaseModalProps {}

const CreateCustomerModal: FC<CreateCustomerModalProps> = ({ onClose }) => {
	const [firstName, setFirstName] = useState('')
	const [accessToSpecificProducts, setAccessToSpecificProducts] = useState(false)

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Create New Customer</h1>
			</header>

			<div className={styles.body}>
				<div className={styles.twoColumns}>
					<div className={styles.fieldWrapper}>
						<h2>First Name</h2>
						<Input value={firstName} setValue={setFirstName} placeholder={'John'} />
					</div>
					<div className={styles.fieldWrapper}>
						<h2>Last Name</h2>
						<Input value={firstName} setValue={setFirstName} placeholder={'Doe'} />
					</div>
				</div>

				<div className={styles.fieldWrapper}>
					<h2>Email Address</h2>
					<Input value={firstName} setValue={setFirstName} placeholder={'john@gmail.com'} />
				</div>
				{/*<div className={styles.fieldWrapper}>*/}
				{/*	<h2>Password</h2>*/}
				{/*	<Input value={firstName} setValue={setFirstName} placeholder={'j#Oh@n!'} />*/}
				{/*</div>*/}
				<label className={styles.checkboxWrapper}>
					<Checkbox
						id={'specificProduct'}
						checked={accessToSpecificProducts}
						setChecked={setAccessToSpecificProducts}
					/>
					<p>Automatically grant access to specific products</p>
				</label>
				{accessToSpecificProducts && (
					<div className={styles.fieldWrapper}>
						<CustomMultiSelect
							value={[]}
							setValue={() => {}}
							options={[{ value: 'Student Membership', label: 'Student Membership' }]}
						/>
					</div>
				)}
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} onClick={onClose}>
						Close
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'plus',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
					>
						Create Customer
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default CreateCustomerModal
