import React, { FC, useRef, useState } from 'react'
import styles from './Tables.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { NavLink, useNavigate } from 'react-router-dom'
import Checkbox from '../UI/Checkbox/Checkbox'
import ProductTypeBadge from '../UI/ProductTypeBadge/ProductTypeBadge'
import { Group } from '../../types/Group/Group'
import { format } from 'date-fns'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import { ActionMenuOption } from '../../types/ActionMenuOption/ActionMenuOption'

interface GroupsTableProps {
	groups: Group[]
	onDeleteClick: (id: number) => void
}

interface GroupsTableBodyItemProps {
	group: Group
	onDeleteClick: (id: number) => void
}

const GroupsTableBodyItem: FC<GroupsTableBodyItemProps> = ({ group, onDeleteClick }) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)
	const navigate = useNavigate()

	const actionMenuButtons: ActionMenuOption[] = []

	actionMenuButtons.push({
		onClick: () => navigate(`/products/groups/edit/${group.id}`),
		icon: {
			id: 'pen',
			width: 12,
			height: 12,
		},
		title: 'Edit group',
	})

	actionMenuButtons.push({
		onClick: () => onDeleteClick(group.id),
		icon: {
			id: 'trashBin',
			width: 12,
			height: 12,
		},
		title: 'Delete group',
	})

	return (
		<NavLink
			className={styles.trWrapper}
			to={`/products/groups/edit/${group.id}`}
			style={{
				paddingBottom: isActionMenuActive ? +actionMenuButtons.length * 50 : 5,
			}}
		>
			<tr>
				<td className={styles.large}>
					<div className={styles.itemInfo}>
						<div className={styles.itemImage}>
							{group.image?.cfId && (
								<img
									src={`https://imagedelivery.net/${group.image?.cfId}/productCard`}
									alt={group.name}
								/>
							)}
						</div>
						<div>
							<p>{group.name}</p>
							<span>ID: {group.id}</span>
						</div>
					</div>
				</td>
				<td className={styles.extraLarge}>{group.listingsCount}</td>
				<td>
					<p>{format(new Date(group.createdAt), 'MMM dd, yyyy')}</p>
					<span>{format(new Date(group.createdAt), 'hh:mm aa z')}</span>
				</td>
				<td className={styles.small}>
					<ActionMenu
						actionButtons={actionMenuButtons}
						isActive={isActionMenuActive}
						setIsActive={setIsActionMenuActive}
					/>
				</td>
			</tr>
		</NavLink>
	)
}

const GroupsTable: FC<GroupsTableProps> = ({ groups, onDeleteClick }) => {
	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.large}>Group</th>
					<th className={styles.extraLarge}>Products</th>
					<th>Date Created</th>
					<th className={styles.small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{groups.map(group => (
					<GroupsTableBodyItem group={group} onDeleteClick={onDeleteClick} key={group.id} />
				))}
			</tbody>
		</table>
	)
}

export default GroupsTable
