import React, { useRef } from 'react'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import styles from './AuthPages.module.scss'
import { Route, Routes, useNavigate } from 'react-router-dom'
import SignInPage from './pages/SignInPage'
import SignUpPage from './pages/SignUpPage'
import EmailVerificationPage from './pages/EmailVerificationPage'
import SetupStorePage from './pages/SetupStorePage'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import ReCAPTCHA from 'react-google-recaptcha'
import ResetPasswordPage from './pages/ResetPasswordPage'
import CompleteResetPasswordPage from './pages/CompleteResetPasswordPage'
import AuthDiscord from './pages/AuthDiscord'
import AuthGoogle from './pages/AuthGoogle'
import StatusAlertBadge from '../../components/UI/StatusAlertBadge/StatusAlertBadge'
import { StatusAlert } from '../../types/StatusAlert/StatusAlert'
import { ButtonsIconAlign } from '../../components/UI/Button/Button'

const AuthPages = () => {
	const recaptchaRef = useRef(null)

	console.log('Auth page is rendered');

	return (
			<PageContainer>
				<ReCAPTCHA
					ref={recaptchaRef}
					sitekey={'6LdQiIIpAAAAAAkhvhS9zuo5WNS5mAzZrALD9cCa'}
					size={'invisible'}
				/>
				<div className={styles.authPages}>
					<Routes>
						<Route path={'sign-in'} element={<SignInPage recaptchaRef={recaptchaRef} />} />
						<Route path={'sign-up'} element={<SignUpPage recaptchaRef={recaptchaRef} />} />
						<Route
							path={'reset-password'}
							element={<ResetPasswordPage recaptchaRef={recaptchaRef} />}
						/>
						<Route path={'reset-password-complete'} element={<CompleteResetPasswordPage />} />
						<Route path={'verify-email'} element={<EmailVerificationPage />} />
						<Route path={'setup-store'} element={<SetupStorePage />} />
						<Route path={'discord/callback'} element={<AuthDiscord />} />
						<Route path={'google/callback'} element={<AuthGoogle />} />
					</Routes>
				</div>
			</PageContainer>
	)
}

export default AuthPages
