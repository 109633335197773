import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { useAPI } from '../../../hooks/useAPI'
import { DetailedOrder } from '../../../types/DetailedOrder/DetailedOrder'
import { OrdersService } from '../../../API/OrdersService'
import { ChargesService } from '../../../API/ChargesService'
import CheckboxWrapper from '../../UI/CheckboxWrapper/CheckboxWrapper'

interface ProcessOrderModalProps extends BaseModalProps {
	chargeId: string | undefined
	shopId: string
	getOrder: () => void
}

const ProcessOrderModal: FC<ProcessOrderModalProps> = ({ onClose, chargeId, shopId, getOrder }) => {
	const [showInDashboardStats, setShowInDashboardStats] = useState(true)

	const [isProcessOrderLoading, , processOrder] = useAPI<DetailedOrder>(
		() =>
			ChargesService.markAsPaid(shopId, chargeId || '', {
				showInDashboardStats,
			}),
		false,
		'Order successfully processed',
		{
			onSuccess: () => {
				onClose()
				getOrder()
			},
		}
	)

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Process Order</h1>
			</header>

			<div className={styles.body}>
				<p id={styles.description}>
					You are about to <u>process this order manually.</u> Once you confirm below, we’ll mark
					this order paid and deliver the product to the customer.
				</p>

				<div className={styles.mtMediumLarge}>
					<CheckboxWrapper
						id={'showInDashboardStats'}
						checked={showInDashboardStats}
						setChecked={setShowInDashboardStats}
						title={'Include this manually processed order with your storefront analytics.'}
					/>
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Close
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'truck',
							width: 14,
							height: 10,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={processOrder}
						isLoading={isProcessOrderLoading}
					>
						Process Order
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default ProcessOrderModal
