import React, { useEffect, useState } from 'react'
import styles from '../AuthPages.module.scss'
import { SpriteIcon } from '../../../components/UI/SpriteIcon/SpriteIcon'
import Input from '../../../components/UI/FormComponents/Input'
import Button, { ButtonStyles } from '../../../components/UI/Button/Button'
import { useAPI } from '../../../hooks/useAPI'
import { AuthService } from '../../../API/AuthService'
import { usePageTitle } from '../../../hooks/usePageTitle'
import { useLocation } from 'react-router-dom'

const ResetPasswordPage = () => {
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [errors, setErrors] = useState({
		password: '',
		confirmPassword: '',
	})
	const location = useLocation()
	const params = new URLSearchParams(location.search)
	const token = params.get('token')
	usePageTitle('Complete Resetting Password')

	const resetValidator = () => {
		setErrors({
			...errors,
			password: !password
				? 'Required'
				: password.length < 8
				? 'The length of the password must exceed 8 characters.'
				: '',
			confirmPassword: !confirmPassword
				? 'Required'
				: password !== confirmPassword
				? 'Passwords did not match'
				: '',
		})

		return !!password && password === confirmPassword
	}

	const [isResetBasicCompleteLoading, , resetBasicComplete] = useAPI(
		() =>
			AuthService.resetBasicComplete({
				newPassword: password,
				token: token || '',
			}),
		false,
		'New password successfully saved',
		{
			validator: resetValidator,
		}
	)

	return (
		<div className={styles.authInner}>
			<div className={styles.logo}>
				<SpriteIcon iconId={'nameLogo'} width={124} height={27} />
			</div>
			<h1 className={styles.title}>Enter a secure new password</h1>

			<div className={styles.fieldWrapper}>
				<h2 className={styles.fieldTitle}>New Password</h2>
				<Input
					value={password}
					setValue={setPassword}
					placeholder={'*****************'}
					type={'password'}
					errorMessage={errors.password}
				/>
			</div>

			<div className={styles.fieldWrapper}>
				<h2 className={styles.fieldTitle}>Confirm New Password</h2>
				<Input
					value={confirmPassword}
					setValue={setConfirmPassword}
					placeholder={'*****************'}
					type={'password'}
					errorMessage={errors.confirmPassword}
				/>
			</div>

			<div className={styles.button}>
				<Button
					style={ButtonStyles.PRIMARY}
					onClick={resetBasicComplete}
					isLoading={isResetBasicCompleteLoading}
				>
					Save New Password
				</Button>
			</div>
		</div>
	)
}

export default ResetPasswordPage
