import React, { FC, ReactNode, useEffect } from 'react'
import styles from './PageHeader.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { useNavigate } from 'react-router-dom'

interface PageHeaderProps {
	title: string
	children?: ReactNode
	backButton?: {
		onClick?: () => void
		isActive: boolean
	}
	isRow?: boolean
}

const PageHeader: FC<PageHeaderProps> = ({ title, children, backButton, isRow }) => {
	const navigate = useNavigate()

	return (
		<div className={`${styles.pageHeader} ${isRow && styles.row}`}>
			<div className={styles.headerHead}>
				{backButton?.isActive && (
					<div className={styles.backButton} onClick={() => navigate(-1)}>
						<SpriteIcon iconId={'arrow'} width={14} height={12} />
					</div>
				)}

				<h1>{title}</h1>
			</div>
			<div className={styles.headerEnd}>{children}</div>
		</div>
	)
}

export default PageHeader
