import React, { FC } from 'react'
import styles from './ProductAdvisor.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { scrollIntoViewAndFocus } from '../../helpers/scrollIntoViewAndFocus'
import { CreateProductSectionIds } from '../../types/CreateProductSectionIds/CreateProductSectionIds'
import { ListingVisibility } from '../../types/ListingVisibility/ListingVisibility'

interface ProductAdvisorGeneralProps {
	titleValue: string
	shortDescription: string
	description: string | undefined
	percent: number
	setCurrenSectionId: (value: CreateProductSectionIds) => void
}

const ProductAdvisorGeneral: FC<ProductAdvisorGeneralProps> = ({
	titleValue,
	shortDescription,
	description,
	percent,
	setCurrenSectionId,
}) => {
	const onNextClickHandler = () => {
		setCurrenSectionId(CreateProductSectionIds.DESIGN)
		scrollIntoViewAndFocus(CreateProductSectionIds.DESIGN)
	}

	return (
		<div className={styles.productAdvisor}>
			<header className={styles.header}>
				<h2 className={styles.title}>Product Advisor</h2>
				<p className={styles.progressPercent}>{percent}%</p>

				<div className={styles.progressBar}>
					<div
						className={styles.progressBarActiveLine}
						style={{
							width: `${percent}%`,
						}}
					/>
					<div
						className={styles.progressBarWillActiveLine}
						style={{
							width: `${percent + (!description ? 10 : 0) + (!shortDescription ? 10 : 0)}%`,
						}}
					/>
				</div>

				<p className={styles.description}>
					A detailed product title and description can potentially increase conversion rates.
				</p>
			</header>

			<div className={styles.body}>
				<h2 className={styles.title}>Getting Started</h2>

				<div className={styles.advices}>
					<div
						className={`${styles.adviceItem} ${titleValue ? styles.done : styles.alert}`}
						onClick={() => scrollIntoViewAndFocus('productTitle')}
					>
						<div>
							<h3 className={styles.adviceTitle}>Title</h3>
							<p className={styles.adviceDescription}>Add a short title to your product.</p>
						</div>

						<SpriteIcon
							className={styles.asteriskSymbol}
							iconId={'asteriskSymbol'}
							width={10}
							height={10}
						/>
						<SpriteIcon
							className={styles.checked}
							iconId={'checkedCircle'}
							width={15}
							height={15}
						/>
					</div>

					<div
						className={`${styles.adviceItem} ${description && styles.done}`}
						onClick={() => scrollIntoViewAndFocus('productDescription')}
					>
						<div>
							<h3 className={styles.adviceTitle}>Description</h3>
							<p className={styles.adviceDescription}>Add a description to your product.</p>
						</div>

						<SpriteIcon
							className={styles.checked}
							iconId={'checkedCircle'}
							width={15}
							height={15}
						/>
						<div className={styles.advicePercent}>+10%</div>
					</div>
					<div
						className={`${styles.adviceItem} ${shortDescription && styles.done}`}
						onClick={() => scrollIntoViewAndFocus('productShortDescription')}
					>
						<div>
							<h3 className={styles.adviceTitle}>Short Description</h3>
							<p className={styles.adviceDescription}>Add a short description to your product.</p>
						</div>

						<div className={styles.advicePercent}>+10%</div>
						<SpriteIcon
							className={styles.checked}
							iconId={'checkedCircle'}
							width={15}
							height={15}
						/>
					</div>
				</div>

				<footer className={styles.footer}>
					<Button
						style={ButtonStyles.BORDERED}
						disableShadow={true}
						width={'100%'}
						icon={{
							id: 'arrowRight',
							height: 12,
							width: 13,
							align: ButtonsIconAlign.RIGHT,
						}}
						onClick={onNextClickHandler}
					>
						Next
					</Button>
				</footer>
			</div>
		</div>
	)
}

export default ProductAdvisorGeneral
