import React, { FC } from 'react'
import styles from './ProductGeneral.module.scss'
import generalStyles from '../../CreateProductPage.module.scss'
import Input from '../../../../components/UI/FormComponents/Input'
import Textarea from '../../../../components/UI/FormComponents/Textarea'

import CustomMDEditor from '../../../../components/UI/CustomMDEditor/CustomMDEditor'
import { FormErrors } from '../../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../../helpers/fieldValidators'
import RequiredBadge from '../../../../components/UI/RequiredBadge/RequiredBadge'

interface ProductGeneralProps {
	titleValue: string
	setTitleValue: (value: string) => void
	shortDescription: string
	setShortDescription: (value: string) => void

	description: string | undefined
	setDescription: (value: string | undefined) => void
	errors: FormErrors
	setErrors: (value: FormErrors) => void
}

const ProductGeneral: FC<ProductGeneralProps> = ({
	titleValue,
	setTitleValue,
	setDescription,
	description,
	setShortDescription,
	shortDescription,
	errors,
	setErrors,
}) => {
	return (
		<div className={styles.productGeneral}>
			<div className={generalStyles.fieldWrapper}>
				<p className={generalStyles.fieldTitle}>
					Product Title <RequiredBadge />
				</p>
				<Input
					id={'productTitle'}
					value={titleValue}
					setValue={setTitleValue}
					placeholder={'Product Title'}
					errorMessage={errors['title']}
					onBlur={event =>
						setErrors({
							...errors,
							title: fieldValidators.blankValidator('Product title', titleValue),
						})
					}
				/>
			</div>
			<div className={generalStyles.fieldWrapper}>
				<p className={generalStyles.fieldTitle}>Short Description</p>
				<Textarea
					id={'productShortDescription'}
					value={shortDescription}
					setValue={setShortDescription}
					resizable={false}
					placeholder={'Short Description'}
				/>
				{/*<button className={styles.aiButton}>*/}
				{/*	<GradientStarsSvg />*/}
				{/*	Use Sellpass AI*/}
				{/*</button>*/}
			</div>
			<div className={generalStyles.horLine} />
			<div className={generalStyles.fieldWrapper}>
				<p className={generalStyles.fieldTitle}>Product Description</p>
				<CustomMDEditor value={description} setValue={setDescription} id={'productDescription'} />
			</div>
		</div>
	)
}

export default ProductGeneral
