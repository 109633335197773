import React, { FC, useEffect, useState } from 'react'
import styles from '../Modals.module.scss'
import Input from '../../UI/FormComponents/Input'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import CustomSelect from '../../UI/CustomSelect/CustomSelect'
import DatePicker from '../../UI/DatePicker/DatePicker'
import { useAPI } from '../../../hooks/useAPI'
import { UserSettingsDeveloperService } from '../../../API/UserSettingsDeveloperService'
import { fieldValidators } from '../../../helpers/fieldValidators'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { APIKey } from '../../../types/APIKey/APIKey'

interface GenerateApiKeyModalProps extends BaseModalProps {
	getApiKeys: () => void
}

const GenerateApiKeyModal: FC<GenerateApiKeyModalProps> = ({ onClose, getApiKeys }) => {
	const [name, setName] = useState('')
	const [ticksInputValue, setTicksInputValue] = useState('7')
	const [customTicksDate, setCustomTicksDate] = useState(new Date())
	const [errors, setErrors] = useState<FormErrors>({})

	const calculateTicks = () => {
		const today = new Date()
		const pickedDate =
			ticksInputValue !== 'custom'
				? new Date(new Date().setDate(today.getDate() + +ticksInputValue))
				: customTicksDate

		return (pickedDate.getTime() - today.getTime()) * 10000
	}

	const generateApiKeyValidator = () => {
		const nameValidator = fieldValidators.blankValidator('API Key Name', name)

		setErrors({
			...errors,
			name: nameValidator,
		})

		return !nameValidator
	}

	const [isGenerateApiKeyLoading, , generateApiKey] = useAPI(
		() =>
			UserSettingsDeveloperService.createApiKey({
				name,
				expiresInTicks: ticksInputValue === 'null' ? null : calculateTicks(),
			}),
		false,
		'API Key successfully generated',
		{
			validator: generateApiKeyValidator,
			onSuccess: () => {
				onClose()
				getApiKeys()
			},
		}
	)

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Generate API Key</h1>
			</header>

			<div className={styles.body}>
				<div className={styles.fieldWrapper}>
					<h2>API Key Name</h2>
					<Input
						value={name}
						setValue={setName}
						placeholder={'Name'}
						errorMessage={errors['name']}
						onBlur={() => generateApiKeyValidator()}
					/>
				</div>

				<div className={styles.fieldWrapper}>
					<h2>Expiration Date</h2>
					<div className={styles.doubleFields}>
						<CustomSelect
							value={ticksInputValue}
							setValue={setTicksInputValue}
							options={[
								{ value: '7', label: '7 days' },
								{ value: '30', label: '30 days' },
								{ value: '60', label: '60 days' },
								{ value: '90', label: '90 days' },
								{ value: 'custom', label: 'Custom' },
								{ value: 'null', label: 'No expiration' },
							]}
						/>

						{ticksInputValue === 'custom' && (
							<DatePicker
								date={customTicksDate}
								setDate={setCustomTicksDate}
								minDate={new Date()}
							/>
						)}
					</div>
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'send',
							height: 14,
							width: 12,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={generateApiKey}
						isLoading={isGenerateApiKeyLoading}
					>
						Generate New Key
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default GenerateApiKeyModal
