import {type FC} from 'react';
import styles from './SubscriptionPlanCard.module.scss';

interface ChangePlanButtonProps {
    onChangePlan: () => void;
}

const ChangePlanButton: FC<ChangePlanButtonProps> = ({ onChangePlan }) => {
    return (
        <button className={styles.changePlanButton} onClick={onChangePlan}>
            Change plan
        </button>
    );
};

export default ChangePlanButton;