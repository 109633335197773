import React, { FC } from 'react'
import styles from './StatusBadges.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import { WarehouseStatus } from '../../../types/WarehouseStatus/WarehouseStatus'
import { OrderStatus } from '../../../types/OrderStatus/OrderStatus'
import { OrderChargeStatus } from '../../../types/OrderChargeStatus/OrderChargeStatus'
import { PartOrderDeliveryStatus } from '../../../types/PartOrderDeliveryStatus/PartOrderDeliveryStatus'
import { WebhookMessageEventStatus } from '../../../types/WebhookMessageEventStatus/WebhookMessageEventStatus'

interface WebhookMessageEventStatusBadgeProps {
	webhookMessageEventStatus: WebhookMessageEventStatus
}

const WebhookMessageEventStatusBadge: FC<WebhookMessageEventStatusBadgeProps> = ({
	webhookMessageEventStatus,
}) => {
	const webhookMessageEventStatusData = {
		[WebhookMessageEventStatus.PENDING]: {
			name: 'Pending',
			className: 'brown',
			iconId: 'exclamationMarkCircle',
		},
		[WebhookMessageEventStatus.SUCCESS]: {
			name: 'Success',
			className: 'green',
			iconId: 'checkedCircle',
		},
		[WebhookMessageEventStatus.FAIL]: {
			name: 'Fail',
			className: 'red',
			iconId: 'xMarkCircle',
		},
		[WebhookMessageEventStatus.SENDING]: {
			name: 'Sending',
			className: 'brown',
			iconId: 'exclamationMarkCircle',
		},
	}

	return (
		<div
			className={`${styles.statusBadge} ${
				styles[webhookMessageEventStatusData[webhookMessageEventStatus].className]
			}`}
		>
			{webhookMessageEventStatusData[webhookMessageEventStatus].name}
			<SpriteIcon
				iconId={webhookMessageEventStatusData[webhookMessageEventStatus].iconId}
				width={16}
				height={16}
			/>
		</div>
	)
}

export default WebhookMessageEventStatusBadge
