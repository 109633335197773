import React, { FC, useRef, useState } from 'react'
import styles from './Tables.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { NavLink, useNavigate } from 'react-router-dom'
import StatusBadge from '../UI/StatusBadge/StatusBadge'
import PaymentTypeBadge from '../PaymentTypeBadge/PaymentTypeBadge'
import RatingBadge from '../UI/RatingBadge/RatingBadge'
import { Review } from '../../types/Review/Review'
import { format } from 'date-fns'
import { ActionMenuOption } from '../../types/ActionMenuOption/ActionMenuOption'
import ActionMenu from '../UI/ActionMenu/ActionMenu'

interface ReviewsTableProps {
	reviews: Review[]
	onReplyReviewClick: (id: number) => void
	onAppealReviewClick: (id: number) => void
	isReviewsModifyAvailable: boolean
}

interface ReviewsTableBodyItemProps {
	review: Review
	onReplyReviewClick: (id: number) => void
	onAppealReviewClick: (id: number) => void
	isReviewsModifyAvailable: boolean
}

const ReviewsTableBodyItem: FC<ReviewsTableBodyItemProps> = ({
	review,
	onAppealReviewClick,
	onReplyReviewClick,
	isReviewsModifyAvailable,
}) => {
	const tableBodyRef = useRef<HTMLAnchorElement>(null)
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)

	const actionButtons: ActionMenuOption[] = []

	isReviewsModifyAvailable &&
		actionButtons.push(
			...[
				{
					title: 'Reply to review',
					icon: {
						id: 'pen',
						height: 12,
						width: 12,
					},
					onClick: () => onReplyReviewClick(review.id),
				},
				{
					title: 'Appeal review',
					icon: {
						id: 'scales',
						height: 12,
						width: 12,
					},
					onClick: () => onAppealReviewClick(review.id),
				},
			]
		)

	return (
		<NavLink
			className={styles.trWrapper}
			to={`/operations/reviews/${review.id}`}
			style={{
				paddingBottom: isActionMenuActive ? +actionButtons.length * 50 : 5,
			}}
			ref={tableBodyRef}
		>
			<tr>
				<td>
					<div className={styles.itemInfo}>
						<div>
							<p>{review.customerEmail}</p>
							<span>ID: {review.id}</span>
						</div>
					</div>
				</td>
				<td className={styles.medium}>
					<RatingBadge rating={review.rating} />
				</td>
				<td className={styles.large}>{review.comment}</td>
				<td>
					<p>{format(new Date(review.updatedAt), 'MMM dd, yyyy')}</p>
					<span>{format(new Date(review.updatedAt), 'hh:mm aa z')}</span>
				</td>
				<td className={styles.small}>
					<ActionMenu
						actionButtons={actionButtons}
						isActive={isActionMenuActive}
						setIsActive={setIsActionMenuActive}
					/>
				</td>
			</tr>
		</NavLink>
	)
}

const ReviewsTable: FC<ReviewsTableProps> = ({
	reviews,
	onReplyReviewClick,
	onAppealReviewClick,
	isReviewsModifyAvailable,
}) => {
	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th>Review Info</th>
					<th className={styles.medium}>Rating</th>
					<th className={styles.large}>Message</th>
					<th>Date & Time</th>
					<th className={styles.small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{reviews.map(review => (
					<ReviewsTableBodyItem
						isReviewsModifyAvailable={isReviewsModifyAvailable}
						review={review}
						onAppealReviewClick={onAppealReviewClick}
						onReplyReviewClick={onReplyReviewClick}
						key={review.id}
					/>
				))}
			</tbody>
		</table>
	)
}

export default ReviewsTable
