import React, { FC, useState } from 'react'
import Modal from '../../../../components/Modals/Modal'
import { closeModal } from '../../../../helpers/closeModal'
import CreateProductVariantModal from '../../../../components/Modals/CreateProductVariantModal/CreateProductVariantModal'
import ConfirmModal from '../../../../components/Modals/ConfirmModal/ConfirmModal'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../../components/UI/Button/Button'
import { VariantsGeneral } from '../../../../types/CreateVariants/VariantsGeneral'
import { VariantsPricing } from '../../../../types/CreateVariants/VariantsPricing'
import { VariantsDelivery } from '../../../../types/CreateVariants/VariantsDelivery'
import { VariantsCustomFields } from '../../../../types/CreateVariants/VariantsCustomFields'
import { VariantsDiscordSettings } from '../../../../types/CreateVariants/VariantsDiscordSettings'
import { VariantsAdvanced } from '../../../../types/CreateVariants/VariantsAdvanced'
import { ProductVariantsTab } from '../../../../types/ProductVariantsTab/ProductVariantsTab'
import styles from './ProductVariants.module.scss'
import { CreateProductSectionIds } from '../../../../types/CreateProductSectionIds/CreateProductSectionIds'
import ProductVariants from './ProductVariants'
import { useAutoAnimate } from '@formkit/auto-animate/react'

interface ProductVariantsWrapperProps {
	setVariantsGeneral: (value: VariantsGeneral) => void
	variantsGeneral: VariantsGeneral
	variantsPricing: VariantsPricing
	setVariantsPricing: (value: VariantsPricing) => void
	setCurrentVariantIndex: (value: string) => void
	variantsDelivery: VariantsDelivery
	setVariantsDelivery: (value: VariantsDelivery) => void
	variantsCustomFields: VariantsCustomFields
	setVariantsCustomFields: (value: VariantsCustomFields) => void
	setVariantsDiscordSettings: (value: VariantsDiscordSettings) => void
	variantsDiscordSettings: VariantsDiscordSettings
	setVariantsAdvanced: (value: VariantsAdvanced) => void
	variantsAdvanced: VariantsAdvanced
	currentVariantsTab: { [variantIndex: string]: ProductVariantsTab }

	setCurrentVariantsTab: (value: { [variantIndex: string]: ProductVariantsTab }) => void
}

const ProductVariantsWrapper: FC<ProductVariantsWrapperProps> = ({
	variantsPricing,
	setVariantsPricing,
	setCurrentVariantIndex,
	variantsDelivery,
	setVariantsDelivery,
	variantsGeneral,
	setVariantsGeneral,
	setVariantsCustomFields,
	variantsCustomFields,
	variantsDiscordSettings,
	setVariantsDiscordSettings,
	variantsAdvanced,
	setVariantsAdvanced,
	setCurrentVariantsTab,
	currentVariantsTab,
}) => {
	const [isCreateVariantModalActive, setIsCreateVariantModalActive] = useState(false)
	const [isCreateVariantModalVisible, setIsCreateVariantModalVisible] = useState(false)
	const [isDeleteVariantModalActive, setIsDeleteVariantModalActive] = useState(false)
	const [isDeleteVariantModalVisible, setIsDeleteVariantModalVisible] = useState(false)
	const [usingVariantId, setUsingVariantId] = useState<string | null>(null)
	const [animationParent] = useAutoAnimate()

	const closeDeleteModal = () => {
		closeModal(setIsDeleteVariantModalActive, setIsDeleteVariantModalVisible)
	}

	const onDeleteVariantHandler = () => {
		const tempVariantsGeneral = { ...variantsGeneral }
		const deletedPosition = tempVariantsGeneral[usingVariantId || ''].position
		delete tempVariantsGeneral[usingVariantId || '']

		Object.values(tempVariantsGeneral).forEach(obj => {
			if (obj.position > deletedPosition) {
				obj.position -= 1
			}
		})

		setVariantsGeneral(tempVariantsGeneral)
		setUsingVariantId(null)
		closeDeleteModal()
	}

	const moveUp = (currentItem: { id: string; position: number }) => {
		if (currentItem.position === 0) return
		const variantIds = Object.keys(variantsGeneral)
		const nextItemId = variantIds.find(
			id => variantsGeneral[id].position === currentItem.position - 1
		)
		if (nextItemId) {
			setVariantsGeneral({
				...variantsGeneral,
				[nextItemId]: {
					...variantsGeneral[nextItemId],
					position: currentItem.position,
				},
				[currentItem.id]: {
					...variantsGeneral[currentItem.id],
					position: currentItem.position - 1,
				},
			})
		}
	}

	const moveDown = (currentItem: { id: string; position: number }) => {
		const variantIds = Object.keys(variantsGeneral)

		if (currentItem.position === variantIds.length - 1) return

		const prevItemId = variantIds.find(
			id => variantsGeneral[id].position === currentItem.position + 1
		)

		if (prevItemId) {
			setVariantsGeneral({
				...variantsGeneral,
				[prevItemId]: {
					...variantsGeneral[prevItemId],
					position: currentItem.position,
				},
				[currentItem.id]: {
					...variantsGeneral[currentItem.id],
					position: currentItem.position + 1,
				},
			})
		}
	}

	return (
		<div>
			{isCreateVariantModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsCreateVariantModalActive, setIsCreateVariantModalVisible)
					}
					visible={isCreateVariantModalVisible}
					setVisible={setIsCreateVariantModalVisible}
				>
					<CreateProductVariantModal
						variantsDelivery={variantsDelivery}
						setVariantsDelivery={setVariantsDelivery}
						setVariantsPricing={setVariantsPricing}
						variantsPricing={variantsPricing}
						setVariantsGeneral={setVariantsGeneral}
						variantsGeneral={variantsGeneral}
						variantsDiscordSettings={variantsDiscordSettings}
						setVariantsDiscordSettings={setVariantsDiscordSettings}
						setVariantsAdvanced={setVariantsAdvanced}
						variantsAdvanced={variantsAdvanced}
						variantsCustomFields={variantsCustomFields}
						setVariantsCustomFields={setVariantsCustomFields}
						onClose={() =>
							closeModal(setIsCreateVariantModalActive, setIsCreateVariantModalVisible)
						}
						currentVariantsTab={currentVariantsTab}
						setCurrentVariantsTab={setCurrentVariantsTab}
					/>
				</Modal>
			)}
			{isDeleteVariantModalActive && (
				<Modal
					closeModal={closeDeleteModal}
					visible={isDeleteVariantModalVisible}
					setVisible={setIsDeleteVariantModalVisible}
				>
					<ConfirmModal
						title={'Delete Variant'}
						description={`You are about to delete ${
							usingVariantId && variantsGeneral[usingVariantId].title
						}. Are you sure you want to delete this variant? This cannot be undone.`}
						onConfirm={onDeleteVariantHandler}
						onClose={closeDeleteModal}
						buttonIcon={{
							id: 'trashBin',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						confirmButtonText={'Delete Variant'}
					/>
				</Modal>
			)}

			<div className={styles.variants} ref={animationParent}>
				{Object.keys(variantsGeneral)
					.sort((a, b) => variantsGeneral[a].position - variantsGeneral[b].position)
					.map(variantKey => (
						<section
							className={styles.variant}
							id={CreateProductSectionIds.VARIANTS}
							key={variantKey}
						>
							<ProductVariants
								variantsGeneral={variantsGeneral}
								setVariantsGeneral={setVariantsGeneral}
								variantsPricing={variantsPricing}
								setVariantsPricing={setVariantsPricing}
								currentVariantId={variantKey}
								setCurrentVariantIndex={setCurrentVariantIndex}
								variantsDelivery={variantsDelivery}
								setVariantsDelivery={setVariantsDelivery}
								setVariantsCustomFields={setVariantsCustomFields}
								variantsCustomFields={variantsCustomFields}
								variantsDiscordSettings={variantsDiscordSettings}
								setVariantsDiscordSettings={setVariantsDiscordSettings}
								setVariantsAdvanced={setVariantsAdvanced}
								variantsAdvanced={variantsAdvanced}
								setCurrentVariantsTab={setCurrentVariantsTab}
								currentVariantsTab={currentVariantsTab}
								onDeleteClick={() => {
									setUsingVariantId(variantKey)
									setIsDeleteVariantModalActive(true)
								}}
								onEditClick={() => {
									setUsingVariantId(variantKey)
									setIsCreateVariantModalActive(true)
								}}
								onMoveUpClick={() =>
									moveUp({ id: variantKey, position: variantsGeneral[variantKey].position })
								}
								onMoveDownClick={() =>
									moveDown({ id: variantKey, position: variantsGeneral[variantKey].position })
								}
							/>
						</section>
					))}
			</div>

			<div className={styles.createButton}>
				<Button
					style={ButtonStyles.SECONDARY}
					width={'100%'}
					icon={{
						id: 'plus',
						height: 13,
						width: 13,
						align: ButtonsIconAlign.LEFT,
					}}
					onClick={() => setIsCreateVariantModalActive(true)}
				>
					Add Variant
				</Button>
			</div>
		</div>
	)
}

export default ProductVariantsWrapper
