export enum FraudRuleFactor {
	EMAIL = 'EMAIL',
	IP = 'IP',
	COUNTRY = 'COUNTRY',
	FRAUD_SCORE = 'FRAUD_SCORE',
	ISP = 'ISP',
	CITY = 'CITY',
	EMAIL_DOMAIN = 'EMAIL_DOMAIN',
	DISCORD_ID = 'DISCORD_ID',
}
