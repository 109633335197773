import { privateAPI } from './API'

export const WebhooksService = {
	getAllLogs: async (shopId: string, iterator?: string | null, limit?: number) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/webhooks/logs`, {
			params: {
				iterator,
				limit,
			},
		})
	},

	getOneLog: async (
		shopId: string,
		externalEndpointId: string | undefined,
		externalMessageId: string | undefined
	) => {
		return await privateAPI.get(
			`/v1/dash/shops/${shopId}/external/webhooks/${externalMessageId}/${externalEndpointId}`
		)
	},
}
