import React, { FC, useEffect, useState } from 'react'
import StatusAlertBadge from '../UI/StatusAlertBadge/StatusAlertBadge'
import { StatusAlert } from '../../types/StatusAlert/StatusAlert'
import SerialsDeliveryTable from '../Tables/SerialsDeliveryTable'
import Pagination from '../UI/Pagination/Pagination'
import styles from './PreviouslySoldSerials.module.scss'
import { GetDeliverySerial } from '../../types/GetDeliverySerial/GetDeliverySerial'

interface PreviouslySoldSerialsProps {
	previouslySoldSerials: GetDeliverySerial[]
	isRemoved: boolean
}

const PreviouslySoldSerials: FC<PreviouslySoldSerialsProps> = ({
	previouslySoldSerials,
	isRemoved,
}) => {
	const [currentPage, setCurrentPage] = useState(0)
	const [serials, setSerials] = useState<GetDeliverySerial[]>([])

	useEffect(() => {
		setSerials(
			previouslySoldSerials.filter(
				(listing, index) => index + 1 > 3 * currentPage && index < 3 * (currentPage + 1)
			) || []
		)
	}, [currentPage])

	return (
		<div>
			{isRemoved ? (
				<StatusAlertBadge
					title={'Previously Sold Serials Removed'}
					description={`We’ve identified and removed ${previouslySoldSerials.length} serials that have previously been sold within this warehouse. Feel free to view the removed serials below.`}
					statusAlertType={StatusAlert.WARNING}
				/>
			) : (
				<StatusAlertBadge
					title={'Previously Sold Serials Identified'}
					description={`We’ve identified ${previouslySoldSerials.length} serials that have previously been sold within this warehouse. To prevent any overlap with existing stock, you can view them below.`}
					statusAlertType={StatusAlert.WARNING}
				/>
			)}

			<div className={styles.mtMediumLarge}>
				<SerialsDeliveryTable serials={serials} />
			</div>
			<div className={styles.mtMediumLarge}>
				<Pagination
					withoutPageSizeSelector={true}
					totalItems={previouslySoldSerials.length}
					pageSize={3}
					setCurrentPage={setCurrentPage}
					currentPage={currentPage}
				/>
			</div>
		</div>
	)
}

export default PreviouslySoldSerials
