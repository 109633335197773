import React, { FC, useEffect, useState } from 'react'
import Select, { MultiValue } from 'react-select'
import styles from './CustomMultiSelect.module.scss'
import { CustomSelectOption } from '../../../types/CustomSelectOption/CustomSelectOption'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

interface CustomMultiSelectProps {
	options: CustomSelectOption[]
	value: string[]
	setValue: (newValue: string[]) => void
}

const CustomMultiSelect: FC<CustomMultiSelectProps> = ({ setValue, options, value }) => {
	const [localValue, setLocalValue] = useState<MultiValue<CustomSelectOption>>()
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	const handleOnChange = (newValue: MultiValue<CustomSelectOption>) => {
		setValue(newValue.map(newValueItem => newValueItem.value))
		setLocalValue(newValue)
	}

	useEffect(() => {
		setLocalValue(
			value.map(valueItem => {
				return {
					value: valueItem,
					label: options.find(option => option.value === valueItem)?.label || '',
				}
			})
		)
	}, [value, options])

	return (
		<Select
			className={styles.multiSelect}
			classNames={{
				control: ({ isFocused }) => (isFocused ? styles.controlFocused : styles.control),

				menuList: () => styles.menuList,
				menu: () => styles.menu,
				option: ({ isSelected }) => (isSelected ? styles.optionSelected : styles.option),
				singleValue: () => styles.singleValue,
				valueContainer: () => styles.valueContainer,
				indicatorsContainer: () => styles.indicatorsContainer,
			}}
			blurInputOnSelect={true}
			captureMenuScroll={false}
			options={options}
			components={{
				DropdownIndicator: ({ isFocused }) => {
					return (
						<SpriteIcon
							className={`${styles.arrowHead} ${isFocused && styles.active}`}
							iconId={'arrowDown'}
							width={12}
							height={6}
						/>
					)
				},
				ClearIndicator: ({ isFocused, clearValue }) => {
					return (
						<SpriteIcon
							onClick={clearValue}
							className={`${styles.close} ${isFocused && styles.active}`}
							iconId={'close'}
							width={11}
							height={11}
						/>
					)
				},
			}}
			isSearchable={false}
			isMulti={true}
			onChange={handleOnChange}
			value={localValue}
		/>
	)
}

export default CustomMultiSelect
