import React, { FC, useState } from 'react'
import styles from './ProductVariantsCheckout.module.scss'
import generalStyles from '../../CreateProductPage.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../../components/UI/Button/Button'
import Switch from '../../../../components/UI/Switch/Switch'
import ProductCustomField from '../ProductCustomField/ProductCustomField'
import DiscordSettings from '../../../../components/DiscordSettings/DiscordSettings'
import { VariantsCustomFields } from '../../../../types/CreateVariants/VariantsCustomFields'
import Modal from '../../../../components/Modals/Modal'
import CreateProductCustomFieldModal from '../../../../components/Modals/CreateProductCustomFieldModal/CreateProductCustomFieldModal'
import { closeModal } from '../../../../helpers/closeModal'
import { VariantsDiscordSettings } from '../../../../types/CreateVariants/VariantsDiscordSettings'
import ConnectDiscord from '../../../../components/ConnectDiscord/ConnectDiscord'
import { CustomField } from '../../../../types/CustomField/CustomField'

interface ProductVariantsCheckoutProps {
	variantsCustomFields: VariantsCustomFields
	setVariantsCustomFields: (value: VariantsCustomFields) => void
	currentVariantIndex: string
	setVariantsDiscordSettings: (value: VariantsDiscordSettings) => void
	variantsDiscordSettings: VariantsDiscordSettings
}

const ProductVariantsCheckout: FC<ProductVariantsCheckoutProps> = ({
	variantsCustomFields,
	setVariantsCustomFields,
	currentVariantIndex,
	variantsDiscordSettings,
	setVariantsDiscordSettings,
}) => {
	const [isCreateCustomFieldModalActive, setIsCreateCustomFieldModalActive] = useState(false)
	const [isCreateCustomFieldModalVisible, setIsCreateCustomFieldModalVisible] = useState(false)
	const [editingCustomFieldName, setEditingCustomFieldName] = useState<string | null>(null)

	const onCloseCreateCustomFieldModalHandler = () => {
		closeModal(setIsCreateCustomFieldModalActive, setIsCreateCustomFieldModalVisible)
		setEditingCustomFieldName(null)
	}

	return (
		<>
			{isCreateCustomFieldModalActive && (
				<Modal
					closeModal={onCloseCreateCustomFieldModalHandler}
					visible={isCreateCustomFieldModalVisible}
					setVisible={setIsCreateCustomFieldModalVisible}
				>
					<CreateProductCustomFieldModal
						currentVariantIndex={currentVariantIndex}
						variantsCustomFields={variantsCustomFields}
						setVariantsCustomFields={setVariantsCustomFields}
						editingCustomFieldName={editingCustomFieldName}
						onClose={onCloseCreateCustomFieldModalHandler}
					/>
				</Modal>
			)}

			<div className={styles.productVariantsCheckout}>
				<div className={generalStyles.fieldWrapper}>
					<header>
						<div>
							<p className={generalStyles.fieldTitle}>Custom Fields</p>
							<p className={generalStyles.fieldDescription}>
								Collect custom text response(s) prior to checkout.
							</p>
						</div>

						<Switch
							checked={variantsCustomFields[currentVariantIndex].enableCustomFields}
							setChecked={checked =>
								setVariantsCustomFields({
									...variantsCustomFields,
									[currentVariantIndex]: {
										...variantsCustomFields[currentVariantIndex],
										enableCustomFields: checked,
									},
								})
							}
						/>
					</header>
					{variantsCustomFields[currentVariantIndex].enableCustomFields && (
						<>
							{variantsCustomFields[currentVariantIndex]?.customFields.map(customField => (
								<ProductCustomField
									key={customField.name}
									customField={customField}
									onEditClick={() => {
										setEditingCustomFieldName(customField.name)
										setIsCreateCustomFieldModalActive(true)
									}}
									onDeleteClick={() =>
										setVariantsCustomFields({
											...variantsCustomFields,
											[currentVariantIndex]: {
												...variantsCustomFields[currentVariantIndex],
												customFields: variantsCustomFields[currentVariantIndex].customFields
													? (
															variantsCustomFields[currentVariantIndex]
																.customFields as CustomField[]
													  ).filter(item => item.name !== customField.name)
													: [],
											},
										})
									}
								/>
							))}
							<div className={generalStyles.mtMediumLarge}>
								<Button
									disableShadow={true}
									width={'100%'}
									style={ButtonStyles.BORDERED}
									icon={{
										id: 'plus',
										height: 13,
										width: 13,
										align: ButtonsIconAlign.LEFT,
									}}
									onClick={() => setIsCreateCustomFieldModalActive(true)}
								>
									Add Custom Field
								</Button>
							</div>
						</>
					)}
				</div>

				<div className={generalStyles.horLine} />

				<div className={generalStyles.fieldWrapper}>
					<DiscordSettings
						onRefreshClick={() =>
							setVariantsDiscordSettings({
								...variantsDiscordSettings,
								[currentVariantIndex]: {
									...variantsDiscordSettings[currentVariantIndex],
									beforePurchaseRequireRoles: null,
									beforePurchaseAddUser: null,
								},
							})
						}
						currentVariantIndex={currentVariantIndex}
						beforePurchaseRequireRoles={
							variantsDiscordSettings[currentVariantIndex].beforePurchaseRequireRoles
						}
						isBeforePurchaseAddUserEnabled={
							variantsDiscordSettings[currentVariantIndex].isBeforePurchaseAddUserEnabled
						}
						isBeforePurchaseRequireRolesEnabled={
							variantsDiscordSettings[currentVariantIndex].isBeforePurchaseRequireRolesEnabled
						}
						setIsBeforePurchaseRequireRolesEnabled={value =>
							setVariantsDiscordSettings({
								...variantsDiscordSettings,
								[currentVariantIndex]: {
									...variantsDiscordSettings[currentVariantIndex],
									isBeforePurchaseRequireRolesEnabled: value,
								},
							})
						}
						setIsBeforePurchaseAddUserEnabled={value =>
							setVariantsDiscordSettings({
								...variantsDiscordSettings,
								[currentVariantIndex]: {
									...variantsDiscordSettings[currentVariantIndex],
									isBeforePurchaseAddUserEnabled: value,
								},
							})
						}
						setBeforePurchaseRequireRoles={value =>
							setVariantsDiscordSettings({
								...variantsDiscordSettings,
								[currentVariantIndex]: {
									...variantsDiscordSettings[currentVariantIndex],
									beforePurchaseRequireRoles: value,
								},
							})
						}
						beforePurchaseAddUser={
							variantsDiscordSettings[currentVariantIndex].beforePurchaseAddUser
						}
						setBeforePurchaseAddUser={value =>
							setVariantsDiscordSettings({
								...variantsDiscordSettings,
								[currentVariantIndex]: {
									...variantsDiscordSettings[currentVariantIndex],
									beforePurchaseAddUser: value,
								},
							})
						}
						isEnabled={variantsDiscordSettings[currentVariantIndex].isEnabled}
						setIsEnabled={value =>
							setVariantsDiscordSettings({
								...variantsDiscordSettings,
								[currentVariantIndex]: {
									...variantsDiscordSettings[currentVariantIndex],
									isEnabled: value,
								},
							})
						}
						setIsRequired={value =>
							setVariantsDiscordSettings({
								...variantsDiscordSettings,
								[currentVariantIndex]: {
									...variantsDiscordSettings[currentVariantIndex],
									isRequired: value,
								},
							})
						}
						isRequired={variantsDiscordSettings[currentVariantIndex].isRequired}
					/>
				</div>
			</div>
		</>
	)
}

export default ProductVariantsCheckout
