import React, { useEffect, useState } from 'react'
import styles from './FAQPage.module.scss'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageHeader from '../../components/PageHeader/PageHeader'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import Modal from '../../components/Modals/Modal'
import CreateFAQModal from '../../components/Modals/CreateFAQModal/CreateFAQModal'
import { closeModal } from '../../helpers/closeModal'
import { useAPI } from '../../hooks/useAPI'
import { useAppContext } from '../../hooks/useAppContext'
import { FAQ as FAQType } from '../../types/FAQ/FAQ'
import { FaqsService } from '../../API/FaqsService'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import FAQ from '../../components/FAQ/FAQ'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import noQuestionsImg from '../../assets/images/noQuestions.png'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import SearchInput, { SearchInputStyles } from '../../components/UI/SearchInput/SearchInput'
import PageTableHeader from '../../components/PageTableHeader/PageTableHeader'
import Pagination from '../../components/UI/Pagination/Pagination'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import { useDebounce } from '../../hooks/useDebounce'
import Spinner from '../../components/UI/Spinner/Spinner'
import { usePageTitle } from '../../hooks/usePageTitle'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

const FaqPage = () => {
	const [isCreateModalActive, setIsCreateModalActive] = useState(false)
	const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
	const [isDeleteModalActive, setIsDeleteModalActive] = useState(false)
	const [editingFaq, setEditingFaq] = useState<FAQType | null>(null)
	const [searchValue, setSearchValue] = useState('')
	const [totalItems, setTotalItems] = useState(0)
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const { shop } = useAppContext()
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isContentViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.CONTENT_VIEW) || isAdmin
	const isContentModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.CONTENT_MODIFY) || isAdmin
	const isContentDeleteAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.CONTENT_DELETE) || isAdmin
	usePageTitle('Frequently Asked Questions')

	const [isGetFaqsLoading, faqs, getFaqs] = useAPI<FAQType[]>(
		() => FaqsService.getAll(shop?.id || '', searchValue, currentPage + 1, pageSize),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	const isFirstLoading = useFirstLoading(isGetFaqsLoading)
	useDebounce(searchValue, getFaqs, 200)

	const [isDeleteFaqLoading, , deleteFaq] = useAPI(
		() => FaqsService.delete(shop?.id || '', editingFaq?.id || null),
		false,
		'Faq successfully deleted',
		{
			onSuccess: () => {
				closeDeleteModalHandler()
				getFaqs()
			},
		}
	)

	const onDeleteClickHandler = (faq: FAQType | null) => {
		setEditingFaq(faq)
		setIsDeleteModalActive(true)
	}

	const onEditClickHandler = (faq: FAQType | null) => {
		setEditingFaq(faq)
		setIsCreateModalActive(true)
	}

	const closeCreateModalHandler = () => {
		closeModal(setIsCreateModalActive, setIsCreateModalVisible)
		setEditingFaq(null)
	}

	const closeDeleteModalHandler = () => {
		closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)
		setEditingFaq(null)
	}

	useEffect(() => {
		if (shop && isContentViewAvailable) {
			getFaqs()
		}
	}, [shop, currentPage, pageSize])

	if (isFirstLoading) return <PageLoading />

	if (!isContentViewAvailable)
		return (
			<NoDataComponent
				imageSrc={noQuestionsImg}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)

	return (
		<PageContainer>
			{isCreateModalActive && (
				<Modal
					closeModal={closeCreateModalHandler}
					visible={isCreateModalVisible}
					setVisible={setIsCreateModalVisible}
				>
					<CreateFAQModal
						editingFaq={editingFaq}
						onClose={closeCreateModalHandler}
						getFaqs={getFaqs}
					/>
				</Modal>
			)}
			{isDeleteModalActive && (
				<Modal
					closeModal={closeDeleteModalHandler}
					visible={isDeleteModalVisible}
					setVisible={setIsDeleteModalVisible}
				>
					<ConfirmModal
						title={'Delete FAQ'}
						onConfirm={deleteFaq}
						description={`Are you sure you want to delete this FAQ? This cannot be undone.`}
						onClose={closeDeleteModalHandler}
						confirmButtonText={'Delete'}
						isButtonLoading={isDeleteFaqLoading}
						buttonIcon={{
							id: 'trashBin',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
					/>
				</Modal>
			)}
			<PageWrapper>
				{!faqs?.length && !searchValue ? (
					<NoDataComponent
						imageSrc={noQuestionsImg}
						title={'No Questions'}
						description={
							'You don’t have any questions or answers. To create a faq, tap on the button below.'
						}
						button={
							isContentModifyAvailable
								? {
										icon: {
											id: 'plus',
											height: 13,
											width: 13,
											align: ButtonsIconAlign.RIGHT,
										},
										onClick: () => setIsCreateModalActive(true),
										text: 'Create a Faq',
								  }
								: null
						}
					/>
				) : (
					<div className={styles.FAQPage}>
						<PageHeader title={'Frequently Asked Questions'}>
							<div className={styles.headerEnd}>
								<SearchInput
									style={SearchInputStyles.secondary}
									value={searchValue}
									setValue={setSearchValue}
									placeholder={'Search for a question by title or answer'}
								/>
								{isContentModifyAvailable && (
									<Button
										style={ButtonStyles.SECONDARY}
										icon={{
											id: 'plus',
											width: 13,
											height: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										onClick={() => setIsCreateModalActive(true)}
									>
										Add another question
									</Button>
								)}
							</div>
						</PageHeader>

						<PageTableHeader
							listItemNamePlural={'questions'}
							totalItems={totalItems}
							pageSize={pageSize}
							setPageSize={setPageSize}
							setCurrentPage={setCurrentPage}
						/>

						{isGetFaqsLoading && <Spinner />}

						{faqs && (
							<div className={styles.body}>
								{faqs.map(faq => (
									<FAQ
										faq={faq}
										onDeleteClickHandler={onDeleteClickHandler}
										onEditClickHandler={onEditClickHandler}
										key={faq.id}
										isContentModifyAvailable={isContentModifyAvailable}
										isContentDeleteAvailable={isContentDeleteAvailable}
									/>
								))}
							</div>
						)}

						<div className={styles.paginationWrapper}>
							<Pagination
								withoutPageSizeSelector={true}
								totalItems={totalItems}
								pageSize={pageSize}
								setPageSize={setPageSize}
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
							/>
						</div>
					</div>
				)}
			</PageWrapper>
		</PageContainer>
	)
}

export default FaqPage
