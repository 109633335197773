import { Subscriptions } from '../types/Subscriptions/Subscriptions'

export const subscriptionOptions = {
	[Subscriptions.CORE]: {
		title: 'CORE',
		description: 'For entrepreneurs who are just getting started selling online.',
		monthlyPrice: '$0/mo',
		yearlyPrice: '$0/yr',
	},
	[Subscriptions.CORE_PLUS]: {
		title: 'CORE PLUS',
		description: 'Feature add-ons to help entrepreneurs sell with a budget.',
		monthlyPrice: '$29/mo',
		yearlyPrice: '$299/yr',
	},
	[Subscriptions.BUSINESS]: {
		title: 'BUSINESS',
		description: 'Entrepreneurial magnates fueling growth for their internet business.',
		monthlyPrice: '$79/mo',
		yearlyPrice: '$799/yr',
	},
	[Subscriptions.SCALE]: {
		title: 'SCALE',
		description: 'A curated plan for the top of businesses rapidly scaling.',
		monthlyPrice: '$299/mo',
		yearlyPrice: '$2,999/yr',
	},
}
