import React, { FC } from 'react'
import styles from './TeamMember.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { Manager } from '../../types/Manager/Manager'
import { format } from 'date-fns'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'
import { useAppContext } from '../../hooks/useAppContext'

const TeamMemberOwner = () => {
	const { user } = useAppContext()

	return (
		<div className={styles.teamMember}>
			<div className={styles.teamMemberHead}>
				<div className={styles.teamMemberIcon}>
					<SpriteIcon iconId={'crown'} width={16} height={12} />
				</div>
				<div>
					<p className={styles.teamMemberEmail}>{user?.email}</p>
				</div>
			</div>
			<div className={styles.teamMemberEnd}>
				{user?.isTfaEnabled ? (
					<div
						data-tooltip-id='my-tooltip'
						data-tooltip-content='Two-factor authentication enabled'
					>
						<SpriteIcon className={styles.green} iconId={'checkedCircle'} width={16} height={16} />
					</div>
				) : (
					<div
						data-tooltip-id='my-tooltip'
						data-tooltip-content='Two-factor authentication is not enabled'
					>
						<SpriteIcon
							className={styles.brown}
							iconId={'exclamationMarkCircle'}
							width={16}
							height={16}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default TeamMemberOwner
