import { privateAPI } from './API'
import { WebhookCreate } from '../types/WebhookCreate/WebhookCreate'

export const DeveloperSettingsService = {
	getAllWebhooks: async (shopId: string) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/settings/developers/webhooks`)
	},
	createWebhook: async (shopId: string, data: WebhookCreate) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/settings/developers/webhooks`, data)
	},
	resetWebhookSecret: async (shopId: string) => {
		return await privateAPI.post(
			`/v1/dash/shops/${shopId}/settings/developers/webhooks/reset-secret`
		)
	},
	deleteWebhook: async (shopId: string, id: number | null) => {
		return await privateAPI.delete(`/v1/dash/shops/${shopId}/settings/developers/webhooks/${id}`)
	},
}
