import React, { FC, useEffect, useState } from 'react'
import styles from '../Modals.module.scss'
import Input from '../../UI/FormComponents/Input'
import Textarea from '../../UI/FormComponents/Textarea'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { VariantsGeneral } from '../../../types/CreateVariants/VariantsGeneral'
import uniqid from 'uniqid'
import { closeModal } from '../../../helpers/closeModal'
import { VariantsPricing } from '../../../types/CreateVariants/VariantsPricing'
import { VariantsDelivery } from '../../../types/CreateVariants/VariantsDelivery'
import { ChargeType } from '../../../types/ChargeType/ChargeType'
import { VariantsDiscordSettings } from '../../../types/CreateVariants/VariantsDiscordSettings'
import { VariantsAdvanced } from '../../../types/CreateVariants/VariantsAdvanced'
import { DeliveryType } from '../../../types/DeliveryType/DeliveryType'
import { VariantsCustomFields } from '../../../types/CreateVariants/VariantsCustomFields'
import { ProductVariantsTab } from '../../../types/ProductVariantsTab/ProductVariantsTab'

interface CreateProductVariantModalProps extends BaseModalProps {
	setVariantsGeneral: (value: VariantsGeneral) => void
	variantsGeneral: VariantsGeneral
	variantsPricing: VariantsPricing
	setVariantsPricing: (value: VariantsPricing) => void
	variantsDelivery: VariantsDelivery
	setVariantsDelivery: (value: VariantsDelivery) => void
	setVariantsDiscordSettings: (value: VariantsDiscordSettings) => void
	variantsDiscordSettings: VariantsDiscordSettings
	setVariantsAdvanced: (value: VariantsAdvanced) => void
	variantsCustomFields: VariantsCustomFields
	setVariantsCustomFields: (value: VariantsCustomFields) => void

	variantsAdvanced: VariantsAdvanced
	editingVariantId?: string | null
	currentVariantsTab: { [variantIndex: string]: ProductVariantsTab }
	setCurrentVariantsTab: (value: { [variantIndex: string]: ProductVariantsTab }) => void
}

const CreateProductVariantModal: FC<CreateProductVariantModalProps> = ({
	setVariantsGeneral,
	variantsGeneral,
	variantsDelivery,
	setVariantsDelivery,
	variantsPricing,
	setVariantsPricing,
	onClose,
	variantsDiscordSettings,
	setVariantsDiscordSettings,
	variantsAdvanced,
	setVariantsAdvanced,
	editingVariantId,
	variantsCustomFields,
	setVariantsCustomFields,
	setCurrentVariantsTab,
	currentVariantsTab,
}) => {
	const [title, setTitle] = useState('Default Variant')

	const onSaveHandler = () => {
		const variantId = uniqid()

		if (editingVariantId) {
			setVariantsGeneral({
				...variantsGeneral,
				[editingVariantId]: {
					...variantsGeneral[editingVariantId],
					title,
				},
			})
		} else {
			let maxPosition = 0
			Object.values(variantsGeneral).forEach(obj => {
				maxPosition = Math.max(maxPosition, obj.position)
			})

			setCurrentVariantsTab({
				...currentVariantsTab,
				[variantId]: ProductVariantsTab.GENERAL,
			})

			setVariantsGeneral({
				...variantsGeneral,
				[variantId]: {
					title,
					description: '',
					shortDescription: '',
					isDescriptionEnabled: false,
					position: maxPosition + 1,
				},
			})
			setVariantsDelivery({
				...variantsDelivery,
				[variantId]: {
					deliveryConfiguration: {},
					deliveryType: DeliveryType.PRESET,
					maxQuantity: 0,
					minQuantity: 0,
					deliveryTimeSeconds: '0',
					isDeliveryTimeEnabled: true,
					customDeliveryTimeSeconds: '0',
				},
			})
			setVariantsDiscordSettings({
				...variantsDiscordSettings,
				[variantId]: {
					beforePurchaseRequireRoles: {
						guildId: '',
						roleIds: [],
					},
					beforePurchaseAddUser: {
						guildId: '',
						roleIds: [],
					},
					isEnabled: false,
					isRequired: false,
					isBeforePurchaseRequireRolesEnabled: false,
					isBeforePurchaseAddUserEnabled: false,
				},
			})
			setVariantsPricing({
				...variantsPricing,
				[variantId]: {
					chargeType: ChargeType.ONE_TIME,
					gateways: [],
					price: {
						amount: '0.00',
						currency: 'USD',
					},
					compareAtPrice: null,
					costPerItem: null,
					chargeTypes: [ChargeType.ONE_TIME, ChargeType.RECURRING],
					subscriptionSettings: null,
				},
			})
			setVariantsAdvanced({
				...variantsAdvanced,
				[variantId]: {
					customWarranty: 0,
					redirectUrl: '',
					terms: '',
					warranty: {
						durationSeconds: '',
						text: '',
					},
					isRedirectUrlEnabled: false,
					isTermsEnabled: false,
					customerNote: '',
					isCustomerNoteEnabled: false,
					isWarrantyEnabled: false,
				},
			})
			setVariantsCustomFields({
				...variantsCustomFields,
				[variantId]: {
					customFields: [],
					enableCustomFields: false,
				},
			})
		}

		onClose()
	}

	useEffect(() => {
		if (editingVariantId) {
			setTitle(variantsGeneral[editingVariantId].title)
		}
	}, [])

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>{editingVariantId ? 'Edit' : 'Create'} Variant</h1>
			</header>

			<div className={styles.body}>
				<div className={styles.fieldWrapper}>
					<h2>Title</h2>
					<Input value={title} setValue={setTitle} placeholder={'Title'} />
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						disableShadow={true}
						icon={{
							id: 'plus',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						onClick={onSaveHandler}
					>
						{editingVariantId ? 'Edit' : 'Create'} Variant
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default CreateProductVariantModal
