import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import ImageInput from '../../UI/ImageInput/ImageInput'
import { useAPI } from '../../../hooks/useAPI'
import { ImagesService } from '../../../API/ImagesService'
import { useAppContext } from '../../../hooks/useAppContext'
import { SpriteIcon } from '../../UI/SpriteIcon/SpriteIcon'
import FormErrorsDisplayBadge from '../../UI/FormErrorsDisplayBadge/FormErrorsDisplayBadge'
import { FormErrors } from '../../../types/FormErrors/FormErrors'

interface AddImageModalProps extends BaseModalProps {
	getImages: () => void
}

const AddImageModal: FC<AddImageModalProps> = ({ onClose, getImages }) => {
	const [imageFile, setImageFile] = useState<File | null>(null)
	const { shop } = useAppContext()
	const [showErrorsDisplay, setShowErrorsDisplay] = useState(false)
	const [errors, setErrors] = useState<FormErrors>({
		imageFile: '',
	})
	const errorMessages = Object.keys(errors)
		.map(key => errors[key])
		.filter(error => error)

	const [isUploadImageLoading, , uploadImage] = useAPI(
		() => {
			const formData = new FormData()

			formData.append('file', imageFile || '')

			return ImagesService.upload(shop?.id || '', formData)
		},
		false,
		'Images successfully uploaded',
		{
			onSuccess: () => {
				getImages()
				onClose()
			},
		}
	)

	const onUploadClickHandler = () => {
		if (imageFile) {
			uploadImage()
		} else {
			setErrors({
				imageFile: "Image file can't be blank",
			})
			setShowErrorsDisplay(true)
		}
	}

	return (
		<div className={styles.modalInner}>
			{/*{showErrorsDisplay && !!errorMessages.length && (*/}
			{/*	<FormErrorsDisplayBadge*/}
			{/*		title={`Image Upload Error`}*/}
			{/*		errors={errorMessages}*/}
			{/*		isModal={true}*/}
			{/*	/>*/}
			{/*)}*/}
			<header className={styles.header}>
				<h1>Add Image</h1>
			</header>

			<div className={styles.body}>
				<ImageInput
					title={'Upload an image'}
					subTitle={'PNG, JPG, or GIF. 5MB max'}
					file={imageFile}
					setFile={setImageFile}
					errorMessage={errors['imageFile']}
				/>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						disableShadow={true}
						icon={{
							id: 'download',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						onClick={onUploadClickHandler}
						isLoading={isUploadImageLoading}
					>
						Save Image
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default AddImageModal
