import React, { FC } from 'react'
import styles from './PaymentTypeBadge.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { Gateways } from '../../types/Gateways/Gateways'
import { getGatewaysOptions } from '../../helpers/getGatewaysOptions'
import { useAppContext } from '../../hooks/useAppContext'

interface PaymentTypeBadgeProps {
	gateway: string
}

const PaymentTypeBadge: FC<PaymentTypeBadgeProps> = ({ gateway }) => {
	const { availablePayments } = useAppContext()
	const gatewayOptions = availablePayments?.find(item => item.name === gateway)

	return (
		<div className={styles.paymentTypeBadge}>
			<div className={styles.logo}>
				<img
					src={`https://imagedelivery.net/${gatewayOptions?.logoCfImageId}/productCard`}
					alt={gatewayOptions?.name}
				/>
			</div>
			{gatewayOptions?.displayName}
		</div>
	)
}

export default PaymentTypeBadge
