import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Input from '../../UI/FormComponents/Input'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import Tabs from '../../UI/Tabs/Tabs'
import { useAPI } from '../../../hooks/useAPI'
import { OrdersService } from '../../../API/OrdersService'

interface IssueReplacementModal extends BaseModalProps {
	orderId: string | undefined
	shopId: string
	partOrderId: number | null
	partOrderQuantity: number
	getOrder: () => void
}

const IssueReplacementModal: FC<IssueReplacementModal> = ({
	onClose,
	orderId,
	partOrderId,
	shopId,
	getOrder,
	partOrderQuantity,
}) => {
	const [quantity, setQuantity] = useState(0)
	const [tab, setTab] = useState<'full' | 'partial'>('full')

	const [isReplacePartOrderLoading, , replacePartOrder] = useAPI(
		() =>
			OrdersService.replacePartOrder(shopId, orderId, Number(partOrderId), {
				quantity: tab === 'full' ? partOrderQuantity : quantity,
			}),
		false,
		'Part order successfully replaced',
		{
			onSuccess: () => {
				onClose()
				getOrder()
			},
		}
	)

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Issue Replacement</h1>
			</header>

			<div className={styles.body}>
				<Tabs
					tabs={[
						{
							label: 'Full',
							value: 'full',
						},
						{
							label: 'Partial',
							value: 'partial',
						},
					]}
					currentTab={tab}
					setCurrentTab={setTab}
				/>

				<p className={`${styles.description} ${styles.mtLarge}`}>
					{tab === 'partial'
						? 'To issue a partial replacement, enter the quantity from available stock below then tap on the button below.'
						: 'To issue a full replacement of this product with any available stock, tap on the button below.'}
				</p>

				{tab === 'partial' && (
					<div className={styles.fieldWrapper}>
						<h2>Quantity</h2>
						<Input value={quantity} setValue={setQuantity} placeholder={'0'} />
					</div>
				)}
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'send',
							height: 14,
							width: 12,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={replacePartOrder}
						isLoading={isReplacePartOrderLoading}
					>
						Issue {tab === 'partial' ? 'Partial' : 'Full'} Replacement
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default IssueReplacementModal
