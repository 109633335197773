import React, { useEffect, useState } from 'react'
import styles from './ReviewDetailedPage.module.scss'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageHeader from '../../components/PageHeader/PageHeader'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import RatingBadge from '../../components/UI/RatingBadge/RatingBadge'
import MessageInput from '../../components/UI/MessageInput/MessageInput'
import { useAPI } from '../../hooks/useAPI'
import { ReviewsService } from '../../API/ReviewsService'
import { useAppContext } from '../../hooks/useAppContext'
import { useParams } from 'react-router-dom'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import { ReviewDetailed } from '../../types/ReviewDetailed/ReviewDetailed'
import Modal from '../../components/Modals/Modal'
import AppealReviewModal from '../../components/Modals/AppealReviewModal/AppealReviewModal'
import { closeModal } from '../../helpers/closeModal'
import { usePageTitle } from '../../hooks/usePageTitle'
import { cleanEmail } from '../../helpers/cleanEmail'
import { format } from 'date-fns'
import { FormErrors } from '../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../helpers/fieldValidators'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'
import { Subscriptions } from '../../types/Subscriptions/Subscriptions'

const ReviewDetailedPage = () => {
	const { shop, user, setIsSubscriptionModalActive, setRequiredSubscription } = useAppContext()
	const [isAppealReviewModalActive, _setIsAppealReviewModalActive] = useState(false)
	const [isAppealReviewModalVisible, setIsAppealReviewModalVisible] = useState(false)
	const [replyMessage, setReplyMessage] = useState('')
	const params = useParams()
	const reviewId = params.id
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isReviewsModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.REVIEWS_MODIFY) || isAdmin
	const [errors, setErrors] = useState<FormErrors>({})
	usePageTitle(`Review Detailed`)

	const setIsAppealReviewModalActive = (is: boolean) => {
		if (user?.subscription.perks.appealReviews) {
			_setIsAppealReviewModalActive(is)
		} else {
			setIsSubscriptionModalActive(true)
			setRequiredSubscription(Subscriptions.CORE_PLUS)
		}
	}
	const answerToReviewValidator = () => {
		const messageValidator = fieldValidators.blankValidator('Message', replyMessage)

		setErrors({
			message: messageValidator,
		})

		return !messageValidator
	}

	const [isGetDetailedReviewLoading, detailedReview, getDetailedReview] = useAPI<ReviewDetailed>(
		() => ReviewsService.getOne(shop?.id || '', Number(reviewId)),
		false
	)

	const [isAnswerToReviewLoading, , answerToReview] = useAPI(
		() =>
			ReviewsService.answerToReview(shop?.id || '', Number(reviewId), {
				reply: replyMessage,
			}),
		false,
		'Message successfully send',
		{
			validator: answerToReviewValidator,
			onSuccess: () => {
				getDetailedReview()
			},
		}
	)

	const cleanedEmail = cleanEmail(detailedReview?.order.customerEmail || '')

	const closeAppealReviewModal = () => {
		closeModal(setIsAppealReviewModalActive, setIsAppealReviewModalVisible)
	}

	useEffect(() => {
		if (shop) {
			getDetailedReview()
		}
	}, [shop])

	useEffect(() => {
		if (detailedReview?.sellerReply) {
			setReplyMessage(detailedReview.sellerReply)
		}
	}, [detailedReview])

	if (isGetDetailedReviewLoading) return <PageLoading />

	return (
		<PageContainer>
			{isAppealReviewModalActive && (
				<Modal
					closeModal={closeAppealReviewModal}
					visible={isAppealReviewModalVisible}
					setVisible={setIsAppealReviewModalVisible}
				>
					<AppealReviewModal
						reviewId={Number(reviewId)}
						getReviews={getDetailedReview}
						onClose={closeAppealReviewModal}
					/>
				</Modal>
			)}
			<PageWrapper>
				<div className={styles.reviewDetailedPage}>
					<PageHeader
						isRow={true}
						title={'Manage Review'}
						backButton={{
							isActive: true,
						}}
					>
						<div className={styles.headerEnd}>
							{isReviewsModifyAvailable && (
								<div className={styles.headerEndItem}>
									<Button
										style={ButtonStyles.SECONDARY}
										icon={{
											id: 'scales',
											width: 14,
											height: 14,
											align: ButtonsIconAlign.RIGHT,
										}}
										onClick={() => setIsAppealReviewModalActive(true)}
									>
										Appeal Review
									</Button>
								</div>
							)}
						</div>
					</PageHeader>

					<div className={styles.body}>
						<div className={styles.column}>
							<div className={styles.section}>
								<header>
									<h1>Reply to Review</h1>
								</header>

								<div className={styles.titleValueWrapper}>
									<div className={styles.author}>
										<div className={styles.authorAva}>
											{cleanedEmail[0]}
											{cleanedEmail[cleanedEmail.length - 1]}
										</div>
										<div className={styles.authorInfo}>
											<h2>{detailedReview?.comment || '-'}</h2>
											<p>{detailedReview?.order.customerEmail}</p>
										</div>
									</div>

									<RatingBadge rating={detailedReview?.rating || 0} />
								</div>

								{isReviewsModifyAvailable && (
									<footer className={styles.sectionFooter}>
										<MessageInput
											style={'secondary'}
											value={replyMessage}
											setValue={setReplyMessage}
											placeholder={'Type a response to this review...'}
											onClick={answerToReview}
										/>
									</footer>
								)}
							</div>
						</div>
						<div className={styles.column}>
							<div className={styles.section}>
								<header>
									<h1>Order Information</h1>
								</header>

								<div className={styles.titleValueWrapper}>
									<h2>Order ID</h2>
									<p>{detailedReview?.order.id}</p>
								</div>
								<div className={styles.titleValueWrapper}>
									<h2>Revenue</h2>
									<p>-</p>
								</div>
								<div className={styles.titleValueWrapper}>
									<h2>Quantity</h2>
									<p>-</p>
								</div>
								<div className={styles.titleValueWrapper}>
									<h2>Payment Type</h2>
									<p>-</p>
								</div>
								<div className={styles.titleValueWrapper}>
									<h2>Country</h2>
									<div className={styles.iconValue}>
										{/*<img*/}
										{/*	width={20}*/}
										{/*	height={10}*/}
										{/*	src='https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/1200px-Flag_of_the_United_States.svg.png'*/}
										{/*	alt=''*/}
										{/*/>*/}
										<p>-</p>
									</div>
								</div>
								<div className={styles.titleValueWrapper}>
									<h2>Date</h2>
									<p>
										{detailedReview?.order.createdAt &&
											format(
												new Date(detailedReview?.order.createdAt),
												"MMM dd, yyyy 'at' hh:mm aa z"
											)}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default ReviewDetailedPage
