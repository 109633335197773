import React, { FC, useEffect, useState } from 'react'
import styles from '../Modals.module.scss'
import { ExplorerPlugin } from '../../../types/Plugin/ExplorerPlugin'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import SetupField from '../../UI/SetupField/SetupField'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { useAppContext } from '../../../hooks/useAppContext'
import ConfigurePaymentMethodModalsEditLimits from '../ConfigurePaymentMethodModals/ConfigurePaymentMethodModalsEditLimits'
import Switch from '../../UI/Switch/Switch'
import { useAPI } from '../../../hooks/useAPI'
import { PaymentsSettingsService } from '../../../API/PaymentsSettingsService'
import { SettingsPayments } from '../../../types/SettingsPayments/SettingsPayments'
import Checkbox from '../../UI/Checkbox/Checkbox'
import { BulkUpdateProductsService } from '../../../API/BulkUpdateProductsService'
import { fieldValidators } from '../../../helpers/fieldValidators'
import { BulkGatewayAction } from '../../../types/BulkGatewayAction/BulkGatewayAction'

interface ConfigurePluginModalProps extends BaseModalProps {
	plugin?: ExplorerPlugin | null
	getPayments: () => void
	payments?: SettingsPayments
	editingAccountId: number | null
}

const ConfigurePluginModal: FC<ConfigurePluginModalProps> = ({
	plugin,
	getPayments,
	onClose,
	payments,
	editingAccountId,
}) => {
	const [values, setValues] = useState<{
		[valueName: string]: string | number | boolean
	}>({})
	const [errors, setErrors] = useState<FormErrors>({})
	const [isEditLimits, setIsEditLimits] = useState(false)
	const [maxAmountOfOrdersPerDay, setMaxAmountOfOrdersPerDay] = useState(0)
	const [maxAmountOfOrdersPerWeek, setMaxAmountOfOrdersPerWeek] = useState(0)
	const [maxAmountOfOrdersPerMonth, setMaxAmountOfOrdersPerMonth] = useState(0)
	const [maxRevenuePerDay, setMaxRevenuePerDay] = useState(0)
	const [maxRevenuePerWeek, setMaxRevenuePerWeek] = useState(0)
	const [maxRevenuePerMonth, setMaxRevenuePerMonth] = useState(0)
	const [blockVpnProxy, setBlockVpnProxy] = useState(false)
	const [isEnabledForAllProducts, setIsEnabledForAllProducts] = useState(false)
	const [priority, setPriority] = useState(1)
	const { shop } = useAppContext()

	const setupFieldsValidator = () => {
		const tempErrors: FormErrors = {}

		const result =
			plugin?.setupParameters.map(setupParameter => {
				const fieldValidator = setupParameter.isRequired
					? fieldValidators.blankValidator(setupParameter.name, values[setupParameter.variableName])
					: ''

				tempErrors[setupParameter.variableName] = fieldValidator

				return !fieldValidator
			}) || []

		setErrors({
			...errors,
			...tempErrors,
		})

		return result.every(value => value)
	}

	const [isBulkUpdateProductPaymentsLoading, , bulkUpdateProductPayments] = useAPI(
		() =>
			BulkUpdateProductsService.updateGateways(shop?.id || '', {
				gateways: [
					{
						gateway: plugin?.name || '',
					},
				],
				productIds: null,
				action: BulkGatewayAction.ADD
			}),
		false,
		'Payment successfully enabled for all products',
		{
			onSuccess: () => {
				getPayments()
				onClose()
			},
		}
	)

	const [isUpdateGatewayLoading, , updateGateway] = useAPI(
		() =>
			PaymentsSettingsService.updateGateway(shop?.id || '', plugin?.name || '', {
				accounts: [
					...(payments?.customGatewayAccounts
						.filter(
							account => account.gatewayName === plugin?.name && account.id !== editingAccountId
						)
						.map(account => ({
							id: account.id,
							savedSettings: account.savedSettings,
							isEnabled: account.isEnabled,
							maxAmountOfOrdersPerDay: account.maxAmountOfOrdersPerDay,
							maxAmountOfOrdersPerMonth: account.maxAmountOfOrdersPerMonth,
							maxAmountOfOrdersPerWeek: account.maxAmountOfOrdersPerWeek,
							maxRevenuePerDay: account.maxRevenuePerDay,
							maxRevenuePerMonth: account.maxRevenuePerMonth,
							maxRevenuePerWeek: account.maxRevenuePerWeek,
							priority: account.priority,
							blockVpnProxy: account?.blockVpnProxy || false,
							status: account.status,
						})) || []),
					{
						id: editingAccountId,
						savedSettings: values,
						isEnabled: true,
						maxAmountOfOrdersPerDay: !maxAmountOfOrdersPerDay ? null : maxAmountOfOrdersPerDay,
						maxAmountOfOrdersPerMonth: !maxAmountOfOrdersPerMonth
							? null
							: maxAmountOfOrdersPerMonth,
						maxAmountOfOrdersPerWeek: !maxAmountOfOrdersPerWeek ? null : maxAmountOfOrdersPerWeek,
						maxRevenuePerDay: !maxRevenuePerDay ? null : maxRevenuePerDay,
						maxRevenuePerMonth: !maxRevenuePerMonth ? null : maxRevenuePerMonth,
						maxRevenuePerWeek: !maxRevenuePerWeek ? null : maxRevenuePerWeek,
						priority: priority,
						blockVpnProxy: blockVpnProxy,
					},
				],
				overrideShownPaymentMethods: payments?.overridePaymentMethods[plugin?.name || ''] || null,
			}),
		false,
		'Payment account successfully saved',
		{
			onSuccess: () => {
				if (isEnabledForAllProducts) {
					bulkUpdateProductPayments()
				} else {
					getPayments()
					onClose()
				}
			},
			validator: setupFieldsValidator,
		}
	)

	useEffect(() => {
		if (editingAccountId) {
			const editingAccount = payments?.customGatewayAccounts.find(
				account => account.id === editingAccountId
			)

			setMaxAmountOfOrdersPerDay(editingAccount?.maxAmountOfOrdersPerDay || 0)
			setMaxAmountOfOrdersPerMonth(editingAccount?.maxAmountOfOrdersPerMonth || 0)
			setMaxAmountOfOrdersPerWeek(editingAccount?.maxAmountOfOrdersPerWeek || 0)
			setMaxRevenuePerDay(editingAccount?.maxRevenuePerDay || 0)
			setMaxRevenuePerWeek(editingAccount?.maxRevenuePerWeek || 0)
			setMaxRevenuePerMonth(editingAccount?.maxRevenuePerMonth || 0)
			setValues(editingAccount?.savedSettings || {})
			setPriority(editingAccount?.priority || 1)
			setIsEnabledForAllProducts(editingAccount?.isEnabled || false)
		}
	}, [])

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Configure {plugin?.displayName}</h1>
			</header>
			{isEditLimits ? (
				<ConfigurePaymentMethodModalsEditLimits
					maxRevenuePerDay={maxRevenuePerDay}
					setMaxRevenuePerDay={setMaxRevenuePerDay}
					maxRevenuePerWeek={maxRevenuePerWeek}
					setMaxRevenuePerWeek={setMaxRevenuePerWeek}
					maxRevenuePerMonth={maxRevenuePerMonth}
					setMaxRevenuePerMonth={setMaxRevenuePerMonth}
					maxAmountOfOrdersPerDay={maxAmountOfOrdersPerDay}
					setMaxAmountOfOrdersPerDay={setMaxAmountOfOrdersPerDay}
					maxAmountOfOrdersPerWeek={maxAmountOfOrdersPerWeek}
					setMaxAmountOfOrdersPerWeek={setMaxAmountOfOrdersPerWeek}
					maxAmountOfOrdersPerMonth={maxAmountOfOrdersPerMonth}
					setMaxAmountOfOrdersPerMonth={setMaxAmountOfOrdersPerMonth}
				/>
			) : (
				<div className={styles.body}>
					{plugin?.setupParameters.map(setupParameter => (
						<div className={styles.fieldWrapper}>
							<SetupField
								setupParameter={setupParameter}
								setValue={value => setValues({ ...values, [setupParameter.variableName]: value })}
								value={values[setupParameter.variableName]}
								errorMessage={errors[setupParameter.variableName]}
								setErrors={setErrors}
								errors={errors}
							/>
						</div>
					))}

					<div className={styles.fieldWrapper}>
						<div className={styles.switchWrapper}>
							<Switch checked={blockVpnProxy} setChecked={setBlockVpnProxy} />
							<p>Block customers with a VPN/proxy or high fraud score</p>
						</div>
					</div>

					<div className={styles.fieldWrapper}>
						<div className={styles.switchWrapper}>
							<Checkbox
								id={'isEnabled'}
								checked={isEnabledForAllProducts}
								setChecked={setIsEnabledForAllProducts}
							/>
							<p>Enable for all products</p>
						</div>
					</div>
				</div>
			)}
			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>

					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'pen',
							width: 15,
							height: 15,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={() => setIsEditLimits(!isEditLimits)}
					>
						{!isEditLimits ? 'Edit Limits' : 'Edit Settings'}
					</Button>

					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'download',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={updateGateway}
						isLoading={isUpdateGatewayLoading || isBulkUpdateProductPaymentsLoading}
					>
						Save Settings
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default ConfigurePluginModal
