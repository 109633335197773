import React, { FC, useEffect, useState } from 'react'
import styles from '../Modals.module.scss'
import Switch from '../../UI/Switch/Switch'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { SpriteIcon } from '../../UI/SpriteIcon/SpriteIcon'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import ConfigurePaymentMethodModalsEditLimits from './ConfigurePaymentMethodModalsEditLimits'
import { useAppContext } from '../../../hooks/useAppContext'
import { useAPI } from '../../../hooks/useAPI'
import { PaymentsSettingsService } from '../../../API/PaymentsSettingsService'
import { StripeAccount } from '../../../types/PaymentAccounts/StripeAccount/StripeAccount'
import { SettingsPayments } from '../../../types/SettingsPayments/SettingsPayments'
import Checkbox from '../../UI/Checkbox/Checkbox'

interface ConfigureStripeModalProps extends BaseModalProps {
	payments?: SettingsPayments
	getPayments: () => void
	editingAccountId: number | null
}

const ConfigureStripeModal: FC<ConfigureStripeModalProps> = ({
	onClose,
	payments,
	getPayments,
	editingAccountId,
}) => {
	const [isEditLimits, setIsEditLimits] = useState(false)
	const [maxAmountOfOrdersPerDay, setMaxAmountOfOrdersPerDay] = useState(0)
	const [maxAmountOfOrdersPerWeek, setMaxAmountOfOrdersPerWeek] = useState(0)
	const [maxAmountOfOrdersPerMonth, setMaxAmountOfOrdersPerMonth] = useState(0)
	const [maxRevenuePerDay, setMaxRevenuePerDay] = useState(0)
	const [maxRevenuePerWeek, setMaxRevenuePerWeek] = useState(0)
	const [maxRevenuePerMonth, setMaxRevenuePerMonth] = useState(0)
	const [blockVpnProxy, setBlockVpnProxy] = useState(false)
	const [isEnabled, setIsEnabled] = useState(false)
	const [accountKey, setAccountKey] = useState('')
	const [values, setValues] = useState<{
		[valueName: string]: string | number | boolean
	}>({})
	const isNotConnected =
		payments &&
		!payments.customGatewayAccounts.filter(account => account.gatewayName === 'Stripe').length

	const { shop } = useAppContext()

	const [isUpdateStripeLoading, , updateStripe] = useAPI(
		() =>
			PaymentsSettingsService.updateGateway(shop?.id || '', 'Stripe', {
				accounts: [
					...(payments?.customGatewayAccounts
						.filter(account => account.gatewayName === 'Stripe')
						.map(account => ({
							savedSettings: account.savedSettings,
							isEnabled: account.isEnabled,
							maxAmountOfOrdersPerDay: account.maxAmountOfOrdersPerDay,
							maxAmountOfOrdersPerMonth: account.maxAmountOfOrdersPerMonth,
							maxAmountOfOrdersPerWeek: account.maxAmountOfOrdersPerWeek,
							maxRevenuePerDay: account.maxRevenuePerDay,
							maxRevenuePerMonth: account.maxRevenuePerMonth,
							maxRevenuePerWeek: account.maxRevenuePerWeek,
							priority: account.priority,
							blockVpnProxy: account?.blockVpnProxy || false,
							status: account.status,
						})) || []),
					{
						savedSettings: values,
						isEnabled: true,
						maxAmountOfOrdersPerDay: !maxAmountOfOrdersPerDay ? null : maxAmountOfOrdersPerDay,
						maxAmountOfOrdersPerMonth: !maxAmountOfOrdersPerMonth
							? null
							: maxAmountOfOrdersPerMonth,
						maxAmountOfOrdersPerWeek: !maxAmountOfOrdersPerWeek ? null : maxAmountOfOrdersPerWeek,
						maxRevenuePerDay: !maxRevenuePerDay ? null : maxRevenuePerDay,
						maxRevenuePerMonth: !maxRevenuePerMonth ? null : maxRevenuePerMonth,
						maxRevenuePerWeek: !maxRevenuePerWeek ? null : maxRevenuePerWeek,
						priority: 1,
						blockVpnProxy: blockVpnProxy,
					},
				],
				overrideShownPaymentMethods: payments?.overridePaymentMethods['Stripe'] || null,
			}),
		false,
		'Successfully saved',
		{
			onSuccess: () => {
				onClose()
				getPayments()
			},
		}
	)

	useEffect(() => {
		if (editingAccountId) {
			const editingAccount = payments?.customGatewayAccounts.find(
				account => account.id === editingAccountId
			)

			setMaxAmountOfOrdersPerDay(editingAccount?.maxAmountOfOrdersPerDay || 0)
			setMaxAmountOfOrdersPerWeek(editingAccount?.maxAmountOfOrdersPerWeek || 0)
			setMaxAmountOfOrdersPerMonth(editingAccount?.maxAmountOfOrdersPerMonth || 0)
			setMaxRevenuePerDay(editingAccount?.maxRevenuePerDay || 0)
			setMaxRevenuePerWeek(editingAccount?.maxRevenuePerWeek || 0)
			setMaxRevenuePerMonth(editingAccount?.maxRevenuePerMonth || 0)
			setMaxRevenuePerMonth(editingAccount?.maxRevenuePerMonth || 0)
			setBlockVpnProxy(editingAccount?.blockVpnProxy || false)
			setValues(editingAccount?.savedSettings || {})
		}
	}, [])

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Configure Stripe</h1>
			</header>

			{isEditLimits ? (
				<ConfigurePaymentMethodModalsEditLimits
					maxRevenuePerDay={maxRevenuePerDay}
					setMaxRevenuePerDay={setMaxRevenuePerDay}
					maxRevenuePerWeek={maxRevenuePerWeek}
					setMaxRevenuePerWeek={setMaxRevenuePerWeek}
					maxRevenuePerMonth={maxRevenuePerMonth}
					setMaxRevenuePerMonth={setMaxRevenuePerMonth}
					maxAmountOfOrdersPerDay={maxAmountOfOrdersPerDay}
					setMaxAmountOfOrdersPerDay={setMaxAmountOfOrdersPerDay}
					maxAmountOfOrdersPerWeek={maxAmountOfOrdersPerWeek}
					setMaxAmountOfOrdersPerWeek={setMaxAmountOfOrdersPerWeek}
					maxAmountOfOrdersPerMonth={maxAmountOfOrdersPerMonth}
					setMaxAmountOfOrdersPerMonth={setMaxAmountOfOrdersPerMonth}
				/>
			) : (
				<div className={styles.body}>
					<div className={styles.fieldWrapper}>
						<h2>We use Stripe to process payments. Connect your account below to begin.</h2>
						<Button
							style={ButtonStyles.BORDERED}
							icon={{
								id: 'blankWindow',
								width: 12,
								height: 12,
								align: ButtonsIconAlign.LEFT,
								className: styles.dark,
							}}
							disableShadow={true}
							width={'100%'}
							onClick={() =>
								window.open(
									'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_PdeJkm7MrA9RMisZSoQRoVMu746bZ8S3&scope=read_write',
									'_blank'
								)
							}
						>
							Connect Stripe
						</Button>
					</div>

					{!isNotConnected && (
						<>
							<div className={styles.fieldWrapper}>
								<div className={styles.switchWrapper}>
									<Switch checked={blockVpnProxy} setChecked={setBlockVpnProxy} />
									<p>Block customers with a VPN/proxy or high fraud score</p>
								</div>
							</div>
							<div className={styles.fieldWrapper}>
								<div className={styles.switchWrapper}>
									<Checkbox id={'isEnabled'} checked={isEnabled} setChecked={setIsEnabled} />
									<p>Enable for all products</p>
								</div>
							</div>
						</>
					)}
				</div>
			)}

			<footer className={styles.footer}>
				{!isNotConnected && (
					<div className={styles.buttons}>
						<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
							Cancel
						</Button>

						<Button
							style={ButtonStyles.BORDERED}
							icon={{
								id: 'pen',
								width: 15,
								height: 15,
								align: ButtonsIconAlign.LEFT,
							}}
							disableShadow={true}
							onClick={() => setIsEditLimits(!isEditLimits)}
						>
							{!isEditLimits ? 'Edit Limits' : 'Edit Settings'}
						</Button>

						<Button
							style={ButtonStyles.BORDERED}
							icon={{
								id: 'download',
								width: 13,
								height: 13,
								align: ButtonsIconAlign.LEFT,
							}}
							disableShadow={true}
							onClick={updateStripe}
							isLoading={isUpdateStripeLoading}
						>
							Save Settings
						</Button>
					</div>
				)}
			</footer>
		</div>
	)
}

export default ConfigureStripeModal
