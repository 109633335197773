import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import CustomMDEditor from '../../UI/CustomMDEditor/CustomMDEditor'
import { useAPI } from '../../../hooks/useAPI'
import { ReviewsService } from '../../../API/ReviewsService'
import { useAppContext } from '../../../hooks/useAppContext'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'

interface ReplyToReviewModalProps extends BaseModalProps {
	reviewId: number | null
	getReviews: () => void
}

const ReplyToReviewModal: FC<ReplyToReviewModalProps> = ({ onClose, reviewId, getReviews }) => {
	const [message, setMessage] = useState<string>()
	const [errors, setErrors] = useState<FormErrors>({})
	const { shop } = useAppContext()

	const answerToReviewValidator = () => {
		const messageValidator = fieldValidators.blankValidator('Message', message)

		setErrors({
			message: messageValidator,
		})

		return !messageValidator
	}

	const [isAnswerToReviewLoading, , answerToReview] = useAPI(
		() =>
			ReviewsService.answerToReview(shop?.id || '', Number(reviewId), {
				reply: message || '',
			}),
		false,
		'Message successfully send',
		{
			validator: answerToReviewValidator,
			onSuccess: () => {
				onClose()
				getReviews()
			},
		}
	)

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Reply to Review</h1>
			</header>

			<div className={styles.body}>
				<div className={styles.fieldWrapper}>
					<h2>Message</h2>
					<CustomMDEditor
						value={message}
						setValue={setMessage}
						placeholder={'Thanks so much for the review. Super glad that you’re loving it.'}
						errorMessage={errors['message']}
						onBlur={() => answerToReviewValidator()}
					/>
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'send',
							height: 14,
							width: 12,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={answerToReview}
						isLoading={isAnswerToReviewLoading}
					>
						Send Review Response
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default ReplyToReviewModal
