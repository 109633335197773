import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import styles from './DevelopersKeysPage.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import Input, { InputIconAligns } from '../../components/UI/FormComponents/Input'
import { useCopy } from '../../hooks/useCopy'
import { useAppContext } from '../../hooks/useAppContext'
import ApiKeysTable from '../../components/Tables/ApiKeysTable'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import { useAPI } from '../../hooks/useAPI'
import { UserSettingsDeveloperService } from '../../API/UserSettingsDeveloperService'
import Modal from '../../components/Modals/Modal'
import GenerateApiKeyModal from '../../components/Modals/GenerateApiKeyModal/GenerateApiKeyModal'
import { closeModal } from '../../helpers/closeModal'
import { APIKey } from '../../types/APIKey/APIKey'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import { usePageTitle } from '../../hooks/usePageTitle'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

const DevelopersKeysPage = () => {
	const copy = useCopy()
	const [isGenerateKeyModalActive, setIsGenerateKeyModalActive] = useState(false)
	const [isGenerateKeyModalVisible, setIsGenerateKeyModalVisible] = useState(false)
	const [isDeleteKeyModalActive, setIsDeleteKeyModalActive] = useState(false)
	const [isDeleteKeyModalVisible, setIsDeleteKeyModalVisible] = useState(false)
	const [isResetKeyModalActive, setIsResetKeyModalActive] = useState(false)
	const [isResetKeyModalVisible, setIsResetKeyModalVisible] = useState(false)
	const [deletingApiKeyId, setDeletingApiKeyId] = useState<number | null>(null)
	const { shop } = useAppContext()
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isDevelopersViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.DEVELOPERS_VIEW) || isAdmin
	usePageTitle('Keys')

	const [isGetApiKeysLoading, apiKeys, getApiKeys] = useAPI<APIKey[]>(
		() => UserSettingsDeveloperService.getApiKeys(),
		false
	)

	const [isResetApiKeyLoading, , resetApiKey] = useAPI(
		(apiKey: APIKey) =>
			UserSettingsDeveloperService.createApiKey({
				name: apiKey.name,
				expiresInTicks: apiKey.expiresAt
					? (new Date(apiKey.expiresAt).getTime() - new Date().getTime()) * 10000
					: null,
			}),
		false,
		'API Key successfully reset',
		{
			onSuccess: () => {
				getApiKeys()
				closeResetModal()
			},
		}
	)

	const [isDeleteApiKeyLoading, , deleteApiKey] = useAPI(
		id => UserSettingsDeveloperService.deleteApiKey(id),
		false,
		'API Key successfully deleted',
		{
			onSuccess: () => {
				closeDeleteModal()
				getApiKeys()
			},
		}
	)

	const onDeleteApiKeyClickHandler = (id: number) => {
		setDeletingApiKeyId(id)
		setIsDeleteKeyModalActive(true)
	}

	const onResetApiKeyClickHandler = (id: number) => {
		setDeletingApiKeyId(id)
		setIsResetKeyModalActive(true)
	}

	const resetApiKeyHandler = () => {
		const resettingApiKey = apiKeys?.find(apiKey => apiKey.id === deletingApiKeyId)

		if (resettingApiKey) {
			deleteApiKey(deletingApiKeyId)
			resetApiKey(resettingApiKey)
		}
	}

	const closeDeleteModal = () => {
		closeModal(setIsDeleteKeyModalActive, setIsDeleteKeyModalVisible)
		setDeletingApiKeyId(null)
	}

	const closeResetModal = () => {
		closeModal(setIsResetKeyModalActive, setIsResetKeyModalVisible)
		setDeletingApiKeyId(null)
	}

	useEffect(() => {
		getApiKeys()
	}, [])

	if (isGetApiKeysLoading) return <PageLoading />

	return (
		<PageContainer>
			{isGenerateKeyModalActive && (
				<Modal
					closeModal={() => closeModal(setIsGenerateKeyModalActive, setIsGenerateKeyModalVisible)}
					visible={isGenerateKeyModalVisible}
					setVisible={setIsGenerateKeyModalVisible}
				>
					<GenerateApiKeyModal
						getApiKeys={getApiKeys}
						onClose={() => closeModal(setIsGenerateKeyModalActive, setIsGenerateKeyModalVisible)}
					/>
				</Modal>
			)}
			{isDeleteKeyModalActive && (
				<Modal
					closeModal={closeDeleteModal}
					visible={isDeleteKeyModalVisible}
					setVisible={setIsDeleteKeyModalVisible}
				>
					<ConfirmModal
						title={'Delete API Key'}
						description={
							'You’re about to delete API Key. Are you sure you want to delete this API Key? This cannot be undone.'
						}
						onConfirm={() => deletingApiKeyId && deleteApiKey(deletingApiKeyId)}
						confirmButtonText={'Delete API Key'}
						buttonIcon={{
							id: 'trashBin',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						isButtonLoading={isDeleteApiKeyLoading}
						onClose={closeDeleteModal}
					/>
				</Modal>
			)}
			{isResetKeyModalActive && (
				<Modal
					closeModal={closeResetModal}
					visible={isResetKeyModalVisible}
					setVisible={setIsResetKeyModalVisible}
				>
					<ConfirmModal
						title={'Reset API Key'}
						description={
							'You’re about to reset API Key. Are you sure you want to reset this API Key? This cannot be undone.'
						}
						onConfirm={resetApiKeyHandler}
						confirmButtonText={'Reset API Key'}
						buttonIcon={{
							id: 'refresh',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						isButtonLoading={isResetApiKeyLoading}
						onClose={closeResetModal}
					/>
				</Modal>
			)}
			<PageWrapper>
				<div className={styles.developersKeysPage}>
					<PageHeader title={'Keys'} />

					<div className={styles.developersKeysPageBody}>
						<section className={styles.section}>
							<header className={styles.sectionHeader}>
								<h1 className={styles.sectionTitle}>Developer Tools</h1>
							</header>

							<div className={styles.sectionBody}>
								<div className={styles.fieldWrapper}>
									<p className={styles.fieldWrapperTitle}>Shop ID</p>
									<div className={styles.formWrapper}>
										<Input
											value={shop?.id}
											setValue={() => {}}
											icon={{
												id: 'copy',
												width: 14,
												height: 14,
												align: InputIconAligns.RIGHT,
												onClick: () => copy(shop?.id.toString() || ''),
											}}
										/>
									</div>
									<p className={styles.fieldWrapperDescription}>
										This is your own identification number to access and interact with our public
										API.
									</p>
								</div>
							</div>
						</section>

						{isDevelopersViewAvailable && (
							<section className={styles.section}>
								<header className={styles.sectionHeader}>
									<h1 className={styles.sectionTitle}>API Keys</h1>
								</header>

								{apiKeys && (
									<ApiKeysTable
										apiKeys={apiKeys}
										onResetClick={onResetApiKeyClickHandler}
										onDeleteClick={onDeleteApiKeyClickHandler}
									/>
								)}

								<footer className={styles.sectionFooter}>
									<p className={styles.helperText}>
										This API key will allow you to authenticate your API requests and interact with
										our developer API.
									</p>
									<div className={styles.mtMediumLarge}>
										<Button
											style={ButtonStyles.BORDERED}
											icon={{
												id: 'plus',
												width: 13,
												height: 13,
												align: ButtonsIconAlign.LEFT,
											}}
											disableShadow={true}
											onClick={() => setIsGenerateKeyModalActive(true)}
										>
											Create New Key
										</Button>
									</div>
								</footer>
							</section>
						)}
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default DevelopersKeysPage
