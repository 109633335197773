import React, { FC, ReactNode } from 'react'
import styles from './PageContainer.module.scss'

interface ContainerProps {
	children: ReactNode
}

const PageContainer: FC<ContainerProps> = ({ children }) => {
	return <div className={styles.container}>{children}</div>
}

export default PageContainer
