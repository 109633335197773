import React, { FC } from 'react'
import styles from './PageTableHeader.module.scss'
import AdvancedFiltering from '../AdvancedFiltering/AdvancedFiltering'
import CustomSelect, { SelectStyles } from '../UI/CustomSelect/CustomSelect'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'

interface PageTableHeaderProps {
	listItemNamePlural: string

	totalItems: number
	pageSize: number
	setPageSize: (pageSize: number) => void
	setCurrentPage: (page: number) => void
	button?: {
		icon?: {
			id: string
			width: number
			height: number
			align: ButtonsIconAlign
		}
		text: string
		onClick?: () => void
		style?: ButtonStyles
	}
}

const PageTableHeader: FC<PageTableHeaderProps> = ({
	listItemNamePlural,
	setPageSize,
	pageSize,
	totalItems,
	button,
	setCurrentPage,
}) => {
	return (
		<div className={`${styles.tableHeader} ${button && styles.withButton}`}>
			<div className={styles.pageSizeController}>
				Showing {totalItems < pageSize ? totalItems : pageSize} of{' '}
				{totalItems && totalItems.toLocaleString()} {listItemNamePlural}
				<div>
					<CustomSelect
						style={SelectStyles.secondary}
						value={pageSize.toString()}
						setValue={value => {
							setPageSize(+value)
							setCurrentPage(0)
						}}
						options={[
							{ value: '10', label: `10 rows` },
							{ value: '20', label: `20 rows` },
							{ value: '30', label: `30 rows` },
							{ value: '40', label: `40 rows` },
							{ value: '50', label: `50 rows` },
						]}
					/>
				</div>
			</div>

			{/*<p className={styles.helperText}>Showing 1 – 2 of 117 {listItemNamePlural}</p>*/}
			<div className={styles.tableHeaderButtons}>
				{button && (
					<Button icon={button.icon} style={button.style} onClick={button.onClick}>
						{button.text}
					</Button>
				)}
				<AdvancedFiltering />
			</div>
		</div>
	)
}

export default PageTableHeader
