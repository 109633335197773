import React, { useEffect, useState } from 'react'
import styles from './ReviewsPage.module.scss'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageHeader from '../../components/PageHeader/PageHeader'
import SearchInput, { SearchInputStyles } from '../../components/UI/SearchInput/SearchInput'
import Pagination from '../../components/UI/Pagination/Pagination'
import ReviewsTable from '../../components/Tables/ReviewsTable'
import { useAppContext } from '../../hooks/useAppContext'
import { useAPI } from '../../hooks/useAPI'
import { ReviewsService } from '../../API/ReviewsService'
import { Review } from '../../types/Review/Review'
import Spinner from '../../components/UI/Spinner/Spinner'
import AverageRatingStatistics from '../../components/AverageRatingStatistics/AverageRatingStatistics'
import PageTableHeader from '../../components/PageTableHeader/PageTableHeader'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import noReviewsImage from '../../assets/images/noReviews.png'
import { useDebounce } from '../../hooks/useDebounce'
import Modal from '../../components/Modals/Modal'
import { closeModal } from '../../helpers/closeModal'
import AppealReviewModal from '../../components/Modals/AppealReviewModal/AppealReviewModal'
import ReplyToReviewModal from '../../components/Modals/ReplyToReviewModal/ReplyToReviewModal'
import { usePageTitle } from '../../hooks/usePageTitle'
import { GetReviewsStats } from '../../types/GetReviewsStats/GetReviewsStats'
import Section from '../../components/UI/Section/Section'
import Switch from '../../components/UI/Switch/Switch'
import { SettingsService } from '../../API/SettingsService'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import { GetSettingsGeneral } from '../../types/GetSettingsGeneral/GetSettingsGeneral'
import { Subscriptions } from '../../types/Subscriptions/Subscriptions'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

const ReviewsPage = () => {
	const [totalItems, setTotalItems] = useState(23)
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [searchValue, setSearchValue] = useState('')
	const [isAppealReviewModalActive, _setIsAppealReviewModalActive] = useState(false)
	const [isAppealReviewModalVisible, setIsAppealReviewModalVisible] = useState(false)
	const [isReplyReviewModalActive, setIsReplyReviewModalActive] = useState(false)
	const [isReplyReviewModalVisible, setIsReplyReviewModalVisible] = useState(false)
	const [usingReviewId, setUsingReviewId] = useState<number | null>(null)
	const [isAutomatedReviewsEnabled, setIsAutomatedReviewsEnabled] = useState(false)
	const { shop, user, setIsSubscriptionModalActive, setRequiredSubscription } = useAppContext()
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isReviewsViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.REVIEWS_VIEW) || isAdmin
	const isReviewsModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.REVIEWS_MODIFY) || isAdmin
	usePageTitle(`Reviews`)

	const setIsAppealReviewModalActive = (is: boolean) => {
		if (user?.subscription.perks.appealReviews) {
			_setIsAppealReviewModalActive(is)
		} else {
			setIsSubscriptionModalActive(true)
			setRequiredSubscription(Subscriptions.CORE_PLUS)
		}
	}

	const [isGetReviewsLoading, reviews, getReviews] = useAPI<Review[]>(
		() => ReviewsService.getAll(shop?.id || '', searchValue, currentPage + 1, pageSize),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	const [, settingsGeneral, getGeneralSettings] = useAPI<GetSettingsGeneral>(
		() => SettingsService.getCompany(shop?.id || ''),
		false,
		undefined,
		{
			onSuccess: response => {
				setIsAutomatedReviewsEnabled(response.data.data.shopSettings.isAutomatedReviewsEnabled)
			},
		}
	)

	const [isGetReviewsStatsLoading, reviewsStats, getReviewsStats] = useAPI<GetReviewsStats>(
		() => ReviewsService.getStats(shop?.id || ''),
		false
	)

	const [isUpdateAutomatedReviewsLoading, , updateAutomatedReviews] = useAPI(
		() =>
			SettingsService.updateAutomatedReviews(shop?.id || '', {
				isEnabled: isAutomatedReviewsEnabled,
			}),
		false,
		'Settings successfully saved'
	)

	const isFirstLoading = useFirstLoading(isGetReviewsLoading)
	useDebounce(searchValue, getReviews, 400)

	const closeReplyReviewModal = () => {
		closeModal(setIsReplyReviewModalActive, setIsReplyReviewModalVisible)
	}

	const closeAppealReviewModal = () => {
		closeModal(setIsAppealReviewModalActive, setIsAppealReviewModalVisible)
	}

	useEffect(() => {
		if (shop && isReviewsViewAvailable) {
			getReviews()
			getReviewsStats()
			getGeneralSettings()
		}
	}, [shop, pageSize, currentPage])

	if (isFirstLoading) {
		return <PageLoading />
	}

	if (!isReviewsViewAvailable)
		return (
			<NoDataComponent
				imageSrc={noReviewsImage}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)

	if (!reviews?.length && !searchValue) {
		return (
			<NoDataComponent
				imageSrc={noReviewsImage}
				title={'No Reviews'}
				description={'When a customer leaves a review for an order, it will appear here.'}
			/>
		)
	}

	return (
		<PageContainer>
			{isReplyReviewModalActive && (
				<Modal
					closeModal={closeReplyReviewModal}
					visible={isReplyReviewModalVisible}
					setVisible={setIsReplyReviewModalVisible}
				>
					<ReplyToReviewModal
						reviewId={usingReviewId}
						getReviews={getReviews}
						onClose={closeReplyReviewModal}
					/>
				</Modal>
			)}

			{isAppealReviewModalActive && (
				<Modal
					closeModal={closeAppealReviewModal}
					visible={isAppealReviewModalVisible}
					setVisible={setIsAppealReviewModalVisible}
				>
					<AppealReviewModal
						reviewId={usingReviewId}
						getReviews={getReviews}
						onClose={closeAppealReviewModal}
					/>
				</Modal>
			)}

			<PageWrapper>
				<div className={styles.reviewsPage}>
					<PageHeader title={'Reviews'}>
						<div className={styles.headerEnd}>
							<SearchInput
								value={searchValue}
								setValue={setSearchValue}
								style={SearchInputStyles.secondary}
								placeholder={'Search for a review'}
							/>
						</div>
					</PageHeader>

					<Section title={'Average Score'}>
						{reviewsStats && <AverageRatingStatistics reviewsStats={reviewsStats} />}
					</Section>

					{isReviewsModifyAvailable && (
						<div className={styles.mtMediumLarge}>
							<Section title={'Review Add-Ons'}>
								<div className={styles.switchWrapper}>
									<div className={styles.switchWrapperHead}>
										<h2>
											Automated Reviews <span>(recommended)</span>
										</h2>
										<p>
											Our system will add a positive review to your store if customers don’t leave a
											review within 7 days.
										</p>
									</div>
									<Switch
										checked={isAutomatedReviewsEnabled}
										setChecked={checked => {
											if (user?.subscription.perks.autoReviews) {
												setIsAutomatedReviewsEnabled(checked)
											} else {
												setIsSubscriptionModalActive(true)
												setRequiredSubscription(Subscriptions.SCALE)
											}
										}}
									/>
								</div>
								{settingsGeneral?.shopSettings.isAutomatedReviewsEnabled !==
									isAutomatedReviewsEnabled && (
									<div className={styles.mtMediumLarge}>
										<Button
											style={ButtonStyles.BORDERED}
											icon={{
												id: 'download',
												height: 13,
												width: 13,
												align: ButtonsIconAlign.LEFT,
											}}
											disableShadow={true}
											onClick={updateAutomatedReviews}
											isLoading={isUpdateAutomatedReviewsLoading}
										>
											Save Settings
										</Button>
									</div>
								)}
							</Section>
						</div>
					)}

					<PageTableHeader
						listItemNamePlural={'reviews'}
						totalItems={totalItems}
						pageSize={pageSize}
						setPageSize={setPageSize}
						setCurrentPage={setCurrentPage}
					/>

					{isGetReviewsLoading ? (
						<Spinner />
					) : (
						reviews && (
							<div className={styles.tableWrapper}>
								<ReviewsTable
									isReviewsModifyAvailable={isReviewsModifyAvailable}
									reviews={reviews}
									onReplyReviewClick={id => {
										setIsReplyReviewModalActive(true)
										setUsingReviewId(id)
									}}
									onAppealReviewClick={id => {
										setIsAppealReviewModalActive(true)
										setUsingReviewId(id)
									}}
								/>
							</div>
						)
					)}
					<div className={styles.paginationWrapper}>
						<Pagination
							withoutPageSizeSelector={true}
							totalItems={totalItems}
							pageSize={pageSize}
							setPageSize={setPageSize}
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
						/>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default ReviewsPage
