import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import styles from './PostsPage.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import SearchInput, { SearchInputStyles } from '../../components/UI/SearchInput/SearchInput'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import Pagination from '../../components/UI/Pagination/Pagination'
import PostsTable from '../../components/Tables/PostsTable'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../../hooks/useAppContext'
import { useAPI } from '../../hooks/useAPI'
import { AnnouncementsService } from '../../API/AnnouncementsService'
import Spinner from '../../components/UI/Spinner/Spinner'
import { Announcement } from '../../types/Announcement/Announcement'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import noPostsImage from '../../assets/images/noPosts.png'
import Modal from '../../components/Modals/Modal'
import { closeModal } from '../../helpers/closeModal'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import PageTableHeader from '../../components/PageTableHeader/PageTableHeader'
import PostCard from '../../components/PostCard/PostCard'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import { useDebounce } from '../../hooks/useDebounce'
import { usePageTitle } from '../../hooks/usePageTitle'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

const PostsPage = () => {
	const [searchValue, setSearchValue] = useState('')
	const [totalItems, setTotalItems] = useState(0)
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const navigate = useNavigate()
	const { shop } = useAppContext()
	const [isDeleteModalActive, setIsDeleteModalActive] = useState(false)
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
	const [currentPostId, setCurrentPostId] = useState<number | null>(null)
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isContentViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.CONTENT_VIEW) || isAdmin
	const isContentModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.CONTENT_MODIFY) || isAdmin
	const isContentDeleteAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.CONTENT_DELETE) || isAdmin
	usePageTitle(`Posts`)

	const [isGetAnnouncementsLoading, announcements, getAnnouncements] = useAPI<Announcement[]>(
		() => AnnouncementsService.getAll(shop?.id || '', searchValue, currentPage + 1, pageSize),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	const isFirstLoading = useFirstLoading(isGetAnnouncementsLoading)
	useDebounce(searchValue, getAnnouncements, 400)

	const [isDeleteAnnouncementLoading, , deleteAnnouncement] = useAPI(
		() => AnnouncementsService.delete(shop?.id || '', currentPostId),
		false,
		'Post successfully deleted',
		{
			onSuccess: () => {
				getAnnouncements()
				closeDeleteModal()
			},
		}
	)

	const onDeletePostClickHandler = (id: number) => {
		setCurrentPostId(id)
		setIsDeleteModalActive(true)
	}

	const closeDeleteModal = () => {
		setCurrentPostId(null)
		closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)
	}

	useEffect(() => {
		if (shop && isContentViewAvailable) {
			getAnnouncements()
		}
	}, [shop, currentPage, pageSize])

	if (isFirstLoading) return <PageLoading />

	if (!isContentViewAvailable)
		return (
			<NoDataComponent
				imageSrc={noPostsImage}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)

	if (!announcements?.length && !searchValue) {
		return (
			<NoDataComponent
				imageSrc={noPostsImage}
				title={'No Posts'}
				description={'You don’t have any posts. To create a post, tap on the button below.'}
				button={
					isContentModifyAvailable
						? {
								icon: {
									id: 'plus',
									height: 13,
									width: 13,
									align: ButtonsIconAlign.LEFT,
								},
								onClick: () => navigate('/content/posts/create'),
								text: 'Create Post',
						  }
						: null
				}
			/>
		)
	}

	return (
		<PageContainer>
			{isDeleteModalActive && (
				<Modal
					closeModal={closeDeleteModal}
					visible={isDeleteModalVisible}
					setVisible={setIsDeleteModalVisible}
				>
					<ConfirmModal
						title={'Delete Post'}
						description={`You are about to delete ${
							announcements?.find(announcement => announcement.id === currentPostId)?.title
						}. Are you sure you want to delete this post? This cannot be undone.`}
						onConfirm={deleteAnnouncement}
						confirmButtonText={'Delete'}
						isButtonLoading={isDeleteAnnouncementLoading}
						onClose={closeDeleteModal}
						buttonIcon={{
							id: 'trashBin',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
					/>
				</Modal>
			)}
			<PageWrapper>
				<div className={styles.blacklistPage}>
					<PageHeader title={'Posts'}>
						<div className={styles.headerEnd}>
							<SearchInput
								style={SearchInputStyles.secondary}
								value={searchValue}
								setValue={setSearchValue}
								placeholder={'Search for a post'}
							/>
							{/*<Button*/}
							{/*	style={ButtonStyles.SECONDARY}*/}
							{/*	icon={{*/}
							{/*		id: 'download',*/}
							{/*		height: 13,*/}
							{/*		width: 13,*/}
							{/*		align: ButtonsIconAlign.LEFT,*/}
							{/*	}}*/}
							{/*	onClick={() => navigate('create')}*/}
							{/*>*/}
							{/*	Export CSV*/}
							{/*</Button>*/}
							{isContentModifyAvailable && (
								<Button
									style={ButtonStyles.SECONDARY}
									icon={{
										id: 'plus',
										height: 13,
										width: 13,
										align: ButtonsIconAlign.LEFT,
									}}
									onClick={() => navigate('create')}
								>
									New Post
								</Button>
							)}
						</div>
					</PageHeader>

					<PageTableHeader
						listItemNamePlural={'posts'}
						pageSize={pageSize}
						setPageSize={setPageSize}
						totalItems={totalItems}
						setCurrentPage={setCurrentPage}
					/>

					{isGetAnnouncementsLoading ? (
						<Spinner />
					) : (
						announcements && (
							<div className={styles.posts}>
								{announcements.map(announcement => (
									<PostCard
										announcement={announcement}
										key={announcement.id}
										onDeleteClick={onDeletePostClickHandler}
										isContentDeleteAvailable={isContentDeleteAvailable}
										isContentModifyAvailable={isContentModifyAvailable}
									/>
								))}
								{/*<PostsTable*/}
								{/*	announcements={announcements}*/}
								{/*	onDeletePostClick={onDeletePostClickHandler}*/}
								{/*/>*/}
							</div>
						)
					)}

					<div className={styles.paginationWrapper}>
						<Pagination
							withoutPageSizeSelector={true}
							totalItems={totalItems}
							pageSize={pageSize}
							setPageSize={setPageSize}
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
						/>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default PostsPage
