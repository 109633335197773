export function splitImageNameAndExtension(filename: string): [string, string] {
	const lastDotIndex = filename.lastIndexOf('.')
	if (lastDotIndex === -1) {
		// If there's no dot (.), assume the extension is empty.
		return [filename, '']
	}

	const imageName = filename.substring(0, lastDotIndex)
	const imageExtension = filename.substring(lastDotIndex + 1)

	return [imageName, imageExtension]
}
