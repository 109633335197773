import React, { FC, useState } from 'react'
import styles from './ImagePicker.module.scss'
import Modal from '../Modals/Modal'
import SelectImageModal from '../Modals/SelectImageModal/SelectImageModal'
import { closeModal } from '../../helpers/closeModal'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { Image } from '../../types/Image/Image'
import ImageViewer from '../UI/ImageViewer/ImageViewer'
import ConfirmModal from '../Modals/ConfirmModal/ConfirmModal'
import { ButtonsIconAlign } from '../UI/Button/Button'

interface ImagePickerProps {
	setImages: (images: Image[]) => void
	images?: (Image | null)[]
	style?: 'secondary'
}

const ImagePicker: FC<ImagePickerProps> = ({ setImages, images, style }) => {
	const [isModalActive, setIsModalActive] = useState(false)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [isDeleteModalActive, setIsDeleteModalActive] = useState(false)
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

	const onDeleteClickHandler = () => {
		setImages([])
		closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)
	}

	return (
		<>
			{isModalActive && (
				<Modal
					closeModal={() => closeModal(setIsModalActive, setIsModalVisible)}
					visible={isModalVisible}
					setVisible={setIsModalVisible}
				>
					<SelectImageModal
						onClose={() => closeModal(setIsModalActive, setIsModalVisible)}
						onSelectImages={setImages}
					/>
				</Modal>
			)}
			{isDeleteModalActive && (
				<Modal
					closeModal={() => closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)}
					visible={isDeleteModalVisible}
					setVisible={setIsDeleteModalVisible}
				>
					<ConfirmModal
						onClose={() => closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)}
						title={'Remove Image'}
						description={
							'You are about to remove this image. Are you sure you want to remove this image?'
						}
						onConfirm={onDeleteClickHandler}
						buttonIcon={{
							id: 'trashBin',
							height: 12,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						confirmButtonText={'Remove Image'}
					/>
				</Modal>
			)}
			{images && images[0] ? (
				<ImageViewer path={images[0]?.cfId} onDeleteClick={() => setIsDeleteModalActive(true)} />
			) : (
				<div
					className={`${styles.imagePicker} ${style && styles[style]}`}
					onClick={() => setIsModalActive(true)}
				>
					<>
						<header className={styles.header}>
							<SpriteIcon iconId={'image'} width={12} height={12} />
							<p>
								{style === 'secondary'
									? 'Add Image (tap to select an image from your content gallery)'
									: 'Add Image'}
							</p>
						</header>
						<span>Tap to select an image from your content gallery.</span>
					</>
				</div>
			)}
		</>
	)
}

export default ImagePicker
