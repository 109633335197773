import React, { FC } from 'react'
import styles from './TicketMessage.module.scss'
import { TicketMessage as TicketMessageType } from '../../types/TicketMessage/TicketMessage'
import { format, formatDistanceToNow } from 'date-fns'
import { cleanEmail } from '../../helpers/cleanEmail'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'

interface TicketMessageProps {
	message: TicketMessageType
	customerEmail: string
	shopName: string
}

const TicketMessage: FC<TicketMessageProps> = ({ message, customerEmail, shopName }) => {
	const cleanedEmail = cleanEmail(customerEmail)

	return (
		<div className={`${styles.ticketMessage} ${message.isSellerReply && styles.isSellers}`}>
			<div className={styles.messageWrapper}>
				<p>{message.content}</p>
				<time>
					{format(new Date(message.createdAt), 'dd/MM/yyyy')} at{' '}
					{format(new Date(message.createdAt), 'hh:mm:ss aa')}
				</time>
			</div>
			{!message.isSellerReply ? (
				<div className={`${styles.author} ${styles.reverse}`}>
					<div className={styles.ava}>
						{customerEmail[0]}
						{cleanedEmail[cleanedEmail.length - 1]}
					</div>
				</div>
			) : (
				<div className={styles.author}>
					<span>{shopName}</span>
					<div className={styles.ava}>
						<SpriteIcon iconId={'profile'} width={14} height={14} />
					</div>
				</div>
			)}
		</div>
	)
}

export default TicketMessage
