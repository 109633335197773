import React, { useEffect } from 'react'
import styles from '../AuthPages.module.scss'
import { AuthService } from '../../../API/AuthService'
import Spinner from '../../../components/UI/Spinner/Spinner'
import { useAPI } from '../../../hooks/useAPI'
import { usePageTitle } from '../../../hooks/usePageTitle'

const AuthGoogle = () => {
	const locationHref = decodeURIComponent(window.location.href)
	const code = locationHref.split('?')[1].split('&')[0].slice(5)
	usePageTitle('Google Auth')

	const [] = useAPI<{ accessToken: string }>(
		() =>
			AuthService.loginGoogle({
				code,
				redirectUrl: `${
					window.location.hostname === 'localhost'
						? 'http://localhost:3000'
						: 'https://dash.billgang.com'
				}/auth/google/callback`,
			}),
		true,
		'Successfully sign in',
		{
			onSuccess: response => {
				localStorage.setItem('accessToken', response.data.data.accessToken)
				window.close()
			},
		}
	)

	return (
		<div className={styles.authSideInner}>
			<Spinner />
		</div>
	)
}

export default AuthGoogle
