import { FC, useEffect, useState } from 'react'
import styles from './DiscordSettings.module.scss'
import Checkbox from '../UI/Checkbox/Checkbox'
import CustomSelect, { SelectStyles } from '../UI/CustomSelect/CustomSelect'
import CustomMultiSelect from '../UI/CustomMultiSelect/CustomMultiSelect'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { DiscordGuildWithRoles } from '../../types/DiscordGuildWithRoles/DiscordGuildWithRoles'
import { DiscordGuild } from '../../types/DiscordGuild/DiscordGuild'
import { DiscordGuildRole } from '../../types/DiscordGuildRole/DiscordGuildRole'
import { useAppContext } from '../../hooks/useAppContext'
import { useAPI } from '../../hooks/useAPI'
import { DiscordUserService } from '../../API/DiscordUserService'
import { Subscriptions } from '../../types/Subscriptions/Subscriptions'
import { DiscordSettingVariants } from '../../types/DiscordSettings/DiscordSettingVariants'
import { User } from '../../types/User/User'
import { NavLink } from 'react-router-dom'

interface DiscordSettingProps {
	title: string
	isEnabled: boolean
	setIsEnabled: (value: boolean) => void
	data: DiscordGuildWithRoles | null
	setData: (value: DiscordGuildWithRoles | null) => void
	guilds: DiscordGuild[] | undefined
	currentVariantIndex?: string
	type: DiscordSettingVariants
}

const subscriptionRequirementMap = {
	[DiscordSettingVariants.BEFORE_PURCHASE_REQUIRE_ROLES]: Subscriptions.BUSINESS,
	[DiscordSettingVariants.BEFORE_PURCHASE_ADD_USER]: Subscriptions.CORE_PLUS,
	[DiscordSettingVariants.AFTER_PURCHASE_ADD_USER]: Subscriptions.CORE_PLUS,
}

const hasValidSubscription = (type: DiscordSettingVariants, user: User): boolean => {
	switch (type) {
		case DiscordSettingVariants.BEFORE_PURCHASE_REQUIRE_ROLES:
			return !!user?.subscription.perks.discordCheckRolesBeforePurchase
		case DiscordSettingVariants.BEFORE_PURCHASE_ADD_USER:
		case DiscordSettingVariants.AFTER_PURCHASE_ADD_USER:
			return !!user?.subscription.perks.discordStandardCluster
		default:
			return false
	}
}

const DiscordSetting: FC<DiscordSettingProps> = ({
	title,
	isEnabled,
	setIsEnabled,
	data,
	setData,
	guilds,
	currentVariantIndex,
	type,
}) => {
	const { shop, user, setIsSubscriptionModalActive, setRequiredSubscription } = useAppContext()
	const [guildRoles, setGuildRoles] = useState<DiscordGuildRole[]>()
	const [showInviteBotButton, setShowInviteBotButton] = useState<boolean>(false)
	const [isInvalidBotToken, setIsInvalidBotToken] = useState<boolean>(false)
	const [currentClientId, setCurrentClientId] = useState<string | null>(null)

	const checkSubscription = () => {
		const requiredSubscription = subscriptionRequirementMap[type]
		if (user && hasValidSubscription(type, user)) {
			return true
		}
		setIsSubscriptionModalActive(true)
		setRequiredSubscription(requiredSubscription)
		return false
	}

	const onDiscordInviteBotClick = () => {
		window.open(
			`https://discord.com/api/oauth2/authorize?client_id=${currentClientId}&permissions=8&scope=bot%20applications.commands`,
			'_blank',
			'noopener'
		)
	}

	const [, , fetchGuild] = useAPI<{ roles: DiscordGuildRole[] }>(
		fetchData =>
			DiscordUserService.getGuild(fetchData.discordGuildId, shop?.id || '')
				.then(response => {
					setGuildRoles(response.data.data.roles)
					setShowInviteBotButton(false)
					setIsInvalidBotToken(false)
				})
				.catch(reason => {
					const status = reason.response.status
					switch (status) {
						case 404:
							setShowInviteBotButton(true)
							setCurrentClientId(reason.response.data?.data.applicationId)
							break
						case 401:
						case 400:
							setShowInviteBotButton(true)
							setIsInvalidBotToken(true)
							break
						default:
							setShowInviteBotButton(false)
							setIsInvalidBotToken(false)
							break
					}
				}),
		false,
		undefined
	)

	useEffect(() => {
		if (data?.guildId) {
			fetchGuild({
				discordGuildId: data.guildId,
				type: title,
			})
		}
	}, [data?.guildId, title])

	useEffect(() => {
		setShowInviteBotButton(false)
		setIsInvalidBotToken(false)
	}, [guildRoles])

	const rolesSelectOptions =
		guildRoles?.map(role => ({
			value: role.id,
			label: role.name,
		})) || []

	return (
		<div className={styles.discordSetting}>
			<div className={styles.checkboxWrapper}>
				<Checkbox
					id={`${title}${currentVariantIndex}`}
					checked={isEnabled}
					setChecked={checked => {
						if (checkSubscription()) {
							setIsEnabled(checked)
						}
					}}
				/>
				<p>{title}</p>
			</div>
			{isEnabled && (
				<div className={styles.doubleFields}>
					<div className={styles.fieldWrapper}>
						<p className={styles.fieldTitle}>Choose Server</p>
						<CustomSelect
							style={SelectStyles.secondary}
							value={data?.guildId || ''}
							setValue={value => {
								setData({
									roleIds: [],
									guildId: value,
								})
							}}
							options={[
								{
									value: '',
									label: 'Choose server',
								},
								...(guilds?.map(guild => ({ value: guild.id, label: guild.name })) || []),
							]}
						/>
					</div>
					<div className={`${styles.fieldWrapper} ${styles.fromBottom}`}>
						{showInviteBotButton ? (
							isInvalidBotToken ? (
								<NavLink
									className={styles.link}
									to='/discord-bot/builder'
									target='_blank'
									rel='noopener noreferrer'
								>
									<Button
										style={ButtonStyles.BORDERED}
										disableShadow={true}
										icon={{
											id: 'discord',
											height: 11,
											width: 15,
											align: ButtonsIconAlign.LEFT,
										}}
									>
										You need to create Discord Bot first
									</Button>
								</NavLink>
							) : (
								<Button
									style={ButtonStyles.BORDERED}
									disableShadow={true}
									icon={{
										id: 'discord',
										height: 11,
										width: 15,
										align: ButtonsIconAlign.LEFT,
									}}
									onClick={onDiscordInviteBotClick}
								>
									Invite Discord Bot
								</Button>
							)
						) : (
							<>
								<p className={styles.fieldTitle}>Role(s)</p>
								<CustomMultiSelect
									options={rolesSelectOptions}
									value={data?.roleIds || []}
									setValue={newValue => {
										setData({
											guildId: data?.guildId || '',
											roleIds: newValue,
										})
									}}
								/>
							</>
						)}
					</div>
				</div>
			)}
		</div>
	)
}

export default DiscordSetting
