import React, { FC } from 'react'
import styles from './JsonFileInput.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

interface JsonFileInputProps {
	file: File | null
	setFile: (value: File | null) => void
}
const JsonFileInput: FC<JsonFileInputProps> = ({ setFile, file }) => {
	return (
		<label className={styles.jsonFileInput}>
			<SpriteIcon iconId={'cloudUpload'} width={17} height={12} />
			{file ? file.name : 'Add File (tap to upload raw JSON file)'}
			<input
				type='file'
				accept={'application/JSON'}
				onChange={event => setFile(event.target.files ? event.target.files[0] : null)}
			/>
		</label>
	)
}

export default JsonFileInput
