import styles from '../DiscordBotBuilderPage.module.scss'
import DiscordBotStatus from '../../../components/UI/StatusBadges/DiscordBotStatus'
import { DiscordClusterBotStatus } from '../../../types/DiscordClusterBotStatus/DiscordClusterBotStatus'
import ActionMenu, { ActionMenuStyles } from '../../../components/UI/ActionMenu/ActionMenu'
import React, { FC } from 'react'
import { SpriteIcon } from '../../../components/UI/SpriteIcon/SpriteIcon'
import { GetDiscordClusterBot } from '../../../types/GetDiscordCluster/GetDiscordCluster'
import { useCopy } from '../../../hooks/useCopy'
import { ActionMenuOption } from '../../../types/ActionMenuOption/ActionMenuOption'

interface ReadyBotProps {
	botData: GetDiscordClusterBot
	botType: 'primary' | 'secondary'
	onEditClick: () => void
	onDeleteClick: () => void
}

const ReadyBot: FC<ReadyBotProps> = ({ botData, botType, onEditClick, onDeleteClick }) => {
	const [isActionMenuActive, setIsActionMenuActive] = React.useState<boolean>(false)
	const copy = useCopy()

	const actionButtons: ActionMenuOption[] = [
		{
			onClick: onEditClick,
			icon: {
				id: 'pen',
				height: 14,
				width: 14,
			},
			title: 'Edit Bot',
		},
		{
			onClick: onDeleteClick,
			icon: {
				id: 'trashBin',
				height: 14,
				width: 14,
			},
			title: 'Delete Bot',
		},
	]

	return (
		<div className={`${styles.readyBot}`}>
			<div className={styles.readyBotContent}>
				<div className={styles.readyBotHead}>
					<div className={styles.readyBotLogo}>
						<img src={botData.avatar} alt={botData.username} />
					</div>
					<div>
						<h2 className={styles.readyBotName}>{botData.username}</h2>
						<div className={`${styles.readyBotInfo} ${styles.mt5}`}>
							<p className={styles.secondaryText}>
								{botType === 'secondary' ? 'Secondary' : 'Primary'} Bot
							</p>
							<p>•</p>
							<DiscordBotStatus status={botData.botStatus} />
						</div>
					</div>
				</div>

				<div className={styles.readyBotContentEnd}>
					<div
						className={styles.wrappedLink}
						onClick={() =>
							copy(
								`https://discord.com/api/oauth2/authorize?client_id=${botData.clientId}&permissions=8&scope=bot%20applications.commands`
							)
						}
					>
						<SpriteIcon iconId={'chain'} height={14} width={14} />
						<p
							className={`${styles.secondaryText} ${styles.textOverflowEllipses}`}
						>{`https://discord.com/api/oauth2/authorize?client_id=${botData.clientId}&permissions=8&scope=bot%20applications.commands`}</p>
					</div>
					<ActionMenu
						style={ActionMenuStyles.third}
						actionButtons={actionButtons}
						isActive={isActionMenuActive}
						setIsActive={setIsActionMenuActive}
					/>
				</div>
			</div>
		</div>
	)
}

export default ReadyBot
