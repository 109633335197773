import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import styles from './WarehousePage.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import SearchInput, { SearchInputStyles } from '../../components/UI/SearchInput/SearchInput'
import Pagination from '../../components/UI/Pagination/Pagination'
import StockGroupsTable from '../../components/Tables/StockGroupsTable'
import Modal from '../../components/Modals/Modal'
import { closeModal } from '../../helpers/closeModal'
import CreateStockGroupModal from '../../components/Modals/CreateStockGroupModal/CreateStockGroupModal'
import { useAPI } from '../../hooks/useAPI'
import { StockSystemService } from '../../API/StockSystemService'
import { useAppContext } from '../../hooks/useAppContext'
import Spinner from '../../components/UI/Spinner/Spinner'
import { StockGroup } from '../../types/StockGroup/StockGroup'
import PageTableHeader from '../../components/PageTableHeader/PageTableHeader'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import noWarehouses from '../../assets/images/noWarehouses.png'
import { usePageTitle } from '../../hooks/usePageTitle'
import { useDebounce } from '../../hooks/useDebounce'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import DeleteWarehouseModal from '../../components/Modals/DeleteWarehouseModal/DeleteWarehouseModal'

const WarehousePage = () => {
	const [searchValue, setSearchValue] = useState('')
	const [totalItems, setTotalItems] = useState(23)
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const { shop } = useAppContext()
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isStockSystemViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.STOCK_SYSTEMS_VIEW) || isAdmin
	const isStockSystemModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.STOCK_SYSTEMS_MODIFY) || isAdmin
	const isStockSystemDeleteAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.STOCK_SYSTEMS_DELETE) || isAdmin

	const [isCreateStockGroupModalActive, setIsCreateStockGroupModalActive] = useState(false)
	const [isCreateStockGroupModalVisible, setIsCreateStockGroupModalVisible] = useState(false)
	const [isDeleteStockGroupModalActive, setIsDeleteStockGroupModalActive] = useState(false)
	const [isDeleteStockGroupModalVisible, setIsDeleteStockGroupModalVisible] = useState(false)
	const [deletingWarehouse, setDeletingWarehouse] = useState<StockGroup | null>(null)
	usePageTitle(`Digital Warehouse`)

	const [isGetWarehousesLoading, warehouses, getWarehouses] = useAPI<StockGroup[]>(
		() => StockSystemService.getWarehouses(shop?.id || '', searchValue, currentPage + 1, pageSize),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	useDebounce(searchValue, getWarehouses, 400)
	const isFirstLoading = useFirstLoading(isGetWarehousesLoading)

	const onDeleteClickHandler = (warehouse: StockGroup) => {
		setDeletingWarehouse(warehouse)
		setIsDeleteStockGroupModalActive(true)
	}

	useEffect(() => {
		if (shop && isStockSystemViewAvailable) {
			getWarehouses()
		}
	}, [shop, currentPage, pageSize])

	if (isFirstLoading) {
		return <PageLoading />
	}

	if (!isStockSystemViewAvailable) {
		return (
			<NoDataComponent
				imageSrc={noWarehouses}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)
	}

	return (
		<PageContainer>
			{isCreateStockGroupModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsCreateStockGroupModalActive, setIsCreateStockGroupModalVisible)
					}
					visible={isCreateStockGroupModalVisible}
					setVisible={setIsCreateStockGroupModalVisible}
				>
					<CreateStockGroupModal
						getWarehouses={getWarehouses}
						onClose={() =>
							closeModal(setIsCreateStockGroupModalActive, setIsCreateStockGroupModalVisible)
						}
					/>
				</Modal>
			)}
			{isDeleteStockGroupModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsDeleteStockGroupModalActive, setIsDeleteStockGroupModalVisible)
					}
					visible={isDeleteStockGroupModalVisible}
					setVisible={setIsDeleteStockGroupModalVisible}
				>
					<DeleteWarehouseModal
						deletingWarehouse={deletingWarehouse}
						onSuccess={getWarehouses}
						onClose={() =>
							closeModal(setIsDeleteStockGroupModalActive, setIsDeleteStockGroupModalVisible)
						}
					/>
				</Modal>
			)}
			<PageWrapper>
				{!warehouses?.length && !searchValue ? (
					<NoDataComponent
						imageSrc={noWarehouses}
						title={'No Warehouses'}
						description={'To create a stock warehouse for serials, tap on the button below.'}
						button={
							isStockSystemModifyAvailable
								? {
										icon: {
											id: 'plus',
											height: 13,
											width: 13,
											align: ButtonsIconAlign.LEFT,
										},
										onClick: () => setIsCreateStockGroupModalActive(true),
										text: 'New Warehouse',
								  }
								: null
						}
					/>
				) : (
					<div className={styles.warehousePage}>
						<PageHeader title={'Digital Warehouse'}>
							<div className={styles.headerEnd}>
								<SearchInput
									value={searchValue}
									setValue={setSearchValue}
									style={SearchInputStyles.secondary}
									placeholder={'Search for warehouse by name or id'}
								/>
								{isStockSystemModifyAvailable && (
									<Button
										style={ButtonStyles.SECONDARY}
										icon={{
											id: 'plus',
											height: 13,
											width: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										onClick={() => setIsCreateStockGroupModalActive(true)}
									>
										New Warehouse
									</Button>
								)}
							</div>
						</PageHeader>

						<PageTableHeader
							pageSize={pageSize}
							setPageSize={setPageSize}
							totalItems={totalItems}
							listItemNamePlural={'warehouses'}
							setCurrentPage={setCurrentPage}
						/>

						{isGetWarehousesLoading ? (
							<Spinner />
						) : (
							warehouses && (
								<div className={styles.warehouseTable}>
									<StockGroupsTable
										onDeleteClick={onDeleteClickHandler}
										warehouses={warehouses}
										isStockSystemModifyAvailable={isStockSystemModifyAvailable}
										isStockSystemDeleteAvailable={isStockSystemDeleteAvailable}
									/>
								</div>
							)
						)}

						<div className={styles.paginationWrapper}>
							<Pagination
								withoutPageSizeSelector={true}
								totalItems={totalItems}
								pageSize={pageSize}
								setPageSize={setPageSize}
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
							/>
						</div>
					</div>
				)}
			</PageWrapper>
		</PageContainer>
	)
}

export default WarehousePage
