import React, { FC } from 'react'
import styles from '../../OrderDetailedPage.module.scss'
import OrangeBadge from '../../../../components/UI/OrangeBadge/OrangeBadge'
import { DetailedOrder } from '../../../../types/DetailedOrder/DetailedOrder'
import { SpriteIcon } from '../../../../components/UI/SpriteIcon/SpriteIcon'
import OrderChargeStatusBadge from '../../../../components/UI/StatusBadges/OrderChargeStatusBadge'
import UpdatedOnDateBadge from '../../../../components/UI/UpdatedOnDateBadge/UpdatedOnDateBadge'

interface OrdersCustomerInformationProps {
	order: DetailedOrder
}

const OrdersCustomerInformation: FC<OrdersCustomerInformationProps> = ({ order }) => {
	const riskScore = order.charge.customerForCharge?.deviceInfo?.ipDetails.riskScore || 0

	return (
		<div className={styles.section}>
			<header className={styles.header}>
				<h1>Shield Information</h1>
				<UpdatedOnDateBadge date={new Date()} />
			</header>

			<div className={styles.dataItem}>
				<div className={styles.dataItemHead}>
					<div className={styles.icon}>
						<SpriteIcon iconId={'wrench'} width={20} height={20} />
					</div>
					<div>
						<h2 className={styles.dataTitle}>
							{order.charge.customerForCharge?.deviceInfo?.ipDetails?.ip}
						</h2>
						<p className={styles.dataSubtitle}>IP Address</p>
					</div>
				</div>
			</div>

			<div className={styles.dataItem}>
				<div className={styles.dataItemHead}>
					<div className={styles.icon}>
						<SpriteIcon iconId={'wifi'} width={20} height={15} />
					</div>
					<div>
						<h2 className={styles.dataTitle}>
							{order.charge.customerForCharge?.deviceInfo?.ipDetails.isp}
						</h2>
						<p className={styles.dataSubtitle}>Internet Service Provider</p>
					</div>
				</div>
			</div>

			<div className={styles.dataItem}>
				<div className={styles.dataItemHead}>
					<div className={styles.icon}>
						<SpriteIcon iconId={'checkedShield'} width={20} height={20} />
					</div>
					<div>
						<h2 className={styles.dataTitle}>
							{order.charge.customerForCharge?.deviceInfo?.ipDetails.riskScore}/100
						</h2>
						<p className={styles.dataSubtitle}>Risk Score</p>
					</div>
				</div>

				<div className={styles.brown}>
					{riskScore > 60 && <SpriteIcon iconId={'exclamationMarkCircle'} width={16} height={16} />}
				</div>
			</div>

			<div className={styles.dataItem}>
				<div className={styles.dataItemHead}>
					<div className={styles.icon}>
						<SpriteIcon iconId={'flag'} width={20} height={20} />
					</div>
					<div>
						<h2 className={styles.dataTitle}>
							{order.charge.customerForCharge?.deviceInfo?.ipDetails.country}
						</h2>
						<p className={styles.dataSubtitle}>Country</p>
					</div>
				</div>
			</div>
			{/*<div className={styles.dataItem}>*/}
			{/*	<div className={styles.dataItemHead}>*/}
			{/*		<div className={styles.icon}>*/}
			{/*			<SpriteIcon iconId={'web'} width={20} height={20} />*/}
			{/*		</div>*/}
			{/*		<div>*/}
			{/*			<h2 className={styles.dataTitle}>{order.charge.customerForCharge?.deviceInfo.ipDetails.}</h2>*/}
			{/*			<p className={styles.dataSubtitle}>VPN</p>*/}
			{/*		</div>*/}
			{/*	</div>*/}

			{/*	<div className={styles.brown}>*/}
			{/*		<SpriteIcon iconId={'exclamationMarkCircle'} width={16} height={16} />*/}
			{/*	</div>*/}
			{/*</div>*/}

			{/*<div className={styles.dataItem}>*/}
			{/*	<div className={styles.dataItemHead}>*/}
			{/*		<div className={styles.icon}>*/}
			{/*			<SpriteIcon iconId={'rhombusExclamationMark'} width={20} height={20} />*/}
			{/*		</div>*/}
			{/*		<div>*/}
			{/*			<h2 className={styles.dataTitle}>{'Disabled'}</h2>*/}
			{/*			<p className={styles.dataSubtitle}>Proxy</p>*/}
			{/*		</div>*/}
			{/*	</div>*/}

			{/*	<div className={styles.green}>*/}
			{/*		<SpriteIcon iconId={'checkedCircle'} width={16} height={16} />*/}
			{/*	</div>*/}
			{/*</div>*/}
		</div>
	)
}

export default OrdersCustomerInformation
