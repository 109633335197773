import React, { FC, useRef, useState } from 'react'
import styles from './FAQ.module.scss'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import { FAQ as FAQType } from '../../types/FAQ/FAQ'
import { ActionMenuOption } from '../../types/ActionMenuOption/ActionMenuOption'

interface FAQProps {
	faq: FAQType
	onDeleteClickHandler: (faq: FAQType | null) => void
	onEditClickHandler: (faq: FAQType | null) => void
	isContentModifyAvailable: boolean
	isContentDeleteAvailable: boolean
}

const FAQ: FC<FAQProps> = ({
	faq,
	onDeleteClickHandler,
	onEditClickHandler,
	isContentDeleteAvailable,
	isContentModifyAvailable,
}) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)

	const actionMenuButtons: ActionMenuOption[] = []

	isContentModifyAvailable &&
		actionMenuButtons.push({
			onClick: () => onEditClickHandler(faq),
			icon: {
				id: 'pen',
				width: 12,
				height: 12,
			},
			title: 'Edit FAQ',
		})

	isContentDeleteAvailable &&
		actionMenuButtons.push({
			onClick: () => onDeleteClickHandler(faq),
			icon: {
				id: 'trashBin',
				width: 12,
				height: 12,
			},
			title: 'Delete FAQ',
		})

	return (
		<div className={styles.faqItem}>
			<div className={styles.faqItemHead}>
				<h2>{faq.question}</h2>
				<p>{faq.answer}</p>
			</div>

			<ActionMenu
				actionButtons={actionMenuButtons}
				isActive={isActionMenuActive}
				setIsActive={setIsActionMenuActive}
			/>
		</div>
	)
}

export default FAQ
