import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import Radio from '../../UI/Radio/Radio'
import { ListingVisibility } from '../../../types/ListingVisibility/ListingVisibility'
import { useAppContext } from '../../../hooks/useAppContext'
import { Gateways } from '../../../types/Gateways/Gateways'
import { useAPI } from '../../../hooks/useAPI'
import { BulkUpdateProductsService } from '../../../API/BulkUpdateProductsService'
import { BulkUpdateModalsProps } from '../../../types/BulkUpdateModalsProps/BulkUpdateModalsProps'
import RadioWrapper from '../../UI/RadioWrapper/RadioWrapper'

const UpdateVisibilityModal: FC<BulkUpdateModalsProps> = ({ onClose, productIds, getProducts }) => {
	const [visibility, setVisibility] = useState<ListingVisibility>(ListingVisibility.PUBLIC)
	const { shop } = useAppContext()

	const [isUpdateVisibilityLoading, , updateVisibility] = useAPI(
		() =>
			BulkUpdateProductsService.updateVisibility(shop?.id || '', {
				visibility: visibility,
				productIds,
			}),
		false,
		'Products successfully updated',
		{
			onSuccess: () => {
				onClose()
				getProducts()
			},
		}
	)

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Update Visibility</h1>
			</header>

			<div className={styles.body}>
				<p className={styles.description}>
					Change how customers can access or view your products before they continue with checkout.
				</p>

				<div className={styles.fieldWrapper}>
					<div className={styles.mt10}>
						<RadioWrapper
							id={'visible'}
							value={ListingVisibility.PUBLIC}
							setValue={value => setVisibility(value as ListingVisibility)}
							checked={visibility === ListingVisibility.PUBLIC}
							title={'Visible (public)'}
							description={'Available to any customer.'}
						/>
					</div>
					<div className={styles.mt10}>
						<RadioWrapper
							id={'hidden'}
							value={ListingVisibility.HIDDEN}
							setValue={value => setVisibility(value as ListingVisibility)}
							checked={visibility === ListingVisibility.HIDDEN}
							title={'HIDDEN (LINK)'}
							description={'Viewed with a direct product link.'}
						/>
					</div>
					<div className={styles.mt10}>
						<RadioWrapper
							id={'private'}
							value={ListingVisibility.PRIVATE}
							setValue={value => setVisibility(value as ListingVisibility)}
							checked={visibility === ListingVisibility.PRIVATE}
							title={'HIDDEN (PRIVATE)'}
							description={'Product cannot be viewed.'}
						/>
					</div>
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						disableShadow={true}
						icon={{
							id: 'download',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						onClick={updateVisibility}
						isLoading={isUpdateVisibilityLoading}
					>
						Save Update
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default UpdateVisibilityModal
