import React, { FC } from 'react'
import styles from '../../OrderDetailedPage.module.scss'

interface OrdersCustomFieldsProps {
	metadata: {
		[title: string]: string
	}
}

const OrdersCustomFields: FC<OrdersCustomFieldsProps> = ({ metadata }) => {
	return (
		<div className={styles.section}>
			<header className={styles.header}>
				<h1>Custom Fields</h1>
			</header>

			{Object.keys(metadata).map(key => (
				<div className={styles.titleValueWrapper}>
					<h2>“{key}”</h2>
					<p>{metadata[key]}</p>
				</div>
			))}
		</div>
	)
}

export default OrdersCustomFields
