import { privateAPI } from './API'

export const AuditLogsService = {
	getAll: async (shopId: string, searchString?: string, PageNumber?: number, PageSize?: number) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/audit-logs`, {
			params: {
				searchString,
				PageNumber,
				PageSize,
			},
		})
	},
	getSpecific: async (shopId: string, auditLogId: string) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/audit-logs/${auditLogId}`)
	},
}
