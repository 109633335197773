import { APIDocumentation } from './APIDocumentation'
import { capitalizeText } from './capitalizeText'

export function getAction(data: { method: string; path: string }) {
	for (const [endpoint, methods] of Object.entries(APIDocumentation)) {
		const regex = new RegExp('^' + endpoint.replace(/\{[^}]+\}/g, '[^/]+') + '$')
		if (regex.test(data.path)) {
			if (methods[data.method]) {
				return methods[data.method]
					.split(' ')
					.map(word => capitalizeText(word))
					.join(' ')
			}
		}
	}

	return null
}
