import React, { FC } from 'react'
import styles from '../DiscordBotBuilderPage.module.scss'
import ConnectDiscord from '../../../components/ConnectDiscord/ConnectDiscord'
import { SecuritySettings } from '../../../types/SecuritySettings/SecuritySettings'

interface ConnectDiscordStepProps {
	securitySettings?: SecuritySettings
}

const ConnectDiscordStep: FC<ConnectDiscordStepProps> = ({ securitySettings }) => {
	return (
		<div className={styles.stepWrapper}>
			<p className={styles.defaultTitle}>Discord Integration</p>
			<p className={`${styles.secondaryText} ${styles.mt10}`}>
				To continue with building a Discord bot with Billgang, you’ll need to connect your Discord
				account. Tap on the button below to get started.
			</p>

			<div className={styles.mtMediumLarge}>
				<ConnectDiscord
					style={'secondary'}
					isConnected={!!securitySettings?.authDetails.discordOauth}
				/>

				{securitySettings?.authDetails.discordOauth?.username && (
					<p className={`${styles.secondaryText} ${styles.mt10}`}>
						Connected Account: {securitySettings?.authDetails.discordOauth?.username}
					</p>
				)}
			</div>
		</div>
	)
}

export default ConnectDiscordStep
