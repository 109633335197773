import React, { FC } from 'react'
import generalStyles from '../../CreatePostPage.module.scss'
import Input from '../../../../components/UI/FormComponents/Input'
import Textarea from '../../../../components/UI/FormComponents/Textarea'
import CustomMDEditor from '../../../../components/UI/CustomMDEditor/CustomMDEditor'
import { FormErrors } from '../../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../../helpers/fieldValidators'
import RequiredBadge from '../../../../components/UI/RequiredBadge/RequiredBadge'

interface ProductGeneralProps {
	headerTitle: string
	setHeaderTitle: (value: string) => void
	subHeaderTitle: string
	setSubHeaderTitle: (value: string) => void
	buttonText: string
	setButtonText: (value: string) => void
	buttonLink: string
	setButtonLink: (value: string) => void
	setDescription: (value: string | undefined) => void
	description: string | undefined
	errors: FormErrors
	setErrors: (value: FormErrors) => void
}

const PostGeneral: FC<ProductGeneralProps> = ({
	headerTitle,
	setHeaderTitle,
	setSubHeaderTitle,
	subHeaderTitle,
	setDescription,
	description,
	buttonText,
	setButtonText,
	buttonLink,
	setButtonLink,
	setErrors,
	errors,
}) => {
	return (
		<div>
			<div className={generalStyles.fieldWrapper}>
				<p className={generalStyles.fieldTitle}>
					Header Title <RequiredBadge />
				</p>
				<Input
					value={headerTitle}
					setValue={setHeaderTitle}
					placeholder={'Header Title'}
					errorMessage={errors['headerTitle']}
					onBlur={() =>
						setErrors({
							...errors,
							headerTitle: fieldValidators.blankValidator('Header Title', headerTitle),
						})
					}
				/>
			</div>
			<div className={generalStyles.horLine} />
			<div className={generalStyles.fieldWrapper}>
				<p className={generalStyles.fieldTitle}>Subheader Title</p>
				<Textarea
					value={subHeaderTitle}
					setValue={setSubHeaderTitle}
					placeholder={'An optional, concise summary of your announcement'}
				/>
			</div>
			<div className={generalStyles.horLine} />
			<div className={generalStyles.fieldWrapper}>
				<p className={generalStyles.fieldTitle}>Post Description</p>
				<CustomMDEditor value={description} setValue={setDescription} />
			</div>
			<div className={generalStyles.horLine} />
			<div className={generalStyles.doubleFields}>
				<div className={generalStyles.fieldWrapper}>
					<p className={generalStyles.fieldTitle}>Button Text</p>
					<p className={generalStyles.fieldDescription}>
						Text that will appear on the button of this post.
					</p>
					<Input value={buttonText} setValue={setButtonText} placeholder={'Visit Us'} />
				</div>
				<div className={generalStyles.fieldWrapper}>
					<p className={generalStyles.fieldTitle}>Button Link</p>
					<p className={generalStyles.fieldDescription}>
						Send customers to an external link if the button is tapped.
					</p>
					<Input value={buttonLink} setValue={setButtonLink} placeholder={'https://google.com'} />
				</div>
			</div>
		</div>
	)
}

export default PostGeneral
