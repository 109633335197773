import React, { FC } from 'react'
import styles from './Tables.module.scss'
import Checkbox from '../UI/Checkbox/Checkbox'
import { NotificationEvent } from '../../types/NotificationEvent/NotificationEvent'

interface NotificationsTableProps {
	emailNotifications: NotificationEvent[]
	setEmailNotifications: (value: NotificationEvent[]) => void
	discordNotifications: NotificationEvent[]
	setDiscordNotifications: (value: NotificationEvent[]) => void
	telegramNotifications: NotificationEvent[]
	setTelegramNotifications: (value: NotificationEvent[]) => void
}

const NotificationsTable: FC<NotificationsTableProps> = ({
	emailNotifications,
	setDiscordNotifications,
	setTelegramNotifications,
	setEmailNotifications,
	telegramNotifications,
	discordNotifications,
}) => {
	const notificationsOptions = [
		{
			title: 'Order Completed',
			description: 'When an order is completed',
			type: NotificationEvent.ORDER_COMPLETED,
		},
		{
			title: 'Product Out of Stock',
			description: 'When a product is out of stock and requires more stock',
			type: NotificationEvent.WAREHOUSE_OUT_OF_STOCK,
		},
		// {
		// 	title: 'Product Edited',
		// 	description: 'When a product is edited from your dashboard',
		// 	type: NotificationEvent.PRODUCT_EDITED,
		// },
		// {
		// 	title: 'Product Deleted',
		// 	description: 'When a product is deleted from your dashboard',
		// 	type: ShopForUserNotificationType.PRODUCT_DELETED,
		// },
		{
			title: 'Review Posted',
			description: 'When a customer leaves a new reviews',
			type: NotificationEvent.REVIEW_CREATED,
		},
		{
			title: 'Review Edited',
			description: 'When a customer edits an already existing review',
			type: NotificationEvent.REVIEW_EDITED,
		},
		{
			title: 'Review Removed',
			description: 'When a review is removed from your store',
			type: NotificationEvent.REVIEW_APPEALED,
		},
		{
			title: 'Ticket Created',
			description: 'When a new ticket is opened by a customer',
			type: NotificationEvent.TICKET_CREATED,
		},
		{
			title: 'Ticket Reply',
			description: 'When a customer replies to a ticket',
			type: NotificationEvent.TICKET_MESSAGE_RECEIVED,
		},
	]

	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.extraLarge}>TRIGGER</th>
					<th>EMAIL</th>
					<th>DISCORD</th>
					{/*<th>TELEGRAM</th>*/}
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{notificationsOptions.map(notificationsOption => (
					<div className={styles.trWrapper}>
						<tr>
							<td className={styles.extraLarge}>
								<div className={styles.itemInfo}>
									<div>
										<p>{notificationsOption.title}</p>
										<span>{notificationsOption.description}</span>
									</div>
								</div>
							</td>
							<td>
								<Checkbox
									id={notificationsOption.title}
									checked={emailNotifications.includes(notificationsOption.type)}
									setChecked={checked => {
										if (checked) {
											setEmailNotifications([...emailNotifications, notificationsOption.type])
										} else {
											setEmailNotifications(
												emailNotifications.filter(
													notification => notification !== notificationsOption.type
												)
											)
										}
									}}
								/>
							</td>
							<td>
								<Checkbox
									id={notificationsOption.title + notificationsOption.type}
									checked={discordNotifications.includes(notificationsOption.type)}
									setChecked={checked => {
										if (checked) {
											setDiscordNotifications([...discordNotifications, notificationsOption.type])
										} else {
											setDiscordNotifications(
												discordNotifications.filter(
													notification => notification !== notificationsOption.type
												)
											)
										}
									}}
								/>
							</td>
							{/*<td>*/}
							{/*	<Checkbox*/}
							{/*		id={notificationsOption.type}*/}
							{/*		checked={telegramNotifications.includes(notificationsOption.type)}*/}
							{/*		setChecked={checked => {*/}
							{/*			if (checked) {*/}
							{/*				setTelegramNotifications([...telegramNotifications, notificationsOption.type])*/}
							{/*			} else {*/}
							{/*				setTelegramNotifications(*/}
							{/*					telegramNotifications.filter(*/}
							{/*						notification => notification !== notificationsOption.type*/}
							{/*					)*/}
							{/*				)*/}
							{/*			}*/}
							{/*		}}*/}
							{/*	/>*/}
							{/*</td>*/}
						</tr>
					</div>
				))}
			</tbody>
		</table>
	)
}

export default NotificationsTable
