import { privateAPI } from './API'
import { ShopForUserPermissions } from '../types/ShopForUserPermissions/ShopForUserPermissions'

export const ManagersInvitesService = {
	getAll: async (shopId: string) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/managers/invites`)
	},
	create: async (
		shopId: string,
		data: {
			email: string
			permissions: (ShopForUserPermissions | undefined | null)[]
		}
	) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/managers/invites`, data)
	},
	delete: async (shopId: string, inviteId: string) => {
		return await privateAPI.delete(`/v1/dash/shops/${shopId}/managers/invites/${inviteId}`)
	},
}
