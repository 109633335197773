import React, { FC } from 'react'
import styles from './FormErrorsDisplayBadge.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

interface FormErrorsDisplayBadgeProps {
	title: string
	errors: string[]
	isModal?: boolean
}

const FormErrorsDisplayBadge: FC<FormErrorsDisplayBadgeProps> = ({ title, errors, isModal }) => {
	return (
		<div className={`${styles.formErrorsDisplayBadge} ${isModal && styles.modal}`}>
			<header className={styles.header}>
				<SpriteIcon iconId={'hexagonExclamationMark'} width={16} height={16} />
				<h1 className={styles.title}>{title}</h1>
			</header>
			<div className={styles.body}>
				<ul>
					{errors.map(error => (
						<li key={error}>{error}</li>
					))}
				</ul>
			</div>
		</div>
	)
}

export default FormErrorsDisplayBadge
