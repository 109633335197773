import React, { FC } from 'react'
import styles from './SubscriptionCard.module.scss'
import Button, { ButtonStyles } from '../UI/Button/Button'
import { Subscriptions } from '../../types/Subscriptions/Subscriptions'
import { subscriptionOptions } from '../../helpers/subscriptionsOptions'

interface SubscriptionCardProps {
	subscription: Subscriptions
	isActive: boolean
	yearly: boolean
	onUpgradeClick: () => void
}

const SubscriptionCard: FC<SubscriptionCardProps> = ({
	subscription,
	isActive,
	yearly,
	onUpgradeClick,
}) => {
	const isScalePlan = subscription === Subscriptions.SCALE

	return (
		<div className={`${styles.subscriptionCard} ${isScalePlan && styles.scalePlan}`}>
			<h1 className={styles.subscriptionTitle}>{subscriptionOptions[subscription].title}</h1>
			<h2 className={styles.subscriptionPrice}>
				{yearly
					? subscriptionOptions[subscription].yearlyPrice
					: subscriptionOptions[subscription].monthlyPrice}
			</h2>

			<p className={styles.description}>{subscriptionOptions[subscription].description}</p>

			<Button
				width={'100%'}
				style={
					isActive ? ButtonStyles.BORDERED : !isScalePlan ? ButtonStyles.DARK : ButtonStyles.PRIMARY
				}
				onClick={onUpgradeClick}
			>
				{isActive ? 'Active Plan' : 'Upgrade'}
			</Button>
		</div>
	)
}

export default SubscriptionCard
