import React, { FC } from 'react'
import styles from './StatusBadges.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import { WarehouseStatus } from '../../../types/WarehouseStatus/WarehouseStatus'

interface WarehouseStatusBadgeProps {
	warehouseStatus: WarehouseStatus
}

const WarehouseStatusBadge: FC<WarehouseStatusBadgeProps> = ({ warehouseStatus }) => {
	const warehouseStatusData = {
		[WarehouseStatus.IN_STOCK]: {
			name: 'In Stock',
			className: 'green',
			iconId: 'checkedCircle',
		},
		[WarehouseStatus.LOW_STOCK]: {
			name: 'Low Stock',
			className: 'brown',
			iconId: 'exclamationMarkCircle',
		},
		[WarehouseStatus.OUT_OF_STOCK]: {
			name: 'Out Of Stock',
			className: 'red',
			iconId: 'xMarkCircle',
		},
	}

	return (
		<div
			className={`${styles.statusBadge} ${styles[warehouseStatusData[warehouseStatus].className]}`}
		>
			{warehouseStatusData[warehouseStatus].name}
			<SpriteIcon iconId={warehouseStatusData[warehouseStatus].iconId} width={16} height={16} />
		</div>
	)
}

export default WarehouseStatusBadge
