import React, { FC, useEffect, useState } from 'react'
import styles from './PriceInput.module.scss'
import CustomSelect from '../CustomSelect/CustomSelect'

interface PriceInputProps {
	currency: string
	setCurrency: (value: string) => void
	price: string
	setPrice: (value: string) => void
}

const currencies = [
	'USD',
	'EUR',
	'JPY',
	'GBP',
	'AUD',
	'CAD',
	'CHF',
	'CNY',
	'SEK',
	'NZD',
	'PLN',
	'ILS',
	'HKD',
	'ISK',
	'PHP',
	'DKK',
	'HUF',
	'CZK',
	'RON',
	'IDR',
	'INR',
	'BRL',
	'RUB',
	'HRK',
	'THB',
	'MYR',
	'BGN',
	'NOK',
	'ZAR',
	'MXN',
	'SGD',
	'KRW',
]

const PriceInput: FC<PriceInputProps> = ({ currency, setCurrency, setPrice, price }) => {
	const [isFocused, setIsFocused] = useState(false)
	const [isHovered, setIsHovered] = useState(false)

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value

		if (/^[0-9.]*$/.test(value)) {
			setPrice(value)
		}
	}

	return (
		<div className={`${styles.priceInput} ${(isFocused || isHovered) && styles.focus} `}>
			<div
				className={styles.selectWrapper}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				{/*<select*/}
				{/*	value={currency}*/}
				{/*	onChange={event => setCurrency(event.target.value)}*/}

				{/*>*/}
				{/*	{currencies.map(currency => (*/}
				{/*		<option value={currency} key={currency}>*/}
				{/*			{currency}*/}
				{/*		</option>*/}
				{/*	))}*/}
				{/*</select>*/}
				<CustomSelect
					controlClassName={
						isHovered || isFocused ? styles.controlClassNameFocused : styles.controlClassName
					}
					controlClassNameFocused={styles.controlClassNameFocused}
					value={currency}
					setValue={setCurrency}
					options={currencies.map(currency => ({ value: currency, label: currency }))}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
				/>
			</div>

			<input
				value={price}
				onChange={onChangeHandler}
				placeholder={'0.00'}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			/>
		</div>
	)
}

export default PriceInput
