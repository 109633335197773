import React, { FC } from 'react'
import styles from './FraudShieldTypeBadge.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import { FraudRuleFactor } from '../../../types/FraudRuleFactor/FraudRuleFactor'

interface FraudShieldTypeBadgeProps {
	factor: FraudRuleFactor
}

const FraudRuleFactorBadge: FC<FraudShieldTypeBadgeProps> = ({ factor }) => {
	const factorsOptions = {
		[FraudRuleFactor.FRAUD_SCORE]: {
			label: 'Fraud Score',
			iconId: 'wrench',
		},
		[FraudRuleFactor.IP]: {
			label: 'IP Address',
			iconId: 'wrench',
		},
		[FraudRuleFactor.COUNTRY]: {
			label: 'Country',
			iconId: 'flag',
		},
		[FraudRuleFactor.EMAIL]: {
			label: 'Email Address',
			iconId: 'atSymbol',
		},
		[FraudRuleFactor.ISP]: {
			label: 'ISP',
			iconId: 'wifi',
		},
		[FraudRuleFactor.CITY]: {
			label: 'City',
			iconId: 'flag',
		},
		[FraudRuleFactor.DISCORD_ID]: {
			label: 'Discord ID',
			iconId: 'discordStroke',
		},
		[FraudRuleFactor.EMAIL_DOMAIN]: {
			label: 'Email Domain',
			iconId: 'atSymbol',
		},
	}

	return (
		<div className={`${styles.typeBadge}`}>
			{factorsOptions[factor].label}
			<SpriteIcon iconId={factorsOptions[factor].iconId} width={16} height={16} />
		</div>
	)
}

export default FraudRuleFactorBadge
