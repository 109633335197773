import React, { useEffect, useState } from 'react'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import styles from '../Settings.module.scss'
import Input from '../../../components/UI/FormComponents/Input'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../components/UI/Button/Button'
import CustomSelect, { SelectStyles } from '../../../components/UI/CustomSelect/CustomSelect'
import Switch from '../../../components/UI/Switch/Switch'
import { useAPI } from '../../../hooks/useAPI'
import { SettingsService } from '../../../API/SettingsService'
import { useAppContext } from '../../../hooks/useAppContext'
import PageLoading from '../../../components/UI/PageLoading/PageLoading'
import InputMask from '../../../components/UI/FormComponents/InputMask'
import SubdomainInput from '../../../components/UI/SubdomainInput/SubdomainInput'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'
import { GetSettingsGeneral } from '../../../types/GetSettingsGeneral/GetSettingsGeneral'
import RadioWrapper from '../../../components/UI/RadioWrapper/RadioWrapper'
import { CustomerAccountsStatus } from '../../../types/CustomerAccountsStatus/CustomerAccountsStatus'

import { Subscriptions } from '../../../types/Subscriptions/Subscriptions'
import InputWithText from '../../../components/UI/FormComponents/InputWithText'
import { ShopForUserPermissions } from '../../../types/ShopForUserPermissions/ShopForUserPermissions'
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent'
import pageNotFoundImage from '../../../assets/images/404.png'
import { NavLink } from 'react-router-dom'

const timezones = [
	{ label: '(GMT-12:00) International Date Line West', value: '-12:00' },
	{ label: '(GMT-11:00) Midway Island, Samoa', value: '-11:00' },
	{ label: '(GMT-10:00) Hawaii', value: '-10:00' },
	{ label: '(GMT-09:00) Alaska', value: '-09:00' },
	{ label: '(GMT-08:00) Pacific Time (US & Canada)', value: '-08:00' },
	{ label: '(GMT-07:00) Mountain Time (US & Canada)', value: '-07:00' },
	{ label: '(GMT-06:00) Central Time (US & Canada)', value: '-06:00' },
	{ label: '(GMT-05:00) Eastern Time (US & Canada)', value: '-05:00' },
	{ label: '(GMT-04:00) Atlantic Time (Canada)', value: '-04:00' },
	{ label: '(GMT-03:00) Buenos Aires, Georgetown', value: '-03:00' },
	{ label: '(GMT-02:00) Mid-Atlantic', value: '-02:00' },
	{ label: '(GMT-01:00) Azores, Cape Verde Islands', value: '-01:00' },
	{ label: '(GMT) Greenwich Mean Time, Dublin, London', value: '00:00' },
	{ label: '(GMT+01:00) Amsterdam, Berlin, Paris', value: '+01:00' },
	{ label: '(GMT+02:00) Athens, Istanbul, Helsinki', value: '+02:00' },
	{ label: '(GMT+03:00) Moscow, St. Petersburg, Dubai', value: '+03:00' },
	{ label: '(GMT+04:00) Baku, Tbilisi, Yerevan', value: '+04:00' },
	{ label: '(GMT+05:00) Islamabad, Karachi, Tashkent', value: '+05:00' },
	{ label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi', value: '+05:30' },
	{ label: '(GMT+06:00) Almaty, Dhaka', value: '+06:00' },
	{ label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: '+07:00' },
	{ label: '(GMT+08:00) Beijing, Hong Kong, Singapore', value: '+08:00' },
	{ label: '(GMT+09:00) Tokyo, Seoul, Osaka', value: '+09:00' },
	{ label: '(GMT+10:00) Brisbane, Melbourne, Sydney', value: '10:00' },
	{ label: '(GMT+11:00) Solomon Islands, New Caledonia', value: '11:00' },
	{ label: '(GMT+12:00) Fiji, Kamchatka, Marshall Islands', value: '12:00' },
]

const currencies = [
	'USD',
	'EUR',
	'JPY',
	'GBP',
	'AUD',
	'CAD',
	'CHF',
	'CNY',
	'SEK',
	'NZD',
	'PLN',
	'ILS',
	'HKD',
	'ISK',
	'PHP',
	'DKK',
	'HUF',
	'CZK',
	'RON',
	'IDR',
	'INR',
	'BRL',
	'RUB',
	'HRK',
	'THB',
	'MYR',
	'BGN',
	'NOK',
	'ZAR',
	'MXN',
	'SGD',
	'KRW',
]

const SettingsGeneralPage = () => {
	const [timezone, setTimezone] = useState('-06:00')
	const [websiteName, setWebsiteName] = useState('')
	const [companyEmail, setCompanyEmail] = useState('')
	const [subdomain, setSubdomain] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [globalRedirectLink, setGlobalRedirectLink] = useState('')
	const [currency, setCurrency] = useState('')
	const [onHold, setOnHold] = useState(false)
	const [errors, setErrors] = useState<FormErrors>({})
	const [isPasswordProtectionEnabled, setIsPasswordProtectionEnabled] = useState(false)
	const [passwordProtection, setPasswordProtection] = useState('')
	const [postPurchaseRequestDays, setPostPurchaseRequestDays] = useState(0)
	const [isPostPurchaseRequestEnabled, setIsPostPurchaseRequestEnabled] = useState(false)
	const [isGlobalRedirectLinkEnabled, setIsGlobalRedirectLinkEnabled] = useState(false)
	const [customerAccountsStatus, setCustomerAccountsStatus] = useState<CustomerAccountsStatus>(
		CustomerAccountsStatus.OPTIONAL
	)

	const { shop, user, setIsSubscriptionModalActive, setRequiredSubscription } = useAppContext()
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isSettingsGeneralViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.SETTINGS_GENERAL_VIEW) || isAdmin
	const isSettingsGeneralModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.SETTINGS_GENERAL_MODIFY) || isAdmin

	const [isGetGeneralSettingsLoading, generalSettings, getGeneralSettings] =
		useAPI<GetSettingsGeneral>(() => SettingsService.getCompany(shop?.id || ''), false)

	const [isUpdateSubdomainLoading, , updateSubdomain] = useAPI(
		() => SettingsService.updateSubdomain(shop?.id || '', subdomain),
		false,
		'Settings saved successfully',
		{
			onSuccess: () => {
				getGeneralSettings()
			},
			validator: () => subdomainValidator(),
		}
	)

	const [isUpdatePostPurchaseRequestLoading, , updatePostPurchaseRequest] = useAPI(
		() =>
			SettingsService.updatePostPurchaseRequest(shop?.id || '', {
				days: isPostPurchaseRequestEnabled ? +postPurchaseRequestDays : null,
			}),
		false,
		'Settings saved successfully',
		{
			onSuccess: () => {
				getGeneralSettings()
			},
		}
	)

	const [isUpdateCompanyInfoLoading, , updateCompanyInfo] = useAPI(
		() =>
			SettingsService.updateCompanyInfo(shop?.id || '', {
				phoneNumber: phoneNumber,
				email: companyEmail,
			}),
		false,
		'Settings saved successfully',
		{
			validator: () => companySupportValidator(),
			onSuccess: () => {
				getGeneralSettings()
			},
		}
	)

	const [isUpdateTimezoneAndCurrencyLoading, , updateTimezoneAndCurrency] = useAPI(
		() =>
			SettingsService.updateTimezoneAndCurrency(shop?.id || '', {
				currency,
				timeZoneInfo: timezone,
			}),
		false,
		'Settings saved successfully',
		{
			onSuccess: () => {
				getGeneralSettings()
			},
		}
	)

	const [isUpdateNameLoading, , updateName] = useAPI(
		() => SettingsService.updateName(shop?.id || '', websiteName),
		false,
		'Settings saved successfully',
		{
			validator: () => websiteNameValidator(),
			onSuccess: () => {
				getGeneralSettings()
			},
		}
	)

	const [isUpdateWebsiteSettingsLoading, , updateWebsiteSettings] = useAPI(
		() =>
			SettingsService.updateWebsiteSettings(shop?.id || '', {
				onHold: onHold,
				password: isPasswordProtectionEnabled ? passwordProtection || null : null,
				redirectUrl: isGlobalRedirectLinkEnabled ? globalRedirectLink || null : null,
			}),
		false,
		'Settings saved successfully',
		{
			onSuccess: () => {
				getGeneralSettings()
			},
		}
	)

	const [isUpdateCustomerAccountsLoading, , updateCustomerAccounts] = useAPI(
		() =>
			SettingsService.updateCustomerAccounts(shop?.id || '', {
				status: customerAccountsStatus,
			}),
		false,
		'Settings saved successfully',
		{
			onSuccess: () => {
				getGeneralSettings()
			},
		}
	)

	const companySupportValidator = () => {
		const emailValidator = fieldValidators.emailValidator(companyEmail)

		setErrors({
			...errors,
			companyEmail: emailValidator,
		})

		return !emailValidator
	}

	const subdomainValidator = () => {
		const subdomainValidator = fieldValidators.blankValidator('Website Subdomain', subdomain)

		setErrors({
			...errors,
			subdomain: subdomainValidator,
		})

		return !subdomainValidator
	}

	const websiteNameValidator = () => {
		const websiteNameValidator = fieldValidators.blankValidator('Website Name', websiteName)

		setErrors({
			...errors,
			websiteName: websiteNameValidator,
		})

		return !websiteNameValidator
	}

	const onWebsiteSettingsSaveClickHandler = () => {
		updateWebsiteSettings()
	}

	const customerAccountsOnChangeHandler = (value: CustomerAccountsStatus) => {
		// if (
		// 	value === CustomerAccountsStatus.DISABLED &&
		// 	user?.subscription.id !== Subscriptions.SCALE
		// ) {
		// 	setIsSubscriptionModalActive(true)
		// 	setRequiredSubscription(Subscriptions.SCALE)
		// 	return
		// }
		// if (
		// 	value === CustomerAccountsStatus.REQUIRED &&
		// 	user?.subscription.id !== Subscriptions.BUSINESS &&
		// 	user?.subscription.id !== Subscriptions.SCALE
		// ) {
		// 	setIsSubscriptionModalActive(true)
		// 	setRequiredSubscription(Subscriptions.BUSINESS)
		// 	return
		// }
		setCustomerAccountsStatus(value)
	}

	useEffect(() => {
		if (shop && isSettingsGeneralViewAvailable) {
			getGeneralSettings()
		}
	}, [shop])

	useEffect(() => {
		if (generalSettings) {
			setCompanyEmail(generalSettings.information.email)
			setCurrency(generalSettings.timezoneAndCurrency.currency)
			setSubdomain(generalSettings.information.subdomain.replace('.billgang.store', ''))
			setWebsiteName(generalSettings.information.name)
			setPhoneNumber(generalSettings.information.phoneNumber || '')
			setOnHold(generalSettings.shopSettings.onHold)
			setPasswordProtection(generalSettings.shopSettings.password || '')
			setIsPasswordProtectionEnabled(!!generalSettings.shopSettings.password)
			setTimezone(generalSettings.timezoneAndCurrency.timeZoneInfo)
			setGlobalRedirectLink(generalSettings.shopSettings.redirectUrl)
			setCustomerAccountsStatus(generalSettings.shopSettings.customerAccountSettings)
			setIsGlobalRedirectLinkEnabled(!!generalSettings.shopSettings.redirectUrl)
			setPostPurchaseRequestDays(
				generalSettings.shopSettings.postPurchaseReviewRequestAfterDays || 0
			)
			setIsPostPurchaseRequestEnabled(
				generalSettings.shopSettings.postPurchaseReviewRequestAfterDays === 0
					? true
					: !!generalSettings.shopSettings.postPurchaseReviewRequestAfterDays
			)
		}
	}, [generalSettings])

	if (isGetGeneralSettingsLoading) return <PageLoading />

	if (!isSettingsGeneralViewAvailable)
		return (
			<NoDataComponent
				isSmallHeight={true}
				imageSrc={pageNotFoundImage}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)

	return (
		<PageWrapper>
			<div className={styles.settingsPageInnerBody}>
				<div className={styles.settingSection}>
					<header className={styles.settingSectionHeader}>
						<h1 className={styles.settingSectionTitle}>Website Settings</h1>
					</header>
					<div className={styles.settingSectionBody}>
						<div className={styles.fieldWrapper}>
							<p className={styles.fieldWrapperTitle}>Website Name</p>
							<div className={styles.formWrapper}>
								<Input
									value={websiteName}
									setValue={setWebsiteName}
									placeholder={'Dunn, Inc'}
									onBlur={() =>
										setErrors({
											...errors,
											websiteName: fieldValidators.blankValidator('Website Name', websiteName),
										})
									}
									errorMessage={errors['websiteName']}
								/>
							</div>
							<p className={styles.fieldWrapperDescription}>
								This is your store name across Billgang, both in the dashboard and your storefront.
							</p>

							{isSettingsGeneralModifyAvailable &&
								generalSettings?.information.name !== websiteName && (
									<div className={styles.mtMediumLarge}>
										<Button
											style={ButtonStyles.BORDERED}
											icon={{
												id: 'download',
												width: 13,
												height: 13,
												align: ButtonsIconAlign.LEFT,
											}}
											disableShadow={true}
											isLoading={isUpdateNameLoading}
											onClick={updateName}
										>
											Save Settings
										</Button>
									</div>
								)}
						</div>

						<div className={styles.mtMediumLarge}>
							<div className={styles.fieldWrapper}>
								<header className={styles.fieldWrapperHeader}>
									<div>
										<p className={styles.fieldWrapperTitle}>Post Purchase Review Request</p>
										<p className={styles.fieldWrapperDescription}>
											Send customers an email requesting a review after a specified period.
										</p>
									</div>
									<Switch
										checked={isPostPurchaseRequestEnabled}
										setChecked={setIsPostPurchaseRequestEnabled}
									/>
								</header>
								{isPostPurchaseRequestEnabled && (
									<div className={styles.formWrapper}>
										<InputWithText
											value={postPurchaseRequestDays}
											setValue={setPostPurchaseRequestDays}
											placeholder={'0'}
											type={'number'}
											text={'days'}
										/>
									</div>
								)}
							</div>

							{isSettingsGeneralModifyAvailable &&
								((generalSettings?.shopSettings.postPurchaseReviewRequestAfterDays !== undefined &&
									generalSettings.shopSettings.postPurchaseReviewRequestAfterDays !==
										+postPurchaseRequestDays) ||
									isPostPurchaseRequestEnabled !==
										(generalSettings?.shopSettings.postPurchaseReviewRequestAfterDays === 0
											? true
											: !!generalSettings?.shopSettings.postPurchaseReviewRequestAfterDays)) && (
									<div className={styles.mtMediumLarge}>
										<Button
											style={ButtonStyles.BORDERED}
											icon={{
												id: 'download',
												width: 13,
												height: 13,
												align: ButtonsIconAlign.LEFT,
											}}
											disableShadow={true}
											isLoading={isUpdatePostPurchaseRequestLoading}
											onClick={updatePostPurchaseRequest}
										>
											Save Settings
										</Button>
									</div>
								)}
						</div>

						<div className={styles.mtMediumLarge}>
							<div className={styles.fieldWrapper}>
								<header className={styles.fieldWrapperHeader}>
									<div>
										<p className={styles.fieldWrapperTitle}>Global Redirect Link</p>
										<p className={`${styles.fieldWrapperDescription} `}>
											Customers will be redirected to a custom URL immediately after checkout. (Does
											not override individual product redirect links).
										</p>
									</div>
									<Switch
										checked={isGlobalRedirectLinkEnabled}
										setChecked={setIsGlobalRedirectLinkEnabled}
									/>
								</header>
								{isGlobalRedirectLinkEnabled && (
									<div className={styles.formWrapper}>
										<Input
											value={globalRedirectLink}
											setValue={setGlobalRedirectLink}
											placeholder={'https://google.com'}
										/>
									</div>
								)}
							</div>
						</div>

						{isSettingsGeneralModifyAvailable &&
							((generalSettings?.shopSettings.redirectUrl &&
								generalSettings.shopSettings.redirectUrl !== globalRedirectLink) ||
								isGlobalRedirectLinkEnabled !== !!generalSettings?.shopSettings.redirectUrl) && (
								<div className={styles.mtMediumLarge}>
									<Button
										style={ButtonStyles.BORDERED}
										icon={{
											id: 'download',
											width: 13,
											height: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										disableShadow={true}
										isLoading={isUpdateWebsiteSettingsLoading}
										onClick={updateWebsiteSettings}
									>
										Save Settings
									</Button>
								</div>
							)}
					</div>
				</div>

				<div className={styles.settingSection}>
					<header className={styles.settingSectionHeader}>
						<h1 className={styles.settingSectionTitle}>Domain Management</h1>
					</header>
					<div className={styles.settingSectionBody}>
						<div className={styles.fieldWrapper}>
							<p className={styles.fieldWrapperTitle}>Website Subdomain</p>
							<div className={styles.formWrapper}>
								<SubdomainInput
									domain={subdomain}
									setDomain={setSubdomain}
									errorMessage={errors['subdomain']}
									onBlur={() =>
										setErrors({
											...errors,
											subdomain: fieldValidators.blankValidator('Website Subdomain', subdomain),
										})
									}
								/>
							</div>
							<p className={styles.fieldWrapperDescription}>
								This is your unique website subdomain. You can change this once every 30 days.{' '}
								<NavLink to={'/settings/domains'} className={styles.orangeText}>
									Get a custom domain.
								</NavLink>
							</p>

							{isSettingsGeneralModifyAvailable &&
								generalSettings?.information.subdomain.replace('.billgang.store', '') !==
									subdomain && (
									<div className={styles.mtMediumLarge}>
										<Button
											style={ButtonStyles.BORDERED}
											icon={{
												id: 'download',
												width: 13,
												height: 13,
												align: ButtonsIconAlign.LEFT,
											}}
											disableShadow={true}
											isLoading={isUpdateSubdomainLoading}
											onClick={updateSubdomain}
										>
											Save Settings
										</Button>
									</div>
								)}
						</div>
						<div className={styles.mtMediumLarge}>
							<div className={styles.fieldWrapper}>
								<header className={styles.fieldWrapperHeader}>
									<div>
										<p className={styles.fieldWrapperTitle}>Store Maintenance</p>
										<p className={styles.fieldWrapperDescription}>
											Enable maintenance to place your store on hold. Customers will not be able to
											access your store.
										</p>
									</div>
									<Switch checked={onHold} setChecked={setOnHold} />
								</header>
							</div>
						</div>

						{isSettingsGeneralModifyAvailable &&
							onHold !== generalSettings?.shopSettings.onHold && (
								<div className={styles.mtMediumLarge}>
									<Button
										style={ButtonStyles.BORDERED}
										icon={{
											id: 'download',
											width: 13,
											height: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										disableShadow={true}
										isLoading={isUpdateWebsiteSettingsLoading}
										onClick={onWebsiteSettingsSaveClickHandler}
									>
										Save Settings
									</Button>
								</div>
							)}

						<div className={styles.mtMediumLarge}>
							<div className={styles.fieldWrapper}>
								<header className={styles.fieldWrapperHeader}>
									<div>
										<p className={styles.fieldWrapperTitle}>Password Protection</p>
										<p className={styles.fieldWrapperDescription}>
											Lock your website to customers that have access to this password.
										</p>
									</div>
									<Switch
										checked={isPasswordProtectionEnabled}
										setChecked={checked => {
											if (user?.subscription.perks.passwordProtected) {
												setIsPasswordProtectionEnabled(checked)
											} else {
												setIsSubscriptionModalActive(true)
												setRequiredSubscription(Subscriptions.SCALE)
											}
										}}
									/>
								</header>
								{isPasswordProtectionEnabled && (
									<div className={styles.formWrapper}>
										<Input
											value={passwordProtection}
											setValue={setPasswordProtection}
											placeholder={'Password'}
										/>
									</div>
								)}
							</div>
						</div>
						{isSettingsGeneralModifyAvailable &&
							(passwordProtection !== (generalSettings?.shopSettings.password || '') ||
								isPasswordProtectionEnabled !== !!generalSettings?.shopSettings.password) && (
								<div className={styles.mtMediumLarge}>
									<Button
										style={ButtonStyles.BORDERED}
										icon={{
											id: 'download',
											width: 13,
											height: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										disableShadow={true}
										isLoading={isUpdateWebsiteSettingsLoading}
										onClick={onWebsiteSettingsSaveClickHandler}
									>
										Save Settings
									</Button>
								</div>
							)}
					</div>
				</div>

				<div className={styles.settingSection}>
					<header className={styles.settingSectionHeader}>
						<h1 className={styles.settingSectionTitle}>Company Support</h1>
					</header>
					<div className={styles.settingSectionBody}>
						<div className={styles.twoColumns}>
							<div className={styles.fieldWrapper}>
								<p className={styles.fieldWrapperTitle}>Company Email</p>
								<div className={styles.formWrapper}>
									<Input
										value={companyEmail}
										setValue={setCompanyEmail}
										placeholder={'support@example.com'}
										errorMessage={errors['companyEmail']}
										onBlur={() =>
											setErrors({
												...errors,
												companyEmail: fieldValidators.emailValidator(companyEmail),
											})
										}
									/>
								</div>
								<p className={styles.fieldWrapperDescription}>
									You’ll receive notifications and support requests directly to this email address.
								</p>
							</div>
							<div className={styles.fieldWrapper}>
								<p className={styles.fieldWrapperTitle}>Phone Number</p>
								<div className={styles.formWrapper}>
									<InputMask
										mask={'+9 (999) 999-9999'}
										value={phoneNumber}
										setValue={setPhoneNumber}
										placeholder={'+0 (000) 000-0000'}
										onBlur={() =>
											setErrors({
												...errors,
												phoneNumber: fieldValidators.phoneNumberValidator(
													phoneNumber.replace(/[^\d+]/g, '')
												),
											})
										}
										errorMessage={errors['phoneNumber']}
									/>
								</div>
								<p className={styles.fieldWrapperDescription}>
									Support number that will be available to customers.
								</p>
							</div>
						</div>
						{isSettingsGeneralModifyAvailable &&
							(phoneNumber !== (generalSettings?.information.phoneNumber || '') ||
								companyEmail !== generalSettings?.information.email) && (
								<div className={styles.mtMediumLarge}>
									<Button
										style={ButtonStyles.BORDERED}
										icon={{
											id: 'download',
											width: 13,
											height: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										disableShadow={true}
										onClick={updateCompanyInfo}
										isLoading={isUpdateCompanyInfoLoading}
									>
										Save Settings
									</Button>
								</div>
							)}
					</div>
				</div>

				<div className={styles.settingSection}>
					<header className={styles.settingSectionHeader}>
						<h1 className={styles.settingSectionTitle}>Customer Accounts</h1>
					</header>
					<div className={styles.settingSectionBody}>
						<div className={styles.twoColumns}>
							<RadioWrapper
								id={'optional'}
								value={CustomerAccountsStatus.OPTIONAL}
								setValue={value => customerAccountsOnChangeHandler(value as CustomerAccountsStatus)}
								checked={customerAccountsStatus === CustomerAccountsStatus.OPTIONAL}
								title={'OPTIONAL'}
								description={'Customers can checkout as a guest OR create an account.'}
							/>
							<RadioWrapper
								id={'disabled'}
								value={CustomerAccountsStatus.DISABLED}
								setValue={value => customerAccountsOnChangeHandler(value as CustomerAccountsStatus)}
								checked={customerAccountsStatus === CustomerAccountsStatus.DISABLED}
								title={'DISABLED'}
								description={'Customers can only checkout with a guest email address.'}
							/>
							<RadioWrapper
								id={'required'}
								value={CustomerAccountsStatus.REQUIRED}
								setValue={value => customerAccountsOnChangeHandler(value as CustomerAccountsStatus)}
								checked={customerAccountsStatus === CustomerAccountsStatus.REQUIRED}
								title={'REQUIRED'}
								description={'Customers are required to create an account to checkout.'}
							/>
						</div>

						{isSettingsGeneralModifyAvailable &&
							customerAccountsStatus !== generalSettings?.shopSettings.customerAccountSettings && (
								<div className={styles.mtMediumLarge}>
									<Button
										style={ButtonStyles.BORDERED}
										icon={{
											id: 'download',
											width: 13,
											height: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										disableShadow={true}
										onClick={updateCustomerAccounts}
										isLoading={isUpdateCustomerAccountsLoading}
									>
										Save Settings
									</Button>
								</div>
							)}
					</div>
				</div>

				<div className={styles.settingSection}>
					<header className={styles.settingSectionHeader}>
						<h1 className={styles.settingSectionTitle}>Timezone and Currency</h1>
					</header>
					<div className={styles.settingSectionBody}>
						<div className={styles.twoColumns}>
							<div className={styles.fieldWrapper}>
								<p className={styles.fieldWrapperTitle}>Timezone</p>
								<div className={styles.formWrapper}>
									<CustomSelect
										style={SelectStyles.secondary}
										value={timezone}
										setValue={setTimezone}
										options={timezones}
									/>
								</div>
								<p className={styles.fieldWrapperDescription}>
									Select the timezone that will be set for your account and dashboard.
								</p>
							</div>
							<div className={styles.fieldWrapper}>
								<p className={styles.fieldWrapperTitle}>Currency</p>
								<div className={styles.formWrapper}>
									<CustomSelect
										style={SelectStyles.secondary}
										value={currency}
										setValue={setCurrency}
										options={currencies.map(currency => ({
											value: currency,
											label: currency,
										}))}
									/>
								</div>
								<p className={styles.fieldWrapperDescription}>
									Set a currency that will be visible across your website.
								</p>
							</div>
						</div>

						{isSettingsGeneralModifyAvailable &&
							(timezone !== generalSettings?.timezoneAndCurrency.timeZoneInfo ||
								currency !== generalSettings.timezoneAndCurrency.currency) && (
								<div className={styles.mtMediumLarge}>
									<Button
										style={ButtonStyles.BORDERED}
										icon={{
											id: 'download',
											width: 13,
											height: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										disableShadow={true}
										onClick={updateTimezoneAndCurrency}
										isLoading={isUpdateTimezoneAndCurrencyLoading}
									>
										Save Settings
									</Button>
								</div>
							)}
					</div>
				</div>

				{/*<div className={styles.settingSection}>*/}
				{/*	<p className={styles.settingSectionTitle}>ANNOUNCEMENT</p>*/}
				{/*	<p className={styles.settingSectionDescription}>*/}
				{/*		Set a custom message for your customers to see once they visit your storefront*/}
				{/*	</p>*/}
				{/*	<div className={styles.formWrapper}>*/}
				{/*		<Textarea*/}
				{/*			height={90}*/}
				{/*			value={*/}
				{/*				'This is a custom announcement for your storefront. Edit this in your settings.'*/}
				{/*			}*/}
				{/*			setValue={() => {}}*/}
				{/*		/>*/}
				{/*	</div>*/}
				{/*	<footer className={styles.settingSectionFooter}>*/}
				{/*		<p className={styles.helperText}>*/}
				{/*			This message will be a banner at the top of the page.*/}
				{/*		</p>*/}
				{/*		<Button style={ButtonStyles.THIRD}>Save Settings</Button>*/}
				{/*	</footer>*/}
				{/*</div>*/}
			</div>
		</PageWrapper>
	)
}

export default SettingsGeneralPage
