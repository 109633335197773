import React, { FC, useEffect, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import DeliveryProductTypeComponent from '../../DeliveryProductType/DeliveryProductTypeComponent'
import { SpriteIcon } from '../../UI/SpriteIcon/SpriteIcon'
import ChooseProductTypeModalInnerStep from './ChooseProductTypeModalInnerStep'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { VariantsDelivery } from '../../../types/CreateVariants/VariantsDelivery'
import { DeliveryConfigurationType } from '../../../types/DeliveryConfigurationType/DeliveryConfigurationType'
import uniqid from 'uniqid'
import { productDeliverablesOptions } from '../../../helpers/productDeliverablesOptions'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'

interface ChooseProductTypeModalProps extends BaseModalProps {
	variantsDelivery: VariantsDelivery
	setVariantsDelivery: (value: VariantsDelivery) => void
	currentVariantIndex: string
	editId?: string | null
}

const ChooseProductTypeModal: FC<ChooseProductTypeModalProps> = ({
	variantsDelivery,
	setVariantsDelivery,
	currentVariantIndex,
	onClose,
	editId,
}) => {
	const [selectedDeliveryConfigurationType, setSelectedDeliveryConfigurationType] =
		useState<DeliveryConfigurationType | null>(null)
	const [step, setStep] = useState(0)
	const [textData, setTextData] = useState('')
	const [customName, setCustomName] = useState('')
	const [discordGuild, setDiscordGuild] = useState('')
	const [discordGuildRoles, setDiscordGuildRoles] = useState<string[]>([])
	const [limitStock, setLimitStock] = useState(0)
	const [currentWarehouseId, setCurrentWarehouseId] = useState<string>('')
	const [isLimitStockEnabled, setIsLimitStockEnabled] = useState(false)
	const [isCustomNameEnabled, setIsCustomNameEnabled] = useState(false)
	const [stock, setStock] = useState<number | null>(null)
	const [errors, setErrors] = useState<FormErrors>({})

	const onSaveValidator = () => {
		const linkValidator =
			selectedDeliveryConfigurationType === DeliveryConfigurationType.LINK
				? fieldValidators.URLValidator('Custom Link', textData)
				: ''
		const downloadLinkValidator =
			selectedDeliveryConfigurationType === DeliveryConfigurationType.DOWNLOAD
				? fieldValidators.URLValidator('Download Link', textData)
				: ''
		const serviceTextValidator =
			selectedDeliveryConfigurationType === DeliveryConfigurationType.TEXT
				? fieldValidators.blankValidator('Service Note', textData)
				: ''
		const ebookLinkValidator =
			selectedDeliveryConfigurationType === DeliveryConfigurationType.EBOOK
				? fieldValidators.URLValidator('Ebook Link', textData)
				: ''
		const customNoteValidator =
			selectedDeliveryConfigurationType === DeliveryConfigurationType.CUSTOM
				? fieldValidators.blankValidator('Custom Note', textData)
				: ''

		setErrors({
			customLink: linkValidator,
			downloadLink: downloadLinkValidator,
			serviceNote: serviceTextValidator,
			ebookLink: ebookLinkValidator,
			customNote: customNoteValidator,
		})

		return !(
			linkValidator ||
			downloadLinkValidator ||
			serviceTextValidator ||
			ebookLinkValidator ||
			customNoteValidator
		)
	}

	const onSaveHandler = () => {
		if (onSaveValidator()) {
			const uniqueId1 = uniqid()

			if (selectedDeliveryConfigurationType) {
				setVariantsDelivery({
					...variantsDelivery,
					[currentVariantIndex]: {
						...variantsDelivery[currentVariantIndex],
						deliveryConfiguration: {
							...variantsDelivery[currentVariantIndex].deliveryConfiguration,
							[editId || (uniqueId1 as any)]: {
								type: selectedDeliveryConfigurationType,
								customName: isCustomNameEnabled ? customName : null,
								displayStock: null,
								textData: textData || null,
								discord: discordGuild
									? {
											guildId: discordGuild,
											roleIds: discordGuildRoles,
									  }
									: null,
								serialsSystemId: +currentWarehouseId || null,
								stock: isLimitStockEnabled ? limitStock : stock,
							},
						},
					},
				})
				onClose()
			}
		}
	}

	useEffect(() => {
		if (editId) {
			const deliveryConfiguration =
				variantsDelivery[currentVariantIndex].deliveryConfiguration[editId]
			setTextData(deliveryConfiguration.textData || '')
			setCustomName(deliveryConfiguration.customName || '')
			setIsCustomNameEnabled(!!deliveryConfiguration.customName)
			setCurrentWarehouseId(deliveryConfiguration.serialsSystemId || '')
			setDiscordGuild(deliveryConfiguration.discord?.guildId || '')
			setDiscordGuildRoles(deliveryConfiguration.discord?.roleIds || [])
			setSelectedDeliveryConfigurationType(deliveryConfiguration.type)
			setLimitStock(deliveryConfiguration.stock)
			setIsLimitStockEnabled(!!deliveryConfiguration.stock)
			setStep(1)
		}
	}, [])

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				{step === 1 && selectedDeliveryConfigurationType && (
					<div className={styles.iconWrapper}>
						<SpriteIcon
							iconId={productDeliverablesOptions[selectedDeliveryConfigurationType].icon.id}
							width={productDeliverablesOptions[selectedDeliveryConfigurationType].icon.width}
							height={productDeliverablesOptions[selectedDeliveryConfigurationType].icon.height}
						/>
					</div>
				)}

				<h1>
					{step === 1 && selectedDeliveryConfigurationType
						? productDeliverablesOptions[selectedDeliveryConfigurationType].title
						: 'Add Deliverable'}
				</h1>
			</header>

			<div className={styles.body}>
				{step === 1 && selectedDeliveryConfigurationType ? (
					<ChooseProductTypeModalInnerStep
						discordRoles={discordGuildRoles}
						setDiscordRoles={setDiscordGuildRoles}
						setDiscordGuild={setDiscordGuild}
						discordGuild={discordGuild}
						limitStock={limitStock}
						setLimitStock={setLimitStock}
						selectedDeliveryConfigurationType={selectedDeliveryConfigurationType}
						currentWarehouseId={currentWarehouseId}
						setCurrentWarehouseId={setCurrentWarehouseId}
						setIsLimitStockEnabled={setIsLimitStockEnabled}
						isLimitStockEnabled={isLimitStockEnabled}
						setStock={setStock}
						stock={stock}
						textData={textData}
						setTextData={setTextData}
						editId={editId}
						isCustomNameEnabled={isCustomNameEnabled}
						customName={customName}
						setCustomName={setCustomName}
						setIsCustomNameEnabled={setIsCustomNameEnabled}
						errors={errors}
						setErrors={setErrors}
					/>
				) : (
					<div className={styles.oneColumn} style={{ gridGap: 10 }}>
						<DeliveryProductTypeComponent
							deliveryConfigurationType={DeliveryConfigurationType.DISCORD}
							onClick={() =>
								setSelectedDeliveryConfigurationType(DeliveryConfigurationType.DISCORD)
							}
							isActive={DeliveryConfigurationType.DISCORD === selectedDeliveryConfigurationType}
						/>

						<DeliveryProductTypeComponent
							deliveryConfigurationType={DeliveryConfigurationType.SERIALS}
							onClick={() =>
								setSelectedDeliveryConfigurationType(DeliveryConfigurationType.SERIALS)
							}
							isActive={DeliveryConfigurationType.SERIALS === selectedDeliveryConfigurationType}
						/>

						<DeliveryProductTypeComponent
							deliveryConfigurationType={DeliveryConfigurationType.TEXT}
							onClick={() => setSelectedDeliveryConfigurationType(DeliveryConfigurationType.TEXT)}
							isActive={DeliveryConfigurationType.TEXT === selectedDeliveryConfigurationType}
						/>
						<DeliveryProductTypeComponent
							deliveryConfigurationType={DeliveryConfigurationType.DOWNLOAD}
							onClick={() =>
								setSelectedDeliveryConfigurationType(DeliveryConfigurationType.DOWNLOAD)
							}
							isActive={DeliveryConfigurationType.DOWNLOAD === selectedDeliveryConfigurationType}
						/>
						<DeliveryProductTypeComponent
							deliveryConfigurationType={DeliveryConfigurationType.LINK}
							onClick={() => setSelectedDeliveryConfigurationType(DeliveryConfigurationType.LINK)}
							isActive={DeliveryConfigurationType.LINK === selectedDeliveryConfigurationType}
						/>
						<DeliveryProductTypeComponent
							deliveryConfigurationType={DeliveryConfigurationType.EBOOK}
							onClick={() => setSelectedDeliveryConfigurationType(DeliveryConfigurationType.EBOOK)}
							isActive={DeliveryConfigurationType.EBOOK === selectedDeliveryConfigurationType}
						/>
						{/*<DeliveryProductTypeComponent*/}
						{/*	deliveryConfigurationType={DeliveryConfigurationType.TEXT}*/}
						{/*	onClick={() => setSelectedDeliveryConfigurationType(DeliveryConfigurationType.TEXT)}*/}
						{/*	isActive={DeliveryConfigurationType.TEXT === selectedDeliveryConfigurationType}*/}
						{/*/>*/}
						<DeliveryProductTypeComponent
							deliveryConfigurationType={DeliveryConfigurationType.CUSTOM}
							onClick={() => setSelectedDeliveryConfigurationType(DeliveryConfigurationType.CUSTOM)}
							isActive={DeliveryConfigurationType.CUSTOM === selectedDeliveryConfigurationType}
						/>
						{/*<DeliveryProductTypeComponent*/}
						{/*	deliveryConfigurationType={DeliveryConfigurationType.TELEGRAM}*/}
						{/*	onClick={() => {}}*/}
						{/*	isActive={DeliveryConfigurationType.TELEGRAM === selectedDeliveryConfigurationType}*/}
						{/*/>*/}
					</div>
				)}
			</div>

			<footer className={styles.footer}>
				{/*<a className={styles.helpText} href='https://suggestions.sellpass.io/' target={'_blank'}>*/}
				{/*	Selling something else?*/}
				{/*</a>*/}
				<div className={styles.buttons}>
					{step === 0 ? (
						<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
							Cancel
						</Button>
					) : (
						<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={() => setStep(0)}>
							Back
						</Button>
					)}
					{step === 0 ? (
						<Button
							style={ButtonStyles.BORDERED}
							icon={{
								id: 'arrowRight',
								width: 13,
								height: 12,
								align: ButtonsIconAlign.RIGHT,
							}}
							disableShadow={true}
							onClick={() => selectedDeliveryConfigurationType && setStep(1)}
						>
							Next
						</Button>
					) : (
						<Button
							style={ButtonStyles.BORDERED}
							icon={{
								id: 'download',
								width: 13,
								height: 13,
								align: ButtonsIconAlign.LEFT,
							}}
							disableShadow={true}
							onClick={onSaveHandler}
						>
							Save
						</Button>
					)}
				</div>
			</footer>
		</div>
	)
}

export default ChooseProductTypeModal
