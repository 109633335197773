import { DeliveryConfigurationType } from '../types/DeliveryConfigurationType/DeliveryConfigurationType'

export const productDeliverablesOptions = {
	[DeliveryConfigurationType.LINK]: {
		icon: {
			id: 'chain',
			width: 16,
			height: 16,
		},
		title: 'Link',
		description: 'Access to custom links after checkout.',
	},
	[DeliveryConfigurationType.DISCORD]: {
		icon: {
			id: 'discord3',
			width: 25,
			height: 17,
		},
		title: 'Discord',
		description: 'Access to your Discord community server/roles.',
	},
	[DeliveryConfigurationType.SERIALS]: {
		icon: {
			id: 'scroll',
			width: 18,
			height: 15,
		},
		title: 'Serials',
		description: 'License keys, accounts, software tokens, and more.',
	},
	[DeliveryConfigurationType.EBOOK]: {
		icon: {
			id: 'book',
			width: 15,
			height: 18,
		},
		title: 'Ebook',
		description: 'Educational content through a direct download or document link.',
	},
	[DeliveryConfigurationType.TEXT]: {
		icon: {
			id: 'briefcase',
			width: 16,
			height: 15,
		},
		title: 'Service',
		description: 'Send a custom note or sell professional services.',
	},
	[DeliveryConfigurationType.CUSTOM]: {
		icon: {
			id: 'stars',
			width: 16,
			height: 15,
		},
		title: 'Custom',
		description: 'Deliver a customized product type.',
	},
	[DeliveryConfigurationType.DOWNLOAD]: {
		icon: {
			id: 'download',
			width: 15,
			height: 15,
		},
		title: 'Downloadable',
		description: 'A downloadable file such as a zip, pdf, mp4, etc.',
	},
	[DeliveryConfigurationType.TELEGRAM]: {
		icon: {
			id: 'telegramStroke',
			width: 16,
			height: 14,
		},
		title: 'Telegram',
		description: 'Access to your Telegram group.',
	},
}
