import React, { FC } from 'react'
import styles from '../Modals.module.scss'

import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'

import { useAppContext } from '../../../hooks/useAppContext'
import FeatureBadge from '../../UI/FeatureBadge/FeatureBadge'
import { Subscriptions } from '../../../types/Subscriptions/Subscriptions'
import { useNavigate } from 'react-router-dom'

const getSubscriptionDetails = (subscription: Subscriptions) => {
	switch (subscription) {
		case Subscriptions.CORE_PLUS:
			return {
				features: (
					<div className={`${styles.row} ${styles.largeGap} ${styles.mtLarge}`}>
						<FeatureBadge featureText={'Custom domain'} />
						<FeatureBadge featureText={'Discord integration'} />
						<FeatureBadge featureText={'10 team members'} />
						<FeatureBadge featureText={'5 additional storefronts'} />
						<FeatureBadge featureText={'Cashback rewards'} />
						<FeatureBadge featureText={'Affiliate system'} />
						<FeatureBadge featureText={'Review engagement'} />
						<FeatureBadge featureText={'Order threshold'} />
						<FeatureBadge featureText={'Topup bonuses'} />
						<FeatureBadge featureText={'Spending threshold'} />
						<p>... and so much more</p>
					</div>
				),
				name: 'Core Plus',
			}
		case Subscriptions.SCALE:
			return {
				features: (
					<div className={`${styles.row} ${styles.largeGap} ${styles.mtLarge}`}>
						<FeatureBadge featureText={'Unlimited digital warehouses'} />
						<FeatureBadge featureText={'50 team members'} />
						<FeatureBadge featureText={'500GB storage'} />
						<FeatureBadge featureText={'25 additional storefronts'} />
						<FeatureBadge featureText={'Individual payment processors'} />
						<FeatureBadge featureText={'Automatic/split partner payouts'} />
						<FeatureBadge featureText={'Disable platform branding'} />
						<FeatureBadge featureText={'Priority feature requests'} />
						<p>... and so much more</p>
					</div>
				),
				name: 'Scale',
			}
		case Subscriptions.BUSINESS:
			return {
				features: (
					<div className={`${styles.row} ${styles.largeGap} ${styles.mtLarge}`}>
						<FeatureBadge featureText={'2,500 digital warehouses'} />
						<FeatureBadge featureText={'25 team members'} />
						<FeatureBadge featureText={'100GB storage'} />
						<FeatureBadge featureText={'10 additional storefronts'} />
						<FeatureBadge featureText={'Third party plugins'} />
						<FeatureBadge featureText={'Advanced transaction data'} />
						<FeatureBadge featureText={'Custom payment integrations'} />
						<FeatureBadge featureText={'Cashflow balancing system'} />
						<FeatureBadge featureText={'Team audit logs'} />
						<p>... and so much more</p>
					</div>
				),
				name: 'Business',
			}
	}
}

interface UpgradeSubscriptionModalProps extends BaseModalProps {
	subscription: Subscriptions
}

const UpgradeSubscriptionModal: FC<UpgradeSubscriptionModalProps> = ({ onClose, subscription }) => {
	const { shop } = useAppContext()
	const subscriptionDetails = getSubscriptionDetails(subscription)
	const navigate = useNavigate()

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Feature Not Available</h1>
			</header>

			<div className={styles.body}>
				<p className={`${styles.mediumSmallText}`}>
					Upgrade your subscription plan to {subscriptionDetails?.name} and unlock:
				</p>

				{subscriptionDetails?.features}

				<p className={styles.mtLarge}>
					Visit our{' '}
					<a className={styles.link} href='https://billgang.com/pricing' target={'_blank'}>
						pricing page
					</a>{' '}
					to view all features per subscription tier.
				</p>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'blankWindow',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={() => {
							navigate('/settings/billing')
							onClose()
						}}
					>
						Upgrade Plan
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default UpgradeSubscriptionModal
