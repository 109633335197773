export const getDatePeriod = {
	getLastWeeksDate() {
		const now = new Date()

		return new Date(
			now.getFullYear(),
			now.getMonth(),
			now.getDate() - 7,
			now.getHours(),
			now.getMinutes(),
			now.getSeconds()
		)
	},
	getLastYearDate() {
		const now = new Date()

		return new Date(
			now.getFullYear() - 1,
			now.getMonth(),
			now.getDate(),
			now.getHours(),
			now.getMinutes(),
			now.getSeconds()
		)
	},
	getLastDayDate() {
		const now = new Date()

		return new Date(
			now.getFullYear(),
			now.getMonth(),
			now.getDate() - 1,
			now.getHours(),
			now.getMinutes(),
			now.getSeconds()
		)
	},
	getLastMonthDate(number: number = 1) {
		const now = new Date()

		return new Date(
			now.getFullYear(),
			now.getMonth() - number,
			now.getDate(),
			now.getHours(),
			now.getMinutes(),
			now.getSeconds()
		)
	},
	getPastWeeksDate() {
		const lastWeek = this.getLastWeeksDate()

		return new Date(
			lastWeek.getFullYear(),
			lastWeek.getMonth(),
			lastWeek.getDate() - 7,
			lastWeek.getHours(),
			lastWeek.getMinutes(),
			lastWeek.getSeconds()
		)
	},
	getPastDaysDate() {
		const lastDayDate = this.getLastDayDate()

		return new Date(
			lastDayDate.getFullYear(),
			lastDayDate.getMonth(),
			lastDayDate.getDate() - 1,
			lastDayDate.getHours(),
			lastDayDate.getMinutes(),
			lastDayDate.getSeconds()
		)
	},
	getPastYearsDate() {
		const lastYearDate = this.getLastYearDate()

		return new Date(
			lastYearDate.getFullYear() - 1,
			lastYearDate.getMonth(),
			lastYearDate.getDate(),
			lastYearDate.getHours(),
			lastYearDate.getMinutes(),
			lastYearDate.getSeconds()
		)
	},
	getPastMonthsDate() {
		const lastMonthDate = this.getLastMonthDate()

		return new Date(
			lastMonthDate.getFullYear(),
			lastMonthDate.getMonth() - 1,
			lastMonthDate.getDate(),
			lastMonthDate.getHours(),
			lastMonthDate.getMinutes(),
			lastMonthDate.getSeconds()
		)
	},
}
