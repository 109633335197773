import React, { FC, ReactNode } from 'react'
import styles from './RadioWrapper.module.scss'
import Radio from '../Radio/Radio'

interface RadioWrapperProps {
	id: string
	value: string
	setValue: (value: string) => void
	checked: boolean
	title: string
	description: string
	className?: string
	children?: ReactNode
}

const RadioWrapper: FC<RadioWrapperProps> = ({
	checked,
	value,
	setValue,
	id,
	description,
	title,
	children,
	className,
}) => {
	return (
		<label className={`${styles.radioWrapper} ${checked && styles.active} ${className ?? ''}`}>
			<Radio id={id} value={value} setValue={setValue} checked={checked} />
			<div className={styles.label}>
				<p>{title}</p>
				<span>{description}</span>
			</div>
			{children}
		</label>
	)
}

export default RadioWrapper
