import React, { useEffect, useState } from 'react'
import styles from './AlternativeAuthVariants.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { useAppContext } from '../../hooks/useAppContext'

const AlternativeAuthVariants = () => {
	const [newWindow, setNewWindow] = useState<Window | null>(null)
	const { redirectAfterAuthPath } = useAppContext()

	useEffect(() => {
		if (newWindow) {
			const Interval = setInterval(() => {
				if (newWindow.closed) {
					clearInterval(Interval)
					if (localStorage.getItem('accessToken')) {
						window.location.pathname = redirectAfterAuthPath ? redirectAfterAuthPath : '/'
					}
				}
			}, 500)
		}
	}, [newWindow])

	return (
		<div className={styles.alternativeVariants}>
			{/*<div*/}
			{/*	className={styles.variantWrapper}*/}
			{/*	onClick={() =>*/}
			{/*		setNewWindow(*/}
			{/*			window.open(*/}
			{/*				`https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=${*/}
			{/*					window.location.hostname === 'localhost'*/}
			{/*						? 'http%3A%2F%2Flocalhost%3A3000'*/}
			{/*						: 'https%3A%2F%2Fdash.billgang.com'*/}
			{/*				}%2Fauth%2Fgoogle%2Fcallback&prompt=consent&response_type=code&client_id=165295676705-d81fbtjp5tn4uni6mljmssjp96ll01tq.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline&flowName=GeneralOAuthFlow`,*/}
			{/*				'_blank',*/}
			{/*				'width=480, height=720'*/}
			{/*			)*/}
			{/*		)*/}
			{/*	}*/}
			{/*>*/}
			{/*	<SpriteIcon iconId={'google'} width={15} height={15} />*/}
			{/*</div>*/}
			{/*<div className={styles.variantWrapper}>*/}
			{/*	<SpriteIcon iconId={'apple'} width={15} height={18} />*/}
			{/*</div>*/}
			<div
				className={styles.variantWrapper}
				onClick={() =>
					setNewWindow(
						window.open(
							`https://discord.com/api/oauth2/authorize?client_id=1032724229172510740&redirect_uri=${
								window.location.hostname === 'localhost'
									? 'http%3A%2F%2Flocalhost%3A3000'
									: 'https%3A%2F%2Fdash.billgang.com'
							}%2Fauth%2Fdiscord%2Fcallback&response_type=code&scope=identify%20guilds%20email%20guilds.join`,
							'_blank',
							'width=480, height=720'
						)
					)
				}
			>
				<SpriteIcon iconId={'discord'} width={19} height={14} />
			</div>
		</div>
	)
}

export default AlternativeAuthVariants
