import React, { FC } from 'react'
import styles from './PaymentType.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import Checkbox from '../UI/Checkbox/Checkbox'
import { Gateways } from '../../types/Gateways/Gateways'
import { getGatewaysOptions } from '../../helpers/getGatewaysOptions'
import { AvailablePayment } from '../../types/AvailablePayment/AvailablePayment'
import uniqid from 'uniqid'

interface PaymentTypeProps {
	checked: boolean
	setChecked: (value: boolean) => void
	gateway: AvailablePayment
	onEditButtonClick?: () => void
}

const PaymentType: FC<PaymentTypeProps> = ({ checked, setChecked, gateway, onEditButtonClick }) => {
	return (
		<label className={`${styles.paymentType}`}>
			<div className={styles.paymentTypeHead}>
				<div className={styles.icon}>
					<img
						src={`https://imagedelivery.net/${gateway.logoCfImageId}/gatewayLogo`}
						alt={gateway.name}
					/>
				</div>
				<div>
					<p className={styles.title}>{gateway.displayName}</p>
					{/*<p className={styles.subtitle}>{gatewayOptions.subtitle}</p>*/}
				</div>
			</div>
			<div className={styles.paymentTypeEnd}>
				{onEditButtonClick && (
					<SpriteIcon
						className={styles.editButton}
						iconId={'pen'}
						width={15}
						height={15}
						onClick={event => {
							event.preventDefault()
							onEditButtonClick()
						}}
					/>
				)}

				<Checkbox
					id={gateway.name + uniqid()}
					checked={checked}
					setChecked={setChecked}
					onClick={event => event.stopPropagation()}
				/>
			</div>
		</label>
	)
}

export default PaymentType
