import React, { FC, useState } from 'react'
import styles from '../DiscordBotBuilderPage.module.scss'
import discordTokenImg from '../../../assets/images/discordToken.png'
import discordClientIdSecretImg from '../../../assets/images/discordClientIdSecret.png'
import Input from '../../../components/UI/FormComponents/Input'
import AlertBadge from '../../../components/UI/AlertBadge/AlertBadge'
import { Alert } from '../../../types/Alert/Alert'
import ColorInput from '../../../components/UI/FormComponents/ColorInput'
import CustomSelect from '../../../components/UI/CustomSelect/CustomSelect'
import { NavLink } from 'react-router-dom'
import Switch from '../../../components/UI/Switch/Switch'
import { CreateDiscordClusterBot } from '../../../types/CreateDiscordClusterBot/CreateDiscordClusterBot'
import { DiscordClusterBotStatus } from '../../../types/DiscordClusterBotStatus/DiscordClusterBotStatus'

interface CustomizeBotStepProps {
	botsCustomizes: { botStatus: DiscordClusterBotStatus; botActivityText: string }
	setBotsCustomizes: (value: {
		botStatus: DiscordClusterBotStatus
		botActivityText: string
	}) => void
}

const CustomizeBotStep: FC<CustomizeBotStepProps> = ({ setBotsCustomizes, botsCustomizes }) => {
	const [color, setColor] = useState('')

	return (
		<div className={styles.stepWrapper}>
			<p className={styles.defaultTitle}>Discord Bot Profile</p>
			<p className={`${styles.secondaryText} ${styles.mt10}`}>
				This section allows you to customize the bot to fit your branding and storefront identity.
			</p>
			<div className={`${styles.doubleGrid} ${styles.mtMediumLarge}`}>
				{/*<div>*/}
				{/*	<p className={styles.defaultTitle}>Embed Color</p>*/}
				{/*	<div className={styles.mt10}>*/}
				{/*		<ColorInput colorValue={color} setColorValue={setColor} />*/}
				{/*	</div>*/}
				{/*</div>*/}
				{/*<div>*/}
				{/*	<p className={styles.defaultTitle}>Embed Footer</p>*/}
				{/*	<div className={styles.mt10}>*/}
				{/*		<Input*/}
				{/*			value={''}*/}
				{/*			setValue={() => {}}*/}
				{/*			placeholder={'Start selling digital products with Billgang.com 💸'}*/}
				{/*		/>*/}
				{/*	</div>*/}
				{/*</div>*/}
				<div>
					<p className={styles.defaultTitle}>Bot Status</p>
					<div className={styles.mt10}>
						<CustomSelect
							value={botsCustomizes.botStatus}
							setValue={value =>
								setBotsCustomizes({
									...botsCustomizes,
									botStatus: value as DiscordClusterBotStatus,
								})
							}
							options={[
								{
									label: 'Online',
									value: DiscordClusterBotStatus.ONLINE,
								},
								{
									label: 'Offline',
									value: DiscordClusterBotStatus.OFFLINE,
								},
								{
									label: 'Away',
									value: DiscordClusterBotStatus.AWAY,
								},
								{
									label: 'Do Not Disturb',
									value: DiscordClusterBotStatus.DO_NOT_DISTURB,
								},
							]}
						/>
					</div>
				</div>
				<div>
					<p className={styles.defaultTitle}>Bot Activity Text</p>
					<div className={styles.mt10}>
						<Input
							value={botsCustomizes.botActivityText}
							setValue={value =>
								setBotsCustomizes({
									...botsCustomizes,
									botActivityText: value,
								})
							}
							placeholder={'Powered by Billgang.com 💸'}
						/>
					</div>
				</div>
			</div>
			<div className={`${styles.spaceBetween} ${styles.mtMediumLarge}`}>
				<div>
					<p className={styles.defaultTitle}>Billgang Branding</p>
					<p className={`${styles.secondaryText} ${styles.mt10}`}>
						You can disable Billgang branding on the Scale plan.{' '}
						<NavLink to={'/settings/billing'} className={styles.link}>
							Upgrade now.
						</NavLink>
					</p>
				</div>
				<Switch checked={true} setChecked={() => {}} />
			</div>
		</div>
	)
}

export default CustomizeBotStep
