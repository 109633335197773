import React, { FC, useEffect, useState } from 'react'
import styles from './ConversionRate.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import FeedbackActionMenu from '../FeedbackActionMenu/FeedbackActionMenu'
import { DatePeriod } from '../../types/DatePeriod/DatePeriod'
import UpdatedOnDateBadge from '../UI/UpdatedOnDateBadge/UpdatedOnDateBadge'
import Tabs from '../UI/Tabs/Tabs'
import { GetConversionRates } from '../../types/GetConversionRates/GetConversionRates'

interface ConversionRateProps {
	datePeriod: DatePeriod
	data: GetConversionRates
}

const ConversionRate: FC<ConversionRateProps> = ({ datePeriod, data }) => {
	const barChartHeight = 110
	const [updatedDate, setUpdatedDate] = useState(new Date())
	const checkoutPercent = data.checkoutCount / data.totalVisitsCount
	const purchasedPercent = data.purchasedCount / data.totalVisitsCount
	const abandonedPercent = data.abandonedCount / data.totalVisitsCount
	const roundedCheckoutPercent = Math.round(checkoutPercent * 100) || 0
	const roundedPurchasedPercent = Math.round(purchasedPercent * 100) || 0
	const roundedAbandonedPercent = Math.round(abandonedPercent * 100) || 0

	const findBiggerAndSmallerNumber = (a: number, b: number) => {
		if (a > b) {
			return { bigger: a, smaller: b }
		} else {
			return { bigger: b, smaller: a }
		}
	}

	const getDatePeriodInString = () => {
		switch (datePeriod) {
			case DatePeriod.allTime:
				return 'All time'
			case DatePeriod.lastDay:
				return 'Last 24 hours'
			case DatePeriod.lastMonth:
				return 'Last 30 days'
			case DatePeriod.lastWeek:
				return 'Last 7 days'
			case DatePeriod.lastYear:
				return 'Last 12 months'
		}
	}

	useEffect(() => {
		setUpdatedDate(new Date())
	}, [data])

	return (
		<div className={styles.conversionRate}>
			<header className={styles.header}>
				<h1>Conversion Rate</h1>
				<FeedbackActionMenu />
			</header>

			<header className={styles.subHeader}>
				<time className={styles.time}>{getDatePeriodInString()}</time>

				<UpdatedOnDateBadge date={updatedDate} />
			</header>

			<div className={styles.statistics}>
				<div className={styles.statisticsItem}>
					<h2 className={styles.statisticsName}>Checkout</h2>
					<p className={styles.statisticsPercent}>{roundedCheckoutPercent}%</p>
					<p className={styles.statisticsCount}>{data.checkoutCount}</p>
				</div>
				<div className={styles.statisticsItem}>
					<h2 className={styles.statisticsName}>Purchased</h2>
					<p className={styles.statisticsPercent}>{roundedPurchasedPercent}%</p>
					<p className={styles.statisticsCount}>{data.purchasedCount}</p>
				</div>
				<div className={styles.statisticsItem}>
					<h2 className={styles.statisticsName}>Abandoned</h2>
					<p className={styles.statisticsPercent}>{roundedAbandonedPercent}%</p>
					<p className={styles.statisticsCount}>{data.abandonedCount}</p>
				</div>
			</div>

			<div className={styles.barCharts}>
				<div className={styles.barChart}>
					<div className={styles.chartWrapper}>
						<div
							data-tooltip-id={'my-tooltip'}
							data-tooltip-content={`${100}%`}
							className={styles.chart}
							style={{
								height: `${100}%`,
							}}
						/>
					</div>
				</div>
				<div className={`${styles.merger} ${!checkoutPercent && styles.inactive}`}>
					<div
						className={styles.mergerItem}
						style={{
							height: 100 + '%',
							borderTop: `${Math.ceil(
								barChartHeight - barChartHeight * checkoutPercent
							)}px solid transparent`,
							transform: `${1 > checkoutPercent ? 'scaleX(1)' : 'scaleX(-1)'}`,
						}}
					></div>
				</div>

				<div className={styles.barChart}>
					<div className={styles.chartWrapper}>
						<div
							data-tooltip-id={'my-tooltip'}
							data-tooltip-content={`${roundedCheckoutPercent}%`}
							className={styles.chart}
							style={{
								height: `${roundedCheckoutPercent}%`,
							}}
						/>
					</div>
				</div>
				<div className={`${styles.merger} ${!purchasedPercent && styles.inactive}`}>
					<div
						className={styles.mergerItem}
						style={{
							height:
								findBiggerAndSmallerNumber(checkoutPercent, purchasedPercent).bigger * 100 + '%',
							borderTop: `${Math.ceil(
								barChartHeight *
									findBiggerAndSmallerNumber(checkoutPercent, purchasedPercent).bigger -
									barChartHeight *
										findBiggerAndSmallerNumber(checkoutPercent, purchasedPercent).smaller
							)}px solid transparent`,
							transform: `${checkoutPercent > purchasedPercent ? 'scaleX(1)' : 'scaleX(-1)'}`,
						}}
					></div>
				</div>
				<div className={styles.barChart}>
					<div className={styles.chartWrapper}>
						<div
							data-tooltip-id={'my-tooltip'}
							data-tooltip-content={`${roundedPurchasedPercent}%`}
							className={styles.chart}
							style={{
								height: `${roundedPurchasedPercent}%`,
							}}
						/>
					</div>
				</div>
				<div className={`${styles.merger} ${!abandonedPercent && styles.inactive}`}>
					<div
						className={styles.mergerItem}
						style={{
							height:
								findBiggerAndSmallerNumber(purchasedPercent, abandonedPercent).bigger * 100 + '%',
							borderTop: `${
								barChartHeight *
									findBiggerAndSmallerNumber(purchasedPercent, abandonedPercent).bigger -
								barChartHeight *
									findBiggerAndSmallerNumber(purchasedPercent, abandonedPercent).smaller
							}px solid transparent`,
							transform: `${purchasedPercent > abandonedPercent ? 'scaleX(1)' : 'scaleX(-1)'}`,
						}}
					></div>
				</div>
				<div className={styles.barChart}>
					<div className={styles.chartWrapper}>
						<div
							data-tooltip-id={'my-tooltip'}
							data-tooltip-content={`${roundedAbandonedPercent}%`}
							className={styles.chart}
							style={{
								height: `${roundedAbandonedPercent}%`,
							}}
						/>
					</div>
				</div>
			</div>
			<div className={styles.chartNames}>
				<p className={styles.chartName}>Traffic</p>

				<p className={styles.chartName}>Checkout</p>

				<p className={styles.chartName}>Purchased</p>

				<p className={styles.chartName}>Abandoned</p>
			</div>
		</div>
	)
}

export default ConversionRate
