import React, { ReactNode, useState, useEffect, Component, ErrorInfo } from 'react'
import styles from './ErrorBoundary.module.scss'
import pageNotFoundImg from '../../assets/images/404.png'
import NoDataComponent from '../NoDataComponent/NoDataComponent'
import { useNavigate } from 'react-router-dom'
import { LinearClient } from '@linear/sdk'

interface ErrorBoundaryProps {
	children?: ReactNode
}

interface ErrorBoundaryState {
	hasError: boolean
	error: Error | null
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	public state: ErrorBoundaryState = {
		hasError: false,
		error: null,
	}

	public static getDerivedStateFromError(_: Error): ErrorBoundaryState {
		return { hasError: true, error: _ }
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		// console.error('Uncaught error:', error, errorInfo)
	}

	public render() {
		if (this.state.hasError && this.state.error) {
			return (
				<ErrorViewer
					error={this.state.error}
					hasError={this.state.hasError}
					setHasError={value => (this.state.hasError = value)}
				/>
			)
		}

		return this.props.children
	}
}

interface ErrorViewerProps {
	hasError: boolean
	setHasError: (value: boolean) => void
	error: Error
}

const ErrorViewer: React.FC<ErrorViewerProps> = ({ hasError, error, setHasError }) => {
	const apiKey = 'lin_api_Pl4boueIHoNxolsUDbCFqx8SCBPTiXkcEuMFq27E'

	const linearClient = new LinearClient({
		apiKey: apiKey,
	})

	const createIssue = async (error: Error) => {
		const teams = await linearClient.teams()
		const team = teams.nodes[teams.nodes.length - 1]
		if (team.id) {
			await linearClient.createIssue({
				teamId: team.id,
				title: 'Unhandled Error',
				description: `
				Path: ${window.location.pathname}
				Error Message: "${error.message}"`,
			})
		}
	}

	useEffect(() => {
		if (hasError && window.location.hostname !== 'localhost') {
			createIssue(error)
		}
	}, [hasError])

	return (
		<NoDataComponent
			imageSrc={pageNotFoundImg}
			title={'Unhandled Error'}
			description={
				'Sorry about that. There seems to be an error with your request, please contact <a href="https://support.billgang.com/en/">Billgang Support</a>.'
			}
			button={{
				text: 'Go Home',
				onClick: () => {
					setHasError(false)
					localStorage.removeItem('accessToken')
					localStorage.removeItem('shopId')
					window.location.pathname = '/'
				},
			}}
		/>
	)
}

export default ErrorBoundary
