import React, { useEffect, useRef, useState } from 'react'
import styles from './AdvancedFiltering.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { useOutsideClick } from '../../hooks/useOutsideClick'

const AdvancedFiltering = () => {
	const [isActive, setIsActive] = useState(false)
	const advancedFilteringRef = useRef<HTMLDivElement>(null)
	useOutsideClick(
		advancedFilteringRef,
		() => {
			setIsActive(false)
		},
		!isActive
	)

	useEffect(() => {
		if (isActive) {
			advancedFilteringRef.current?.classList.add(styles.active)

			setTimeout(() => {
				advancedFilteringRef.current?.classList.add(styles.visible)
			}, 20)
		} else {
			advancedFilteringRef.current?.classList.remove(styles.visible)

			setTimeout(() => {
				advancedFilteringRef.current?.classList.remove(styles.active)
			}, 300)
		}
	}, [isActive])

	return (
		<div className={styles.advancedFiltering} ref={advancedFilteringRef}>
			<div className={styles.front} onClick={() => setIsActive(true)}>
				<SpriteIcon iconId={'gear'} width={13} height={12} />
			</div>

			<div className={styles.body}>
				<h2 className={styles.title}>Advanced Filtering</h2>
				<p className={styles.description}>
					Contact our sales team to upgrade to our Scale plan for advanced filtering.
				</p>
				<Button
					style={ButtonStyles.BORDERED}
					icon={{
						id: 'blankWindow',
						width: 13,
						height: 13,
						align: ButtonsIconAlign.LEFT,
					}}
					disableShadow={true}
					onClick={() =>
						//@ts-ignore
						window.Intercom('show')
					}
				>
					Contact Sales
				</Button>
			</div>
		</div>
	)
}

export default AdvancedFiltering
