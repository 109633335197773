import React, { FC } from 'react'
import styles from './ProductVariantHead.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../../components/UI/Button/Button'
import getSymbolFromCurrency from 'currency-symbol-map'
import { SpriteIcon } from '../../../../components/UI/SpriteIcon/SpriteIcon'

interface ProductVariantProps {
	title: string
	price: string
	currency: string
	onClick: () => void
	onDeleteClick: () => void
	onEditClick: () => void
	onMoveUpClick: () => void
	onMoveDownClick: () => void
	deliverables: number
	variantsLength: number
}

const ProductVariantHead: FC<ProductVariantProps> = ({
	onDeleteClick,
	onClick,
	title,
	price,
	currency,
	onEditClick,
	deliverables,
	onMoveUpClick,
	onMoveDownClick,
	variantsLength,
}) => {
	return (
		<div className={`${styles.productVariant}`} onClick={onClick}>
			<div className={styles.head}>
				<h1>{title}</h1>
				<div className={styles.info}>
					<p>
						{getSymbolFromCurrency(currency)}
						{Number(price)} / one-time <span className={styles.bullet}>•</span> {deliverables}{' '}
						deliverable
					</p>
				</div>
			</div>
			{variantsLength > 1 && (
				<div className={styles.tail} onClick={event => event.stopPropagation()}>
					<div className={styles.iconWrapper} onClick={onMoveUpClick}>
						<SpriteIcon className={styles.arrowUp} iconId={'arrowDown'} width={15} height={8} />
					</div>
					<div className={styles.iconWrapper} onClick={onMoveDownClick}>
						<SpriteIcon iconId={'arrowDown'} width={15} height={8} />
					</div>
					{/*<div className={styles.iconWrapper} onClick={() => onClick}>*/}
					{/*	<SpriteIcon iconId={'pen'} width={15} height={15} />*/}
					{/*</div>*/}

					<div className={styles.iconWrapper} onClick={onDeleteClick}>
						<SpriteIcon iconId={'trashBin'} width={14} height={15} />
					</div>
				</div>
			)}
		</div>
	)
}

export default ProductVariantHead
