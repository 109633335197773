import React, { FC, useEffect } from 'react'
import styles from './ProductGroupEdits.module.scss'
import { ListingVisibility } from '../../types/ListingVisibility/ListingVisibility'
import ImagePicker from '../ImagePicker/ImagePicker'
import RadioWrapper from '../UI/RadioWrapper/RadioWrapper'
import ImageViewer from '../UI/ImageViewer/ImageViewer'
import { Image } from '../../types/Image/Image'
import { FormErrors } from '../../types/FormErrors/FormErrors'

interface ProductEditsProps {
	images: Image[]
	setImages: (images: Image[]) => void
	visibility: ListingVisibility
	setVisibility: (value: ListingVisibility) => void
}

const ProductGroupEdits: FC<ProductEditsProps> = ({
	images,
	setImages,
	visibility,
	setVisibility,
}) => {
	return (
		<div className={styles.ProductGroupEdits}>
			<div className={styles.fieldWrapper}>
				<p className={styles.fieldTitle}>Media</p>
				<div className={styles.mediaGrid}>
					<ImagePicker setImages={selectedImages => setImages([...images, ...selectedImages])} />
					{images.map(image => (
						<ImageViewer
							path={image.cfId}
							onDeleteClick={() => setImages(images.filter(item => item.id !== image.id))}
							key={image.id}
						/>
					))}
				</div>
				{/*<HelpLink href={'//'} text={'View help article for best image practices'} />*/}
			</div>
			<div className={styles.horLine} />
			<div className={styles.fieldWrapper}>
				<div className={styles.radios}>
					<RadioWrapper
						id={'visible'}
						value={ListingVisibility.PUBLIC}
						setValue={value => setVisibility(value as ListingVisibility)}
						checked={visibility === ListingVisibility.PUBLIC}
						title={'Visible (public)'}
						description={'Available to any customer.'}
					/>

					<RadioWrapper
						id={'hidden'}
						value={ListingVisibility.HIDDEN}
						setValue={value => setVisibility(value as ListingVisibility)}
						checked={visibility === ListingVisibility.HIDDEN}
						title={'HIDDEN (LINK)'}
						description={'Viewed with a direct product link.'}
					/>

					<RadioWrapper
						id={'private'}
						value={ListingVisibility.PRIVATE}
						setValue={value => setVisibility(value as ListingVisibility)}
						checked={visibility === ListingVisibility.PRIVATE}
						title={'HIDDEN (PRIVATE)'}
						description={'Product cannot be viewed.'}
					/>
				</div>
			</div>
		</div>
	)
}

export default ProductGroupEdits
