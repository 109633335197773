import { useEffect, useState } from 'react'
import { useAppContext } from './useAppContext'
import { AxiosError, AxiosResponse } from 'axios'

export const useAPI = <T>(
	request: (data: any) => Promise<any>,
	loadOnStart: boolean,
	successMessage?: string,
	functions?: {
		validator?: () => boolean
		onSuccess?: (response: AxiosResponse<{ data: T }>) => void
		onError?: (error: AxiosError) => void
		showErrorValidator?: (error: any) => boolean
	}
): [boolean, T | undefined, <K>(data?: K) => void] => {
	const [isLoading, setIsLoading] = useState(false)
	const { setActionMessage } = useAppContext()
	const [data, setData] = useState<T>()

	const requestWrapper = <K>(data?: K) => {
		if (functions?.validator ? functions.validator() : true) {
			setIsLoading(true)
			request(data)
				.then(response => {
					if (response) {
						functions?.onSuccess && functions.onSuccess(response)
						if (successMessage) {
							setActionMessage({
								status: true,
								isActive: true,
								message: successMessage,
								title: 'Success',
							})
						}

						setData(response.data?.data || true)
					}
				})
				.catch(error => {
					if (
						!(error?.config?.url.includes('user') && !error.response?.data.errors) &&
						(functions?.showErrorValidator ? functions?.showErrorValidator(error) : true)
					) {
						setActionMessage({
							status: false,
							isActive: true,
							message:
								(error.response?.data.errors && error.response?.data.errors[0]) ||
								'Unhandled error',
							title: error.response?.data.message || 'error',
						})
					}

					functions?.onError && functions.onError(error)
				})
				.finally(() => setIsLoading(false))
		}
	}

	useEffect(() => {
		if (loadOnStart) requestWrapper(null)
	}, [])

	return [isLoading, data, requestWrapper]
}
