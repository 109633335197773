export enum ShopForUserPermissions {
	OWNER = 'OWNER',
	ADMIN = 'ADMIN',
	ANALYTICS_VIEW = 'ANALYTICS_VIEW',
	ANALYTICS_DELETE = 'ANALYTICS_DELETE',
	SETTINGS_PAYMENTS_VIEW = 'SETTINGS_PAYMENTS_VIEW',
	SETTINGS_PAYMENTS_MODIFY = 'SETTINGS_PAYMENTS_MODIFY',
	SETTINGS_GENERAL_VIEW = 'SETTINGS_GENERAL_VIEW',
	SETTINGS_GENERAL_MODIFY = 'SETTINGS_GENERAL_MODIFY',
	ORDERS_VIEW = 'ORDERS_VIEW',
	ORDERS_MODIFY = 'ORDERS_MODIFY',
	STOCK_SYSTEMS_VIEW = 'STOCK_SYSTEMS_VIEW',
	STOCK_SYSTEMS_MODIFY = 'STOCK_SYSTEMS_MODIFY',
	STOCK_SYSTEMS_DELETE = 'STOCK_SYSTEMS_DELETE',
	CHARGES_VIEW = 'CHARGES_VIEW',
	CHARGES_MODIFY = 'CHARGES_MODIFY',
	COUPONS_VIEW = 'COUPONS_VIEW',
	COUPONS_MODIFY = 'COUPONS_MODIFY',
	COUPONS_DELETE = 'COUPONS_DELETE',
	REVIEWS_VIEW = 'REVIEWS_VIEW',
	REVIEWS_MODIFY = 'REVIEWS_MODIFY',
	CUSTOMERS_VIEW = 'CUSTOMERS_VIEW',
	CUSTOMERS_MODIFY = 'CUSTOMERS_MODIFY',
	CONTENT_VIEW = 'CONTENT_VIEW',
	CONTENT_MODIFY = 'CONTENT_MODIFY',
	CATEGORIES_VIEW = 'CATEGORIES_VIEW',
	CATEGORIES_MODIFY = 'CATEGORIES_MODIFY',
	CONTENT_DELETE = 'CONTENT_DELETE',
	CATEGORIES_DELETE = 'CATEGORIES_DELETE',
	CUSTOMIZATION_VIEW = 'CUSTOMIZATION_VIEW',
	CUSTOMIZATION_MODIFY = 'CUSTOMIZATION_MODIFY',
	DEVELOPERS_VIEW = 'DEVELOPERS_VIEW',
	DEVELOPERS_MODIFY = 'DEVELOPERS_MODIFY',
	DEVELOPERS_DELETE = 'DEVELOPERS_DELETE',
	PRODUCTS_VIEW = 'PRODUCTS_VIEW',
	PRODUCTS_MODIFY = 'PRODUCTS_MODIFY',
	PRODUCTS_DELETE = 'PRODUCTS_DELETE',
	TICKETS_VIEW = 'TICKETS_VIEW',
	TICKETS_MODIFY = 'TICKETS_MODIFY',
	AFFILIATES_VIEW = 'AFFILIATES_VIEW',
	AFFILIATES_MODIFY = 'AFFILIATES_MODIFY',
	FRAUD_VIEW = 'FRAUD_VIEW',
	FRAUD_MODIFY = 'FRAUD_MODIFY',
	FRAUD_DELETE = 'FRAUD_DELETE',
}
