import React, { FC } from 'react'
import styles from './ProductVariantsAdvanced.module.scss'
import generalStyles from '../../CreateProductPage.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../../components/UI/Button/Button'
import Switch from '../../../../components/UI/Switch/Switch'
import Textarea from '../../../../components/UI/FormComponents/Textarea'
import CustomMDEditor from '../../../../components/UI/CustomMDEditor/CustomMDEditor'
import Input from '../../../../components/UI/FormComponents/Input'
import CustomSelect, { SelectStyles } from '../../../../components/UI/CustomSelect/CustomSelect'
import { VariantsAdvanced } from '../../../../types/CreateVariants/VariantsAdvanced'
import CheckboxWrapper from '../../../../components/UI/CheckboxWrapper/CheckboxWrapper'
import { warrantySelectOptions } from '../../../../helpers/warrantySelectOptions'
import { useNavigate } from 'react-router-dom'
import InputWithText from '../../../../components/UI/FormComponents/InputWithText'

interface ProductVariantsAdvancedProps {
	setVariantsAdvanced: (value: VariantsAdvanced) => void
	variantsAdvanced: VariantsAdvanced
	currentVariantIndex: string
}

const ProductVariantsAdvanced: FC<ProductVariantsAdvancedProps> = ({
	setVariantsAdvanced,
	variantsAdvanced,
	currentVariantIndex,
}) => {
	const navigate = useNavigate()

	return (
		<div className={styles.productVariantsAdvanced}>
			<div className={generalStyles.fieldWrapper}>
				<header>
					<div>
						<p className={generalStyles.fieldTitle}>Note to Customer</p>
						<p className={generalStyles.fieldDescription}>
							Send a note to the customer after checkout via email.
						</p>
					</div>
					<Switch
						checked={variantsAdvanced[currentVariantIndex].isCustomerNoteEnabled}
						setChecked={checked =>
							setVariantsAdvanced({
								...variantsAdvanced,
								[currentVariantIndex]: {
									...variantsAdvanced[currentVariantIndex],
									isCustomerNoteEnabled: checked,
								},
							})
						}
					/>
				</header>
				{variantsAdvanced[currentVariantIndex].isCustomerNoteEnabled && (
					<div className={generalStyles.mtMediumLarge}>
						<Textarea
							value={variantsAdvanced[currentVariantIndex].customerNote}
							setValue={value =>
								setVariantsAdvanced({
									...variantsAdvanced,
									[currentVariantIndex]: {
										...variantsAdvanced[currentVariantIndex],
										customerNote: value,
									},
								})
							}
							placeholder={
								'To activate your one-time access software, head over to https://software.com/access and enter the license key.'
							}
							height={125}
						/>
					</div>
				)}
			</div>

			<div className={generalStyles.horLine} />

			<div className={generalStyles.fieldWrapper}>
				<header>
					<div>
						<p className={generalStyles.fieldTitle}>Terms of Service</p>
						<p className={generalStyles.fieldDescription}>
							Write a unique terms of service that will be linked to this product.
						</p>
					</div>
					<Button
						style={ButtonStyles.THIRD}
						icon={{
							id: 'blankWindow',
							width: 12,
							height: 12,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={() => window.open(`/settings/legal`, '_blank', 'noopener')}
					>
						Edit Terms of Service
					</Button>
				</header>
				{variantsAdvanced[currentVariantIndex].isTermsEnabled && (
					<div className={generalStyles.mtMediumLarge}>
						<CustomMDEditor
							value={variantsAdvanced[currentVariantIndex].terms}
							setValue={value =>
								setVariantsAdvanced({
									...variantsAdvanced,
									[currentVariantIndex]: {
										...variantsAdvanced[currentVariantIndex],
										terms: value,
									},
								})
							}
						/>
					</div>
				)}
			</div>
			<div className={generalStyles.fieldWrapper}>
				<header>
					<div>
						<p className={generalStyles.fieldTitle}>Privacy Policy</p>
						<p className={generalStyles.fieldDescription}>
							This details how you’ll use customer data and information for your business.
						</p>
					</div>
					<Button
						style={ButtonStyles.THIRD}
						icon={{
							id: 'blankWindow',
							width: 12,
							height: 12,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={() => window.open(`/settings/legal`, 'blank', 'noopener')}
					>
						Edit Privacy Policy
					</Button>
				</header>
			</div>

			<div className={generalStyles.fieldWrapper}>
				<header>
					<div>
						<p className={generalStyles.fieldTitle}>Refund Policy</p>
						<p className={generalStyles.fieldDescription}>
							A policy to protect you against potentially fraudulent customers that may harm your
							sales.
						</p>
					</div>
					<Button
						style={ButtonStyles.THIRD}
						icon={{
							id: 'blankWindow',
							width: 12,
							height: 12,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={() => window.open(`/settings/legal`, 'blank', 'noopener')}
					>
						Edit Refund Policy
					</Button>
				</header>
			</div>

			{/*<div className={generalStyles.mtMediumLarge}>*/}
			{/*	<CheckboxWrapper*/}
			{/*		id={''}*/}
			{/*		checked={false}*/}
			{/*		setChecked={() => {}}*/}
			{/*		title={'TERMS OF SERVICE CONSENT'}*/}
			{/*		description={'Require customers to consent to your terms of service prior to checkout.'}*/}
			{/*	/>*/}
			{/*</div>*/}

			<div className={generalStyles.horLine} />

			<div className={generalStyles.fieldWrapper}>
				<header>
					<div>
						<p className={generalStyles.fieldTitle}>Redirect Link</p>
						<p className={`${generalStyles.fieldDescription}`}>
							Instantly redirect customers to another link after checkout.
						</p>
					</div>
					<Switch
						checked={variantsAdvanced[currentVariantIndex].isRedirectUrlEnabled}
						setChecked={checked =>
							setVariantsAdvanced({
								...variantsAdvanced,
								[currentVariantIndex]: {
									...variantsAdvanced[currentVariantIndex],
									isRedirectUrlEnabled: checked,
								},
							})
						}
					/>
				</header>
				{variantsAdvanced[currentVariantIndex].isRedirectUrlEnabled && (
					<>
						<div className={styles.mt10}>
							<Input
								value={variantsAdvanced[currentVariantIndex].redirectUrl}
								setValue={value =>
									setVariantsAdvanced({
										...variantsAdvanced,
										[currentVariantIndex]: {
											...variantsAdvanced[currentVariantIndex],
											redirectUrl: value,
										},
									})
								}
								placeholder={'https://google.com'}
							/>
						</div>
					</>
				)}
			</div>
			<div className={generalStyles.fieldWrapper}>
				<header>
					<div>
						<p className={generalStyles.fieldTitle}>Warranty Period</p>
						<p className={generalStyles.fieldDescription}>
							Customize a warranty period for refunds. Customers won't be able to leave a negative
							review if they don't contact you.
						</p>
					</div>
					<Switch
						checked={variantsAdvanced[currentVariantIndex].isWarrantyEnabled}
						setChecked={checked =>
							setVariantsAdvanced({
								...variantsAdvanced,
								[currentVariantIndex]: {
									...variantsAdvanced[currentVariantIndex],
									isWarrantyEnabled: checked,
								},
							})
						}
					/>
				</header>

				{variantsAdvanced[currentVariantIndex].isWarrantyEnabled && (
					<div className={styles.mt10}>
						<CustomSelect
							style={SelectStyles.secondary}
							value={variantsAdvanced[currentVariantIndex].warranty?.durationSeconds}
							setValue={value =>
								setVariantsAdvanced({
									...variantsAdvanced,
									[currentVariantIndex]: {
										...variantsAdvanced[currentVariantIndex],
										warranty: {
											...variantsAdvanced[currentVariantIndex].warranty,
											durationSeconds: value as any,
										},
									},
								})
							}
							options={warrantySelectOptions}
						/>
					</div>
				)}

				{variantsAdvanced[currentVariantIndex].warranty?.durationSeconds === 'manual' && (
					<div className={styles.mt10}>
						<InputWithText
							text={'seconds'}
							type={'number'}
							placeholder={'0'}
							value={variantsAdvanced[currentVariantIndex].customWarranty}
							setValue={value =>
								setVariantsAdvanced({
									...variantsAdvanced,
									[currentVariantIndex]: {
										...variantsAdvanced[currentVariantIndex],
										customWarranty: value,
									},
								})
							}
						/>
					</div>
				)}

				{variantsAdvanced[currentVariantIndex].isWarrantyEnabled &&
				variantsAdvanced[currentVariantIndex].warranty?.durationSeconds ? (
					<div className={styles.mt10}>
						<Textarea
							value={variantsAdvanced[currentVariantIndex].warranty.text}
							setValue={value =>
								setVariantsAdvanced({
									...variantsAdvanced,
									[currentVariantIndex]: {
										...variantsAdvanced[currentVariantIndex],
										warranty: {
											...variantsAdvanced[currentVariantIndex].warranty,
											text: value,
										},
									},
								})
							}
							height={125}
							placeholder={
								'All payments are final and you will not receive a refund after this purchase. However, you can reach out to our support team for assistance at any point.'
							}
						/>
					</div>
				) : null}
			</div>
		</div>
	)
}

export default ProductVariantsAdvanced
