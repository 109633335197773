import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageHeader from '../../components/PageHeader/PageHeader'
import styles from './ImagesPage.module.scss'
import SearchInput, { SearchInputStyles } from '../../components/UI/SearchInput/SearchInput'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../../hooks/useAppContext'
import PostsTable from '../../components/Tables/PostsTable'
import Pagination from '../../components/UI/Pagination/Pagination'
import ImagesTable from '../../components/Tables/ImagesTable'
import Modal from '../../components/Modals/Modal'
import { closeModal } from '../../helpers/closeModal'
import AddImageModal from '../../components/Modals/AddImageModal/AddImageModal'
import { useAPI } from '../../hooks/useAPI'
import { ImagesService } from '../../API/ImagesService'
import { Image } from '../../types/Image/Image'
import Spinner from '../../components/UI/Spinner/Spinner'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import noImagesImage from '../../assets/images/noImages.png'
import PageTableHeader from '../../components/PageTableHeader/PageTableHeader'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import noPostsImage from '../../assets/images/noPosts.png'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import { useDebounce } from '../../hooks/useDebounce'
import { usePageTitle } from '../../hooks/usePageTitle'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

const ImagesPage = () => {
	const [searchValue, setSearchValue] = useState('')
	const [totalItems, setTotalItems] = useState(0)
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [isUploadImageModalActive, setIsUploadImageModalActive] = useState(false)
	const [isUploadImageModalVisible, setIsUploadImageModalVisible] = useState(false)
	const [deletingImageId, setDeletingImageId] = useState<number | null>(null)
	const [isDeleteImageModalVisible, setIsDeleteImageModalVisible] = useState(false)
	const [isDeleteImageModalActive, setIsDeleteImageModalActive] = useState(false)
	usePageTitle('Images')
	const { shop } = useAppContext()
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isContentViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.CONTENT_VIEW) || isAdmin
	const isContentModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.CONTENT_MODIFY) || isAdmin
	const isContentDeleteAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.CONTENT_DELETE) || isAdmin

	const [isGetImagesLoading, images, getImages] = useAPI<Image[]>(
		() => ImagesService.getAll(shop?.id || '', searchValue, currentPage + 1, pageSize),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	const isFirstLoading = useFirstLoading(isGetImagesLoading)
	useDebounce(searchValue, getImages, 400)

	const [isDeleteImageLoading, , deleteImage] = useAPI(
		() => ImagesService.delete(shop?.id || '', deletingImageId),
		false,
		'Image successfully deleted',
		{
			onSuccess: response => {
				getImages()
				closeDeleteModal()
			},
		}
	)

	const closeDeleteModal = () => {
		closeModal(setIsDeleteImageModalActive, setIsDeleteImageModalVisible)
		setDeletingImageId(null)
	}

	const onDeleteImageClickHandler = (id: number) => {
		setIsDeleteImageModalActive(true)
		setDeletingImageId(id)
	}

	useEffect(() => {
		if (shop && isContentViewAvailable) {
			getImages()
		}
	}, [shop, currentPage, pageSize])

	if (isFirstLoading) return <PageLoading />

	if (!isContentViewAvailable)
		return (
			<NoDataComponent
				imageSrc={noImagesImage}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)

	return (
		<PageContainer>
			{isUploadImageModalActive && (
				<Modal
					closeModal={() => closeModal(setIsUploadImageModalActive, setIsUploadImageModalVisible)}
					visible={isUploadImageModalVisible}
					setVisible={setIsUploadImageModalVisible}
				>
					<AddImageModal
						getImages={getImages}
						onClose={() => closeModal(setIsUploadImageModalActive, setIsUploadImageModalVisible)}
					/>
				</Modal>
			)}
			{isDeleteImageModalActive && (
				<Modal
					closeModal={closeDeleteModal}
					visible={isDeleteImageModalVisible}
					setVisible={setIsDeleteImageModalVisible}
				>
					<ConfirmModal
						title={'Delete Image'}
						description={
							'This image will be deleted from your gallery forever. You will not be able to access it in the future. Are you sure you want to delete this image?'
						}
						onConfirm={deleteImage}
						isButtonLoading={isDeleteImageLoading}
						onClose={closeDeleteModal}
						buttonIcon={{
							id: 'trashBin',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						confirmButtonText={'Delete Image'}
					/>
				</Modal>
			)}
			<PageWrapper>
				{!images?.length && !searchValue ? (
					<NoDataComponent
						imageSrc={noImagesImage}
						title={'No Images'}
						description={
							'Upload an image to add to your gallery. You can access these images at any time.'
						}
						button={
							isContentModifyAvailable
								? {
										icon: {
											id: 'plus',
											height: 13,
											width: 13,
											align: ButtonsIconAlign.LEFT,
										},
										onClick: () => setIsUploadImageModalActive(true),
										text: 'New Image',
								  }
								: null
						}
					/>
				) : (
					<div className={styles.imagesPage}>
						<PageHeader title={'Images'}>
							<div className={styles.headerEnd}>
								<SearchInput
									style={SearchInputStyles.secondary}
									value={searchValue}
									setValue={setSearchValue}
									placeholder={'Search for an image'}
								/>
								{isContentModifyAvailable && (
									<Button
										style={ButtonStyles.SECONDARY}
										icon={{
											id: 'plus',
											height: 13,
											width: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										onClick={() => setIsUploadImageModalActive(true)}
									>
										New Image
									</Button>
								)}
							</div>
						</PageHeader>

						<PageTableHeader
							listItemNamePlural={'images'}
							pageSize={pageSize}
							setPageSize={setPageSize}
							totalItems={totalItems}
							setCurrentPage={setCurrentPage}
						/>

						{isGetImagesLoading ? (
							<Spinner />
						) : (
							images && (
								<div className={styles.tableWrapper}>
									<ImagesTable
										images={images}
										onDeleteClick={onDeleteImageClickHandler}
										isContentDeleteAvailable={isContentDeleteAvailable}
									/>
								</div>
							)
						)}

						<div className={styles.paginationWrapper}>
							<Pagination
								withoutPageSizeSelector={true}
								totalItems={totalItems}
								pageSize={pageSize}
								setPageSize={setPageSize}
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
							/>
						</div>
					</div>
				)}
			</PageWrapper>
		</PageContainer>
	)
}

export default ImagesPage
