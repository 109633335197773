import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import AlertBadge from '../../UI/AlertBadge/AlertBadge'
import { Alert } from '../../../types/Alert/Alert'
import Input from '../../UI/FormComponents/Input'
import { useAPI } from '../../../hooks/useAPI'
import { DomainsService } from '../../../API/DomainsService'
import { useAppContext } from '../../../hooks/useAppContext'

interface ConnectDomainModalProps extends BaseModalProps {
	getDomains: () => void
}

const ConnectDomainModal: FC<ConnectDomainModalProps> = ({ onClose, getDomains }) => {
	const [domain, setDomain] = useState('')
	const { shop } = useAppContext()

	const [isConnectDomainLoading, , connectDomain] = useAPI(
		() => DomainsService.add(shop?.id || '', { hostname: domain }),
		false,
		'Domain successfully connected',
		{
			onSuccess: () => {
				getDomains()
				onClose()
			},
		}
	)

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Connect Domain</h1>
			</header>

			<div className={styles.body}>
				<AlertBadge alert={Alert.INFORMATION} disableShadow={true}>
					<p>
						Follow this{' '}
						<a
							href='https://support.billgang.com/en/articles/8915889-how-do-i-use-a-custom-domain-for-my-store'
							target={'_blank'}
						>
							{' '}
							help article
						</a>{' '}
						to connect your custom domain to Billgang.
					</p>
				</AlertBadge>

				<div className={styles.fieldWrapper}>
					<h2>Website Domain</h2>
					<Input value={domain} setValue={setDomain} placeholder={'billgang.store'} />
					<p className={styles.fieldDescription}>
						It may take up to 5-10 minutes to get your custom domain set up with Billgang.
					</p>
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'arrowRight',
							width: 13,
							height: 12,
							align: ButtonsIconAlign.RIGHT,
						}}
						disableShadow={true}
						onClick={connectDomain}
						isLoading={isConnectDomainLoading}
					>
						Connect Domain
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default ConnectDomainModal
