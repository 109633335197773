import React, { useEffect, useState } from 'react'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import styles from './DiscordBotBuilderPage.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import { usePageTitle } from '../../hooks/usePageTitle'
import StatusAlertBadge from '../../components/UI/StatusAlertBadge/StatusAlertBadge'
import { StatusAlert } from '../../types/StatusAlert/StatusAlert'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import Tabs from '../../components/UI/Tabs/Tabs'
import ConnectingBotSteps from './components/ConnectingBotSteps'
import ConnectDiscordStep from './components/ConnectDiscordStep'
import SelectOptionsStep from './components/SelectOptionsStep'
import ConnectBotStep from './components/ConnectBotStep'
import CustomizeBotStep from './components/CustomizeBotStep'
import VerifyBotStep from './components/VerifyBotStep'
import DiscordBotBuilderServersTab from './components/DiscordBotBuilderServersTab'
import { useAPI } from '../../hooks/useAPI'
import { SecuritySettings } from '../../types/SecuritySettings/SecuritySettings'
import { UserSecurityService } from '../../API/UserSecurityService'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import { DiscordClusterType } from '../../types/DiscordClusterType/DiscordClusterType'
import { DiscordClusterBotStatus } from '../../types/DiscordClusterBotStatus/DiscordClusterBotStatus'
import { DiscordBotsService } from '../../API/DiscordBotsService'
import { useAppContext } from '../../hooks/useAppContext'
import { FormErrors } from '../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../helpers/fieldValidators'
import { GetDiscordCluster } from '../../types/GetDiscordCluster/GetDiscordCluster'
import DiscordBotStatus from '../../components/UI/StatusBadges/DiscordBotStatus'
import ActionMenu from '../../components/UI/ActionMenu/ActionMenu'
import ReadyBot from './components/ReadyBot'
import Modal from '../../components/Modals/Modal'
import { closeModal } from '../../helpers/closeModal'
import EditDiscordBotModal from '../../components/Modals/EditDiscordBotModal/EditDiscordBotModal'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'

export enum BotConnectingSteps {
	CONNECT_DISCORD,
	SELECT_OPTION,
	PRIMARY_BOT,
	SECONDARY_BOT,
	CUSTOMIZE_BOT,
	VERIFY_BOT,
}

const DiscordBotBuilderPage = () => {
	usePageTitle('Discord Bot Builder')
	const { shop } = useAppContext()
	const [formErrors, setFormErrors] = useState<FormErrors>({})
	const [currentTab, setCurrentTab] = useState<'bots' | 'servers'>('bots')
	const [currentStep, setCurrentStep] = useState<BotConnectingSteps>(
		BotConnectingSteps.CONNECT_DISCORD
	)
	const [isEditDiscordBotModalActive, setIsEditDiscordBotModalActive] = useState(false)
	const [isEditDiscordBotModalVisible, setIsEditDiscordBotModalVisible] = useState(false)
	const [isDeleteDiscordModalActive, setIsDeleteDiscordBotModalActive] = useState(false)
	const [isDeleteDiscordBotModalVisible, setIsDeleteDiscordBotModalVisible] = useState(false)
	const [discordClusterType, setDiscordClusterType] = useState<DiscordClusterType | null>(null)
	const [editingDiscordBotType, setEditingDiscordBotType] = useState<'secondary' | 'primary'>(
		'secondary'
	)
	const [botCustomizes, setBotCustomizes] = useState({
		botStatus: DiscordClusterBotStatus.ONLINE,
		botActivityText: '',
	})
	const [botSettings, setBotSettings] = useState({
		primary: {
			botToken: '',
			clientId: '',
			clientSecret: '',
		},
		secondary: {
			botToken: '',
			clientId: '',
			clientSecret: '',
		},
	})

	const createBotValidator = () => {
		const primaryBotTokenValidator =
			currentStep === BotConnectingSteps.PRIMARY_BOT
				? fieldValidators.blankValidator('Bot Token', botSettings.primary.botToken)
				: ''
		const primaryClientIdValidator =
			currentStep === BotConnectingSteps.PRIMARY_BOT
				? fieldValidators.blankValidator('Client Id', botSettings.primary.clientId)
				: ''
		const primaryClientSecretValidator =
			currentStep === BotConnectingSteps.PRIMARY_BOT
				? fieldValidators.blankValidator('Client Secret', botSettings.primary.clientSecret)
				: ''
		const secondaryBotTokenValidator =
			currentStep === BotConnectingSteps.SECONDARY_BOT
				? fieldValidators.blankValidator('Bot Token', botSettings.secondary.botToken)
				: ''
		const secondaryClientIdValidator =
			currentStep === BotConnectingSteps.SECONDARY_BOT
				? fieldValidators.blankValidator('Client Id', botSettings.secondary.clientId)
				: ''
		const secondaryClientSecretValidator =
			currentStep === BotConnectingSteps.SECONDARY_BOT
				? fieldValidators.blankValidator('Client Secret', botSettings.secondary.clientSecret)
				: ''

		setFormErrors({
			primaryBotToken: primaryBotTokenValidator,
			primaryClientId: primaryClientIdValidator,
			primaryClientSecret: primaryClientSecretValidator,
			secondaryBotToken: secondaryBotTokenValidator,
			secondaryClientId: secondaryClientIdValidator,
			secondaryClientSecret: secondaryClientSecretValidator,
		})

		return !(
			primaryBotTokenValidator ||
			primaryClientIdValidator ||
			primaryClientSecretValidator ||
			secondaryBotTokenValidator ||
			secondaryClientIdValidator ||
			secondaryClientSecretValidator
		)
	}

	const [isGetSecuritySettingsLoading, securitySettings, getSecuritySettings] =
		useAPI<SecuritySettings>(() => UserSecurityService.getSecurity(), false)

	const [isGetDiscordClusterLoading, discordCluster, getDiscordCluster] = useAPI<GetDiscordCluster>(
		() => DiscordBotsService.get(shop?.id || ''),
		false
	)

	const [isDeleteDiscordClusterLoading, , deleteDiscordCluster] = useAPI(
		() => DiscordBotsService.delete(shop?.id || ''),
		false,
		'Discord Bot successfully deleted',
		{
			onSuccess: () => {
				getDiscordCluster()
				closeDeleteDiscordBotModal()
			},
		}
	)

	const [isCreateDiscordBotLoading, , createDiscordBot] = useAPI<SecuritySettings>(
		() =>
			DiscordBotsService.post(shop?.id || '', {
				type: discordClusterType || DiscordClusterType.Standard,
				primaryBot: {
					...botSettings.primary,
					botStatus: botCustomizes.botStatus,
					botActivityText: botCustomizes.botActivityText || null,
				},
				secondaryBot:
					discordClusterType === DiscordClusterType.Secured
						? {
								...botSettings.secondary,
								botStatus: botCustomizes.botStatus,
								botActivityText: botCustomizes.botActivityText || null,
						  }
						: null,
			}),
		false,
		'Discord Bot successfully created'
	)

	const onNextStepClick = () => {
		if (createBotValidator()) {
			if (currentStep < BotConnectingSteps.CUSTOMIZE_BOT) {
				if (
					currentStep === BotConnectingSteps.PRIMARY_BOT &&
					discordClusterType === DiscordClusterType.Standard
				) {
					return setCurrentStep(BotConnectingSteps.CUSTOMIZE_BOT)
				}

				setCurrentStep(currentStep => currentStep + 1)
			} else {
				createDiscordBot()
			}
		}
	}

	const onPrevStepClick = () => {
		if (currentStep > 0) {
			if (
				currentStep === BotConnectingSteps.CUSTOMIZE_BOT &&
				discordClusterType === DiscordClusterType.Standard
			) {
				return setCurrentStep(BotConnectingSteps.PRIMARY_BOT)
			}
			setCurrentStep(currentStep => currentStep - 1)
		}
	}

	const onEditDiscordBot = (botType: 'secondary' | 'primary') => {
		setIsEditDiscordBotModalActive(true)
		setEditingDiscordBotType(botType)
	}

	const closeDeleteDiscordBotModal = () => {
		closeModal(setIsDeleteDiscordBotModalActive, setIsDeleteDiscordBotModalVisible)
	}

	useEffect(() => {
		getSecuritySettings()

		if (shop) {
			getDiscordCluster()
		}
	}, [shop])

	return (
		<PageWrapper>
			{isEditDiscordBotModalActive && discordCluster && (
				<Modal
					closeModal={() =>
						closeModal(setIsEditDiscordBotModalActive, setIsEditDiscordBotModalVisible)
					}
					setVisible={setIsEditDiscordBotModalVisible}
					visible={isEditDiscordBotModalVisible}
				>
					<EditDiscordBotModal
						botType={editingDiscordBotType}
						discordCluster={discordCluster}
						onSuccess={() => getDiscordCluster()}
						onClose={() =>
							closeModal(setIsEditDiscordBotModalActive, setIsEditDiscordBotModalVisible)
						}
					/>
				</Modal>
			)}

			{isDeleteDiscordModalActive && (
				<Modal
					closeModal={closeDeleteDiscordBotModal}
					visible={isDeleteDiscordBotModalVisible}
					setVisible={setIsDeleteDiscordBotModalVisible}
				>
					<ConfirmModal
						title={'Delete Discord Bot'}
						description={
							'You’re about to delete discord bot. Are you sure you want to delete this discord bot? This cannot be undone.'
						}
						onConfirm={deleteDiscordCluster}
						isButtonLoading={isDeleteDiscordClusterLoading}
						confirmButtonText={'Delete Discord Bot'}
						onClose={closeDeleteDiscordBotModal}
						buttonIcon={{
							id: 'trashBin',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
					/>
				</Modal>
			)}

			{isGetSecuritySettingsLoading || isGetDiscordClusterLoading ? (
				<PageLoading />
			) : (
				<PageContainer>
					<div className={styles.discordBotBuilderPage}>
						<PageHeader title={'Builder'} />

						{discordClusterType === DiscordClusterType.Secured ? (
							<StatusAlertBadge
								title={'Secured Bot Note'}
								description={
									'For maximum impact and data security, you’re required to create two separate Discord bots. The primary bot will act as the core bot for authentication and redirects. The secondary bot will host additional services that’ll assist the primaries bot functions.'
								}
								statusAlertType={StatusAlert.INFORMATION}
							/>
						) : (
							<StatusAlertBadge
								title={'Custom Hosting'}
								description={
									'Our custom bots are built to power commerce for Discord-based communities. To learn more about how to get set up, please read our in-depth help article.'
								}
								statusAlertType={StatusAlert.INFORMATION}
								button={{
									icon: {
										id: 'blankWindow',
										height: 13,
										width: 13,
										align: ButtonsIconAlign.LEFT,
									},
									text: 'View article',
									onClick: () =>
										window.open(
											'https://support.billgang.com/en/articles/9257179-how-do-i-create-my-own-custom-discord-bot-for-my-business',
											'blank',
											'noopener'
										),
								}}
							/>
						)}

						{/*<div className={styles.tabsWrapper}>*/}
						{/*	<Tabs*/}
						{/*		style={'alternate'}*/}
						{/*		tabs={[*/}
						{/*			{*/}
						{/*				label: 'Bots',*/}
						{/*				value: 'bots',*/}
						{/*			},*/}
						{/*			{*/}
						{/*				label: 'Servers',*/}
						{/*				value: 'servers',*/}
						{/*			},*/}
						{/*		]}*/}
						{/*		currentTab={currentTab}*/}
						{/*		setCurrentTab={setCurrentTab}*/}
						{/*	/>*/}
						{/*</div>*/}

						{currentTab === 'bots' &&
							(!discordCluster ? (
								<div className={styles.section}>
									<ConnectingBotSteps
										currentStep={currentStep}
										setCurrentStep={setCurrentStep}
										discordClusterType={discordClusterType}
									/>
									{currentStep === BotConnectingSteps.CONNECT_DISCORD && (
										<ConnectDiscordStep securitySettings={securitySettings} />
									)}
									{currentStep === BotConnectingSteps.SELECT_OPTION && (
										<SelectOptionsStep
											discordClusterType={discordClusterType}
											setDiscordClusterType={setDiscordClusterType}
											currentStep={currentStep}
											setCurrentStep={setCurrentStep}
										/>
									)}
									{currentStep === BotConnectingSteps.PRIMARY_BOT && (
										<ConnectBotStep
											botSettings={botSettings}
											setBotSettings={setBotSettings}
											botType={'primary'}
											formErrors={formErrors}
											setFromErrors={setFormErrors}
										/>
									)}
									{currentStep === BotConnectingSteps.SECONDARY_BOT && (
										<ConnectBotStep
											botSettings={botSettings}
											setBotSettings={setBotSettings}
											botType={'secondary'}
											formErrors={formErrors}
											setFromErrors={setFormErrors}
										/>
									)}
									{currentStep === BotConnectingSteps.CUSTOMIZE_BOT && (
										<CustomizeBotStep
											botsCustomizes={botCustomizes}
											setBotsCustomizes={setBotCustomizes}
										/>
									)}
									{currentStep === BotConnectingSteps.VERIFY_BOT && <VerifyBotStep />}

									<div className={styles.buttons}>
										<Button
											icon={{
												id: 'arrowLeft',
												width: 13,
												height: 12,
												align: ButtonsIconAlign.LEFT,
											}}
											style={ButtonStyles.BORDERED}
											disableShadow={true}
											onClick={onPrevStepClick}
										>
											Back
										</Button>
										{currentStep !== BotConnectingSteps.SELECT_OPTION && (
											<Button
												icon={{
													id: 'arrowRight',
													width: 13,
													height: 12,
													align: ButtonsIconAlign.RIGHT,
												}}
												style={ButtonStyles.BORDERED}
												width={'100%'}
												disableShadow={true}
												onClick={onNextStepClick}
												isLoading={isCreateDiscordBotLoading}
											>
												{currentStep === BotConnectingSteps.CUSTOMIZE_BOT ? 'Create Bot' : 'Next'}
											</Button>
										)}
									</div>
								</div>
							) : (
								<div className={styles.mtMediumLarge}>
									{discordCluster?.primaryBot && (
										<ReadyBot
											botData={discordCluster?.primaryBot}
											botType={'primary'}
											onEditClick={() => onEditDiscordBot('primary')}
											onDeleteClick={() => setIsDeleteDiscordBotModalActive(true)}
										/>
									)}
									{discordCluster?.secondaryBot && (
										<ReadyBot
											botData={discordCluster?.secondaryBot}
											botType={'secondary'}
											onEditClick={() => onEditDiscordBot('secondary')}
											onDeleteClick={() => setIsDeleteDiscordBotModalActive(true)}
										/>
									)}
								</div>
							))}

						{currentTab === 'servers' && <DiscordBotBuilderServersTab />}
					</div>
				</PageContainer>
			)}
		</PageWrapper>
	)
}

export default DiscordBotBuilderPage
