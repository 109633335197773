import React from 'react'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import noAddonsImage from '../../assets/images/noAddons.png'
import { ButtonsIconAlign } from '../../components/UI/Button/Button'
import { usePageTitle } from '../../hooks/usePageTitle'

const AddonsPage = () => {
	usePageTitle('Add-ons')

	return (
		<NoDataComponent
			title={'Add-ons'}
			description={
				'We’re currently building out our add-ons feature and expect a launch in October 2024.'
			}
			imageSrc={noAddonsImage}
			button={{
				icon: {
					id: 'blankWindow',
					height: 12,
					width: 12,
					align: ButtonsIconAlign.RIGHT,
				},
				onClick: () => window.open('https://x.com/billgangcom', 'blank', 'noopener'),
				text: 'Follow us on X.com',
			}}
		/>
	)
}

export default AddonsPage
