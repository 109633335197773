import { privateAPI } from './API'

export const UserService = {
	get: async () => {
		return await privateAPI.get(`/v1/dash/user/new`)
	},
	logout: async () => {
		return await privateAPI.post(`/v1/dash/user/logout`)
	},
	completeEmailVerification: async (token: string) => {
		return await privateAPI.post(`/v1/dash/user/complete-email-verification`, {
			token,
		})
	},
	resendEmailVerification: async () => {
		return await privateAPI.post(`/v1/dash/user/resend-email-verification`)
	},
}
