import React, { useState } from 'react'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import styles from '../Settings.module.scss'
import Button, { ButtonStyles } from '../../../components/UI/Button/Button'
import ImageInput from '../../../components/UI/ImageInput/ImageInput'
import Input from '../../../components/UI/FormComponents/Input'
import CustomMDEditor from '../../../components/UI/CustomMDEditor/CustomMDEditor'
import Radio from '../../../components/UI/Radio/Radio'

const SettingsDesignPage = () => {
	const [imageFile, setImageFile] = useState<File | null>(null)

	return (
		<PageWrapper>
			<div className={styles.settingsPageInnerBody}>
				<div className={`${styles.settingSection}`}>
					<header className={styles.settingSectionHeader}>
						<p className={styles.settingSectionTitle}>Business logo</p>
						<p className={styles.settingSectionDescription}>
							This logo will be visible on your storefront, dashboard, and other areas for your
							business.
						</p>
					</header>
					<div className={styles.formWrapper}>
						<ImageInput
							title={'Upload a business logo \n'}
							subTitle={'PNG, JPG, or GIF. 5MB max.'}
							file={imageFile}
							setFile={setImageFile}
						/>
					</div>
					<footer className={styles.settingSectionFooter}>
						<p className={styles.helperText}>Recommended size: 512 x 512</p>
						<Button style={ButtonStyles.THIRD}>Save Settings</Button>
					</footer>
				</div>

				<div className={`${styles.settingSection}`}>
					<header className={styles.settingSectionHeader}>
						<p className={styles.settingSectionTitle}>CUSTOMER ACCOUNTS</p>
						<p className={styles.settingSectionDescription}>
							Set specific account requirements prior to checkout.
						</p>
					</header>
					<div className={styles.formWrapper}>
						<div className={styles.twoColumns}>
							<div className={styles.borderedRadioWrapper}>
								<Radio value={'OPTIONAL'} id={'optional'} setValue={() => {}} checked={true} />
								<div className={styles.borderedRadioWrapperInfo}>
									<p className={styles.borderedRadioWrapperTitle}>OPTIONAL</p>
									<p className={styles.borderedRadioWrapperDescription}>
										Customers can choose to checkout as a guest OR create an account.
									</p>
								</div>
							</div>
							<div className={styles.borderedRadioWrapper}>
								<Radio value={'DISABLED'} id={'DISABLED'} setValue={() => {}} checked={false} />
								<div className={styles.borderedRadioWrapperInfo}>
									<p className={styles.borderedRadioWrapperTitle}>DISABLED</p>
									<p className={styles.borderedRadioWrapperDescription}>
										Customers can only checkout with a guest email address.
									</p>
								</div>
							</div>
							<div className={styles.borderedRadioWrapper}>
								<Radio value={'REQUIRED'} id={'REQUIRED'} setValue={() => {}} checked={false} />
								<div className={styles.borderedRadioWrapperInfo}>
									<p className={styles.borderedRadioWrapperTitle}>REQUIRED</p>
									<p className={styles.borderedRadioWrapperDescription}>
										Customers are required to create an account to checkout.
									</p>
								</div>
							</div>
						</div>
					</div>
					<footer className={styles.settingSectionFooter}>
						<p className={styles.helperText}>
							We recommend setting the requirement to “Optional” to build customer loyalty.
						</p>
						<Button style={ButtonStyles.THIRD}>Save Settings</Button>
					</footer>
				</div>

				<div className={`${styles.settingSection}`}>
					<header className={styles.settingSectionHeader}>
						<p className={styles.settingSectionTitle}>SOCIAL LINKS</p>
						<p className={styles.settingSectionDescription}>
							These links will be accessible across your storefront and dashboard for customers.
						</p>
					</header>
					<div className={styles.formWrapper}>
						<div className={styles.twoColumns}>
							<Input
								value={''}
								setValue={() => {}}
								placeholder={'https://t.me/example'}
								icon={{
									id: 'telegram',
									width: 12,
									height: 10,
								}}
							/>
							<Input
								value={''}
								setValue={() => {}}
								placeholder={'https://discord.gg/example'}
								icon={{
									id: 'discord2',
									width: 14,
									height: 10,
								}}
							/>
							<Input
								value={''}
								setValue={() => {}}
								placeholder={'https://tiktok.com/example'}
								icon={{
									id: 'tiktok',
									width: 12,
									height: 13,
								}}
							/>
							<Input
								value={''}
								setValue={() => {}}
								placeholder={'https://facebook.com/example'}
								icon={{
									id: 'facebook',
									width: 7,
									height: 13,
								}}
							/>
							<Input
								value={''}
								setValue={() => {}}
								placeholder={'https://twitter.com/example'}
								icon={{
									id: 'twitter',
									width: 13,
									height: 10,
								}}
							/>
							<Input
								value={''}
								setValue={() => {}}
								placeholder={'https://instagram.com/example'}
								icon={{
									id: 'instagram',
									width: 14,
									height: 14,
								}}
							/>
							<Input
								value={''}
								setValue={() => {}}
								placeholder={'https://reddit.com/example'}
								icon={{
									id: 'reddit',
									width: 12,
									height: 12,
								}}
							/>
							<Input
								value={''}
								setValue={() => {}}
								placeholder={'https://youtube.com/example'}
								icon={{
									id: 'youtube',
									width: 14,
									height: 10,
								}}
							/>
						</div>
					</div>
					<footer className={styles.settingSectionFooter}>
						<p className={styles.helperText}>Please make sure you enter valid links.</p>
						<Button style={ButtonStyles.THIRD}>Save Settings</Button>
					</footer>
				</div>

				<div className={`${styles.settingSection}`}>
					<header className={styles.settingSectionHeader}>
						<p className={styles.settingSectionTitle}>support and analytics</p>
						<p className={styles.settingSectionDescription}>
							Link your storefront with external analytics and support platforms.
						</p>
					</header>
					<div className={styles.formWrapper}>
						<Input
							value={''}
							setValue={() => {}}
							icon={{
								id: 'crispChat2',
								width: 12,
								height: 10,
							}}
							placeholder={'Crisp Website ID'}
						/>
					</div>
					<div className={styles.formWrapper}>
						<Input
							value={''}
							setValue={() => {}}
							icon={{
								id: 'googleAnalytics2',
								width: 10,
								height: 12,
							}}
							placeholder={'UA-1234567'}
						/>
					</div>
					<footer className={styles.settingSectionFooter}>
						<p className={styles.helperText}>Please make sure you enter valid IDs.</p>
						<Button style={ButtonStyles.THIRD}>Save Settings</Button>
					</footer>
				</div>

				<div className={`${styles.settingSection}`}>
					<header className={styles.settingSectionHeader}>
						<p className={styles.settingSectionTitle}>TERMS OF SERVICE</p>
						<p className={styles.settingSectionDescription}>
							Link your storefront with external analytics and support platforms.
						</p>
					</header>
					<div className={styles.formWrapper}>
						<CustomMDEditor value={''} setValue={() => {}} />
					</div>
					<footer className={styles.settingSectionFooter}>
						<p className={styles.helperText}>
							A terms of service is optional but strongly recommended.
						</p>
						<Button style={ButtonStyles.THIRD}>Save Settings</Button>
					</footer>
				</div>
			</div>
		</PageWrapper>
	)
}

export default SettingsDesignPage
