import React, { useEffect, useState } from 'react'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import styles from '../Settings.module.scss'
import Input from '../../../components/UI/FormComponents/Input'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../components/UI/Button/Button'
import Checkbox from '../../../components/UI/Checkbox/Checkbox'
import CustomSelect, { SelectStyles } from '../../../components/UI/CustomSelect/CustomSelect'
import Switch from '../../../components/UI/Switch/Switch'
import InputWithText from '../../../components/UI/FormComponents/InputWithText'
import CustomMdEditor from '../../../components/UI/CustomMDEditor/CustomMDEditor'
import CheckboxWrapper from '../../../components/UI/CheckboxWrapper/CheckboxWrapper'
import OptionalBadge from '../../../components/UI/OptionalBadge/OptionalBadge'
import { useAPI } from '../../../hooks/useAPI'
import { GetSettingsGeneral } from '../../../types/GetSettingsGeneral/GetSettingsGeneral'
import { SettingsService } from '../../../API/SettingsService'
import { useAppContext } from '../../../hooks/useAppContext'
import { GetSettingsLegal } from '../../../types/GetSettingsLegal/GetSettingsLegal'
import PageLoading from '../../../components/UI/PageLoading/PageLoading'
import { ShopForUserPermissions } from '../../../types/ShopForUserPermissions/ShopForUserPermissions'
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent'
import pageNotFoundImage from '../../../assets/images/404.png'

const SettingsLegalPage = () => {
	const { shop } = useAppContext()
	const [terms, setTerms] = useState<string | undefined>('')
	const [isTermsEnabled, setIsTermsEnabled] = useState(false)
	const [showTermsOnProducts, setShowTermsOnProducts] = useState(false)
	const [privacyPolicy, setPrivacyPolicy] = useState<string | undefined>('')
	const [showPrivacyPolicyOnProducts, setShowPrivacyPolicyOnProducts] = useState(false)
	const [isPrivacyPolicyEnabled, setIsPrivacyPolicyEnabled] = useState(false)
	const [refundPolicy, setRefundPolicy] = useState<string | undefined>('')
	const [showRefundPolicyOnProducts, setShowRefundPolicyOnProducts] = useState(false)
	const [isRefundPolicyEnabled, setIsRefundPolicyEnabled] = useState(false)
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isSettingsGeneralViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.SETTINGS_GENERAL_VIEW) || isAdmin
	const isSettingsGeneralModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.SETTINGS_GENERAL_MODIFY) || isAdmin

	const [isGetLegalSettingsLoading, legalSettings, getLegalSettings] = useAPI<GetSettingsLegal>(
		() => SettingsService.getLegal(shop?.id || ''),
		false
	)

	const [isUpdateTermsLoading, , updateTerms] = useAPI(
		() =>
			SettingsService.updateTermsOfService(shop?.id || '', {
				content: isTermsEnabled ? terms || null : null,
				showOnProducts: showTermsOnProducts,
			}),
		false,
		'Settings saved successfully'
	)

	const [isUpdatePrivacyPolicyLoading, , updatePrivacyPolicy] = useAPI(
		() =>
			SettingsService.updatePrivacyPolicy(shop?.id || '', {
				content: isPrivacyPolicyEnabled ? privacyPolicy || null : null,
				showOnProducts: showPrivacyPolicyOnProducts,
			}),
		false,
		'Settings saved successfully'
	)

	const [isUpdateRefundPolicyLoading, , updateRefundPolicy] = useAPI(
		() =>
			SettingsService.updateRefundPolicy(shop?.id || '', {
				content: isRefundPolicyEnabled ? refundPolicy || null : null,
				showOnProducts: showRefundPolicyOnProducts,
			}),
		false,
		'Settings saved successfully'
	)

	useEffect(() => {
		if (
			!isUpdateRefundPolicyLoading &&
			!isUpdatePrivacyPolicyLoading &&
			!isUpdateTermsLoading &&
			shop
		) {
			getLegalSettings()
		}
	}, [isUpdateTermsLoading, isUpdateRefundPolicyLoading, isUpdatePrivacyPolicyLoading])

	useEffect(() => {
		if (shop && isSettingsGeneralViewAvailable) {
			getLegalSettings()
		}
	}, [shop])

	useEffect(() => {
		if (legalSettings) {
			setRefundPolicy(legalSettings.refundPolicy)
			setIsRefundPolicyEnabled(!!legalSettings.refundPolicy)
			setIsTermsEnabled(!!legalSettings.termsOfService)
			setTerms(legalSettings.termsOfService)
			setIsPrivacyPolicyEnabled(!!legalSettings.privacyPolicy)
			setPrivacyPolicy(legalSettings.privacyPolicy)
			setShowTermsOnProducts(legalSettings.showTermsOfServiceOnProduct)
			setShowRefundPolicyOnProducts(legalSettings.showRefundPolicyOnProduct)
			setShowPrivacyPolicyOnProducts(legalSettings.showPrivacyPolicyOnProduct)
		}
	}, [legalSettings])

	if (isGetLegalSettingsLoading) return <PageLoading />

	if (!isSettingsGeneralViewAvailable)
		return (
			<NoDataComponent
				isSmallHeight={true}
				imageSrc={pageNotFoundImage}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)

	return (
		<PageWrapper>
			<div className={styles.settingsPageInnerBody}>
				<div className={styles.settingSection}>
					<header className={styles.settingSectionHeader}>
						<h1 className={styles.settingSectionTitle}>Legal Policies</h1>
					</header>
					<div className={styles.settingSectionBody}>
						<div className={styles.fieldWrapper}>
							<header className={styles.fieldWrapperHeader}>
								<p className={styles.fieldWrapperTitle}>
									Terms of Service <OptionalBadge />
								</p>
								<Switch checked={isTermsEnabled} setChecked={setIsTermsEnabled} />
							</header>
							{isTermsEnabled && (
								<div className={styles.formWrapper}>
									<CustomMdEditor value={terms} setValue={setTerms} />
								</div>
							)}
						</div>
						{isTermsEnabled && (
							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Product Terms of Service'}
									checked={showTermsOnProducts}
									setChecked={setShowTermsOnProducts}
									title={'Product Terms of Service'}
									description={'Enable this terms of service to each product on your storefront.'}
								/>
							</div>
						)}

						{isSettingsGeneralModifyAvailable &&
							(legalSettings?.termsOfService !== terms ||
								legalSettings?.showTermsOfServiceOnProduct !== showTermsOnProducts ||
								!!legalSettings.termsOfService !== isTermsEnabled) && (
								<div className={styles.mtMediumLarge}>
									<Button
										style={ButtonStyles.BORDERED}
										icon={{
											id: 'download',
											width: 13,
											height: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										disableShadow={true}
										onClick={updateTerms}
										isLoading={isUpdateTermsLoading}
									>
										Save Settings
									</Button>
								</div>
							)}

						<div className={styles.mtMediumLarge}>
							<div className={styles.fieldWrapper}>
								<header className={styles.fieldWrapperHeader}>
									<p className={styles.fieldWrapperTitle}>
										Privacy Policy <OptionalBadge />
									</p>
									<Switch checked={isPrivacyPolicyEnabled} setChecked={setIsPrivacyPolicyEnabled} />
								</header>
								{isPrivacyPolicyEnabled && (
									<div className={styles.formWrapper}>
										<CustomMdEditor value={privacyPolicy} setValue={setPrivacyPolicy} />
									</div>
								)}
							</div>
						</div>

						{isPrivacyPolicyEnabled && (
							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Product Privacy Policy'}
									checked={showPrivacyPolicyOnProducts}
									setChecked={setShowPrivacyPolicyOnProducts}
									title={'Product Privacy Policy'}
									description={'Enable this privacy policy to each product on your storefront.'}
								/>
							</div>
						)}

						{isSettingsGeneralModifyAvailable &&
							(legalSettings?.privacyPolicy !== privacyPolicy ||
								legalSettings?.showPrivacyPolicyOnProduct !== showPrivacyPolicyOnProducts ||
								!!legalSettings.privacyPolicy !== isPrivacyPolicyEnabled) && (
								<div className={styles.mtMediumLarge}>
									<Button
										style={ButtonStyles.BORDERED}
										icon={{
											id: 'download',
											width: 13,
											height: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										disableShadow={true}
										onClick={updatePrivacyPolicy}
										isLoading={isUpdatePrivacyPolicyLoading}
									>
										Save Settings
									</Button>
								</div>
							)}

						<div className={styles.mtMediumLarge}>
							<div className={styles.fieldWrapper}>
								<header className={styles.fieldWrapperHeader}>
									<p className={styles.fieldWrapperTitle}>
										Refund Policy <OptionalBadge />
									</p>
									<Switch checked={isRefundPolicyEnabled} setChecked={setIsRefundPolicyEnabled} />
								</header>
								{isRefundPolicyEnabled && (
									<div className={styles.formWrapper}>
										<CustomMdEditor value={refundPolicy} setValue={setRefundPolicy} />
									</div>
								)}
							</div>
						</div>

						{isRefundPolicyEnabled && (
							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Product Refund Policy'}
									checked={showRefundPolicyOnProducts}
									setChecked={setShowRefundPolicyOnProducts}
									title={'Product Refund Policy'}
									description={'Enable this refund policy to each product on your storefront.'}
								/>
							</div>
						)}

						{isSettingsGeneralModifyAvailable &&
							(legalSettings?.refundPolicy !== refundPolicy ||
								legalSettings?.showRefundPolicyOnProduct !== showRefundPolicyOnProducts ||
								!!legalSettings?.refundPolicy !== isRefundPolicyEnabled) && (
								<div className={styles.mtMediumLarge}>
									<Button
										style={ButtonStyles.BORDERED}
										icon={{
											id: 'download',
											width: 13,
											height: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										disableShadow={true}
										onClick={updateRefundPolicy}
										isLoading={isUpdateRefundPolicyLoading}
									>
										Save Settings
									</Button>
								</div>
							)}
					</div>
				</div>
			</div>
		</PageWrapper>
	)
}

export default SettingsLegalPage
