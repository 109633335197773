import React, { FC } from 'react'
import styles from '../DiscordBotBuilderPage.module.scss'
import { SpriteIcon } from '../../../components/UI/SpriteIcon/SpriteIcon'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../components/UI/Button/Button'
import { DiscordClusterType } from '../../../types/DiscordClusterType/DiscordClusterType'
import { BotConnectingSteps } from '../DiscordBotBuilderPage'
import { useAppContext } from '../../../hooks/useAppContext'
import { Subscriptions } from '../../../types/Subscriptions/Subscriptions'
import { Subscription } from '../../../types//Subscriptions/Subscription'

interface SelectOptionsStepProps {
	discordClusterType: DiscordClusterType | null
	setDiscordClusterType: (value: DiscordClusterType | null) => void
	currentStep: BotConnectingSteps
	setCurrentStep: (value: BotConnectingSteps) => void
}
const DiscordSubscriptionMap = {
	[DiscordClusterType.Secured]: Subscriptions.BUSINESS,
	[DiscordClusterType.Standard]: Subscriptions.CORE_PLUS,
}
const DiscordPerksMap: Record<DiscordClusterType, keyof Subscription['perks']> = {
	[DiscordClusterType.Secured]: 'discordSecuredCluster',
	[DiscordClusterType.Standard]: 'discordStandardCluster',
}

const SelectOptionsStep: FC<SelectOptionsStepProps> = ({
	discordClusterType,
	setDiscordClusterType,
	setCurrentStep,
	currentStep,
}) => {
	const { user, setIsSubscriptionModalActive, setRequiredSubscription } = useAppContext()

	const onOptionClickHandler = (discordClusterType: DiscordClusterType) => {
		if (user?.subscription.perks?.[DiscordPerksMap[discordClusterType]]) {
			setDiscordClusterType(discordClusterType)
			setCurrentStep(currentStep + 1)
		} else {
			setIsSubscriptionModalActive(true)
			setRequiredSubscription(DiscordSubscriptionMap[discordClusterType])
		}
	}

	return (
		<div className={styles.stepWrapper}>
			<div className={styles.doubleGrid}>
				<div
					className={`${styles.botOption} ${
						discordClusterType === DiscordClusterType.Standard && styles.active
					}`}
				>
					<div className={styles.botOptionIcon}>
						<SpriteIcon iconId={'discordStroke'} width={24} height={17} />
					</div>

					<h2 className={styles.botOptionTitle}>Standard Bot</h2>
					<p className={`${styles.secondaryText} ${styles.mt10}`}>
						Quick bot setup for communities with minimal security requirements. Includes platform
						branding.
					</p>

					<div className={styles.mtMedium}>
						<Button
							style={ButtonStyles.DARK}
							icon={{
								id: 'arrowRight',
								width: 13,
								height: 12,
								align: ButtonsIconAlign.RIGHT,
							}}
							width={'100%'}
							disableShadow={true}
							onClick={() => onOptionClickHandler(DiscordClusterType.Standard)}
						>
							Create Bot
						</Button>
					</div>
				</div>
				<div
					className={`${styles.botOption} ${
						discordClusterType === DiscordClusterType.Secured && styles.active
					}`}
				>
					<div className={styles.botOptionIcon}>
						<SpriteIcon iconId={'robot'} width={22} height={23} />
					</div>

					<h2 className={styles.botOptionTitle}>Secured Bot</h2>
					<p className={`${styles.secondaryText} ${styles.mt10}`}>
						A custom branded bot built for businesses with demanding community members.
					</p>

					<div className={styles.mtMedium}>
						<Button
							style={ButtonStyles.DARK}
							icon={{
								id: 'arrowRight',
								width: 13,
								height: 12,
								align: ButtonsIconAlign.RIGHT,
							}}
							width={'100%'}
							disableShadow={true}
							onClick={() => onOptionClickHandler(DiscordClusterType.Secured)}
						>
							Create Bot
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SelectOptionsStep
