import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './ProductVariants.module.scss'
import ProductVariantHead from '../ProductVariantHead/ProductVariantHead'
import ProductVariantsGeneral from '../ProductVariantsGeneral/ProductVariantsGeneral'
import ProductVariantsPrice from '../ProductVariantsPrice/ProductVariantsPrice'
import ProductVariantsDelivery from '../ProductVariantsDelivery/ProductVariantsDelivery'
import ProductVariantsCheckout from '../ProductVariantsCheckout/ProductVariantsCheckout'
import ProductVariantsAdvanced from '../ProductVariantsAdvanced/ProductVariantsAdvanced'
import { VariantsPricing } from '../../../../types/CreateVariants/VariantsPricing'
import { VariantsDelivery } from '../../../../types/CreateVariants/VariantsDelivery'
import { VariantsGeneral } from '../../../../types/CreateVariants/VariantsGeneral'
import { VariantsCustomFields } from '../../../../types/CreateVariants/VariantsCustomFields'
import { VariantsDiscordSettings } from '../../../../types/CreateVariants/VariantsDiscordSettings'
import { VariantsAdvanced } from '../../../../types/CreateVariants/VariantsAdvanced'
import { ProductVariantsTab } from '../../../../types/ProductVariantsTab/ProductVariantsTab'
import Tabs from '../../../../components/UI/Tabs/Tabs'

interface ProductVariantsProps {
	setVariantsGeneral: (value: VariantsGeneral) => void
	variantsGeneral: VariantsGeneral
	variantsPricing: VariantsPricing
	setVariantsPricing: (value: VariantsPricing) => void
	currentVariantId: string
	setCurrentVariantIndex: (value: string) => void
	variantsDelivery: VariantsDelivery
	setVariantsDelivery: (value: VariantsDelivery) => void
	variantsCustomFields: VariantsCustomFields
	setVariantsCustomFields: (value: VariantsCustomFields) => void
	setVariantsDiscordSettings: (value: VariantsDiscordSettings) => void
	variantsDiscordSettings: VariantsDiscordSettings
	setVariantsAdvanced: (value: VariantsAdvanced) => void
	variantsAdvanced: VariantsAdvanced
	currentVariantsTab: { [variantIndex: string]: ProductVariantsTab }
	setCurrentVariantsTab: (value: { [variantIndex: string]: ProductVariantsTab }) => void
	onEditClick: () => void
	onDeleteClick: () => void
	onMoveUpClick: () => void
	onMoveDownClick: () => void
}

const ProductVariants: FC<ProductVariantsProps> = ({
	variantsPricing,
	setVariantsPricing,
	setCurrentVariantIndex,
	currentVariantId,
	variantsDelivery,
	setVariantsDelivery,
	variantsGeneral,
	setVariantsGeneral,
	setVariantsCustomFields,
	variantsCustomFields,
	variantsDiscordSettings,
	setVariantsDiscordSettings,
	variantsAdvanced,
	setVariantsAdvanced,
	setCurrentVariantsTab,
	currentVariantsTab,
	onDeleteClick,
	onEditClick,
	onMoveDownClick,
	onMoveUpClick,
}) => {
	const [isActive, setIsActive] = useState(true)
	const variantRef = useRef<HTMLDivElement>(null)
	const variantTabs = [
		{
			label: 'General',
			value: ProductVariantsTab.GENERAL,
		},
		{
			label: 'Pricing',
			value: ProductVariantsTab.PRICING,
		},
		{
			label: 'Delivery',
			value: ProductVariantsTab.DELIVERY,
		},
		{
			label: 'Checkout',
			value: ProductVariantsTab.CHECKOUT,
		},
		{
			label: 'Advanced',
			value: ProductVariantsTab.ADVANCED,
		},
	]

	const changeVariantTab = (tab: ProductVariantsTab) => {
		setCurrentVariantsTab({
			...currentVariantsTab,
			[currentVariantId]: tab,
		})
	}

	if (Object.keys(variantsGeneral).length === 1) {
		delete variantTabs[0]
	}

	useEffect(() => {
		if (isActive) {
			variantRef.current?.classList.add(styles.active)

			setTimeout(() => {
				variantRef.current?.classList.add(styles.visible)
			}, 10)
		} else {
			variantRef.current?.classList.remove(styles.visible)

			setTimeout(() => {
				variantRef.current?.classList.remove(styles.active)
			}, 300)
		}

		if (Object.keys(variantsGeneral).length === 1) {
			setCurrentVariantsTab({
				...currentVariantsTab,
				[currentVariantId]: ProductVariantsTab.PRICING,
			})
		}
	}, [isActive])

	return (
		<>
			<div className={styles.productVariants} ref={variantRef}>
				<ProductVariantHead
					deliverables={
						Object.keys(variantsDelivery[currentVariantId].deliveryConfiguration).length
					}
					title={variantsGeneral[currentVariantId].title}
					onClick={() => setIsActive(!isActive)}
					onDeleteClick={onDeleteClick}
					price={variantsPricing[currentVariantId].price.amount}
					currency={variantsPricing[currentVariantId].price.currency}
					onEditClick={onEditClick}
					onMoveDownClick={onMoveDownClick}
					onMoveUpClick={onMoveUpClick}
					variantsLength={Object.keys(variantsGeneral).length}
				/>

				<div className={styles.body}>
					<Tabs
						tabs={variantTabs}
						currentTab={currentVariantsTab[currentVariantId]}
						setCurrentTab={changeVariantTab}
					/>

					{currentVariantsTab[currentVariantId] === ProductVariantsTab.GENERAL && (
						<ProductVariantsGeneral
							variantsGeneral={variantsGeneral}
							setVariantsGeneral={setVariantsGeneral}
							currentVariantIndex={currentVariantId}
						/>
					)}
					{currentVariantsTab[currentVariantId] === ProductVariantsTab.PRICING && (
						<ProductVariantsPrice
							variantsPricing={variantsPricing}
							setVariantsPricing={setVariantsPricing}
							currentVariantIndex={currentVariantId}
						/>
					)}
					{currentVariantsTab[currentVariantId] === ProductVariantsTab.DELIVERY && (
						<ProductVariantsDelivery
							variantsDelivery={variantsDelivery}
							setVariantsDelivery={setVariantsDelivery}
							currentVariantIndex={currentVariantId}
						/>
					)}
					{currentVariantsTab[currentVariantId] === ProductVariantsTab.CHECKOUT && (
						<ProductVariantsCheckout
							setVariantsCustomFields={setVariantsCustomFields}
							variantsCustomFields={variantsCustomFields}
							currentVariantIndex={currentVariantId}
							variantsDiscordSettings={variantsDiscordSettings}
							setVariantsDiscordSettings={setVariantsDiscordSettings}
						/>
					)}
					{currentVariantsTab[currentVariantId] === ProductVariantsTab.ADVANCED && (
						<ProductVariantsAdvanced
							variantsAdvanced={variantsAdvanced}
							setVariantsAdvanced={setVariantsAdvanced}
							currentVariantIndex={currentVariantId}
						/>
					)}
				</div>
			</div>
		</>
	)
}

export default ProductVariants
