import React, { FC } from 'react'
import styles from './CustomerTicket.module.scss'
import { Ticket } from '../../types/Ticket/Ticket'
import { formatDistanceToNow } from 'date-fns'

interface CustomerTicketProps {
	ticket: Ticket
	onClick: () => void
	isActive: boolean
}

const CustomerTicket: FC<CustomerTicketProps> = ({ ticket, onClick, isActive }) => {
	return (
		<div className={styles.customerTicketWrapper}>
			<div className={`${styles.customerTicket} ${isActive && styles.active}`} onClick={onClick}>
				<header>
					<h2>{ticket.title}</h2>
					<time>{formatDistanceToNow(new Date(ticket.updatedAt))} ago</time>
				</header>
				<p className={styles.email}>{ticket.email}</p>
				<p className={styles.message}>{ticket.lastMessage}</p>
			</div>
		</div>
	)
}

export default CustomerTicket
