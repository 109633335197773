import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import styles from './DropdownSection.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

interface DropdownSectionProps {
	children: ReactNode
	title: string
	maxHeight: number
}

const DropdownSection: FC<DropdownSectionProps> = ({ children, title, maxHeight }) => {
	const [isActive, setIsActive] = useState(true)
	const dropdownRef = useRef<HTMLDivElement>(null)
	const sectionRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (isActive) {
			dropdownRef.current?.classList.add(styles.active)
			setTimeout(() => {
				dropdownRef.current?.classList.add(styles.visible)
				if (sectionRef.current) {
					sectionRef.current.style.maxHeight = `${maxHeight}px`
				}
			}, 10)
		} else {
			dropdownRef.current?.classList.remove(styles.visible)
			if (sectionRef.current) {
				sectionRef.current.style.maxHeight = '0px'
			}

			setTimeout(() => {
				dropdownRef.current?.classList.remove(styles.active)
			}, 300)
		}
	}, [isActive])

	return (
		<div className={`${styles.dropdownSection} `} ref={dropdownRef}>
			<div className={styles.face} onClick={() => setIsActive(!isActive)}>
				<h1>{title}</h1>
				<SpriteIcon iconId={'arrowDown'} width={10} height={4} />
			</div>
			<div className={styles.horLine} />
			<div className={`${styles.section}`} ref={sectionRef}>
				{children}
			</div>
		</div>
	)
}

export default DropdownSection
