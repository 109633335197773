import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Input from '../../UI/FormComponents/Input'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { useAPI } from '../../../hooks/useAPI'
import { StockSystemService } from '../../../API/StockSystemService'
import { useAppContext } from '../../../hooks/useAppContext'
import { fieldValidators } from '../../../helpers/fieldValidators'

interface CreateStockGroupModalProps extends BaseModalProps {
	getWarehouses: () => void
}

const CreateStockGroupModal: FC<CreateStockGroupModalProps> = ({ onClose, getWarehouses }) => {
	const [name, setName] = useState('')
	const { shop } = useAppContext()
	const [nameError, setNameError] = useState('')

	const [isCreateWarehouseLoading, , createWarehouse] = useAPI(
		() =>
			StockSystemService.createWarehouse(shop?.id || '', {
				name,
			}),
		false,
		'Stock group successfully created',
		{
			onSuccess: () => {
				onClose()
				getWarehouses()
			},
		}
	)

	const onSaveHandler = () => {
		if (name) {
			createWarehouse()
		} else {
		}
	}

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Create Warehouse</h1>
			</header>

			<div className={styles.body}>
				<p className={styles.description}>
					Enter a stock name you’ll remember internally. Once created, you’ll then be able to add
					stock to this group for your products.
				</p>
				<div className={styles.fieldWrapper}>
					<h2>STOCK NAME</h2>
					<Input
						value={name}
						setValue={setName}
						placeholder={'Title'}
						errorMessage={nameError}
						onBlur={() => {
							setNameError(fieldValidators.blankValidator('Warehouse title', name))
						}}
					/>
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						disableShadow={true}
						icon={{
							id: 'plus',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						onClick={onSaveHandler}
						isLoading={isCreateWarehouseLoading}
					>
						Create Warehouse
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default CreateStockGroupModal
