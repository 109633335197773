import React, { ChangeEvent, DragEvent, FC, useEffect, useRef, useState } from 'react'
import styles from './ImageSelector.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { CircularProgressbar } from 'react-circular-progressbar'
import { Image } from '../../types/Image/Image'

interface ImageSelectorProps {
	file: File | null
	setFile: (file: File | null) => void
	uploadProgress:
		| null
		| {
				file: File | null
				progress: number
		  }[]
	uploadImage: () => void
	onDeleteClick: (id: number) => void
	images: Image[] | undefined
	selectedImages: Image[]
	onImageClick: (image: Image) => void
}

const ImageSelector: FC<ImageSelectorProps> = ({
	setFile,
	file,
	uploadProgress,
	uploadImage,
	images,
	onDeleteClick,
	selectedImages,
	onImageClick,
}) => {
	const [dragActive, setDragActive] = useState(false)
	const inputRef = useRef<HTMLInputElement>(null)
	const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setFile(event.target.files ? event.target.files[0] : null)
	}

	const handleDrag = (event: DragEvent) => {
		event.preventDefault()
		event.stopPropagation()

		if (event.type === 'dragenter' || event.type === 'dragover') {
			setDragActive(true)
		} else if (event.type === 'dragleave') {
			setDragActive(false)
		}
	}

	const handleDrop = (event: DragEvent) => {
		event.preventDefault()
		event.stopPropagation()
		setDragActive(false)
		if (event.dataTransfer.files && event.dataTransfer.files[0]) {
			setFile(event.dataTransfer.files[0])
		}
	}

	useEffect(() => {
		if (file) {
			uploadImage()
		}
	}, [file])

	return (
		<div className={styles.imageSelector} onDragEnter={handleDrag}>
			<div className={styles.grid}>
				<div
					className={`${styles.imageUploader} ${dragActive && styles.dragActive}`}
					onClick={() => inputRef.current?.click()}
				>
					<SpriteIcon iconId={'upload'} width={25} height={25} />
					<p>Drag images here</p>
					<input
						type='file'
						accept={'image/jpeg, image/png, image/gif'}
						ref={inputRef}
						onChange={onChangeHandler}
					/>
				</div>

				{uploadProgress?.reverse().map(image => (
					<div className={`${styles.image} ${image.progress < 100 && styles.blur}`}>
						{<img src={image.file ? URL.createObjectURL(image.file) : ''} alt='' />}

						<button className={styles.deleteButton}>
							<SpriteIcon iconId={'trashBin'} width={12} height={12} />
						</button>

						{image.progress < 100 && (
							<div className={styles.uploadProgress}>
								<CircularProgressbar
									value={image.progress}
									styles={{
										path: {
											stroke: 'var(--primary_color)',
										},
										trail: {
											stroke: 'var(--light_gray)',
										},
									}}
								/>
							</div>
						)}
					</div>
				))}

				{images?.map(image => (
					<div
						className={`${styles.image} ${
							selectedImages?.find(selectImage => selectImage.id === image.id) && styles.selected
						}`}
						onClick={() => onImageClick(image)}
					>
						<img src={`https://imagedelivery.net/${image.cfId}/productCard`} alt={image.fileName} />
						<button className={styles.deleteButton} onClick={() => onDeleteClick(image.id)}>
							<SpriteIcon iconId={'trashBin'} width={14} height={15} />
						</button>
						<SpriteIcon className={styles.checked} iconId={'checked'} width={20} height={16} />
					</div>
				))}
			</div>

			{dragActive && (
				<div
					className={styles.dragFileElement}
					onDragEnter={handleDrag}
					onDragLeave={handleDrag}
					onDragOver={handleDrag}
					onDrop={handleDrop}
				></div>
			)}
		</div>
	)
}

export default ImageSelector
