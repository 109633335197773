import React, { FC, useEffect, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import Input, { InputIconAligns } from '../../UI/FormComponents/Input'
import CustomMultiSelect from '../../UI/CustomMultiSelect/CustomMultiSelect'
import { ShopForUserPermissions } from '../../../types/ShopForUserPermissions/ShopForUserPermissions'
import { useAPI } from '../../../hooks/useAPI'
import { ManagersInvitesService } from '../../../API/ManagersInvitesService'
import { useAppContext } from '../../../hooks/useAppContext'
import CheckboxWrapper from '../../UI/CheckboxWrapper/CheckboxWrapper'
import { containsAllElements } from '../../../helpers/containsAllElements'
import { useCopy } from '../../../hooks/useCopy'
import { Manager } from '../../../types/Manager/Manager'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'
import FieldErrorMessage from '../../UI/FieldErrorMessage/FieldErrorMessage'
import Tabs from '../../UI/Tabs/Tabs'
import { ManagersService } from '../../../API/ManagersService'

const analyticsSelectOptions = [
	{
		label: 'Read',
		value: ShopForUserPermissions.ANALYTICS_VIEW,
	},
	{
		label: 'Delete',
		value: ShopForUserPermissions.ANALYTICS_DELETE,
	},
]

const fraudsSelectOptions = [
	{
		label: 'Read',
		value: ShopForUserPermissions.FRAUD_VIEW,
	},
	{
		label: 'Edit',
		value: ShopForUserPermissions.FRAUD_MODIFY,
	},
	{
		label: 'Delete',
		value: ShopForUserPermissions.FRAUD_DELETE,
	},
]

const affiliatesSelectOptions = [
	{
		label: 'Read',
		value: ShopForUserPermissions.AFFILIATES_VIEW,
	},
	{
		label: 'Edit',
		value: ShopForUserPermissions.AFFILIATES_MODIFY,
	},
]

const ticketsSelectOptions = [
	{
		label: 'Read',
		value: ShopForUserPermissions.TICKETS_VIEW,
	},
	{
		label: 'Edit',
		value: ShopForUserPermissions.TICKETS_MODIFY,
	},
]

const contentSelectOptions = [
	{
		label: 'Read',
		value: ShopForUserPermissions.CONTENT_VIEW,
	},
	{
		label: 'Edit',
		value: ShopForUserPermissions.CONTENT_MODIFY,
	},
	{
		label: 'Delete',
		value: ShopForUserPermissions.CONTENT_DELETE,
	},
]

const categoriesSelectOptions = [
	{
		label: 'Read',
		value: ShopForUserPermissions.CATEGORIES_VIEW,
	},
	{
		label: 'Edit',
		value: ShopForUserPermissions.CATEGORIES_MODIFY,
	},
	{
		label: 'Delete',
		value: ShopForUserPermissions.CATEGORIES_DELETE,
	},
]

const couponsSelectOptions = [
	{
		label: 'Read',
		value: ShopForUserPermissions.COUPONS_VIEW,
	},
	{
		label: 'Edit',
		value: ShopForUserPermissions.COUPONS_MODIFY,
	},
	{
		label: 'Delete',
		value: ShopForUserPermissions.COUPONS_DELETE,
	},
]

const customizationSelectOptions = [
	{
		label: 'Read',
		value: ShopForUserPermissions.CUSTOMIZATION_VIEW,
	},
	{
		label: 'Edit',
		value: ShopForUserPermissions.CUSTOMIZATION_MODIFY,
	},
]

const feedbackSelectOptions = [
	{
		label: 'Read',
		value: ShopForUserPermissions.REVIEWS_VIEW,
	},
	{
		label: 'Edit',
		value: ShopForUserPermissions.REVIEWS_MODIFY,
	},
]

const settingsPaymentsSelectOptions = [
	{
		label: 'Read',
		value: ShopForUserPermissions.SETTINGS_PAYMENTS_VIEW,
	},
	{
		label: 'Edit',
		value: ShopForUserPermissions.SETTINGS_PAYMENTS_MODIFY,
	},
]

const settingsGeneralSelectOptions = [
	{
		label: 'Read',
		value: ShopForUserPermissions.SETTINGS_GENERAL_VIEW,
	},
	{
		label: 'Edit',
		value: ShopForUserPermissions.SETTINGS_GENERAL_MODIFY,
	},
]

const productsSelectOptions = [
	{
		label: 'Read',
		value: ShopForUserPermissions.PRODUCTS_VIEW,
	},
	{
		label: 'Edit',
		value: ShopForUserPermissions.PRODUCTS_MODIFY,
	},
	{
		label: 'Delete',
		value: ShopForUserPermissions.PRODUCTS_DELETE,
	},
]

const ordersSelectOptions = [
	{
		label: 'Read',
		value: ShopForUserPermissions.ORDERS_VIEW,
	},
	{
		label: 'Edit',
		value: ShopForUserPermissions.ORDERS_MODIFY,
	},
]

const chargesSelectOptions = [
	{
		label: 'Read',
		value: ShopForUserPermissions.CHARGES_VIEW,
	},
	{
		label: 'Edit',
		value: ShopForUserPermissions.CHARGES_MODIFY,
	},
]

const developersSelectOptions = [
	{
		label: 'Read',
		value: ShopForUserPermissions.DEVELOPERS_VIEW,
	},
	{
		label: 'Edit',
		value: ShopForUserPermissions.DEVELOPERS_MODIFY,
	},
	{
		label: 'Delete',
		value: ShopForUserPermissions.DEVELOPERS_DELETE,
	},
]

const warehousesSelectOptions = [
	{
		label: 'Read',
		value: ShopForUserPermissions.STOCK_SYSTEMS_VIEW,
	},
	{
		label: 'Edit',
		value: ShopForUserPermissions.STOCK_SYSTEMS_MODIFY,
	},
	{
		label: 'Delete',
		value: ShopForUserPermissions.STOCK_SYSTEMS_DELETE,
	},
]

const customersSelectOptions = [
	{
		label: 'Read',
		value: ShopForUserPermissions.CUSTOMERS_VIEW,
	},
	{
		label: 'Edit',
		value: ShopForUserPermissions.CUSTOMERS_MODIFY,
	},
]

interface InviteTeamMemberModalProps extends BaseModalProps {
	getInvites: () => void
	getManagers: () => void
	editingManager: Manager | null
}

const InviteTeamMemberModal: FC<InviteTeamMemberModalProps> = ({
	onClose,
	getInvites,
	getManagers,
	editingManager,
}) => {
	const [permissionTab, setPermissionTab] = useState<'advanced' | 'basic'>('basic')
	const [email, setEmail] = useState('')
	const [administratorValue, setAdministratorValue] = useState<ShopForUserPermissions.ADMIN>()
	const [customersValue, setCustomersValue] = useState<string[]>([])
	const [productsValue, setProductsValue] = useState<string[]>([])
	const [couponsValue, setCouponsValue] = useState<string[]>([])
	const [analyticsValue, setAnalyticsValue] = useState<string[]>([])
	const [customizationValue, setCustomizationValue] = useState<string[]>([])
	const [feedbackValue, setFeedbackValue] = useState<string[]>([])
	const [categoriesValue, setCategoriesValue] = useState<string[]>([])
	const [fraudsValue, setFraudsValue] = useState<string[]>([])
	const [developersValue, setDevelopersValue] = useState<string[]>([])
	const [ordersValue, setOrdersValue] = useState<string[]>([])
	const [chargesValue, setChargesValue] = useState<string[]>([])
	const [warehousesValue, setWarehousesValue] = useState<string[]>([])
	const [contentValue, setContentValue] = useState<string[]>([])
	const [ticketsValue, setTicketsValue] = useState<string[]>([])
	const [settingsPaymentsValue, setSettingsPaymentsValue] = useState<string[]>([])
	const [settingsGeneralValue, setSettingsGeneralValue] = useState<string[]>([])
	const [errors, setErrors] = useState<FormErrors>({})
	const { shop } = useAppContext()
	const copy = useCopy()

	const validateCreateInviteLink = () => {
		const emailBlankValidator = fieldValidators.blankValidator('Team Member Email', email)
		const emailValidator = fieldValidators.emailValidator(email)
		const permissionsValidator =
			productsValue.length ||
			feedbackValue.length ||
			categoriesValue.length ||
			fraudsValue.length ||
			customizationValue.length ||
			analyticsValue.length ||
			couponsValue.length ||
			customersValue.length ||
			ticketsValue.length ||
			warehousesValue.length ||
			settingsGeneralValue.length ||
			settingsPaymentsValue.length ||
			contentValue.length ||
			developersValue.length ||
			chargesValue.length ||
			ordersValue.length ||
			administratorValue
				? ''
				: "Permissions can't be empty"

		setErrors({
			email: emailBlankValidator || emailValidator,
			permissions: permissionsValidator,
		})

		return !(permissionsValidator || emailValidator || emailBlankValidator)
	}

	const [isUpdatePermissionsLoading, , updatePermissions] = useAPI(
		permissions =>
			ManagersService.update(shop?.id || '', editingManager?.id || 0, {
				permissions,
			}),
		false,
		'Manager successfully updated',
		{
			onSuccess: () => {
				onClose()
				getManagers()
			},
		}
	)

	const [isCreateInviteLinkLoading, inviteLink, createInviteLink] = useAPI<{
		id: string
		inviteToken: string
	}>(
		permissions => {
			return ManagersInvitesService.create(shop?.id || '', {
				email,
				permissions: permissions,
			})
		},
		false,
		'Invite link successfully created',
		{
			onSuccess: () => {
				getInvites()
			},
			validator: validateCreateInviteLink,
		}
	)

	const onSubmitClick = () => {
		const permissions: ShopForUserPermissions[] = [
			...(productsValue as ShopForUserPermissions[]),
			...(feedbackValue as ShopForUserPermissions[]),
			...(categoriesValue as ShopForUserPermissions[]),
			...(fraudsValue as ShopForUserPermissions[]),
			...(customizationValue as ShopForUserPermissions[]),
			...(analyticsValue as ShopForUserPermissions[]),
			...(couponsValue as ShopForUserPermissions[]),
			...(customersValue as ShopForUserPermissions[]),
			...(ticketsValue as ShopForUserPermissions[]),
			...(warehousesValue as ShopForUserPermissions[]),
			...(settingsGeneralValue as ShopForUserPermissions[]),
			...(settingsPaymentsValue as ShopForUserPermissions[]),
			...(contentValue as ShopForUserPermissions[]),
			...(developersValue as ShopForUserPermissions[]),
			...(chargesValue as ShopForUserPermissions[]),
			...(ordersValue as ShopForUserPermissions[]),
		]

		administratorValue && permissions.push(ShopForUserPermissions.ADMIN)

		if (editingManager) {
			updatePermissions(permissions)
		} else {
			createInviteLink(permissions)
		}
	}

	useEffect(() => {
		if (editingManager) {
			const ticketsOptionsValues = ticketsSelectOptions.map(option => option.value)
			const fraudsOptionsValues = fraudsSelectOptions.map(option => option.value)
			const couponsOptionsValues = couponsSelectOptions.map(option => option.value)
			const developersOptionsValues = developersSelectOptions.map(option => option.value)
			const categoriesOptionsValues = categoriesSelectOptions.map(option => option.value)
			const productsOptionsValues = productsSelectOptions.map(option => option.value)
			const ordersOptionsValues = ordersSelectOptions.map(option => option.value)
			const feedbacksOptionsValues = feedbackSelectOptions.map(option => option.value)
			const contentOptionsValues = contentSelectOptions.map(option => option.value)
			const chargesOptionsValues = chargesSelectOptions.map(option => option.value)
			const analyticsOptionsValues = analyticsSelectOptions.map(option => option.value)
			const customizationOptionsValues = customizationSelectOptions.map(option => option.value)
			const warehousesOptionsValues = warehousesSelectOptions.map(option => option.value)
			const settingsGeneralOptionsValues = settingsGeneralSelectOptions.map(option => option.value)
			const settingsPaymentsOptionsValues = settingsPaymentsSelectOptions.map(
				option => option.value
			)
			const permissions = editingManager.permissions

			setEmail(editingManager.email)

			setTicketsValue(permissions.filter(permission => ticketsOptionsValues.includes(permission)))
			setContentValue(permissions.filter(permission => contentOptionsValues.includes(permission)))
			setDevelopersValue(
				permissions.filter(permission => developersOptionsValues.includes(permission))
			)
			setCouponsValue(permissions.filter(permission => couponsOptionsValues.includes(permission)))
			setCategoriesValue(
				permissions.filter(permission => categoriesOptionsValues.includes(permission))
			)
			setFraudsValue(permissions.filter(permission => fraudsOptionsValues.includes(permission)))
			setProductsValue(permissions.filter(permission => productsOptionsValues.includes(permission)))
			setOrdersValue(permissions.filter(permission => ordersOptionsValues.includes(permission)))
			setFeedbackValue(
				permissions.filter(permission => feedbacksOptionsValues.includes(permission))
			)
			setChargesValue(permissions.filter(permission => chargesOptionsValues.includes(permission)))
			setAnalyticsValue(
				permissions.filter(permission => analyticsOptionsValues.includes(permission))
			)
			setCustomizationValue(
				permissions.filter(permission => customizationOptionsValues.includes(permission))
			)
			setWarehousesValue(
				permissions.filter(permission => warehousesOptionsValues.includes(permission))
			)
			setSettingsGeneralValue(
				permissions.filter(permission => settingsGeneralOptionsValues.includes(permission))
			)
			setSettingsPaymentsValue(
				permissions.filter(permission => settingsPaymentsOptionsValues.includes(permission))
			)
			setAdministratorValue(
				permissions.includes(ShopForUserPermissions.ADMIN)
					? ShopForUserPermissions.ADMIN
					: undefined
			)
		}
	}, [])

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Invite Team Member</h1>
			</header>

			{inviteLink ? (
				<div className={styles.body}>
					<div className={styles.fieldWrapper}>
						<header>
							<div>
								<h2>Invite Link</h2>
								<p className={styles.fieldDescription}>
									Share this link with the team member you’d like to join your storefront.
								</p>
							</div>
						</header>
						<div className={styles.mt10}>
							<div className={styles.row}>
								<Input
									value={`https://dash.billgang.com/invite/${inviteLink.inviteToken}/accept`}
									setValue={() => {}}
									icon={{
										id: 'refresh',
										height: 13,
										width: 13,
										align: InputIconAligns.RIGHT,
										onClick: createInviteLink,
									}}
								/>
								<Button
									disableShadow={true}
									style={ButtonStyles.BORDERED}
									icon={{
										id: 'copy',
										width: 13,
										height: 13,
										align: ButtonsIconAlign.LEFT,
									}}
									onClick={() =>
										copy(`https://dash.billgang.com/invite/${inviteLink.inviteToken}/accept`)
									}
								>
									Copy
								</Button>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className={styles.body}>
					<div className={styles.fieldWrapper}>
						<h2>Team Member Email</h2>
						<div className={styles.mt10}>
							<Input
								value={email}
								setValue={setEmail}
								placeholder={'team@billgang.com'}
								errorMessage={errors['email']}
								disabled={!!editingManager}
								onBlur={() =>
									setErrors({
										...errors,
										email:
											fieldValidators.blankValidator('Team Member Email', email) ||
											fieldValidators.emailValidator(email),
									})
								}
							/>
						</div>
					</div>
					<div className={styles.mtMediumLarge}>
						<Tabs
							tabs={[
								{
									label: 'Basic',
									value: 'basic',
								},
								{
									label: 'Advanced',
									value: 'advanced',
								},
							]}
							currentTab={permissionTab}
							setCurrentTab={setPermissionTab}
						/>
					</div>

					{permissionTab === 'basic' && (
						<>
							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Administrator'}
									checked={!!administratorValue}
									setChecked={checked =>
										checked
											? setAdministratorValue(ShopForUserPermissions.ADMIN)
											: setAdministratorValue(undefined)
									}
									title={'Administrator'}
									description={'Member can read, edit, delete, and update your entire company.'}
									isReversed={true}
								/>
							</div>
							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Products'}
									checked={containsAllElements(
										productsValue,
										productsSelectOptions.map(option => option.value)
									)}
									setChecked={checked => {
										if (checked) {
											setProductsValue(productsSelectOptions.map(option => option.value))
										} else {
											setProductsValue([])
										}
									}}
									title={'Products'}
									description={'Member can read, edit, delete, and update products.'}
									isReversed={true}
								/>
							</div>
							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Warehouses'}
									checked={containsAllElements(
										warehousesValue,
										warehousesSelectOptions.map(option => option.value)
									)}
									setChecked={checked => {
										if (checked) {
											setWarehousesValue(warehousesSelectOptions.map(option => option.value))
										} else {
											setWarehousesValue([])
										}
									}}
									title={'Warehouses'}
									description={'Member can read, edit, delete, and update warehouses.'}
									isReversed={true}
								/>
							</div>
							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Categories'}
									checked={containsAllElements(
										categoriesValue,
										categoriesSelectOptions.map(option => option.value)
									)}
									setChecked={checked => {
										if (checked) {
											setCategoriesValue(categoriesSelectOptions.map(option => option.value))
										} else {
											setCategoriesValue([])
										}
									}}
									title={'Categories'}
									description={'Member can read, edit, delete, and update categories.'}
									isReversed={true}
								/>
							</div>
							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Customers'}
									checked={containsAllElements(
										customersValue,
										customersSelectOptions.map(option => option.value)
									)}
									setChecked={checked => {
										if (checked) {
											setCustomersValue(customersSelectOptions.map(option => option.value))
										} else {
											setCustomersValue([])
										}
									}}
									title={'Customers'}
									description={'Member can read, edit, delete, and update customers.'}
									isReversed={true}
								/>
							</div>
							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Coupons'}
									checked={containsAllElements(
										couponsValue,
										couponsSelectOptions.map(option => option.value)
									)}
									setChecked={checked => {
										if (checked) {
											setCouponsValue(couponsSelectOptions.map(option => option.value))
										} else {
											setCouponsValue([])
										}
									}}
									title={'Coupons'}
									description={'Member can read, edit, delete, and update coupons.'}
									isReversed={true}
								/>
							</div>
							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Posts'}
									checked={containsAllElements(
										contentValue,
										contentSelectOptions.map(option => option.value)
									)}
									setChecked={checked => {
										if (checked) {
											setContentValue(contentSelectOptions.map(option => option.value))
										} else {
											setContentValue([])
										}
									}}
									title={'Posts'}
									description={'Member can read, edit, delete, and update posts.'}
									isReversed={true}
								/>
							</div>
							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Analytics'}
									checked={containsAllElements(
										analyticsValue,
										analyticsSelectOptions.map(option => option.value)
									)}
									setChecked={checked => {
										if (checked) {
											setAnalyticsValue(analyticsSelectOptions.map(option => option.value))
										} else {
											setAnalyticsValue([])
										}
									}}
									title={'Analytics'}
									description={'Member can read and delete analytics.'}
									isReversed={true}
								/>
							</div>
							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Orders'}
									checked={containsAllElements(
										ordersValue,
										ordersSelectOptions.map(option => option.value)
									)}
									setChecked={checked => {
										if (checked) {
											setOrdersValue(ordersSelectOptions.map(option => option.value))
										} else {
											setOrdersValue([])
										}
									}}
									title={'Orders'}
									description={'Member can read, edit, delete, and update orders.'}
									isReversed={true}
								/>
							</div>
							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Charges'}
									checked={containsAllElements(
										chargesValue,
										chargesSelectOptions.map(option => option.value)
									)}
									setChecked={checked => {
										if (checked) {
											setChargesValue(chargesSelectOptions.map(option => option.value))
										} else {
											setChargesValue([])
										}
									}}
									title={'Charges'}
									description={'Member can read, edit, delete, and update charges.'}
									isReversed={true}
								/>
							</div>
							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Developers'}
									checked={containsAllElements(
										developersValue,
										developersSelectOptions.map(option => option.value)
									)}
									setChecked={checked => {
										if (checked) {
											setDevelopersValue(developersSelectOptions.map(option => option.value))
										} else {
											setDevelopersValue([])
										}
									}}
									title={'Developers'}
									description={'Member can read, edit, delete, and update developers.'}
									isReversed={true}
								/>
							</div>
							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Customization'}
									checked={containsAllElements(
										customizationValue,
										customizationSelectOptions.map(option => option.value)
									)}
									setChecked={checked => {
										if (checked) {
											setCustomizationValue(customizationSelectOptions.map(option => option.value))
										} else {
											setCustomizationValue([])
										}
									}}
									title={'Customization'}
									description={'Member can read, edit, delete, and update customization.'}
									isReversed={true}
								/>
							</div>

							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Feedback'}
									checked={containsAllElements(
										feedbackValue,
										feedbackSelectOptions.map(option => option.value)
									)}
									setChecked={checked => {
										if (checked) {
											setFeedbackValue(feedbackSelectOptions.map(option => option.value))
										} else {
											setFeedbackValue([])
										}
									}}
									title={'Feedback'}
									description={'Member can read, edit, delete, and update feedback.'}
									isReversed={true}
								/>
							</div>
							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Tickets'}
									checked={containsAllElements(
										ticketsValue,
										ticketsSelectOptions.map(option => option.value)
									)}
									setChecked={checked => {
										if (checked) {
											setTicketsValue(ticketsSelectOptions.map(option => option.value))
										} else {
											setTicketsValue([])
										}
									}}
									title={'Tickets'}
									description={'Member can read, edit, delete, and update tickets.'}
									isReversed={true}
								/>
							</div>
							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Frauds'}
									checked={containsAllElements(
										fraudsValue,
										fraudsSelectOptions.map(option => option.value)
									)}
									setChecked={checked => {
										if (checked) {
											setFraudsValue(fraudsSelectOptions.map(option => option.value))
										} else {
											setFraudsValue([])
										}
									}}
									title={'Fraud Shield'}
									description={'Member can read, edit, delete, and update fraud shield.'}
									isReversed={true}
								/>
							</div>

							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'Payment Settings'}
									checked={containsAllElements(
										settingsPaymentsValue,
										settingsPaymentsSelectOptions.map(option => option.value)
									)}
									setChecked={checked => {
										if (checked) {
											setSettingsPaymentsValue(
												settingsPaymentsSelectOptions.map(option => option.value)
											)
										} else {
											setSettingsPaymentsValue([])
										}
									}}
									title={'Settings Payments'}
									description={'Member can read, edit, delete, and update payment settings.'}
									isReversed={true}
								/>
							</div>
							<div className={styles.mtMediumLarge}>
								<CheckboxWrapper
									id={'General Settings'}
									checked={containsAllElements(
										settingsGeneralValue,
										settingsGeneralSelectOptions.map(option => option.value)
									)}
									setChecked={checked => {
										if (checked) {
											setSettingsGeneralValue(
												settingsGeneralSelectOptions.map(option => option.value)
											)
										} else {
											setSettingsGeneralValue([])
										}
									}}
									title={'General Settings'}
									description={'Member can read, edit, delete, and update general settings.'}
									isReversed={true}
								/>
							</div>
						</>
					)}
					{permissionTab === 'advanced' && (
						<>
							<div className={styles.horLine} />
							<div className={styles.fieldWrapper}>
								<header>
									<div>
										<h2>Products</h2>
										<p className={styles.fieldDescription}>
											Member can create, read, update, and delete products.
										</p>
									</div>
								</header>
								<div className={styles.mt10}>
									<CustomMultiSelect
										value={productsValue}
										setValue={setProductsValue}
										options={productsSelectOptions}
									/>
								</div>
							</div>
							<div className={styles.horLine} />
							<div className={styles.fieldWrapper}>
								<header>
									<div>
										<h2>Customers</h2>
										<p className={styles.fieldDescription}>
											Member can create, read, update, and delete customers.
										</p>
									</div>
								</header>
								<div className={styles.mt10}>
									<CustomMultiSelect
										value={customersValue}
										setValue={setCustomersValue}
										options={customersSelectOptions}
									/>
								</div>
							</div>
							<div className={styles.horLine} />
							<div className={styles.fieldWrapper}>
								<header>
									<div>
										<h2>Coupons</h2>
										<p className={styles.fieldDescription}>
											Member can create, read, update, and delete coupons.
										</p>
									</div>
								</header>
								<div className={styles.mt10}>
									<CustomMultiSelect
										value={couponsValue}
										setValue={setCouponsValue}
										options={couponsSelectOptions}
									/>
								</div>
							</div>
							<div className={styles.horLine} />
							<div className={styles.fieldWrapper}>
								<header>
									<div>
										<h2>Fraud Shield</h2>
										<p className={styles.fieldDescription}>
											Member can create, read, update, and delete fraud shield.
										</p>
									</div>
								</header>
								<div className={styles.mt10}>
									<CustomMultiSelect
										value={fraudsValue}
										setValue={setFraudsValue}
										options={fraudsSelectOptions}
									/>
								</div>
							</div>
							<div className={styles.horLine} />
							<div className={styles.fieldWrapper}>
								<header>
									<div>
										<h2>Warehouses</h2>
										<p className={styles.fieldDescription}>
											Member can create, read, update, and delete warehouses.
										</p>
									</div>
								</header>
								<div className={styles.mt10}>
									<CustomMultiSelect
										value={warehousesValue}
										setValue={setWarehousesValue}
										options={warehousesSelectOptions}
									/>
								</div>
							</div>
							<div className={styles.horLine} />
							<div className={styles.fieldWrapper}>
								<header>
									<div>
										<h2>Categories</h2>
										<p className={styles.fieldDescription}>
											Member can create, read, update, and delete categories.
										</p>
									</div>
								</header>
								<div className={styles.mt10}>
									<CustomMultiSelect
										value={categoriesValue}
										setValue={setCategoriesValue}
										options={categoriesSelectOptions}
									/>
								</div>
							</div>
							<div className={styles.horLine} />
							<div className={styles.fieldWrapper}>
								<header>
									<div>
										<h2>Analytics</h2>
										<p className={styles.fieldDescription}>Member can read and delete analytics.</p>
									</div>
								</header>
								<div className={styles.mt10}>
									<CustomMultiSelect
										value={analyticsValue}
										setValue={setAnalyticsValue}
										options={analyticsSelectOptions}
									/>
								</div>
							</div>
							<div className={styles.horLine} />
							<div className={styles.fieldWrapper}>
								<header>
									<div>
										<h2>Posts</h2>
										<p className={styles.fieldDescription}>
											Member create, read, update, and delete posts.
										</p>
									</div>
								</header>
								<div className={styles.mt10}>
									<CustomMultiSelect
										value={contentValue}
										setValue={setContentValue}
										options={contentSelectOptions}
									/>
								</div>
							</div>
							<div className={styles.horLine} />
							<div className={styles.fieldWrapper}>
								<header>
									<div>
										<h2>Orders</h2>
										<p className={styles.fieldDescription}>Member can read, update orders.</p>
									</div>
								</header>
								<div className={styles.mt10}>
									<CustomMultiSelect
										value={ordersValue}
										setValue={setOrdersValue}
										options={ordersSelectOptions}
									/>
								</div>
							</div>
							<div className={styles.horLine} />
							<div className={styles.fieldWrapper}>
								<header>
									<div>
										<h2>Tickets</h2>
										<p className={styles.fieldDescription}>Member can read, update tickets.</p>
									</div>
								</header>
								<div className={styles.mt10}>
									<CustomMultiSelect
										value={ticketsValue}
										setValue={setTicketsValue}
										options={ticketsSelectOptions}
									/>
								</div>
							</div>
							<div className={styles.horLine} />
							<div className={styles.fieldWrapper}>
								<header>
									<div>
										<h2>Customization</h2>
										<p className={styles.fieldDescription}>
											Member can create, read, update, and delete customization.
										</p>
									</div>
								</header>
								<div className={styles.mt10}>
									<CustomMultiSelect
										value={customizationValue}
										setValue={setCustomizationValue}
										options={customizationSelectOptions}
									/>
								</div>
							</div>
							<div className={styles.horLine} />
							<div className={styles.fieldWrapper}>
								<header>
									<div>
										<h2>Feedback</h2>
										<p className={styles.fieldDescription}>
											Member can create, read, update, and delete feedback.
										</p>
									</div>
								</header>
								<div className={styles.mt10}>
									<CustomMultiSelect
										value={feedbackValue}
										setValue={setFeedbackValue}
										options={feedbackSelectOptions}
									/>
								</div>
							</div>
							<div className={styles.horLine} />
							<div className={styles.fieldWrapper}>
								<header>
									<div>
										<h2>Developers</h2>
										<p className={styles.fieldDescription}>
											Member can create, read, update, and delete developers.
										</p>
									</div>
								</header>
								<div className={styles.mt10}>
									<CustomMultiSelect
										value={developersValue}
										setValue={setDevelopersValue}
										options={developersSelectOptions}
									/>
								</div>
							</div>
							<div className={styles.horLine} />
							<div className={styles.fieldWrapper}>
								<header>
									<div>
										<h2>Settings General</h2>
										<p className={styles.fieldDescription}>
											Member can create, read, update, and delete general settings.
										</p>
									</div>
								</header>
								<div className={styles.mt10}>
									<CustomMultiSelect
										value={settingsGeneralValue}
										setValue={setSettingsGeneralValue}
										options={settingsGeneralSelectOptions}
									/>
								</div>
							</div>
							<div className={styles.horLine} />
							<div className={styles.fieldWrapper}>
								<header>
									<div>
										<h2>Payment Settings</h2>
										<p className={styles.fieldDescription}>
											Member can create, read, update, and delete payment settings.
										</p>
									</div>
								</header>
								<div className={styles.mt10}>
									<CustomMultiSelect
										value={settingsPaymentsValue}
										setValue={setSettingsPaymentsValue}
										options={settingsPaymentsSelectOptions}
									/>
								</div>
							</div>
						</>
					)}

					{errors['permissions'] && (
						<div className={styles.mtMediumLarge}>
							<FieldErrorMessage errorMessage={errors['permissions']} />
						</div>
					)}
				</div>
			)}

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button
						style={ButtonStyles.BORDERED}
						disableShadow={true}
						onClick={onClose}
						width={'fit-content'}
					>
						{inviteLink ? 'Close' : 'Cancel'}
					</Button>
					{!inviteLink && (
						<Button
							style={ButtonStyles.BORDERED}
							disableShadow={true}
							icon={{
								id: editingManager ? 'download' : 'plus',
								width: 13,
								height: 13,
								align: ButtonsIconAlign.LEFT,
							}}
							onClick={onSubmitClick}
							isLoading={isCreateInviteLinkLoading || isUpdatePermissionsLoading}
						>
							{editingManager ? 'Save Team Roles' : 'Create Invite Link'}
						</Button>
					)}
				</div>
			</footer>
		</div>
	)
}

export default InviteTeamMemberModal
