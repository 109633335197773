import React, { FC } from 'react'
import styles from './NoDataComponent.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { useAppContext } from '../../hooks/useAppContext'
import ShopStatusBlock from '../ShopStatusBlock/ShopStatusBlock'

interface EmptyComponentProps {
	imageSrc: string
	title: string
	description: string
	isSmallHeight?: boolean
	button?: {
		text: string
		onClick: () => void
		icon?: {
			id: string
			width: number
			height: number
			align: ButtonsIconAlign
		}
		isLoading?: boolean
	} | null
}

const NoDataComponent: FC<EmptyComponentProps> = ({
	imageSrc,
	description,
	button,
	title,
	isSmallHeight,
}) => {
	const {shop} = useAppContext();
	if (shop?.status === 'LIVE') {
		return (
			<div className={`${styles.emptyComponent} ${isSmallHeight && styles.small}`}>
				<img src={imageSrc} alt='no' />
				<h1>{title}</h1>
				<p className={styles.description} dangerouslySetInnerHTML={{ __html: description }}></p>
				{button && (
					<Button
						style={ButtonStyles.SECONDARY}
						icon={button.icon}
						onClick={button.onClick}
						isLoading={button.isLoading}
					>
						{button.text}
					</Button>
				)}
			</div>
		)
	} else {
		return <ShopStatusBlock/>
	}

}

export default NoDataComponent
