import React, { FC } from 'react'
import styles from './ProductAdvisor.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { CreateProductSectionIds } from '../../types/CreateProductSectionIds/CreateProductSectionIds'
import { Image } from '../../types/Image/Image'
import { ListingVisibility } from '../../types/ListingVisibility/ListingVisibility'
import { scrollIntoViewAndFocus } from '../../helpers/scrollIntoViewAndFocus'

interface ProductAdvisorDesignProps {
	percent: number
	setCurrenSectionId: (value: CreateProductSectionIds) => void
	images: Image[]
	visibility: ListingVisibility
}

const ProductAdvisorDesign: FC<ProductAdvisorDesignProps> = ({
	setCurrenSectionId,
	percent,
	visibility,
	images,
}) => {
	const onNextClickHandler = () => {
		setCurrenSectionId(CreateProductSectionIds.VARIANTS)
		scrollIntoViewAndFocus(CreateProductSectionIds.VARIANTS)
	}

	const onBackClickHandler = () => {
		setCurrenSectionId(CreateProductSectionIds.GENERAL)
		scrollIntoViewAndFocus(CreateProductSectionIds.GENERAL)
	}

	return (
		<div className={styles.productAdvisor}>
			<header className={styles.header}>
				<h2 className={styles.title}>Product Advisor</h2>
				<p className={styles.progressPercent}>{percent}%</p>

				<div className={styles.progressBar}>
					<div
						className={styles.progressBarActiveLine}
						style={{
							width: `${percent}%`,
						}}
					/>
					<div
						className={styles.progressBarWillActiveLine}
						style={{
							width: `${percent + (!images.length ? 10 : 0)}%`,
						}}
					/>
				</div>

				<p className={styles.description}>
					Before you create this product, we recommend fixing these critical issues.
				</p>
			</header>

			<div className={styles.body}>
				<h2 className={styles.title}>Design & Experience</h2>

				<div className={styles.advices}>
					<div className={`${styles.adviceItem} ${images.length && styles.done}`}>
						<div>
							<h3 className={styles.adviceTitle}>Media</h3>
							<p className={styles.adviceDescription}>Add an image to your product.</p>
						</div>

						<div className={styles.advicePercent}>+10%</div>
						<SpriteIcon
							className={styles.checked}
							iconId={'checkedCircle'}
							width={15}
							height={15}
						/>
					</div>

					<div
						className={`${styles.adviceItem} ${
							visibility === ListingVisibility.PUBLIC ? styles.done : styles.alert
						}`}
					>
						<div>
							<h3 className={styles.adviceTitle}>Visibility</h3>
							<p className={styles.adviceDescription}>Set your product visibility to PUBLIC.</p>
						</div>
						<SpriteIcon
							className={styles.asteriskSymbol}
							iconId={'asteriskSymbol'}
							width={10}
							height={10}
						/>
						<SpriteIcon
							className={styles.checked}
							iconId={'checkedCircle'}
							width={15}
							height={15}
						/>
					</div>
				</div>

				<footer className={styles.footer}>
					<div className={styles.buttons}>
						<Button
							style={ButtonStyles.BORDERED}
							disableShadow={true}
							width={'100%'}
							icon={{
								id: 'arrowLeft',
								height: 12,
								width: 13,
								align: ButtonsIconAlign.LEFT,
							}}
							onClick={onBackClickHandler}
						>
							Back
						</Button>
						<Button
							style={ButtonStyles.BORDERED}
							disableShadow={true}
							width={'100%'}
							icon={{
								id: 'arrowRight',
								height: 12,
								width: 13,
								align: ButtonsIconAlign.RIGHT,
							}}
							onClick={onNextClickHandler}
						>
							Next
						</Button>
					</div>
				</footer>
			</div>
		</div>
	)
}

export default ProductAdvisorDesign
