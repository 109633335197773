import { privateAPI } from './API'
import { AxiosProgressEvent } from 'axios'

export const ImagesService = {
	getAll: async (shopId: string, searchString?: string, PageNumber?: number, PageSize?: number) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/images`, {
			params: {
				searchString,
				PageNumber,
				PageSize,
			},
		})
	},
	upload: async (
		shopId: string,
		data: FormData,
		onUploadProgress?: (data: AxiosProgressEvent) => void
	) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/images`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			onUploadProgress: onUploadProgress,
		})
	},
	delete: async (shopId: string, imageId: number | null) => {
		return await privateAPI.delete(`/v1/dash/shops/${shopId}/images/${imageId}`)
	},
}
