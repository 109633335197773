import React, { useEffect, useState } from 'react'
import styles from './CreateCouponPage.module.scss'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageHeader from '../../components/PageHeader/PageHeader'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import Input from '../../components/UI/FormComponents/Input'
import Switch from '../../components/UI/Switch/Switch'
import CustomMultiSelect from '../../components/UI/CustomMultiSelect/CustomMultiSelect'
import DatePicker from '../../components/UI/DatePicker/DatePicker'
import Radio from '../../components/UI/Radio/Radio'
import uniqid from 'uniqid'
import { useAPI } from '../../hooks/useAPI'
import { CouponsService } from '../../API/CouponsService'
import { useAppContext } from '../../hooks/useAppContext'
import { Product } from '../../types/Product/Product'
import { ProductsService } from '../../API/ProductsService'
import { MultiValue } from 'react-select'
import CustomSelect, { SelectStyles } from '../../components/UI/CustomSelect/CustomSelect'
import { Gateways } from '../../types/Gateways/Gateways'
import { PaymentsSettingsService } from '../../API/PaymentsSettingsService'
import { SettingsPayments } from '../../types/SettingsPayments/SettingsPayments'
import { getGatewaysOptions } from '../../helpers/getGatewaysOptions'
import { useNavigate, useParams } from 'react-router-dom'
import { CouponDetailed } from '../../types/CouponDetailed/CouponDetailed'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import { CreateCoupon } from '../../types/CreateCoupon/CreateCoupon'
import FormErrorsDisplayBadge from '../../components/UI/FormErrorsDisplayBadge/FormErrorsDisplayBadge'
import { fieldValidators } from '../../helpers/fieldValidators'
import RequiredBadge from '../../components/UI/RequiredBadge/RequiredBadge'
import { usePageTitle } from '../../hooks/usePageTitle'

const CreateCouponPage = () => {
	const { shop, availablePayments } = useAppContext()
	const [couponCode, setCouponCode] = useState(uniqid())
	const [limitStock, setLimitStock] = useState<number>()
	const [isFixed, setIsFixed] = useState(false)
	const [discount, setDiscount] = useState<number>()
	const [isLimitStockActive, setIsLimitStockActive] = useState(false)
	const [startDate, setStartDate] = useState(new Date())
	const [endDate, setEndDate] = useState(new Date())
	const [isStartDateActive, setIsStartDateActive] = useState(false)
	const [isEndDateActive, setIsEndDateActive] = useState(false)
	const [isPaymentsSelectActive, setIsPaymentsSelectActive] = useState(false)
	const [isProductsSelectActive, setIsProductsSelectActive] = useState(false)
	const [productsSelectValue, setProductsSelectValue] = useState<string[]>([])
	const [gatewaysSelectValue, setGatewaysSelectValue] = useState<string[]>([])
	const [errors, setErrors] = useState({
		discount: '',
		limitStock: '',
		couponCode: '',
	})
	const [showErrorsDisplay, setShowErrorsDisplay] = useState(false)
	usePageTitle('Create Coupon')

	const params = useParams()
	const couponId = params.id
	const navigate = useNavigate()
	const errorMessages = Object.keys(errors)
		//@ts-ignore
		.map(key => errors[key as any])
		.filter(error => error)

	const [isCreateCouponLoading, , createCoupon] = useAPI(
		(data: CreateCoupon) => CouponsService.create(shop?.id || '', data),
		false,
		'Coupon successfully created',
		{
			onSuccess: () => {
				navigate('/products/coupons')
			},
		}
	)

	const [isUpdateCouponLoading, , updateCoupon] = useAPI(
		(data: CreateCoupon) => CouponsService.update(shop?.id || '', Number(couponId), data),
		false,
		'Coupon successfully updated',
		{
			onSuccess: () => {
				navigate('/products/coupons')
			},
		}
	)

	const [isGetCouponLoading, coupon, getCoupon] = useAPI<CouponDetailed>(
		() => CouponsService.getOne(shop?.id || '', Number(couponId)),
		false
	)

	const [isGetProductsLoading, products, getProducts] = useAPI<Product[]>(
		() => ProductsService.getProducts(shop?.id || ''),
		false
	)

	const [isGetPaymentsLoading, payments, getPayments] = useAPI<SettingsPayments>(
		() => PaymentsSettingsService.getPayments(shop?.id || ''),
		false
	)

	const validateCouponData = () => {
		const limitStockValidator = isLimitStockActive
			? fieldValidators.blankValidator('Coupon limit', limitStock)
			: ''
		const discountValidator = fieldValidators.blankValidator(
			`Coupon discount ${isFixed ? 'amount' : 'percentage'}`,
			discount
		)
		const couponCodeValidator = fieldValidators.blankValidator('Coupon code', couponCode)

		setErrors({
			limitStock: limitStockValidator,
			discount: discountValidator,
			couponCode: couponCodeValidator,
		})

		return !(limitStockValidator || discountValidator)
	}

	const onSubmitClickHandler = () => {
		if (validateCouponData()) {
			const data: CreateCoupon = {
				discount,
				isFixed,
				name: couponCode,
				startDate: isStartDateActive ? startDate : null,
				endDate: isEndDateActive ? endDate : null,
				maxUses: isLimitStockActive ? limitStock : null,
				restrictToProductsIds: productsSelectValue.map(id => +id),
				restrictToGateways: gatewaysSelectValue as Gateways[],
			}

			if (couponId) {
				updateCoupon<CreateCoupon>(data)
			} else {
				createCoupon<CreateCoupon>(data)
			}
		} else {
			setShowErrorsDisplay(true)
		}
	}

	useEffect(() => {
		if (shop) {
			getProducts()
			getPayments()

			if (couponId) {
				getCoupon()
			}
		}
	}, [shop])

	useEffect(() => {
		if (endDate.getTime() < startDate.getTime()) {
			setEndDate(startDate)
		}
	}, [startDate])

	useEffect(() => {
		if (coupon && products) {
			setIsEndDateActive(!!coupon?.endDate)
			setIsStartDateActive(!!coupon?.startDate)
			setEndDate(coupon?.endDate ? new Date(coupon?.endDate) : new Date())
			setStartDate(coupon?.startDate ? new Date(coupon?.startDate) : new Date())
			setGatewaysSelectValue(coupon?.restrictToGateways || [])
			setIsProductsSelectActive(!!coupon?.restrictToProductIds)
			setIsPaymentsSelectActive(!!coupon?.restrictToGateways)
			setProductsSelectValue(coupon?.restrictToProductIds?.map(id => id.toString()) || [])
			setLimitStock(coupon?.maxUses || 0)
			setIsLimitStockActive(!!coupon?.maxUses)
			setIsFixed(coupon.isFixed)
			setDiscount(coupon.discount)
			setCouponCode(coupon.name)
		}
	}, [coupon, products])

	if (isGetCouponLoading) return <PageLoading />

	return (
		<PageContainer>
			<PageWrapper>
				{showErrorsDisplay && !!errorMessages.length && (
					<FormErrorsDisplayBadge
						title={`Coupon ${couponId ? 'Edit' : 'Create'} Error`}
						errors={errorMessages}
					/>
				)}

				<div className={styles.createCouponPage}>
					<PageHeader title={`${couponId ? 'Edit' : 'Create'} Coupon`} />

					<div className={styles.body}>
						<div className={styles.section}>
							<h1 className={styles.sectionTitle}>Promotion Details</h1>

							<div className={styles.horLine} />

							<div className={styles.fieldWrapper}>
								<p className={styles.fieldTitle}>
									Coupon Code <RequiredBadge />
								</p>
								<div className={styles.mt10}>
									<Input
										value={couponCode}
										setValue={setCouponCode}
										placeholder={'WINTERLAUNCH'}
										errorMessage={errors.couponCode}
										onBlur={() =>
											setErrors({
												...errors,
												couponCode: fieldValidators.blankValidator('Coupon code', couponCode),
											})
										}
									/>
									<p className={styles.fieldDescription}>
										This is the code customers will enter prior to completing their checkout.
									</p>
								</div>
							</div>

							{/*<div className={styles.fieldWrapper}>*/}
							{/*	<p className={styles.fieldTitle}>Discount Length</p>*/}
							{/*	<CustomSelect*/}
							{/*		style={SelectStyles.secondary}*/}
							{/*		value={'couponCode'}*/}
							{/*		setValue={() => {}}*/}
							{/*		options={[*/}
							{/*			{*/}
							{/*				value: 'Once - first payment only',*/}
							{/*				label: 'Once - first payment only',*/}
							{/*			},*/}
							{/*		]}*/}
							{/*	/>*/}

							{/*	<p className={styles.fieldDescription}>*/}
							{/*		Set the duration of the discount when applied to a subscription.*/}
							{/*	</p>*/}
							{/*</div>*/}

							{/*<div className={styles.horLine} />*/}
							{/*<div className={styles.fieldWrapper}>*/}
							{/*	<header>*/}
							{/*		<div>*/}
							{/*			<p className={styles.fieldTitle}>One Time</p>*/}
							{/*			<p className={styles.fieldDescription}>*/}
							{/*				Discount to only be used one time per customer.*/}
							{/*			</p>*/}
							{/*		</div>*/}
							{/*		<Switch checked={true} setChecked={() => {}} />*/}
							{/*	</header>*/}
							{/*</div>*/}
							<div className={styles.horLine} />

							<div className={styles.doubleFields}>
								<div className={styles.fieldWrapper}>
									<header>
										<div>
											<p className={styles.fieldTitle}>Select Payments</p>
										</div>
										<Switch
											checked={isPaymentsSelectActive}
											setChecked={setIsPaymentsSelectActive}
										/>
									</header>

									{isPaymentsSelectActive && (
										<div className={styles.mt10}>
											<CustomMultiSelect
												options={
													availablePayments?.map(gateway => ({
														value: gateway.name,
														label: gateway.displayName,
													})) || []
												}
												value={gatewaysSelectValue}
												setValue={setGatewaysSelectValue}
											/>
										</div>
									)}
									<p
										className={`${styles.fieldDescription} ${
											!isPaymentsSelectActive && styles.mt5
										}`}
									>
										Limit this coupon to certain select payment methods.
									</p>
								</div>
								<div className={styles.fieldWrapper}>
									<header>
										<div>
											<p className={styles.fieldTitle}>Select Products</p>
										</div>
										<Switch
											checked={isProductsSelectActive}
											setChecked={setIsProductsSelectActive}
										/>
									</header>

									{isProductsSelectActive && (
										<div className={styles.mt10}>
											<CustomMultiSelect
												options={
													products?.map(product => ({
														label: product.name,
														value: product.id.toString(),
													})) || []
												}
												value={productsSelectValue}
												setValue={setProductsSelectValue}
											/>
										</div>
									)}
									<p
										className={`${styles.fieldDescription} ${
											!isProductsSelectActive && styles.mt5
										}`}
									>
										Limit this coupon to certain select products.
									</p>
								</div>
							</div>

							<div className={styles.horLine} />

							<div className={styles.doubleFields}>
								<div className={styles.fieldWrapper}>
									<header>
										<div>
											<p className={styles.fieldTitle}>Start Date</p>
										</div>
										<Switch checked={isStartDateActive} setChecked={setIsStartDateActive} />
									</header>
									{isStartDateActive && (
										<div className={styles.mt10}>
											<DatePicker date={startDate} setDate={setStartDate} minDate={new Date()} />
										</div>
									)}
									<p className={`${styles.fieldDescription} ${!isStartDateActive && styles.mt5}`}>
										Set a date for this coupon to release.
									</p>
								</div>
								<div className={styles.fieldWrapper}>
									<header>
										<div>
											<p className={styles.fieldTitle}>End Date</p>
										</div>
										<Switch checked={isEndDateActive} setChecked={setIsEndDateActive} />
									</header>

									{isEndDateActive && (
										<div className={styles.mt10}>
											<DatePicker date={endDate} setDate={setEndDate} minDate={startDate} />
										</div>
									)}
									<p className={`${styles.fieldDescription} ${!isEndDateActive && styles.mt5}`}>
										Expire the coupon code at a certain time.
									</p>
								</div>
							</div>
						</div>

						<div className={styles.column}>
							<Button
								style={ButtonStyles.SECONDARY}
								icon={{
									id: 'plus',
									width: 13,
									height: 13,
									align: ButtonsIconAlign.LEFT,
								}}
								onClick={onSubmitClickHandler}
								isLoading={isCreateCouponLoading || isUpdateCouponLoading}
							>
								{couponId ? 'Edit' : 'Create'} coupon
							</Button>

							<div className={styles.section}>
								<h1 className={styles.sectionTitle}>Promotion Value </h1>

								<div className={styles.horLine} />

								<div className={styles.fieldWrapper}>
									<p className={styles.fieldTitle}>
										{isFixed ? 'Amount' : 'Percentage'} <RequiredBadge />
									</p>

									<div className={styles.mt10}>
										<Input
											value={discount}
											setValue={setDiscount}
											placeholder={isFixed ? '0.00' : '0%'}
											errorMessage={errors.discount}
											onBlur={() =>
												setErrors({
													...errors,
													discount: fieldValidators.blankValidator(
														`Coupon discount ${isFixed ? 'amount' : 'percentage'}`,
														discount
													),
												})
											}
										/>
									</div>

									<div className={styles.couponTypes}>
										<label className={styles.radioWrapper}>
											<Radio
												id={'fixed'}
												value={'fixed'}
												setValue={() => setIsFixed(true)}
												checked={isFixed}
											/>
											<div className={styles.label}>
												<p>Fixed</p>
												<span>Numerical ($) value</span>
											</div>
										</label>
										<label className={styles.radioWrapper}>
											<Radio
												id={'PERCENTAGE'}
												value={'PERCENTAGE'}
												setValue={() => setIsFixed(false)}
												checked={!isFixed}
											/>
											<div className={styles.label}>
												<p>PERCENTAGE</p>
												<span>Percent (%) value</span>
											</div>
										</label>
									</div>
								</div>

								<div className={styles.horLine} />

								<div className={styles.fieldWrapper}>
									<header>
										<div>
											<p className={styles.fieldTitle}>Limit Availability</p>
										</div>
										<Switch checked={isLimitStockActive} setChecked={setIsLimitStockActive} />
									</header>

									{isLimitStockActive && (
										<div className={styles.mt10}>
											<Input
												value={limitStock}
												setValue={setLimitStock}
												placeholder={'0'}
												errorMessage={errors.limitStock}
												onBlur={() =>
													setErrors({
														...errors,
														limitStock: fieldValidators.blankValidator('Coupon limit', limitStock),
													})
												}
											/>
										</div>
									)}

									<p className={`${styles.fieldDescription} ${!isLimitStockActive && styles.mt5}`}>
										Limit availability of the coupon.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default CreateCouponPage
