import React, { FC, useEffect, useState } from 'react'
import modalStyles from '../Modals.module.scss'
import Button, { ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import styles from './ViewDeliveryModal.module.scss'
import { SpriteIcon } from '../../UI/SpriteIcon/SpriteIcon'
import CustomSelect, { SelectStyles } from '../../UI/CustomSelect/CustomSelect'
import SerialsDeliveryTable from '../../Tables/SerialsDeliveryTable'
import Pagination from '../../UI/Pagination/Pagination'
import { PartOrder } from '../../../types/PartOrder/PartOrder'
import { productDeliverablesOptions } from '../../../helpers/productDeliverablesOptions'
import { DeliveryConfigurationType } from '../../../types/DeliveryConfigurationType/DeliveryConfigurationType'
import Textarea from '../../UI/FormComponents/Textarea'
import { PartOrderDeliveryCategory } from '../../../types/PartOrderDeliveryCategory/PartOrderDeliveryCategory'
import Input from '../../UI/FormComponents/Input'
import { GetDeliverySerial } from '../../../types/GetDeliverySerial/GetDeliverySerial'

interface ViewDeliveryModalProps extends BaseModalProps {
	partOrders?: PartOrder[]
	currentDeliveredProductId: number | null
	currentReplacementId: number | null
}

const ViewDeliveryModal: FC<ViewDeliveryModalProps> = ({
	onClose,
	partOrders,
	currentDeliveredProductId,
	currentReplacementId,
}) => {
	const [currentSerialsPage, setCurrentSerialsPage] = useState(0)
	const [serials, setSerials] = useState<GetDeliverySerial[]>([])
	const currentPartOrder = partOrders?.find(partOrder => partOrder.id === currentDeliveredProductId)
	const [currentDeliveryCategory, setCurrentDeliveryCategory] =
		useState<PartOrderDeliveryCategory | null>(null)
	const deliveryCategories = currentReplacementId
		? currentPartOrder?.replacements.find(replacement => replacement.id === currentReplacementId)
				?.deliveryCategories
		: currentPartOrder?.deliveryCategories

	const serialKeys = currentDeliveryCategory?.deliveryItems.map(
		deliveryItem => deliveryItem.serialKey
	)

	useEffect(() => {
		if (serialKeys && currentDeliveryCategory?.type === DeliveryConfigurationType.SERIALS) {
			setSerials(
				serialKeys?.filter(
					(listing, index) =>
						index + 1 > 4 * currentSerialsPage && index < 4 * (currentSerialsPage + 1)
				) || []
			)
		}
	}, [currentSerialsPage, currentDeliveryCategory?.deliveryItems])

	useEffect(() => {
		if (deliveryCategories) {
			setCurrentDeliveryCategory(deliveryCategories[0])
		}
	}, [deliveryCategories])

	return (
		<div className={modalStyles.modalInner}>
			<header className={modalStyles.header}>
				<h1>
					{!currentDeliveryCategory?.customName
						? 'View Delivery'
						: currentDeliveryCategory.customName}
				</h1>
			</header>
			<div className={modalStyles.body}>
				<div className={styles.deliveries}>
					{deliveryCategories?.map(deliveryCategory => (
						<div
							className={`${styles.deliveriesItem} ${
								currentDeliveryCategory?.type === deliveryCategory.type && styles.active
							}`}
							onClick={() => setCurrentDeliveryCategory(deliveryCategory)}
						>
							<SpriteIcon
								iconId={productDeliverablesOptions[deliveryCategory.type].icon.id}
								width={16}
								height={15}
							/>
						</div>
					))}
				</div>

				{currentDeliveryCategory?.type === DeliveryConfigurationType.SERIALS && (
					<div className={modalStyles.mtMediumLarge}>
						<div className={modalStyles.fieldWrapper}>
							<SerialsDeliveryTable serials={serials} />
						</div>
						<div className={modalStyles.mtMediumLarge}>
							<Pagination
								withoutPageSizeSelector={true}
								totalItems={serialKeys?.length || 0}
								pageSize={4}
								setCurrentPage={setCurrentSerialsPage}
								currentPage={currentSerialsPage}
							/>
						</div>
					</div>
				)}

				{(currentDeliveryCategory?.type === DeliveryConfigurationType.TEXT ||
					currentDeliveryCategory?.type === DeliveryConfigurationType.CUSTOM) &&
					currentDeliveryCategory?.deliveryItems.map(deliveryItem => (
						<div className={modalStyles.fieldWrapper}>
							<Textarea value={deliveryItem.textData} setValue={() => {}} />
						</div>
					))}

				{(currentDeliveryCategory?.type === DeliveryConfigurationType.DOWNLOAD ||
					currentDeliveryCategory?.type === DeliveryConfigurationType.EBOOK ||
					currentDeliveryCategory?.type === DeliveryConfigurationType.LINK) &&
					currentDeliveryCategory?.deliveryItems.map(deliveryItem => (
						<div className={modalStyles.fieldWrapper} key={deliveryItem.id}>
							<Input value={deliveryItem.textData} setValue={() => {}} />
						</div>
					))}

				{currentDeliveryCategory?.type === DeliveryConfigurationType.DISCORD && (
					<div className={modalStyles.mtMediumLarge}>
						<div className={styles.discordRoles}>
							{currentDeliveryCategory.deliveryItems.map(item => (
								<div className={styles.discordRole}>{item.textData}</div>
							))}
						</div>
					</div>
				)}
			</div>
			<footer className={modalStyles.footer}>
				<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
					Cancel
				</Button>
			</footer>
		</div>
	)
}

export default ViewDeliveryModal
