import { privateAPI } from './API'
import { FraudRuleType } from '../types/FraudRuleType/FraudRuleType'
import { CreateFraudRule } from '../types/CreateFraudRule/CreateFraudRule'

export const FraudRulesService = {
	getAll: async (
		shopId: string,
		searchString?: string,
		PageNumber?: number,
		PageSize?: number,
		filterType?: FraudRuleType
	) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/fraud/rules`, {
			params: {
				searchString,
				PageNumber,
				PageSize,
				filterType,
			},
		})
	},
	create: async (shopId: string, data: CreateFraudRule) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/fraud/rules`, data)
	},
	delete: async (shopId: string, fraudRuleId: number) => {
		return await privateAPI.delete(`/v1/dash/shops/${shopId}/fraud/rules/${fraudRuleId}`)
	},
}
