import React, { FC } from 'react'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import styles from './UpdatedOnDateBadge.module.scss'
import { format, formatDistanceToNow } from 'date-fns'

interface UpdatedOnDateBadgeProps {
	date: Date | null
}

const UpdatedOnDateBadge: FC<UpdatedOnDateBadgeProps> = ({ date }) => {
	return (
		<div
			className={styles.UpdatedOnDateBadge}
			data-tooltip-id='my-tooltip'
			data-tooltip-content={`Updated ${formatDistanceToNow(date || new Date())} ago`}
		>
			<SpriteIcon iconId={'clock'} width={14} height={14} />
		</div>
	)
}

export default UpdatedOnDateBadge
