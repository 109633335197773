import { privateAPI } from './API'
import { CreateRewardRule } from '../types/CreateRewardRule/CreateRewardRule'
import { CreateReferralSystems } from '../types/CreateReferralSystems/CreateReferralSystems'

export const RewardsService = {
	getAll: async (shopId: string) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/rewards`)
	},
	create: async (shopId: string, data: CreateRewardRule) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/rewards`, data)
	},
	update: async (shopId: string, id: number | undefined, data: CreateRewardRule) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/rewards/${id}`, data)
	},
	updateReferralsSystems: async (shopId: string, data: CreateReferralSystems) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/rewards/referral-system`, data)
	},
}
