import { privateAPI } from './API'
import { CreateGroup } from '../types/CreateGroup/CreateGroup'

export const GroupsService = {
	getAll: async (
		shopId: string,
		searchString?: string,
		categoryId?: number | null,
		PageNumber?: number,
		PageSize?: number
	) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/groups`, {
			params: {
				searchString,
				categoryId,
				PageNumber,
				PageSize,
			},
		})
	},
	create: async (shopId: string, data: CreateGroup) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/groups`, data)
	},
	getOne: async (shopId: string, id: number) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/groups/${id}`)
	},
	update: async (shopId: string, id: number, data: CreateGroup) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/groups/${id}`, data)
	},
	delete: async (shopId: string, id: number) => {
		return await privateAPI.delete(`/v1/dash/shops/${shopId}/groups/${id}`)
	},
}
