import React, { FC, useEffect, useState } from 'react'
import styles from '../Modals.module.scss'
import Input from '../../UI/FormComponents/Input'
import Textarea from '../../UI/FormComponents/Textarea'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { Gateways } from '../../../types/Gateways/Gateways'
import PriceInput from '../../UI/PriceInput/PriceInput'
import { VariantsPricing } from '../../../types/CreateVariants/VariantsPricing'
import Switch from '../../UI/Switch/Switch'
import Checkbox from '../../UI/Checkbox/Checkbox'
import CheckboxWrapper from '../../UI/CheckboxWrapper/CheckboxWrapper'
import { getGatewaysOptions } from '../../../helpers/getGatewaysOptions'
import { capitalizeText } from '../../../helpers/capitalizeText'

interface EditPricingGatewaySettingsModalProps extends BaseModalProps {
	currentGateway: string | null
	pricing?: {
		gateway: string
		overrodePrice: null | {
			amount: string
			currency: string
		}
	}
	setPricing: (value: {
		gateway: string
		overrodePrice: null | {
			amount: string
			currency: string
		}
	}) => void
}

const EditPricingGatewaySettingsModal: FC<EditPricingGatewaySettingsModalProps> = ({
	currentGateway,
	onClose,
	pricing,
	setPricing,
}) => {
	const [customGatewayPricingSwitch, setCustomGatewayPricingSwitch] = useState(false)
	const [customFraudRulesSwitch, setCustomFraudRulesSwitch] = useState(false)
	const [blockVPNs, setBlockVPNs] = useState(false)
	const [blockProxies, setBlockProxies] = useState(false)
	const [customPrice, setCustomPrice] = useState('')
	const [customCurrency, setCustomCurrency] = useState('USD')

	const saveSettingsClickHandler = () => {
		if (currentGateway) {
			setPricing({
				gateway: currentGateway,
				overrodePrice: customGatewayPricingSwitch
					? {
							amount: customPrice,
							currency: customCurrency,
					  }
					: null,
			})

			onClose()
		}
	}

	useEffect(() => {
		if (pricing) {
			if (pricing.overrodePrice?.amount) {
				setCustomGatewayPricingSwitch(true)
			}

			setCustomPrice(pricing?.overrodePrice?.amount.toString() || '0.00')
			setCustomCurrency(pricing?.overrodePrice?.currency || 'USD')
		}
	}, [])

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Edit {currentGateway} Settings</h1>
			</header>

			<div className={styles.body}>
				{
					<div className={styles.fieldWrapper}>
						<div className={styles.spaceBetween}>
							<p>Custom Gateway Pricing</p>
							<Switch
								checked={customGatewayPricingSwitch}
								setChecked={setCustomGatewayPricingSwitch}
							/>
						</div>
						{customGatewayPricingSwitch && (
							<div className={styles.mt10}>
								<PriceInput
									currency={customCurrency}
									setCurrency={setCustomCurrency}
									price={customPrice}
									setPrice={setCustomPrice}
								/>
								<div className={styles.helpText}>
									Offer pricing discounts if members select {currentGateway} prior to checkout.
								</div>
							</div>
						)}
					</div>
				}
				<div className={styles.fieldWrapper}>
					<div className={styles.spaceBetween}>
						<p>Custom Fraud Rules</p>
						<Switch checked={customFraudRulesSwitch} setChecked={setCustomFraudRulesSwitch} />
					</div>

					{customFraudRulesSwitch && (
						<div className={styles.mt10}>
							<CheckboxWrapper
								id={'blockVPNS'}
								checked={blockVPNs}
								setChecked={setBlockVPNs}
								title={'Block VPNs'}
								description={'Customers that attempt to checkout with a VPN will be blocked.'}
							/>
						</div>
					)}
					{customFraudRulesSwitch && (
						<div className={styles.mtMediumLarge}>
							<CheckboxWrapper
								id={'blockProxies'}
								checked={blockProxies}
								setChecked={setBlockProxies}
								title={'Block Proxies'}
								description={'Customers that attempt to checkout with a proxy will be blocked.'}
							/>
						</div>
					)}
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						disableShadow={true}
						icon={{
							id: 'download',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						onClick={saveSettingsClickHandler}
					>
						Save Settings
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default EditPricingGatewaySettingsModal
