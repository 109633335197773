import React, { FC } from 'react'
import styles from './SettingsMigratePage.module.scss'
import Input from '../../../../../components/UI/FormComponents/Input'
import { FormErrors } from '../../../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../../../helpers/fieldValidators'

interface MigrateApiKeyStepProps {
	apiKey: string
	setApiKey: (value: string) => void
	errors: FormErrors
	setErrors: (value: FormErrors) => void
}

const MigrateApiKeyStep: FC<MigrateApiKeyStepProps> = ({
	setApiKey,
	apiKey,
	setErrors,
	errors,
}) => {
	return (
		<div className={styles.mtMediumLarge}>
			<p className={styles.defaultText}>Sellpass Automated Migration</p>
			<div className={styles.mt10}>
				<Input
					value={apiKey}
					setValue={setApiKey}
					placeholder={'Sellpass API Key'}
					errorMessage={errors['apiKey']}
					onBlur={() =>
						setErrors({
							...errors,
							apiKey: fieldValidators.blankValidator('Sellpass API Key', apiKey),
						})
					}
				/>
			</div>
			<p className={`${styles.secondaryText} ${styles.mt10}`}>
				We will remove the subscription from your sellpass account and add it to this account.
			</p>
		</div>
	)
}

export default MigrateApiKeyStep
