import React, { FC, useEffect, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { useAPI } from '../../../hooks/useAPI'
import Input from '../../UI/FormComponents/Input'
import { useAppContext } from '../../../hooks/useAppContext'
import { fieldValidators } from '../../../helpers/fieldValidators'
import AlertBadge from '../../UI/AlertBadge/AlertBadge'
import { Alert } from '../../../types/Alert/Alert'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { DiscordBotsService } from '../../../API/DiscordBotsService'
import { DiscordClusterBotStatus } from '../../../types/DiscordClusterBotStatus/DiscordClusterBotStatus'
import { GetDiscordCluster } from '../../../types/GetDiscordCluster/GetDiscordCluster'
import CustomSelect from '../../UI/CustomSelect/CustomSelect'

interface EditDiscordBotModalProps extends BaseModalProps {
	onSuccess?: () => void
	botType: 'secondary' | 'primary'
	discordCluster: GetDiscordCluster
}

const EditDiscordBotModal: FC<EditDiscordBotModalProps> = ({
	onClose,
	onSuccess,
	discordCluster,
	botType,
}) => {
	const { shop } = useAppContext()
	const [botToken, setBotToken] = useState('')
	const [clientId, setClientId] = useState('')
	const [clientSecret, setClientSecret] = useState('')
	const [formErrors, setFormErrors] = useState<FormErrors>({})
	const [botStatus, setBotStatus] = useState<DiscordClusterBotStatus>(
		DiscordClusterBotStatus.ONLINE
	)
	const [botActivityText, setBotActivityText] = useState('')

	const [isUpdateDiscordBotLoading, , updateDiscordBot] = useAPI(
		() => {
			const botData = {
				botToken,
				clientId,
				clientSecret,
				botStatus: botStatus,
				botActivityText: botActivityText,
			}

			return DiscordBotsService.put(shop?.id || '', {
				type: discordCluster.type,
				primaryBot:
					botType === 'primary'
						? botData
						: {
								botToken: discordCluster.primaryBot.botToken,
								botStatus: discordCluster.primaryBot.botStatus,
								botActivityText: discordCluster.primaryBot.botActivityText,
								clientSecret: discordCluster.primaryBot.clientSecret,
								clientId: discordCluster.primaryBot.clientId,
						  },
				secondaryBot:
					botType === 'secondary'
						? botData
						: discordCluster.secondaryBot && {
								botToken: discordCluster.secondaryBot.botToken,
								botStatus: discordCluster.secondaryBot.botStatus,
								botActivityText: discordCluster.secondaryBot.botActivityText,
								clientSecret: discordCluster.secondaryBot.clientSecret,
								clientId: discordCluster.secondaryBot.clientId,
						  },
			})
		},
		false,
		'Discord Bot successfully updated',
		{
			onSuccess: () => {
				onClose()
				onSuccess && onSuccess()
			},
		}
	)

	useEffect(() => {
		const botData = botType === 'primary' ? discordCluster.primaryBot : discordCluster.secondaryBot
		setBotToken(botData?.botToken || '')
		setClientId(botData?.clientId || '')
		setClientSecret(botData?.clientSecret || '')
		setBotActivityText(botData?.botActivityText || '')
		setBotStatus(botData?.botStatus || DiscordClusterBotStatus.OFFLINE)
	}, [])

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Edit {botType === 'primary' ? 'Primary' : 'Secondary'} Bot</h1>
			</header>

			<div className={styles.body}>
				<div className={styles.mtMediumLarge}>
					<p className={styles.defaultTitle}>Client ID</p>
					<div className={styles.mt10}>
						<Input
							value={clientId}
							setValue={setClientId}
							placeholder={'000000000000000000000000'}
							errorMessage={formErrors['clientId']}
							disabled={true}
							onBlur={() =>
								setFormErrors({
									...formErrors,
									clientId: fieldValidators.blankValidator('Client Id', clientId),
								})
							}
						/>
					</div>
					<p className={`${styles.secondaryText} ${styles.mt10}`}>
						Copy and paste the application ID of your bot from this page:
						<a
							className={styles.link}
							href='https://discord.com/developers/applications'
							target={'_blank'}
						>
							https://discord.com/developers/applications
						</a>
						.
					</p>
				</div>
				<div className={styles.mtMediumLarge}>
					<AlertBadge alert={Alert.DANGER} disableShadow={true}>
						Important: When setting up your Discord bot, be sure to enable all required intents such
						as presence, server members, and message content intent toggles.
					</AlertBadge>
				</div>
				<div className={`${styles.doubleFields} ${styles.mtMediumLarge}`}>
					<div>
						<p className={styles.defaultTitle}>Client Secret</p>
						<div className={styles.mt10}>
							<Input
								value={clientSecret}
								setValue={setClientSecret}
								placeholder={'000000000000000000000000'}
								autoComplete={'off'}
								errorMessage={formErrors['clientSecret']}
								onBlur={() =>
									setFormErrors({
										...formErrors,
										clientSecret: fieldValidators.blankValidator('Client Secret', clientSecret),
									})
								}
							/>
						</div>
						<p className={`${styles.secondaryText} ${styles.mt10}`}>
							Enter your client secret then copy the newly generated secret and paste it in the
							field above.
						</p>
					</div>
					<div>
						<p className={styles.defaultTitle}>Bot Token</p>
						<div className={styles.mt10}>
							<Input
								type={'password'}
								value={botToken}
								setValue={setBotToken}
								placeholder={'**************************************'}
								autoComplete={'off'}
								errorMessage={formErrors['botToken']}
								onBlur={() =>
									setFormErrors({
										...formErrors,
										botToken: fieldValidators.blankValidator('Bot Token', botToken),
									})
								}
							/>
						</div>
						<p className={`${styles.secondaryText} ${styles.mt10}`}>
							Enter your bot token then copy the newly generated token and paste it in the field
							above.
						</p>
					</div>
				</div>

				<div className={`${styles.doubleFields} ${styles.mtMediumLarge}`}>
					<div className={styles.fieldWrapper}>
						<p className={styles.defaultTitle}>Bot Status</p>
						<div className={styles.mt10}>
							<CustomSelect
								value={botStatus}
								setValue={value => setBotStatus(value as DiscordClusterBotStatus)}
								options={[
									{
										label: 'Online',
										value: DiscordClusterBotStatus.ONLINE,
									},
									{
										label: 'Offline',
										value: DiscordClusterBotStatus.OFFLINE,
									},
									{
										label: 'Away',
										value: DiscordClusterBotStatus.AWAY,
									},
									{
										label: 'Do Not Disturb',
										value: DiscordClusterBotStatus.DO_NOT_DISTURB,
									},
								]}
							/>
						</div>
					</div>
					<div className={styles.fieldWrapper}>
						<p className={styles.defaultTitle}>Bot Activity Text</p>
						<div className={styles.mt10}>
							<Input
								value={botActivityText}
								setValue={setBotActivityText}
								placeholder={'Powered by Billgang.com 💸'}
							/>
						</div>
					</div>
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: 'pen',
							height: 14,
							width: 12,
							align: ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={updateDiscordBot}
						isLoading={isUpdateDiscordBotLoading}
					>
						Edit {botType === 'primary' ? 'Primary' : 'Secondary'} Bot
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default EditDiscordBotModal
