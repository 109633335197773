export const StatusMap = {
	LIVE: {
		name: 'live',
		title: 'Live',
		mainTitle: '',
		subtitle: '',
		icon: 'checkedCircle',
		path: '',
		isExternalLink: false,
		btnText: ''
	},
	LOCKED: {
		name: 'locked',
		title: 'Locked',
		mainTitle: 'Not available',
		subtitle: 'The subscription has not been paid',
		icon: 'lock2',
		path: '/settings/billing',
		isExternalLink: false,
		btnText: 'Pay subscription'
	},
	ACTION_REQUIRED: {
		name: 'actionRequired',
		title: 'Action required',
		mainTitle: 'Action required',
		subtitle: '',
		icon: 'warning',
		path: 'https://support.billgang.com/en/',
		isExternalLink: true,
		btnText: 'Contact us'
	},
	BANNED: {
		name: 'banned',
		title: 'Banned',
		mainTitle: 'The store has been banned',
		subtitle: '',
		icon: 'circleX',
		path: 'https://support.billgang.com/en/',
		isExternalLink: true,
		btnText: 'Contact us'
	}
}