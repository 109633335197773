import React, { FC } from 'react'
import styles from './StockStatusBadge.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

interface StockStatusBadgeProps {
	isAvailable: boolean
}

const StockStatusBadge: FC<StockStatusBadgeProps> = ({ isAvailable }) => {
	return (
		<div className={`${styles.statusBadge} ${isAvailable ? styles.success : styles.fail}`}>
			{isAvailable ? 'Available' : 'Sold'}
			<SpriteIcon iconId={isAvailable ? 'checkedCircle' : 'circleX'} width={16} height={16} />
		</div>
	)
}

export default StockStatusBadge
