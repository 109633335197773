import React, { FC } from 'react'
import modalStyles from '../Modals.module.scss'
import Input from '../../UI/FormComponents/Input'
import RewardTypeForm from './RewardTypeForm'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'
import RequiredBadge from '../../UI/RequiredBadge/RequiredBadge'

interface OrdersRuleFormProps {
	ordersCount: number
	setOrdersCount: (value: number) => void
	errors: FormErrors
	setErrors: (value: FormErrors) => void
}

const OrdersRuleForm: FC<OrdersRuleFormProps> = ({
	setOrdersCount,
	ordersCount,
	setErrors,
	errors,
}) => {
	return (
		<div className={modalStyles.mtMediumLarge}>
			<div className={modalStyles.fieldWrapper}>
				<h2>
					Order Limit <RequiredBadge />
				</h2>
				<Input
					value={ordersCount}
					setValue={setOrdersCount}
					type={'number'}
					placeholder={'0'}
					errorMessage={errors['ordersCount']}
					onBlur={() =>
						setErrors({
							...errors,
							ordersCount: fieldValidators.blankValidator('Order limit', ordersCount),
						})
					}
				/>
				<p className={modalStyles.fieldDescription}>
					Number of orders customers have before unlocking this reward.
				</p>
			</div>
		</div>
	)
}

export default OrdersRuleForm
