import React, { FC, useRef, useState } from 'react'
import styles from './Tables.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { NavLink, useNavigate } from 'react-router-dom'
import Checkbox from '../UI/Checkbox/Checkbox'
import ProductTypeBadge from '../UI/ProductTypeBadge/ProductTypeBadge'
import { Coupon } from '../../types/Coupon/Coupon'
import OrangeBadge from '../UI/OrangeBadge/OrangeBadge'
import { format } from 'date-fns'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import CouponTypeBadge from '../UI/CouponTypeBadge/CouponTypeBadge'
import { ActionMenuOption } from '../../types/ActionMenuOption/ActionMenuOption'

interface DiscordServersTableProps {}

interface DiscordServersTableBodyItemProps {}

const DiscordServersTableBodyItem: FC<DiscordServersTableBodyItemProps> = ({}) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)

	const actionMenuButtons: ActionMenuOption[] = []

	return (
		<div
			className={styles.trWrapper}
			style={{
				paddingBottom: isActionMenuActive ? +actionMenuButtons.length * 50 : 5,
			}}
		>
			<tr>
				<td className={styles.large}>
					<div className={styles.itemInfo}>
						<div className={styles.itemImage}>BL</div>
						<div>
							<p>{'Billgang.com'}</p>
						</div>
					</div>
				</td>
				<td>421</td>
				<td>65,215</td>
				<td>
					<p>{format(new Date(), 'MMM dd, yyyy')}</p>
					<span>{format(new Date(), 'hh:mm aa z')}</span>
				</td>
				<td className={styles.small}>
					<ActionMenu
						actionButtons={actionMenuButtons}
						isActive={isActionMenuActive}
						setIsActive={setIsActionMenuActive}
					/>
				</td>
			</tr>
		</div>
	)
}

const DiscordServersTable: FC<DiscordServersTableProps> = ({}) => {
	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.large}>SERVER DETAILS</th>
					<th>MEMBERS</th>
					<th>COMMANDS USED</th>
					<th>Last Updated</th>
					<th className={styles.small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				<DiscordServersTableBodyItem />
				<DiscordServersTableBodyItem />
			</tbody>
		</table>
	)
}

export default DiscordServersTable
