import React from 'react'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import styles from '../Settings.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../components/UI/Button/Button'
import Input from '../../../components/UI/FormComponents/Input'
import NotificationsTable from '../../../components/Tables/NotificationsTable'
import Checkbox from '../../../components/UI/Checkbox/Checkbox'
import DevicesTable from '../../../components/Tables/DevicesTable'

const SettingsDevicesPage = () => {
	return (
		<PageWrapper>
			<div className={styles.settingsPageInnerBody}>
				<div className={`${styles.settingSection} ${styles.table}`}>
					<header className={styles.settingSectionHeader}>
						<p className={styles.settingSectionTitle}>Device history</p>
						<p className={styles.settingSectionDescription}>
							We will always notify you of new login locations and suspicious activity with your
							account. If you don’t recognize a login device or location, we highly recommend
							signing out of all devices and resetting your password.
						</p>
					</header>
					<div className={styles.formWrapper}>
						<DevicesTable />
					</div>
					<footer className={styles.settingSectionFooter}>
						<p className={styles.orangeText}>
							In some cases, we may temporarily pause orders if we believe your account was taken
							over by bad actors.
						</p>
						<Button style={ButtonStyles.PRIMARY}>Log Out of All Known Devices</Button>
					</footer>
				</div>
			</div>
		</PageWrapper>
	)
}

export default SettingsDevicesPage
