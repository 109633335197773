import React, { FC, useEffect, useState } from 'react'
import styles from './TrafficAcquisition.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import FeedbackActionMenu from '../FeedbackActionMenu/FeedbackActionMenu'
import { DatePeriod } from '../../types/DatePeriod/DatePeriod'
import UpdatedOnDateBadge from '../UI/UpdatedOnDateBadge/UpdatedOnDateBadge'
import Tabs from '../UI/Tabs/Tabs'
import { GetAnalyticsVisit } from '../../types/GetAnalyticsVisit/GetAnalyticsVisit'
import { parseUserAgent } from '../../helpers/parseUserAgent'
import { countriesObject } from '../../helpers/countriesObject'

enum TrafficTabs {
	location,
	device,
	browser,
}

interface TrafficAcquisitionProps {
	datePeriod: DatePeriod
	data: GetAnalyticsVisit[]
}

const TrafficAcquisition: FC<TrafficAcquisitionProps> = ({ datePeriod, data }) => {
	const [currentTrafficTab, setCurrentTrafficTab] = useState<TrafficTabs>(TrafficTabs.location)
	const [updatedDate, setUpdatedDate] = useState(new Date())

	const countryCounts: {
		[countryCode: string]: number
	} = {}
	const browserCounts: {
		[browserName: string]: number
	} = {}
	const deviceCounts: {
		[deviceName: string]: number
	} = {}

	data.forEach(item => {
		const parsedUserAgent = parseUserAgent(item.useragent)

		if (countryCounts[item.ipDetailsCountry]) {
			countryCounts[item.ipDetailsCountry]++
		} else {
			countryCounts[item.ipDetailsCountry] = 1
		}

		if (browserCounts[parsedUserAgent.browser]) {
			browserCounts[parsedUserAgent.browser]++
		} else {
			browserCounts[parsedUserAgent.browser] = 1
		}

		if (deviceCounts[parsedUserAgent.device]) {
			deviceCounts[parsedUserAgent.device]++
		} else {
			deviceCounts[parsedUserAgent.device] = 1
		}
	})

	const getDatePeriodInString = () => {
		switch (datePeriod) {
			case DatePeriod.allTime:
				return 'All time'
			case DatePeriod.lastDay:
				return 'Last 24 hours'
			case DatePeriod.lastMonth:
				return 'Last 30 days'
			case DatePeriod.lastWeek:
				return 'Last 7 days'
			case DatePeriod.lastYear:
				return 'Last 12 months'
		}
	}

	useEffect(() => {
		setUpdatedDate(new Date())
	}, [data])

	return (
		<div className={styles.trafficAcquisition}>
			<header className={styles.header}>
				<h1>Website Traffic</h1>
				<FeedbackActionMenu />
			</header>

			<header className={styles.subHeader}>
				<div>
					<p className={styles.numbers}>{data.length}</p>
					<time className={styles.time}>{getDatePeriodInString()}</time>
				</div>

				<UpdatedOnDateBadge date={updatedDate} />
			</header>

			<div className={styles.tabsWrapper}>
				<Tabs
					tabs={[
						{
							label: 'Location',
							value: TrafficTabs.location,
						},
						{
							label: 'Device',
							value: TrafficTabs.device,
						},
						{
							label: 'Browser',
							value: TrafficTabs.browser,
						},
					]}
					currentTab={currentTrafficTab}
					setCurrentTab={setCurrentTrafficTab}
				/>
			</div>

			{currentTrafficTab === TrafficTabs.location && (
				<div className={styles.statistics}>
					{Object.keys(countryCounts)
						.sort((a, b) => countryCounts[b] - countryCounts[a])
						.slice(0, 4)
						.map(countryCode => (
							<div className={styles.statisticsItem} key={countryCode}>
								<div className={styles.head}>
									<img
										width={25}
										height={25}
										src={`https://flagsapi.com/${countryCode}/flat/64.png`}
										alt={countryCode}
									/>
									<p>{countriesObject[countryCode]}</p>
								</div>
								<span>
									{countryCounts[countryCode].toLocaleString()} visit
									{countryCounts[countryCode] > 1 && 's'}
								</span>
							</div>
						))}
				</div>
			)}

			{currentTrafficTab === TrafficTabs.device && (
				<div className={styles.statistics}>
					{Object.keys(deviceCounts)
						.sort((a, b) => deviceCounts[b] - deviceCounts[a])
						.slice(0, 4)
						.map(deviceName => (
							<div className={styles.statisticsItem} key={deviceName}>
								<p>{deviceName}</p>
								<span>
									{deviceCounts[deviceName].toLocaleString()} visit
									{deviceCounts[deviceName] > 1 && 's'}
								</span>
							</div>
						))}
				</div>
			)}

			{currentTrafficTab === TrafficTabs.browser && (
				<div className={styles.statistics}>
					{Object.keys(browserCounts)
						.sort((a, b) => browserCounts[b] - browserCounts[a])
						.slice(0, 4)
						.map(browserName => (
							<div className={styles.statisticsItem} key={browserName}>
								<p>{browserName}</p>
								<span>
									{browserCounts[browserName].toLocaleString()} visit
									{browserCounts[browserName] > 1 && 's'}
								</span>
							</div>
						))}
				</div>
			)}
		</div>
	)
}

export default TrafficAcquisition
