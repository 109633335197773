import { privateAPI } from './API'

export const DomainsService = {
	getAll: async (shopId: string) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/custom-domains`)
	},
	add: async (shopId: string, data: { hostname: string }) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/custom-domains`, data)
	},
	updateShortened: async (shopId: string) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/custom-domains/shortened`)
	},
	delete: async (shopId: string, domainId: number) => {
		return await privateAPI.delete(`/v1/dash/shops/${shopId}/custom-domains/${domainId}`)
	},
	recheck: async (shopId: string, domainId: number) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/custom-domains/${domainId}/recheck`)
	},
}
