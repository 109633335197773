import React, { FC } from 'react'
import styles from './FormComponents.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage'

interface TextareaProps {
	value: string
	setValue: (value: string) => void
	placeholder?: string
	required?: boolean
	errorMessage?: string
	height?: number
	maxLength?: number
	resizable?: boolean
	onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void
	id?: string
}

const Textarea: FC<TextareaProps> = ({
	value,
	setValue,
	placeholder,
	required,
	errorMessage,
	height,
	resizable = true,
	maxLength,
	onBlur,
	id,
}) => {
	return (
		<div>
			<textarea
				id={id}
				className={`${styles.textarea} ${errorMessage ? styles.error : ''} ${
					resizable && styles.resizable
				}`}
				value={value}
				onChange={event => setValue(event.target.value)}
				placeholder={placeholder}
				required={required}
				style={{ height }}
				maxLength={maxLength}
				onBlur={onBlur}
			/>
			{errorMessage && <FieldErrorMessage errorMessage={errorMessage} />}
		</div>
	)
}

export default Textarea
