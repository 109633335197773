import React, { FC, useRef, useState } from 'react'
import styles from './Tables.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { NavLink, useNavigate } from 'react-router-dom'
import StatusBadge from '../UI/StatusBadge/StatusBadge'
import PaymentTypeBadge from '../PaymentTypeBadge/PaymentTypeBadge'
import RatingBadge from '../UI/RatingBadge/RatingBadge'
import { Review } from '../../types/Review/Review'
import { format } from 'date-fns'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import { APIKey } from '../../types/APIKey/APIKey'
import { useCopy } from '../../hooks/useCopy'

interface ApiKeysTableProps {
	apiKeys: APIKey[]
	onResetClick: (id: number) => void
	onDeleteClick: (id: number) => void
}

interface ApiKeysTableBodyItemProps {
	apiKey: APIKey
	onResetClick: (id: number) => void
	onDeleteClick: (id: number) => void
}

const ApiKeysTableBodyItem: FC<ApiKeysTableBodyItemProps> = ({
	apiKey,
	onResetClick,
	onDeleteClick,
}) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)
	const tableBodyRef = useRef<HTMLDivElement>(null)
	const copy = useCopy()

	const convertToSecret = (secretKey: string) => {
		const slice = secretKey.slice(5, secretKey.length - 4)
		return secretKey.replace(slice, ' ••••••••••••••••••• ')
	}

	const actionMenuButtons = [
		{
			onClick: () => onResetClick(apiKey.id),
			icon: {
				id: 'refresh',
				width: 14,
				height: 14,
			},
			title: 'Reset Key',
		},
		{
			onClick: () => onDeleteClick(apiKey.id),
			icon: {
				id: 'trashBin',
				width: 12,
				height: 13,
			},
			title: 'Delete Key',
		},
	]

	return (
		<div
			className={styles.trWrapper}
			style={{
				paddingBottom: isActionMenuActive ? +actionMenuButtons.length * 50 : 5,
			}}
			ref={tableBodyRef}
		>
			<tr>
				<td className={styles.extraLarge}>
					<div className={`${styles.row} ${styles.gapMediumLarge}`}>
						<p>{convertToSecret(apiKey.accessToken)}</p>
						<SpriteIcon
							className={styles.cursorPointer}
							iconId={'copy'}
							width={14}
							height={14}
							onClick={() => copy(apiKey.accessToken)}
						/>
					</div>
				</td>
				<td>{apiKey.name}</td>
				<td>
					{apiKey.expiresAt ? (
						<>
							<p>{format(new Date(apiKey.expiresAt), 'MMM dd, yyyy')}</p>
							<span>{format(new Date(apiKey.expiresAt), 'hh:mm aa z')}</span>
						</>
					) : (
						<p>-</p>
					)}
				</td>
				<td className={styles.small}>
					<ActionMenu
						actionButtons={actionMenuButtons}
						isActive={isActionMenuActive}
						setIsActive={setIsActionMenuActive}
					/>
				</td>
			</tr>
		</div>
	)
}

const ApiKeysTable: FC<ApiKeysTableProps> = ({ apiKeys, onResetClick, onDeleteClick }) => {
	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.extraLarge}>KEY</th>
					<th>NAME</th>
					<th>Date & Time</th>
					<th className={styles.small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{apiKeys.map(apiKey => (
					<ApiKeysTableBodyItem
						apiKey={apiKey}
						onDeleteClick={onDeleteClick}
						onResetClick={onResetClick}
						key={apiKey.id}
					/>
				))}
			</tbody>
		</table>
	)
}

export default ApiKeysTable
