import React, { useEffect, useRef, useState } from 'react'
import styles from './DashboardWelcome.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import Button, { ButtonsIconAlign } from '../UI/Button/Button'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../../hooks/useAppContext'
import Modal from '../Modals/Modal'
import { closeModal } from '../../helpers/closeModal'
import ConfirmModal from '../Modals/ConfirmModal/ConfirmModal'

const DashboardWelcome = () => {
	const navigate = useNavigate()
	const dashboardWelcomeRef = useRef<HTMLDivElement>(null)
	const [isActive, setIsActive] = useState(true)
	const [isDisableWelcomeModalActive, setIsDisableWelcomeModalActive] = useState(false)
	const [isDisableWelcomeModalVisible, setIsDisableWelcomeModalVisible] = useState(false)
	const { builderLink } = useAppContext()

	const onCloseClick = (event: React.MouseEvent) => {
		event.stopPropagation()
		setIsDisableWelcomeModalActive(true)
	}

	const closeDisableWelcomeModal = () => {
		closeModal(setIsDisableWelcomeModalActive, setIsDisableWelcomeModalVisible)
	}

	const closeDashboardWelcome = () => {
		localStorage.setItem('isDashboardWelcomeDisabled', 'true')
		closeDisableWelcomeModal()
	}

	useEffect(() => {
		if (isActive) {
			dashboardWelcomeRef.current?.classList.add(styles.active)
			setTimeout(() => {
				dashboardWelcomeRef.current?.classList.add(styles.visible)
			}, 10)
		} else {
			dashboardWelcomeRef.current?.classList.remove(styles.visible)

			setTimeout(() => {
				dashboardWelcomeRef.current?.classList.remove(styles.active)
			}, 300)
		}
	}, [isActive])

	if (localStorage.getItem('isDashboardWelcomeDisabled')) {
		return null
	}

	return (
		<>
			{isDisableWelcomeModalActive && (
				<Modal
					closeModal={closeDisableWelcomeModal}
					visible={isDisableWelcomeModalVisible}
					setVisible={setIsDisableWelcomeModalVisible}
				>
					<ConfirmModal
						title={'Close Onboarding Checklist'}
						description={
							'Tap on the confirmation button below to close this onboarding checklist forever.'
						}
						onConfirm={closeDashboardWelcome}
						confirmButtonText={'Close Onboarding'}
						onClose={closeDisableWelcomeModal}
						buttonIcon={{
							id: 'close',
							align: ButtonsIconAlign.LEFT,
							width: 13,
							height: 13,
						}}
					/>
				</Modal>
			)}
			<div className={styles.dashboardWelcome} ref={dashboardWelcomeRef}>
				<div className={styles.dashboardWelcomeBackground} />
				<header className={styles.header} onClick={() => setIsActive(!isActive)}>
					<div>
						<h1>Welcome to Billgang 👋</h1>
						<p>
							You’re on your way to building your digital empire. Use this personalized guide to get
							your store up and running.
						</p>
					</div>
					<div className={styles.headerEnd}>
						<div className={styles.iconWrapper}>
							<SpriteIcon iconId={'arrowDown'} width={10} height={4} />
						</div>
						<div className={styles.iconWrapper} onClick={onCloseClick}>
							<SpriteIcon iconId={'close'} width={10} height={10} />
						</div>
					</div>
				</header>
				<div className={styles.body}>
					{/*<div className={styles.announce}>*/}
					{/*	<SpriteIcon iconId={'stars'} width={14.5} height={14.5} />*/}
					{/*	<p>*/}
					{/*		Complete this checklist to automatically unlock a 14-day trial of our Business plan.*/}
					{/*	</p>*/}
					{/*</div>*/}
					<div className={styles.checklist}>
						<div className={styles.checklistItem}>
							<header className={styles.checklistItemHeader}>
								<h2>📦 Create A Product</h2>
							</header>
							<div className={styles.checklistItemInfo}>
								<p>
									Whether it’s access to your community, a digital download, or a subscription
									license key, it can be sold with Billgang Commerce.
								</p>
							</div>
							<Button
								onClick={() => navigate('/products')}
								icon={{
									id: 'arrowRight',
									width: 11,
									height: 14,
									align: ButtonsIconAlign.RIGHT,
								}}
								disableShadow={true}
								width={'fit-content'}
							>
								Add products
							</Button>
						</div>
						<div className={styles.checklistItem}>
							<header className={styles.checklistItemHeader}>
								<h2>🎨 Customize Your Store</h2>
							</header>
							<div className={styles.checklistItemInfo}>
								<p>
									A strong identity is important for your brand. Set up your business storefront
									with our powerful drag-and-drop builder.
								</p>
							</div>
							<Button
								icon={{
									id: 'blankWindow',
									width: 12,
									height: 12,
									align: ButtonsIconAlign.RIGHT,
								}}
								width={'fit-content'}
								onClick={() => window.open(builderLink, '_blank', 'noopener')}
								disableShadow={true}
							>
								Customize store theme
							</Button>
						</div>
						<div className={styles.checklistItem}>
							<header className={styles.checklistItemHeader}>
								<h2>⭐ Setup Affiliate Rewards</h2>
							</header>
							<div className={styles.checklistItemInfo}>
								<p>
									Automatically reward customers with products or on-site balance for completing
									certain tasks with Billgang Contacts.
								</p>
							</div>
							<Button
								icon={{
									id: 'arrowRight',
									width: 11,
									height: 14,
									align: ButtonsIconAlign.RIGHT,
								}}
								width={'fit-content'}
								onClick={() => navigate('/marketing/affiliates/rewards')}
								disableShadow={true}
							>
								View rewards
							</Button>
						</div>
						<div className={styles.checklistItem}>
							<header className={styles.checklistItemHeader}>
								<h2>⚙️ View Business Settings</h2>
							</header>
							<div className={styles.checklistItemInfo}>
								<p>
									Customize your storefront display name, add your social links, set a custom
									domain, and manage account billing.
								</p>
							</div>
							<Button
								icon={{
									id: 'arrowRight',
									width: 11,
									height: 14,
									align: ButtonsIconAlign.RIGHT,
								}}
								width={'fit-content'}
								onClick={() => navigate('/settings/general')}
								disableShadow={true}
							>
								Go to settings
							</Button>
						</div>
						<div className={styles.checklistItem}>
							<header className={styles.checklistItemHeader}>
								<h2>💰 Start Getting Paid</h2>
							</header>
							<div className={styles.checklistItemInfo}>
								<p>
									Connect over 30+ different payment methods to begin getting paid for the products
									you sell with Billgang Payments.
								</p>
							</div>
							<Button
								icon={{
									id: 'arrowRight',
									width: 11,
									height: 14,
									align: ButtonsIconAlign.RIGHT,
								}}
								width={'fit-content'}
								onClick={() => navigate('/settings/payments')}
								disableShadow={true}
							>
								Enable payments
							</Button>
						</div>
						<div className={styles.checklistItem}>
							<header className={styles.checklistItemHeader}>
								<h2>👥 Invite Your Team</h2>
							</header>
							<div className={styles.checklistItemInfo}>
								<p>
									Send invites to other administrators, support members, finance managers, or set
									custom roles.
								</p>
							</div>
							<Button
								icon={{
									id: 'arrowRight',
									width: 11,
									height: 14,
									align: ButtonsIconAlign.RIGHT,
								}}
								width={'fit-content'}
								onClick={() => navigate('/settings/team')}
								disableShadow={true}
							>
								Invite a team member
							</Button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default DashboardWelcome
