import React, { FC, useEffect, useState } from 'react'
import styles from '../../CustomerDetailedPage.module.scss'
import OrdersTable from '../../../../components/Tables/OrdersTable'
import Pagination from '../../../../components/UI/Pagination/Pagination'
import { DetailedCustomer } from '../../../../types/DetailedCustomer/DetailedCustomer'
import { useAPI } from '../../../../hooks/useAPI'
import { OrdersService } from '../../../../API/OrdersService'
import { useAppContext } from '../../../../hooks/useAppContext'
import { Order } from '../../../../types/Order/Order'
import Spinner from '../../../../components/UI/Spinner/Spinner'

interface CustomerTransactionHistoryProps {
	customer: DetailedCustomer
	setLastOrder: (order: Order) => void
}

const CustomerTransactionHistory: FC<CustomerTransactionHistoryProps> = ({
	customer,
	setLastOrder,
}) => {
	const [totalItems, setTotalItems] = useState(0)
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(5)
	const { shop } = useAppContext()

	const [isGetOrdersLoading, orders, getOrders] = useAPI<Order[]>(
		() => OrdersService.getAll(shop?.id || '', null, customer.email, currentPage + 1, pageSize),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
				setLastOrder(response.data.data[0])
			},
		}
	)

	useEffect(() => {
		if (shop) {
			getOrders()
		}
	}, [shop, currentPage])

	return (
		<div>
			<div className={styles.section}>
				<header className={styles.header}>
					<h1 className={styles.sectionTitle}>Transaction History</h1>
				</header>
				{isGetOrdersLoading && <Spinner />}
				{orders && <OrdersTable orders={orders} />}
			</div>

			<div className={styles.mtMediumLarge}>
				<Pagination
					withoutPageSizeSelector={true}
					totalItems={totalItems}
					pageSize={pageSize}
					setPageSize={setPageSize}
					setCurrentPage={setCurrentPage}
					currentPage={currentPage}
				/>
			</div>
		</div>
	)
}

export default CustomerTransactionHistory
