import React, { FC, useEffect, useState } from 'react'
import modalStyles from '../Modals.module.scss'
import styles from './SetupGoogleTFAModal.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import InputMask from '../../UI/FormComponents/InputMask'
import { useAPI } from '../../../hooks/useAPI'
import { UserSecurityService } from '../../../API/UserSecurityService'
import Spinner from '../../UI/Spinner/Spinner'

interface SetupGoogleTFAModalProps extends BaseModalProps {
	isEnabled: boolean
	isRequired?: boolean
	onSuccess?: () => void
}

const SetupGoogleTFAModal: FC<SetupGoogleTFAModalProps> = ({
	onClose,
	isEnabled,
	isRequired,
	onSuccess,
}) => {
	const [step, setStep] = useState(0)
	const [code, setCode] = useState('')

	const [isTFAStartLoading, TFAData, TFAStart] = useAPI<{
		key: string
		entryCode: string
		qrImage: string
	}>(() => UserSecurityService.googleTFAStart(), false)

	const [isTFACompleteLoading, , TFAComplete] = useAPI<{
		key: string
		entryCode: string
		qrImage: string
	}>(
		() =>
			UserSecurityService.googleTFAComplete({
				key: TFAData?.key || '',
				code: code.replace('-', ''),
			}),
		false,
		'Successfully enabled 2FA for the account',
		{
			onSuccess: () => {
				onClose()
				onSuccess && onSuccess()
			},
		}
	)

	const [isTFADisableLoading, , TFADisable] = useAPI(
		() =>
			UserSecurityService.googleTFADisable({
				code: code.replace('-', ''),
			}),
		false,
		'Successfully disabled 2FA for the account',
		{
			onSuccess: () => {
				onClose()
				onSuccess && onSuccess()
			},
		}
	)

	const continueButtonHandler = () => {
		if (step === 0) {
			setStep(1)
		} else if (isEnabled) {
			TFADisable()
		} else {
			TFAComplete()
		}
	}

	useEffect(() => {
		if (isEnabled) {
			setStep(1)
		} else {
			TFAStart()
		}
	}, [])

	if (isTFAStartLoading) return <Spinner />

	return (
		<div className={modalStyles.modalInner}>
			<header className={modalStyles.header}>
				<h1>{isEnabled ? 'Disable' : 'Set Up'} Google Authenticator</h1>
			</header>

			{step === 0 ? (
				<div className={modalStyles.body}>
					<p className={modalStyles.description}>
						Scan the QR code below with the Google Authenticator app. You can disable two factor
						authentication with this code at any point in the future.
					</p>
					<div className={styles.qr}>
						<img src={TFAData?.qrImage} alt='qr' />
					</div>
				</div>
			) : (
				<div className={modalStyles.body}>
					<p className={modalStyles.description}>
						Enter the 6-digit code from your Google Authenticator app{' '}
						{isEnabled ? 'to disable 2FA' : ''}.
					</p>
					<div className={modalStyles.fieldWrapper}>
						<h2>CODE</h2>
						<InputMask mask={'999-999'} value={code} setValue={setCode} placeholder={'000-000'} />
					</div>
				</div>
			)}

			<footer className={modalStyles.footer}>
				<div className={modalStyles.buttons}>
					{!isRequired && (
						<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
							Cancel
						</Button>
					)}

					<Button
						style={ButtonStyles.BORDERED}
						icon={{
							id: step === 0 ? 'arrowRight' : 'download',
							width: 13,
							height: 13,
							align: step === 0 ? ButtonsIconAlign.RIGHT : ButtonsIconAlign.LEFT,
						}}
						disableShadow={true}
						onClick={continueButtonHandler}
						isLoading={isTFACompleteLoading || isTFADisableLoading}
					>
						{step === 0 ? 'Continue' : 'Save Settings'}
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default SetupGoogleTFAModal
