import { privateAPI } from './API'
import { CreateCoupon } from '../types/CreateCoupon/CreateCoupon'

export const CouponsService = {
	getAll: async (shopId: string, searchString?: string, PageNumber?: number, PageSize?: number) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/coupons`, {
			params: {
				searchString,
				PageNumber,
				PageSize,
			},
		})
	},
	create: async (shopId: string, data: CreateCoupon) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/coupons`, data)
	},
	getOne: async (shopId: string, id: number) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/coupons/${id}`)
	},
	update: async (shopId: string, id: number, data: CreateCoupon) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/coupons/${id}`, data)
	},
	delete: async (shopId: string, id: number) => {
		return await privateAPI.delete(`/v1/dash/shops/${shopId}/coupons/${id}`)
	},
}
