import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './StatisticsItem.module.scss'
import {
	Area,
	AreaChart,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts'
import FeedbackActionMenu from '../FeedbackActionMenu/FeedbackActionMenu'
import UpdatedOnDateBadge from '../UI/UpdatedOnDateBadge/UpdatedOnDateBadge'
import { useAppContext } from '../../hooks/useAppContext'
import getSymbolFromCurrency from 'currency-symbol-map'
import { StatisticsData } from '../../types/StatisticsData/StatisticsData'
import { format } from 'date-fns'
import { getDatePeriod } from '../../helpers/getDatePeriod'
import { DatePeriod } from '../../types/DatePeriod/DatePeriod'
import { formatNumberWithDecimalsAndCommas } from '../../helpers/formatNumberWithDecimalsAndCommas'

interface StatisticsItemProps {
	title: string
	width?: string
	height?: number
	withoutGraph?: boolean
	isMoney?: boolean
	data: StatisticsData[]
	datePeriod: DatePeriod
	dateRange: {
		fromDate: Date | null
		toDate: Date | null
	}
}

const StatisticsItem: FC<StatisticsItemProps> = ({
	title,
	height,
	withoutGraph,
	isMoney,
	data,
	datePeriod,
	dateRange,
}) => {
	const totalValueRef = useRef(0)
	const [focusValue, setFocusValue] = useState<string | number>(totalValueRef.current)
	const { shop } = useAppContext()
	const [handledData, setHandledData] = useState<
		{
			count: number
			date: string
		}[]
	>([])
	const [updatedDate, setUpdatedDate] = useState(new Date())
	const dayMilliseconds = 86400000
	const dateDiff =
		dateRange.fromDate && dateRange.toDate
			? dateRange.toDate.getTime() - dateRange.fromDate.getTime()
			: 999999999999
	const maxCountLength = data.length ? Math.max(...data.map(d => d.count)).toString().length : 0

	const handleData = () => {
		const newData = []
		let dateInterval = dateRange.fromDate || new Date()
		let datePoints = 30
		let incrementTime = dayMilliseconds * 30
		let dateFormat = dateDiff > dayMilliseconds ? 'dd MMM' : 'HH:mm'
		const dateDiffDays = Math.floor(dateDiff / dayMilliseconds)

		if (dateDiffDays > 31) {
			datePoints = Math.ceil(dateDiffDays / 30)
			incrementTime = 2629746000
			dateFormat = 'dd MMM'
		}

		if (dateDiffDays > 365) {
			datePoints = Math.ceil(dateDiffDays / 365)
			incrementTime = 31536000000
			dateFormat = 'dd MMM, yyyy'
		}

		if (dateDiffDays > 1 && dateDiffDays <= 31) {
			datePoints = dateDiffDays
			incrementTime = dayMilliseconds
			dateFormat = 'dd MMM'
		}

		if (dateDiffDays === 1) {
			datePoints = 25
			incrementTime = 60 * 60 * 1000
			dateFormat = 'HH:mm'
		}

		let date = new Date(dateInterval)
		const endDate1 = new Date(dateInterval)

		for (let i = 0; i < datePoints; i++) {
			const endDate = new Date(
				endDate1.setTime(
					endDate1.getTime() +
						(i === 3 && incrementTime === 31536000000
							? incrementTime + 24 * 60 * 60 * 1000
							: incrementTime)
				)
			)
			const values = data
				.filter(item => {
					const itemDate = new Date(item.date)

					return itemDate.getTime() >= date.getTime() && itemDate.getTime() <= endDate.getTime()
				})
				.map(item => item.count)

			newData.push({
				date: format(date, dateFormat),

				count: values.reduce((a, b) => a + b, 0),
			})

			date = endDate
			setHandledData(newData)
		}
	}

	useEffect(() => {
		setUpdatedDate(new Date())
		handleData()
	}, [data])

	useEffect(() => {
		totalValueRef.current = handledData.map(data => data.count).reduce((a, b) => a + b, 0)
		setFocusValue(totalValueRef.current)
	}, [handledData])

	return (
		<div className={styles.statisticsItem}>
			<header className={styles.header}>
				<div className={styles.head}>
					<h1>{title}</h1>
				</div>
				<FeedbackActionMenu />
			</header>
			<div className={styles.horLine} />
			<header className={styles.subHeader}>
				<div>
					<p className={styles.numbers}>
						{isMoney && getSymbolFromCurrency(shop?.currency || 'USD')}
						{isMoney
							? formatNumberWithDecimalsAndCommas(Number(focusValue), 2)
							: focusValue.toLocaleString()}
					</p>
					<time className={styles.time}>
						{' '}
						{dateRange.fromDate && dateRange.toDate
							? `${format(dateRange.fromDate, 'MMMM dd, yyyy')} - ${format(
									dateRange.toDate,
									'MMMM dd, yyyy'
							  )}`
							: 'All Time'}
					</time>
				</div>

				<UpdatedOnDateBadge date={updatedDate} />
			</header>
			{!withoutGraph && (
				<div className={styles.graph}>
					<ResponsiveContainer width={'100%'} height={height || 130}>
						<AreaChart
							data={handledData}
							onMouseLeave={() => {
								setFocusValue(totalValueRef.current)
							}}
							margin={{
								top: 5,
								right: 0,
								left: maxCountLength > 1 ? maxCountLength * 5 : 0,
								bottom: 5,
							}}
						>
							{/*<CartesianGrid stroke='var(--secondary_gray)' strokeDasharray='5 5' />*/}
							<defs>
								<linearGradient
									id='count'
									x1='1%'
									y1='10%'
									x2='0%'
									y2='90%'
									gradientUnits='userSpaceOnUse'
								>
									<stop stopColor='#FF3F19' />
									<stop offset='1' stopColor='white' stopOpacity='0' />
								</linearGradient>
							</defs>
							<Area
								type='monotone'
								dataKey={'count'}
								stroke='#FF3F19'
								strokeWidth={2}
								dot={false}
								connectNulls={true}
								fill='url(#count)'
								fillOpacity={'0.12'}
							/>

							<Tooltip
								labelStyle={{
									color: 'var(--light_color)',
								}}
								contentStyle={{
									display: 'none',
									backgroundColor: 'var(--dark_color)',
									borderRadius: 10,
									borderColor: 'none',
								}}
								formatter={(value, name, props) => {
									setFocusValue(value as number)

									return value
								}}
							/>
							<XAxis dataKey={'date'} />
							<YAxis
								allowDataOverflow={false}
								type={'number'}
								dataKey={'count'}
								tick={{ fontSize: 'var(--small)', width: 100 }}
							/>
						</AreaChart>
					</ResponsiveContainer>
				</div>
			)}
		</div>
	)
}

export default StatisticsItem
