import React, { FC } from 'react'
import styles from '../SettingsMigratePage.module.scss'
import Input from '../../../../components/UI/FormComponents/Input'
import CheckboxWrapper from '../../../../components/UI/CheckboxWrapper/CheckboxWrapper'
import { MigrateOptions } from '../SettingsMigratePage'

interface AdditionalDataTransferStepProps {
	migrateOptions: MigrateOptions
	setMigrateOptions: (value: MigrateOptions) => void
}

const AdditionalDataTransferStep: FC<AdditionalDataTransferStepProps> = ({
	setMigrateOptions,
	migrateOptions,
}) => {
	return (
		<div className={styles.mtMediumLarge}>
			<p className={styles.secondaryText}>
				We’ve identified a couple more pieces of data that we think you’ll want to transfer to
				Billgang. Feel free to select the data types you want our system to pull from Sellpass.
			</p>
			<p className={`${styles.secondaryText} ${styles.mt10}`}>
				As a reminder, transferred data will not override any existing data on Billgang. Our systems
				will only create new warehouses, add historical analytics, etc.This may take an additional
				4-5 minutes to fetch and store here on Billgang.
			</p>
			<div className={`${styles.twoColumns} ${styles.mtLarge}`}>
				<CheckboxWrapper
					isReversed={true}
					id={'Groups'}
					checked={migrateOptions.migrateGroups}
					setChecked={checked =>
						setMigrateOptions({
							...migrateOptions,
							migrateGroups: checked,
						})
					}
					title={'Groups'}
					description={'Transfer all groups with all products attached.'}
				/>
				<CheckboxWrapper
					isReversed={true}
					id={'Categories'}
					checked={migrateOptions.migrateCategories}
					setChecked={checked =>
						setMigrateOptions({
							...migrateOptions,
							migrateCategories: checked,
						})
					}
					title={'Categories'}
					description={'Transfer all categories and attach products to those categories.'}
				/>
				{/*<CheckboxWrapper*/}
				{/*	isReversed={true}*/}
				{/*	id={'Customers'}*/}
				{/*	checked={migrateOptions.migrate}*/}
				{/*	setChecked={checked =>*/}
				{/*		setMigrateOptions({*/}
				{/*			...migrateOptions,*/}
				{/*			migrateCategories: checked,*/}
				{/*		})*/}
				{/*	}*/}
				{/*	title={'Customers'}*/}
				{/*	description={'Transfer 375 customer emails with individual balances and history.'}*/}
				{/*/>*/}
				<CheckboxWrapper
					isReversed={true}
					id={'Blacklist'}
					checked={migrateOptions.migrateBlacklists}
					setChecked={checked =>
						setMigrateOptions({
							...migrateOptions,
							migrateBlacklists: checked,
						})
					}
					title={'Blacklist'}
					description={'Transfer all customer blacklists and notes.'}
				/>
				<CheckboxWrapper
					isReversed={true}
					id={'Tickets'}
					checked={migrateOptions.migrateTickets}
					setChecked={checked =>
						setMigrateOptions({
							...migrateOptions,
							migrateTickets: checked,
						})
					}
					title={'Tickets'}
					description={'Transfer all opened and closed support tickets.'}
				/>
				<CheckboxWrapper
					isReversed={true}
					id={'Content'}
					checked={migrateOptions.migrateContent}
					setChecked={checked =>
						setMigrateOptions({
							...migrateOptions,
							migrateContent: checked,
						})
					}
					title={'Content'}
					description={'Transfer all announcements and all frequently asked questions.'}
				/>
			</div>
		</div>
	)
}

export default AdditionalDataTransferStep
