import React from 'react'
import styles from './EmbedsBanner.module.scss'
import embedsImage from '../../assets/images/billgangEmbeds.png'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { useAppContext } from '../../hooks/useAppContext'

const EmbedsBanner = () => {
	const { builderLink } = useAppContext()

	return (
		<div className={styles.embedsBanner}>
			<div className={styles.embedsBannerTextSide}>
				<h1 className={styles.title}>Billgang Embeds</h1>
				<p className={`${styles.description} ${styles.mtExtraLarge}`}>
					If a Billgang-powered storefront isn't best suited for you, you're welcome to leverage all
					of our products through your own website. From payments to automated product delivery to
					customer insights, you get everything Billgang has to offer.
				</p>
				<p className={`${styles.description} ${styles.mtMedium}`}>
					With embedded products, you can build and design your own storefront beyond our
					drag-and-drop builder and still access Billgang’s powerful commerce infrastructure.
				</p>

				<div className={`${styles.buttons} ${styles.mtExtraLarge}`}>
					<Button
						style={ButtonStyles.THIRD}
						icon={{
							id: 'code',
							width: 15,
							height: 13,
							align: ButtonsIconAlign.LEFT,
							className: styles.primaryColor,
						}}
						disableShadow={true}
						onClick={() => window.open(builderLink + '/embed', 'blank', 'noopener')}
					>
						Generate Embed
					</Button>
					<Button
						style={ButtonStyles.THIRD}
						icon={{
							id: 'blankWindow',
							width: 12,
							height: 12,
							align: ButtonsIconAlign.LEFT,
							className: styles.primaryColor,
						}}
						disableShadow={true}
						onClick={() => window.open(builderLink + '/embed', 'blank', 'noopener')}
					>
						Customize Embed
					</Button>
				</div>
			</div>
			<div className={styles.embedsBannerImage}>
				<img src={embedsImage} alt='embeds' />
			</div>
		</div>
	)
}

export default EmbedsBanner
