import React, { FC } from 'react'
import styles from './CheckboxWrapper.module.scss'
import Checkbox from '../Checkbox/Checkbox'

interface CheckboxWrapperProps {
	id: string
	checked: boolean
	setChecked: (value: boolean) => void
	title: string
	description?: string
	isReversed?: boolean
}

const CheckboxWrapper: FC<CheckboxWrapperProps> = ({
	checked,
	setChecked,
	id,
	title,
	description,
	isReversed,
}) => {
	return (
		<label className={`${styles.checkboxWrapper} ${isReversed && styles.reversed}`}>
			<Checkbox id={id} checked={checked} setChecked={setChecked} />
			<div className={styles.texts}>
				<p className={styles.title}>{title}</p>
				{description && <p className={styles.description}>{description}</p>}
			</div>
		</label>
	)
}

export default CheckboxWrapper
