import React, { FC } from 'react'
import styles from './InputWithButton.module.scss'
import formStyles from '../FormComponents.module.scss'
import Button, { ButtonStyles } from '../../Button/Button'

interface InputWithButtonProps {
	value: string
	setValue: (value: string) => void
	placeholder?: string
	buttonText: string
	onButtonClick: () => void
}

const InputWithButton: FC<InputWithButtonProps> = ({
	setValue,
	value,
	placeholder,
	onButtonClick,
	buttonText,
}) => {
	return (
		<div className={styles.inputWithButton}>
			<input
				className={formStyles.input}
				value={value}
				onChange={event => setValue(event.target.value)}
				placeholder={placeholder}
			/>

			<Button style={ButtonStyles.DARK} disableShadow={true} onClick={onButtonClick}>
				{buttonText}
			</Button>
		</div>
	)
}

export default InputWithButton
