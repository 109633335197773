import React, { FC, useState } from 'react'
import styles from './Tables.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { NavLink, useNavigate } from 'react-router-dom'
import Checkbox from '../UI/Checkbox/Checkbox'
import ProductTypeBadge from '../UI/ProductTypeBadge/ProductTypeBadge'
import OrangeBadge from '../UI/OrangeBadge/OrangeBadge'
import { StockGroup } from '../../types/StockGroup/StockGroup'
import { format } from 'date-fns'
import WarehouseStatusBadge from '../UI/StatusBadges/WarehouseStatusBadge'
import { ActionMenuOption } from '../../types/ActionMenuOption/ActionMenuOption'
import ActionMenu from '../UI/ActionMenu/ActionMenu'

interface StockGroupsTableProps {
	warehouses: StockGroup[]
	isStockSystemDeleteAvailable: boolean
	isStockSystemModifyAvailable: boolean
	onDeleteClick: (warehouse: StockGroup) => void
}

interface StockGroupsTableBodyProps {
	warehouse: StockGroup
	isStockSystemDeleteAvailable: boolean
	isStockSystemModifyAvailable: boolean
	onDeleteClick: (warehouse: StockGroup) => void
}

const StockGroupsTableBody: FC<StockGroupsTableBodyProps> = ({
	warehouse,
	isStockSystemDeleteAvailable,
	isStockSystemModifyAvailable,
	onDeleteClick,
}) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)
	const navigate = useNavigate()

	const actionButtons: ActionMenuOption[] = []

	isStockSystemModifyAvailable &&
		actionButtons.push({
			title: 'Edit warehouse',
			icon: {
				id: 'pen',
				height: 12,
				width: 12,
			},
			onClick: () => navigate(`/products/warehouse/stock-groups/${warehouse.id}`),
		})

	isStockSystemDeleteAvailable &&
		actionButtons.push({
			title: 'Delete warehouse',
			icon: {
				id: 'trashBin',
				height: 12,
				width: 12,
			},
			onClick: () => onDeleteClick(warehouse),
		})

	return (
		<NavLink
			className={styles.trWrapper}
			to={`stock-groups/${warehouse.id}`}
			style={{
				paddingBottom: isActionMenuActive ? +actionButtons.length * 50 : 5,
			}}
		>
			<tr>
				<td className={styles.large}>
					<div className={styles.itemInfo}>
						<div>
							<p>{warehouse.name}</p>
							<span>ID: {warehouse.id}</span>
						</div>
					</div>
				</td>
				<td>{warehouse.productsCount}</td>
				<td>
					<WarehouseStatusBadge warehouseStatus={warehouse.status} />
				</td>
				<td>{warehouse.stock}</td>
				<td>
					<p>{format(new Date(warehouse.lastUpdated), 'MMM dd, yyyy')}</p>
					<span>{format(new Date(warehouse.lastUpdated), 'hh:mm aa z')}</span>
				</td>
				<td className={styles.small}>
					<ActionMenu
						actionButtons={actionButtons}
						isActive={isActionMenuActive}
						setIsActive={setIsActionMenuActive}
					/>
				</td>
			</tr>
		</NavLink>
	)
}

const StockGroupsTable: FC<StockGroupsTableProps> = ({
	warehouses,
	isStockSystemDeleteAvailable,
	isStockSystemModifyAvailable,
	onDeleteClick,
}) => {
	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.large}>Name</th>
					<th>Products</th>
					<th>Status</th>
					<th>Stock</th>
					<th>Last Updated</th>
					<th className={styles.small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{warehouses.map(warehouse => (
					<StockGroupsTableBody
						warehouse={warehouse}
						isStockSystemDeleteAvailable={isStockSystemDeleteAvailable}
						isStockSystemModifyAvailable={isStockSystemModifyAvailable}
						onDeleteClick={onDeleteClick}
						key={warehouse.id}
					/>
				))}
			</tbody>
		</table>
	)
}

export default StockGroupsTable
