import React, { FC } from 'react'
import styles from '../SettingsMigratePage.module.scss'
import Input from '../../../../components/UI/FormComponents/Input'
import { FormErrors } from '../../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../../helpers/fieldValidators'

interface MigrateApiKeyStepProps {
	apiKey: string
	setApiKey: (value: string) => void
	shopDomain: string
	setShopDomain: (value: string) => void
	errors: FormErrors
	setErrors: (value: FormErrors) => void
}

const MigrateApiKeyStep: FC<MigrateApiKeyStepProps> = ({
	setApiKey,
	apiKey,
	shopDomain,
	setShopDomain,
	setErrors,
	errors,
}) => {
	return (
		<div className={styles.mtMediumLarge}>
			<p className={styles.defaultText}>Sellpass Automated Migration</p>
			<div className={styles.mt10}>
				<Input
					value={apiKey}
					setValue={setApiKey}
					placeholder={'Sellpass API Key'}
					errorMessage={errors['apiKey']}
					onBlur={() =>
						setErrors({
							...errors,
							apiKey: fieldValidators.blankValidator('Sellpass API Key', apiKey),
						})
					}
				/>
			</div>
			<p className={`${styles.secondaryText} ${styles.mt10}`}>
				Billgang will introduce support for migrations from other platforms based on customer
				demand.
			</p>

			<div className={styles.mtMediumLarge}>
				<p className={styles.defaultText}>Sellpass Shop Domain</p>
				<div className={styles.mt10}>
					<Input
						value={shopDomain}
						setValue={setShopDomain}
						placeholder={'demo.sellpass.io'}
						errorMessage={errors['shopDomain']}
						onBlur={() =>
							setErrors({
								...errors,
								shopDomain: fieldValidators.blankValidator('Sellpass Shop Domain', shopDomain),
							})
						}
					/>
				</div>
			</div>
		</div>
	)
}

export default MigrateApiKeyStep
