import React, { FC } from 'react'
import styles from './PaymentSetting.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { BalanceSettings } from '../../types/BalanceSettings/BalanceSettings'
import { BalanceSettingsStatus } from '../../types/BalanceSettingsStatus/BalanceSettingsStatus'
import NoDataComponent from '../NoDataComponent/NoDataComponent'
import pageNotFoundImage from '../../assets/images/404.png'

interface balancePaymentSettingProps {
	onEditClick: () => void
	balanceSettings?: BalanceSettings
	isSettingsPaymentsModifyAvailable: boolean
}

const BalancePaymentSetting: FC<balancePaymentSettingProps> = ({
	onEditClick,
	balanceSettings,
	isSettingsPaymentsModifyAvailable,
}) => {
	return (
		<>
			<div className={`${styles.paymentSetting}`}>
				<div className={styles.paymentSettingInner}>
					<div className={styles.payment}>
						<SpriteIcon iconId={'logo'} width={19} height={23} />
						<div>
							<p>Balance</p>
							<span>Store Currency</span>
						</div>
					</div>
					<div className={styles.paymentEnd}>
						{isSettingsPaymentsModifyAvailable && (
							<SpriteIcon onClick={onEditClick} iconId={'pen'} width={15} height={15} />
						)}

						{balanceSettings?.status === BalanceSettingsStatus.ACTIVE ||
						balanceSettings?.status === BalanceSettingsStatus.LIMITS_REACHED ? (
							<SpriteIcon
								className={styles.checkedCircle}
								iconId={'checkedCircle'}
								width={16}
								height={16}
							/>
						) : (
							<SpriteIcon
								className={styles.xMarkCircle}
								iconId={'xMarkCircle'}
								width={16}
								height={16}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default BalancePaymentSetting
