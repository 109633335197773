import React, { FC } from 'react'
import styles from './Tables.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import OrangeBadge from '../UI/OrangeBadge/OrangeBadge'
import StatusCodeBadge from '../UI/StatusBadges/StatusCodeBadge'
import { NavLink } from 'react-router-dom'
import { WebhookLog } from '../../types/WebhookLog/WebhookLog'
import WebhookEventBadge from '../UI/WebhookEventBadge/WebhookEventBadge'
import WebhookMessageEventStatusBadge from '../UI/StatusBadges/WebhookMessageEventStatusBadge'
import { format } from 'date-fns'

interface WebhookLogsTableProps {
	webhookLogs: WebhookLog[]
}

const WebhookLogsTable: FC<WebhookLogsTableProps> = ({ webhookLogs }) => {
	const endpoints = webhookLogs
		.map(log =>
			log.endpoints.map(endpoint => ({
				...endpoint,
				externalMessageId: log.externalMessageId,
				event: log.event,
				createdAt: log.createdAt,
			}))
		)
		.flat()

	console.log(endpoints)

	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.large}>URL</th>
					<th>Events</th>
					<th>Response</th>
					{/*<th>Retries</th>*/}
					<th>Date & Time</th>
					<th className={styles.small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{endpoints.map(endpoint => (
					<NavLink
						className={styles.trWrapper}
						to={`/developers/webhook-logs/${endpoint.externalMessageId}/${endpoint.externalEndpointId}`}
						key={endpoint.externalMessageId}
					>
						<tr>
							<td className={styles.large}>
								<p>{endpoint.url}</p>
							</td>
							<td>
								<WebhookEventBadge event={endpoint.event} />
							</td>
							<td>
								<WebhookMessageEventStatusBadge webhookMessageEventStatus={endpoint.status} />
							</td>
							{/*<td>5/5</td>*/}
							<td>
								<p>{format(new Date(endpoint.createdAt), 'MMM dd, yyyy')}</p>
								<span>{format(new Date(endpoint.createdAt), 'hh:mm aa z')}</span>
							</td>
							<td className={styles.small}>
								<SpriteIcon
									className={styles.arrowRight}
									iconId={'arrowDown'}
									width={10}
									height={4}
								/>
							</td>
						</tr>
					</NavLink>
				))}
			</tbody>
		</table>
	)
}

export default WebhookLogsTable
