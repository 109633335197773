import React, { FC } from 'react'
import styles from './Tabs.module.scss'

interface TabsProps {
	tabs: {
		label: string
		value: number | string
	}[]
	currentTab: number | string | null
	setCurrentTab: (value: any) => void
	style?: 'alternate'
}

const Tabs: FC<TabsProps> = ({ setCurrentTab, currentTab, tabs, style }) => {
	return (
		<div className={`${styles.tabs} ${style && styles[style]}`}>
			{tabs.map(tab => (
				<div
					className={`${styles.tab} ${currentTab === tab.value && styles.active}`}
					onClick={() => setCurrentTab(tab.value)}
					key={tab.value}
				>
					{tab.label}
				</div>
			))}
		</div>
	)
}

export default Tabs
