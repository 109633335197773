import React, { FC, useState } from 'react'
import generalStyles from '../../CreateProductPage.module.scss'
import Input from '../../../../components/UI/FormComponents/Input'
import { VariantsDelivery } from '../../../../types/CreateVariants/VariantsDelivery'
import { fieldValidators } from '../../../../helpers/fieldValidators'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../../components/UI/Button/Button'

interface ProductDeliveryDynamicProps {
	variantsDelivery: VariantsDelivery
	setVariantsDelivery: (value: VariantsDelivery) => void
	currentVariantIndex: string
}

const ProductDeliveryDynamic: FC<ProductDeliveryDynamicProps> = ({
	setVariantsDelivery,
	variantsDelivery,
	currentVariantIndex,
}) => {
	const [errorMessage, setErrorMessage] = useState('')

	return (
		<div>
			<div className={generalStyles.fieldWrapper}>
				<header>
					<div>
						<p className={generalStyles.fieldTitle}>Dynamic Webhook URL</p>
						<p className={generalStyles.fieldDescription}>
							To learn more about how to use our API, visit our developer documentation.
						</p>
					</div>

					<Button
						style={ButtonStyles.THIRD}
						icon={{
							id: 'blankWindow',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						onClick={() => window.open('https://developers.billgang.com/', '_blank', 'noopener')}
					>
						Visit Developer Docs
					</Button>
				</header>
				<Input
					value={variantsDelivery[currentVariantIndex].dynamicDeliveryUrl}
					setValue={value =>
						setVariantsDelivery({
							...variantsDelivery,
							[currentVariantIndex]: {
								...variantsDelivery[currentVariantIndex],
								dynamicDeliveryUrl: value,
							},
						})
					}
					placeholder={'Webhook URL (https://example.com)'}
					errorMessage={errorMessage}
					onBlur={() =>
						setErrorMessage(
							fieldValidators.URLValidator(
								'Dynamic Webhook URL',
								variantsDelivery[currentVariantIndex].dynamicDeliveryUrl || ''
							)
						)
					}
				/>
			</div>
		</div>
	)
}

export default ProductDeliveryDynamic
