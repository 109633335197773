import React, { FC, ReactNode } from 'react'
import styles from './PagesLayout.module.scss'
import Header from '../Header/Header'
import { useLocation } from 'react-router-dom'

interface PagesLayoutProps {
	children: ReactNode
	isAuth?: boolean
}

export const pageLayoutId = 'pageLayout'

const PagesLayout: FC<PagesLayoutProps> = ({ children, isAuth }) => {
	const location = useLocation()
	const isTicketsPage = location.pathname.includes('tickets')

	return (
		<div
			className={!isAuth ? `${styles.pagesLayout} ${isTicketsPage && styles.withoutPadding}` : ''}
			id={pageLayoutId}
		>
			{!isAuth && (
				<div className={styles.header}>
					<Header />
				</div>
			)}

			<div className={styles.pages}>{children}</div>
		</div>
	)
}

export default PagesLayout
