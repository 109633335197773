import React, { FC, useEffect } from 'react'
import styles from './RewardProductsTable.module.scss'
import CustomSelect, { SelectStyles } from '../UI/CustomSelect/CustomSelect'
import Input from '../UI/FormComponents/Input'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { Product } from '../../types/Product/Product'
import { ProductWithVariantForProductRewardType } from '../../types/ProductWithVariantForProductRewardType/ProductWithVariantForProductRewardType'
import { useAPI } from '../../hooks/useAPI'
import { ProductsService } from '../../API/ProductsService'
import { ProductDetailed } from '../../types/Product/ProductDetailed'
import Spinner from '../UI/Spinner/Spinner'

interface RewardProductsTableProps {
	products: Product[]
	productsWithVariants: ProductWithVariantForProductRewardType[]
	setProductsWithVariants: (value: ProductWithVariantForProductRewardType[]) => void
	shopId: string
}

interface RewardProductsTableBodyProps extends RewardProductsTableProps {
	productWithVariant: ProductWithVariantForProductRewardType
}

const RewardProductsTableBody: FC<RewardProductsTableBodyProps> = ({
	productWithVariant,
	products,
	setProductsWithVariants,
	productsWithVariants,
	shopId,
}) => {
	const [isGetProductLoading, productDetailed, getProduct] = useAPI<ProductDetailed>(
		() => ProductsService.getProduct(shopId, productWithVariant.productId),
		false
	)

	useEffect(() => {
		if (productWithVariant.productId) {
			getProduct()
		}
	}, [productWithVariant.productId])

	return (
		<div className={styles.rewardProductsTableBody}>
			<div className={styles.tableSection}>
				<CustomSelect
					style={SelectStyles.secondary}
					value={productWithVariant.productId.toString()}
					setValue={value => {
						setProductsWithVariants(
							productsWithVariants.map(product2 =>
								product2.id === productWithVariant.id
									? {
											...product2,
											productId: +value,
									  }
									: product2
							)
						)
					}}
					options={products.map(product => ({
						value: product.id.toString(),
						label: product.name,
					}))}
				/>
			</div>
			<div className={styles.tableSection}>
				{isGetProductLoading ? (
					<Spinner />
				) : (
					<CustomSelect
						style={SelectStyles.secondary}
						value={productWithVariant.variantId.toString()}
						setValue={value =>
							setProductsWithVariants(
								productsWithVariants.map(product2 =>
									product2.id === productWithVariant.id
										? {
												...product2,
												variantId: +value,
										  }
										: product2
								)
							)
						}
						options={
							productDetailed?.variants?.map(variant => ({
								label: variant.name,
								value: variant.id.toString(),
							})) || []
						}
					/>
				)}
			</div>
			<div className={styles.tableSection}>
				<Input
					value={productWithVariant.quantity}
					setValue={value =>
						setProductsWithVariants(
							productsWithVariants.map(product2 =>
								product2.id === productWithVariant.id
									? {
											...product2,
											quantity: value,
									  }
									: product2
							)
						)
					}
					type={'number'}
					placeholder={'0'}
				/>
			</div>
			<div className={styles.tableSection}>
				<SpriteIcon
					className={styles.trashBin}
					iconId={'trashBin'}
					width={12}
					height={13}
					onClick={() =>
						setProductsWithVariants(
							productsWithVariants.filter(product => product.id !== productWithVariant.id)
						)
					}
				/>
			</div>
		</div>
	)
}

const RewardProductsTable: FC<RewardProductsTableProps> = ({
	products,
	setProductsWithVariants,
	productsWithVariants,
	shopId,
}) => {
	return (
		<div className={styles.rewardProductsTable}>
			<header className={styles.rewardProductsTableHeader}>
				<div className={styles.tableSection}>Product</div>
				<div className={styles.tableSection}>Variant</div>
				<div className={styles.tableSection}>Quantity</div>
				<div className={styles.tableSection}></div>
			</header>
			{productsWithVariants.map(product => (
				<RewardProductsTableBody
					productsWithVariants={productsWithVariants}
					products={products}
					productWithVariant={product}
					setProductsWithVariants={setProductsWithVariants}
					shopId={shopId}
					key={product.id}
				/>
			))}
		</div>
	)
}

export default RewardProductsTable
