import React, { FC, useState } from 'react'
import styles from './AffiliatesMember.module.scss'
import { Link } from 'react-router-dom'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { Affiliate } from '../../types/Affiliate/Affiliate'

interface AffiliatesMemberProps {
	affiliate: Affiliate
}

const AffiliatesMember: FC<AffiliatesMemberProps> = ({ affiliate }) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<div className={styles.memberDetail} onClick={() => setIsOpen(!isOpen)}>
			<div className={styles.head}>
				<span className={styles.ava}>{affiliate?.customerEmail && affiliate.customerEmail[0]}</span>
				<div className={styles.text}>
					<Link to={`/customers/${affiliate.customerId}`}>
						<h4>{affiliate.customerEmail}</h4>
					</Link>
					<p>{affiliate.referralSystem.totalReferrals} referred</p>
				</div>
				<SpriteIcon iconId={'arrowDown'} width={10} height={4} />
			</div>
			<div className={`${styles.body} ${isOpen && styles.open}`}>
				<div className={styles.subCard}>
					<SpriteIcon iconId={'dollarCircle'} width={14.5} height={14.5} />
					<div>
						<h4>{affiliate.referralSystem.revenueGeneratedUsd} USD</h4>
						<p>Revenue Generated</p>
					</div>
				</div>
				<div className={styles.subCard}>
					<SpriteIcon iconId={'lightning'} width={14.5} height={14.5} />

					<div>
						<h4>{affiliate.referralSystem.balanceAwarded} USD</h4>
						<p>Balance Awarded</p>
					</div>
				</div>
				<div className={styles.subCard}>
					<SpriteIcon iconId={'chain'} width={14.5} height={14.5} />

					<div>
						<h4>{affiliate.referralSystem.referralCode}</h4>
						<p>Referral Code</p>
					</div>
				</div>

				<Button
					style={ButtonStyles.BORDERED}
					icon={{
						id: 'trashBin',
						width: 12,
						height: 13,
						align: ButtonsIconAlign.LEFT,
					}}
					width={'100%'}
					disableShadow={true}
				>
					Disable Referral Code
				</Button>
			</div>
		</div>
	)
}

export default AffiliatesMember
