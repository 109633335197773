import React, { FC } from 'react'
import styles from './CouponTypeBadge.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

interface CouponTypeBadgeProps {
	isFixed: boolean
}

const CouponTypeBadge: FC<CouponTypeBadgeProps> = ({ isFixed }) => {
	return (
		<div className={styles.couponTypeBadge}>
			<p className={styles.text}>{isFixed ? 'Fixed' : 'Percentage'}</p>
			<SpriteIcon iconId={isFixed ? 'dollar' : 'percent'} width={isFixed ? 9 : 13} height={14} />
		</div>
	)
}

export default CouponTypeBadge
