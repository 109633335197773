import React, { FC } from 'react'
import styles from './PostCard.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { Announcement } from '../../types/Announcement/Announcement'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import ListingVisibilityStatusBadge from '../UI/StatusBadges/ListingVisibilityStatusBadge'

interface PostCardProps {
	announcement: Announcement
	onDeleteClick: (id: number) => void
	isContentModifyAvailable: boolean
	isContentDeleteAvailable: boolean
}

const PostCard: FC<PostCardProps> = ({
	announcement,
	onDeleteClick,
	isContentDeleteAvailable,
	isContentModifyAvailable,
}) => {
	const navigate = useNavigate()

	return (
		<div className={styles.postCard}>
			<div className={styles.postImage}>
				<SpriteIcon iconId={'image'} width={30} height={30} />
			</div>
			<div className={styles.postCardBody}>
				<header className={styles.postCardBodyHeader}>
					<time>
						{format(new Date(announcement.updatedAt), `MMM dd, yyyy`)}
						<p>{format(new Date(announcement.updatedAt), 'hh:mm aa z')}</p>
					</time>
					<div className={styles.postCardBodyHeaderButtons}>
						{isContentModifyAvailable && (
							<SpriteIcon
								iconId={'pen'}
								width={15}
								height={15}
								onClick={() => navigate(`/content/posts/edit/${announcement.id}`)}
							/>
						)}
						{isContentDeleteAvailable && (
							<SpriteIcon
								iconId={'trashBin'}
								width={14}
								height={15}
								onClick={() => onDeleteClick(announcement.id)}
							/>
						)}
					</div>
				</header>

				<h2 className={styles.postTitle}>{announcement.title}</h2>
				<p className={styles.postDescription}>{announcement.shortDescription}</p>

				<footer className={styles.postCardBodyFooter}>
					<ListingVisibilityStatusBadge visibility={announcement.visibility} />
				</footer>
			</div>
		</div>
	)
}

export default PostCard
