import { usePageTitle } from '../../../hooks/usePageTitle'
import React, { useState } from 'react'
import styles from '../AuthPages.module.scss'
import { SpriteIcon } from '../../../components/UI/SpriteIcon/SpriteIcon'
import Input from '../../../components/UI/FormComponents/Input'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../components/UI/Button/Button'
import { useNavigate } from 'react-router-dom'
import CustomSelect, { SelectStyles } from '../../../components/UI/CustomSelect/CustomSelect'
import ImageInput from '../../../components/UI/ImageInput/ImageInput'
import { useAPI } from '../../../hooks/useAPI'
import { ShopsService } from '../../../API/ShopsService'
import { ImagesService } from '../../../API/ImagesService'
import { SettingsService } from '../../../API/SettingsService'
import OptionalBadge from '../../../components/UI/OptionalBadge/OptionalBadge'
import InputWithText from '../../../components/UI/FormComponents/InputWithText'
import Textarea from '../../../components/UI/FormComponents/Textarea'
import FieldErrorMessage from '../../../components/UI/FieldErrorMessage/FieldErrorMessage'
import { fieldValidators } from '../../../helpers/fieldValidators'
import { FormErrors } from '../../../types/FormErrors/FormErrors'

const SetupStorePage = () => {
	const navigate = useNavigate()
	const [logoFile, setLogoFile] = useState<File | null>(null)
	const [displayName, setDisplayName] = useState('')
	const [subdomain, setSubdomain] = useState('')
	const [businessDetails, setBusinessDetails] = useState('')
	const [errors, setErrors] = useState<FormErrors>({})
	const [whatDoYouSell, setWhatDoYouSell] = useState('')
	const [howDidYouFind, setHowDidYouFind] = useState('')
	const [monthlyTransactionVolume, setMonthlyTransactionVolume] = useState('')
	const [step, setStep] = useState(0)
	usePageTitle('Setup Store')
	const subdomainRegExp = /^[a-zA-Z0-9]+$/
	const displayNameValidator = fieldValidators.blankValidator('Display name', displayName)
	const subdomainValidator = subdomainRegExp.test(subdomain) ? '' : 'Invalid subdomain'
	const businessDetailsValidator = fieldValidators.blankValidator(
		'Business details',
		businessDetails
	)

	const createShopValidator = () => {
		setErrors({
			...errors,
			displayName: displayNameValidator,
			subdomain: subdomainValidator,
			whatDoYouSell: !whatDoYouSell ? 'Required' : '',
			businessDetails: businessDetailsValidator,
		})

		return !!(displayName && subdomain && whatDoYouSell && businessDetails)
	}

	const [isAddLogoLoading, , addLogo] = useAPI(
		(imageId: number) => SettingsService.updateLogo(localStorage.getItem('shopId') || '', imageId),
		false,
		'Logo successfully added',
		{
			onSuccess: () => {
				window.location.pathname = '/'
			},
		}
	)

	const [isUploadLogoLoading, , uploadLogo] = useAPI<{ id: string }>(
		(shopId: string) => {
			const formData = new FormData()

			if (logoFile) {
				formData.append('file', logoFile)
			}

			return ImagesService.upload(shopId, formData)
		},
		false,
		'Image successfully uploaded',
		{
			onSuccess: response => {
				addLogo(response.data.data.id)
			},
		}
	)

	const [isCreateShopLoading, , createShop] = useAPI<{ id: string }>(
		() =>
			ShopsService.create({
				name: displayName,
				subDomain: subdomain,
				howDidYouFind: howDidYouFind,
				whatDoYouSell: whatDoYouSell,
				monthlyTransactionVolume: monthlyTransactionVolume ? +monthlyTransactionVolume : null,
			}),
		false,
		'Shop successfully created',
		{
			validator: createShopValidator,
			onSuccess: response => {
				localStorage.setItem('shopId', response.data.data.id)

				if (logoFile) {
					uploadLogo(response.data.data.id)
				} else {
					window.location.pathname = '/'
				}
			},
		}
	)

	const onContinueClickHandler = () => {
		setErrors({
			...errors,
			displayName: displayNameValidator,
			subdomain: subdomainValidator,
		})

		if (step === 0 && !subdomainValidator && displayName) {
			setStep(1)
		}

		if (step === 1) {
			createShop()
		}
	}

	const foundSelectOptions = [
		{ value: 'Discord', label: 'Discord' },
		{ value: 'Forum', label: 'Forum' },
		{ value: 'Telegram', label: 'Telegram' },
		{ value: 'Instagram', label: 'Instagram' },
		{ value: 'TikTok', label: 'TikTok' },
		{ value: 'YouTube', label: 'YouTube' },
		{ value: 'Twitter', label: 'Twitter' },
		{ value: 'Facebook', label: 'Facebook' },
		{ value: 'Invited', label: 'Invited' },
		{ value: 'Search Engine', label: 'Search Engine' },
		{ value: 'Other', label: 'Other' },
	]

	const sellSelectOptions = [
		{ value: 'Software/SaaS', label: 'Software/SaaS' },
		{ value: 'Discord access', label: 'Discord access' },
		{ value: 'Telegram access', label: 'Telegram access' },
		{ value: 'Online course', label: 'Online course' },
		{ value: 'Memberships', label: 'Memberships' },
		{ value: 'Digital products', label: 'Digital products' },
		{ value: 'Freelance services', label: 'Freelance services' },
		{ value: 'AI tools/services', label: 'AI tools/services' },
		{ value: 'Private content', label: 'Private content' },
		{ value: 'Something else', label: 'Something else' },
	]

	const transactionVolumeOptions = [
		{ value: '0', label: 'Less than $1,000' },
		{ value: '1000', label: '$1,000 - $2,500' },
		{ value: '2500', label: '$2,500 - $5,000' },
		{ value: '5000', label: '$5,000 - $7,500' },
		{ value: '7500', label: '$7,500 - $10,000' },
		{ value: '10000', label: '$10,000 - $25,000' },
		{ value: '25000', label: '$25,000 - $50,000' },
		{ value: '50000', label: '$50,000 - $75,000' },
		{ value: '75000', label: '$75,000 - $100,000' },
		{ value: '100000', label: '$100,000 or more' },
	]

	return (
		<div className={styles.authInner}>
			<div className={styles.logo}>
				<SpriteIcon iconId={'nameLogo'} width={124} height={27} />
			</div>
			{step === 0 && (
				<>
					<h1 className={`${styles.title} ${styles.onboarding}`}>Create your store</h1>
					<p className={`${styles.description} ${styles.onboarding}`}>
						Just a few quick questions while we provision your new business, we'll get you started
						based on your responses.
					</p>

					<div className={styles.fieldWrapper}>
						<h2 className={styles.fieldTitle}>Business or display name</h2>
						<Input
							value={displayName}
							setValue={setDisplayName}
							placeholder={'E.g Melt, Inc or Melt'}
							errorMessage={errors.displayName}
						/>
					</div>

					<div className={styles.fieldWrapper}>
						<h2 className={styles.fieldTitle}>
							Upload your business logo <OptionalBadge />
						</h2>
						<ImageInput
							file={logoFile}
							title={'Upload a business logo'}
							setFile={setLogoFile}
							subTitle={'PNG, JPG, or GIF. 5MB max.'}
						/>
					</div>

					<div className={styles.fieldWrapper}>
						<h2 className={styles.fieldTitle}>Enter a name for your subdomain</h2>
						<InputWithText
							text={'.billgang.store'}
							value={subdomain}
							setValue={setSubdomain}
							placeholder={'Melt'}
							errorMessage={errors.subdomain}
						/>
					</div>
				</>
			)}
			{step === 1 && (
				<>
					<h1 className={`${styles.title} ${styles.onboarding}`}>You’re almost there</h1>
					<p className={`${styles.description} ${styles.onboarding}`}>
						This helps us build better products and cater features towards your business.
					</p>

					<div className={styles.fieldWrapper}>
						<h2 className={styles.fieldTitle}>What are you selling?</h2>
						{/*<CustomSelect*/}
						{/*	style={SelectStyles.secondary}*/}
						{/*	value={whatDoYouSell}*/}
						{/*	setValue={setWhatDoYouSell}*/}
						{/*	options={sellSelectOptions}*/}
						{/*	errorMessage={errors.whatDoYouSell}*/}
						{/*/>*/}

						<div className={styles.buttonsSelect}>
							{sellSelectOptions.map(option => (
								<div
									className={`${styles.buttonsSelectItem} ${
										whatDoYouSell === option.value && styles.active
									}`}
									key={option.label}
									onClick={() => setWhatDoYouSell(option.value)}
								>
									{option.label}
								</div>
							))}
						</div>
						{errors['whatDoYouSell'] && (
							<FieldErrorMessage errorMessage={errors['whatDoYouSell']} />
						)}
					</div>

					<div className={styles.fieldWrapper}>
						<h2 className={styles.fieldTitle}>Business details</h2>
						<Textarea
							value={businessDetails}
							setValue={setBusinessDetails}
							placeholder={
								'Give us the elevator pitch about your business. You can also share what you’ll use Billgang for or any problems you’ll solve with our payments platform.'
							}
							height={130}
							errorMessage={errors.businessDetails}
						/>
					</div>

					<div className={styles.fieldWrapper}>
						<h2 className={styles.fieldTitle}>
							How did you find Billgang? <OptionalBadge />
						</h2>
						<CustomSelect
							style={SelectStyles.secondary}
							value={howDidYouFind}
							setValue={setHowDidYouFind}
							options={foundSelectOptions}
						/>
					</div>
					<div className={styles.fieldWrapper}>
						<h2 className={styles.fieldTitle}>
							Monthly transaction volume <OptionalBadge />
						</h2>
						<CustomSelect
							style={SelectStyles.secondary}
							value={monthlyTransactionVolume}
							setValue={setMonthlyTransactionVolume}
							options={transactionVolumeOptions}
						/>
					</div>
				</>
			)}

			<div className={styles.button}>
				<Button
					icon={{
						id: 'arrowRight',
						width: 11,
						height: 13,
						align: ButtonsIconAlign.RIGHT,
					}}
					style={ButtonStyles.PRIMARY}
					onClick={onContinueClickHandler}
					isLoading={isCreateShopLoading || isUploadLogoLoading || isAddLogoLoading}
				>
					Continue
				</Button>
			</div>
		</div>
	)
}

export default SetupStorePage
