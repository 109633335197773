import { privateAPI } from './API'
import { AnnouncementCreate } from '../types/Announcement/AnnouncementCreate'

export const AnnouncementsService = {
	getAll: async (shopId: string, searchString?: string, PageNumber?: number, PageSize?: number) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/announcements`, {
			params: {
				searchString,
				PageNumber,
				PageSize,
			},
		})
	},
	create: async (shopId: string, data: AnnouncementCreate) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/announcements`, data)
	},
	getOne: async (shopId: string, id: number) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/announcements/${id}`)
	},
	update: async (shopId: string, id: number, data: AnnouncementCreate) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/announcements/${id}`, data)
	},
	delete: async (shopId: string, id: number | null) => {
		return await privateAPI.delete(`/v1/dash/shops/${shopId}/announcements/${id}`)
	},
}
