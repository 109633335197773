import React, { FC, useEffect, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import Input from '../../UI/FormComponents/Input'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import { useAPI } from '../../../hooks/useAPI'
import { RewardsService } from '../../../API/RewardsService'
import { useAppContext } from '../../../hooks/useAppContext'
import { ReferralSystem } from '../../../types/ReferralSystem/ReferralSystem'
import FormErrorsDisplayBadge from '../../UI/FormErrorsDisplayBadge/FormErrorsDisplayBadge'
import { FormErrors } from '../../../types/FormErrors/FormErrors'
import { fieldValidators } from '../../../helpers/fieldValidators'
import RequiredBadge from '../../UI/RequiredBadge/RequiredBadge'

interface ReferralsModalProps extends BaseModalProps {
	getRewards: () => void
	referralSystem: ReferralSystem | undefined
}

const ReferralsModal: FC<ReferralsModalProps> = ({ onClose, getRewards, referralSystem }) => {
	const { shop } = useAppContext()
	const [percentage, setPercentage] = useState('')
	const [showErrorsDisplay, setShowErrorsDisplay] = useState(false)
	const [errors, setErrors] = useState<FormErrors>({
		percentage: '',
	})
	const errorMessages = Object.keys(errors)
		.map(key => errors[key])
		.filter(error => error)

	const onSaveHandler = () => {
		if (percentage) {
			updateReferralsSystem()
		} else {
			setErrors({ ...errors, percentage: fieldValidators.blankValidator('Percentage', percentage) })
			setShowErrorsDisplay(true)
		}
	}

	const [isUpdateReferralsSystemLoading, , updateReferralsSystem] = useAPI(
		() =>
			RewardsService.updateReferralsSystems(shop?.id || '', {
				isEnabled: true,
				earnPercent: +percentage,
			}),
		false,
		'Referrals system created successfully',
		{
			onSuccess: () => {
				onClose()
				getRewards()
			},
		}
	)

	useEffect(() => {
		if (referralSystem) {
			setPercentage(referralSystem.earnPercent.toString())
		}
	}, [])

	return (
		<div className={styles.modalInner}>
			{/*{showErrorsDisplay && !!errorMessages.length && (*/}
			{/*	<FormErrorsDisplayBadge*/}
			{/*		title={`Reward rule Save Error`}*/}
			{/*		errors={errorMessages}*/}
			{/*		isModal={true}*/}
			{/*	/>*/}
			{/*)}*/}
			<header className={styles.header}>
				<h1>Earn X% from Referrals Rule </h1>
			</header>
			<div className={styles.body}>
				<p className={styles.description}>
					Give customers a percent of the payment via balance of the friends they refer to your
					store.
				</p>

				<div className={styles.fieldWrapper}>
					<h2>Percentage</h2>
					<Input
						value={percentage}
						setValue={setPercentage}
						placeholder={'e.g: 5%'}
						type={'number'}
						errorMessage={errors['percentage']}
						onBlur={() =>
							setErrors({
								...errors,
								percentage: fieldValidators.blankValidator('Percentage', percentage),
							})
						}
					/>
				</div>
			</div>
			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						disableShadow={true}
						icon={{
							id: 'download',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						onClick={onSaveHandler}
						isLoading={isUpdateReferralsSystemLoading}
					>
						Save Rule
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default ReferralsModal
