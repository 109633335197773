import React, { FC } from 'react'
import styles from './FieldErrorMessage.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

interface FieldErrorMessageProps {
	errorMessage: string
}

const FieldErrorMessage: FC<FieldErrorMessageProps> = ({ errorMessage }) => {
	return (
		<div className={styles.errorMessage}>
			<SpriteIcon iconId={'hexagonExclamationMark'} width={16} height={16} />
			<p>{errorMessage}</p>
		</div>
	)
}

export default FieldErrorMessage
