import React, { FC } from 'react'
import styles from './Section.module.scss'

interface SectionProps {
	title: string
	description?: string
	children: React.ReactNode | React.ReactNode[]
}

const Section: FC<SectionProps> = ({ children, title, description }) => {
	return (
		<section className={styles.section}>
			<header className={styles.sectionHeader}>
				<h1 className={styles.sectionTitle}>{title}</h1>
			</header>
			<div className={styles.sectionBody}>{children}</div>
		</section>
	)
}

export default Section
