import React, { FC } from 'react'
import styles from './StatusBadges.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import { WarehouseStatus } from '../../../types/WarehouseStatus/WarehouseStatus'
import { OrderStatus } from '../../../types/OrderStatus/OrderStatus'
import { OrderChargeStatus } from '../../../types/OrderChargeStatus/OrderChargeStatus'

interface OrderChargeStatusBadgeProps {
	orderChargeStatus: OrderChargeStatus
}

const OrderChargeStatusBadge: FC<OrderChargeStatusBadgeProps> = ({ orderChargeStatus }) => {
	const orderChargeStatusData = {
		[OrderChargeStatus.CANCELLED]: {
			name: 'Cancelled',
			className: 'red',
			iconId: 'xMarkCircle',
		},
		[OrderChargeStatus.PAID]: {
			name: 'Paid',
			className: 'green',
			iconId: 'checkedCircle',
		},
		[OrderChargeStatus.PENDING]: {
			name: 'Pending',
			className: 'brown',
			iconId: 'exclamationMarkCircle',
		},
		[OrderChargeStatus.NOT_PAID]: {
			name: 'Not Paid',
			className: 'red',
			iconId: 'xMarkCircle',
		},
		[OrderChargeStatus.EXPIRED]: {
			name: 'Expired',
			className: 'red',
			iconId: 'xMarkCircle',
		},
		[OrderChargeStatus.REFUNDED]: {
			name: 'Refunded',
			className: 'green',
			iconId: 'checkedCircle',
		},
	}

	return (
		<div
			className={`${styles.statusBadge} ${
				styles[orderChargeStatusData[orderChargeStatus].className]
			}`}
		>
			{orderChargeStatusData[orderChargeStatus].name}
			<SpriteIcon iconId={orderChargeStatusData[orderChargeStatus].iconId} width={16} height={16} />
		</div>
	)
}

export default OrderChargeStatusBadge
