import React, { FC, useEffect, useState } from 'react'
import styles from '../Modals.module.scss'
import Input from '../../UI/FormComponents/Input'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import { BaseModalProps } from '../../../types/BaseModalProps/BaseModalProps'
import uniqid from 'uniqid'
import CustomSelect, { SelectStyles } from '../../UI/CustomSelect/CustomSelect'
import Switch from '../../UI/Switch/Switch'
import { VariantsCustomFields } from '../../../types/CreateVariants/VariantsCustomFields'
import { CustomFieldType } from '../../../types/CustomFieldType/CustomFieldType'
import { CustomField } from '../../../types/CustomField/CustomField'
import Textarea from '../../UI/FormComponents/Textarea'
import Checkbox from '../../UI/Checkbox/Checkbox'
import OptionalBadge from '../../UI/OptionalBadge/OptionalBadge'

interface CreateProductCustomFieldModalProps extends BaseModalProps {
	variantsCustomFields: VariantsCustomFields
	setVariantsCustomFields: (value: VariantsCustomFields) => void
	currentVariantIndex: string
	editingCustomFieldName: string | null
}

const CreateProductCustomFieldModal: FC<CreateProductCustomFieldModalProps> = ({
	onClose,
	setVariantsCustomFields,
	variantsCustomFields,
	currentVariantIndex,
	editingCustomFieldName,
}) => {
	const [name, setName] = useState('')
	const [regex, setRegex] = useState('')
	const [placeholder, setPlaceholder] = useState('')
	const [defaultValue, setDefaultValue] = useState<any>('')
	const [required, setRequired] = useState(false)
	const [fieldType, setFieldType] = useState<string>(CustomFieldType.NUMBER)

	const onSaveHandler = () => {
		const customFieldData: CustomField = {
			name,
			placeholder,
			defaultValue,
			required,
			type: fieldType as CustomFieldType,
			regex: regex,
		}

		if (editingCustomFieldName) {
			setVariantsCustomFields({
				...variantsCustomFields,
				[currentVariantIndex]: {
					...variantsCustomFields[currentVariantIndex],
					customFields: variantsCustomFields[currentVariantIndex].customFields.map(customField =>
						customField.name === editingCustomFieldName ? customFieldData : customField
					),
				},
			})
		} else {
			setVariantsCustomFields({
				...variantsCustomFields,
				[currentVariantIndex]: {
					...variantsCustomFields[currentVariantIndex],
					customFields: [
						...variantsCustomFields[currentVariantIndex].customFields,
						customFieldData,
					],
				},
			})
		}

		onClose()
	}

	useEffect(() => {
		if (editingCustomFieldName) {
			const editingCustomField = variantsCustomFields[currentVariantIndex].customFields.find(
				customField => customField.name === editingCustomFieldName
			)
			setName(editingCustomField?.name || '')
			setPlaceholder(editingCustomField?.placeholder || '')
			setDefaultValue(editingCustomField?.defaultValue || '')
			setRequired(editingCustomField?.required || false)
			setFieldType(editingCustomField?.type || CustomFieldType.NUMBER)
			setRegex(editingCustomField?.regex || '')
		}
	}, [])

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>{editingCustomFieldName ? 'Edit' : 'Create'} Custom Field</h1>
			</header>

			<div className={styles.body}>
				<div className={styles.fieldWrapper}>
					<h2>Name</h2>
					<Input value={name} setValue={setName} placeholder={'Name'} />
				</div>
				{fieldType !== CustomFieldType.CHECK_BOX && fieldType !== CustomFieldType.HIDDEN && (
					<div className={styles.fieldWrapper}>
						<h2>
							Placeholder <OptionalBadge />
						</h2>
						<Input value={placeholder} setValue={setPlaceholder} placeholder={'Placeholder'} />
					</div>
				)}

				{fieldType === CustomFieldType.NUMBER && (
					<div className={styles.fieldWrapper}>
						<h2>
							Default value <OptionalBadge />
						</h2>
						<Input
							value={defaultValue}
							setValue={setDefaultValue}
							placeholder={'Default value'}
							type={'number'}
						/>
					</div>
				)}

				{(fieldType === CustomFieldType.TEXT || fieldType === CustomFieldType.HIDDEN) && (
					<div className={styles.fieldWrapper}>
						<h2>
							Default value <OptionalBadge />
						</h2>
						<Input value={defaultValue} setValue={setDefaultValue} placeholder={'Default value'} />
					</div>
				)}

				{(fieldType === CustomFieldType.TEXT || fieldType === CustomFieldType.LARGE_TEXT) && (
					<div className={styles.fieldWrapper}>
						<h2>
							Regex <OptionalBadge />
						</h2>
						<Input value={regex} setValue={setRegex} placeholder={'Regex value'} />
					</div>
				)}

				{fieldType === CustomFieldType.LARGE_TEXT && (
					<div className={styles.fieldWrapper}>
						<h2>
							Default value <OptionalBadge />
						</h2>
						<Textarea
							value={defaultValue}
							setValue={setDefaultValue}
							placeholder={'Default value'}
						/>
					</div>
				)}

				{fieldType === CustomFieldType.CHECK_BOX && (
					<div className={styles.switchWrapper}>
						<Checkbox
							id={'customFieldCheckbox'}
							checked={defaultValue}
							setChecked={setDefaultValue}
						/>
						<p>Default value</p>
					</div>
				)}

				<div className={styles.fieldWrapper}>
					<h2>Input type</h2>
					<CustomSelect
						style={SelectStyles.secondary}
						value={fieldType}
						setValue={setFieldType}
						options={[
							{
								value: CustomFieldType.NUMBER,
								label: 'Number',
							},
							{
								value: CustomFieldType.TEXT,
								label: 'Textbox',
							},
							{
								value: CustomFieldType.LARGE_TEXT,
								label: 'Large Textbox',
							},
							{
								value: CustomFieldType.CHECK_BOX,
								label: 'Checkbox',
							},

							{
								value: CustomFieldType.HIDDEN,
								label: 'Hidden',
							},
						]}
					/>
				</div>
				<div className={styles.switchWrapper}>
					<Switch checked={required} setChecked={setRequired} />
					<p>Required?</p>
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						disableShadow={true}
						icon={{
							id: 'plus',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						onClick={onSaveHandler}
					>
						{editingCustomFieldName ? 'Edit' : 'Create'} custom field
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default CreateProductCustomFieldModal
