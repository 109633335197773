import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import styles from './WhitelistPage.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import SearchInput, { SearchInputStyles } from '../../components/UI/SearchInput/SearchInput'
import Pagination from '../../components/UI/Pagination/Pagination'
import FraudRulesTable from '../../components/Tables/FraudRulesTable'
import Modal from '../../components/Modals/Modal'
import { closeModal } from '../../helpers/closeModal'
import PageTableHeader from '../../components/PageTableHeader/PageTableHeader'
import { useAPI } from '../../hooks/useAPI'
import { useAppContext } from '../../hooks/useAppContext'
import { FraudRulesService } from '../../API/FraudRulesService'
import { FraudRuleType } from '../../types/FraudRuleType/FraudRuleType'
import { useFirstLoading } from '../../hooks/useFirstLoading'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import noWhitelistsImage from '../../assets/images/noWhitelists.png'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import { useDebounce } from '../../hooks/useDebounce'
import CreateFraudRuleModal from '../../components/Modals/CreateFraudRuleModal/CreateFraudRuleModal'
import { FraudRule } from '../../types/FraudRule/FraudRule'
import Spinner from '../../components/UI/Spinner/Spinner'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import { usePageTitle } from '../../hooks/usePageTitle'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

const WhitelistPage = () => {
	const [searchValue, setSearchValue] = useState('')
	const [totalItems, setTotalItems] = useState(0)
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [isCreateModalActive, setIsCreateModalActive] = useState(false)
	const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
	const [isDeleteModalActive, setIsDeleteModalActive] = useState(false)
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
	const [deletingFraudRuleId, setDeletingFraudRuleId] = useState<number | null>(null)
	const { shop } = useAppContext()
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isFraudViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.FRAUD_VIEW) || isAdmin
	const isFraudModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.FRAUD_MODIFY) || isAdmin
	const isFraudDeleteAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.FRAUD_DELETE) || isAdmin
	usePageTitle(`Whitelist`)

	const [isGetWhitelistsLoading, whitelists, getWhitelists] = useAPI<FraudRule[]>(
		() =>
			FraudRulesService.getAll(
				shop?.id || '',
				searchValue,
				currentPage + 1,
				pageSize,
				FraudRuleType.ALLOW
			),
		false,
		undefined,
		{
			onSuccess: response => {
				setTotalItems(response.headers['x-pagination-total'])
			},
		}
	)

	const [isDeleteWhitelistLoading, , deleteWhitelist] = useAPI<FraudRule[]>(
		() => FraudRulesService.delete(shop?.id || '', deletingFraudRuleId || 0),
		false,
		'Fraud rule successfully deleted',
		{
			onSuccess: () => {
				closeDeleteModal()
				getWhitelists()
			},
		}
	)

	useDebounce(searchValue, getWhitelists, 400)
	const isFirstLoading = useFirstLoading(isGetWhitelistsLoading)

	const closeDeleteModal = () => {
		closeModal(setIsDeleteModalActive, setIsDeleteModalVisible)
	}

	const onFraudRuleDeleteClickHandler = (fraudRuleId: number) => {
		setDeletingFraudRuleId(fraudRuleId)
		setIsDeleteModalActive(true)
	}

	useEffect(() => {
		if (shop && isFraudViewAvailable) {
			getWhitelists()
		}
	}, [shop, pageSize, currentPage])

	if (isFirstLoading) {
		return <PageLoading />
	}

	if (!isFraudViewAvailable) {
		return (
			<NoDataComponent
				imageSrc={noWhitelistsImage}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)
	}

	return (
		<PageContainer>
			{isCreateModalActive && (
				<Modal
					closeModal={() => closeModal(setIsCreateModalActive, setIsCreateModalVisible)}
					visible={isCreateModalVisible}
					setVisible={setIsCreateModalVisible}
				>
					<CreateFraudRuleModal
						fraudRuleType={FraudRuleType.ALLOW}
						getFraudRules={getWhitelists}
						onClose={() => closeModal(setIsCreateModalActive, setIsCreateModalVisible)}
					/>
				</Modal>
			)}
			{isDeleteModalActive && (
				<Modal
					closeModal={closeDeleteModal}
					visible={isDeleteModalVisible}
					setVisible={setIsDeleteModalVisible}
				>
					<ConfirmModal
						title={'Delete Whitelist'}
						description={
							'You’re about to delete whitelist. Are you sure you want to delete this whitelist? This cannot be undone.'
						}
						onConfirm={deleteWhitelist}
						isButtonLoading={isDeleteWhitelistLoading}
						confirmButtonText={'Delete'}
						onClose={closeDeleteModal}
						buttonIcon={{
							id: 'trashBin',
							height: 13,
							width: 13,
							align: ButtonsIconAlign.LEFT,
						}}
					/>
				</Modal>
			)}
			<PageWrapper>
				{!whitelists?.length && !searchValue ? (
					<NoDataComponent
						imageSrc={noWhitelistsImage}
						title={'No Whitelists'}
						description={
							'You don’t have any whitelists. To create a whitelist, tap on the button below.'
						}
						button={
							isFraudModifyAvailable
								? {
										icon: {
											id: 'plus',
											height: 13,
											width: 13,
											align: ButtonsIconAlign.LEFT,
										},
										onClick: () => setIsCreateModalActive(true),
										text: 'New Whitelist',
								  }
								: null
						}
					/>
				) : (
					<div className={styles.blacklistPage}>
						<PageHeader title={'Whitelist'}>
							<div className={styles.headerEnd}>
								<SearchInput
									style={SearchInputStyles.secondary}
									value={searchValue}
									setValue={setSearchValue}
									placeholder={'Search for a whitelist by data or note'}
								/>
								{isFraudModifyAvailable && (
									<Button
										style={ButtonStyles.SECONDARY}
										icon={{
											id: 'plus',
											height: 13,
											width: 13,
											align: ButtonsIconAlign.LEFT,
										}}
										onClick={() => setIsCreateModalActive(true)}
									>
										New Whitelist
									</Button>
								)}
							</div>
						</PageHeader>

						<PageTableHeader
							listItemNamePlural={'whitelists'}
							totalItems={totalItems}
							pageSize={pageSize}
							setPageSize={setPageSize}
							setCurrentPage={setCurrentPage}
						/>

						{isGetWhitelistsLoading ? (
							<Spinner />
						) : (
							whitelists && (
								<div className={styles.tableWrapper}>
									<FraudRulesTable
										fraudRuleType={FraudRuleType.ALLOW}
										fraudRules={whitelists}
										onDeleteClick={onFraudRuleDeleteClickHandler}
										isFraudDeleteAvailable={isFraudDeleteAvailable}
									/>
								</div>
							)
						)}

						<div className={styles.paginationWrapper}>
							<Pagination
								withoutPageSizeSelector={true}
								totalItems={totalItems}
								pageSize={pageSize}
								setPageSize={setPageSize}
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
							/>
						</div>
					</div>
				)}
			</PageWrapper>
		</PageContainer>
	)
}

export default WhitelistPage
