import { publicAPI } from './API'
import { BasicRegister } from '../types/Auth/BasicRegister'
import { LoginBasic } from '../types/Auth/LoginBasic'
import { ResetBasic } from '../types/Auth/ResetBasic'
import { ResetBasicComplete } from '../types/Auth/ResetBasicComplete'
import { LoginGoogle } from '../types/Auth/LoginGoogle'

export const AuthService = {
	registerBasic: async (data: BasicRegister) => {
		return await publicAPI.post(`/v1/dash/auth/register/basic`, data)
	},
	loginBasic: async (data: LoginBasic) => {
		return await publicAPI.post(`/v1/dash/auth/login/basic`, data)
	},
	resetBasic: async (data: ResetBasic) => {
		return await publicAPI.post(`/v1/dash/auth/reset/basic`, data)
	},
	resetBasicComplete: async (data: ResetBasicComplete) => {
		return await publicAPI.post(`/v1/dash/auth/reset/basic/complete`, data)
	},
	loginGoogle: async (data: LoginGoogle) => {
		return await publicAPI.post(`/v1/dash/auth/login/google`, data)
	},
	loginDiscord: async (data: LoginGoogle) => {
		return await publicAPI.post(`/v1/dash/auth/login/discord`, data)
	},
}
