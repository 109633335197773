import { privateAPI } from './API'
import { SessionAuthType } from '../types/SesssionAuthType/SessionAuthType'

export const UserSecurityService = {
	getSecurity: async () => {
		return await privateAPI.get(`/v1/dash/user/security`)
	},
	connectDiscord: async (data: { code: string; redirectUrl: string }) => {
		return await privateAPI.post(`/v1/dash/user/security/discord/connect`, data)
	},
	connectGoogle: async (data: { code: string; redirectUrl: string }) => {
		return await privateAPI.post(`/v1/dash/user/security/google/connect`, data)
	},
	googleTFAStart: async () => {
		return await privateAPI.post(`/v1/dash/user/security/tfa/providers/google/challenge/start`)
	},
	googleTFAComplete: async (data: { key: string; code: string }) => {
		return await privateAPI.post(
			`/v1/dash/user/security/tfa/providers/google/challenge/complete`,
			data
		)
	},
	googleTFADisable: async (data: { code: string }) => {
		return await privateAPI.post(`/v1/dash/user/security/tfa/providers/google/disable`, data)
	},
	changeEmail: async (data: { email: string }) => {
		return await privateAPI.post(`/v1/dash/user/security/email/change`, data)
	},
	changePassword: async (data: { oldPassword: string; newPassword: string }) => {
		return await privateAPI.post(`/v1/dash/user/security/basic/change`, data)
	},
	getSessions: async (
		authTypeFilter: SessionAuthType | null,
		isActiveFilter: boolean,
		PageNumber?: number,
		PageSize?: number
	) => {
		return await privateAPI.get(`/v1/dash/user/security/sessions`, {
			params: {
				authTypeFilter,
				isActiveFilter,
				PageNumber,
				PageSize,
			},
		})
	},
	invalidateSession: async (sessionId: number) => {
		return await privateAPI.post(`/v1/dash/user/security/sessions/${sessionId}/invalidate`)
	},
	invalidateAllSessions: async () => {
		return await privateAPI.post(`/v1/dash/user/security/sessions/invalidate`)
	},
}
