import React, { FC, useState } from 'react'
import styles from '../Modals.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../UI/Button/Button'
import PaymentType from '../../PaymentType/PaymentType'
import { Gateways } from '../../../types/Gateways/Gateways'
import { BulkUpdateModalsProps } from '../../../types/BulkUpdateModalsProps/BulkUpdateModalsProps'
import { useAPI } from '../../../hooks/useAPI'
import { BulkUpdateProductsService } from '../../../API/BulkUpdateProductsService'
import { useAppContext } from '../../../hooks/useAppContext'
import { BulkGatewayAction } from '../../../types/BulkGatewayAction/BulkGatewayAction'
import RadioWrapper from '../../UI/RadioWrapper/RadioWrapper'

const UpdatePaymentMethodsModal: FC<BulkUpdateModalsProps> = ({
	onClose,
	productIds,
	getProducts,
}) => {
	const { shop, availablePayments } = useAppContext()
	const [checkedGateways, setCheckedGateways] = useState<string[]>([])
	const [action, setAction] = useState<BulkGatewayAction>(BulkGatewayAction.REPLACE)

	const [isUpdateGatewaysLoading, , updateGateways] = useAPI(
		() =>
			BulkUpdateProductsService.updateGateways(shop?.id || '', {
				gateways: checkedGateways.map(checkedGateway => ({
					gateway: checkedGateway,
				})),
				productIds,
				action: action,
			}),
		false,
		'Products successfully updated',
		{
			onSuccess: () => {
				onClose()
				getProducts()
			},
		}
	)

	return (
		<div className={styles.modalInner}>
			<header className={styles.header}>
				<h1>Update Payment Methods</h1>
			</header>

			<div className={styles.body}>
				<div>
					<p className={styles.description}>
						Toggle the payment methods you want to enable/disable for the selected products:
					</p>
					<div className={styles.spaceBetween}>
						<RadioWrapper
							value={BulkGatewayAction.REPLACE}
							setValue={() => setAction(BulkGatewayAction.REPLACE)}
							title={'REPLACE'}
							description={'Replaces and includes selected payment methods.'}
							checked={action === BulkGatewayAction.REPLACE}
							id={BulkGatewayAction.REPLACE}
						/>
						<RadioWrapper
							value={BulkGatewayAction.ADD}
							setValue={() => setAction(BulkGatewayAction.ADD)}
							title={'ADD'}
							description={'Includes selected payment methods.'}
							checked={action === BulkGatewayAction.ADD}
							id={BulkGatewayAction.ADD}
						/>
						<RadioWrapper
							value={BulkGatewayAction.REMOVE}
							setValue={() => setAction(BulkGatewayAction.REMOVE)}
							title={'REMOVE'}
							description={'Deletes selected payment methods.'}
							checked={action === BulkGatewayAction.REMOVE}
							id={BulkGatewayAction.REMOVE}
						/>
					</div>
				</div>

				<div className={styles.mtLarge}>
					<p className={styles.description}>
						Toggle the payment methods you want to enable/disable for the selected products:
					</p>

					<div className={styles.fieldWrapper}>
						<div className={styles.twoColumns}>
							{availablePayments?.map(gateway => (
								<PaymentType
									checked={checkedGateways.includes(gateway.name)}
									setChecked={checked => {
										if (checked) {
											setCheckedGateways([...checkedGateways, gateway.name as Gateways])
										} else {
											setCheckedGateways(
												checkedGateways.filter(checkedGateway => checkedGateway !== gateway.name)
											)
										}
									}}
									gateway={gateway}
								/>
							))}
						</div>
					</div>
				</div>
			</div>

			<footer className={styles.footer}>
				<div className={styles.buttons}>
					<Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
						Cancel
					</Button>
					<Button
						style={ButtonStyles.BORDERED}
						onClick={updateGateways}
						disableShadow={true}
						icon={{
							id: 'download',
							width: 13,
							height: 13,
							align: ButtonsIconAlign.LEFT,
						}}
						isLoading={isUpdateGatewaysLoading}
					>
						Save Update
					</Button>
				</div>
			</footer>
		</div>
	)
}

export default UpdatePaymentMethodsModal
