export const fieldValidators = {
	blankValidator: (fieldName: string, fieldValue: string | number | boolean | undefined) => {
		if (fieldValue) {
			return ''
		}

		return `${fieldName} can't be blank`
	},
	URLValidator: (fieldName: string, url: string) => {
		const urlPattern: RegExp = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/)

		return urlPattern.test(url) ? `` : `${fieldName} value is not a valid URL/URI`
	},
	emailValidator: (email: string) => {
		return email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) ? '' : 'Invalid email'
	},
	phoneNumberValidator: (phoneNumber: string) => {
		const phonePattern: RegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/

		return phonePattern.test(phoneNumber) ? '' : 'Invalid phone number'
	},
	passwordValidator: (password: string) => {
		return !password
			? "Password can't be blank"
			: password.length < 8
			? 'The length of the password must exceed 8 characters.'
			: ''
	},
}
