import React, { FC } from 'react'
import styles from './CopyField.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

interface CopyFieldProps {
	value: string
}

const CopyField: FC<CopyFieldProps> = ({ value }) => {
	return (
		<div className={styles.copyField}>
			<p>{value}</p>
			<SpriteIcon iconId={'copy'} width={14.5} height={14.5} />
		</div>
	)
}

export default CopyField
