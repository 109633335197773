import React, { FC } from 'react'
import styles from './SubdomainInput.module.scss'
import CustomSelect from '../CustomSelect/CustomSelect'
import Input from '../FormComponents/Input'
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage'

interface SubdomainInputProps {
	domain: string
	setDomain: (value: string) => void
	errorMessage?: string
	onBlur?: () => void
}

const SubdomainInput: FC<SubdomainInputProps> = ({ domain, setDomain, errorMessage, onBlur }) => {
	return (
		<>
			<div className={`${styles.subdomainInput} ${errorMessage && styles.error}`}>
				<input
					value={domain}
					onChange={event => setDomain(event.target.value)}
					placeholder={'Your domain'}
					onBlur={onBlur}
				/>
				<div className={styles.domain}>.billgang.store</div>
			</div>
			{errorMessage && <FieldErrorMessage errorMessage={errorMessage} />}
		</>
	)
}

export default SubdomainInput
